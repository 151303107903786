import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Typography } from "@material-ui/core";
import {
  B2BSpinner,
  GenericInfoPopup,
  Icon,
  IconName,
  LoadingPopup,
  PoweredByHopper,
  useDeviceTypes,
} from "halifax";
import { PriceFreezeRefundModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import {
  CallState,
  ModalButtonType,
  TAPPED_TAKEOVER_CHOICE,
  VIEWED_TAKEOVER,
} from "redmond";
import { PATH_SHOP, PATH_TRIPS, FAQ_URL } from "../../../../utils/urlPaths";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IPriceFreezeRefundModalProps
  extends PriceFreezeRefundModalConnectorProps,
    RouteComponentProps {}

export const PriceFreezeRefundModal = ({
  displayPriceFreezeRefundModal,
  priceFreeze,
  refundPriceFreezeCallState,
  passengers,
  refundPriceFreeze,
  setDisplayPriceFreezeRefundModal,
  history,
}: IPriceFreezeRefundModalProps) => {
  const { matchesMobile } = useDeviceTypes();
  const isSuccess = refundPriceFreezeCallState === CallState.Success;
  const isError = refundPriceFreezeCallState === CallState.Failed;
  const totalAmount = priceFreeze?.offer.totalAmount.fiat;

  const takeoverEventPropeties = {
    primary_button: "cancel_price_freeze",
    secondary_button: "exit",
    price_freeze_flow: true,
    type: "cancel_price_freeze",
  };

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_TAKEOVER,
      properties: {
        ...takeoverEventPropeties,
        screen: matchesMobile
          ? "choose_travelers"
          : history.location.pathname === PATH_SHOP
          ? "flight_list"
          : "review_flight_details",
      },
    });
  }, []);

  const handleRefundPriceFreeze = () => {
    refundPriceFreeze(priceFreeze?.id || "");
    trackEvent({
      eventName: TAPPED_TAKEOVER_CHOICE,
      properties: {
        ...takeoverEventPropeties,
        button_choice: ModalButtonType.SECONDARY,
      },
    });
  };

  const handleDoneButtonClick = () => {
    setDisplayPriceFreezeRefundModal(false);
    history.push({
      pathname: PATH_TRIPS,
      search: "?tripsFilter=Frozen%20Prices",
    });
  };

  const getButtons = (isError?: boolean | undefined) => {
    if (isError) {
      return [
        {
          buttonText: textConstants.CONTACT_SUPPORT,
          onClick: () => {
            window.open(FAQ_URL, "_blank")?.focus();
            trackEvent({
              eventName: TAPPED_TAKEOVER_CHOICE,
              properties: {
                ...takeoverEventPropeties,
                button_choice: ModalButtonType.PRIMARY,
              },
            });
          },
          defaultStyle: "h4r-primary",
        },
        {
          buttonText: textConstants.TRY_AGAIN,
          onClick: handleRefundPriceFreeze,
          defaultStyle: "h4r-secondary",
        },
      ];
    }
    return [
      {
        buttonText: isSuccess
          ? textConstants.DONE
          : textConstants.YES_GET_A_REFUND,
        onClick: () => {
          isSuccess ? handleDoneButtonClick() : handleRefundPriceFreeze();
        },
        defaultStyle: "h4r-primary",
      },
    ];
  };

  if (refundPriceFreezeCallState === CallState.InProcess) {
    return (
      <LoadingPopup
        indicatorSize="small"
        indicator={B2BSpinner}
        open
        popupSize="small"
        message={textConstants.LOADING_TEXT}
        footer={PoweredByHopper}
      />
    );
  }

  if (isError) {
    return (
      <GenericInfoPopup
        buttons={getButtons(isError)}
        className="price-freeze-refund-error-modal"
        image={<Icon className="error-icon" name={IconName.UnableToProcess} />}
        isMobile={matchesMobile}
        open={true}
        subtitle={textConstants.ERROR_REFUND_SUBTITLE}
        title={textConstants.ERROR_REFUND_TITLE}
        // note: mobile popovers in the checkout workflow have z-index: 1300
        zIndex={matchesMobile ? 1301 : 1300}
      />
    );
  }

  return (
    <GenericInfoPopup
      buttons={getButtons()}
      className="price-freeze-refund-modal"
      image={
        isSuccess ? (
          <Icon className="success-icon" name={IconName.Checked} />
        ) : (
          <Icon className="error-icon" name={IconName.UnableToProcess} />
        )
      }
      isMobile={matchesMobile}
      open={displayPriceFreezeRefundModal}
      subtitle={
        isSuccess ? (
          textConstants.REFUND_SUCCESS_SUB
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: passengers
                ? textConstants.REFUND_MODAL_SUB(totalAmount, passengers)
                : "",
            }}
          />
        )
      }
      title={
        isSuccess ? (
          <Typography variant="h2" className="refund-success-title">
            {textConstants.REFUND_SUCCESS}
          </Typography>
        ) : (
          textConstants.REFUND_MODAL_TITLE
        )
      }
      // note: mobile popovers in the checkout workflow have z-index: 1300
      zIndex={matchesMobile ? 1301 : 1300}
      onClose={() =>
        isSuccess
          ? handleDoneButtonClick()
          : setDisplayPriceFreezeRefundModal(false)
      }
      showCloseButton={true}
    />
  );
};
