import React from "react";
import { startCase } from "lodash-es";
import { Box, Chip, Button } from "@material-ui/core";

import { AppliedFilterTagsProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

export const AppliedFilterTags = (props: AppliedFilterTagsProps) => {
  const appliedFilters = Object.keys(props.filters).filter(
    (key) => props.filters[key]
  );

  const handleDelete = (key: string) => {
    props.resets.reset[key]();
    if (key === "stops") {
      props.setRerunPrediction();
    }
  };

  if (appliedFilters.length <= 0) return null;

  return (
    <Box
      className={"applied-filter-tags"}
      role="region"
      aria-label="Applied filter tags"
    >
      {appliedFilters.map((filter) => (
        <Chip
          className={"applied-filter-chip"}
          key={filter}
          label={startCase(filter)}
          aria-label={`Remove ${filter}`}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleDelete(filter);
            }
          }}
          onDelete={() => handleDelete(filter)}
          color="secondary"
        />
      ))}
      {appliedFilters.length > 0 && (
        <Button
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (appliedFilters.includes("stops")) {
                props.setRerunPrediction();
              }
              props.resets.resetAll();
            }
          }}
          onClick={() => {
            if (appliedFilters.includes("stops")) {
              props.setRerunPrediction();
            }
            props.resets.resetAll();
          }}
        >
          {textConstants.RESET_FILTERS}
        </Button>
      )}
    </Box>
  );
};
