import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { travelWalletApiPrefix } from "../paths";
import { WalletDetailsResponse } from "redmond";

const END_POINT: string = `${travelWalletApiPrefix}/details`;

export const fetchTravelWalletDetails = (): Promise<WalletDetailsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<WalletDetailsResponse> = await axios.get(
        END_POINT,
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
