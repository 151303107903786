import { put } from "redux-saga/effects";
import { SingleFlightItineraryResponse } from "redmond";
import { airBookingApi } from "@b2bportal/air-booking-api";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import {
  IFetchSingleFlightItinerary,
  setSingleFlightItinerary,
  setFetchSingleFlightItineraryCallStateFailed,
} from "../actions/actions";
import Logger from "../../../helpers/Logger";

export function* fetchSingleFlightItinerarySaga(
  action: IFetchSingleFlightItinerary
) {
  try {
    const { itineraryId } = action;
    const request = () => {
      return airBookingApi(axios as any).apiV0ItineraryFlightSinglePut({
        itineraryId,
        referenceDateTime: dayjs().format(),
      });
    };
    const response: AxiosResponse<SingleFlightItineraryResponse> =
      yield request();

    if (response) {
      yield put(
        setSingleFlightItinerary({
          response: response.data,
        })
      );
    } else {
      yield put(setFetchSingleFlightItineraryCallStateFailed());
    }
  } catch (e) {
    yield put(setFetchSingleFlightItineraryCallStateFailed());
    Logger.debug(e);
  }
}
