export const apiVersionPrefix: string = "/api/v0";

export const locationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/location`;
export const calendarApiPrefix: string = `${apiVersionPrefix}/calendar`;
export const tripDetailsApiPrefix: string = `${apiVersionPrefix}/tripDetail`;
export const watchApiPrefix: string = `${apiVersionPrefix}/alert/watch`;
export const tripSummariesApiPrefix: string = `${apiVersionPrefix}/shopSummary`;
export const passengersApiPrefix: string = `${apiVersionPrefix}/passengers`;
export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const passengerValidationApiPrefix: string = `${apiVersionPrefix}/passengerValidation`;
export const tripPriceApiPrefix: string = `${apiVersionPrefix}/tripPrice`;
export const paymentApiPrefix: string = `${apiVersionPrefix}/payment`;
export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const agentPortalChargeBookingFeeApi: string = `${apiVersionPrefix}/agentPortal/chargeBookingFee`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;

export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;

export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;

export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;

export const priceFreezePath: string = `${apiVersionPrefix}/pricefreeze`;
export const priceFreezeOfferPath: string = `${priceFreezePath}/offer`;
export const customPriceFreezeOfferPath: string = `${priceFreezeOfferPath}/custom`;
export const priceFreezeOfferDataPath: string = `${customPriceFreezeOfferPath}/data`;
export const seatApiPrefix: string = `${apiVersionPrefix}/seats`;
export const seatMapsApiPrefix: string = `${seatApiPrefix}/seatMap`;
export const priceFreezeRefund: string = `${priceFreezePath}/refund`;
export const similarFlightApiPrefix: string = `${priceFreezePath}/similarflight`;
export const transferToSimilarFlightsPrefix: string = `${similarFlightApiPrefix}/transfer`;
export const startFareQuoteApiPrefix: string = `${priceFreezePath}/startFareQuote`;
export const scheduleFareQuoteApiPrefix: string = `${priceFreezePath}/scheduleFareQuote`;
export const pollFareQuoteApiPrefix: string = `${priceFreezePath}/pollFareQuote`;

export const priceDropApiPrefix: string = `${apiVersionPrefix}/pricedrop`;
export const fetchEligibilityApiPrefix: string = `${priceDropApiPrefix}/eligibility/get`;

export const userApiPrefix = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix = `${userApiPrefix}/wallet`;

export const lodgingLocationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/lodging`;
export const hotelAvailabilityApiPrefix: string = `${apiVersionPrefix}/hotels/availability`;
export const flightItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/flight`;
export const hotelItinerariesApiPrefix: string = `${apiVersionPrefix}/itinerary/hotel`;

export const potentialCrossSellOffersApiPrefix: string = `${apiVersionPrefix}/crosssell/offers/potential`;
// Customer Preferences
export const userFlightPreferencesPath: string = `${apiVersionPrefix}/user/preference/flight`;

// Corporate Travel
export const pendingRequestApiPrefix = `${apiVersionPrefix}/pendingRequests/flight`;
export const fetchPolicies = `${apiVersionPrefix}/policies/details`;

export const showFintechEligibilityApiPrefix = `${apiVersionPrefix}/cotbFintech/isOptinEligible`;
export const optInSubscriptionApiPrefix = `${apiVersionPrefix}/cotbFintech/optInToSubscription`;

// Engagement data stream
export const engagementDataApiPrefix: string = `${apiVersionPrefix}/engagement`;
export const trackEngagementDataPath: string = `${engagementDataApiPrefix}/event`;
