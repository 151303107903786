import {
  ISetAirlineFilter,
  ISetAirportFilter,
  ISetBaggageTransfersFilter,
  ISetDurationFilter,
  ISetFareclassOptionFilter,
  ISetFlightNumberFilter,
  ISetMaxPriceFilter,
  ISetOutboundArrivalTimeRange,
  ISetOutboundDepartureTimeRange,
  ISetPolicyFilter,
  ISetReturnArrivalTimeRange,
  ISetReturnDepartureTimeRange,
  ISetStopsOption,
  setApplyUserFlightPreferences,
  setBaggageTransfersFilter,
  setDurationFilter,
  setPolicyFilter,
  setUserPreferencesNotAvailable,
} from "./../../../../actions/actions";
import dayjs from "dayjs";
import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import {
  IRerunPrediction,
  setRerunPrediction,
} from "../../../../../shop/actions/actions";
import {
  allFlightShopFilterSelector,
  predictionLoadingSelector,
  flightShopTypeSelector,
  hasSetMaxPriceFilterSelectorV2,
  flightsByFlightShopTypeSelector,
  filteredFlightsSelectorV2,
} from "../../../../../shop/reducer/selectors";
import {
  setAirlineFilter,
  setAirportFilter,
  setFareclassOptionFilter,
  setFlightNumberFilter,
  setMaxPriceFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnArrivalTimeRange,
  setReturnDepartureTimeRange,
  setStopsOption,
} from "../../../../actions/actions";
import {
  getAirlineFilter,
  getAirportFilter,
  getDepartureDate,
  getFareclassOptionFilter,
  getDestination,
  getFlightNumberFilter,
  getHasSetAirlineFilter,
  getHasSetFareClassFilter,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetStopsOption,
  getMaxPriceFilter,
  getOrigin,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getStopsOption,
  getTripCategory,
  getBaggageTransfersFilter,
  getDurationFilter,
  getMinMaxFlightDurations,
  getActiveFiltersCount,
  getApplyUserFlightPreferences,
  getHasUserSetFlightPreferences,
  getUserFlightPreferences,
  getUserFlightPreferencesCallState,
  getPolicyFilter,
  getUserFlightPreferencesNotAvailable,
} from "../../../../reducer/selectors";
import { FlightShopSearchFilter } from "./component";
import {
  FareclassOptionFilter,
  initialFilterOptions,
} from "../../../../reducer";
import { Dispatch } from "@reduxjs/toolkit";
import { IFlightNumberFilter, ITimeRange, SliceStopCountFilter } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const dayjsDate = departureDate ? dayjs(departureDate) : null;
  const departureDateString = dayjsDate ? dayjsDate.format("ddd, MMM D") : "";

  const flights = flightsByFlightShopTypeSelector(state);
  const airportMap = flights?.airports;
  const origin = getOrigin(state);
  const destination = getDestination(state);

  const hasSetTimeRange =
    getHasSetOutboundTimeRange(state) || getHasSetReturnTimeRange(state);

  const originAirport =
    airportMap && origin ? airportMap[origin?.id.code.code] : undefined;
  const destinationAirport =
    airportMap && destination
      ? airportMap[destination?.id.code.code]
      : undefined;

  const { minDuration, maxDuration } = getMinMaxFlightDurations(state);

  return {
    flightShopFilters: allFlightShopFilterSelector(state),
    predictionLoading: predictionLoadingSelector(state),
    tripCategory: getTripCategory(state),
    flightShopType: flightShopTypeSelector(state),
    stopsOption: getStopsOption(state),
    hasSetStopsOption: getHasSetStopsOption(state),
    airlineFilter: getAirlineFilter(state),
    hasSetAirlineFilter: getHasSetAirlineFilter(state),
    airportFilter: getAirportFilter(state),
    maxPriceFilter: getMaxPriceFilter(state),
    hasSetMaxPrice: hasSetMaxPriceFilterSelectorV2(state),
    flightNumberFilter: getFlightNumberFilter(state),
    fareClassOptionFilter: getFareclassOptionFilter(state),
    departureDateString,
    outboundDepartureTimeRange: getOutboundDepartureTimeRange(state),
    outboundArrivalTimeRange: getOutboundArrivalTimeRange(state),
    returnDepartureTimeRange: getReturnDepartureTimeRange(state),
    returnArrivalTimeRange: getReturnArrivalTimeRange(state),
    hasSetTimeRange,
    fareclassOptionFilter: getFareclassOptionFilter(state),
    hasSetFareclassFilter: getHasSetFareClassFilter(state),
    hasBaggageTransfersFilter: getBaggageTransfersFilter(state),
    durationFilter: getDurationFilter(state),
    origin,
    originAirport,
    destination,
    destinationAirport,
    airportMap,
    minDuration,
    maxDuration,
    filteredFlightCount: filteredFlightsSelectorV2(state)(false).length,
    hasActiveFilters: getActiveFiltersCount(state) > 0,
    userHasSetFlightPreferences: getHasUserSetFlightPreferences(state),
    userFlightPreferencesNotAvailable:
      getUserFlightPreferencesNotAvailable(state),
    userFlightPreferences: getUserFlightPreferences(state),
    userFlightPreferencesCallState: getUserFlightPreferencesCallState(state),
    shouldApplyUserFlightPreferences: getApplyUserFlightPreferences(state),
    policyFilter: getPolicyFilter(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStopsOption: (stopsOption: SliceStopCountFilter): ISetStopsOption =>
    dispatch(setStopsOption(stopsOption, "list")),
  setRerunPrediction: (): IRerunPrediction => dispatch(setRerunPrediction()),
  setAirlineFilter: (airlineFilter: string[]): ISetAirlineFilter =>
    dispatch(setAirlineFilter(airlineFilter)),
  setAirportFilter: (airportFilter: string[]): ISetAirportFilter =>
    dispatch(setAirportFilter(airportFilter)),
  setMaxPriceFilter: (maxPriceFilter: number): ISetMaxPriceFilter =>
    dispatch(setMaxPriceFilter(maxPriceFilter)),
  setFlightNumberFilter: (
    flightNumberFilter: IFlightNumberFilter[]
  ): ISetFlightNumberFilter =>
    dispatch(setFlightNumberFilter(flightNumberFilter)),
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ): ISetOutboundDepartureTimeRange =>
    dispatch(setOutboundDepartureTimeRange(outboundDepartureTimeRange)),
  setOutboundArrivalTimeRange: (
    outboundArrivalTimeRange: ITimeRange
  ): ISetOutboundArrivalTimeRange =>
    dispatch(setOutboundArrivalTimeRange(outboundArrivalTimeRange)),
  setReturnDepartureTimeRange: (
    returnDepartureTimeRange: ITimeRange
  ): ISetReturnDepartureTimeRange =>
    dispatch(setReturnDepartureTimeRange(returnDepartureTimeRange)),
  setReturnArrivalTimeRange: (
    returnArrivalTimeRange: ITimeRange
  ): ISetReturnArrivalTimeRange =>
    dispatch(setReturnArrivalTimeRange(returnArrivalTimeRange)),
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ): ISetFareclassOptionFilter =>
    dispatch(setFareclassOptionFilter(fareclassOptionFilter, "list")),
  setBaggageTransfersFilter: (value: boolean): ISetBaggageTransfersFilter =>
    dispatch(setBaggageTransfersFilter(value)),
  setDurationFilter: (duration: number): ISetDurationFilter =>
    dispatch(setDurationFilter(duration)),
  setPolicyFilter: (policyFilter: boolean): ISetPolicyFilter =>
    dispatch(setPolicyFilter(policyFilter)),
  resetAllFilters: () => {
    dispatch(setStopsOption(initialFilterOptions.stopsOption));
    dispatch(setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(
      setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
      })
    );
    dispatch(setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(setFlightNumberFilter(initialFilterOptions.flightNumberFilter));
    dispatch(
      setOutboundArrivalTimeRange(initialFilterOptions.outboundArrivalTimeRange)
    );
    dispatch(
      setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      setReturnDepartureTimeRange(initialFilterOptions.returnDepartureTimeRange)
    );
    dispatch(
      setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange)
    );
    dispatch(setDurationFilter(initialFilterOptions.durationFilter));
    dispatch(setPolicyFilter(initialFilterOptions.policyFilter));
  },
  setApplyUserFlightPreferences: (applyPreferences: boolean) => {
    dispatch(setApplyUserFlightPreferences(applyPreferences));
  },
  setUserPreferencesNotAvailable: (userPreferencesNotAvailable: boolean) => {
    dispatch(setUserPreferencesNotAvailable(userPreferencesNotAvailable));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopSearchFilterConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopSearchFilter = connector(
  FlightShopSearchFilter
);
