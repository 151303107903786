import {
  CallState,
  MessageMethodEnum,
  UpdateWatchAlertRequest,
  WatchAlertView,
} from "redmond";
import { put } from "redux-saga/effects";

import { updateWatch } from "../../../api/v0/price-watch/updateWatch";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";

export function* updateWatchSaga(action: actions.IUpdateWatch) {
  try {
    const request: UpdateWatchAlertRequest = {
      key: action.alertKey,
      method: {
        email: action.email,
        MessageMethod: MessageMethodEnum.Email,
      },
    };
    const watches: WatchAlertView[] = yield updateWatch(request);
    yield put(actions.setUpdateWatchCallState(CallState.Success));
    yield put(actions.setWatches(watches));
  } catch (e) {
    yield put(actions.setUpdateWatchCallState(CallState.Failed));
    Logger.debug(e);
  }
}
