import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getOpenDatesModal
} from "../../../../reducer";
import { CalenderPickerButton } from "./component";
import { setOpenDatesModal } from "../../../../actions/actions";

export const mapStateToProps = (state: IStoreState) => ({
  openDatesModal: getOpenDatesModal(state)
});

const mapDispatchToProps = {
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedCalenderPickerButtonProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCalenderPickerButton = connector(CalenderPickerButton);
