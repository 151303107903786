import React, { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { startCase } from "lodash-es";
import { Box, Chip, Button } from "@material-ui/core";

import { AppliedFilterTagsProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

const AppliedFilterTag: FC<
  {
    filter: string;
    onDelete: (filter: string) => void;
  } & PropsWithChildren
> = ({ filter, onDelete }) => {
  const handleDelete = useCallback(() => {
    onDelete(filter);
  }, [filter, onDelete]);

  const onKeyDownHandler = useCallback<
    React.KeyboardEventHandler<HTMLDivElement>
  >(
    (e) => {
      if (e.key === "Enter") {
        onDelete(filter);
      }
    },
    [filter, onDelete]
  );
  return (
    <Chip
      className={"applied-filter-chip"}
      label={startCase(filter)}
      aria-label={`Remove ${filter}`}
      onKeyDown={onKeyDownHandler}
      onDelete={handleDelete}
      color="secondary"
    />
  );
};

export const AppliedFilterTags = (props: AppliedFilterTagsProps) => {
  const { filters, resets, setRerunPrediction } = props;
  const appliedFilters = useMemo(
    () => Object.keys(filters).filter((key) => filters[key]),
    [filters]
  );

  const handleDelete = useCallback(
    (key: string) => {
      resets.reset[key]();
      if (key === "stops") {
        setRerunPrediction();
      }
    },
    [resets]
  );

  const onKeyDownHandler = useCallback<
    React.KeyboardEventHandler<HTMLButtonElement>
  >(
    (e) => {
      if (e.key === "Enter") {
        if (appliedFilters.includes("stops")) {
          setRerunPrediction();
        }
        resets.resetAll();
      }
    },
    [resets, setRerunPrediction]
  );
  const onResetFilters = useCallback(() => {
    if (appliedFilters.includes("stops")) {
      setRerunPrediction();
    }
    resets.resetAll();
  }, [resets, setRerunPrediction]);

  if (appliedFilters.length <= 0) return null;
  return (
    <Box className={"applied-filter-tags"}>
      {appliedFilters.map((filter) => (
        <AppliedFilterTag
          key={filter}
          filter={filter}
          onDelete={handleDelete}
        />
      ))}
      {appliedFilters.length > 0 && (
        <Button onKeyDown={onKeyDownHandler} onClick={onResetFilters}>
          {textConstants.RESET_FILTERS}
        </Button>
      )}
    </Box>
  );
};
