import { createSelector } from "@reduxjs/toolkit";
import {
  PriceFreezeOfferDataWithRewards,
  PriceFreezeUserSelectedDurationProperties,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  PRICE_FREEZE_USER_SELECTED_DURATION_USER_SELECTED,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY_AVAILABLE,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY_DEFAULT_12_HR,
  PRICE_FREEZE_USER_SELECTED_DURATION,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY,
  PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY,
  PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_POPUP,
  PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER,
  PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_CONTROL,
  PRICE_FREEZE_VOID_WINDOW,
  PRICE_FREEZE_DEFAULT_DURATIONS,
  PRICE_FREEZE_DEFAULT_DURATIONS_KNOCKOUT,
  PRICE_FREEZE_SHOW_DURATIONS,
  PRICE_FREEZE_SHOW_DURATIONS_VARIANT1,
} from "../../../../context/experiments";
import {
  convertSecondsToDays,
  PriceFreezeDurationSelectionMethod,
} from "../../utils/index";
import { USE_SLIDER_THRESHOLD } from "./textConstants";

export const getPriceFreezeOfferDataCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.getPriceFreezeOfferDataCallState;

export const priceFreezeOfferDataSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeOfferData;

export const priceFreezeOfferRadioDurationsSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeOfferRadioDurations;

export const selectedPriceFreezeOfferDataIndexSelector = (state: IStoreState) =>
  state.flightFreeze.selectedPriceFreezeOfferDataIndex;

export const selectedPriceFreezeRadioDataIndexSelector = (state: IStoreState) =>
  state.flightFreeze.selectedRadioOfferDataIndex;

export const generateCustomPriceFreezeOfferCallStateSelector = (
  state: IStoreState
) => state.flightFreeze.generateCustomPriceFreezeOfferCallState;

export const priceFreezeExperimentsSelector = (state: IStoreState) =>
  state.flightFreeze.experiments;

export const priceFreezeOfferVoidWindowEndSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeOffer?.voidWindowAvailabilityEnd;

export const priceFreezeUserSelectedDurationDisplaySelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => {
    return experiments?.[PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY];
  }
);

export const isPriceFreezeDurationPopupEnabledSelector = createSelector(
  priceFreezeUserSelectedDurationDisplaySelector,
  (userSelectedDurationDisplay) => {
    return (
      userSelectedDurationDisplay ===
      PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_POPUP
    );
  }
);

export const priceFreezeUserSelectedDurationNoSliderSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => {
    return experiments?.[PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER];
  }
);

export const priceFreezeUserSelectedDurationDefaultSelectionMethodSelector =
  createSelector(
    priceFreezeUserSelectedDurationNoSliderSelector,
    priceFreezeUserSelectedDurationDisplaySelector,
    (noSliderVariant, displayVariant) => {
      // Disregard No-slider experiment, and always use Slider if the Pop-up experiment is enabled.
      return displayVariant ===
        PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_POPUP ||
        noSliderVariant ===
        PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_CONTROL
        ? PriceFreezeDurationSelectionMethod.Slider
        : PriceFreezeDurationSelectionMethod.Buttons;
    }
  );

export const priceFreezeUserSelectedDurationSelectionMethodSelector =
  createSelector(
    priceFreezeUserSelectedDurationDefaultSelectionMethodSelector,
    priceFreezeOfferDataSelector,
    (defaultSelectionMethod, priceFreezeOfferDataList) => {
      if (!priceFreezeOfferDataList) {
        return PriceFreezeDurationSelectionMethod.Buttons;
      }

      return priceFreezeOfferDataList.length > USE_SLIDER_THRESHOLD
        ? defaultSelectionMethod
        : PriceFreezeDurationSelectionMethod.Toggle;
    }
  );

export const priceFreezeUserSelectedDurationSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => experiments?.[PRICE_FREEZE_USER_SELECTED_DURATION]
);

export const isPriceFreezeDurationEnabledSelector = createSelector(
  priceFreezeUserSelectedDurationSelector,
  (userSelectedDuration) =>
    userSelectedDuration === PRICE_FREEZE_USER_SELECTED_DURATION_USER_SELECTED
);

export const priceFreezeDefaultDurationsSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => experiments?.[PRICE_FREEZE_DEFAULT_DURATIONS]
);

export const priceFreezeDefaultDurationsKnockoutSelector = createSelector(
  priceFreezeDefaultDurationsSelector,
  (default_duration) =>
    default_duration === PRICE_FREEZE_DEFAULT_DURATIONS_KNOCKOUT
);

export const isPriceFreezeDurationActiveSelector = createSelector(
  isPriceFreezeDurationEnabledSelector,
  priceFreezeOfferDataSelector,
  (isPriceFreezeDurationEnabled, priceFreezeOfferData): boolean => {
    return (
      isPriceFreezeDurationEnabled &&
      !!priceFreezeOfferData &&
      priceFreezeOfferData.length > 1
    );
  }
);

export const isPriceFreezeDurationShortActiveSelector = createSelector(
  isPriceFreezeDurationEnabledSelector,
  priceFreezeOfferDataSelector,
  (isPriceFreezeDurationEnabled, priceFreezeOfferData): boolean => {
    return (
      isPriceFreezeDurationEnabled &&
      !!priceFreezeOfferData &&
      priceFreezeOfferData.length === 2
    );
  }
);

export const isPriceFreezeDurationLongActiveSelector = createSelector(
  isPriceFreezeDurationEnabledSelector,
  priceFreezeOfferDataSelector,
  (isPriceFreezeDurationEnabled, priceFreezeOfferData): boolean => {
    return (
      isPriceFreezeDurationEnabled &&
      !!priceFreezeOfferData &&
      priceFreezeOfferData.length > 2
    );
  }
);

export const priceFreezeUserSelectedDurationCopySelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => experiments?.[PRICE_FREEZE_USER_SELECTED_DURATION_COPY]
);

export const isPriceFreezeDurationNewCopyEnabledSelector = createSelector(
  priceFreezeUserSelectedDurationCopySelector,
  (userSelectedDurationCopy) =>
    userSelectedDurationCopy ===
    PRICE_FREEZE_USER_SELECTED_DURATION_COPY_AVAILABLE
);

export const isPriceFreezeDurationDefault12HrCopyEnabledSelector =
  createSelector(
    priceFreezeUserSelectedDurationCopySelector,
    (userSelectedDurationCopy) => {
      return (
        userSelectedDurationCopy ===
        PRICE_FREEZE_USER_SELECTED_DURATION_COPY_DEFAULT_12_HR
      );
    }
  );

export const isPriceFreezeDurationNewCopyActiveSelector = createSelector(
  isPriceFreezeDurationActiveSelector,
  isPriceFreezeDurationNewCopyEnabledSelector,
  (isPriceFreezeDurationActive, isPriceFreezeDurationNewCopyEnabled): boolean =>
    isPriceFreezeDurationActive && isPriceFreezeDurationNewCopyEnabled
);

export const isPriceFreezeDurationDefault12HrCopyActiveSelector =
  createSelector(
    isPriceFreezeDurationActiveSelector,
    isPriceFreezeDurationDefault12HrCopyEnabledSelector,
    (
      isPriceFreezeDurationActive,
      isPriceFreezeDurationDefault12HrCopyEnabled
    ): boolean => {
      return (
        isPriceFreezeDurationActive &&
        isPriceFreezeDurationDefault12HrCopyEnabled
      );
    }
  );

export const customPriceFreezeOfferSelector = (state: IStoreState) =>
  state.flightFreeze.customPriceFreezeOffer;

export const priceFreezeOfferDataObjectWithTtlKeySelector = createSelector(
  priceFreezeOfferDataSelector,
  (
    priceFreezeOfferData
  ): {
      [key in number]: {
        offerData: PriceFreezeOfferDataWithRewards;
        index: number;
      };
    } => {
    const offerDataObject = {};

    priceFreezeOfferData?.forEach((offerData, index) => {
      offerDataObject[convertSecondsToDays(offerData.secondsTtl)] = {
        offerData,
        index,
      };
    });

    return offerDataObject;
  }
);

export const priceFreezeMaxDurationSelector = createSelector(
  priceFreezeOfferDataSelector,
  (priceFreezeOfferData): number | undefined => {
    if (!priceFreezeOfferData || priceFreezeOfferData.length === 0) {
      return undefined;
    }

    return priceFreezeOfferData[priceFreezeOfferData.length - 1]?.secondsTtl;
  }
);

export const priceFreezeMinOfferSelector = createSelector(
  priceFreezeOfferDataSelector,
  (priceFreezeOfferData): PriceFreezeOfferDataWithRewards | null => {
    if (!priceFreezeOfferData || priceFreezeOfferData.length === 0) {
      return null;
    }

    return priceFreezeOfferData[0];
  }
);

export const priceFreezeOfferDataLengthSelector = createSelector(
  priceFreezeOfferDataSelector,
  (priceFreezeOfferData): number => {
    if (!priceFreezeOfferData || priceFreezeOfferData.length === 0) {
      return 0;
    }

    return priceFreezeOfferData.length;
  }
);

export const priceFreezeMiddleOfferSelector = createSelector(
  priceFreezeOfferDataSelector,
  (priceFreezeOfferData): PriceFreezeOfferDataWithRewards | null => {
    if (!priceFreezeOfferData || priceFreezeOfferData.length === 0) {
      return null;
    }

    const length = priceFreezeOfferData.length;
    let indexOfMiddleOffer;
    if (length < 3) {
      indexOfMiddleOffer = length - 1;
    } else if (length % 2 === 0) {
      indexOfMiddleOffer = length / 2;
    } else {
      indexOfMiddleOffer = Math.floor(length / 2);
    }

    return priceFreezeOfferData[indexOfMiddleOffer];
  }
);

export const priceFreezeMiddleOfferIndexSelector = createSelector(
  priceFreezeOfferDataSelector,
  (priceFreezeOfferData): number | null => {
    if (!priceFreezeOfferData || priceFreezeOfferData.length === 0) {
      return null;
    }

    const length = priceFreezeOfferData.length;
    let indexOfMiddleOffer;
    if (length < 3) {
      indexOfMiddleOffer = length - 1;
    } else if (length % 2 === 0) {
      indexOfMiddleOffer = length / 2;
    } else {
      indexOfMiddleOffer = Math.floor(length / 2);
    }

    return indexOfMiddleOffer;
  }
);

export const priceFreezeMaxOfferSelector = createSelector(
  priceFreezeOfferDataSelector,
  (priceFreezeOfferData): PriceFreezeOfferDataWithRewards | null => {
    if (!priceFreezeOfferData || priceFreezeOfferData.length === 0) {
      return null;
    }

    return priceFreezeOfferData[priceFreezeOfferData.length - 1];
  }
);

export const priceFreezeMinMaxDurationsPropertiesSelector = createSelector(
  priceFreezeMinOfferSelector,
  priceFreezeMaxOfferSelector,
  (
    minDurationOffer,
    maxDurationOffer
  ): Omit<
    PriceFreezeUserSelectedDurationProperties,
    /*
      note: default values require importing from '/shop' and '/freeze', which will create a situation where
      A imports from B and B imports from A; it allows a circular dependency to be created from selectors (if we are not careful in future),
      so we are not importing anything from `/shop` or `/freeze` to this file (in order to keep the dependency unidirectional)
    */
    "default_fee_price_per_traveler_usd" | "default_duration_offered"
  > => {
    if (!minDurationOffer || !maxDurationOffer) {
      return {};
    }

    return {
      min_fee_price_per_traveler_usd: minDurationOffer.price.fiat.value,
      min_duration_offered: minDurationOffer.secondsTtl,
      max_fee_price_per_traveler_usd: maxDurationOffer.price.fiat.value,
      max_duration_offered: maxDurationOffer.secondsTtl,
    };
  }
);

export const priceFreezeVoidWindowSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => experiments?.[PRICE_FREEZE_VOID_WINDOW]
);

export const priceFreezeVoidWindowEndSelector = createSelector(
  priceFreezeOfferVoidWindowEndSelector,
  customPriceFreezeOfferSelector,
  (voidWindow, customPriceFreezeOffer) => {
    if (!!voidWindow && voidWindow?.VoidWindowAvailabilityEnd === "Until") {
      return voidWindow?.time;
    }
    if (!!customPriceFreezeOffer && customPriceFreezeOffer.voidWindowAvailabilityEnd.VoidWindowAvailabilityEnd === "Until") {
      return customPriceFreezeOffer.voidWindowAvailabilityEnd.time;
    }
    return "Unavailable";
  }
);

export const isCheapestPriceFreezeOfferSelector = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer?.cheapestTrip.isCheapest || false;

export const priceFreezeShowDurationsVariantSelector = createSelector(
  priceFreezeExperimentsSelector,
  (experiments) => {
    return experiments?.[PRICE_FREEZE_SHOW_DURATIONS];
  }
);

export const isPriceFreezeShowDurationsVariant1Selector = createSelector(
  priceFreezeShowDurationsVariantSelector,
  (show_durations_variant) =>
    show_durations_variant === PRICE_FREEZE_SHOW_DURATIONS_VARIANT1
);

export const initialPriceFreezeOfferDataWithRewardsSelector = (state: IStoreState) =>
  state.flightFreeze.initialPriceFreezeOfferDataWithRewards

export const initialSelectedPriceFreezeRadioIndexSelector = (state: IStoreState) =>
  state.flightFreeze.initialSelectedPriceFreezeRadioIndex
