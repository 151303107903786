import React from "react";
import { ToggleSwitch } from "halifax";
import { DateToggleFilter } from "../../reducer";
import { TodayTomorrowToggleConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

export interface ITodayTomorrowToggleProps
  extends TodayTomorrowToggleConnectorProps {
  size: "small" | "medium";
}

export const TodayTomorrowToggle = (props: ITodayTomorrowToggleProps) => {
  const { size, dateToggleFilter, setTodayTomorrowDateFilter } = props;

  return (
    <ToggleSwitch
      className="today-tomorrow-toggle-root"
      size={size}
      ariaLabel={constants.TODAY_TOMORROW_TOGGLE_ARIA_LABEL}
      values={[DateToggleFilter.TODAY, DateToggleFilter.TOMORROW]}
      selectedValue={dateToggleFilter}
      setSelectedValue={(selectedValue: number) => {
        setTodayTomorrowDateFilter({
          dateFilter: selectedValue as DateToggleFilter,
        });
      }}
      getValueText={(value: number) => constants.getValueText(value)}
      getAriaValueText={(value: number) => constants.getAriaValueText(value)}
    />
  );
};
