import { History } from "history";
import { ChangedPriceFreezeDurationProperties } from "redmond";
import { FlightShopStep } from "../../../shop/reducer";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../../../shop/utils/parseQueryString";
import { PATH_SHOP, PATH_FREEZE } from "../../../../utils/urlPaths";

export const getPriceFreezeDurationSelectionPageProperty = (
  history: History
): Pick<ChangedPriceFreezeDurationProperties, "page"> | undefined => {
  switch (history.location.pathname) {
    case PATH_FREEZE: {
      return {
        page: "pf_checkout",
      };
    }
    case PATH_SHOP: {
      const { flightShopProgress } = parseQueryString(
        history
      ) as IFlightShopParsedQuery;
      return {
        page:
          flightShopProgress === FlightShopStep.ReviewItinerary
            ? "review_itinerary"
            : "flight_list",
      };
    }
    default:
      return undefined;
  }
};
