import React, { useContext, useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  ActionButton,
  AirlineIcon,
  getTotalPriceText,
  getRewardText,
  removeTimezone,
} from "halifax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

import { ClientContext } from "../../../../App";
import { MobileFareDetailsConnectorProps } from "./container";
import * as constants from "../FlightShopReviewItinerary/constants";
import { getSliceIndex } from "../../../../utils/flights";
import {
  Restriction,
  getRestrictions,
} from "../FlightShopReviewDetails/component";
import {
  AVAILABLE,
  CFAR,
  getExperimentVariant,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { FlightShopStep, MulticityFlightShopStep } from "../../reducer";
import "./styles.scss";
import clsx from "clsx";
import { getPricesWithComma } from "halifax";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components/TravelWalletDetailsBanner";
import {
  SelectedTravelOfferScreen,
  TravelWalletOffer,
  TripCategory,
} from "redmond";
import dayjs from "dayjs";
import { Airport } from "@b2bportal/air-shopping-api";

interface IMobileFareDetailsProps
  extends MobileFareDetailsConnectorProps,
    RouteComponentProps {
  hasActiveRefundableFare: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
}

export const MobileFareDetails = (props: IMobileFareDetailsProps) => {
  const {
    fareDetails,
    perPaxRefundableFarePrices,
    tripDetails,
    rewardsKey,
    tripCategory,
    history,
    populateFlightBookQueryParams,
    setFlightShopProgress,
    setMulticityFlightShopProgress,
    returnDate,
    departureDate,
    airports,
    isMultiTicket,
    offersByTripId,
    hasActiveRefundableFare,
    credit,
    isSeatsUXOptimizationExperiment,
    hasTravelFusionFareBrand,
  } = props;
  const [isHackerFare, setIsHackerFare] = useState(isMultiTicket);
  const [bestOffer, setBestOffer] = useState<TravelWalletOffer | undefined>(
    undefined
  );

  const isOneWay = tripCategory === TripCategory.ONE_WAY;
  const isMulticity = tripCategory === TripCategory.MULTI_CITY;

  const clientContext = useContext(ClientContext);
  const expState = useExperiments();
  const cfarExperimentVariant = getExperimentVariant(
    expState.experiments,
    CFAR
  );
  const isCfarEnabled = useMemo(() => {
    return cfarExperimentVariant === AVAILABLE;
  }, [cfarExperimentVariant]);

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  useEffect(() => {
    setIsHackerFare(isMultiTicket);
  }, [isMultiTicket]);

  useEffect(() => {
    offersByTripId && setBestOffer(offersByTripId[tripDetails.id]);
  }, [offersByTripId]);

  if (!tripDetails || !fareDetails || !fareDetails.paxPricings) {
    history.push(history.location);
    return null;
  }

  const totalCost = perPaxRefundableFarePrices?.fiat;
  const totalCostText = totalCost
    ? getTotalPriceText({
        price: totalCost,
      })
    : "";
  const rewardText =
    rewardsKey && perPaxRefundableFarePrices?.rewards?.[rewardsKey]
      ? getRewardText({
          reward: perPaxRefundableFarePrices.rewards[rewardsKey],
          round: true,
        })
      : "";
  const firstSliceIndex = getSliceIndex(true, tripDetails);

  const [showOfferBanner, setShowOfferBanner] = useState(false);

  useEffect(() => {
    setShowOfferBanner(
      isTravelWalletOfferExperiment &&
        !!bestOffer &&
        (credit &&
        Math.abs(credit.amount.amount) === Math.abs(bestOffer.amount.amount)
          ? true
          : !!bestOffer.shopPageBanner)
    );
  }, [credit, bestOffer]);

  const renderRestrictionSection = () => {
    if (isOneWay) {
      return (
        <Box className="restrictions-section">
          {fareDetails &&
            getRestrictions({
              fareDetails,
              sliceIndex: firstSliceIndex,
              hasActiveRefundableFare,
              isSeatsUXOptimizationExperiment,
              hasTravelFusionFareBrand,
            }).map((restriction) => (
              <Restriction
                key={restriction.name}
                symbol={restriction.symbol}
                name={restriction.name}
                description={<Typography>{restriction.description}</Typography>}
              />
            ))}
        </Box>
      );
    } else {
      const secondSliceIndex = getSliceIndex(false, tripDetails);
      const outboundHeader =
        departureDate &&
        constants.getReviewCardHeaderWithType(
          true,
          airports[tripDetails.slices[firstSliceIndex].destinationCode]
            ? airports[tripDetails.slices[firstSliceIndex].destinationCode]
                .cityName
            : tripDetails.slices[firstSliceIndex].destinationName,
          departureDate
        );

      const returnHeader =
        returnDate &&
        constants.getReviewCardHeaderWithType(
          false,
          airports[tripDetails.slices[secondSliceIndex].destinationCode]
            ? airports[tripDetails.slices[secondSliceIndex].destinationCode]
                .cityName
            : tripDetails.slices[secondSliceIndex].destinationName,
          returnDate
        );

      return (
        <>
          <Box
            className={clsx("restrictions-section outbound-section", {
              "hacker-fare-restrictions-section": isHackerFare,
            })}
          >
            <Typography className="fare-slice-header" variant="body1">
              <span className="bold-header">{outboundHeader?.type}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: outboundHeader?.description || "",
                }}
              ></span>
            </Typography>
            {isHackerFare && (
              <Box className="flight-info-details">
                <Box className="airline-details">
                  <AirlineIcon
                    airlineCode={
                      tripDetails.slices[0].segmentDetails[0].airlineCode
                    }
                  />
                </Box>
                {/* We display the details for either the outgoing or returning slice */}
                <Typography variant="body2" className="flight-info">
                  {`${tripDetails.slices[0].segmentDetails[0].airlineName}`}
                </Typography>
              </Box>
            )}
            {fareDetails &&
              getRestrictions({
                fareDetails,
                sliceIndex: firstSliceIndex,
                hasActiveRefundableFare,
                isSeatsUXOptimizationExperiment,
                hasTravelFusionFareBrand,
              }).map((restriction) => (
                <Restriction
                  key={restriction.name}
                  symbol={restriction.symbol}
                  name={restriction.name}
                  description={
                    <Typography>{restriction.description}</Typography>
                  }
                />
              ))}
          </Box>
          <Box
            className={clsx("restrictions-section", {
              "hacker-fare-restrictions-section": isHackerFare,
            })}
          >
            <Typography className="fare-slice-header" variant="body1">
              <span className="bold-header">{returnHeader?.type}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: returnHeader?.description || "",
                }}
              ></span>
            </Typography>
            {isHackerFare && (
              <Box className="flight-info-details">
                <Box className="airline-details">
                  <AirlineIcon
                    airlineCode={
                      tripDetails.slices[1].segmentDetails[0].airlineCode
                    }
                  />
                </Box>
                {/* We display the details for either the outgoing or returning slice */}
                <Typography variant="body2" className="flight-info">
                  {`${tripDetails.slices[1].segmentDetails[0].airlineName}`}
                </Typography>
              </Box>
            )}
            {fareDetails &&
              getRestrictions({
                fareDetails,
                sliceIndex: secondSliceIndex,
                hasActiveRefundableFare,
                isSeatsUXOptimizationExperiment,
                hasTravelFusionFareBrand,
              }).map((restriction) => (
                <Restriction
                  key={restriction.name}
                  symbol={restriction.symbol}
                  name={restriction.name}
                  description={
                    <Typography>{restriction.description}</Typography>
                  }
                />
              ))}
          </Box>
        </>
      );
    }
  };

  const renderMulticityRestrictionsSection = () => {
    return tripDetails.slices.map((tripSlice, idx) => {
      const [departureLocations, departureDate] = constants
        .getMulticityReviewHeader(
          idx,
          airports[tripSlice.originCode] as Airport,
          airports[tripSlice.destinationCode] as Airport,
          dayjs(removeTimezone(tripSlice.departureTime)).toDate()
        )
        .split("-");

      return (
        <Box
          className={clsx("restrictions-section", {
            "hacker-fare-restrictions-section": isHackerFare,
          })}
          key={`multicity-restrictions-flight${idx}`}
        >
          <Typography className="fare-slice-header" variant="body1">
            <span className="bold-header">{departureLocations}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: departureDate,
              }}
            ></span>
          </Typography>
          <Box className="flight-info-details">
            <Box className="airline-details">
              <AirlineIcon
                airlineCode={
                  tripDetails.slices[idx].segmentDetails[0].airlineCode
                }
              />
            </Box>
            <Typography variant="body2" className="flight-info">
              {`${tripDetails.slices[idx].segmentDetails[0].airlineName}`}
            </Typography>
          </Box>
          {fareDetails &&
            getRestrictions({
              fareDetails,
              sliceIndex: idx,
              hasActiveRefundableFare,
              isSeatsUXOptimizationExperiment,
              hasTravelFusionFareBrand,
            }).map((restriction) => (
              <Restriction
                key={restriction.name}
                symbol={restriction.symbol}
                name={restriction.name}
                description={<Typography>{restriction.description}</Typography>}
              />
            ))}
        </Box>
      );
    });
  };

  return (
    <Box className="fare-details-container">
      <Box className="fare-details-banner-container">
        {clientContext.itineraryReviewBackground && (
          <img
            src={clientContext.itineraryReviewBackground}
            alt="fare-details-banner-background"
            className="fare-details-banner-background"
          />
        )}
        {showOfferBanner && (
          <TravelWalletDetailsBanner
            offer={bestOffer}
            variant="default"
            screen={SelectedTravelOfferScreen.FLIGHT_REVIEW}
          />
        )}
        <Box
          className={clsx("fare-details-overlay-text", {
            "combination-flight-text": isMultiTicket,
          })}
        >
          <Typography className="title">
            {isMultiTicket
              ? constants.FARE_DETAILS_COMBINATION_FLIGHT_TITLE
              : constants.FARE_DETAILS_TITLE}
          </Typography>
          <Typography className="subtitle">
            {isMultiTicket
              ? constants.FARE_DETAILS_COMBINATION_FLIGHT_SUBTITLE
              : constants.FARE_DETAILS_SUBTITLE}
          </Typography>
        </Box>
      </Box>
      {!isMulticity && renderRestrictionSection()}
      {isMulticity && renderMulticityRestrictionsSection()}
      <Box className="flight-shop-fare-details-pricing-section">
        <Box className="price-text">
          <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
          <Typography className="content" variant="body1">
            {getPricesWithComma(totalCostText)}
            {rewardText && (
              <>
                <span className="separator">/</span>
                <span className="fare-reward">{rewardText}</span>
              </>
            )}
          </Typography>
        </Box>
        <Typography className="description-text">
          {constants.getTotalPriceDescription(tripCategory)}
        </Typography>
      </Box>
      <Box className="flight-shop-fare-details-sticky-row">
        <Box className="flight-shop-fare-details-button-section">
          <ActionButton
            className={clsx("continue-button", "b2b")}
            onClick={() => {
              if (isCfarEnabled) {
                if (isMulticity) {
                  setMulticityFlightShopProgress(
                    MulticityFlightShopStep.Customize
                  );
                } else {
                  setFlightShopProgress(FlightShopStep.Customize);
                }
              } else {
                populateFlightBookQueryParams({ history });
              }
            }}
            disabled={false}
            message={constants.CONTINUE_TEXT}
          />
        </Box>
      </Box>
    </Box>
  );
};
