import axios from "axios";
import { DeleteWatchAlertRequest, DeleteWatchAlertResponse } from "redmond";
import { airPriceWatchApi } from "@b2bportal/air-price-watch-api";

export const deleteWatch = (
  request: DeleteWatchAlertRequest
): Promise<DeleteWatchAlertResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airPriceWatchApi(
        axios as any
      ).apiV0AlertWatchDeletePost(request);
      resolve(res.data as any);
    } catch (e) {
      reject(e);
    }
  });
};
