import React from "react";
import { SliceStopCountFilter } from "redmond";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { ButtonWrap, Icon, IconName, RadioDropdown } from "halifax";
import { initialFilterOptions } from "../../../../../../reducer";
import { Box } from "@material-ui/core";
import {
  AIR_CX_V3_1_VARIANT_1,
  AirCXV3VariantType,
  CONTROL,
} from "../../../../../../../../context/experiments";

interface IStopsOptionSelectionProps {
  stopsOption: SliceStopCountFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => void;
  setRerunPrediction?: () => void;
  hasSetStopsOption?: boolean;
  showDropdownContentOnly?: boolean;
  appliedLabel?: string;
  isFlightListOptimizationExperiment?: boolean;
  icon?: IconName;
  title?: string;
  radioLabelPlacement?: "start" | "end";
  airCXV3Variant?: AirCXV3VariantType;
}

export const StopsOptionSelection = (props: IStopsOptionSelectionProps) => {
  const {
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    showDropdownContentOnly,
    hasSetStopsOption,
    appliedLabel,
    isFlightListOptimizationExperiment,
    icon,
    title,
    radioLabelPlacement,
    airCXV3Variant,
  } = props;

  const isAirCXV3Experiment = airCXV3Variant !== CONTROL;

  const handleChange = (value: string) => {
    setStopsOption(value as SliceStopCountFilter);
    if (!!setRerunPrediction && airCXV3Variant !== AIR_CX_V3_1_VARIANT_1) {
      setRerunPrediction();
    }
  };

  return (
    <Box className="flight-shop-stops-filter-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />} {title}
        </Box>
      )}
      <RadioDropdown
        dropdownLabel={textConstants.STOPS_HEADER_TEXT(
          hasSetStopsOption ? appliedLabel : undefined
        )}
        options={stopsOptions}
        setOption={handleChange}
        selected={stopsOption}
        showDropdownContentOnly={showDropdownContentOnly}
        buttonClassName={clsx("b2b-shop-filter", {
          "has-value": hasSetStopsOption,
        })}
        groupAriaLabel={textConstants.CHOOSE_STOPS_OPTION_TEXT}
        buttonAriaLabel={textConstants.CHANGE_STOPS_OPTION_TEXT}
        popoverClassName={clsx("b2b", "flight-availability-stops-popover", {
          "filter-experiment": isFlightListOptimizationExperiment,
        })}
        dropdownIcon={
          hasSetStopsOption && appliedLabel ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                handleChange(initialFilterOptions.stopsOption);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        anchorOrigin={(() => {
          switch (true) {
            case isAirCXV3Experiment:
              return {
                vertical: "bottom",
                horizontal: "left",
              };
            case isFlightListOptimizationExperiment:
              return {
                vertical: "bottom",
                horizontal: "right",
              };
            default:
              return undefined;
          }
        })()}
        transformOrigin={(() => {
          switch (true) {
            case isAirCXV3Experiment:
              return {
                vertical: "top",
                horizontal: "left",
              };
            case isFlightListOptimizationExperiment:
              return {
                vertical: "top",
                horizontal: "right",
              };
            default:
              return undefined;
          }
        })()}
        radioLabelPlacement={radioLabelPlacement}
      />
    </Box>
  );
};

interface IStopsOption {
  label: string;
  value: SliceStopCountFilter;
}

const stopsOptions: IStopsOption[] = [
  {
    label: textConstants.ANY_NUMBER_OF_STOPS_TEXT,
    value: SliceStopCountFilter.ANY_NUMBER,
  },
  {
    label: textConstants.NO_STOPS_ONLY,
    value: SliceStopCountFilter.NONE,
  },
  {
    label: textConstants.ONE_STOP_OR_FEWER,
    value: SliceStopCountFilter.ONE_OR_LESS,
  },
  {
    label: textConstants.TWO_STOPS_OR_FEWER,
    value: SliceStopCountFilter.TWO_OR_LESS,
  },
];
