import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getTripCategory,
  getOrigin,
  getDestination,
  getDepartureDate,
  getReturnDate,
  getMulticityRoutes,
} from "../../../search/reducer/selectors";
import { rebookDepartureDateTimeSelector } from "../../../ancillary/reducer/selectors";
import {
  flightShopProgressSelector,
  flightShopMulticityProgressSelector,
  openFlightShopCalendarMobileSelector,
  flightShopTypeSelector,
  getSortedAndFilteredFlights,
  isInDisruptionProtectionRebookSelector,
} from "../../reducer/selectors";
import {
  setFlightShopProgress,
  setOpenFlightShopCalendarMobile,
} from "../../actions/actions";
import { setMobileSearchProgress } from "../../../search/actions/actions";
import { FlightShopProgressHeader } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getTravelWalletCredit,
  getTravelWalletOffers,
} from "../../../travel-wallet/reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripCategory: getTripCategory(state),
  currentProgress: flightShopProgressSelector(state),
  currentMulticityProgress: flightShopMulticityProgressSelector(state),
  multicityRoutes: getMulticityRoutes(state),
  origin: getOrigin(state),
  destination: getDestination(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  flightShopType: flightShopTypeSelector(state),
  openFlightShopCalendarMobile: openFlightShopCalendarMobileSelector(state),
  flightList: getSortedAndFilteredFlights(state),
  isInDisruptionProtectionRebook: isInDisruptionProtectionRebookSelector(state),
  rebookDepartureDateTime: rebookDepartureDateTimeSelector(state),
  walletItemCount: getTravelWalletCredit(state)
    ? getTravelWalletOffers(state).length + 1
    : getTravelWalletOffers(state).length,
});

const mapDispatchToProps = {
  setFlightShopProgress,
  setMobileSearchProgress,
  setOpenFlightShopCalendarMobile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopProgressHeaderConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopProgressHeader = connector(
  withRouter(FlightShopProgressHeader)
);
