import React, { useContext, useState } from "react";
import { ContactInfoForm, emailRegex, useDeviceTypes } from "halifax";
import { EDIT_CONTACT } from "redmond";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import { FlightFreezeContactInfoFormConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import "./styles.scss";

export interface IFlightFreezeContactInfoFormProps
  extends RouteComponentProps,
    FlightFreezeContactInfoFormConnectorProps {
  title?: string;
  subtitle?: string;
  isDisableSave: boolean;
  setIsDisableSave: (isDisableSave: boolean) => void;
}

export const FlightFreezeContactInfoForm = ({
  contactInfo,
  setContactInfo,
  isDisableSave,
  setIsDisableSave,
  title = "",
  subtitle = "",
}: IFlightFreezeContactInfoFormProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo } = clientContext;
  const { matchesMobile } = useDeviceTypes();
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEmailPopulated, setIsEmailPopulated] = useState(false);
  const [isEmailFieldDisabled, setIsEmailFieldDisabled] =
    useState(isDisableSave);
  const [contactEmail, setContactEmail] = useState<string>(
    sessionInfo?.userInfo.email ?? ""
  );

  // note: when userInfo email is empty and the email state hasn't been populated, showInitialActions is true
  const showInitialActions = React.useMemo(
    () =>
      !sessionInfo?.userInfo.email &&
      !emailRegex.test(contactInfo.email) &&
      !isEmailPopulated,
    [isEmailPopulated]
  );

  React.useEffect(() => {
    setIsDisableSave(!emailRegex.test(contactInfo.email));
  }, [contactInfo]);

  React.useEffect(() => {
    setIsEmailFieldDisabled(!emailRegex.test(contactEmail));
  }, [contactEmail]);

  React.useEffect(() => {
    if (emailRegex.test(contactInfo.email)) {
      setIsEmailPopulated(true);
    }
  }, [contactInfo]);

  React.useEffect(() => {
    // note: when it's showing initial actions, and the user is not editing the email, simulate a click on "edit"
    if (
      showInitialActions &&
      !isEditingEmail &&
      !emailRegex.test(contactEmail)
    ) {
      handleEdit();
    }
  }, [showInitialActions, isEditingEmail, contactEmail]);

  const handleEdit = () => {
    setIsEditingEmail(true);
  };

  const handleBlur = () => {
    setIsEditingEmail(false);
  };

  const handleSave = () => {
    if (!isEmailFieldDisabled) {
      setContactInfo(contactEmail, "");
    }
    handleBlur();
  };

  const getActions = () => {
    const initialActions = [
      {
        content: textConstants.SAVE_TEXT,
        disabled: false,
        onClick: handleSave,
      },
    ];
    const regularActions = [
      {
        content: textConstants.EDIT_EMAIL_TEXT,
        onClick: handleEdit,
        showOnEdit: false,
      },
      {
        content: textConstants.SAVE_TEXT,
        showOnEdit: true,
        disabled: isEmailFieldDisabled,
        onClick: handleSave,
      },
      {
        content: textConstants.CANCEL_TEXT,
        showOnEdit: true,
        linkClassName: "cancel-action",
        onClick: () => {
          setContactEmail(contactInfo.email);
          handleBlur();
        },
      },
    ];
    return !isEmailPopulated
      ? initialActions
      : regularActions.filter((a) => isEditingEmail === a.showOnEdit);
  };

  return (
    <ContactInfoForm
      className={clsx("flight-freeze-contact-info-form-root", {
        mobile: matchesMobile,
      })}
      title={title}
      headerVariant={matchesMobile ? "caption" : "h2"}
      subtitle={subtitle}
      contactInfo={{
        phoneNumber: "",
        email: contactEmail,
      }}
      hidePhoneNumber
      isMobile={matchesMobile}
      disabled={!isEditingEmail}
      actions={getActions()}
      onContactInfoChange={(contactInfo) => {
        setContactEmail(contactInfo.email);
        if (contactInfo.email && emailRegex.test(contactInfo.email)) {
          trackEvent({
            eventName: EDIT_CONTACT,
            properties: {},
          });
        }
      }}
    />
  );
};
