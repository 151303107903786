export const GET_PRICE_FREEZE_OFFER = "flightFreeze/GET_PRICE_FREEZE_OFFER";
export type GET_PRICE_FREEZE_OFFER = typeof GET_PRICE_FREEZE_OFFER;

export const SET_PRICE_FREEZE_OFFER = "flightFreeze/SET_PRICE_FREEZE_OFFER";
export type SET_PRICE_FREEZE_OFFER = typeof SET_PRICE_FREEZE_OFFER;

export const SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED =
  "flightFreeze/SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED";
export type SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED =
  typeof SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED;

export const SET_UP_FLIGHT_FREEZE_PARAMS =
  "flightFreeze/SET_UP_FLIGHT_FREEZE_PARAMS";
export type SET_UP_FLIGHT_FREEZE_PARAMS = typeof SET_UP_FLIGHT_FREEZE_PARAMS;

export const SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED =
  "flightFreeze/SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED";
export type SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED =
  typeof SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED;

export const SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED =
  "flightFreeze/SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED";
export type SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED =
  typeof SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED;

export const SET_PASSENGER_COUNTS = "flightFreeze/SET_PASSENGER_COUNTS";
export type SET_PASSENGER_COUNTS = typeof SET_PASSENGER_COUNTS;

export const FETCH_PRICE_FREEZE = "flightFreeze/FETCH_PRICE_FREEZE";
export type FETCH_PRICE_FREEZE = typeof FETCH_PRICE_FREEZE;

export const GET_PRICE_FREEZE = "flightFreeze/GET_PRICE_FREEZE";
export type GET_PRICE_FREEZE = typeof GET_PRICE_FREEZE;

export const SET_PRICE_FREEZE = "flightFreeze/SET_PRICE_FREEZE";
export type SET_PRICE_FREEZE = typeof SET_PRICE_FREEZE;

export const SET_PRICE_FREEZE_CALL_STATE_FAILED =
  "flightFreeze/SET_PRICE_FREEZE_CALL_STATE_FAILED";
export type SET_PRICE_FREEZE_CALL_STATE_FAILED =
  typeof SET_PRICE_FREEZE_CALL_STATE_FAILED;

export const SET_PRICE_FREEZE_QUOTE_DATA =
  "flightFreeze/SET_PRICE_FREEZE_QUOTE_DATA";
export type SET_PRICE_FREEZE_QUOTE_DATA = typeof SET_PRICE_FREEZE_QUOTE_DATA;

export const REFUND_PRICE_FREEZE = "flightFreeze/REFUND_PRICE_FREEZE";
export type REFUND_PRICE_FREEZE = typeof REFUND_PRICE_FREEZE;

export const SET_REFUND_PRICE_FREEZE = "flightFreeze/SET_REFUND_PRICE_FREEZE";
export type SET_REFUND_PRICE_FREEZE = typeof SET_REFUND_PRICE_FREEZE;

export const DISPLAY_PF_REFUND_MODAL_FLOW =
  "flightFreeze/DISPLAY_PF_REFUND_MODAL_FLOW";
export type DISPLAY_PF_REFUND_MODAL_FLOW = typeof DISPLAY_PF_REFUND_MODAL_FLOW;

export const RESET_PRICE_FREEZE_PURCHASE =
  "flightFreeze/RESET_PRICE_FREEZE_PURCHASE";
export type RESET_PRICE_FREEZE_PURCHASE = typeof RESET_PRICE_FREEZE_PURCHASE;

export const GET_PRICE_FREEZE_OFFER_DATA =
  "flightFreeze/GET_PRICE_FREEZE_OFFER_DATA";
export type GET_PRICE_FREEZE_OFFER_DATA = typeof GET_PRICE_FREEZE_OFFER_DATA;

export const SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED =
  "flightFreeze/SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED";
export type SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED =
  typeof SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED;

export const SET_PRICE_FREEZE_OFFER_DATA =
  "flightFreeze/SET_PRICE_FREEZE_OFFER_DATA";
export type SET_PRICE_FREEZE_OFFER_DATA = typeof SET_PRICE_FREEZE_OFFER_DATA;

export const SET_PRICE_FREEZE_OFFER_RADIO_DURATIONS =
  "flightFreeze/SET_PRICE_FREEZE_OFFER_RADIO_DURATIONS";
export type SET_PRICE_FREEZE_OFFER_RADIO_DURATIONS =
  typeof SET_PRICE_FREEZE_OFFER_RADIO_DURATIONS;

export const SET_SELECTED_PRICE_FREEZE_RADIO_INDEX =
  "flightFreeze/SET_SELECTED_PRICE_FREEZE_RADIO_INDEX";
export type SET_SELECTED_PRICE_FREEZE_RADIO_INDEX =
  typeof SET_SELECTED_PRICE_FREEZE_RADIO_INDEX;

export const SET_INITIAL_SELECTED_PRICE_FREEZE_RADIO_INDEX =
  "flightFreeze/SET_INITIAL_SELECTED_PRICE_FREEZE_RADIO_INDEX";
export type SET_INITIAL_SELECTED_PRICE_FREEZE_RADIO_INDEX =
  typeof SET_INITIAL_SELECTED_PRICE_FREEZE_RADIO_INDEX;

export const SET_SELECTED_PRICE_FREEZE_OFFER_DATA_INDEX =
  "flightFreeze/SET_SELECTED_PRICE_FREEZE_OFFER_DATA_INDEX";
export type SET_SELECTED_PRICE_FREEZE_OFFER_DATA_INDEX =
  typeof SET_SELECTED_PRICE_FREEZE_OFFER_DATA_INDEX;

export const GENERATE_CUSTOM_PRICE_FREEZE_OFFER =
  "flightFreeze/GENERATE_CUSTOM_PRICE_FREEZE_OFFER";
export type GENERATE_CUSTOM_PRICE_FREEZE_OFFER =
  typeof GENERATE_CUSTOM_PRICE_FREEZE_OFFER;

export const SET_CHEAPEST_ELIGIBLE_FLIGHT_CUSTOM_PRICE_FREEZE_OFFER =
  "flightFreeze/SET_CHEAPEST_ELIGIBLE_FLIGHT_CUSTOM_PRICE_FREEZE_OFFER";
export type SET_CHEAPEST_ELIGIBLE_FLIGHT_CUSTOM_PRICE_FREEZE_OFFER =
  typeof SET_CHEAPEST_ELIGIBLE_FLIGHT_CUSTOM_PRICE_FREEZE_OFFER;

export const SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_SUCCESS =
  "flightFreeze/SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_SUCCESS";
export type SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_SUCCESS =
  typeof SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_SUCCESS;

export const SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED =
  "flightFreeze/SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED";
export type SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED =
  typeof SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED;

export const SET_CUSTOM_PRICE_FREEZE_OFFER =
  "flightFreeze/SET_CUSTOM_PRICE_FREEZE_OFFER";
export type SET_CUSTOM_PRICE_FREEZE_OFFER =
  typeof SET_CUSTOM_PRICE_FREEZE_OFFER;

export const SET_INITIAL_PRICE_FREEZE_OFFER_DATA_WITH_REWARDS =
  "flightFreeze/SET_INITIAL_PRICE_FREEZE_OFFER_DATA_WITH_REWARDS";
export type SET_INITIAL_PRICE_FREEZE_OFFER_DATA_WITH_REWARDS =
  typeof SET_INITIAL_PRICE_FREEZE_OFFER_DATA_WITH_REWARDS;

export const INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER =
  "flightFreeze/INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER";
export type INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER =
  typeof INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER;

export const FETCH_PRICE_FREEZE_FARE_QUOTE =
  "flightFreeze/FETCH_PRICE_FREEZE_FARE_QUOTE";
export type FETCH_PRICE_FREEZE_FARE_QUOTE =
  typeof FETCH_PRICE_FREEZE_FARE_QUOTE;

export const FETCH_PRICE_FREEZE_FARE_QUOTE_STATE_RESET =
  "flightFreeze/FETCH_PRICE_FREEZE_FARE_QUOTE_STATE_RESET";
export type FETCH_PRICE_FREEZE_FARE_QUOTE_STATE_RESET =
  typeof FETCH_PRICE_FREEZE_FARE_QUOTE_STATE_RESET;

export const FETCH_PRICE_FREEZE_FARE_QUOTE_CANCELLED =
  "flightFreeze/FETCH_PRICE_FREEZE_FARE_QUOTE_CANCELLED";
export type FETCH_PRICE_FREEZE_FARE_QUOTE_CANCELLED =
  typeof FETCH_PRICE_FREEZE_FARE_QUOTE_CANCELLED;

export const SET_PRICE_FREEZE_FARE_QUOTE_CALL_STATE_FAILED =
  "flightFreeze/SET_PRICE_FREEZE_FARE_QUOTE_CALL_STATE_FAILED";
export type SET_PRICE_FREEZE_FARE_QUOTE_CALL_STATE_FAILED =
  typeof SET_PRICE_FREEZE_FARE_QUOTE_CALL_STATE_FAILED;

export const SET_PRICE_FREEZE_FARE_QUOTE =
  "flightFreeze/SET_PRICE_FREEZE_FARE_QUOTE";
export type SET_PRICE_FREEZE_FARE_QUOTE = typeof SET_PRICE_FREEZE_FARE_QUOTE;

export const SET_PRICE_FREEZE_FARE_QUOTE_ERROR_TITLES_TYPE =
  "flightFreeze/SET_PRICE_FREEZE_FARE_QUOTE_ERROR_TITLES_TYPE";
export type SET_PRICE_FREEZE_FARE_QUOTE_ERROR_TITLES_TYPE =
  typeof SET_PRICE_FREEZE_FARE_QUOTE_ERROR_TITLES_TYPE;

export const ACKNOWLEDGE_PRICE_FREEZE_FARE_QUOTE_DETAILS =
  "flightFreeze/ACKNOWLEDGE_PRICE_FREEZE_FARE_QUOTE_DETAILS";
export type ACKNOWLEDGE_PRICE_FREEZE_FARE_QUOTE_DETAILS =
  typeof ACKNOWLEDGE_PRICE_FREEZE_FARE_QUOTE_DETAILS;

export const SET_PRICE_FREEZE_EXPERIMENTS =
  "flightFreeze/SET_PRICE_FREEZE_EXPERIMENTS";
export type SET_PRICE_FREEZE_EXPERIMENTS = typeof SET_PRICE_FREEZE_EXPERIMENTS;

export const SET_PRICE_FREEZE_ENTRY_POINT_STEP =
  "flightFreeze/SET_PRICE_FREEZE_ENTRY_POINT_STEP";
export type SET_PRICE_FREEZE_ENTRY_POINT_STEP =
  typeof SET_PRICE_FREEZE_ENTRY_POINT_STEP;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  "flightFreeze/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  "flightFreeze/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  "flightFreeze/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE;

export const SET_TRAVEL_WALLET_CREDIT = "flightFreeze/SET_TRAVEL_WALLET_CREDIT";
export type SET_TRAVEL_WALLET_CREDIT = typeof SET_TRAVEL_WALLET_CREDIT;

export const SET_CREDIT_TO_APPLY = "flightFreeze/SET_CREDIT_TO_APPLY";
export type SET_CREDIT_TO_APPLY = typeof SET_CREDIT_TO_APPLY;
