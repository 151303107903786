import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../reducers/types";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../rewards/reducer";
import { FlightToHotelCrossSell } from "./component";
import {
  getAvailableCrossSellHotels,
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getSearchedNightCount,
  getHotelShopParams,
  getSortedLodgingsByPricing,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getHotelEntryProperties,
  getViewedHotelListProperties,
  getCrossSellLatency,
} from "./reducer/selectors";
import { withRouter } from "react-router";
import { setSelectedLodgingIndex } from "./actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  latency: getCrossSellLatency(state),
  lodgings: getAvailableCrossSellHotels(state),
  searchLocationResult: getHotelAvailabilitySearchLocationResult(state),
  fromDate: getHotelAvailabilityFromDate(state),
  untilDate: getHotelAvailabilityUntilDate(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  nightCount: getSearchedNightCount(state),
  isVentureX: !!getRewardsAccounts(state).find((acc) =>
    acc.productDisplayName.includes("Venture X")
  ),
  hotelShopParams: getHotelShopParams(state),
  sortedLodgings: getSortedLodgingsByPricing(state),
  adultsCount: getHotelAvailabilityAdultsCount(state),
  children: getHotelAvailabilityChildren(state),
  viewedHotelListProperties: getViewedHotelListProperties(state),
  hotelEntryProperties: getHotelEntryProperties(state),
});

const mapDispatchToProps = {
  setSelectedLodgingIndex,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightToHotelCrossSellConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightToHotelCrossSell = connector(
  withRouter(FlightToHotelCrossSell)
);
