import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { startFareQuoteApiPrefix } from "../paths";
import { StartFareQuoteResult } from "redmond";

export const startFareQuote = (): Promise<StartFareQuoteResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<StartFareQuoteResult> = await axios.post(
        startFareQuoteApiPrefix,
        {},
        {
          baseURL: config.baseURL,
        }
      );

      resolve((res.data as any).value);
    } catch (e) {
      reject(e);
    }
  });

export default startFareQuote;
