import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { fetchTripPricing } from "../../actions/actions";
import { PriceBreakdown } from "./component";

import {
  currencySelector,
  priceQuoteLineItemsSelector,
  pricingParamsSelector,
  priceQuoteSummaryLineItemsSelector,
  getTripPricingCallState,
  totalTripPricingAncillaries,
} from "../../reducer";
import {
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer";
import { cfarOfferTextSelector } from "../../reducer/selectors/cfar";
import { containsSelfTransferLayoverFareSegment } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = containsSelfTransferLayoverFareSegment(
    tripDetails.fareDetails
  );

  return {
    tripPricingCallState: getTripPricingCallState(state),
    pricingParams: pricingParamsSelector(state),
    currency: currencySelector(state),
    lineItems: priceQuoteLineItemsSelector(state),
    summaryLineItems: priceQuoteSummaryLineItemsSelector(state),
    isVITripSelected: isVITripSelected,
    cfarOfferText: cfarOfferTextSelector(state),
    ancillaries: totalTripPricingAncillaries(state),
  };
};

const mapDispatchToProps = {
  fetchTripPricing,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceBreakdown = withRouter(connector(PriceBreakdown));
