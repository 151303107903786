import { connect, ConnectedProps } from "react-redux";
import {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
  deleteUserPassenger,
  fetchUserPassengers,
  validatePassengers,
  setInfantOnlyError,
  setQuote,
  fetchCorpUserPassengers,
} from "../../actions/actions";
import {
  getUserPassengerCallState,
  getUserPassengers,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
  getPriceQuoteErrors,
  getInfantOnlyError,
  getPriceQuoteWithUpdatedAncillary,
  getTripFinalFlightDate,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { FlightBookPassengerSelection } from "./component";
import { getViewedTripSummaryProperties } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
    selectedTravelerIds: getUserSelectedPassengerIds(state),
    selectedLapInfantIds: getUserSelectedLapInfantIds(state),
    userPassengerCallState: getUserPassengerCallState(state),
    priceQuoteErrors: getPriceQuoteErrors(state),
    infantOnlyError: getInfantOnlyError(state),
    priceQuote: getPriceQuoteWithUpdatedAncillary(state),
    tripFinalFlightDate: getTripFinalFlightDate(state),
    viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
  deleteUserPassenger,
  fetchUserPassengers,
  validatePassengers,
  setInfantOnlyError,
  setQuote,
  fetchCorpUserPassengers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightBookPassengerSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightBookPassengerSelection = connector(
  FlightBookPassengerSelection
);
