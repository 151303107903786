import React from "react";
import {
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import { REFUNDABLE_FARES_ONLY_IN_RETURN_FLIGHTS_BANNER } from "redmond";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { RefundableFareOptionsBannerConnectorProps } from "./container";
import "./styles.scss";

export interface IRefundableFareOptionsBannerProps
  extends RefundableFareOptionsBannerConnectorProps,
    RouteComponentProps {}

export const RefundableFareOptionsBanner = (
  props: IRefundableFareOptionsBannerProps
) => {
  const { cfarOffer } = props;
  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      className={clsx("refundable-fare-options-banner-root", {
        mobile: matchesMobile,
      })}
    >
      <NotificationBanner
        className="refundable-fare-options-banner"
        label={REFUNDABLE_FARES_ONLY_IN_RETURN_FLIGHTS_BANNER(
          cfarOffer?.policyData?.cashCoveragePercentage
        )}
        severity={BannerSeverity.NOTICE}
        icon={<Icon className="banner-icon" name={IconName.InfoCircle} />}
      />
    </Box>
  );
};
