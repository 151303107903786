import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { ancillaryPostBookingOfferPurchasePath } from "../paths";
import {
  AncillaryPostBookingPurchaseRequestScheduler,
  AncillaryPostBookingPurchaseResponse,
} from "redmond";

export const purchasePostBookingOffer = (
  body: AncillaryPostBookingPurchaseRequestScheduler
): Promise<AncillaryPostBookingPurchaseResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<AncillaryPostBookingPurchaseResponse> =
        await axios.post(ancillaryPostBookingOfferPurchasePath, body, {
          baseURL: config.baseURL,
        });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default purchasePostBookingOffer;
