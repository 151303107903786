export const NEXT_TEXT = "Next";
export const WHERE_FROM = "Where from?";
export const WHERE_TO = "Where to?";
export const ADD_FLIGHT_BUTTON_TEXT = "+ Add flight";
export const REMOVE_FLIGHT_BUTTON_TEXT = "Remove flight";
export const CONTINUE = "Continue";
export const SEARCH_FLIGHTS = "Search Flights";
export const MAX_ROUTES_MESSAGE =
  "You’ve reached the maximum number of multi-city flights on a single booking.";
export const OUTBOUND = "Outbound";
export const OUTBOUND_DATE = "Outbound date";
export const OUTBOUND_TO = "Outbound to";
export const OUTBOUND_FROM = "Outbound from";
export const CHOOSE_OUTBOUND_DATE = "Choose outbound date";
export const MISSING_ROUTE_FIELDS_ERROR =
  "Please complete all required fields.";
export const UPDATE_CALENDAR_TEXT = "Updating your calendar";
