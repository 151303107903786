import React from "react";
import { DatePickerButton } from "halifax";
import { ConnectedCalenderPickerButtonProps } from "./container";
import "./styles.scss";
import { TripCategory } from "redmond";
import { DesktopCalendarPicker } from "..";
import { ISetDepartureDate, ISetReturnDate } from "../../../../actions/actions";

export interface ICalenderPickerButtonProps
  extends ConnectedCalenderPickerButtonProps {
  classes?: string[];
  onClick?: () => void;
  disabled?: boolean;
  saveDatesOnClose?: boolean;
  hasMissingSearchInfoError?: boolean;
  tripCategory: TripCategory;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate: (date: Date | null) => ISetDepartureDate | void;
  setReturnDate: (date: Date | null) => ISetReturnDate | void;
}

export const CalenderPickerButton = ({
  classes = [],
  departureDate,
  returnDate,
  tripCategory,
  openDatesModal,
  setOpenDatesModal,
  disabled,
  saveDatesOnClose,
  hasMissingSearchInfoError,
  setDepartureDate,
  setReturnDate,
}: ICalenderPickerButtonProps) => {
  const onClick = () => {
    if (!disabled) {
      setOpenDatesModal(true);
    }
  };

  return (
    <DatePickerButton
      startDate={departureDate}
      startLabel={"Departure"}
      endDate={returnDate}
      endLabel={"Return"}
      classes={classes}
      renderCalendarPopup={(props) => (
        <DesktopCalendarPicker
          {...props}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          open={openDatesModal || props?.open}
          closePopup={() => {
            setOpenDatesModal(false);
            props?.closePopup?.();
          }}
          tripCategory={tripCategory}
          saveDatesOnClose={saveDatesOnClose}
        />
      )}
      onClick={onClick}
      dateFormat={"ddd MMM D"}
      hideEndDate={tripCategory === TripCategory.ONE_WAY}
      hasMissingSearchInfoError={hasMissingSearchInfoError}
    />
  );
};
