import { put, select, putResolve, call } from "redux-saga/effects";

import { actions as searchActions } from "../../search/actions";
import { fetchTripSummaries as fetchSummaries } from "../../../api/v0/shop/fetchTripSummaries";
import Logger from "../../../helpers/Logger";
import { IShopParams } from "../actions/actions";
import { actions } from "../actions";
import {
  getAirEntryProperties,
  getPriceFreezeOfferCheapestTripTripId,
} from "../reducer/selectors";
import { setUpFlightShopParams } from "./utils/setUpFlightShopParams";
import { AirEntryProperties, AIR_ENTRY } from "redmond";
import dayjs from "dayjs";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { ShopSummaryResponse } from "@b2bportal/air-shopping-api";

export function* fetchTripSummaries(action: actions.IFetchTripSummaries) {
  try {
    const {
      departureDate,
      returnDate,
      origin,
      destination,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    } = yield call(setUpFlightShopParams, action);

    const requestBody: IShopParams = {
      origin: { ...origin.id.code },
      destination: { ...destination.id.code },
      ...(returnDate && {
        returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter: {
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      },
    };
    const response: ShopSummaryResponse = yield fetchSummaries(
      requestBody,
      action.isMobile
    );

    const properties: AirEntryProperties | null = yield select(
      getAirEntryProperties
    );
    yield trackEvent({
      eventName: AIR_ENTRY,
      properties,
    });

    // note: the order does matter here! having redux set priceFreezeOffer first will allow it to update priceFreezeOffer
    // related selectors prior to that of the prediction
    // TODO change to using correct types
    yield putResolve(actions.setTripSummaries(response.tripSummaries as any));
    yield putResolve(
      actions.setPriceFreezeOffer(response.priceFreezeOffer as any)
    );
    yield putResolve(actions.setPrediction(response.prediction as any));

    const cheapestTripTripId: string = yield select(
      getPriceFreezeOfferCheapestTripTripId
    );
    if (cheapestTripTripId) {
      yield put(actions.fetchTripDetails({ tripId: cheapestTripTripId }));
    }

    yield put(searchActions.setAwaitingRefetch(false));
  } catch (e) {
    yield put(actions.setTripSummariesError());
    yield put(actions.setPredictionError());
    Logger.debug(e);
  }
}
