import { put } from "redux-saga/effects";
import {
  CallState,
  GetPriceFreezeResponse,
  GetPriceFreezeRequestEnum,
} from "redmond";

import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { getPriceFreeze } from "../../../api/v0/price-freeze/getPriceFreeze";

export function* fetchPriceFreezeSaga(action: actions.IFetchPriceFreeze) {
  try {
    const priceFreeze: GetPriceFreezeResponse = yield getPriceFreeze({
      GetPriceFreezeRequest: GetPriceFreezeRequestEnum.ByPriceFreezeId,
      id: action.priceFreezeId,
    });

    yield put(
      actions.setPriceFreeze({
        priceFreeze,
        priceFreezeCallState: CallState.Success,
      })
    );
  } catch (e) {
    // TODO: consume this state in ErrorModal
    yield put(actions.setPriceFreezeCallStateFailed());
    Logger.debug(e);
  }
}
