import React from "react";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import { FlightRatingsEnum, FlightShopType } from "redmond";
import clsx from "clsx";
import { isEqual } from "lodash-es";
import * as constants from "./textConstants";
import { FareClassOptionSelectionConnectorProps } from "./container";
import "./styles.scss";
import {
  ButtonWrap,
  FareClassInfoModal,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import { noLCCFareclassOptionFilter } from "../../../../../search/reducer";
import {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../../../context/experiments";

interface FareClassOptionSelectionProps
  extends FareClassOptionSelectionConnectorProps {
  isFlightListOptimizationExperiment?: boolean;
  isAirCXV4Experiment?: boolean;
}

export const FareClassOptionSelection = ({
  flightShopType,
  fareClassOptionFilter,
  isInDisruptionProtectionRebook,
  disruptionProtectionOriginalSliceFareRating,
  setFareClassOptions,
  setRerunPrediction,
  isFlightListOptimizationExperiment,
  isAirCXV4Experiment,
}: FareClassOptionSelectionProps) => {
  const [fareclassInfoModalOpen, setFareclassInfoModalOpen] =
    React.useState<boolean>(false);

  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();
  const matchesMediumDesktopOnly =
    matchesDesktop && (!matchesLargeDesktop || isInDisruptionProtectionRebook);

  const { experiments } = useExperiments();

  const globalMobileNavExperimentVariant = getExperimentVariant(
    experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const hasSelectedFareClass = Object.values(fareClassOptionFilter).includes(
    true
  );
  const handleClearFilter = () => {
    let rerunFlag = false;
    if (isEqual(fareClassOptionFilter, noLCCFareclassOptionFilter)) {
      rerunFlag = true;
    }
    for (const fare in fareClassOptionFilter) {
      fareClassOptionFilter[fare] = false;
    }
    setFareClassOptions(fareClassOptionFilter);
    if (rerunFlag) {
      setRerunPrediction();
    }
  };

  const handleSetOption = (fareClass: string) => {
    let rerunFlag = false;
    if (matchesMobile) {
      if (isEqual(fareClassOptionFilter, noLCCFareclassOptionFilter)) {
        rerunFlag = true;
      }
      for (const fare in fareClassOptionFilter) {
        if (fare === fareClass) fareClassOptionFilter[fare] = true;
        else fareClassOptionFilter[fare] = false;
      }
    } else {
      // Check if old fareClassOptionFilter is equal to noLCCFareclassOptionFilter - necessary to run setRerunPrediction() when changing from noLCCFareclassOptionFilter state
      if (isEqual(fareClassOptionFilter, noLCCFareclassOptionFilter)) {
        rerunFlag = true;
      }
      fareClassOptionFilter[fareClass] = !fareClassOptionFilter[fareClass];
      // Check if new fareClassOptionFilter is equal to noLCCFareclassOptionFilter after update above - necessary to run setRerunPrediction() when entering noLCCFareclassOptionFilter state
      if (isEqual(fareClassOptionFilter, noLCCFareclassOptionFilter)) {
        rerunFlag = true;
      }
    }
    setFareClassOptions(fareClassOptionFilter, "list");
    if (rerunFlag) {
      setRerunPrediction();
    }
  };

  return (
    <Box
      className={clsx("fare-class-filter-container", {
        mobile: matchesMobile,
        "medium-desktop": matchesMediumDesktopOnly,
        "filter-experiment": isFlightListOptimizationExperiment,
        "global-mobile-nav": isGlobalMobileNavExperiment,
        "air-cx-v4": isAirCXV4Experiment,
      })}
    >
      {matchesMobile && (
        <Box
          key={"all"}
          className={clsx("fare-class-filter", {
            selected: matchesMobile && !hasSelectedFareClass,
          })}
          onClick={() => handleClearFilter()}
        >
          <Button variant="outlined">All</Button>
        </Box>
      )}
      {Object.keys(constants.FARE_CLASSES).map((fareClass) => {
        const isDisabled = (() => {
          switch (flightShopType) {
            case FlightShopType.DISRUPTION_PROTECTION_EXERCISE:
              return (
                disruptionProtectionOriginalSliceFareRating !== undefined &&
                disruptionProtectionOriginalSliceFareRating <
                  FlightRatingsEnum[fareClass.toLocaleLowerCase()]
              );
            default:
              return false;
          }
        })();

        return (
          <Box
            className={clsx("fare-class-filter", {
              selected:
                !isFlightListOptimizationExperiment &&
                fareClassOptionFilter[fareClass],
              disabled: isDisabled,
            })}
            id={`faretype-${fareClass}`}
            key={fareClass}
          >
            {isAirCXV4Experiment && !matchesMobile ? (
              <ButtonWrap
                className="fare-class-info-modal-button-wrap"
                onClick={() => setFareclassInfoModalOpen(true)}
                aria-label="Fare class info modal button"
              >
                <Icon
                  name={`${constants.FARE_CLASSES[
                    fareClass
                  ].toLowerCase()}-fare-class-seat-outline`}
                />
                <Typography className="fare-class-label">
                  {constants.FARE_CLASSES[fareClass]}
                </Typography>
                <Icon name={IconName.InfoCircle} />
              </ButtonWrap>
            ) : (
              <Tooltip
                tabIndex={0}
                key={fareClass}
                classes={{ tooltip: "fare-type-tooltip" }}
                placement="top"
                title={
                  matchesMobile ? (
                    ""
                  ) : (
                    <>
                      <Typography variant="h6" className="fareclass-tooltip">
                        {constants.FARE_CLASSES[fareClass]} Fares
                      </Typography>
                      <Typography
                        variant="caption"
                        className="fareclass-tooltip"
                      >
                        {constants.FARE_CLASS_DETAILS[fareClass]}
                      </Typography>
                    </>
                  )
                }
                id={fareClass}
                enterDelay={500}
                enterNextDelay={500}
              >
                <Button
                  variant="outlined"
                  onClick={() =>
                    !isFlightListOptimizationExperiment &&
                    handleSetOption(fareClass)
                  }
                  aria-pressed={Boolean(fareClassOptionFilter[fareClass])}
                  aria-describedby={fareClass}
                  disabled={isDisabled}
                  disableRipple={isFlightListOptimizationExperiment}
                >
                  {constants.FARE_CLASSES[fareClass]}
                </Button>
              </Tooltip>
            )}
          </Box>
        );
      })}
      {isAirCXV4Experiment && !matchesMobile && (
        <FareClassInfoModal
          open={fareclassInfoModalOpen}
          setOpen={setFareclassInfoModalOpen}
          isMobile={matchesMobile}
        />
      )}
    </Box>
  );
};
