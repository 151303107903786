import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PaymentCard } from "./component";
import {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchProductToEarn,
} from "../../actions/actions";
import {
  getRewardsPaymentAccount,
  getDeletePaymentMethodCallState,
  getListPaymentMethodsCallState,
  getPaymentMethods,
  getSelectedPaymentMethodId,
  getVerifyPaymentMethodCallState,
  getTotalCreditCardPaymentRequired,
  getIsCreditCardPaymentRequired,
  getRewardsPaymentAccountReferenceId,
  getCheckoutStepNumberSelector,
  getHasError,
  getIsStackedTravelWalletPaymentOnly,
  getIsTravelWalletCreditPaymentOnly,
  getIsTravelWalletOfferPaymentOnly,
  getCredit,
  getOffers,
} from "../../reducer";
import {
  getRewardsAccounts,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import { fetchRewardsAccounts } from "../../../rewards/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { getIsTravelWalletCreditPaymentOnly as getPriceFreezeIsTravelCreditOnlyPayment } from "../../../freeze/reducer";
import {
  getAllowRewardsWithPolicy,
  getAllowTravelWalletWithPolicy,
  getViewedTripSummaryProperties,
} from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const offers = getOffers(state);
  const hasOffersOrCredts =
    (offers && offers.length > 0) || Boolean(getCredit(state));
  return {
    paymentMethods: getPaymentMethods(state),
    rewardsAccounts: getRewardsAccounts(state),
    selectedPaymentMethodId: getSelectedPaymentMethodId(state) ?? "",
    listPaymentMethodCallState: getListPaymentMethodsCallState(state),
    verifyPaymentMethodCallState: getVerifyPaymentMethodCallState(state),
    deletePaymentMethodCallState: getDeletePaymentMethodCallState(state),
    selectedRewardsPaymentAccount: getRewardsPaymentAccount(state),
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    isCreditCardPaymentRequired: getIsCreditCardPaymentRequired(state),
    totalCreditCardPaymentRequired: getTotalCreditCardPaymentRequired(state),
    getCheckoutStepNumber: getCheckoutStepNumberSelector(state),
    hasError: getHasError(state),
    isStackedTravelWalletPaymentOnly:
      getIsStackedTravelWalletPaymentOnly(state),
    isTravelCreditPaymentOnly: getIsTravelWalletCreditPaymentOnly(state),
    isTravelWalletOfferPaymentOnly: getIsTravelWalletOfferPaymentOnly(state),
    canRedeemRewards: getAllowRewardsWithPolicy(state),
    userIsPrimary: getUserIsPrimaryCardHolder(state),
    viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
    canUseTravelWallet:
      getAllowTravelWalletWithPolicy(state) && hasOffersOrCredts,
    isPriceFreezeTravelCreditPaymentOnly: getPriceFreezeIsTravelCreditOnlyPayment(state),
  };
};

const mapDispatchToProps = {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PaymentCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPaymentCard = withRouter(connector(PaymentCard));
