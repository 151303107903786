import { FiatPrice, RewardsPrice, TimeToLive } from "redmond";
import { getTotalPriceText, getRewardText, getPriceString } from "halifax";
import { getDurationText, getDurationTextFromSeconds, getPriceFreezeDefaultDurationEndText } from "../../constants";
import { convertSecondsToDays } from "../../../freeze/utils";
import { getPriceFreezeRewardsString } from "../.././utils/helpers";

export const LOADING_PRICE_FREEZE = (useLockPriceLanguage?: boolean) =>
  `Loading Price ${useLockPriceLanguage ? "Lock" : "Freeze"}`;
export const PRICE_FREEZE_INFO_HEADER = (useLockPriceLanguage?: boolean) =>
  useLockPriceLanguage ? "Lock this price" : "Freeze the price, book later";
export const PRICE_FREEZE_INFO_HEADER_TITLE_CASE = (
  useLockPriceLanguage?: boolean
) =>
  useLockPriceLanguage
    ? "<strong>Lock This Price</strong>"
    : "<strong>Freeze the price</strong>, book later.";
export const PRICE_FREEZE_INFO_DETAILS_PREFIX = "Not ready to book? ";
export const PRICE_FREEZE_INFO_DETAILS = (
  price: string,
  rewards: string,
  timeToLive: TimeToLive | undefined,
  highlightedRewards?: boolean,
  useLockPriceLanguage?: boolean
) => {
  if (useLockPriceLanguage) {
    return `Lock this price today for <strong>${price}</strong>${rewards ? " or " : " "
      }${highlightedRewards ? `<strong>${rewards}</strong>` : rewards
      } per traveler and come back within ${timeToLive ? getDurationText(timeToLive) : ""
      } to complete your purchase.`;
  }
  return `Pay a fee of <strong>${price}</strong>${rewards ? " or " : " "}${highlightedRewards ? `<strong>${rewards}</strong>` : rewards
    } per traveler to freeze the price of this trip for ${timeToLive ? getDurationText(timeToLive) : ""
    }.`;
};

export const PRICE_FREEZE_PREDICTION_XP_BUY_NOW_HEADER = "Price Freeze"
export const PRICE_FREEZE_PREDICTION_XP_BUY_NOW_MAIN_TEXT = (
  price: string,
  rewards: string,
) => {
  return `This is a great price! Pay a fee of <strong>${price}</strong>${rewards ? " or " : " "}${rewards} to freeze until you're ready to book.`
};
export const PRICE_FREEZE_PREDICTION_XP_WAIT_MAIN_TEXT = (
  price: string,
  rewards: string,
) => {
  return `If the price goes down, you'll pay the lower price! Pay a fee of <strong>${price}</strong>${rewards ? " or " : " "}${rewards} to freeze.`
};

export const PRICE_FREEZE_INFO_LOWEST_PRICE_DETAILS = (
  price: string,
  rewards: string,
  timeToLive: TimeToLive | undefined,
  highlightedRewards?: boolean,
  useLockPriceLanguage?: boolean,
  isCheapestPriceFreezeOffer?: boolean
) =>
  `Pay a fee of <strong>${price}</strong>${rewards ? " or " : " "}${highlightedRewards ? `<strong>${rewards}</strong>` : rewards
  } per traveler to ${useLockPriceLanguage ? "lock" : "freeze"
  } the ${isCheapestPriceFreezeOffer ? "current lowest " : "lowest eligible "
  }price of this trip for ${timeToLive ? getDurationText(timeToLive) : ""
  }.`;

export const PRICE_FREEZE_USER_SELECTED_DURATION = (
  price: string,
  rewards: string,
  timeToLive: TimeToLive | undefined,
  offerDataLength: number,
  highlightedRewards?: boolean,
  useLockPriceLanguage?: boolean,
  isCheapestPriceFreezeOffer?: boolean,
) =>
  `${useLockPriceLanguage ? "Lock" : "Freeze"} the ${isCheapestPriceFreezeOffer ? "current lowest " : "lowest eligible "
  }price of this trip for <strong>${price}</strong> ${highlightedRewards ? `<strong>/ ${rewards}</strong>` : `/ ${rewards}`
  } per traveler for ${timeToLive ? getDurationText(timeToLive) : ""
  }${getPriceFreezeDefaultDurationEndText(offerDataLength, false)}`;

export const PRICE_FREEZE_DEFAULT_DURATIONS_KNOCKOUT = (
  price: string,
  rewards: string,
  highlightedRewards?: boolean,
  useLockPriceLanguage?: boolean,
  useCurrentLowestPriceLanguage?: boolean
) =>
  `${useLockPriceLanguage ? "Lock" : "Freeze"} the ${useCurrentLowestPriceLanguage ? "current lowest " : ""
  }price of this trip for <strong>${price}</strong> ${highlightedRewards ? `<strong>/ ${rewards}</strong>` : `/ ${rewards}`
  } per traveler.`;

export const PRICE_FREEZE_DEFAULT_DURATIONS = (
  price: string,
  rewards: string,
  timeToLive: number,
  offerDataLength: number,
  highlightedRewards?: boolean,
  useLockPriceLanguage?: boolean,
  isCheapestPriceFreezeOffer?: boolean
) =>
  `${useLockPriceLanguage ? "Lock" : "Freeze"} the ${isCheapestPriceFreezeOffer ? "current lowest " : "lowest eligible "
  }price of this trip for <strong>${price}</strong> ${highlightedRewards ? `<strong>/ ${rewards}</strong>` : `/ ${rewards}`
  } per traveler for ${timeToLive ? getDurationTextFromSeconds(timeToLive) : ""
  }${getPriceFreezeDefaultDurationEndText(offerDataLength, true)}`;

export const getPriceFreezeDurationTitles = ({
  priceFreezeFiat,
  priceFreezeRewards,
  selectedRewardsAccountId,
  priceFreezeCapAmount,
  priceFreezeCapCurrency,
  duration,
  useLockPriceLanguage,
}: {
  priceFreezeFiat?: FiatPrice;
  priceFreezeRewards?: { [key: string]: RewardsPrice } | null;
  selectedRewardsAccountId?: string | null;
  priceFreezeCapAmount: number;
  priceFreezeCapCurrency: string,
  duration?: number;
  useLockPriceLanguage?: boolean;
}) => {
  const amount =
    priceFreezeFiat && getTotalPriceText({ price: priceFreezeFiat });
  const rewards = getPriceFreezeRewardsString(
    priceFreezeRewards,
    selectedRewardsAccountId
  );

  return {
    header: `<span class='font-regular'>${useLockPriceLanguage ? "Lock" : "Freeze"
      } the price,</span> <span>book later.</span>`,
    title: `${useLockPriceLanguage ? "Lock" : "Freeze"
      } the current price for <strong>${duration ? getDurationTextFromSeconds(duration) : ""
      }</strong> for <strong>${amount}</strong>${rewards ? ` or <strong>${rewards}</strong>` : ""
      } per traveler.`,
    increaseText: `<strong>If the price goes up after you ${useLockPriceLanguage ? "lock it" : "freeze"
      },</strong> we'll cover the difference up to ${getPriceString({
        price: priceFreezeCapAmount || 0,
        currencySymbol: priceFreezeCapCurrency ? priceFreezeCapCurrency : undefined,
      })} per traveler when you book with us.`,
    decreaseText: `<strong>If the price goes down after you ${useLockPriceLanguage ? "lock it" : "freeze"
      },</strong> you pay the lower price when you book with us!`,
    similarFlightText:
      "<strong>If there aren't enough available seats when you try to book,</strong> you can get a full refund.",
    ctaText: "Read terms and conditions",
  };
};

export const PRICE_FREEZE_USER_SELECTED_DURATION_DEFAULT_12_HR = (
  price: string,
  rewards: string,
  highlightedRewards?: boolean,
  useLockPriceLanguage?: boolean,
  isCheapestPriceFreezeOffer?: boolean,
) =>
  `${useLockPriceLanguage ? "Lock" : "Freeze"} the ${isCheapestPriceFreezeOffer ? "current lowest " : "lowest eligible "
  }price of this trip starting at <strong>${price}</strong> ${highlightedRewards ? `<strong>/ ${rewards}</strong>` : `/ ${rewards}`
  } per traveler.`;

export const PRICE_FREEZE_INFO_BUTTON = (useLockPriceLanguage?: boolean) =>
  `${useLockPriceLanguage ? "Lock" : "Freeze"} price`;
export const PRICE_FREEZE_INFO_BUTTON_LONGER = (
  useLockPriceLanguage?: boolean
) => `${useLockPriceLanguage ? "Lock" : "Freeze"} this price`;
export const PRICE_FREEZE_INFO_LINK_LABEL = (useLockPriceLanguage?: boolean) =>
  `View price ${useLockPriceLanguage ? "lock" : "freeze"} info`;

export const PRICE_FREEZE_FLIGHT_DETAILS_MODAL_TITLE = `We selected the flights with the <strong style="display: inline-block;">current lowest price</strong> for you.`;
export const PRICE_FREEZE_FLIGHT_DETAILS_MODAL_SUBTITLE = (
  useLockPriceLanguage?: boolean
) =>
  `You can also choose your own set of flights and ${useLockPriceLanguage ? "lock" : "freeze"
  } the price when reviewing your itinerary.`;

export const PRICE_FREEZE_DETAILS_FROZEN_PRICE_DURATION = (
  timeToLive: TimeToLive | undefined,
  useLockPriceLanguage?: boolean
) =>
  `Current price being ${useLockPriceLanguage ? "locked" : "frozen"
  } for <strong>${timeToLive ? getDurationText(timeToLive) : ""}</strong>:`;

export const PRICE_FREEZE_DETAILS_FROZEN_PRICE_DEFAULT_DURATIONS = (
  timeToLive: number,
  useLockPriceLanguage?: boolean
) =>
  `Current price being ${useLockPriceLanguage ? "locked" : "frozen"
  } for <strong>${timeToLive ? getDurationTextFromSeconds(timeToLive) : ""}</strong>:`;

export const PRICE_FREEZE_DETAILS_FROZEN_PRICE_VALUE = (
  frozenPrice?: { fiat: FiatPrice; rewards: RewardsPrice | undefined } | null
) =>
  frozenPrice
    ? `<strong>${getTotalPriceText({
      price: frozenPrice.fiat,
    })}</strong>${frozenPrice.rewards
      ? ` or ${getRewardText({ reward: frozenPrice.rewards })}`
      : ""
    }`
    : "";

export const PRICE_FREEZE_DETAILS_FROZEN_PRICE_VALUE_DEFAULT_DURATIONS = (
  priceFreezeFiat?: FiatPrice,
  priceFreezeRewards?: { [key: string]: RewardsPrice } | null,
  selectedRewardsAccountId?: string | null,
) =>
  priceFreezeFiat
    ? `<strong>${getTotalPriceText({
      price: priceFreezeFiat,
    })}</strong>${priceFreezeRewards
      ? ` or ${getPriceFreezeRewardsString(priceFreezeRewards, selectedRewardsAccountId)}`
      : ""
    }`
    : "";

export const PRICE_FREEZE_DETAILS_BUTTON_TEXT = "Continue";
export const PRICE_FREEZE_DETAILS_BUTTON_TEXT_LONGER = "Continue to checkout";
export const PRICE_FREEZE_DETAILS_BUTTON_LABEL = (
  useLockPriceLanguage?: boolean
) => `Price ${useLockPriceLanguage ? "lock" : "freeze"}  checkout`;
export const PRICE_FREEZE_CHOOSE_ANOTHER_BUTTON_TEXT = "Choose another flight";
export const PRICE_FREEZE_CHOOSE_ANOTHER_BUTTON_LABEL = "Choose another flight";
export const PRICE_FREEZE_DETAILS_READ_TERMS_TEXT = "Read terms and conditions";
export const PRICE_FREEZE_DETAILS_READ_TERMS_LABEL =
  "Price freeze terms and conditions";
export const ROUND_TRIP = "Round-trip,";
export const PER_TRAVELER = "per traveler";
export const LEARN_MORE = "Learn more";

export const PRICE_FREEZE_CHOOSE_DURATION_TITLE = `Choose the duration of your price freeze.`;
export const PRICE_FREEZE_CHOOSE_DURATION_SUBTITLE = (
  minDurationInSeconds: number | undefined,
  maxDurationInSeconds: number | undefined,
): string => {
  // Use 14 as a fallback in case there is any issue retrieving the max duration dynamically
  const minDurationInDays = minDurationInSeconds
    ? convertSecondsToDays(minDurationInSeconds)
    : 0;
  const maxDurationInDays = maxDurationInSeconds
    ? convertSecondsToDays(maxDurationInSeconds)
    : 14;
  const minHoursOrDays = minDurationInDays === 0.5 || 0 ? 12 : minDurationInDays
  const minHoursOrDaysText = minDurationInDays === 0.5 || 0 ? "hours" : "days"

  return `Choose how long you want to freeze the price of this trip, from ${minHoursOrDays} ${minHoursOrDaysText} up to ${maxDurationInDays} ${maxDurationInDays === 1 ? "day" : "days"
    }.`;
};
export const PRICE_FREEZE_SELCTED_DURATION_BUTTON_TEXT = "Continue";
export const PRICE_FREEZE_SELCTED_DURATION_BUTTON_TEXT_LONGER = "Continue to checkout";
export const PRICE_FREEZE_SELCTED_DURATION_BUTTON_LABEL = (
  useLockPriceLanguage?: boolean
) => `Price ${useLockPriceLanguage ? "lock" : "freeze"}  checkout`;

export const CHOOSE_DURATION_COVERAGE = (
  cap: string
) => `<strong>If the price goes up during this time</strong> we’ll cover the difference up to ${cap} per traveler.
<br/>
<strong>If the price goes down</strong>, you pay the lower price when you book with us!`;
export const CONTINUE = "Continue";

export const CHOOSE_DURATION_FOOTER = (isRoundTrip: boolean) =>
  `*Prices are per traveler, ${isRoundTrip ? "round-trip" : "one-way"}`;

