import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../../../reducers/types";
import { DepartureArrivalSelectionDropdown } from "./component";
import { actions } from "../../../../../../actions";
import {
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnArrivalTimeRange,
  getTripCategory,
} from "../../../../../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  const outboundDepartureTimeRange = getOutboundDepartureTimeRange(state);
  const outboundArrivalTimeRange = getOutboundArrivalTimeRange(state);
  const returnDepartureTimeRange = getReturnDepartureTimeRange(state);
  const returnArrivalTimeRange = getReturnArrivalTimeRange(state);
  const tripCategory = getTripCategory(state);

  return {
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
  };
};

const mapDispatchToProps = {
  setOutboundDepartureTimeRange: actions.setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange: actions.setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange: actions.setReturnDepartureTimeRange,
  setReturnArrivalTimeRange: actions.setReturnArrivalTimeRange,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DepartureArrivalSelectionProps = ConnectedProps<typeof connector>;

export const ConnectedDepartureArrivalSelectionDropdown = connector(
  DepartureArrivalSelectionDropdown
);
