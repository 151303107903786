import { TripSummary } from "./trip-summary";
import { Prices } from "./payment-machine";
import { PriceFreeze } from "./price-freeze";

export interface GetSimilarFlightsRequest {
  id: string;
  passengers: { [key: string]: number };
}

export interface SimilarFlight {
  fareId: string;
  baseAmount: Prices;
  color: string;
}

export interface GetSimilarFlightsResponse {
  prices: SimilarFlight[];
  trips: TripSummary[];
}

export enum ItineraryEnum {
  SingleItinerary = "SingleItinerary",
}

export interface SingleItinerary {
  tripId: string;
  fareId: string;
  Itinerary: ItineraryEnum.SingleItinerary;
}

export type Itinerary = SingleItinerary;

export interface TransferRequest {
  id: string;
  itinerary: Itinerary;
}

export interface TransferResponse {
  priceFreeze: PriceFreeze;
}
