import React from "react";
import { Box } from "@material-ui/core";
import { ActionLink } from "halifax";
import { PRICE_FREEZE_ID_QUERY_PARAM } from "redmond";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { RouteComponentProps } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryStringParser from "query-string";
import clsx from "clsx";

import "./styles.scss";
import { FlightFreezeProgressBarConnectorProps } from "./container";
import {
  PATH_PRICE_FREEZE_OVERVIEW,
  PATH_PRICE_FREEZE_CUSTOMIZE,
  PATH_BOOK,
} from "../../../../utils/urlPaths";
import * as constants from "./constants";

interface IFlightFreezeProgressBarProps
  extends FlightFreezeProgressBarConnectorProps,
    RouteComponentProps {}

enum FlightFreezeStep {
  Review,
  Customize,
  Book,
}

export const FlightFreezeProgressBar = (
  props: IFlightFreezeProgressBarProps
) => {
  const {
    isAddOnOptionAvailable,
    isOptionSelectionComplete,
    currentPriceFreeze,
    populateFlightBookQueryParams,
    history,
  } = props;

  const renderProgressButtons = (progresses: FlightFreezeStep[]) => {
    const isInFlightBook = history.location.pathname === PATH_BOOK;

    const goToFlightFreezeStep = (progress: FlightFreezeStep) => {
      switch (progress) {
        case FlightFreezeStep.Review:
          history.push({
            pathname: PATH_PRICE_FREEZE_OVERVIEW,
            search: queryStringParser.stringify({
              [PRICE_FREEZE_ID_QUERY_PARAM]: currentPriceFreeze?.priceFreeze.id,
            }),
          });
          break;
        case FlightFreezeStep.Customize:
          history.push({
            pathname: PATH_PRICE_FREEZE_CUSTOMIZE,
            search: queryStringParser.stringify({
              [PRICE_FREEZE_ID_QUERY_PARAM]: currentPriceFreeze?.priceFreeze.id,
            }),
          });
          break;
        case FlightFreezeStep.Book:
          populateFlightBookQueryParams({
            history,
            newQueryParams: {
              [PRICE_FREEZE_ID_QUERY_PARAM]:
                currentPriceFreeze?.priceFreeze.id ?? "",
            },
          });
          break;
        default:
          break;
      }
    };

    return progresses.map((progress: FlightFreezeStep, index: number) => {
      const getIsSelected = (step: FlightFreezeStep) => {
        switch (step) {
          case FlightFreezeStep.Review:
            return history.location.pathname === PATH_PRICE_FREEZE_OVERVIEW;
          case FlightFreezeStep.Customize:
            return history.location.pathname === PATH_PRICE_FREEZE_CUSTOMIZE;
          case FlightFreezeStep.Book:
            return history.location.pathname === PATH_BOOK;
          default:
            return false;
        }
      };

      return (
        <React.Fragment key={index}>
          <FlightFreezeProgressButton
            key={`button-${index}`}
            isPriceFreezeReady={!!currentPriceFreeze}
            isAddOnOptionAvailable={isAddOnOptionAvailable}
            isOptionSelectionComplete={isOptionSelectionComplete}
            isInFlightBook={isInFlightBook}
            isSelected={getIsSelected(progress)}
            progress={progress}
            goToFlightFreezeStep={goToFlightFreezeStep}
          />
          {index < progresses.length - 1 && (
            <FontAwesomeIcon
              key={`separator-${index}`}
              className="progress-button-separator"
              icon={faChevronRight}
            />
          )}
        </React.Fragment>
      );
    });
  };

  const getProgresses = (): FlightFreezeStep[] => {
    return [
      FlightFreezeStep.Review,
      FlightFreezeStep.Customize,
      FlightFreezeStep.Book,
    ];
  };

  return (
    <Box className="flight-freeze-progress-bar-root">
      <Box className="flight-freeze-progress-bar-container">
        {renderProgressButtons(getProgresses())}
      </Box>
    </Box>
  );
};

interface IFlightFreezeProgressButtonProps {
  isPriceFreezeReady: boolean;
  isAddOnOptionAvailable: boolean;
  isOptionSelectionComplete: boolean;
  isInFlightBook: boolean;
  isSelected: boolean;
  progress: FlightFreezeStep;
  goToFlightFreezeStep: (progress: FlightFreezeStep) => void;
}

const FlightFreezeProgressButton = (
  props: IFlightFreezeProgressButtonProps
) => {
  const {
    isPriceFreezeReady,
    isAddOnOptionAvailable,
    isOptionSelectionComplete,
    isInFlightBook,
    isSelected,
    progress,
    goToFlightFreezeStep,
  } = props;

  const getProgressText = (progress: FlightFreezeStep) => {
    switch (progress) {
      case FlightFreezeStep.Review:
        return constants.REVIEW_TEXT;
      case FlightFreezeStep.Customize:
        return constants.CUSTOMIZE_TEXT;
      case FlightFreezeStep.Book:
        return constants.BOOK_TEXT;
      default:
        return "";
    }
  };

  const isDisabled = (progress: FlightFreezeStep) => {
    switch (progress) {
      case FlightFreezeStep.Review:
        return !isPriceFreezeReady;
      case FlightFreezeStep.Customize:
        return !isPriceFreezeReady || !isAddOnOptionAvailable;
      case FlightFreezeStep.Book:
        return (
          (!isPriceFreezeReady ||
            (isAddOnOptionAvailable && !isOptionSelectionComplete)) &&
          !isInFlightBook
        );
      default:
        return true;
    }
  };

  const handleOnClick = () => {
    goToFlightFreezeStep(progress);
  };

  return (
    <ActionLink
      className={clsx(
        "flight-freeze-progress-button",
        { selected: isSelected },
        "b2b"
      )}
      onClick={handleOnClick}
      content={getProgressText(progress)}
      disabled={isDisabled(progress)}
    />
  );
};
