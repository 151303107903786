import React from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import "./styles.scss";
import {
  IPriceFreezeEntryInfoOwnProps,
  PriceFreezeEntryInfo,
} from "../PriceFreezeEntryInfo";
import {
  IPriceFreezeEntryButtonOwnProps,
  PriceFreezeEntryButton,
} from "../PriceFreezeEntryButton";
import { IPriceFreezeDurationRadioGroupOwnProps } from "../PriceFreezeDurationRadioGroup";
import { ConnectedPriceFreezeDurationRadioGroup } from "../PriceFreezeDurationRadioGroup/container";

export interface IPriceFreezeEntryProps {
  className?: string;
  priceFreezeEntryButtonProps: IPriceFreezeEntryButtonOwnProps;
  priceFreezeEntryInfoProps: IPriceFreezeEntryInfoOwnProps;
  priceFreezeDurationRadioGroupProps: IPriceFreezeDurationRadioGroupOwnProps
}

export const PriceFreezeEntry = (props: IPriceFreezeEntryProps) => {
  const { className, priceFreezeEntryButtonProps, priceFreezeEntryInfoProps, priceFreezeDurationRadioGroupProps } =
    props;

  const { matchesMobile } = useDeviceTypes();

  return (
    <>
      <Box
        className={clsx("price-freeze-entry-root", className, {
          mobile: matchesMobile,
        })}
      >
        <Box className="price-freeze-entry-container">
          <PriceFreezeEntryInfo {...priceFreezeEntryInfoProps} />
          <ConnectedPriceFreezeDurationRadioGroup {...priceFreezeDurationRadioGroupProps} />
          <PriceFreezeEntryButton {...priceFreezeEntryButtonProps} />
        </Box>
      </Box>
    </>
  );
};
