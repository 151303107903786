import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getDestination,
  getOrigin,
  getTripCategory,
  getDepartureDateBucketsLoading,
  getMobileSearchProgress,
  getDepartureDate,
  getReturnDate,
  getHasOriginAutocompleteError,
  getHasDestinationAutocompleteError,
} from "../../reducer";
import {
  fetchDepartureCalendar,
  setCalendar,
  setDepartureDate,
  setDestinationAutocompleteError,
  setMobileSearchProgress,
  setOriginAutocompleteError,
  setReturnDate,
  setTripCategory,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileFlightSearchControl } from "./component";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  setCurrentStep: setMobileSearchProgress,
  setTripCategory,
  setOriginAutocompleteError,
  setDestinationAutocompleteError,
  setDepartureDate,
  setReturnDate
};
const mapStateToProps = (state: IStoreState) => ({
  origin: getOrigin(state),
  destination: getDestination(state),
  originCode: getOrigin(state)?.id.code.code,
  destinationCode: getDestination(state)?.id.code.code,
  tripCategory: getTripCategory(state),
  departureDateBucketsLoading: getDepartureDateBucketsLoading(state),
  currentStep: getMobileSearchProgress(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  hasOriginAutocompleteError: getHasOriginAutocompleteError(state),
  hasDestinationAutocompleteError: getHasDestinationAutocompleteError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileFlightSearchControl = withRouter(
  connector(MobileFlightSearchControl)
);
