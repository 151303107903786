import React, { useEffect, Dispatch, SetStateAction } from "react";
import { Box, Dialog } from "@material-ui/core";
import queryStringParser from "query-string";
import { RouteComponentProps } from "react-router";
import {
  VIEWED_PRICE_FREEZE,
  ViewedPriceFreezeInfoProperties,
  FROZEN_PRICES_BODY,
} from "redmond";
import {
  MobilePopoverCard,
  useDeviceTypes,
  PriceFreeze,
  PriceFreezeImage,
  PriceFreezeImageMobile,
  IconName,
  Icon,
} from "halifax";
import { PATH_PRICE_FREEZE, PATH_FREEZE } from "../../../../../utils/urlPaths";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { PriceFreezeExplanationModalConnectorProps as PriceFreezeExplanationModalConnectorProps } from "./container";
import { getMapProgressToEvent } from "../sharedUtils";

export interface IPriceFreezeExplanationModalProps
  extends PriceFreezeExplanationModalConnectorProps,
    IPriceFreezeExplanationModalOwnProps,
    RouteComponentProps {}

export interface IPriceFreezeExplanationModalOwnProps {
  showPriceFreezeFlightDetails?: boolean;
  showPriceFreezeDuration?: boolean;
  priceFreezeTitles: {
    header: string;
    title: string;
    increaseText: string;
    decreaseText: string;
    similarFlightText: string;
    ctaText: string;
  };
  hideContinueCta?: boolean;
  useLockPriceLanguage?: boolean;
  priceFreezeExplanationModalOpen: boolean;
  setPriceFreezeExplanationModalOpen: Dispatch<SetStateAction<boolean>>;
  setPriceFreezeFlightDetailsModalOpen: Dispatch<SetStateAction<boolean>>;
  setPriceFreezeDurationModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const PriceFreezeExplanationModal = (
  props: IPriceFreezeExplanationModalProps
) => {
  const {
    populateFlightBookQueryParams,
    showPriceFreezeFlightDetails: showPriceFreezeDetails = false,
    showPriceFreezeDuration = false,
    history,
    priceFreezeTitles,
    hideContinueCta,
    useLockPriceLanguage,
    priceFreezeExplanationModalOpen,
    flightShopStep,
    setPriceFreezeExplanationModalOpen,
    setPriceFreezeFlightDetailsModalOpen,
    setPriceFreezeDurationModalOpen,
    setPriceFreezeEntryPointStep,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  // effect to run tracking
  useEffect(() => {
    const { flightShopProgress } = queryStringParser.parse(
      history.location.search
    );
    if (priceFreezeExplanationModalOpen && flightShopProgress) {
      trackEvent({
        eventName: VIEWED_PRICE_FREEZE,
        properties: {
          price_freeze_entry: getMapProgressToEvent(+flightShopProgress),
        } as ViewedPriceFreezeInfoProperties,
      });
    }
  }, [priceFreezeExplanationModalOpen, history.location.search]);

  const handleContinue = () => {
    // The PriceFreezeExplanationModal can be viewed from PricePrediction and ReviewItinerary
    // In the absence of standard use of the goToPriceFreezeCheckout, we need to set this on the PriceFreezeExplanationModal at each step
    setPriceFreezeEntryPointStep(flightShopStep);
    setPriceFreezeExplanationModalOpen(false);
    if (showPriceFreezeDetails) {
      setPriceFreezeFlightDetailsModalOpen(true);
    } else if (showPriceFreezeDuration) {
      setPriceFreezeDurationModalOpen(true);
    } else {
      populateFlightBookQueryParams({
        history,
        pathname: PATH_FREEZE,
        preserveQuery: true,
      });
    }
  };

  const priceFreezeInfoPopup = (
    <Dialog
      open={priceFreezeExplanationModalOpen}
      className="flight-price-freeze-popup"
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      onClose={() => setPriceFreezeExplanationModalOpen(false)}
    >
      <Box className="price-freeze-wrapper">
        <PriceFreeze
          {...priceFreezeTitles}
          className="price-freeze-card"
          image={PriceFreezeImage}
          onClose={() => setPriceFreezeExplanationModalOpen(false)}
          onContinue={!hideContinueCta ? handleContinue : undefined}
          onClick={() => window.open(`${PATH_PRICE_FREEZE}`, "_blank")}
          icon={
            useLockPriceLanguage ? (
              <Icon
                className="b2b-price-freeze-icon"
                name={IconName.LockWithKeyholeCircle}
              />
            ) : undefined
          }
        />
      </Box>
    </Dialog>
  );

  const priceFreezeInfoMobilePopover = (
    <MobilePopoverCard
      open={priceFreezeExplanationModalOpen}
      className="mobile-price-freeze-popup"
      contentClassName="mobile-price-freeze-content-wrapper"
      onClose={() => setPriceFreezeExplanationModalOpen(false)}
      centered={true}
    >
      <Box className="price-freeze-wrapper">
        <PriceFreeze
          {...priceFreezeTitles}
          className="price-freeze-card"
          image={PriceFreezeImageMobile}
          onClose={() => setPriceFreezeExplanationModalOpen(false)}
          onContinue={handleContinue}
          isMobile={true}
          mobileTermsConditionCopy={FROZEN_PRICES_BODY}
          icon={
            useLockPriceLanguage ? (
              <Icon
                className="b2b-price-freeze-icon"
                name={IconName.LockWithKeyholeCircle}
              />
            ) : undefined
          }
        />
      </Box>
    </MobilePopoverCard>
  );

  return (
    <>{matchesMobile ? priceFreezeInfoMobilePopover : priceFreezeInfoPopup}</>
  );
};
