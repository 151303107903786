import axios from "axios";
import { PassengersResponse, PassengersForFareRequest } from "redmond";
import { airBookingApi } from "@b2bportal/air-booking-api";

export const validatePassengers = (
  req: PassengersForFareRequest
): Promise<PassengersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(axios as any).apiV0PassengerValidationPost(req);

      const responseBody = res.data;
      resolve(responseBody as PassengersResponse);
    } catch (e) {
      reject(e);
    }
  });
