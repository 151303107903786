import dayjs from "dayjs";

export const getTravelersText = (count: number) =>
  `${count} ${count > 1 ? "Travelers" : "Traveler"}`;

export const EXPIRATION_DATE_TEXT = "Expiration date:";
export const FROZEN_PRICE_PER_TRAVELER_TEXT = "Frozen price per traveler:";
export const TRAVELERS_FROZEN_TEXT = "Travelers this price was frozen for:";

export const FROZEN_PRICE_SUMMARY_TITLE = "Frozen Price Summary";

export const getExpireDate = (expiresAt: string) =>
  expiresAt ? dayjs(expiresAt).format("ddd, MMMM D") : "";
