import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  airportsSelector,
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer/selectors";
import {
  fetchSeatMap,
  setSeatMap,
  setSelectedSeats,
} from "../../actions/actions";
import {
  getCheapestSeat,
  getSeatMapAvailability,
  getPriceQuoteWithUpdatedAncillary,
  getSeatMapHtml,
  getSeatMapLoading,
  getSeatSelectionAvailability,
  getSelectedSeats,
  getUserPassengers,
  getUserSelectedPassengerIds,
} from "../../reducer";
import { MobileSeatSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const selectedTravelersId = getUserSelectedPassengerIds(state);
  return {
    seatMapAvailability: getSeatMapAvailability(state),
    priceQuote: getPriceQuoteWithUpdatedAncillary(state),
    seatSelectAvailability: getSeatSelectionAvailability(state),
    seatMapHtml: getSeatMapHtml(state),
    cheapestSeat: getCheapestSeat(state),
    seatMapLoading: getSeatMapLoading(state),
    selectedSeats: getSelectedSeats(state),
    tripDetails,
    passengers: getUserPassengers(state).filter((user) => {
      return selectedTravelersId.includes(user.id);
    }),
    airports: airportsSelector(
      state,
      selectedTripSelector(state)?.tripId || ""
    ),
  };
};

const mapDispatchToProps = {
  fetchSeatMap,
  setSelectedSeats,
  setSeatMap,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileSeatSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileSeatSelection = connector(MobileSeatSelection);
