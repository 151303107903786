import { connect, ConnectedProps } from "react-redux";
import { DesktopCalendarPicker } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import { getDepartureDate, getReturnDate } from "../../../../reducer";
import { actions } from "../../../../actions";

const mapDispatchToProps = {
  setOpenDatesModal: actions.setOpenDatesModal,
};
const mapStateToProps = (state: IStoreState) => {
  return {
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedDesktopCalendarPickerProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopCalendarPicker = connector(DesktopCalendarPicker);
