import React from "react";
import { Box, Typography } from "@material-ui/core";

import {
  Airport,
  TripCategory,
  TripDetails,
  FiatPrice,
  Dealness,
  RewardsPrice,
  Prediction,
  TimeToLive,
  Cap,
} from "redmond";
import {
  PriceWatchCard,
  PricePredictionCard,
  PredictionForecast,
  getPricesWithComma,
  getTotalPriceText,
  Icon,
  IconName,
  IPriceFreezeOfferEntries,
} from "halifax";
import {
  FORECASTED_HIGHEST,
  FORECASTED_LOWEST,
  getLowestPriceInFiat,
  getLowestPriceInRewards,
  getPricePredictionCurrentPriceSubtitle,
  getPriceWatchCopy,
  PRICE_PREDICTION,
  PRICE_PREDICTION_CURRENT_PRICE_TITLE,
  WATCH_TRIP,
  WATCHING,
  WatchState,
  getPriceFreezeTitles,
  SLASH_SEPARATOR,
} from "../../constants";
import PricePredictionBar from "./PricePredictionBar";
import { PriceFreezeEntry } from "../priceFreezeComponents/PriceFreezeEntry";
import { IDeleteWatch } from "../../actions/actions";
import {
  getForecastedPriceDisplayText,
  isForecastedPriceSensible,
  getPriceFreezeRewardsString,
} from "../../utils/helpers";
import {
  IPriceFreezeFlightDetailsModalOwnPropsForButton,
  IPriceFreezeEntryButtonOwnProps,
} from "../priceFreezeComponents/PriceFreezeEntryButton";
import { IPriceFreezeDurationRadioGroupOwnProps } from "../priceFreezeComponents/PriceFreezeDurationRadioGroup";
import {
  IPriceFreezeFlightDetailsModalOwnPropsForInfo,
  IPriceFreezeExplanationModalOwnPropsForInfo,
  IPriceFreezeEntryInfoOwnProps,
} from "../priceFreezeComponents/PriceFreezeEntryInfo";
import { FlightShopStep } from "../../reducer";

interface IMobilePricePredictionFullProps {
  prediction: Prediction | null;
  tripCategory: TripCategory;
  isWatching: boolean;
  hasUnsupportedPredictionFilters: boolean;
  deleteWatch: () => IDeleteWatch;
  rewardsKey: string | undefined;
  priceFreezeOffer: IPriceFreezeOfferEntries | null;
  priceFreezeFiat: FiatPrice | undefined;
  priceFreezeCap: Cap | undefined;
  selectedRewardsAccountId: string | undefined;
  priceFreezeRewards: { [key: string]: RewardsPrice } | null | undefined;
  fareId: string;
  tripDetails: TripDetails | undefined;
  priceFreezeDuration: TimeToLive | undefined;
  airports: { [key: string]: Airport };
  cheapestFrozenPrice:
    | { fiat: FiatPrice; rewards: RewardsPrice | undefined }
    | undefined;

  isPriceFreeze: boolean | undefined;
  onOpenPriceDropProtection: () => void;
  onOpenWatchModal: () => void;
  onClose: () => void;
  useLockPriceLanguage?: boolean;
  canRedeemRewards: boolean;
}

export const MobilePricePredictionFull = ({
  prediction,
  tripCategory,
  isWatching,
  hasUnsupportedPredictionFilters,
  deleteWatch,
  rewardsKey,
  priceFreezeOffer,
  priceFreezeFiat,
  priceFreezeRewards,
  priceFreezeCap,
  selectedRewardsAccountId,
  tripDetails,
  fareId,
  priceFreezeDuration,
  airports,
  cheapestFrozenPrice,
  isPriceFreeze,
  onOpenPriceDropProtection,
  onOpenWatchModal,
  onClose,
  useLockPriceLanguage,
  canRedeemRewards,
}: IMobilePricePredictionFullProps) => {
  const getForecastedPrice = (prediction: Prediction) => {
    const displayText = getForecastedPriceDisplayText(prediction);
    if (displayText) {
      return (
        <Typography className="content" variant="h4">
          {displayText}
          {/* TODO: display RewardText once the backend is ready */}
        </Typography>
      );
    }
    return "";
  };

  const priceFreezeFlightDetailsModalPropsForButton: IPriceFreezeFlightDetailsModalOwnPropsForButton =
    {
      priceFreezeOffer: priceFreezeOffer,
      frozenPrice: cheapestFrozenPrice,
      duration: priceFreezeDuration,
      tripDetails: tripDetails,
      fareId: fareId,
      airports: airports,
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeFlightDetailsModalPropsForInfo: IPriceFreezeFlightDetailsModalOwnPropsForInfo =
    priceFreezeFlightDetailsModalPropsForButton;

  const PriceFreezeExplanationModalPropsForInfo: IPriceFreezeExplanationModalOwnPropsForInfo =
    {
      showPriceFreezeFlightDetails: true,
      priceFreezeTitles: getPriceFreezeTitles({
        priceFreezeFiat,
        priceFreezeRewards,
        selectedRewardsAccountId,
        priceFreezeCap,
        duration: priceFreezeDuration,
        useLockPriceLanguage,
      }),
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeEntryInfoProps: IPriceFreezeEntryInfoOwnProps = {
    useLowestPriceText: true,
    fiatPrice: getTotalPriceText({
      price: priceFreezeFiat as FiatPrice,
    }),
    rewards: getPriceFreezeRewardsString(
      priceFreezeRewards,
      selectedRewardsAccountId
    ),
    duration: priceFreezeDuration,
    useLockPriceLanguage: useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForInfo,
    priceFreezeExplanationModalProps: PriceFreezeExplanationModalPropsForInfo,
    isFromFlightShopReviewItinerary: false,
    prediction: prediction,
  };

  const priceFreezeEntryButtonProps: IPriceFreezeEntryButtonOwnProps = {
    showPriceFreezeDetails: true,
    tripDetails: tripDetails,
    fareId: fareId,
    useLockPriceLanguage: useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForButton,
    flightShopStep: FlightShopStep.PricePrediction,
    setCheapestEligibleOfferData: true,
  };

  const priceFreezeDurationRadioProps: IPriceFreezeDurationRadioGroupOwnProps =
    {
      isFromFlightsReviewItineraryScreen: false,
    };

  return (
    <Box className="mobile-price-prediction-full">
      {prediction && (
        <>
          <PricePredictionBar
            prediction={prediction}
            onOpenPrediction={onClose}
            onOpenPriceDrop={onOpenPriceDropProtection}
          />
          <PricePredictionCard
            className="current-price"
            title={PRICE_PREDICTION_CURRENT_PRICE_TITLE}
            subtitle={getPricePredictionCurrentPriceSubtitle(tripCategory)}
            content={
              <Typography variant="h5">
                <span className="current-price-lowestprice">
                  <b>
                    {getPricesWithComma(
                      getLowestPriceInFiat(prediction?.lowestPrice)
                    )}
                  </b>
                </span>
                {canRedeemRewards &&
                  getLowestPriceInRewards(
                    prediction?.lowestPrice,
                    rewardsKey,
                    SLASH_SEPARATOR
                  )}
              </Typography>
            }
          />
        </>
      )}
      {isPriceFreeze && !!priceFreezeOffer && (
        <>
          <Box className="price-freeze-section">
            <PriceFreezeEntry
              priceFreezeEntryInfoProps={priceFreezeEntryInfoProps}
              priceFreezeEntryButtonProps={priceFreezeEntryButtonProps}
              priceFreezeDurationRadioGroupProps={priceFreezeDurationRadioProps}
            />
          </Box>
        </>
      )}

      {prediction &&
        getForecastedPrice(prediction) &&
        isForecastedPriceSensible(prediction) && (
          <PricePredictionCard
            className="forecasted-price"
            title={
              prediction.dealness === Dealness.Wait
                ? FORECASTED_LOWEST
                : FORECASTED_HIGHEST
            }
            icon={
              <Icon
                className={
                  prediction.dealness === Dealness.Wait
                    ? "forecasted-lowest-icon"
                    : "forecasted-highest-icon"
                }
                name={
                  prediction.dealness === Dealness.Wait
                    ? IconName.DecreaseReviewed
                    : IconName.IncreasedReviewed
                }
              />
            }
            content={getForecastedPrice(prediction)}
            subtitle={getPricePredictionCurrentPriceSubtitle(tripCategory)}
            isForecastedPrice
          />
        )}

      <PriceWatchCard
        className="price-prediction-watch price-prediction-card"
        title={getPriceWatchCopy(
          isWatching ? WatchState.Watched : WatchState.NotWatching,
          hasUnsupportedPredictionFilters,
          prediction?.predictionCopy
        )}
        buttonCopy={isWatching ? WATCHING : WATCH_TRIP}
        onClick={() => (isWatching ? deleteWatch() : onOpenWatchModal())}
        isWatching={isWatching}
      />
      {prediction && prediction.predictionCopy && (
        <Box className="prediction-forecast-section">
          <Typography variant="h4" className="prediction-forecast-header">
            {PRICE_PREDICTION}
          </Typography>
          <PredictionForecast
            className="b2b"
            intervals={prediction.predictionCopy.intervals}
          />
        </Box>
      )}
    </Box>
  );
};

export const MobilePricePredictionFullTitle = () => (
  <Box className="mobile-price-prediction-full-title">
    <Typography variant={"h3"}>{PRICE_PREDICTION}</Typography>
  </Box>
);
