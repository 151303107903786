import React from "react"
import { Box, Typography } from "@material-ui/core";
import { ActionButton, ButtonWrap, DefaultStyle, Icon, IconName } from "halifax";
import clsx from "clsx";
import { airlinesCountTripSegment } from "../../../v2/components/FlightList/components/FlightDetails/component";
import * as textConstants from "../textConstants";
import { IRemindersSection } from "../types";

export const RemindersSection = ({
  slices,
  setVariant,
  buttons,
}: IRemindersSection) => {
  const hasMultipleAirlines = slices.map(s => airlinesCountTripSegment(s.segmentDetails)).some(count => count >= 1);
  return (
    <>
      <Box className="popup-decor">
        <Icon name={IconName.BookTravel} />
      </Box>
      <Box className="title">
        <Typography tabIndex={0} className="title-text" variant="h2">
          <span
            dangerouslySetInnerHTML={{
              __html:
                hasMultipleAirlines
                  ? textConstants.SELF_TRANSFER_MULTIPLE_AIRLINES
                  : textConstants.SELF_TRANSFER_ONE_AIRLINE,
            }}
          />
        </Typography>
      </Box>
      <Box className="subtitle">
        <span>{textConstants.SELF_TRANSFER_MODAL_SUBTITLE}</span>
        <ButtonWrap
          className="reminder-section-missed-connection"
          onClick={() => setVariant("missedConnectionGuarantee")}
        >
          <Typography variant="h4">
            {hasMultipleAirlines
              ? textConstants.SELF_TRANSFER_MODAL_MCR
              : textConstants.SELF_TRANSFER_MODAL_MCG}
          </Typography>
          <Icon name={IconName.InfoCircle} className="info-icon" />
        </ButtonWrap>
      </Box>
      {buttons.length > 0 && (
        <Box className="buttons-wrapper">
          {buttons.map((button) => {
            const {
              buttonText,
              buttonWrapperClassName,
              defaultStyle,
              onClick = () => null,
            } = button;

            return (
              <ActionButton
                key={buttonText}
                className={clsx(
                  "generic-info-popup-action-button",
                  buttonWrapperClassName
                )}
                onClick={onClick}
                disabled={false}
                message={buttonText}
                defaultStyle={defaultStyle as DefaultStyle}
              />
            );
          })}
        </Box>
      )}
    </>
  )
}
