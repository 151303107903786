import { actions, actionTypes } from "../actions";
import { CorporateInfo, RewardsAccount, UserCorpPermissions } from "redmond";

export interface IFlightRewardsState {
  rewardsAccounts: RewardsAccount[];
  selectedAccountReferenceId: string | null;
  fetchRewardsAccountsCallState: CallState;
  isFirstLaunch: boolean;
  agentEmail: string;
  corporateInfo: CorporateInfo;
}

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

export const initialState: IFlightRewardsState = {
  rewardsAccounts: [],
  selectedAccountReferenceId: null,
  fetchRewardsAccountsCallState: CallState.NotCalled,
  isFirstLaunch: false,
  agentEmail: "",
  corporateInfo: {
    permissions: {} as UserCorpPermissions,
    businessIsLive: false,
    role: [] as CorporateInfo["role"],
    policyTier: "",
    businessId: "",
    businessName: "",
    accountReferenceId: "",
    businessLoyaltyPrograms: undefined,
    defaultTravelerId: "",
    hiddenTravelerIds: [],
    hasSeenModalMap: { sean_ellis: false },
  },
};

export function reducer(
  state: IFlightRewardsState = initialState,
  action: actions.FlightRewardsActions
): IFlightRewardsState {
  switch (action.type) {
    case actionTypes.SET_IS_FIRST_LAUNCH:
      return {
        ...state,
        isFirstLaunch: action.isFirstLaunch,
      };

    case actionTypes.SET_AGENT_EMAIL:
      return {
        ...state,
        agentEmail: action.agentEmail,
      };

    case actionTypes.FETCH_REWARDS_ACCOUNTS:
      return {
        ...state,
        fetchRewardsAccountsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchRewardsAccountsCallState: CallState.Failed,
      };

    case actionTypes.SET_REWARDS_ACCOUNTS:
      return {
        ...state,
        rewardsAccounts: action.rewardsAccounts,
        fetchRewardsAccountsCallState: CallState.Success,
      };

    case actionTypes.SET_SELECTED_ACCOUNT_REFERENCE_ID:
      return {
        ...state,
        selectedAccountReferenceId: action.selectedAccountReferenceId,
      };
    case actionTypes.SET_CORPORATE_INFO:
      return {
        ...state,
        corporateInfo: action.corporateInfo,
      };

    default:
      return state;
  }
}

export * from "./selectors";
