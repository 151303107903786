import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { fetchEligibilityApiPrefix } from "../paths";
import { GetEligibilityRequest, GetEligibilityResponse } from "redmond";

export const fetchPriceDropEligibility = (
  body: GetEligibilityRequest
): Promise<GetEligibilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<GetEligibilityResponse> = await axios.post(fetchEligibilityApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchPriceDropEligibility;
