import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import { FlightShopStep } from "../../../shop/reducer";
import { RebookBannerConnectorProps } from "./container";
import {
  PARTIALLY_REBOOKING_TEXT,
  LOWER_FARE_CLASS_TEXT,
} from "../../constants";
import "./styles.scss";

export interface IRebookBannerProps extends RebookBannerConnectorProps {}

export const RebookBanner = (props: IRebookBannerProps) => {
  const {
    origin,
    destination,
    originalSliceContext,
    hasSelectedLowerFareClass,
    flightShopProgress,
  } = props;
  const [rebookCopy, setRebookCopy] = useState<string>();

  useEffect(() => {
    switch (flightShopProgress) {
      case FlightShopStep.ReviewItinerary: {
        if (hasSelectedLowerFareClass) {
          setRebookCopy(LOWER_FARE_CLASS_TEXT);
        } else {
          setRebookCopy(undefined);
        }
        break;
      }
      default: {
        setRebookCopy(
          PARTIALLY_REBOOKING_TEXT({
            origin: origin?.id.code.code,
            destination: destination?.id.code.code,
            airports: originalSliceContext?.airports,
          })
        );
        break;
      }
    }
  }, [
    flightShopProgress,
    origin,
    destination,
    originalSliceContext,
    hasSelectedLowerFareClass,
  ]);

  if (rebookCopy === undefined) {
    return null;
  } else {
    return (
      <Box className="rebook-banner-root">
        <Box className="rebook-banner-container">
          <Icon name={IconName.InfoCircle} className="info-icon" />
          <Typography variant="subtitle1" className="rebook-copy">
            {rebookCopy}
          </Typography>
        </Box>
      </Box>
    );
  }
};
