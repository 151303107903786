import { Lodging } from "redmond";

const priceSortComparer = (l1: Lodging, l2: Lodging) => {
  if (!l1.price || !l2.price) {
    if (!l1.price && !l2.price) {
      return 0;
    } else if (!l1.price) {
      return -1;
    }

    return 1;
  }

  return l1.price.nightlyPrice.fiat.value - l2.price.nightlyPrice.fiat.value;
};

export const orderByPriceLowToHigh = (lodgings: Lodging[]) => {
  return lodgings.sort(priceSortComparer);
};
