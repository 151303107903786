import React from "react";
import * as H from "history";
import { TRIGGERED_ERROR_BOUNDARY } from "redmond";

import { trackEvent } from "../../api/v0/analytics/trackEvent";
import { PATH_HOME } from "../../utils/urlPaths";

class ErrorBoundary extends React.Component<
  { history: H.History<H.History.LocationState>; children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { history: H.History<H.History.LocationState> }) {
    super(props);
    this.state = { hasError: false };
    this.reset = this.reset.bind(this);
  }

  static getDerivedStateFromError(error: any) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const { history } = this.props;

    if (process.env.NODE_ENV !== "production") {
      console.error(error);
      console.trace(errorInfo.componentStack);
    }

    trackEvent({
      eventName: TRIGGERED_ERROR_BOUNDARY,
      properties: {
        component_stack: errorInfo.componentStack,
        error_msg: error.message,
        error_name: error.name,
        error_stack: error.stack,
        location: history.location.pathname,
        location_search: history.location.search,
      },
    });
  }

  reset() {
    this.setState({ hasError: false });
  }

  render() {
    const { children, history } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // Render custom fallback UI below and remove redirect
      history.push(PATH_HOME);
      this.reset();
      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
