import { select, putResolve } from "redux-saga/effects";
import {
  ILocationQueryLabel,
  IResponse,
  ITripTerminus,
  LocationQueryEnum,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import { actions as searchActions } from "../../../search/actions";
import { actions } from "../../actions";
import { ITripTerminusCategory } from "../../../search/types";
import { fetchLocationAutocomplete } from "../../../../api/v0/search/fetchLocationAutocomplete";
import {
  getExistingStateVariables,
  populateFlightShopQueryParametersFromState,
} from "../populateShopQueryParamsSaga";
import { IStoreState } from "../../../../reducers/types";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../../search/reducer";
import { setMobileSearchProgress } from "../../../search/actions/actions";
import { IFlightShopParsedQuery } from "../../utils/parseQueryString";

const shouldRedirect = ({
  origin,
  destination,
  departureDate,
  tripCategory,
  returnDate,
  stopsOption,
}: any) =>
  !origin ||
  !destination ||
  !departureDate ||
  !stopsOption ||
  (tripCategory === TripCategory.ROUND_TRIP && !returnDate);

export function* setUpFlightShopParams(action: actions.IFetchTripSummaries) {
  const state: IStoreState = yield select();
  const history = action.history;

  let {
    departureDate,
    returnDate,
    origin,
    destination,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  } = getExistingStateVariables(state);

  const parsedQueryString: IFlightShopParsedQuery =
    yield populateFlightShopQueryParametersFromState({ state, history });

  if (
    !origin ||
    !destination ||
    !departureDate ||
    (tripCategory === TripCategory.ROUND_TRIP && !returnDate) ||
    // note: when it's from FlightWatch, it should treat parsedQueryString as the source of truth.
    parsedQueryString.isFromFlightWatch
  ) {
    const { correspondingDestination, correspondingOrigin } =
      yield fetchOriginDestination(
        parsedQueryString.origin,
        parsedQueryString.destination
      );
    [
      origin,
      destination,
      departureDate,
      returnDate,
      tripCategory,
      stopsOption,
      noLCC,
    ] = [
      correspondingOrigin,
      correspondingDestination,
      parsedQueryString.departureDate,
      parsedQueryString.returnDate,
      parsedQueryString.tripCategory,
      parsedQueryString.stopsOption as SliceStopCountFilter,
      parsedQueryString.noLCC,
    ];

    // If we are missing the data we need from both the state and the query params
    // we should redirect the user home.
    if (
      shouldRedirect({
        departureDate,
        origin,
        destination,
        returnDate,
        tripCategory,
        stopsOption,
      })
    ) {
      yield putResolve(
        setMobileSearchProgress(MobileFlightSearchStep.LocationSearch)
      );
      action.history.push(PATH_HOME);
      return;
    }
    // Order here matters because of the reducer structure.
    // Set trip category resets the returnDate field.
    yield putResolve(searchActions.setTripCategory(tripCategory));
    yield putResolve(searchActions.setOrigin(origin));
    yield putResolve(searchActions.setDestination(destination));
    yield putResolve(searchActions.setDepartureDate(departureDate));
    yield putResolve(searchActions.setReturnDate(returnDate));
    yield putResolve(searchActions.setStopsOption(stopsOption));
    yield putResolve(
      searchActions.setFareclassOptionFilter({
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      })
    );
  }

  return {
    departureDate,
    returnDate,
    origin,
    destination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  };
}

export function* fetchOriginDestination(origin: string, destination: string) {
  const originRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: origin,
  };

  const { categories: originCategories }: IResponse =
    yield fetchLocationAutocomplete(originRequestBody);
  const correspondingOrigin = originCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find((result) => result.id.code.code === origin) as ITripTerminus;

  const destinationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: destination,
  };

  const { categories: destinationCategories }: IResponse =
    yield fetchLocationAutocomplete(destinationRequestBody);
  const correspondingDestination = destinationCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find((result) => result.id.code.code === destination) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
