import { convertSecondsToDays } from "../../../utils";

export const CHOOSE_DURATION_TITLE = (useShortTitle: boolean) => {
  return useShortTitle
    ? "Choose freeze duration"
    : "Choose the duration of your price freeze.";
};

export const CHOOSE_DURATION_SUBTITLE = (
  minDurationInSeconds: number | undefined,
  maxDurationInSeconds: number | undefined,
  includeDurations: boolean
): string => {
  // Use 14 as a fallback in case there is any issue retrieving the max duration dynamically
  const minDurationInDays = minDurationInSeconds
  ? convertSecondsToDays(minDurationInSeconds)
  : 0;
  const maxDurationInDays = maxDurationInSeconds
    ? convertSecondsToDays(maxDurationInSeconds)
    : 14;
  const minHoursOrDays = minDurationInDays === 0.5 || 0 ? 12 : minDurationInDays
  const minHoursOrDaysText = minDurationInDays === 0.5 || 0 ? "hours" : "days"
  
  return (
    "Choose how long you want to freeze the price of this trip" +
    (includeDurations
      ? `, from ${minHoursOrDays} ${minHoursOrDaysText} up to ${maxDurationInDays} ${
          maxDurationInDays === 1 ? "day" : "days"
        }.`
      : ".")
  );
};

export const CHOOSE_DURATION_FOOTER_FULL_EXPLANATION = (
  cap: string
) => `<strong>If the price goes up during this time</strong> we’ll cover the difference up to ${cap} per traveler.
<br/><br/>
<strong>If the price goes down</strong>, you pay the lower price when you book with us!`;
export const CONTINUE = "Continue";

export const CHOOSE_DURATION_FOOTER_SHORT_PRICE_REMARKS = (
  isRoundTrip: boolean
) => `*Prices are per traveler, ${isRoundTrip ? "round-trip" : "one-way"}`;
export const VOID_WINDOW_TEXT =
  "If you choose to book this flight instead of freezing the price, you can cancel within 24 hours of booking for a full refund of the flight fare (per the airline's policy). Refunds usually take 1-2 billing cycles, but sometimes longer.";
