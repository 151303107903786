export const FARE_CLASS_FLIGHTS_NOT_FOUND_SUBTITLE =
  "There are no flights available in this fare category.";
export const NO_FLIGHTS_FOUND = "No flights found";
export const NO_MORE_FLIGHTS_FOUND = "Find more flights";
export const NO_FLIGHTS_FILTER =
  "Adjust your dates or reset filters to see more results.";
export const NO_MORE_FLIGHTS_FOUND_SUBTITLE =
  "Adjust your dates to see more results.";
export const ADJUST_DATES = "Adjust Dates";
export const RESET_FILTERS = "Reset Filters";
