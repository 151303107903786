import React from "react";
import { Box, Typography } from "@material-ui/core";
import { AirlineIcon, Icon, IconName, ToggleSwitch } from "halifax";
import * as textConstants from "../textConstants";
import { IBaggageSection, ITripLeg } from "../types";
import { BASIC_DETAILS_TEXT } from "../../../../search/components/FlightSearchFilter/components/FareclassOptionDetails/textConstants";

export const BaggageSection = ({
  selectedTripLeg,
  setSelectedTripLeg,
  slices,
  airports,
  fareDetails,
}: IBaggageSection) => {
  return (
    <>
      <Box className="header-container">
        <Icon name={IconName.MoneyOutline} />
        <Typography variant="h4">
          <span
            dangerouslySetInnerHTML={{
              __html: textConstants.BEFORE_CONTINUE,
            }}
          />
        </Typography>
      </Box>
      <Box className="panel-wrapper">
        {slices.length > 1 && (
          <ToggleSwitch
            className="trip-leg-toggle-root"
            size="small"
            ariaLabel={textConstants.OUTBOUND_RETURN__TOGGLE_ARIA_LABEL}
            values={[ITripLeg.OUTBOUND, ITripLeg.RETURN]}
            selectedValue={selectedTripLeg}
            setSelectedValue={(selectedValue: number) => {
              setSelectedTripLeg(selectedValue);
            }}
            getValueText={(value: number) => textConstants.getValueText(value)}
            getAriaValueText={(value: number) =>
              textConstants.getAriaValueText(value)
            }
          />
        )}

        {slices[selectedTripLeg]?.segmentDetails.map((segment, index) => {
          const originCity = airports![segment.originCode]?.cityName ?? "";
          const destinationCity =
            airports![segment.destinationCode]?.cityName ?? "";
          const checkedBaggageUta = fareDetails[0].restrictions![
            selectedTripLeg
          ].amenitiesAndUtas[index]?.utas.utas.find(
            (uta) => uta.category === "checked-bag-allowance"
          );
          return (
            <Box
              className="segment"
              key={`${segment.flightNumber}-${segment.originCode}`}
            >
              <Typography variant="body1">
                <b>{`${originCity} (${segment.originCode})`}</b> to{" "}
                <b>{`${destinationCity} (${segment.destinationCode})`}</b>
              </Typography>
              <Box className="airline">
                <AirlineIcon
                  size="1x"
                  airlineCode={segment.marketingAirline.code}
                />
                <Typography variant="caption">{segment.airlineName}</Typography>
              </Box>
              <Box className="baggage">
                <ul>
                  <li>
                    <Typography variant="body1">
                      {checkedBaggageUta?.description ?? BASIC_DETAILS_TEXT}
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
