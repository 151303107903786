import { put, select, putResolve, call } from "redux-saga/effects";

// actions
import { actions as searchActions } from "../../search/actions";
import { actions } from "../actions";

// reducers
import {
  getAirEntryProperties,
  getMultiCityAirEntryProperties,
  MulticityFlightShopStep,
} from "../reducer";

// types
import { ITripTerminusCategory } from "../../search/types";
import { MulticityRoute } from "../../search/reducer";
import { IStoreState } from "../../../reducers/types";
import {
  ICategorizedResponse,
  AirMultiCityEntryProperties,
  AIR_MULTICITY_ENTRY,
  ILocationQueryLabel,
  IResponse,
  ITripTerminus,
  LocationQueryEnum,
  SliceStopCountFilter,
  AirEntryProperties,
  AIR_ENTRY,
  ShopFilter,
  Platform,
} from "redmond";
import {
  ShopMulticitySummaryRequest,
  ShopMulticitySummaryResponse,
  ShopMulticitySummarySuccessResultEnum,
} from "@b2bportal/air-shopping-api";

// helpers, utils
import {
  getExistingStateVariables,
  populateFlightShopMulticityQueryParametersFromState,
} from "./populateShopQueryParamsSaga";
import dayjs from "dayjs";
import { PATH_HOME } from "../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../search/reducer";
import Logger from "../../../helpers/Logger";
import {
  getMulticityDepartureStringValuesFromParsedShopQuery,
  IMulticityFlightShopParsedQuery,
  parseQueryString,
} from "../utils/parseQueryString";

// api
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import { fetchMulticityTripSummaries as fetchFlights } from "../../../api/v0/shop/fetchMulticityTripSummaries";

const shouldRedirect = ({
  multicityRoutes,
  stopsOption,
}: {
  multicityRoutes: any[];
  stopsOption: SliceStopCountFilter;
}): boolean => {
  const isAtLeast2Routes = multicityRoutes.length >= 2;
  const isAllDefinedMulticityFieldsPopulated = multicityRoutes.every((r) => {
    const allFields = Object.values(r);
    return allFields.every((field) => field !== null);
  });

  return (
    !stopsOption || !isAtLeast2Routes || !isAllDefinedMulticityFieldsPopulated
  );
};

export function* fetchMulticityTripSummaries(
  action: actions.IFetchMulticityTripSummaries
) {
  try {
    const {
      routes,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    } = yield call(setUpMulticityFlightShopParams, action);

    const passengerObj = {};

    if (adultsCount > 0) passengerObj["ADT"] = adultsCount;
    if (infantsInSeatCount > 0) passengerObj["INS"] = infantsInSeatCount;
    if (infantsOnLapCount > 0) passengerObj["INF"] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj["CNN"] = childrenCount;

    let tripFilter = ShopFilter.NoFilter;
    const filterOutBasicFares = noLCC;

    if (filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }

    const requestBody: ShopMulticitySummaryRequest = {
      passengers: passengerObj,
      routes,
      tripFilter,
      platform: action.isMobile ? Platform.Mobile : Platform.Desktop,
    };

    const response: ShopMulticitySummaryResponse = yield fetchFlights(
      requestBody
    );

    yield putResolve(actions.setShopRequest(requestBody));

    const airEntryProperties: AirEntryProperties | null = yield select(
      getAirEntryProperties
    );
    yield trackEvent({
      eventName: AIR_ENTRY,
      properties: {
        ...airEntryProperties,
      },
    });

    const multiCityAirEntryProperties: AirMultiCityEntryProperties | null =
      yield select(getMultiCityAirEntryProperties);
    yield trackEvent({
      eventName: AIR_MULTICITY_ENTRY,
      properties: multiCityAirEntryProperties,
    });

    if (response.result.Result === ShopMulticitySummarySuccessResultEnum.Ok) {
      yield putResolve(
        actions.setMulticityFlights(response.result.value.flights)
      );
    } else {
      yield putResolve(actions.setMulticityFlights([]));
      yield putResolve(
        actions.setTripSummariesError(
          response.result.err.ShopMulticitySummaryErrors
        )
      );
    }

    yield put(searchActions.setAwaitingRefetch(false));
  } catch (e) {
    const errorCode: string = (e as any)[0]?.code;
    yield put(actions.setTripSummariesError(errorCode));
    Logger.debug(e);
  }
}

export function* setUpMulticityFlightShopParams(
  action: actions.IFetchMulticityTripSummaries
) {
  const state: IStoreState = yield select();
  const history = action.history;

  let {
    multicityFlightShopProgress,
    multicityRoutes,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  } = getExistingStateVariables(state);

  yield populateFlightShopMulticityQueryParametersFromState({
    state,
    history,
    isBookingStep:
      multicityFlightShopProgress === MulticityFlightShopStep.BookTrip,
    newQueryParams: { multicityFlightShopProgress },
  });

  yield putResolve(searchActions.setTripCategory(tripCategory));

  let routes = multicityRoutes.map((route) => {
    const { origin, destination, departureDate } = route;
    return {
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      origin: { ...origin?.id.code },
      destination: { ...destination?.id.code },
    };
  });

  const isAllFieldsPopulated = (routes: MulticityRoute[]) => {
    return routes.every((r) =>
      Object.values(r).every((field) => field !== null)
    );
  };

  // if we're missing values from redux state, try to pull them from query string
  if (!isAllFieldsPopulated(multicityRoutes)) {
    const parsedQueryString = parseQueryString(
      history
    ) as IMulticityFlightShopParsedQuery;
    const departureRouteValuesFromShopQuery =
      getMulticityDepartureStringValuesFromParsedShopQuery(parsedQueryString);

    let completedSearchRoutes = [];
    let updatedStateRoutes = [];

    for (const departure of departureRouteValuesFromShopQuery) {
      const { origin, destination, departureDate } = departure;
      const {
        correspondingOrigin: originToSearch,
        correspondingDestination: destinationToSearch,
      } = yield fetchOriginDestination(origin, destination);

      completedSearchRoutes.push({
        departureDate,
        origin: { ...originToSearch.id.code },
        destination: { ...destinationToSearch.id.code },
      });

      updatedStateRoutes.push({
        origin: originToSearch,
        destination: destinationToSearch,
        departureDate: dayjs(departureDate).toDate(),
      });
    }

    if (
      updatedStateRoutes.length >= 2 &&
      isAllFieldsPopulated(updatedStateRoutes)
    )
      yield putResolve(searchActions.setAllMulticityRoutes(updatedStateRoutes));

    yield putResolve(
      searchActions.setStopsOption(
        parsedQueryString.stopsOption as SliceStopCountFilter
      )
    );

    routes = completedSearchRoutes;
  }

  //  If we are missing the data we need from both the state and the query params we should redirect the user home.
  if (
    shouldRedirect({
      multicityRoutes: routes,
      stopsOption,
    })
  ) {
    yield putResolve(actions.resetSelectedTrip());
    yield putResolve(
      searchActions.setMobileSearchProgress(
        MobileFlightSearchStep.LocationSearch
      )
    );

    return action.history.push(PATH_HOME);
  }

  // Order here matters because of the reducer structure.
  yield putResolve(
    searchActions.setFareclassOptionFilter({
      basic: false,
      standard: noLCC,
      enhanced: noLCC,
      premium: noLCC,
      luxury: noLCC,
    })
  );

  return {
    routes,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  };
}

function* fetchOriginDestination(
  originString: string,
  destinationString: string
): Generator<
  {},
  {
    correspondingDestination: ITripTerminus;
    correspondingOrigin: ITripTerminus;
  },
  { categories: ICategorizedResponse[] }
> {
  const originRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: originString,
  };

  const { categories: originCategories }: IResponse =
    yield fetchLocationAutocomplete(originRequestBody);
  const correspondingOrigin = originCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find((result) => result.id.code.code === originString) as ITripTerminus;

  const destinationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: destinationString,
  };

  const { categories: destinationCategories }: IResponse =
    yield fetchLocationAutocomplete(destinationRequestBody);
  const correspondingDestination = destinationCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find(
      (result) => result.id.code.code === destinationString
    ) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
