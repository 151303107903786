export const HEADER_COPY = (useLockPriceLanguage?: boolean) =>
  `Confirm and ${useLockPriceLanguage ? "lock" : "freeze"} this price`;
export const SUBHEADER_COPY = "Enter all required details";
export const REVIEW_ITINERARY = "Review itinerary";
export const CONTACT_INFORMATION = "Contact information";
export const TRAVELERS = "Travelers";
export const REWARDS_AND_PAYMENT = "Rewards & payment";
export const REVIEW_ITINERARY_TITLE = (step: number) =>
  `Step ${step}: Review itinerary`;
export const REWARDS_AND_PAYMENT_TITLE = (step: number) =>
  `Step ${step}: Rewards and payment`;
export const CONTACT_INFO_TITLE = "Contact information";
export const CONTACT_INFO_SUBTITLE = (useLockPriceLanguage?: boolean) =>
  `Confirmation and updates to your ${
    useLockPriceLanguage ? "locked" : "frozen"
  } price will be sent to this email address.`;
export const CONFIRM_BUTTON_TEXT = (useLockPriceLanguage?: boolean) =>
  `${useLockPriceLanguage ? "Lock" : "Freeze"} this price`;
export const VOID_WINDOW_TEXT =
  "If you choose to book this flight instead of freezing the price, you can cancel within 24 hours of booking for a full refund of the flight fare (per the airline's policy). Refunds usually take 1-2 billing cycles, but sometimes longer.";
export const PRICE_FREEZE_DURATION_12_HOURS_IN_SECONDS = 43200;
export const PRICE_FREEZE_DURATION_24_HOURS_IN_SECONDS = 86400;
