import axios, { AxiosResponse } from "axios";
import { CustomerDetailsResponse } from "redmond";

import { config } from "../../config";

import { customerDetailsApiPath } from "../paths";

export const fetchCustomerDetails = (): Promise<CustomerDetailsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<CustomerDetailsResponse> = await axios.get(customerDetailsApiPath, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
