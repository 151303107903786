import React from "react";
import { TimeToLive } from "redmond";
import { getDurationText } from "../../../constants";

export const SHOW_MORE_NUM = 5;
export const INITIAL_RESULT_SET_SIZE = 10;
export const LOADING_FLIGHTS_STRING = "Loading Flights..";
export const LOADING_FLIGHT_DETAILS_STRING = "Loading Flight Details";

export const FLIGHTS_NOT_FOUND_TITLE = "No flights found";
export const GENERIC_FLIGHTS_NOT_FOUND_SUBTITLE =
  "There are no flights available.";
export const FARE_CLASS_FLIGHTS_NOT_FOUND_SUBTITLE =
  "There are no flights available in this fare category.";
export const FITLERED_FLIGHTS_NOT_FOUND_SUBTITLE =
  "There are no flights available that match this filter criteria.";
export const FLIGHTS_NOT_FOUND_TITLE_AIR_CX_V3 =
  "There are no flights available that match your filter criteria.";
export const FLIGHTS_NOT_FOUND_SUBTITLE_AIR_CX_V3 =
  "Clear filters to view more flights on this route, or change your dates.";

export const RESTRICTED_CITY_SUBTITLE = "Please try another city";
export const RESTRICTED_CITY_TITLE =
  "We do not currently have flights servicing from/to a location in your search parameters";
export const RESTRICTED_COUNTRY_SUBTITLE = "Please search again";
export const RESTRICTED_COUNTRY_TITLE =
  "We do not currently have flights servicing from/to a location in your search parameters";

export const ERROR_TITLE = "Oops…something went wrong!";
export const ERROR_SUBTITLE =
  "Check to see if you are connected to a network right now or try reloading.";
export const RELOAD = "Reload";
export const SEARCH_AGAIN = "Search Again";
export const NO_FLIGHTS_FOUND = "No flights found";
export const ADJUST_DATES = "Adjust Dates";
export const RESET_FILTERS = "Reset Filters";
export const FREEZE_PRICE = (useLockPriceLanguage?: boolean) =>
  `${useLockPriceLanguage ? "Lock" : "Freeze"} Price`;

export const PF_MERCH_MODAL_HEADER = (useLockPriceLanguage?: boolean) =>
  `Here are the flights that you’ve chosen to ${
    useLockPriceLanguage ? "lock" : "freeze"
  }.`;
export const getPriceFreezeMerchModalSubtitle = ({
  price,
  rewards,
  timeToLive,
  useLockPriceLanguage,
}: {
  price: string;
  rewards?: string;
  timeToLive: TimeToLive | undefined;
  useLockPriceLanguage?: boolean;
}) =>
  `Review your itinerary and continue on to ${
    useLockPriceLanguage ? "lock" : "freeze"
  } the current price of your trip for ${
    timeToLive ? getDurationText(timeToLive) : ""
  } for a fee of <strong>${price}</strong>${
    rewards ? ` or <strong>${rewards}</strong>` : ""
  } per traveler.`;
export const CURRENT_FROZEN_PRICE = (useLockPriceLanguage?: boolean) =>
  `Current price being ${useLockPriceLanguage ? "locked" : "frozen"}:`;

export const getSelectCtaCopy = (
  input: string,
  usePriceText?: boolean,
  isMobile?: boolean
) =>
  usePriceText ? (
    <>
      {!isMobile ? "Continue for " : ""}
      <strong>{input}</strong>
    </>
  ) : (
    `Select ${input}`
  );
export const getPriceFreezeNoticeCopy = ({
  price,
  rewards,
  timeToLive,
  useLockPriceLanguage,
}: {
  price: string;
  rewards?: string;
  timeToLive: TimeToLive | undefined;
  useLockPriceLanguage?: boolean;
}) =>
  `${
    useLockPriceLanguage ? "Lock" : "Freeze"
  } the current price of your trip for ${
    timeToLive ? getDurationText(timeToLive) : ""
  } for a fee of <strong>${price}</strong>${
    rewards ? ` or <strong>${rewards}</strong>` : ""
  } per traveler.`;

export const NONSTOP_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT = (
  <>
    You’ve reached the end of the <strong>nonstop flights</strong> for this
    route.
  </>
);

export const NONSTOP_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT =
  "Below are all remaining flights, sorted by duration.";
