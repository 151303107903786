import { connect } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  getTripCategory,
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
} from "../../reducer";
import { TripCategory } from "redmond";
import { withRouter } from "react-router";
import { ActionButtonWithRoute } from "halifax";

interface IOwnProps {
  enabled?: boolean;
  return?: Date | null;
  departure?: Date;
}
const mapStateToProps = (state: IStoreState, ownProps: IOwnProps) => {
  const tripCategory = getTripCategory(state);
  const origin = getOrigin(state);
  const destination = getDestination(state);
  const departureDate = getDepartureDate(state) || ownProps.departure;
  const returnDate = getReturnDate(state) || ownProps.return;

  const areTerminiSet = origin !== null && destination !== null;
  const areJuncturesSet =
    returnDate !== null ||
    (departureDate !== null && tripCategory === TripCategory.ONE_WAY);
  return {
    disabled: ownProps.enabled
      ? !ownProps.enabled
      : !(areTerminiSet && areJuncturesSet),
  };
};

const mapDispatchToProps = {};

export const ConnectedFlightSearchButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActionButtonWithRoute));
