import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { TripCategory } from "redmond";
import { PriceFreezeDurationSelection } from "../index";
import * as constants from "./constants";
import { DesktopPriceFreezeDurationDetailsConnectorProps } from "./container";
import "./styles.scss";
import { PriceFreezeDurationSelectionMethod } from "../../../utils";
import clsx from "clsx";

export interface IDesktopPriceFreezeDurationDetailsProps
  extends DesktopPriceFreezeDurationDetailsConnectorProps,
    RouteComponentProps {
  titleStep?: number;
}

export const DesktopPriceFreezeDurationDetails = (
  props: IDesktopPriceFreezeDurationDetailsProps
) => {
  const {
    titleStep = 2,
    tripCategory,
    priceFreezeMaxDuration,
    priceFreezeUserSelectedDurationSelectionMethod: selectionMethod,
    priceFreezeMinOffer,
  } = props;

  const alignFooter =
    selectionMethod === PriceFreezeDurationSelectionMethod.Buttons
      ? "left"
      : "center";

  const includeDurationsInSubtitle =
    selectionMethod !== PriceFreezeDurationSelectionMethod.Buttons;

  const hideDetailsSectionTopCriteria = {
    [PriceFreezeDurationSelectionMethod.Buttons]: true,
    [PriceFreezeDurationSelectionMethod.Slider]: false,
    [PriceFreezeDurationSelectionMethod.Toggle]: false,
  };

  const hideDetailsSectionTop = hideDetailsSectionTopCriteria[selectionMethod];

  const hideDetailsSectionBottomCriteria = {
    [PriceFreezeDurationSelectionMethod.Buttons]: true,
    [PriceFreezeDurationSelectionMethod.Slider]: true,
    [PriceFreezeDurationSelectionMethod.Toggle]: true,
  };

  const hideDetailsSectionBottom =
    hideDetailsSectionBottomCriteria[selectionMethod];

  return (
    <Box className="desktop-price-freeze-duration-details-root">
      <Box className="desktop-price-freeze-duration-details-container">
        <Box className="header-section">
          <Typography className="title" variant="h2">
            {constants.CHOOSE_DURATION_TITLE(titleStep)}
          </Typography>
          <Typography className="subtitle" variant="body2">
            {constants.CHOOSE_DURATION_SUBTITLE(
              priceFreezeMinOffer?.secondsTtl,
              priceFreezeMaxDuration,
              includeDurationsInSubtitle
            )}
          </Typography>
        </Box>
        <Box
          className={clsx("duration-selection-section", {
            "hide-top-section": hideDetailsSectionTop,
          })}
        >
          <PriceFreezeDurationSelection
            textSize="large"
            selectionMethod={selectionMethod}
            hideDetailsSectionTop={hideDetailsSectionTop}
            hideDetailsSectionBottom={hideDetailsSectionBottom}
          />
        </Box>
        <Box className={clsx("footer-section", alignFooter)}>
          <Typography className="footer-copy" variant="body2">
            {constants.CHOOSE_DURATION_FOOTER(
              tripCategory === TripCategory.ROUND_TRIP
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
