import axios from "axios";
import { PersonId } from "redmond";
import { airBookingApi } from "@b2bportal/air-booking-api";

export const deleteUserPassenger = (
  body: IDeleteUserPassengerRequest
): Promise<IDeleteUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(axios as any).apiV0FlightPassengersDeletePost(
        body
      );

      const responseBody: IDeleteUserPassengerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IDeleteUserPassengerRequest {
  personId: PersonId;
}

export interface IDeleteUserPassengerResponse {}
