import axios from "axios";
import { config } from "../../config";
import { optInSubscriptionApiPrefix } from "../paths";

export const optInSubscription = (body: { optIn: boolean }): Promise<{}> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(optInSubscriptionApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default optInSubscription;
