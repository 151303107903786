import { createSelector } from "@reduxjs/toolkit";
import {
  PassengerTypes,
  FiatPrice,
  Airport,
  FareDetails,
  TripDetails,
  Offer,
  CurrentFareVersusCapEnum,
  Prices,
  RewardsPrice,
  PriceFreezeFrozenFare,
  TripCategory,
  AncillaryFetchOfferRequest,
  ShopFilter,
  PriceFreezeReviewDetailsProps,
  PriceFreezeOfferDataWithRewards,
  ChangedPriceFreezeDurationProperties,
  PriceFreezePriceQuote,
  PollFareQuoteOutcomeEnum,
  PriceFreezeStatusEnum,
} from "redmond";
import {
  removeTimezone,
  formatInterval,
  getRewardText,
  getTotalPassengerFromPaxPricing,
  getTotalPriceText,
  getCurrencySymbol,
  getPriceString,
  IFlightSummaryPanelProps,
  truncateToTwoDecimals,
} from "halifax";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import {
  allTripSummariesSelector,
  selectedFareDetailsSelector,
  selectedTripSelector,
  selectedTripIdSelector,
  flightShopProgressSelector,
  tripDetailsByIdSelector,
  selectedCfarOfferPricesSelector,
  selectedDisruptionProtectionOfferPricesSelector,
  getPriceFreezeOfferWithSuggested,
  getPriceFreezeOfferCheapestTripTripId,
  getPriceFreezeOfferCheapestTripFareId,
  selectedChfarOfferPricesSelector,
} from "../../../shop/reducer/selectors";
import { getCustomizeCheckoutBreakdownTotalPrices } from "../../../shop/reducer/utils";
import {
  initialState,
  FlightShopStep,
  getPlusDays,
  ISelectedTrip,
} from "../../../shop/reducer";
import {
  getAddedLegacyPrices,
  getMultipliedLegacyPrices,
} from "../../../book/reducer/utils/pricingHelpers";
import {
  getSelectedAccountReferenceId,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import {
  customPriceFreezeOfferSelector,
  priceFreezeOfferDataSelector,
  selectedPriceFreezeOfferDataIndexSelector,
  priceFreezeOfferDataObjectWithTtlKeySelector,
  priceFreezeMinMaxDurationsPropertiesSelector,
  isPriceFreezeDurationDefault12HrCopyActiveSelector,
  isPriceFreezeDurationActiveSelector,
  priceFreezeDefaultDurationsSelector,
  priceFreezeMiddleOfferIndexSelector,
} from "./priceFreezeDuration";
import * as textConstants from "./textConstants";
import {
  CORP_HIDE_PRICE_DROP_EXPERIMENT,
  AVAILABLE,
} from "../../../../context/experiments";

export const priceFreezeOfferSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeOffer;

export const priceFreezeOfferCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeOfferCallState;

export const setUpFlightFreezeParamsCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.setUpFlightFreezeParamsCallState;

export const getDisplayPfRefundModalSelector = (state: IStoreState) =>
  state.flightFreeze.displayPriceFreezeRefundModal;

export const priceFreezeCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeCallState;

export const priceFreezePassengerCountsSelector = (state: IStoreState) =>
  state.flightFreeze.counts;

export const priceFreezeQuoteDataSelector = (state: IStoreState) =>
  state.flightFreeze.quoteData;

export const priceFreezeFareQuoteTokenKeySelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeFareQuoteTokenKey;

export const priceFreezeShouldIgnoreFareQuoteSelector = (state: IStoreState) =>
  state.flightFreeze.shouldIgnoreQuote;

export const priceFreezeFareQuoteOutcomeSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeFareQuoteOutcome;

export const priceFreezeFareQuoteCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeFareQuoteCallState;

export const priceFreezeFareQuoteErrorTitlesTypeSelector = (
  state: IStoreState
) => state.flightFreeze.priceFreezeFareQuoteErrorTitlesType;

export const priceFreezeFareQuoteAcknowledgedSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeFareQuoteAcknowledged;

export const isTripSummariesEmptySelector = createSelector(
  allTripSummariesSelector,
  (allTripSummaries): boolean => {
    return allTripSummaries === initialState.tripSummariesById;
  }
);

export const currentPriceFreezeSelector = (state: IStoreState) =>
  state.flightFreeze.currentPriceFreeze?.priceFreeze;
export const currentPriceFreezeTripContextSelector = (state: IStoreState) =>
  state.flightFreeze.currentPriceFreeze?.tripContext;
export const currentPriceFreezePriceDropProtectionSelector = (
  state: IStoreState
) => {
  if (
    state.flightShop.experiments?.[CORP_HIDE_PRICE_DROP_EXPERIMENT] ===
    AVAILABLE
  ) {
    return null;
  }
  return state.flightFreeze.currentPriceFreeze?.priceDropProtection;
};

export const getRefundCallStateSelector = (state: IStoreState) =>
  state.flightFreeze.refundPriceFreezeCallState;

export const passengerCountSelector = createSelector(
  priceFreezePassengerCountsSelector,
  (counts) => {
    const {
      adultsCount,
      infantsInSeatCount,
      infantsOnLapCount,
      childrenCount,
    } = counts;
    const passengerObj = {};
    if (adultsCount > 0) passengerObj[PassengerTypes.Adult] = adultsCount;
    if (infantsInSeatCount > 0)
      passengerObj[PassengerTypes.InfantInSeat] = infantsInSeatCount;
    if (infantsOnLapCount > 0)
      passengerObj[PassengerTypes.InfantInLap] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj[PassengerTypes.Child] = childrenCount;
    return passengerObj;
  }
);

export const currentPriceFreezeOfferSelector = createSelector(
  customPriceFreezeOfferSelector,
  priceFreezeOfferSelector,
  (customPriceFreezeOffer, shopSummaryPriceFreezeOffer): Offer | null => {
    return customPriceFreezeOffer ?? shopSummaryPriceFreezeOffer;
  }
);

export const isPriceFreezeOfferAvailableSelector = createSelector(
  currentPriceFreezeOfferSelector,
  (offer): boolean => {
    return !!(offer && offer.id);
  }
);

export const priceFreezeOfferCapSelector = createSelector(
  currentPriceFreezeOfferSelector,
  (offer): string => {
    const currency = offer?.cap.value.currency;
    return getPriceString({
      price: offer?.cap.value.amount || 0,
      currencySymbol: currency ? getCurrencySymbol(currency) : undefined,
    });
  }
);

export const selectFareDetailsSinglePassenger = createSelector(
  selectedFareDetailsSelector,
  (selectedFareDetails) => {
    if (!selectedFareDetails || !selectedFareDetails.paxPricings) {
      return;
    }
    const farePricing = selectedFareDetails.paxPricings[0]?.pricing;

    return (
      farePricing.total ??
      getAddedLegacyPrices(farePricing.baseAmount, farePricing.taxAmount)
    );
  }
);
export const estimatedPriceFreezeQuoteDataSelector = createSelector(
  isPriceFreezeDurationActiveSelector,
  customPriceFreezeOfferSelector,
  priceFreezeOfferSelector,
  selectedFareDetailsSelector,
  priceFreezePassengerCountsSelector,
  priceFreezeQuoteDataSelector,
  (
    isPriceFreezeDurationActive,
    customPriceFreezeOffer,
    priceFreezeOffer,
    selectedFareDetails,
    priceFreezePassengerCounts,
    priceFreezeQuoteData
  ): PriceFreezePriceQuote | null => {
    let offer: Offer;
    let singlePassenger: Prices;

    if (isPriceFreezeDurationActive && !!customPriceFreezeOffer) {
      offer = customPriceFreezeOffer;
    } else if (!isPriceFreezeDurationActive && !!priceFreezeOffer) {
      offer = priceFreezeOffer;
    } else {
      return null;
    }

    if (!!priceFreezeQuoteData) {
      singlePassenger = priceFreezeQuoteData.frozenPricing.singlePassenger;
    } else if (!!selectedFareDetails?.paxPricings) {
      const farePricing = selectedFareDetails.paxPricings[0]?.pricing;
      singlePassenger =
        farePricing.total ??
        getAddedLegacyPrices(farePricing.baseAmount, farePricing.taxAmount);
    } else {
      return null;
    }

    const perPaxAmount = offer.perPaxAmount;
    const passengerQuantity =
      priceFreezePassengerCounts.adultsCount +
      priceFreezePassengerCounts.childrenCount +
      priceFreezePassengerCounts.infantsInSeatCount;

    return {
      // note: the configuration field is not consumed anywhere, so it shouldn't affect anything
      configurationId: "",
      offer,
      frozenPricing: {
        singlePassenger,
        passengerQuantity,
      },
      totalAmount: getMultipliedLegacyPrices(perPaxAmount, passengerQuantity),
      perPaxAmount,
    };
  }
);

export interface IPriceFreezeOverview {
  expiresAt: string;
  tripDetails: TripDetails | null;
  fareDetails: FareDetails | null;
  airports: { [key: string]: Airport } | null;
  offer: Offer | null;
  currentTripPricePerPax: { fiat: string; rewards: string } | null;
  isRoundTrip: boolean;
  priceFreezeActiveStatus: boolean;
  totalPassengers: number;
  savingsAmount: string | null;
  chargedTripPricePerPax: { fiat: string; rewards: string } | null;
  frozenTripPricePerPax: { fiat: string; rewards: string } | null;
  capAmount: string | null;
  hasReachedFareCap: boolean;
  isCurrentPriceLowerThanFrozen: boolean;
  fareDetailsTrip: PriceFreezeFrozenFare | null;
  priceFreezeId: string | null;
  status?: PriceFreezeStatusEnum;
}

export const getCurrentPriceFreezeDataForOverviewPage = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  (
    priceFreeze,
    tripContext,
    selectedRewardsAccountId
  ): IPriceFreezeOverview => {
    if (priceFreeze && tripContext) {
      const getPrices = (amount: Prices | undefined) => {
        if (!amount)
          return {
            fiat: "",
            rewards: "",
          };

        const rewards = amount.rewards[selectedRewardsAccountId ?? ""];

        return {
          fiat: getTotalPriceText({
            price: amount?.fiat,
          }),
          rewards: rewards
            ? getRewardText({
                reward: rewards,
              })
            : "",
        };
      };

      const selectedFare = priceFreeze.frozenFare.paxPricings[0];
      //TODO: use perpax amount when avalaible on the endpoint
      //Current Trip price
      const currentTripPrice = selectedFare.pricing.currentAmount;
      const currentTripPricePerPax = getPrices(currentTripPrice);

      //Charged Trip Price
      const chargedTripPricePerPax = getPrices(
        selectedFare.pricing.chargeAmount
      );

      //Frozen Trip Price
      const frozenTripPrice = selectedFare.pricing.originalAmount;
      const frozenTripPricePerPax = getPrices(frozenTripPrice);
      const isCurrentPriceLowerThanFrozen =
        (currentTripPrice?.fiat.value ?? 0) < frozenTripPrice.fiat.value;

      //Total passenger from offer
      const offer = priceFreeze.priceFreeze.offer;
      const totalPassengers = getTotalPassengerFromPaxPricing(
        priceFreeze.frozenFare.paxPricings
      );

      //Selected fare details
      const fareDetails =
        priceFreeze.tripDetails.fareDetails.find(
          (fare) => fare.id === priceFreeze.frozenFare.id
        ) ?? null;

      return {
        expiresAt: priceFreeze.priceFreeze.expiresAt || "",
        tripDetails: priceFreeze.tripDetails,
        fareDetails,
        airports: tripContext.airports,
        offer,
        currentTripPricePerPax,
        totalPassengers,
        isRoundTrip: priceFreeze.tripDetails.slices.length === 2,
        priceFreezeActiveStatus: priceFreeze.status.Status === "IsActive",
        savingsAmount:
          selectedFare.pricing.savingsAmount.fiat.value != 0
            ? getTotalPriceText({
                price: selectedFare.pricing.savingsAmount.fiat,
              })
            : null,
        chargedTripPricePerPax,
        frozenTripPricePerPax,
        hasReachedFareCap:
          selectedFare.pricing.versusCap.CurrentFareVersusCap ==
          CurrentFareVersusCapEnum.FareOverCap,
        capAmount: getTotalPriceText({
          price: {
            currencyCode: offer.cap.value.currency,
            currencySymbol: getCurrencySymbol(offer.cap.value.currency),
            value: offer.cap.value.amount,
          },
        }),
        isCurrentPriceLowerThanFrozen,
        fareDetailsTrip: priceFreeze.frozenFare,
        priceFreezeId: priceFreeze.priceFreeze.id,
        status: priceFreeze.status.Status,
      };
    }
    return {
      expiresAt: "",
      tripDetails: null,
      fareDetails: null,
      airports: null,
      offer: null,
      currentTripPricePerPax: null,
      frozenTripPricePerPax: null,
      isRoundTrip: true,
      priceFreezeActiveStatus: true,
      totalPassengers: 1,
      savingsAmount: null,
      chargedTripPricePerPax: null,
      capAmount: null,
      hasReachedFareCap: false,
      isCurrentPriceLowerThanFrozen: false,
      fareDetailsTrip: null,
      priceFreezeId: null,
    };
  }
);

export const getFrozenPriceFromPriceFreeze = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  (
    priceFreeze,
    tripContext,
    selectedAccountReferenceId
  ): { fiat: FiatPrice; rewards: RewardsPrice | undefined } | null => {
    if (!priceFreeze) {
      return null;
    }
    if (!tripContext) {
      return null;
    }

    // note: taking the first one from list because all passenger types have the same price
    const selectedFare = priceFreeze.frozenFare.paxPricings[0];

    if (!selectedFare) {
      return null;
    }

    const tripFarePrices = selectedFare.pricing.originalAmount;

    return {
      fiat: tripFarePrices.fiat,
      rewards: selectedAccountReferenceId
        ? tripFarePrices.rewards[selectedAccountReferenceId]
        : undefined,
    };
  }
);

export const frozenPriceSavingsCapSelector = createSelector(
  currentPriceFreezeSelector,
  (priceFreeze): FiatPrice => {
    const cap = priceFreeze?.priceFreeze?.offer?.cap?.value;
    // TODO: cap should be a FiatPrice from the BE
    const capFiatPrice: FiatPrice = {
      currencyCode: cap?.currency ?? "USD",
      currencySymbol: "$",
      value: cap?.amount ?? 0,
    };
    return capFiatPrice;
  }
);

export const getTripCategoryFromPriceFreeze = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  (priceFreeze, tripContext): TripCategory | null => {
    if (!priceFreeze) {
      return null;
    }
    if (!tripContext) {
      return null;
    }

    const slices = priceFreeze.tripDetails.slices;
    const tripCategory =
      slices.length > 1 ? TripCategory.ROUND_TRIP : TripCategory.ONE_WAY;

    return tripCategory;
  }
);

// TODO: given that this logic is heavily duplicated in many components, they should be refactored to use the same selector
export const getFlightSummaryPanelPropsFromPriceFreeze = createSelector(
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
  getTripCategoryFromPriceFreeze,
  flightShopProgressSelector,
  (
    priceFreeze,
    tripContext,
    tripCategory,
    flightShopProgress
  ): IFlightSummaryPanelProps | null => {
    if (!priceFreeze) {
      return null;
    }
    if (!tripContext) {
      return null;
    }

    const { airports } = tripContext;
    const slices = priceFreeze.tripDetails.slices;

    const isChooseReturn = flightShopProgress === FlightShopStep.ChooseReturn;
    const tripSlice =
      tripCategory === TripCategory.ROUND_TRIP && isChooseReturn
        ? slices[1]
        : slices[0];

    const { type, description } = textConstants.getReviewCardHeaderWithType({
      isDeparture: !isChooseReturn,
      location: airports[tripSlice.destinationCode]
        ? airports[tripSlice.destinationCode].cityName
        : tripSlice.destinationName,
      date: removeTimezone(tripSlice.departureTime),
      airportCode: tripSlice.destinationCode,
      bold: true,
    });

    const flightSummary: IFlightSummaryPanelProps = {
      airlineCode: tripSlice.segmentDetails[0].airlineCode,
      departureDescriptionBold: type,
      departureDescription: description,
      formattedDepartureTime: dayjs(
        removeTimezone(tripSlice.departureTime)
      ).format("h:mm A"),
      formattedArrivalTime: dayjs(removeTimezone(tripSlice.arrivalTime)).format(
        "h:mm A"
      ),
      airline: tripSlice.segmentDetails[0].airlineName,
      duration: formatInterval(tripSlice.totalDurationMinutes || 0),
      stopString: textConstants.getStopsString(tripSlice.stops),
      plusDays: getPlusDays(tripSlice),
    };

    return flightSummary;
  }
);

interface ISimilarFlightSelectedTripDetailsProperties {
  price_freeze_id: string;
  exercise_price_usd: number;
  frozen_price_pax_count: number;
  frozen_price_total_usd: number;
  price_freeze_actual_OTM_total_usd: number;
}

export const getSimilarFlightSelectedTripDetailsProperties = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  currentPriceFreezeSelector,
  (
    tripDetails,
    selectedTrip,
    priceFreeze
  ): ISimilarFlightSelectedTripDetailsProperties | null => {
    if (selectedTrip?.tripId) {
      const selectedOWRTrip = selectedTrip as ISelectedTrip;
      const fareId =
        selectedOWRTrip?.returnFareId ?? selectedOWRTrip?.outgoingFareId;
      const selectedFare = tripDetails[selectedTrip.tripId].fareDetails.find(
        (fareDetail) => fareDetail.id === fareId
      );
      const newFlightPrice =
        selectedFare?.paxPricings?.[0]?.pricing.total?.fiat.value ?? 0;
      const frozenFlightPrice =
        priceFreeze?.frozenFare.paxPricings[0]?.pricing.originalAmount.fiat
          .value ?? 0;
      const passengerCount = priceFreeze
        ? getTotalPassengerFromPaxPricing(priceFreeze.frozenFare.paxPricings)
        : 1;
      /*
        note: price_freeze_actual_OTM_total_usd = new_flight_price - frozen_flight_price;
        where new_flight_price is the price of the selected similar flight
      */
      const actualOtmTotal: number =
        (newFlightPrice - frozenFlightPrice) * passengerCount;
      const otmTotal = Math.max(Number(actualOtmTotal.toFixed(2)), 0);

      if (selectedFare) {
        return {
          price_freeze_id: priceFreeze?.priceFreeze.id || "",
          exercise_price_usd:
            selectedFare.paxPricings?.[0].pricing.total?.fiat.value || 0,
          frozen_price_pax_count: passengerCount,
          frozen_price_total_usd:
            priceFreeze?.frozenFare.totalPricing?.originalAmount.fiat.value ||
            0,
          price_freeze_actual_OTM_total_usd: otmTotal,
        };
      }
    }
    return null;
  }
);

export const fetchAncillaryOfferRequestParametersByPriceFreezeSelector =
  createSelector(
    currentPriceFreezeSelector,
    (currentPriceFreeze): AncillaryFetchOfferRequest | null => {
      if (currentPriceFreeze) {
        const { tripDetails, frozenFare, priceFreeze } = currentPriceFreeze;
        const tripId = tripDetails.id;
        const fareId = frozenFare.id;
        const priceFreezeId = priceFreeze.id;

        let passengers: { [key in string]: number } = {};
        frozenFare.paxPricings.forEach((pricing) => {
          if (passengers[pricing.paxType]) {
            passengers[pricing.paxType] += pricing.quantity;
          } else {
            passengers[pricing.paxType] = pricing.quantity;
          }
        });

        return {
          tripId,
          fareId,
          passengers,
          /*
            note: confirmed with Akhil that it can be assigned with NoFilter;
            this param will be removed from AncillaryFetchOfferRequest in the future.
          */
          tripFilter: ShopFilter.NoFilter,
          priceFreezeId,
        };
      } else {
        return null;
      }
    }
  );

export const priceFreezeReviewDetailsPropertiesSelector = createSelector(
  customPriceFreezeOfferSelector,
  priceFreezeOfferSelector,
  selectedFareDetailsSelector,
  (
    customPriceFreezeOffer,
    priceFreezeOffer,
    selectedFare
  ): PriceFreezeReviewDetailsProps => {
    const frozenPrice =
      selectedFare && (selectedFare.paxPricings?.length || 0) > 0
        ? selectedFare.paxPricings?.[0].pricing
        : undefined;

    const offer = customPriceFreezeOffer || priceFreezeOffer;

    return {
      price_freeze_duration: offer?.timeToLive.inSeconds,
      price_freeze_otm_cap_usd: offer?.cap.value.amount,
      price_freeze_total_cost: offer?.totalAmount.fiat.value,
      price_freeze_cost_per_pax: offer?.perPaxAmount.fiat.value,
      frozen_price_total_usd: frozenPrice?.total?.fiat.value,
    };
  }
);

export const priceFreezeChargeAmountPricesSelector = createSelector(
  currentPriceFreezeSelector,
  (priceFreeze): Prices | undefined => {
    return priceFreeze?.frozenFare.paxPricings[0].pricing.chargeAmount;
  }
);

export const priceFreezeFareTaxAmountSelector = createSelector(
  currentPriceFreezeSelector,
  (priceFreeze): Prices | undefined =>
    priceFreeze?.frozenFlight.fareDetails[0].totalPricing?.taxAmount
);

export const priceFreezeCustomizeCheckoutBreakdownTotalPricesSelector =
  createSelector(
    priceFreezeChargeAmountPricesSelector,
    getSelectedAccountReferenceId,
    selectedCfarOfferPricesSelector,
    selectedChfarOfferPricesSelector,
    selectedDisruptionProtectionOfferPricesSelector,
    (
      priceFreezeChargeAmount,
      accountReferenceId,
      cfarOfferPrices,
      chfarOfferPrices,
      disruptionProtectionOfferPrices
    ): { fiat: FiatPrice; rewards: RewardsPrice | undefined } | null => {
      if (priceFreezeChargeAmount) {
        return getCustomizeCheckoutBreakdownTotalPrices({
          fareTotalPrices: priceFreezeChargeAmount,
          cfarOfferPrices,
          chfarOfferPrices,
          accountReferenceId,
          disruptionProtectionOfferPrices,
        });
      }

      return null;
    }
  );

// PF Duration selectors
export const defaultPriceFreezeOfferSelector = createSelector(
  getPriceFreezeOfferWithSuggested,
  priceFreezeOfferSelector,
  (offerFromShopSummary, offerFromOfferGet): Offer | null => {
    /*
      note: the default PF offer is returned from the shopSummary endpoint; offerFromOfferGet is the same offer (e.g: offer with the same offer id),
      but it's retrieved through calling the /offer/get endpoint with the offer id.
    */
    return offerFromOfferGet ?? offerFromShopSummary?.offer ?? null;
  }
);

export const selectedAndValidPriceFreezeOfferDataIndexSelector = createSelector(
  selectedPriceFreezeOfferDataIndexSelector,
  priceFreezeOfferDataObjectWithTtlKeySelector,
  priceFreezeOfferDataSelector,
  defaultPriceFreezeOfferSelector,
  isPriceFreezeDurationDefault12HrCopyActiveSelector,
  priceFreezeDefaultDurationsSelector,
  priceFreezeMiddleOfferIndexSelector,
  (
    selectedPriceFreezeOfferDataIndex,
    offerDataObject,
    offerDataList,
    defaultPriceFreezeOffer,
    isPriceFreezeDurationDefault12HrCopyActive,
    priceFreezeDefaultDurationsXp,
    priceFreezeMiddleDurationOfferIndex
  ): number | null => {
    const selectedOfferIndex =
      offerDataList !== null &&
      selectedPriceFreezeOfferDataIndex !== null &&
      selectedPriceFreezeOfferDataIndex < offerDataList.length
        ? selectedPriceFreezeOfferDataIndex
        : null;

    // Note that defaultDurationInDays does not have to be an integer (e.g. 12 hours = 0.5 days)
    // We should still be able to access offerDataObject with defaultDurationInDays of 0.5 (i.e. offerDataObject[0.5])
    // The `.index` property will always be an integer, as that refers to the position of the offer
    // in the offer data array.
    const defaultDurationInDays = defaultPriceFreezeOffer?.timeToLive.inDays;
    const defaultOfferIndex = defaultDurationInDays
      ? offerDataObject[defaultDurationInDays]?.index ?? null
      : null;

    const firstOfferIndex = offerDataList && offerDataList.length ? 0 : null;

    // TODO: Remove the current index selection code below and use the commented return statement
    // once BF-747 Backend is done.
    // That BE ticket should switch the default duration from 1d to 12hr.
    // In the meantime, we will use the first index instead of the default index to show a lower price for PF.
    //
    //return selectedOfferIndex ?? defaultOfferIndex ?? firstOfferIndex;
    let effectiveDefaultOfferIndex;
    if (priceFreezeDefaultDurationsXp === "Middle") {
      effectiveDefaultOfferIndex = priceFreezeMiddleDurationOfferIndex;
    } else if (isPriceFreezeDurationDefault12HrCopyActive) {
      effectiveDefaultOfferIndex = firstOfferIndex ?? defaultOfferIndex;
    } else {
      effectiveDefaultOfferIndex = defaultOfferIndex ?? firstOfferIndex;
    }

    return selectedOfferIndex ?? effectiveDefaultOfferIndex;
  }
);

export const selectedAndValidPriceFreezeOfferDataSelector = createSelector(
  selectedAndValidPriceFreezeOfferDataIndexSelector,
  priceFreezeOfferDataSelector,
  (
    selectedOfferDataIndex,
    offerDataList
  ): PriceFreezeOfferDataWithRewards | null => {
    if (selectedOfferDataIndex !== null && offerDataList !== null) {
      return offerDataList[selectedOfferDataIndex];
    }

    return null;
  }
);

export const selectedPriceFreezeOfferCapSelector = createSelector(
  selectedAndValidPriceFreezeOfferDataSelector,
  (selectedPriceFreezeOfferData) => {
    return selectedPriceFreezeOfferData?.cap;
  }
);

export const selectedPriceFreezeOfferTtlSelector = createSelector(
  selectedAndValidPriceFreezeOfferDataSelector,
  (selectedPriceFreezeOfferData) => {
    return selectedPriceFreezeOfferData?.secondsTtl;
  }
);

export const selectedPriceFreezeOfferPricesSelector = createSelector(
  selectedAndValidPriceFreezeOfferDataSelector,
  getSelectedAccountReferenceId,
  (
    selectedPriceFreezeOfferData,
    selectedAccountReferenceId
  ): { fiat: FiatPrice; reward: RewardsPrice | undefined } | undefined => {
    const prices = selectedPriceFreezeOfferData?.price;

    if (prices) {
      const reward = selectedAccountReferenceId
        ? prices.rewards[selectedAccountReferenceId]
        : undefined;

      return {
        fiat: prices.fiat,
        reward,
      };
    }

    return undefined;
  }
);

export const changedPriceFreezeDurationPropertiesSelector = createSelector(
  priceFreezeMinMaxDurationsPropertiesSelector,
  defaultPriceFreezeOfferSelector,
  customPriceFreezeOfferSelector,
  (
    priceFreezeMinMaxDurationsProperties,
    defaultPriceFreezeOffer,
    customPriceFreezeOffer
  ): ChangedPriceFreezeDurationProperties => {
    return {
      selected_duration: customPriceFreezeOffer?.timeToLive.inSeconds,
      selected_fee_price_per_traveler_usd:
        customPriceFreezeOffer?.perPaxAmount.fiat.value,
      price_freeze_otm_cap_usd: customPriceFreezeOffer?.cap.value.amount,
      default_fee_price_per_traveler_usd:
        defaultPriceFreezeOffer?.perPaxAmount.fiat.value,
      default_duration_offered: defaultPriceFreezeOffer?.timeToLive.inSeconds,
      ...priceFreezeMinMaxDurationsProperties,
    };
  }
);

export const generateCustomOfferRequestSelector = createSelector(
  flightShopProgressSelector,
  getPriceFreezeOfferCheapestTripTripId,
  getPriceFreezeOfferCheapestTripFareId,
  selectedTripIdSelector,
  selectedFareDetailsSelector,
  defaultPriceFreezeOfferSelector,
  passengerCountSelector,
  (
    flightShopProgress,
    priceFreezeOfferCheapestTripTripId,
    priceFreezeOfferCheapestTripFareId,
    selectedTripId,
    selectedFareDetails,
    defaultPriceFreezeOffer,
    selectedPassengers
  ): {
    flightShopProgress: FlightShopStep;
    priceFreezeOfferCheapestTripTripId: string;
    priceFreezeOfferCheapestTripFareId: string;
    selectedTripId: string | null;
    selectedFareId: string | undefined;
    defaultPriceFreezeOffer: Offer | null;
    selectedPassengers: { [key in string]: number };
  } => {
    return {
      flightShopProgress,
      priceFreezeOfferCheapestTripTripId,
      priceFreezeOfferCheapestTripFareId,
      selectedTripId,
      selectedFareId: selectedFareDetails?.id,
      defaultPriceFreezeOffer,
      selectedPassengers,
    };
  }
);

export const priceFreezeFareQuotePerPassengerTotalPricingSelector =
  createSelector(priceFreezeFareQuoteOutcomeSelector, (outcome) => {
    switch (outcome?.Outcome) {
      case PollFareQuoteOutcomeEnum.Quoted:
        return outcome.tripPricing.pricingByPassenger[0]?.total;
      case PollFareQuoteOutcomeEnum.NoQuote:
      default:
        return undefined;
    }
  });

export const getPriceFreezeEntryPointStepSelector = (state: IStoreState) =>
  state.flightFreeze.priceFreezeEntryPointStep;

export const getFetchApplicableTravelWalletItemsCallState = (
  state: IStoreState
) => state.flightFreeze.fetchApplicableTravelWalletItemsCallState;

export const getCredit = (state: IStoreState) => {
  const credit = state.flightFreeze.credit;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getCreditToApply = (state: IStoreState) => {
  const credit = state.flightFreeze.creditToApply;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getMaxApplicableTravelWalletCreditAmount = createSelector(
  getCredit,
  currentPriceFreezeOfferSelector,
  (credit, pfOffer): number => {
    if (credit && pfOffer) {
      const totalAfterOffer =
        pfOffer.totalAmount.fiat.value + credit?.amount.amount;
      return totalAfterOffer < 0
        ? pfOffer.totalAmount.fiat.value * -1
        : credit.amount.amount;
    }
    return 0;
  }
);

export const getIsTravelWalletCreditPaymentOnly = createSelector(
  currentPriceFreezeOfferSelector,
  getCreditToApply,
  (pfOffer, creditToApply): boolean => {
    if (pfOffer && creditToApply) {
      return Math.abs(creditToApply.amount.amount) >=
        pfOffer?.totalAmount.fiat.value
        ? true
        : false;
    }
    return false;
  }
);

/**
 * This selector will return the index of the `customPriceFreezeOffer` in the `priceFreezeOfferData` array based on the duration of the offer.
 */
export const selectCustomPriceFreezeOfferIndex = createSelector(
  priceFreezeOfferDataSelector,
  customPriceFreezeOfferSelector,
  (priceFreezeOfferData, customPriceFreezeOffer) => {
    if (
      priceFreezeOfferData === null ||
      priceFreezeOfferData.length === 0 ||
      customPriceFreezeOffer === null
    ) {
      return -1;
    }
    return priceFreezeOfferData
      .map((offer) => offer.secondsTtl)
      .indexOf(customPriceFreezeOffer?.timeToLive.inSeconds);
  }
);

/**
 * This selector will return whether the `customPriceFreezeOffer` duration is different from the `selectedAndValidPriceFreezeOffer` duration.
 * It will be useful in determining whether we should fire certain actions.
 */
export const selectHasOfferDurationSelectionChanged = createSelector(
  priceFreezeOfferDataSelector,
  customPriceFreezeOfferSelector,
  selectedAndValidPriceFreezeOfferDataIndexSelector,
  (
    priceFreezeOfferData,
    customPriceFreezeOffer,
    selectedPriceFreezeOfferDataIndex
  ) => {
    if (
      priceFreezeOfferData === null ||
      priceFreezeOfferData.length === 0 ||
      customPriceFreezeOffer === null ||
      selectedPriceFreezeOfferDataIndex === null
    ) {
      return false;
    }

    const customPriceFreezeOfferDuration =
      customPriceFreezeOffer.timeToLive.inSeconds;
    const selectedPriceFreezeOfferDuration =
      priceFreezeOfferData[selectedPriceFreezeOfferDataIndex].secondsTtl;

    return customPriceFreezeOfferDuration !== selectedPriceFreezeOfferDuration;
  }
);

export * from "./priceFreezeDuration";
