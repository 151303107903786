import * as React from "react";
import { Box, Checkbox, Typography } from "@material-ui/core";
import { MobilePopoverCard, ActionLink, CloseButtonIcon } from "halifax";
import clsx from "clsx";

import {
  StopsOptionSelection,
  IStopsOptionSelectionBase,
  IFareclassOptionSelectionBase,
} from "../index";
import * as stopsOptionTextConstants from "../StopsOptionSelection/textConstants";
import * as fareclassOptionTextConstants from "../FareclassOptionSelection/textConstants";
import "./styles.scss";

export interface IMobilePredictionFlightSearchFilterProps
  extends IStopsOptionSelectionBase,
    IFareclassOptionSelectionBase {
  openModal: boolean;
  onClose: () => void;
  bottomButton: JSX.Element;
}

export const MobilePredictionFlightSearchFilter = (
  props: IMobilePredictionFlightSearchFilterProps
) => {
  const {
    openModal,
    onClose,
    stopsOption,
    setStopsOption,
    fareclassOptionFilter,
    setFareclassOptionFilter,
    bottomButton,
  } = props;

  const filterOutBasicFares =
    !fareclassOptionFilter.basic &&
    fareclassOptionFilter.luxury &&
    fareclassOptionFilter.enhanced &&
    fareclassOptionFilter.premium &&
    fareclassOptionFilter.standard;

  return (
    <MobilePopoverCard
      open={openModal}
      onClose={onClose}
      fullScreen={true}
      className={"mobile-flight-search-filter-root"}
      contentClassName={"mobile-flight-search-filter-content-wrapper"}
      bottomButton={bottomButton}
      topRightButton={
        <ActionLink
          className="filter-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={onClose}
        />
      }
    >
      <Box className="mobile-flight-search-filter-content-container">
        <Box className="header-container">
          {fareclassOptionTextConstants.BASIC_FARES}
        </Box>
        <Box className="basic-fares-filter-container">
          <Box className="basic-fares-filter">
            <Typography variant="caption" className="basic-fare-filter">
              {fareclassOptionTextConstants.BASIC_FARE_FILTER_DESCRIPTION}
            </Typography>
          </Box>
          <Box className="basic-fares-filter-checkbox">
            <Checkbox
              className="fareclass-option-checkbox"
              checked={!filterOutBasicFares}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { checked } = event.target;
                setFareclassOptionFilter(() => {
                  if (!checked) {
                    return {
                      luxury: true,
                      enhanced: true,
                      standard: true,
                      premium: true,
                      basic: false,
                    };
                  } else {
                    return {
                      luxury: false,
                      enhanced: false,
                      standard: false,
                      premium: false,
                      basic: false,
                    };
                  }
                });
              }}
            />
          </Box>
        </Box>
        <Box className="header-container">
          {stopsOptionTextConstants.STOPS_HEADER_TEXT}
        </Box>
        <StopsOptionSelection
          className={clsx("mobile-filter-option-selection", "stops")}
          stopsOption={stopsOption}
          setStopsOption={setStopsOption}
          hideTitle={true}
        />
      </Box>
    </MobilePopoverCard>
  );
};
