import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import { Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import "./styles.scss";
import { FlightFreezeConnectorProps } from "./container";
import {
  BookingConfirmationPage,
  FlightFreezeCustomize,
  PriceFreezeOverviewPage,
  PriceFreezePurchase,
} from "./components";
import {
  PATH_FREEZE,
  PATH_FREEZE_CONFIRMATION,
  PATH_PRICE_FREEZE_OVERVIEW,
  PATH_PRICE_FREEZE_CUSTOMIZE,
} from "../../utils/urlPaths";
import { PORTAL_TITLE } from "../../lang/textConstants";

export interface IFlightFreezeProps
  extends RouteComponentProps,
    FlightFreezeConnectorProps {}

export const FlightFreeze = (props: IFlightFreezeProps) => {
  const {
    history,
    setContactInfo,
    fetchRewardsAccounts,
    resetPaymentCardSelectedAccounts,
    generateCustomPriceFreezeOffer,
    initialPriceFreezeOfferDataWithRewards,
    initialSelectedPriceFreezeRadioIndex,
    setSelectedPriceFreezeRadioDataIndex,
    setCreditToApply,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [history.location.pathname]);

  useEffect(() => {
    fetchRewardsAccounts();

    return () => {
      document.title = PORTAL_TITLE;
      // note: reset contact info on destroy
      setContactInfo("", "");
      // note: reset selected rewards account and payment method ids
      resetPaymentCardSelectedAccounts();
      // note: reset custom price freeze offer to the original middle / last offer in durations array
      // and reset selected radio to initial offer index
      if ( initialPriceFreezeOfferDataWithRewards && initialSelectedPriceFreezeRadioIndex ) {
        generateCustomPriceFreezeOffer(initialPriceFreezeOfferDataWithRewards, history, false)
        setSelectedPriceFreezeRadioDataIndex(initialSelectedPriceFreezeRadioIndex)
      }
      setCreditToApply();
    };
  }, []);

  return (
    <Box
      className={clsx("flight-freeze-root", {
        confirm: history.location.pathname === PATH_FREEZE_CONFIRMATION,
        mobile: matchesMobile,
      })}
    >
      <Route path={PATH_FREEZE} exact>
        <PriceFreezePurchase />
      </Route>
      <Route path={PATH_FREEZE_CONFIRMATION}>
        <BookingConfirmationPage />
      </Route>
      <Route path={PATH_PRICE_FREEZE_OVERVIEW}>
        <PriceFreezeOverviewPage />
      </Route>
      <Route path={PATH_PRICE_FREEZE_CUSTOMIZE}>
        <FlightFreezeCustomize />
      </Route>
    </Box>
  );
};
