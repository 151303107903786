import { put, select } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import { priceFreezeFareQuoteCallStateSelector } from "../reducer";
import { actions } from "../actions";

/**
 * Used to conditionally run the `resetPriceFreezePurchase` action creator based on the `priceFreezeFareQuoteCallState`.
 */
export function* cancelPriceFreezeFareQuoteSaga() {
  const state: IStoreState = yield select();

  const priceFreezeFareQuoteCallState =
    priceFreezeFareQuoteCallStateSelector(state);

  if (priceFreezeFareQuoteCallState === 1) {
    yield put(actions.resetFetchPriceFreezeFareQuote());
  }
}
