import * as fareConstants from "../../../../../search/components/FlightSearchFilter/components/FareclassOptionDetails/textConstants";

export const FARE_CLASSES = {
  basic: "Basic",
  standard: "Standard",
  enhanced: "Enhanced",
  premium: "Premium",
  luxury: "Luxury",
};

export const MOBILE_FARE_CLASSES = {
  all: "All",
  ...FARE_CLASSES,
};

export const FARE_CLASS_DETAILS = {
  basic: fareConstants.BASIC_DETAILS_TEXT,
  standard: fareConstants.STANDARD_DETAILS_TEXT,
  enhanced: fareConstants.ENHANCED_DETAILS_TEXT,
  premium: fareConstants.PREMIUM_DETAILS_TEXT,
  luxury: fareConstants.LUXURY_DETAILS_TEXT,
};
