import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  Icon,
  IconName,
  NotificationBanner,
  BannerSeverity,
  getTotalPriceText,
  twoDecimalFormatter,
} from "halifax";
import { CurrentFareVersusCapEnum } from "redmond";
import clsx from "clsx";

import "./styles.scss";
import { FrozenPriceSummaryConnectorProps } from "./container";
import * as constants from "./textConstants";

export interface IFrozenPriceSummaryProps
  extends FrozenPriceSummaryConnectorProps {}

export const FrozenPriceSummary = (props: IFrozenPriceSummaryProps) => {
  const {
    currentPriceFreezeOverview,
    frozenPriceSummaryNotification,
    currentPriceFreeze,
  } = props;

  const { expiresAt, totalPassengers } = currentPriceFreezeOverview;
  const frozenAmount =
    currentPriceFreeze?.frozenFare.paxPricings[0].pricing.originalAmount;

  if (!frozenAmount) {
    return null;
  }

  return (
    <Box className={clsx("frozen-price-summary-root")}>
      <Box className="frozen-price-summary-container">
        <Box className="header-section">
          <Icon className="frozen-icon" name={IconName.FrozenPrice} />
          <Typography className="header-text" variant="h6">
            {constants.FROZEN_PRICE_SUMMARY_TITLE}
          </Typography>
        </Box>
        <Box className="details-section">
          <Box className={clsx("details-row", "expiration")}>
            <Typography variant="body2">
              {constants.EXPIRATION_DATE_TEXT}
            </Typography>
            <Typography variant="body2">
              {constants.getExpireDate(expiresAt)}
            </Typography>
          </Box>
          <Box className={clsx("details-row", "price")}>
            <Typography variant="body2">
              {constants.FROZEN_PRICE_PER_TRAVELER_TEXT}
            </Typography>
            <Typography variant="body2">
              {getTotalPriceText({
                price: frozenAmount.fiat,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>
          <Box className={clsx("details-row", "travelers")}>
            <Typography variant="body2">
              {constants.TRAVELERS_FROZEN_TEXT}
            </Typography>
            <Typography variant="body2">
              {constants.getTravelersText(totalPassengers)}
            </Typography>
          </Box>
        </Box>
        {frozenPriceSummaryNotification && (
          <Box className="notification-section">
            <NotificationBanner
              className="frozen-price-banner"
              html={frozenPriceSummaryNotification.html}
              icon={
                frozenPriceSummaryNotification.type ===
                CurrentFareVersusCapEnum.FareOverCap ? (
                  <Icon className="info-icon" name={IconName.InfoCircleFill} />
                ) : undefined
              }
              severity={
                frozenPriceSummaryNotification.type ===
                CurrentFareVersusCapEnum.FareOverCap
                  ? BannerSeverity.NOTICE
                  : BannerSeverity.IMPROVEMENT
              }
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
