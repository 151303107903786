import {
  TripSummary,
  TripSlice,
  TripDetails,
  AirportMap,
  Prices,
} from "redmond";

import { Prices as AirChfarOfferPrices } from "@b2bportal/air-chfar-api";

import { removeTimezone, formatInterval } from "halifax";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { IReturnSlicesByOutgoingId, ITripSummariesById } from "../index";
import { getReviewCardHeaderWithType, getStopsString } from "./constants";

export const createStopoverString = (trip: TripSlice) => {
  let layoverString = "";
  const stopoverSegments = trip.segmentDetails.filter(
    (s) => s.stopoverDurationMinutes && s.stopoverDurationMinutes > 0
  );
  stopoverSegments.forEach((segment, index) => {
    layoverString = segment.stopoverDurationMinutes
      ? `${layoverString}
       ${formatInterval(segment.stopoverDurationMinutes)}
      ${segment.destinationCode}`
      : "";

    if (index < stopoverSegments.length - 1) {
      layoverString = layoverString + " / ";
    }
  });
  return layoverString;
};

export const getPlusDays = (trip: TripSlice): number => {
  return trip.segmentDetails
    .filter((s) => s.plusDays && s.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);
};

export const formatTripSummaries = (
  flightInfo: TripSummary[]
): {
  tripSummariesById: ITripSummariesById;
  returnFlightsByOutgoingId: IReturnSlicesByOutgoingId;
} => {
  const flights: ITripSummariesById = {};
  const outgoingReturnFlightMap: IReturnSlicesByOutgoingId = {};

  flightInfo.forEach((flight) => {
    const outgoingSliceId = flight.outgoingSlice.id;
    const returnSliceId = flight.returningSlice?.id;

    if (returnSliceId) {
      if (outgoingReturnFlightMap[outgoingSliceId]) {
        // add valid return flight to map
        outgoingReturnFlightMap[outgoingSliceId][returnSliceId] = flight.tripId;
      } else {
        // create map of valid return slices for a specific outgoing slice
        outgoingReturnFlightMap[outgoingSliceId] = {
          [returnSliceId]: flight.tripId,
        };
      }
    }

    // store all trip summaries
    flights[flight.tripId] = flight;
  });

  return {
    tripSummariesById: flights,
    returnFlightsByOutgoingId: outgoingReturnFlightMap,
  };
};

export const getSliceDetails = (
  isOutgoing: boolean,
  tripDetails: TripDetails,
  airports: AirportMap
) => {
  // BF-1552 Support multi-city flights once needed.
  // We need to pass along whether the flight is multi-city, and the slice index instead of isOutgoing.
  const slice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
  const { type, description } = getReviewCardHeaderWithType(
    isOutgoing,
    airports[slice!.destinationCode]
      ? airports[slice!.destinationCode].cityName
      : slice!.destinationName,
    removeTimezone(slice!.departureTime)
  );

  return {
    airlineCode: slice!.segmentDetails[0].airlineCode,
    airline: slice!.segmentDetails[0].airlineName,
    departureDescriptionBold: type,
    departureDescription: description,
    formattedDepartureTime: dayjs(removeTimezone(slice!.departureTime)).format(
      "h:mm A"
    ),
    formattedArrivalTime: dayjs(removeTimezone(slice!.arrivalTime)).format(
      "h:mm A"
    ),
    destinationCode: slice!.destinationCode,
    duration: formatInterval(slice!.totalDurationMinutes || 0),
    stopString: getStopsString(slice!.stops),
    plusDays: getPlusDays(slice!),
  };
};

export const getCustomizeCheckoutBreakdownTotalPrices = ({
  fareTotalPrices,
  accountReferenceId,
  cfarOfferPrices,
  chfarOfferPrices,
  disruptionProtectionOfferPrices,
}: {
  fareTotalPrices: Prices;
  accountReferenceId: string | undefined;
  cfarOfferPrices: Prices | undefined;
  chfarOfferPrices: AirChfarOfferPrices | undefined;
  disruptionProtectionOfferPrices: Prices | undefined;
}) => {
  // note: it will mutate the redux state directly if deep copy isn't used
  const farePrices = cloneDeep(fareTotalPrices);
  const fiat = farePrices.fiat;
  const rewards = farePrices.rewards[accountReferenceId ?? ""] ?? undefined;

  const calculateTotalWithAddOn = (
    addOnPrices: Prices | AirChfarOfferPrices
  ) => {
    if (addOnPrices) {
      fiat.value = fiat.value + addOnPrices.fiat.value;

      const addOnRewards =
        "rewards" in addOnPrices
          ? addOnPrices.rewards[accountReferenceId ?? ""]
          : addOnPrices.accountSpecific[accountReferenceId ?? ""];
      if (rewards && addOnRewards) {
        rewards.value = rewards.value + addOnRewards.value;
      }
    }
  };

  if (cfarOfferPrices) {
    calculateTotalWithAddOn(cfarOfferPrices);
  }

  if (chfarOfferPrices) {
    calculateTotalWithAddOn(chfarOfferPrices);
  }

  if (disruptionProtectionOfferPrices) {
    calculateTotalWithAddOn(disruptionProtectionOfferPrices);
  }

  return {
    fiat,
    rewards,
  };
};
