import { call, put, select } from "redux-saga/effects";
import dayjs from "dayjs";

import {
  FlightShopStep,
  type PriceFreezeOfferDataResponse,
  TripCategory,
} from "redmond";
import { getDepartureDate, getTripCategory } from "../../../../search/reducer";
import { getPriceFreezeOfferData } from "../../../../../api/v0/price-freeze/getPriceFreezeOfferData";
import Logger from "../../../../../helpers/Logger";
import { flightShopProgressSelector } from "../../../../shop/reducer";
import { actions } from "../../../../shop/actions";

/**
 *
 * For the experiment "c1-price-freeze-new-flights-list-entry", we want to append price freeze offer data to the trip details
 */
export function* fetchPriceFreezeOfferDataForFareSaga({
  tripId,
  fareId,
}: actions.IFetchPriceFreezeOfferForFareDetails) {
  try {
    // There are a few business rules we want to follow when showing the price freeze offer CTA
    // - Show CTA on eligible one-way trips
    // - Show the CTA on eligible round trips if the user is selecting the return flight
    const tripCategory: TripCategory = yield select(getTripCategory);
    const flightShopStep: FlightShopStep = yield select(
      flightShopProgressSelector
    );
    const isRoundTrip = tripCategory === TripCategory.ROUND_TRIP;
    const isSelectingReturnFlight =
      flightShopStep === FlightShopStep.ChooseReturn;

    if (isRoundTrip && !isSelectingReturnFlight) {
      return;
    }

    const departureDate: Date | null = yield select(getDepartureDate);

    const priceFreezeOfferDataResponse: PriceFreezeOfferDataResponse =
      yield call(getPriceFreezeOfferData, {
        departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
        tripId,
        fareId,
        entryPoint: { PriceFreezeEntryPoint: 'FlightsListEntry' }
      });

    if (
      !priceFreezeOfferDataResponse ||
      !priceFreezeOfferDataResponse.offerData ||
      priceFreezeOfferDataResponse.offerData.length === 0
    ) {
      return;
    }

    const { offerData: priceFreezeOffers } = priceFreezeOfferDataResponse;
    const middleOffer =
      priceFreezeOfferDataResponse.offerData[
        Math.floor(priceFreezeOfferDataResponse.offerData.length / 2)
      ];

    yield put(
      actions.setPriceFreezeOfferOnFareDetails({
        tripId,
        fareId,
        priceFreezeOffers,
        middleOffer,
      })
    );
  } catch (e) {
    Logger.debug(e);
  }
}
