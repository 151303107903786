import { capitalize, camelCaseToTitleCase } from "halifax";

import { AmenitiesAndUtas, DullesAmenityCanExist, DullesUta } from "redmond";

enum FlightShopSymbol {
  INCLUDED,
  PURCHASABLE,
  UNAVAILABLE,
  GENERIC,
}

interface IRestrictionProps {
  symbol: FlightShopSymbol;
  description: string;
  key: string;
}

export const getFareDetailsRestrictions = ({
  restriction,
}: {
  restriction: AmenitiesAndUtas;
}) => {
  const utas = restriction?.utas?.utas;
  const amenities = restriction?.amenities;
  const restrictions: IRestrictionProps[] = [];

  restrictions.push(
    ...((utas || []).reduce(
      (restrictionsToDisplay: IRestrictionProps[], uta: DullesUta) => {
        const description =
          uta.headline == "Checked or Carry-on Bags"
            ? `${uta.headline} - ${uta.description}`
            : `${uta.description}`;
        const restriction: IRestrictionProps = {
          // TODO: the assessment field should probably be an enum
          symbol:
            uta.assessment === "benefit"
              ? FlightShopSymbol.INCLUDED
              : uta.assessment === "fee"
              ? FlightShopSymbol.PURCHASABLE
              : FlightShopSymbol.UNAVAILABLE,
          // TODO: probably need a different type for category
          description: `${description}`,
          key: uta.category,
        };
        restrictionsToDisplay.push(restriction);

        return restrictionsToDisplay;
      },
      [] as IRestrictionProps[]
    ) ?? [])
  );

  if (amenities) {
    restrictions.push(
      ...Object.keys(amenities).map((key) => {
        const amenitity: DullesAmenityCanExist = amenities![key];
        const symbol = amenitity.exists
          ? FlightShopSymbol.INCLUDED
          : FlightShopSymbol.GENERIC;
        return {
          symbol: symbol,
          description: `${capitalize(camelCaseToTitleCase(key))} - ${
            amenitity.info.displayText
          }`,
          key,
        };
      })
    );
  }
  return restrictions;
};
