import { getTimeWithUtcOffset } from "halifax";
import { FlightItinerarySlice, ITripTerminus } from "redmond";
import { DateToggleFilter } from "../reducer";

export const getDepartureDateFromDateToggleAndOriginalSlice = (
  originalSlice: FlightItinerarySlice,
  dateToggleFilter: DateToggleFilter,
  origin: ITripTerminus | null
): string | null => {
  const originalDepartureSegment = originalSlice?.segments.find(
    (segment) => segment.origin.locationCode === origin?.id.code.code
  );
  const originalDepartureDateTime =
    originalDepartureSegment?.zonedUpdatedDeparture ??
    originalDepartureSegment?.zonedScheduledDeparture ??
    originalDepartureSegment?.updatedDeparture ??
    originalDepartureSegment?.scheduledDeparture;

  switch (dateToggleFilter) {
    case DateToggleFilter.TODAY:
      return originalDepartureDateTime ?? null;
    case DateToggleFilter.TOMORROW:
      return originalDepartureDateTime
        ? getTimeWithUtcOffset(originalDepartureDateTime).add(1, "day").format()
        : null;
    default:
      return null;
  }
};

export * from "./queryStringHelpers";
export * from "./trackEventHelpers";
export * from "./refundableFareHelpers";
export * from "./cfarHelpers";
