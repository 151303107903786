import { Airport, FareDetails, TripDetails, TripSlice } from "redmond";
import { IVirtualInterliningVariant } from "../VirtualIinterliningModal";

export interface ISelfTransferReminderModalProps {
  isOpen: boolean;
  onClose: () => void;
  slices: TripSlice[];
  airports?: { [key: string]: Airport };
  onClickContinue: () => void;
  onClickDifferentFlight: () => void;
  fareDetails: FareDetails[];
  tripDetails: TripDetails;
}

interface IButtons {
  buttonText: string;
  defaultStyle: string;
  buttonWrapperClassName: string;
  onClick: () => void;
}

export interface IDesktop
  extends Omit<
    ISelfTransferReminderModalProps,
    "onClickContinue" | "onClickDifferentFlight" | "tripDetails"
  > {
  setVariant: (variant: IVirtualInterliningVariant) => void;
  buttons: IButtons[];
  selectedTripLeg: ITripLeg;
  setSelectedTripLeg: (value: number) => void;
}

export interface IMobile
  extends Omit<ISelfTransferReminderModalProps, "onClickDifferentFlight"> {
  setVariant: (variant: IVirtualInterliningVariant) => void;
  buttons: IButtons[];
  selectedTripLeg: ITripLeg;
  setSelectedTripLeg: (value: number) => void;
  modalStep: IMobileStep;
  setModalStep: (step: IMobileStep) => void;
}

export type IMobileStep = "reminder" | "fares";

export enum ITripLeg {
  OUTBOUND,
  RETURN,
}

export type IBaggageSection = Pick<
  IDesktop,
  | "selectedTripLeg"
  | "setSelectedTripLeg"
  | "slices"
  | "airports"
  | "fareDetails"
>;

export type IRemindersSection = Pick<
  IDesktop,
  "slices" | "setVariant" | "buttons"
>;
