import dayjs from "dayjs";
import {
  FlightItineraryResponse,
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  getDepartureSlice,
  getReturnSlice,
  HotelItineraryResponse,
  HotelItineraryState,
} from "redmond"; // TODO: Update types import to come from b2binterfaces
import { put, select } from "redux-saga/effects";
import { fetchFlights } from "../../../api/v0/cross-sell/fetchFlights";
import { fetchHotels } from "../../../api/v0/cross-sell/fetchHotels";
import { actions } from "../actions";
import { setMostUpcomingFlight } from "../actions/actions";
import { getAirlinesMap, getAirportMap } from "../reducer/selectors";
import { fetchLocation } from "./fetchCrossSellHotelAvailabilitySaga";

export function* fetchUpcomingFlightCrossSellSaga(
  action: actions.IFetchUpcomingFlightCrossSell
) {
  try {
    const flightItinerariesResponse: FlightItineraryResponse =
      yield fetchFlights(action.request);

    const { future } = flightItinerariesResponse.itineraries;

    const airlines: { [key: string]: Airline | undefined } = yield select(
      getAirlinesMap
    );
    const responseAirlinesMapArr = Object.keys(
      flightItinerariesResponse.airlines
    );
    for (let i = 0; i < responseAirlinesMapArr.length; i++) {
      const currentAirline = responseAirlinesMapArr[i];
      if (!airlines[responseAirlinesMapArr[i]]) {
        airlines[currentAirline] =
          flightItinerariesResponse.airlines[currentAirline];
      }
    }

    const airports: { [key: string]: Airport | undefined } = yield select(
      getAirportMap
    );
    const responseAirportMapArr = Object.keys(
      flightItinerariesResponse.airports
    );
    for (let i = 0; i < responseAirportMapArr.length; i++) {
      if (!airlines[responseAirportMapArr[i]]) {
        const currentAirport = responseAirportMapArr[i];
        airports[currentAirport] =
          flightItinerariesResponse.airports[currentAirport];
      }
    }

    const sortItinerariesByDate = (
      a: BookedFlightItineraryWithDepartureTime,
      b: BookedFlightItineraryWithDepartureTime
    ) => {
      let aDate = getDepartureSlice(a.bookedItinerary).segments[0]
        .scheduledDeparture;
      let bDate = getDepartureSlice(b.bookedItinerary).segments[0]
        .scheduledDeparture;

      const getDepDiff = (dateStringX?: string, dateStringY?: string) => {
        const aDiff = dayjs(dateStringX).diff(dayjs());
        const bDiff = dayjs(dateStringY).diff(dayjs());
        return aDiff - bDiff;
      };

      const depDiff = getDepDiff(aDate, bDate);
      if (depDiff === 0) {
        let aReturn =
          getReturnSlice(a.bookedItinerary)?.segments[0].scheduledArrival || "";
        let bReturn = getReturnSlice(b.bookedItinerary)?.segments[0]
          .scheduledArrival;
        aDate = aReturn || aDate;
        bDate = bReturn || bDate;
        return getDepDiff(aDate, bDate);
      }
      return depDiff;
    };

    const sortedUpcomingFlights = future.sort((a, b) =>
      sortItinerariesByDate(a, b)
    );

    yield put(setMostUpcomingFlight(sortedUpcomingFlights[0]));

    const bookedItinerary = sortedUpcomingFlights[0].bookedItinerary;

    const departureSlice = getDepartureSlice(bookedItinerary as any); // [TODO]: Fix type to used BookedFlightItinerary once it's fixed as a monorepo

    // Gets the last segment to avoid using the layover destinationCode
    const departureLastSegmentIndex = departureSlice
      ? departureSlice.segments.length - 1
      : 0;
    const departureDestinationSegment =
      departureSlice.segments[departureLastSegmentIndex];

    const departureDate = new Date(
      departureDestinationSegment.zonedScheduledArrival ??
        departureDestinationSegment.updatedArrival
    );
    const departureDateWithoutTime = dayjs(departureDate).format("YYYY-MM-DD");
    // Since the checkin date does not have time, it's best to compare dates without time in the .find() below (incase departure time is later than the default 12AM GMT)

    const destinationCode =
      departureDestinationSegment?.destination.locationCode ?? "";
    const destination = airports[destinationCode]?.cityName || destinationCode;

    const returnSlice = getReturnSlice(bookedItinerary as any);

    const returnDate = new Date(
      returnSlice?.segments[0].zonedScheduledArrival ??
        returnSlice?.segments[0].updatedArrival ??
        dayjs(departureDate).add(1, "day").toDate() // if one-way trip, look for hotel for 1 night
    );

    const returnDateWithoutTime = dayjs(returnDate).format("YYYY-MM-DD");
    const adultPaxCount = [
      ...bookedItinerary.passengers.alone,
      ...bookedItinerary.passengers.withLapInfants.map((p) => p.adult),
    ].filter((p) => (p.type as any) === "ADT").length;
    const childPaxArr = bookedItinerary.passengers.alone.filter(
      (p) => (p.type as any) !== "ADT"
    );

    const hotels: HotelItineraryResponse = yield fetchHotels({
      states: [HotelItineraryState.Present, HotelItineraryState.Future], // Using departure date, look for present AND future (checkin date same as departure date or after)
      referenceDateTime: departureDate.toISOString(),
    });

    const { present: presentHotels = [], future: futureHotels = [] } =
      hotels.itineraries;

    const bookedHotel = [...presentHotels, ...futureHotels].find(
      (itinerary) => {
        const checkinDate = new Date(itinerary.reservation.checkInDate); // Defaults to 12AM GMT
        const formattedDepartureDateWithoutTime = new Date( // If we use normal departure date, if flight lands 1AM instead of 12AM, hotel would not be in range.
          departureDateWithoutTime
        );
        const formattedReturnDateWithoutTime = returnSlice
          ? new Date(returnDateWithoutTime)
          : new Date(dayjs(departureDateWithoutTime).add(2, "day").toDate()); // if one-way trip, look for hotel booking made for within 2 days of departure
        return (
          checkinDate.valueOf() >=
            formattedDepartureDateWithoutTime.valueOf() &&
          checkinDate.valueOf() <= formattedReturnDateWithoutTime.valueOf()
        );
      }
    );

    if (!bookedHotel) {
      yield put(actions.setSearchedDates(departureDate, returnDate));

      const { correspondingLocation } = yield fetchLocation(
        destination,
        airports[destinationCode]?.geography.countryCode as string
      );
      yield put(
        actions.setSearchedOccupancyCounts({
          adults: adultPaxCount,
          children: childPaxArr.map(() => 12),
        })
      );
      yield put(actions.setSearchedLocationResult(correspondingLocation));
    }
  } catch (e) {
    yield put(actions.fetchUpcomingFlightCrossSellFailed());
    console.error(e);
  }
}
