import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { DesktopRewardsHeader } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { flightShopProgressSelector } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    step: flightShopProgressSelector(state)
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopRewardsHeaderConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopRewardsHeader = connector(
  withRouter(DesktopRewardsHeader)
);
