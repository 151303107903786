import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { UserFlightPreferencesPayload } from "redmond";
import fetchUserFlightPreferences from "../../../api/v0/user/fetchUserFlightPreferences";

export function* fetchUserFlightPreferencesSaga(
  _: actions.IFetchUserFlightPreferences
) {
  try {
    const flightPreferences: UserFlightPreferencesPayload =
      yield fetchUserFlightPreferences();

    yield put(
      actions.setUserFlightPreferences({
        flightPreferences,
      })
    );
  } catch (e) {
    yield put(actions.setUserFlightPreferencesCallStateFailed());
  }
}
