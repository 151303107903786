import {
  AvailabilityResponse,
  CallState,
  GuestsSelection,
  IResult,
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  PotentialCrossSellOffer,
} from "redmond";
import { actions, actionTypes } from "../actions";

export enum HotelAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch returns empty lodgings
  Complete = "Complete",
  Failed = "Failed",
}

export interface IFlightToHotelCrossSellState {
  availabilityResponse?: AvailabilityResponse;
  hotelAvailabilityCallState: HotelAvailabilityCallState;
  searchFromDate: Date | null;
  searchUntilDate: Date | null;
  searchLocationResult: IResult | null;
  searchAdultsCount: number;
  searchChildrenCount: number[];
  selectedLodgingIndex: number | null;
  fetchFlightsCallState: { [key: string]: CallState };
  airlines: { [key: string]: Airline | undefined };
  airports: { [key: string]: Airport | undefined };
  mostUpcomingFlight?: BookedFlightItineraryWithDepartureTime;
  latency: number | null;
  potentialXSellOffers: PotentialCrossSellOffer[];
}

export const initialState: IFlightToHotelCrossSellState = {
  hotelAvailabilityCallState: HotelAvailabilityCallState.NotCalled,
  searchFromDate: null,
  searchUntilDate: null,
  searchLocationResult: null,
  searchAdultsCount: 2,
  searchChildrenCount: [],
  selectedLodgingIndex: null,
  fetchFlightsCallState: {
    canceled: CallState.NotCalled,
    past: CallState.NotCalled,
    present: CallState.NotCalled,
    future: CallState.NotCalled,
  },
  airlines: {},
  airports: {},
  mostUpcomingFlight: undefined,
  latency: null,
  potentialXSellOffers: [],
};
export function reducer(
  state: IFlightToHotelCrossSellState = initialState,
  action: actions.FlightToHotelCrossSellActions
): IFlightToHotelCrossSellState {
  switch (action.type) {
    case actionTypes.FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY:
      return {
        ...state,
        hotelAvailabilityCallState:
          HotelAvailabilityCallState.InitialSearchCallInProcess,
      };
    case actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS:
      return {
        ...state,
        latency: action.latency,
        availabilityResponse: {
          ...action.payload,
          lodgings: [
            ...(state.availabilityResponse?.lodgings || []),
            ...action.payload.lodgings,
          ],
          bestOverallOffer:
            action.payload.bestOverallOffer ??
            state.availabilityResponse?.bestOverallOffer,
        },
        hotelAvailabilityCallState: HotelAvailabilityCallState.Complete,
      };

    case actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_CALL_STATE_FAILED:
      return {
        ...state,
        hotelAvailabilityCallState: HotelAvailabilityCallState.Failed,
      };
    case actionTypes.SET_SEARCHED_DATES:
      return {
        ...state,
        searchFromDate: action.searchedFromDate,
        searchUntilDate: action.searchedUntilDate,
      };
    case actionTypes.SET_SEARCHED_LOCATION_RESULT:
      return {
        ...state,
        searchLocationResult: action.searchedLocationResult,
      };
    case actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS:
      const { adults, children } = action.counts as GuestsSelection;
      return {
        ...state,
        searchAdultsCount: adults,
        searchChildrenCount: children,
      };
    case actionTypes.SET_SELECTED_LODGING_INDEX:
      return {
        ...state,
        selectedLodgingIndex: action.index,
      };
    case actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL:
      return {
        ...state,
        fetchFlightsCallState: {
          ...state.fetchFlightsCallState,
          future: CallState.InProcess,
        },
      };

    case actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED:
      return {
        ...state,
        fetchFlightsCallState: {
          ...state.fetchFlightsCallState,
          future: CallState.Failed,
        },
      };

    case actionTypes.SET_UPCOMING_FLIGHTS:
      return {
        ...state,
        fetchFlightsCallState: {
          ...state.fetchFlightsCallState,
          future: CallState.Success,
        },
        airports: action.airports,
        airlines: action.airlines,
      };

    case actionTypes.SET_MOST_UPCOMING_FLIGHT:
      return {
        ...state,
        mostUpcomingFlight: action.flight,
      };
    case actionTypes.SET_POTENTIAL_CROSS_SELL_OFFERS:
      return {
        ...state,
        potentialXSellOffers: action.offers,
      };

    default:
      return state;
  }
}
