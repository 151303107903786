import { takeLatest, takeEvery } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchCrossSellHotelAvailabilitySaga } from "./fetchCrossSellHotelAvailabilitySaga";
import { fetchUpcomingFlightCrossSellSaga } from "./fetchUpcomingFlightCrossSellSaga";
import { fetchPotentialCrossSellOffersSaga } from "./fetchPotentialCrossSellOffersSaga";

export function* watchFetchCrossSellHotelAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY,
    fetchCrossSellHotelAvailabilitySaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_CROSS_SELL_HOTEL_AVAILABILITY,
    fetchCrossSellHotelAvailabilitySaga
  );
}

export function* watchfetchUpcomingFlightCrossSellSaga() {
  yield takeEvery(
    actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL,
    fetchUpcomingFlightCrossSellSaga
  );
}

export function* watchFetchPotentialCrossSellOffersSaga() {
  yield takeLatest(
    actionTypes.FETCH_POTENTIAL_CROSS_SELL_OFFERS,
    fetchPotentialCrossSellOffersSaga
  );
}
