import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PassportModal } from "./component";
import {
  getIsMissingPassport,
  getUserPassengers,
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
} from "../../reducer";
import {
  scheduleQuote,
  updateUserPassenger,
  validatePassengers,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  const selectedTravelerIds = getUserSelectedPassengerIds(state);
  const userPassengers = getUserPassengers(state).filter((pass) =>
    selectedTravelerIds.includes(pass.id)
  );
  const selectedInfantIds = getUserSelectedLapInfantIds(state);
  const infantPassengers = getUserPassengers(state).filter((pass) =>
    selectedInfantIds.includes(pass.id)
  );
  return {
    userPassengers,
    infantPassengers,
    selectedTravelerIds,
    selectedInfantIds,
    isMissingPassport: getIsMissingPassport(state),
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  validatePassengers,
  scheduleQuote,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PassportModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPassportModal = withRouter(connector(PassportModal));
