import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import { Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import {
  FlightBookType,
  REVIEW_FLIGHT_DETAILS,
  REVIEW_MULTICITY_FLIGHT_DETAILS,
  TripCategory,
  VIEW_ITINERARY_RESTRICTIONS,
} from "redmond";

import "./styles.scss";
import { FlightBookConnectorProps } from "./container";
import {
  BookingErrorModal,
  BookingInProgressModal,
  BookingSuccessModal,
  PassportModal,
  AgentBookingFeeModal,
  BookingSubmitApprovalErrorModal,
  BookingSubmitApprovalModal,
} from "./components";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_BOOK_WITH_PF_CONFIRMATION,
  PATH_BOOK_WITH_VI_CONFIRMATION,
} from "../../utils/urlPaths";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import { PriceFreezeRefundModal } from "./components/PriceFreezeRefundModal";
import {
  AVAILABLE,
  getExperimentVariant,
  SIMILAR_FLIGHTS,
  useExperiments,
  VOID_WINDOW_EXPERIMENT,
} from "../../context/experiments";
import { FLIGHT_CHECKOUT_TITLE, PORTAL_TITLE } from "../../lang/textConstants";
import { config } from "../../api/config";
import { isCorpTenant } from "@capone/common";

const { DesktopFlightBookWorkflow } = isCorpTenant(config.TENANT)
  ? require("./components/capone-corporate/DesktopFlightBookWorkflow")
  : require("./components/capone/DesktopFlightBookWorkflow");
const { MobileFlightBookWorkflow } = isCorpTenant(config.TENANT)
  ? require("./components/capone-corporate/MobileFlightBookWorkflow")
  : require("./components/capone/MobileFlightBookWorkflow");

export enum MobileFlightBookWorkflowStep {
  TravelerInformation,
  PricingAndPassengerValidation,
  ContactInformation,
  SeatSelection,
  TravelOfferSelection,
  RewardsAndPayment,
  ApprovalReason,
  Review,
}
export interface IFlightBookProps
  extends RouteComponentProps,
    FlightBookConnectorProps {}

export const FlightBook = (props: IFlightBookProps) => {
  const {
    tripDetails,
    location,
    setUpFlightBookParams,
    history,
    finalizedItinerary,
    reviewFlightDetailsProperties,
    viewItineraryRestrictionsProperties,
    selectedAccount,
    displayPriceFreezeRefundModal,
    isFlightBookWithAncillariesActive,
    priceQuote,
    resetFinalized,
    setQuote,
    setIsSimilarFlightsEnabled,
    closeSession,
    fetchTripSummariesV3,
    fetchMulticitySummaries,
    tripCategory,
    airportMap,
    shopSummarySearchString,
    flightBookType,
  } = props;
  const expState = useExperiments();
  const similarFlightsGroup = getExperimentVariant(
    expState.experiments,
    SIMILAR_FLIGHTS
  );
  const isVoidWindowExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, VOID_WINDOW_EXPERIMENT) ===
      AVAILABLE,
    [expState]
  );
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [eventSent, setEventSent] = useState(false);
  const [checkoutStep, setCheckoutStep] =
    useState<MobileFlightBookWorkflowStep>(0);
  const [localTravelerIds, setLocalTravelerIds] = useState<string[]>([]);
  const [localLapInfantIds, setLocalLapInfantIds] = useState<string[]>([]);

  const isMulticity = useMemo(() => {
    return tripCategory === TripCategory.MULTI_CITY;
  }, [tripCategory]);

  useEffect(() => {
    document.title = FLIGHT_CHECKOUT_TITLE;
    return () => {
      // note: reset price quote states on destroy
      setQuote(null);
      resetFinalized();
      closeSession({});
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
    if (!finalizedItinerary) {
      setUpFlightBookParams({ history });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (tripDetails && selectedAccount && !eventSent) {
      trackEvent({
        eventName: isMulticity
          ? REVIEW_MULTICITY_FLIGHT_DETAILS
          : REVIEW_FLIGHT_DETAILS,
        properties: { ...reviewFlightDetailsProperties },
      });
      trackEvent({
        eventName: VIEW_ITINERARY_RESTRICTIONS,
        properties: { ...viewItineraryRestrictionsProperties },
      });
      setEventSent(true);
    }
  }, [tripDetails, selectedAccount]);

  // note: isSimilarFlightsEnabled needs to be consumed by a selector (errorTitles), which is why it's added to redux
  useEffect(() => {
    setIsSimilarFlightsEnabled(similarFlightsGroup === AVAILABLE);
  }, [similarFlightsGroup]);

  useEffect(() => {
    if (
      isVoidWindowExperiment &&
      flightBookType === FlightBookType.DEFAULT &&
      !airportMap &&
      !!shopSummarySearchString
    ) {
      // imitate shop search params so the saga has access to the right values
      if (isMulticity) {
        fetchMulticitySummaries({
          ...history,
          location: {
            ...history.location,
            search: `?${shopSummarySearchString}`,
          },
        });
      } else {
        fetchTripSummariesV3({
          ...history,
          location: {
            ...history.location,
            search: `?${shopSummarySearchString}`,
          },
        });
      }
    }
  }, [
    isVoidWindowExperiment,
    flightBookType,
    history,
    shopSummarySearchString,
  ]);

  return (
    <Box
      className={clsx(
        "flight-book-root",
        {
          confirm: [
            PATH_BOOK_CONFIRMATION,
            PATH_BOOK_WITH_PF_CONFIRMATION, 
            PATH_BOOK_WITH_VI_CONFIRMATION
          ].includes(location.pathname),
        },
        { mobile: matchesMobile }
      )}
    >
      <Route path={PATH_BOOK} exact>
        {tripDetails && (
          <>
            <Box className="flight-book-container">
              {matchesDesktop && <DesktopFlightBookWorkflow />}
              {matchesMobile && (
                <MobileFlightBookWorkflow
                  checkoutStep={checkoutStep}
                  setCheckoutStep={setCheckoutStep}
                  localLapInfantIds={localLapInfantIds}
                  setLocalLapInfantIds={setLocalLapInfantIds}
                  localTravelerIds={localTravelerIds}
                  setLocalTravelerIds={setLocalTravelerIds}
                />
              )}
            </Box>
            <PassportModal
              onCloseMobileModal={() =>
                setCheckoutStep(
                  MobileFlightBookWorkflowStep.TravelerInformation
                )
              }
            />
          </>
        )}
        <BookingErrorModal
          checkoutStep={checkoutStep}
          setCheckoutStep={setCheckoutStep}
          setLocalLapInfantIds={setLocalLapInfantIds}
          setLocalTravelerIds={setLocalTravelerIds}
        />
        <BookingInProgressModal
          // note: hide price-quote-in-progress modal in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting
          hidePriceQuoteInProgress={
            isFlightBookWithAncillariesActive && matchesDesktop && !!priceQuote
          }
        />
        <BookingSubmitApprovalModal />
        <BookingSubmitApprovalErrorModal />
        {displayPriceFreezeRefundModal && <PriceFreezeRefundModal />}
      </Route>
      <Route path={[PATH_BOOK_CONFIRMATION, PATH_BOOK_WITH_PF_CONFIRMATION, PATH_BOOK_WITH_VI_CONFIRMATION]}>
        <BookingSuccessModal isMultiCity={isMulticity} />
        <AgentBookingFeeModal />
      </Route>
    </Box>
  );
};
