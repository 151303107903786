import { Box, Typography } from "@material-ui/core";
import { B2BButton, Icon, IconName } from "halifax";
import React from "react";
import { CalendarPickerButton } from "../FlightSearchControlV2/components";
import clsx from "clsx";
import { ITripTerminus, TripCategory } from "redmond";
import { getCityNameAndAirportCodeFromTerminus } from "../../../../utils/terminus";
import { OriginDestinationSearch } from "../FlightSearchControlV2/components/OriginDestinationSearch";
import { MulticityFlightSearchRouteListConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { MulticityRoute } from "../../reducer";
import "./styles.scss";
import {
  ADD_FLIGHT_BUTTON_TEXT,
  MAX_NUM_MULTICITY_ROUTES,
  MULTICITY_CALENDAR_HEADER_TITLE,
  INCOMPLETE_SEARCH_VALUES_WARNING,
  MAX_ROUTES_WARNING,
} from "./constants";
import {
  getMinAllowedDate,
  getMaxAllowedDate,
} from "../../../shop/utils/getUpdatedMultiCityRoutes";

export interface IMulticityFlightSearchRouteListProps
  extends RouteComponentProps,
    MulticityFlightSearchRouteListConnectorProps {}

export const MulticityFlightSearchRouteList = ({
  multicityRoutes,
  setMulticityOrigin,
  setMulticityDestination,
  setMulticityDepartureDate,
  addMulticityRoute,
  removeMulticityRoute,
  fetchDepartureCalendar,
  hasMissingMulticitySearchInfoError,
  renderErrorBanner,
}: IMulticityFlightSearchRouteListProps) => {
  const getCalendarHeader = (
    origin: ITripTerminus | null,
    destination: ITripTerminus | null
  ) => {
    if (!origin || !destination) return;

    const [originCityName, originAirportCode] =
      getCityNameAndAirportCodeFromTerminus(origin as ITripTerminus);
    const [destinationCityName, destinationAirportCode] =
      getCityNameAndAirportCodeFromTerminus(destination as ITripTerminus);

    return (
      <Typography>
        {MULTICITY_CALENDAR_HEADER_TITLE} <strong>{originCityName}</strong>{" "}
        {originAirportCode} to <strong>{destinationCityName}</strong>{" "}
        {destinationAirportCode}
      </Typography>
    );
  };

  const renderMulticityFlightSearchRow = (
    route: MulticityRoute,
    index: number
  ) => {
    const {
      origin: routeOrigin,
      destination: routeDestination,
      departureDate: routeDepartureDate,
    } = route;

    const minAllowedDate = getMinAllowedDate(multicityRoutes, index);
    const maxAllowedDate = getMaxAllowedDate(multicityRoutes, index);

    return (
      <Box key={index} className={clsx("flight-search-row", "multicity")}>
        <OriginDestinationSearch
          origin={routeOrigin}
          setOrigin={(origin) => setMulticityOrigin(origin, index)}
          destination={routeDestination}
          setDestination={(destination) =>
            setMulticityDestination(destination, index)
          }
          hasMissingSearchInfoError={
            hasMissingMulticitySearchInfoError &&
            (!routeOrigin || !routeDestination)
          }
        />
        <CalendarPickerButton
          onClick={() =>
            fetchDepartureCalendar(
              routeOrigin as ITripTerminus,
              routeDestination as ITripTerminus
            )
          }
          classes={["date-pickers"]}
          saveDatesOnClose
          departureDate={routeDepartureDate}
          returnDate={null}
          setDepartureDate={(departureDate: Date | null) =>
            setMulticityDepartureDate(departureDate, index)
          }
          setReturnDate={() => {}}
          tripCategory={TripCategory.ONE_WAY}
          minAllowedDate={minAllowedDate}
          maxAllowedDate={maxAllowedDate}
          headerTitle={getCalendarHeader(routeOrigin, routeDestination)}
          hasMissingSearchInfoError={
            hasMissingMulticitySearchInfoError && !routeDepartureDate
          }
        />
        {index > 1 ? (
          <B2BButton
            variant="traveler-selector"
            className="remove-route-button"
            onClick={() => removeMulticityRoute(index)}
          >
            <Icon aria-hidden={true} name={IconName.BlueX} ariaLabel="" />
          </B2BButton>
        ) : null}
      </Box>
    );
  };

  const isMaxNumberOfRows = multicityRoutes.length === MAX_NUM_MULTICITY_ROUTES;

  return (
    <Box className={clsx("flight-search", "multicity")}>
      {multicityRoutes.map(renderMulticityFlightSearchRow)}
      {hasMissingMulticitySearchInfoError &&
        renderErrorBanner &&
        renderErrorBanner()}
      {hasMissingMulticitySearchInfoError && !renderErrorBanner && (
        <Typography className="incomplete-search-values-warning">
          {INCOMPLETE_SEARCH_VALUES_WARNING}
        </Typography>
      )}
      <B2BButton
        variant="b2b"
        className={clsx("add-flight-button", { disabled: isMaxNumberOfRows })}
        disabled={isMaxNumberOfRows}
        onClick={addMulticityRoute}
      >
        {ADD_FLIGHT_BUTTON_TEXT}
      </B2BButton>
      {isMaxNumberOfRows ? (
        <Typography className="max-routes-warning">
          {MAX_ROUTES_WARNING}
        </Typography>
      ) : null}
    </Box>
  );
};
