import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../../../reducers/types";
import { allAirlinesSelector } from "../../../../../../../shop/reducer/selectors";
import { setAirlineFilter } from "../../../../../../actions/actions";
import { getAirlineFilter } from "../../../../../../reducer/index";
import { AirlineFilterSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const airlineFilter = getAirlineFilter(state);
  const allAirlines = allAirlinesSelector(state);
  return {
    airlineFilter,
    allAirlines,
  };
};

const mapDispatchToProps = {
  setAirlineFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AirlineFilterProps = ConnectedProps<typeof connector>;

export const ConnectedAirlineFilterSelection = connector(
  AirlineFilterSelection
);
