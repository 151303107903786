import React from "react";
import { Box } from "@material-ui/core";
import { ActionLink, ActionButton, useDeviceTypes } from "halifax";
import { SliceStopCountFilter, TripCategory } from "redmond";
import { isEqual } from "lodash-es";
import clsx from "clsx";

import { MobilePredictionFlightSearchFilter } from "./components";
import * as textConstants from "./textConstants";
import { FlightSearchFilterConnectorProps } from "./container";
import {
  FareclassOptionFilter,
  initialFilterOptions,
} from "../../reducer/index";
import "./styles.scss";

export interface IFlightSearchFilterProps
  extends FlightSearchFilterConnectorProps {
  openModal: boolean;
  onClose: () => void;
  onFiltersApplied: () => void;
  tripCategory: TripCategory;
}

export const FlightSearchFilter = (props: IFlightSearchFilterProps) => {
  const {
    openModal,
    onClose,
    currentStopsOption,
    currentFareclassOptionFilter,
    readyToReset,
    setCurrentStopsOption,
    setCurrentFareclassOptionFilter,
    onFiltersApplied,
    setRerunPrediction,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const [stopsOption, setStopsOption] = React.useState<SliceStopCountFilter>(
    currentStopsOption
  );
  const [
    fareclassOptionFilter,
    setFareclassOptionFilter,
  ] = React.useState<FareclassOptionFilter>(currentFareclassOptionFilter);
  const optionsChanged = React.useMemo(() => {
    return (
      stopsOption !== currentStopsOption ||
      !isEqual(fareclassOptionFilter, currentFareclassOptionFilter)
    );
  }, [
    stopsOption,
    currentStopsOption,
    fareclassOptionFilter,
    currentFareclassOptionFilter,
  ]);

  const handleApply = React.useCallback(() => {
    setCurrentStopsOption(stopsOption);
    setCurrentFareclassOptionFilter(fareclassOptionFilter);
    onFiltersApplied();
  }, [stopsOption, fareclassOptionFilter]);

  const handleReset = React.useCallback(() => {
    setStopsOption(initialFilterOptions.stopsOption);
    setFareclassOptionFilter(initialFilterOptions.fareclassOptionFilter);
    setRerunPrediction();
  }, []);

  const renderFilterButtons = React.useCallback(() => {
    return (
      <Box
        className={clsx("flight-search-filter-bottom-buttons-container", {
          mobile: matchesMobile,
        })}
      >
        <ActionLink
          className={clsx("reset-filters-link", "filter-button", {
            inactive: !readyToReset && !optionsChanged,
          })}
          onClick={handleReset}
          content={textConstants.RESET_FILTERS_TEXT}
        />
        <ActionButton
          className={clsx("apply-filters-button", "filter-button")}
          onClick={() => {
            handleApply();
            onClose();
          }}
          disabled={!optionsChanged}
          message={textConstants.APPLY_FILTERS_TEXT}
        />
      </Box>
    );
  }, [optionsChanged, readyToReset, handleReset, handleApply, onClose]);

  return (
    <MobilePredictionFlightSearchFilter
      openModal={openModal}
      onClose={onClose}
      stopsOption={stopsOption}
      setStopsOption={setStopsOption}
      fareclassOptionFilter={fareclassOptionFilter}
      setFareclassOptionFilter={setFareclassOptionFilter}
      bottomButton={renderFilterButtons()}
    />
  );
};
