import React from "react";
import { Icon, IconName, ButtonWrap } from "halifax";

export const CollapsedContent = ({
  attached,
  attachedContent,
  declinedContent,
  displayEdit,
  onEdit,
}: {
  attached: boolean;
  attachedContent: JSX.Element;
  declinedContent: JSX.Element;
  displayEdit?: boolean;
  onEdit?: () => void;
}) => {
  const EDIT = "Edit";

  const EditButton = () => (
    <ButtonWrap className="edit-button" onClick={onEdit}>
      {EDIT}
    </ButtonWrap>
  );
  return (
    <div className="collapsed-content">
      {attached ? (
        <>
          <div className="icon-content-group">
            <Icon name={IconName.CheckMarkGreen} className="icon-container" />
            {attachedContent}
          </div>
          <div>{displayEdit ? <EditButton /> : null}</div>
        </>
      ) : (
        <>
          <div className="icon-content-group">
            <Icon name={IconName.XMarkRed} className="icon-container" />
            {declinedContent}
          </div>
          <div>{displayEdit ? <EditButton /> : null}</div>
        </>
      )}
    </div>
  );
};
