import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceFreezeSelectedDurationModal } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import {
  priceFreezeMaxOfferSelector,
  priceFreezeMinOfferSelector,
  priceFreezeUserSelectedDurationSelectionMethodSelector,
  selectedAndValidPriceFreezeOfferDataSelector,
  selectedPriceFreezeOfferCapSelector,
} from "../../../../freeze/reducer";
import { generateCustomPriceFreezeOffer } from "../../../../freeze/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedPriceFreezeOfferCap: selectedPriceFreezeOfferCapSelector(state),
    selectedPriceFreezeOfferData:
      selectedAndValidPriceFreezeOfferDataSelector(state),
    priceFreezeUserSelectedDurationSelectionMethod:
      priceFreezeUserSelectedDurationSelectionMethodSelector(state),
    priceFreezeMaxOffer: priceFreezeMaxOfferSelector(state),
    priceFreezeMinOffer: priceFreezeMinOfferSelector(state),
  };
};

const mapDispatchToProps = {
  generateCustomPriceFreezeOffer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeSelectedDurationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeSelectedDurationModal = withRouter(
  connector(PriceFreezeSelectedDurationModal)
);
