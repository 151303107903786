import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import submitForApproval from "../../../../api/v0/book/book-flow/submitForApproval";
import {
  ConnectionResultEnum,
  FlightBookType,
  InvalidEnum,
  TRIP_REQUESTED_AIR,
} from "redmond";
import {
  getCompleteBuyAirPriceFreezeProperties,
  getCompleteBuyAirProperties,
  getCompleteBuyTravelOfferProperties,
  getFlightBookType,
  getSession,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export function* submitForApprovalSaga({
  payload,
}: actions.ISetSubmitForApproval) {
  console.log("submit saga");
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (!session) {
      throw Error("Session is undefined");
    }

    const completeBuyAirProperties = getCompleteBuyAirProperties(state);
    const completeBuyAirPriceFreezeProperties =
      getFlightBookType(state) === FlightBookType.PRICE_FREEZE_EXERCISE
        ? getCompleteBuyAirPriceFreezeProperties(state)
        : undefined;
    const completeBuyTravelOfferProperties =
      getCompleteBuyTravelOfferProperties(state);

    yield submitForApproval(payload);

    trackEvent({
      eventName: TRIP_REQUESTED_AIR,
      properties: {
        ...completeBuyAirProperties,
        ...completeBuyAirPriceFreezeProperties,
        ...completeBuyTravelOfferProperties.properties,
        success: true,
      },
      encryptedProperties: [
        ...completeBuyTravelOfferProperties.encryptedProperties,
      ],
    });

    yield put(actions.setSubmitForApprovalSuccess());
  } catch (e) {
    yield put(
      actions.setSubmitForApprovalFailure({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );

    const state: IStoreState = yield select();
    const completeBuyAirProperties = getCompleteBuyAirProperties(state);
    const completeBuyAirPriceFreezeProperties =
      getCompleteBuyAirPriceFreezeProperties(state);
    const completeBuyTravelOfferProperties =
      getCompleteBuyTravelOfferProperties(state);

    trackEvent({
      eventName: TRIP_REQUESTED_AIR,
      properties: {
        ...completeBuyAirProperties,
        ...completeBuyAirPriceFreezeProperties,
        ...completeBuyTravelOfferProperties.properties,
        failure_reason: (e as Error).message,
      },
      encryptedProperties: [
        ...completeBuyTravelOfferProperties.encryptedProperties,
      ],
    });
  }
}
