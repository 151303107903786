import {
  AncillaryFetchOfferSuccess,
  AncillaryFetchOfferResponseEnum,
  FetchCfarOfferSuccessV2,
  FetchCfarOfferResponseV2Enum,
  UtasPolicy,
  DullesUtas,
} from "redmond";

const getChangePolicyFromDullesUtas = (utas: DullesUtas | undefined) =>
  utas?.changePolicy?.beforeDeparture ?? utas?.changePolicy?.anytime;

export const getCfarChangePolicyFromAncillaryOfferResponse = (
  ancillaryOfferResponse: AncillaryFetchOfferSuccess | null
): UtasPolicy[] => {
  const utasPolicies: (UtasPolicy | undefined)[] = [];
  switch (ancillaryOfferResponse?.FetchOfferResponseV2) {
    case AncillaryFetchOfferResponseEnum.SingleTicket:
      utasPolicies.push(
        getChangePolicyFromDullesUtas(ancillaryOfferResponse?.utas)
      );
      break;
    case AncillaryFetchOfferResponseEnum.MultiTicketV2:
      utasPolicies.push(
        getChangePolicyFromDullesUtas(ancillaryOfferResponse?.outboundUtas)
      );
      utasPolicies.push(
        getChangePolicyFromDullesUtas(ancillaryOfferResponse?.returnUtas)
      );
      break;
    case AncillaryFetchOfferResponseEnum.MultiCity:
      ancillaryOfferResponse?.utas?.forEach((uta) =>
        utasPolicies.push(getChangePolicyFromDullesUtas(uta))
      );
      break;
    default:
      break;
  }
  return utasPolicies.filter((p) => !!p) as UtasPolicy[];
};

export const getCfarChangePolicyFromCfarOfferResponse = (
  cfarOfferResponse: FetchCfarOfferSuccessV2 | null
): UtasPolicy[] => {
  const utasPolicies: (UtasPolicy | undefined)[] = [];
  switch (cfarOfferResponse?.FetchCfarOfferResponseV2) {
    case FetchCfarOfferResponseV2Enum.SingleTicket:
      utasPolicies.push(getChangePolicyFromDullesUtas(cfarOfferResponse?.utas));
      break;
    case FetchCfarOfferResponseV2Enum.MultiTicketV2:
      utasPolicies.push(
        getChangePolicyFromDullesUtas(cfarOfferResponse?.outboundUtas)
      );
      utasPolicies.push(
        getChangePolicyFromDullesUtas(cfarOfferResponse?.returnUtas)
      );
      break;
    default:
      break;
  }
  return utasPolicies.filter((p) => !!p) as UtasPolicy[];
};
