import { isCorpTenant, PATH_CORP_FAQ } from "@capone/common";
import { config } from "../../../../api/config";

export const CAP_ONE_LOGO_ALT = "Capital One Travel";
export const FLIGHT_DISRUPTION_ASSISTANCE = "Flight disruption assistance";
export const GET_HELP_WITH_YOUR_TRIP = "Get help with your trip";
export const QUESTIONS_ABOUT_YOUR_TRIP = "Questions about your trip?";
export const VIEW_FAQS = "View FAQs";
export const CONTACT_SUPPORT = "Contact Support";

export const CONTACT_SUPPORT_URL = isCorpTenant(config.TENANT)
  ? PATH_CORP_FAQ
  : "https://www.capitalone.com/help-center/capital-one-travel/";

export const SUPPORT_LINK = "Support";

export const SUPPORT_LINK_URL = CONTACT_SUPPORT_URL;

export const CONTACT_SUPPORT_HEADER = "Get Help With Capital One Travel";
export const CONTACT_SUPPORT_TEXT_1 =
  "For information regarding Seats, Baggage, Changes, Cancellations and Refunds, please visit";
export const FREQUENTLY_ASKED_QUESTIONS = "Frequently Asked Questions";
export const CONTACT_SUPPORT_TEXT_2 =
  "For other questions regarding your trip, please contact our support team and we’d be happy to help.";
export const CONTACT_SUPPORT_NUMBER = "844-422-6922";

export const QUESTIONS_ABOUT_CAP1_TRAVEL =
  "Questions about Capital One Travel?";

export const GET_HELP = "Get Help";
export const SIGN_IN = "Sign in";
