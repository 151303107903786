import { Box, Typography } from "@material-ui/core";
import { ButtonWrap, Icon, IconName } from "halifax";
import clsx from 'clsx';
import React, { FC } from "react";
import {
  CONTENT
} from "./textConstants";
import "./styles.scss";

interface FlightVICombinationBannerProps {
  isMobile?: boolean
  onClick?: () => void;
  ctaText?: string
}

export const FlightVICombinationBanner: FC<FlightVICombinationBannerProps> = ({
  isMobile = false,
  onClick,
  ctaText,
}) => (
  <Box className={clsx("flight-vi-combination-banner", { mobile: isMobile })}>
    <Icon name={IconName.DiagonalBlueAirplane} />
    <Box className="title-container">
      <Typography className="flight-vi-combination-banner-title">
        {CONTENT}
      </Typography>
      {onClick && ctaText && (
        <ButtonWrap onClick={onClick}>
          <Typography className="airlines-restrictions" variant="subtitle2">
            {ctaText}
          </Typography>
        </ButtonWrap>
      )}
    </Box>
  </Box>
);
