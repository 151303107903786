export const SUCCESS = "Success!";
export const ITINERARY_UPDATED =
  "Your itinerary has been updated and you can continue booking your flight.";
export const CONTINUE = "Continue";

export const FAIL = "Something went wrong.";
export const UNABLE_TO_PROCESS =
  "We were unable to process your selection, please contact support for further assistance or try again.";
export const CONTACT_SUPPORT = "Contact Support";
export const TRY_AGAIN = "Try Again";
export const LOADING_TEXT = "Updating your itinerary...";
