import * as React from "react";
import { debounce, isEqual } from "lodash-es";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  GenericSlider,
  GenericDropdown,
  Icon,
  IconName,
  ButtonWrap,
} from "halifax";
import { Airport, ITimeRange, ITripTerminus, TripCategory } from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  initialFilterOptions,
  TIME_RANGE_MAX,
} from "../../../../../../reducer";

interface IDepartureArrivalSelectionProps {
  tripCategory: TripCategory;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  hasSetTimeRange?: boolean;
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ) => void;
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) => void;
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) => void;
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) => void;
  showDropdownContentOnly?: boolean;
  showDepartureSelectionOnly?: boolean;
  showArrivalSelectionOnly?: boolean;
  appliedLabel?: string;
  isFlightListOptimizationExperiment?: boolean;
  icon?: IconName;
  outboundTitle?: string;
  returnTitle?: string;
  origin?: ITripTerminus | null;
  destination?: ITripTerminus | null;
  originAirport?: Airport;
  destinationAirport?: Airport;
  isAirCXV3Experiment?: boolean;
}

export const DepartureArrivalSelectionDropdown = (
  props: IDepartureArrivalSelectionProps
) => {
  const {
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    showDropdownContentOnly,
    showDepartureSelectionOnly,
    showArrivalSelectionOnly,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    appliedLabel,
    hasSetTimeRange,
    isFlightListOptimizationExperiment,
    icon,
    outboundTitle,
    returnTitle,
    origin,
    destination,
    originAirport,
    destinationAirport,
    isAirCXV3Experiment,
  } = props;

  const outboundSelection = {
    departureTimeRange: outboundDepartureTimeRange,
    onChangeDepartureTimeRange: (min: number, max: number) =>
      setOutboundDepartureTimeRange({ min, max }),
    arrivalTimeRange: outboundArrivalTimeRange,
    onChangeArrivalTimeRange: (min: number, max: number) =>
      setOutboundArrivalTimeRange({ min, max }),
  };

  const returnSelection =
    tripCategory === TripCategory.ROUND_TRIP
      ? {
          departureTimeRange: returnDepartureTimeRange,
          onChangeDepartureTimeRange: (min: number, max: number) =>
            setReturnDepartureTimeRange({ min, max }),
          arrivalTimeRange: returnArrivalTimeRange,
          onChangeArrivalTimeRange: (min: number, max: number) =>
            setReturnArrivalTimeRange({ min, max }),
        }
      : undefined;
  const renderDropdownContent = () => {
    return (
      <>
        {!showArrivalSelectionOnly && (
          <Box
            className={clsx("departure-arrival-selection-root", "outbound", {
              "filter-experiment": isFlightListOptimizationExperiment,
            })}
          >
            <Box className="departure-arrival-selection-container">
              <Box className="header-container">
                {icon && outboundTitle ? (
                  <>
                    <Icon name={icon} /> {outboundTitle}
                  </>
                ) : !isFlightListOptimizationExperiment ? (
                  textConstants.TIMES_OUTBOUND_HEADER_TEXT
                ) : undefined}
              </Box>
              <Box className="selection-container">
                <Box
                  className={clsx("time-window-slider-container", "departure")}
                >
                  <Box className="label-container">
                    {isFlightListOptimizationExperiment &&
                    origin &&
                    destination ? (
                      <>
                        <Icon name={IconName.OutboundPlaneIcon} />
                        <Typography
                          className="label-text"
                          dangerouslySetInnerHTML={{
                            __html:
                              textConstants.getTimeRangeSubtitle(
                                origin.label,
                                destination.label,
                                originAirport,
                                destinationAirport,
                                true,
                                false
                              ) ?? "",
                          }}
                        />
                      </>
                    ) : (
                      textConstants.DEPARTURE_TEXT
                    )}
                  </Box>
                  <TimeWindowSlider
                    timeRange={outboundSelection.departureTimeRange}
                    onChange={outboundSelection.onChangeDepartureTimeRange}
                    isFlightListOptimizationExperiment={
                      isFlightListOptimizationExperiment
                    }
                  />
                </Box>
                <Box
                  className={clsx("time-window-slider-container", "arrival")}
                >
                  <Box className="label-container">
                    {isFlightListOptimizationExperiment &&
                    origin &&
                    destination ? (
                      <>
                        <Icon name={IconName.ReturnPlaneIcon} />
                        <Typography
                          className="label-text"
                          dangerouslySetInnerHTML={{
                            __html:
                              textConstants.getTimeRangeSubtitle(
                                origin.label,
                                destination.label,
                                originAirport,
                                destinationAirport,
                                false,
                                false
                              ) ?? "",
                          }}
                        />
                      </>
                    ) : (
                      textConstants.ARRIVAL_TEXT
                    )}
                  </Box>
                  <TimeWindowSlider
                    timeRange={outboundSelection.arrivalTimeRange}
                    onChange={outboundSelection.onChangeArrivalTimeRange}
                    isFlightListOptimizationExperiment={
                      isFlightListOptimizationExperiment
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {isFlightListOptimizationExperiment && !isAirCXV3Experiment && (
          <Divider className="filter-divider" />
        )}
        {returnSelection && !showDepartureSelectionOnly && (
          <Box
            className={clsx("departure-arrival-selection-root", "arrival", {
              "filter-experiment": isFlightListOptimizationExperiment,
            })}
          >
            <Box className="departure-arrival-selection-container">
              <Box className="header-container">
                {icon && outboundTitle ? (
                  <>
                    <Icon name={icon} /> {returnTitle}
                  </>
                ) : !isFlightListOptimizationExperiment ? (
                  textConstants.TIMES_RETURN_HEADER_TEXT
                ) : undefined}
              </Box>
              <Box className="selection-container">
                <Box
                  className={clsx("time-window-slider-container", "departure")}
                >
                  <Box className="label-container">
                    {isFlightListOptimizationExperiment &&
                    origin &&
                    destination ? (
                      <>
                        <Icon name={IconName.OutboundPlaneIcon} />
                        <Typography
                          className="label-text"
                          dangerouslySetInnerHTML={{
                            __html:
                              textConstants.getTimeRangeSubtitle(
                                origin.label,
                                destination.label,
                                originAirport,
                                destinationAirport,
                                true,
                                true
                              ) ?? "",
                          }}
                        />
                      </>
                    ) : (
                      textConstants.DEPARTURE_TEXT
                    )}
                  </Box>
                  <TimeWindowSlider
                    timeRange={returnSelection.departureTimeRange}
                    onChange={returnSelection.onChangeDepartureTimeRange}
                    isFlightListOptimizationExperiment={
                      isFlightListOptimizationExperiment
                    }
                  />
                </Box>
                <Box
                  className={clsx("time-window-slider-container", "arrival")}
                >
                  <Box className="label-container">
                    {isFlightListOptimizationExperiment &&
                    origin &&
                    destination ? (
                      <>
                        <Icon name={IconName.ReturnPlaneIcon} />
                        <Typography
                          className="label-text"
                          dangerouslySetInnerHTML={{
                            __html:
                              textConstants.getTimeRangeSubtitle(
                                origin.label,
                                destination.label,
                                originAirport,
                                destinationAirport,
                                false,
                                true
                              ) ?? "",
                          }}
                        />
                      </>
                    ) : (
                      textConstants.ARRIVAL_TEXT
                    )}
                  </Box>
                  <TimeWindowSlider
                    timeRange={returnSelection.arrivalTimeRange}
                    onChange={returnSelection.onChangeArrivalTimeRange}
                    isFlightListOptimizationExperiment={
                      isFlightListOptimizationExperiment
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box className={"departure-arrival-dropdown"}>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={clsx(
            "departure-arrival-dropdown",
            "b2b-shop-filter",
            {
              "has-value": hasSetTimeRange,
            }
          )}
          popoverClassName={clsx("departure-arrival-popover", "b2b", {
            "filter-experiment": isFlightListOptimizationExperiment,
          })}
          ariaLabel={`Time Filter`}
          dropdownLabel={textConstants.TIME(
            hasSetTimeRange ? appliedLabel : undefined
          )}
          dropdownContent={renderDropdownContent()}
          dropdownIcon={
            hasSetTimeRange && appliedLabel ? (
              <ButtonWrap
                onClick={(e) => {
                  e.stopPropagation();
                  setOutboundArrivalTimeRange(
                    initialFilterOptions.outboundArrivalTimeRange
                  );
                  setOutboundDepartureTimeRange(
                    initialFilterOptions.outboundDepartureTimeRange
                  );
                  setReturnDepartureTimeRange(
                    initialFilterOptions.returnDepartureTimeRange
                  );
                  setReturnArrivalTimeRange(
                    initialFilterOptions.returnArrivalTimeRange
                  );
                }}
              >
                <Icon name={IconName.XCircle} />
              </ButtonWrap>
            ) : undefined
          }
          anchorOrigin={(() => {
            switch (true) {
              case isAirCXV3Experiment:
                return {
                  vertical: "bottom",
                  horizontal: "left",
                };
              case isFlightListOptimizationExperiment:
                return {
                  vertical: "bottom",
                  horizontal: "right",
                };
              default:
                return undefined;
            }
          })()}
          transformOrigin={(() => {
            switch (true) {
              case isAirCXV3Experiment:
                return {
                  vertical: "top",
                  horizontal: "left",
                };
              case isFlightListOptimizationExperiment:
                return {
                  vertical: "top",
                  horizontal: "right",
                };
              default:
                return undefined;
            }
          })()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </Box>
  );
};

interface ITimeWindowSliderProps {
  className?: string;
  onChange: (min: number, max: number) => void;
  timeRange: ITimeRange;
  isFlightListOptimizationExperiment?: boolean;
}

const TimeWindowSlider = (props: ITimeWindowSliderProps) => {
  const { className, onChange, timeRange, isFlightListOptimizationExperiment } =
    props;

  const [value, setValue] = React.useState(timeRange);

  const debouncedAction = debounce(onChange, 300);
  const [stateDebounceDispatchAction] = React.useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (min: number, max: number) => {
    setValue({ min, max });
    stateDebounceDispatchAction(min, max);
  };

  React.useEffect(() => {
    !isEqual(value, timeRange) && handleChange(timeRange.min, timeRange.max);
  }, [timeRange]);

  const hasSliderChange = React.useMemo(() => {
    return value.min !== 0 || value.max !== TIME_RANGE_MAX;
  }, [value]);

  return (
    <GenericSlider
      className={clsx("time-window-slider-root", className)}
      onChange={handleChange}
      sliderType={"doubleThumb"}
      step={isFlightListOptimizationExperiment ? 360 : 1}
      chosenMin={value.min}
      chosenMax={value.max}
      sliderMin={0}
      sliderMax={TIME_RANGE_MAX}
      getLabel={getTimeLabel}
      alwaysShowTooltip={
        isFlightListOptimizationExperiment ? hasSliderChange : false
      }
      marksToDisplay={
        isFlightListOptimizationExperiment
          ? [
              { value: 0 },
              { value: 360 },
              { value: 720 },
              { value: 1080 },
              { value: TIME_RANGE_MAX },
            ]
          : undefined
      }
      sliderLabel={
        isFlightListOptimizationExperiment
          ? getSliderLabel(value.min, value.max, hasSliderChange)
          : undefined
      }
      showResetButton={isFlightListOptimizationExperiment && hasSliderChange}
      reset={() => {
        handleChange(0, TIME_RANGE_MAX);
      }}
    />
  );
};

const getTimeLabel = (value: number) => {
  const totalTime = dayjs().hour(0).minute(value);
  return totalTime.format("h:mm A");
};

const getSliderLabel = (min: number, max: number, hasSliderChange: boolean) => {
  const departureTime = dayjs().hour(0).minute(min);
  const arrivalTime = dayjs().hour(0).minute(max);
  if (hasSliderChange) {
    return `${departureTime.format("h:mm A")} - ${arrivalTime.format(
      "h:mm A"
    )}`;
  } else {
    return "Anytime";
  }
};
