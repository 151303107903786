import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  B2BButton,
  Icon,
  IconName,
  DesktopPopupModal,
  PassengerCountPicker,
  ActionLink,
  getTotalPassenger,
} from "halifax";
import {
  IPassengerCounts,
  EDIT_TRAVELERS,
  EditTravelersTrackingProps,
  Passengers,
} from "redmond";
import { FlightFreezeTravelersConnectorProps } from "./container";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IFlightFreezeTravelersProps
  extends RouteComponentProps,
    FlightFreezeTravelersConnectorProps {
  titleStep?: number;
  isMobile?: boolean;
  disabled?: boolean;
  useLockPriceLanguage?: boolean;
}

export const FlightFreezeTravelers = ({
  counts,
  setPassengerCounts,
  resetPaymentCardSelectedAccounts,
  isMobile,
  disabled,
  // note: this prop is added so that the consumer can decide what numerical value it should have in the title; it was previously hard coded as '3'
  titleStep = 3,
  useLockPriceLanguage,
}: IFlightFreezeTravelersProps) => {
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState<boolean>(false);
  return (
    <Box className={clsx("flight-freeze-travelers-root", { disabled })}>
      <Box className="traveler-section-container">
        {isMobile ? (
          <Typography variant="caption" className="title">
            {textConstants.FREEZE_FOR(useLockPriceLanguage)}
          </Typography>
        ) : (
          <Box className="traveler-info-description">
            <Typography className="title" variant="h2">
              {textConstants.TRAVELERS_STEP(titleStep)}
            </Typography>
            <Typography className="subtitle" variant="body2">
              {textConstants.TRAVELERS_AMOUNT(useLockPriceLanguage)}
            </Typography>
          </Box>
        )}
        {isMobile ? (
          <Box className="mobile-button-container">
            <Typography variant="h6" className="travelers">
              {textConstants.INPUT_LABEL(counts)}
            </Typography>
            <ActionLink
              className="cta-button"
              onClick={() => setOpenPassengerCountPicker(true)}
              content={
                <Typography className="cta-text" variant="h6">
                  <span>{textConstants.CHANGE_TRAVELERS}</span>
                </Typography>
              }
            />
          </Box>
        ) : (
          <Box className="button-container">
            <B2BButton
              aria-label={`travelers ${textConstants.INPUT_LABEL(counts)}`}
              className={clsx("num-travelers-input", "b2b")}
              variant="traveler-selector"
              onClick={() => setOpenPassengerCountPicker(true)}
              disabled={disabled}
            >
              <Box className="num-traveler-content">
                <Icon
                  aria-hidden={true}
                  className="icon-start"
                  name={IconName.B2BUser}
                  ariaLabel=""
                />
                <Box className="text">
                  <Typography variant="body1" className="travelers-text">
                    {textConstants.INPUT_LABEL(counts)}
                  </Typography>
                </Box>
                <Typography className="change-text" variant="h6">
                  {textConstants.CHANGE_TRAVELERS}
                </Typography>
              </Box>
            </B2BButton>
          </Box>
        )}
      </Box>
      <DesktopPopupModal
        open={openPassengerCountPicker}
        className={clsx({
          "flight-freeze-desktop-passenger-count-picker-popup": !isMobile,
          "flight-freeze-mobile-passenger-count-picker-popup": isMobile,
        })}
        contentClassName="desktop-passenger-count-picker-popup-container"
        onClose={() => setOpenPassengerCountPicker(false)}
        invisibleBackdrop={false}
      >
        <PassengerCountPicker
          titles={textConstants.MODAL_TITLES}
          setPassengerCounts={(counts) => {
            const passengers = counts as IPassengerCounts;
            setPassengerCounts(passengers);
            resetPaymentCardSelectedAccounts();
            trackEvent({
              eventName: EDIT_TRAVELERS,
              properties: {
                success: true,
                price_freeze_pax_total: getTotalPassenger(counts as Passengers),
                price_freeze_pax_adults: passengers.adultsCount,
                price_freeze_pax_children: passengers.childrenCount,
                price_freeze_pax_lap_infants: passengers.infantsOnLapCount,
                price_freeze_pax_seat_infants: passengers.infantsInSeatCount,
              } as EditTravelersTrackingProps,
            });
          }}
          onClickApply={() => setOpenPassengerCountPicker(false)}
          counts={counts}
          className="b2b"
        />
      </DesktopPopupModal>
    </Box>
  );
};
