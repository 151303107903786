import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { fetchUserInfoPath } from "../paths";
import { SessionInfo } from "redmond";

export const fetchUserInfo = (): Promise<SessionInfo> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<SessionInfo> = await axios.get(fetchUserInfoPath, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchUserInfo;
