import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  getCurrentPriceFreezeDataForOverviewPage,
  currentPriceFreezeSelector,
} from "../../../freeze/reducer";
import { frozenPriceSummaryNotificationSelector } from "../../reducer";
import { FrozenPriceSummary } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    currentPriceFreezeOverview: getCurrentPriceFreezeDataForOverviewPage(state),
    frozenPriceSummaryNotification:
      frozenPriceSummaryNotificationSelector(state),
    currentPriceFreeze: currentPriceFreezeSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FrozenPriceSummaryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFrozenPriceSummary = connector(FrozenPriceSummary);
