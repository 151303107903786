import React from "react";
import {
  Box,
  Radio,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import "./styles.scss";
import clsx from "clsx";
import {
  useDeviceTypes,
} from "halifax";
import { getDurationTextFromSeconds } from "../../../constants";
import { PriceFreezeDurationRadioGroupConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { getPriceFreezeRewardsString } from "../../../utils/helpers";

export interface IPriceFreezeDurationRadioGroupProps
  extends PriceFreezeDurationRadioGroupConnectorProps,
  IPriceFreezeDurationRadioGroupOwnProps,
  RouteComponentProps { }

export interface IPriceFreezeDurationRadioGroupOwnProps {
  isFromFlightsReviewItineraryScreen: boolean
}
interface IPriceFreezeDurationRadioProps {
  index: number,
  copy: string,
  selectedIndex: number,
}

export const PriceFreezeDurationRadioGroup = (
  props: IPriceFreezeDurationRadioGroupProps
): React.ReactElement | null => {

  const {
    isFromFlightsReviewItineraryScreen,
    history,
    generateCustomPriceFreezeOffer,
    priceFreezeOfferDurations,
    setSelectedPriceFreezeRadioDataIndex,
    selectedPriceFreezeRadioDataIndex,
    accountReferenceId,
    isPriceFreezeShowDurationsVariant1Active,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  if (
    !priceFreezeOfferDurations ||
    !isFromFlightsReviewItineraryScreen ||
    selectedPriceFreezeRadioDataIndex === null ||
    !isPriceFreezeShowDurationsVariant1Active
  ) {
    return null;
  }

  const displayedPriceFreezeOfferDataList = priceFreezeOfferDurations.map((offerData, originalListIndex) => {
    return { offerData, originalListIndex };
  });

  const getDisplayedListIndex = (originalListIndex: number): number => {
    return displayedPriceFreezeOfferDataList.findIndex(
      ({ originalListIndex: index }) => index === originalListIndex
    );
  };

  const selectedDisplayedListIndex = getDisplayedListIndex(
    selectedPriceFreezeRadioDataIndex
  );

  const durationsAndRewards = priceFreezeOfferDurations.map((ele) => ({ ttl: ele.secondsTtl, price: ele.price.fiat.value, rewards: ele.price.rewards }));

  /* 
    This function calls the get custom offer endpoint 
    and then sets it into state as "customPriceFreezeOffer".
    When the CTA is clicked, it will bring you to the checkout screen with the selected offer in state
  */
  const handleGeneratePriceFreezeOffer = (index: number) => {
    const selectedOfferData = priceFreezeOfferDurations[index];
    if (selectedOfferData) {
      generateCustomPriceFreezeOffer(selectedOfferData, history, false);
    }
  };

  const PriceFreezeDurationRadio = (props: IPriceFreezeDurationRadioProps) => {
    const {
      index,
      copy,
      selectedIndex,
    } = props;

    const isSelected = index === selectedIndex
    const isSingleDuration = priceFreezeOfferDurations.length <= 1
    const mobileClass = matchesMobile ? "mobile" : ""

    const radioComp = !isSingleDuration ?
      <Radio
        className="price-freeze-duration-radio-button"
        size="small"
        value={index}
        checked={isSelected}
        onChange={(e) => {
          setSelectedPriceFreezeRadioDataIndex(parseInt(e.target.value) || 0)
          handleGeneratePriceFreezeOffer(parseInt(e.target.value))
        }}
      />
      : <></>

    let singleClass = isSingleDuration ? "single" : ""

    return (
      <FormControlLabel
        className={clsx("price-freeze-duration-radio-form")}
        value={index}
        control={radioComp}
        label={
          <Box className={clsx("price-freeze-duration-radio-content", singleClass, mobileClass)}>
            <Typography
              className="generic-details-radio-copy"
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
          </Box>
        }
      />
    )
  };

  return (
    <Box
      className={clsx(
        "price-freeze-duration-boxed-radio-group-root",
        {
          mobile: matchesMobile,
        }
      )}
    >
      <Box className="price-freeze-duration-boxed-radio-group-container">
        <FormControl>
          <RadioGroup>
            {
              durationsAndRewards.map((item, index) => {

                const text = `<strong>${getDurationTextFromSeconds(item.ttl)}</strong> for $${item.price} / ${getPriceFreezeRewardsString(item.rewards, accountReferenceId)} per traveler`

                return (
                  <PriceFreezeDurationRadio
                    index={index}
                    copy={text}
                    key={item.ttl}
                    selectedIndex={selectedDisplayedListIndex}
                  />
                );
              })
            }
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
