import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  fetchTransferToSimilarFlightsCallStateSelector,
  transferToSimilarFlightsResponseSelector,
} from "../../reducer/selectors";
import { SimilarFlightsModal } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  fetchTransferToSimilarFlightsCallState:
    fetchTransferToSimilarFlightsCallStateSelector(state),
  transferToSimilarFlightResponse:
    transferToSimilarFlightsResponseSelector(state),
});

const mapDispatchToProps = {
  fetchTransferToSimilarFlights: actions.fetchTransferToSimilarFlights,
  setFetchTransferToSimilarFlightsCallStateNotCalled:
    actions.setFetchTransferToSimilarFlightsCallStateNotCalled,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SimilarFlightsModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedSimilarFlightsModal = withRouter(
  connector(SimilarFlightsModal)
);
