import {
  AncillaryPostBookingFetchOfferResponse,
  AncillaryPostBookingFetchOfferSuccess,
} from "redmond";
import { put } from "redux-saga/effects";
import { actions } from "../../actions";
import Logger from "../../../../helpers/Logger";
import getPostBookingOffer from "../../../../api/v2/ancillary/getPostBookingOffer";
import dayjs from "dayjs";

export function* fetchAncillaryPostBookingOfferSaga(
  props: actions.IFetchAncillaryPostBookingOffer
) {
  try {
    const { request } = props;
    const fetchOfferRequest = request ?? {
      referenceDateTime: dayjs().format(),
    };

    const response: AncillaryPostBookingFetchOfferResponse =
      yield getPostBookingOffer(fetchOfferRequest);

    if (response.PostBookingOfferResponseV2 == "PostBookingOfferFound") {
      yield put(
        actions.setFetchAncillaryPostBookingOfferResponse(
          response as AncillaryPostBookingFetchOfferSuccess
        )
      );
    } else {
      yield put(actions.resetFetchAncillaryPostBookingOfferState());
      return;
    }
  } catch (e) {
    yield put(actions.resetFetchAncillaryPostBookingOfferState());
    Logger.debug(e);
  }
}
