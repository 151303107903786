import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getIsBookingValid,
  getConfirmationContactInfo,
  isTravelerStepCompleteSelector,
  getFinalizedPriceFreeze,
  getRewardsPaymentAccountReferenceId,
  getIsPriceFreezeExerciseEnabled,
} from "../../../book/reducer/selectors";
import { schedulePayment } from "../../../book/actions/actions";
import { MobileFlightFreezeWorkflow } from "./component";
import { fetchApplicableTravelWalletItems } from "../../actions/actions";
import { getIsTravelWalletCreditPaymentOnly } from "../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  return {
    isBookingValid: getIsBookingValid(state),
    contactInfo: getConfirmationContactInfo(state),
    isConfirmationEmailValid: isTravelerStepCompleteSelector(state),
    finalizedPriceFreeze: getFinalizedPriceFreeze(state),
    rewardsPaymentAccountReferenceId:
      getRewardsPaymentAccountReferenceId(state),
    disableChangeFlight: getIsPriceFreezeExerciseEnabled(state),
    isPriceFreezeTravelCreditPaymentOnly: getIsTravelWalletCreditPaymentOnly(state),
  };
};

const mapDispatchToProps = {
  schedulePayment,
  fetchApplicableTravelWalletItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightFreezeWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFlightFreezeWorkflow = withRouter(
  connector(MobileFlightFreezeWorkflow)
);
