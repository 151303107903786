import React from "react";

export const FARECLASS_DROPDOWN_LABEL = (appliedLabel?: string | number) => {
  if (!!appliedLabel) {
    if (typeof appliedLabel === "number") {
      return <span>Fare class ({appliedLabel})</span>;
    } else {
      return (
        <>
          <span className="filter-name">Fare class</span> {appliedLabel}
        </>
      );
    }
  } else {
    return <span className="filter-name">Fare class</span>;
  }
};

export const LEARN_ABOUT_FARE_CLASSES = "Learn about our fare classes";
