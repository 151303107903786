import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  getDisplayPfRefundModalSelector,
  currentPriceFreezeSelector,
  getRefundCallStateSelector,
} from "../../../freeze/reducer";
import { priceFreezeTotalPassengerSelector } from "../../reducer/selectors";
import { PriceFreezeRefundModal } from "./component";
import { actions } from "./../../../freeze/actions";

const mapStateToProps = (state: IStoreState) => ({
  displayPriceFreezeRefundModal: getDisplayPfRefundModalSelector(state),
  priceFreeze: currentPriceFreezeSelector(state)?.priceFreeze,
  refundPriceFreezeCallState: getRefundCallStateSelector(state),
  passengers: priceFreezeTotalPassengerSelector(state),
});

const mapDispatchToProps = {
  refundPriceFreeze: actions.refundPriceFreeze,
  setDisplayPriceFreezeRefundModal: actions.setDisplayPriceFreezeRefundModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeRefundModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeRefundModal = withRouter(
  connector(PriceFreezeRefundModal)
);
