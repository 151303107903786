import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  toggleAppNotifOptIn,
  toggleSMSOptIn,
} from "../../../book/actions/actions";
import { getUserTcpaConsentPhoneNumber } from "../../reducer";
import { OptIn } from "./component";
const mapStateToProps = (state: IStoreState) => ({
  tcpaConsentPhoneNumber: getUserTcpaConsentPhoneNumber(state),
});

const mapDispatchToProps = {
  toggleAppNotifOptIn,
  toggleSMSOptIn,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export type OptInConnectorProps = ConnectedProps<typeof connector>;
export const ConnectedOptIn = withRouter(connector(OptIn));
