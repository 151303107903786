import { put } from "redux-saga/effects";
import { PriceFreezeEntryPointEnum, PriceFreezeOfferDataResponse, PriceFreezeOfferDataWithRewards } from "redmond";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { getPriceFreezeOfferData } from "../../../api/v0/price-freeze/getPriceFreezeOfferData";
import { PriceFreezeEntryPoint } from "redmond";

export function* getPriceFreezeOfferDataSaga(
  action: actions.IGetPriceFreezeOfferData
) {
  try {
    let entryPoint: PriceFreezeEntryPoint | undefined;
    switch(action.entryPoint) {
      case PriceFreezeEntryPointEnum.FlightsListEntry:
        entryPoint = { PriceFreezeEntryPoint: 'FlightsListEntry' }
        break;
      case PriceFreezeEntryPointEnum.PredictionPriceFreezeEntry:
        entryPoint = { PriceFreezeEntryPoint: 'PredictionPriceFreezeEntry' }
        break;
      case PriceFreezeEntryPointEnum.PriceFreezeBannerEntry:
        entryPoint = { PriceFreezeEntryPoint: 'PriceFreezeBannerEntry' }
        break;
      default:
        entryPoint = undefined
    }

    const response: PriceFreezeOfferDataResponse =
      yield getPriceFreezeOfferData({
        departureDate: action.departureDate,
        tripId: action.tripId,
        fareId: action.fareId,
        entryPoint: entryPoint,
      });
    const initialOfferData = response.offerData;
    let length = initialOfferData.length;

    let offerData;
    if (action.knockOutDurations) {
      if (length <= 2) {
        offerData = initialOfferData;
      } else {
        offerData = initialOfferData.slice(2);
      }
    } else {
      offerData = initialOfferData;
    }

    yield put(actions.setPriceFreezeOfferData(offerData));

    if (action.showPriceFreezeEntryPointDurations) {
      length = offerData.length;
      let offerRadioDurations;
      if (length <= 3) {
        offerRadioDurations = offerData;
      } else {
        let mid: number;
        if (length % 2 === 0) {
          mid = length / 2;
        } else {
          mid = Math.floor(length / 2);
        }

        offerRadioDurations = [
          offerData[0],
          offerData[mid],
          offerData[length - 1],
        ];
      }

      const radioDurationsLength = offerRadioDurations.length;
      let initialSelectedOffer: PriceFreezeOfferDataWithRewards;

      if (radioDurationsLength <= 2) {
        initialSelectedOffer = offerRadioDurations[radioDurationsLength - 1];
      } else {
        initialSelectedOffer = offerRadioDurations[Math.floor(radioDurationsLength / 2)];
      }
      const initialOfferIndex = offerRadioDurations.indexOf(initialSelectedOffer)

      // set radio offers into state
      yield put(actions.setPriceFreezeOfferRadioDurations(offerRadioDurations));
      // set the index of the custom offer into state
      yield put(actions.setSelectedPriceFreezeRadioDataIndex(initialOfferIndex))
      // set initial offer with data into state
      yield put(actions.setInitialPriceFreezeOfferDataWithRewards(initialSelectedOffer))
      // set the index of the initialOfferData into state
      yield put(actions.setInitialSelectedPriceFreezeRadioDataIndex(initialOfferIndex))
    }
  } catch (e) {
    yield put(actions.setGetPriceFreezeOfferDataCallStateFailed());
    Logger.debug(e);
  }
}
