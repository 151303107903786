import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import {
  GenericDetailsCard,
  GenericDetailsCardComponent,
  GenericDetailsCardComponentEnum,
  GenericDetailsCardTopSectionComponent,
  GenericDetailsCardTopSectionComponentEnum,
  ErrorOutsideComponentType,
  DisruptionProtectionImageMobile,
  IconName,
  useDeviceTypes,
  getTotalPriceText,
  B2BSpinner,
  LoadingPopup,
  InfoBox,
  twoDecimalFormatter,
  renderFDAInfoBoxOptionOne,
  renderFDAInfoBoxOptionTwo,
  renderFDABulletList,
  Icon,
} from "halifax";
import { CheckCircleOutlined } from "@material-ui/icons";
import clsx from "clsx";
import {
  CallState,
  DISRUPTION_PROTECTION_BODY,
  DISRUPTION_PROTECTION_HEADER,
} from "redmond";
import { useExperimentsById } from "@capone/experiments";

import { ClientContext } from "../../../../../../App";
import {
  CORP_FINTECH_SUBSCRIPTION_KEY,
  DO_NOT_APPLY_OPTION_KEY,
} from "../../../../reducer";
import { DisruptionProtectionDetailsConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { getCoMerchPriceWithRewardsUILabel } from "../../common";
import { PATH_DISRUPTION_PROTECTION } from "../../../../../../utils/urlPaths";
import { Alert, AlertTitle } from "@material-ui/lab";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import { corpFintechSmoketestMap } from "../../CorpFintechSmoketest";

enum DisruptionProtectionOption {
  No,
  Yes,
  Subscription,
}

export enum ErrorFromInsideComponentType {
  NoOptionSelected,
}

export interface IDisruptionProtectionDetailsProps
  extends DisruptionProtectionDetailsConnectorProps,
    RouteComponentProps {
  openDisruptionProtectionDetails?: boolean;
  onClose?: () => void;
  // Note: Action to call when the call to fetch disruption protection offer is finished and there is no disruption protection offer returned.
  // This will overwrite the default action taken.
  onNoDisruptionProtectionOffer?: () => void;
  // Note: Overwrite the default continue action
  onContinue?: () => void;
  contentOnly?: boolean;
  cardContentProps?: {
    hideTopContent?: boolean;
    hideSubtitle?: boolean;
    hideContinueCta?: boolean;
    disablePartialScroll?: boolean;
    readTermsAndConditionsLinkPosition?: "left" | "center" | "right";
    onSelectValueCallback?: () => void;
    onConfirmSelectedValueCallback?: () => void;
    error?: ErrorOutsideComponentType;
  };
  disabled?: boolean;
  isWrapperUIAvailable: boolean;
  hasSelectedCorpFintech?: boolean;
}

export const DisruptionProtectionDetails = (
  props: IDisruptionProtectionDetailsProps
) => {
  const {
    openDisruptionProtectionDetails,
    onClose,
    onContinue = () => {
      populateFlightBookQueryParams({ history });
    },
    onNoDisruptionProtectionOffer = () => {
      populateFlightBookQueryParams({ history });
    },
    disruptionProtectionOffer,
    fetchAncillaryOfferCallState,
    selectedDisruptionProtectionId,
    setSelectedDisruptionProtectionId,
    selectedAccountReferenceId,
    populateFlightBookQueryParams,
    history,
    contentOnly,
    cardContentProps,
    hasDisruptionProtectionOffer,
    disabled,
    isAirOfferRedesignEnabled,
    isCfarCoMerchEnabled,
    isWrapperUIAvailable,
    isCorpFintechEligible,
    hasSelectedCorpFintech = false,
  } = props;
  const {
    hideTopContent,
    hideContinueCta,
    disablePartialScroll,
    readTermsAndConditionsLinkPosition,
    onSelectValueCallback,
    onConfirmSelectedValueCallback,
    error,
  } = cardContentProps ?? {};
  const { isAgentPortal } = useContext(ClientContext);
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const usePartialScroll =
    !disablePartialScroll &&
    useMediaQuery(constants.PARTIAL_SCROLL_THRESHOLD, {
      noSsr: true,
    });

  const corpFintechVariant =
    useExperimentsById("corp-fintech-subscription-smoketest")?.variant ||
    "control";

  const corpSmoketestVariant = corpFintechSmoketestMap[corpFintechVariant];

  const showCorpFintechSubscription =
    isCorpFintechEligible && !hasSelectedCorpFintech;

  const [errorFromInsideComponent, setErrorFromInsideComponent] =
    useState<ErrorFromInsideComponentType | null>(null);

  let selectedValue: DisruptionProtectionOption | undefined;

  if (isAgentPortal) {
    selectedValue = DisruptionProtectionOption.No;
  } else if (selectedDisruptionProtectionId !== null) {
    if (
      selectedDisruptionProtectionId.policyId === CORP_FINTECH_SUBSCRIPTION_KEY
    ) {
      selectedValue = DisruptionProtectionOption.Subscription;
    } else if (
      selectedDisruptionProtectionId.policyId !== DO_NOT_APPLY_OPTION_KEY
    ) {
      selectedValue = DisruptionProtectionOption.Yes;
    } else {
      selectedValue = DisruptionProtectionOption.No;
    }
  }

  const isUnselected = selectedValue === undefined;
  /*
    note: although multiple disruption protection offers can be returned to the FE, we would simply consume
    the first one given the design that we have currently; it's said that only one of missed-connection or delay offer
    can be returned from the BE (they never appear simultaneously), see https://hopchat.slack.com/archives/C03AZ58P0CV/p1651509571284409
  */
  const currentOffer =
    disruptionProtectionOffer && disruptionProtectionOffer[0];
  const coverageAmount = currentOffer?.coveragePricing.perPassengerPrices.fiat;
  // [DIS-1695] For FDG offers (Delay/MC), use `minDelayMinutes` to determine copy
  const minDelayMinutes =
    currentOffer?.extraProperties?.policyDetails.minDelayMinutes;
  const delayThresholdStringInHours =
    minDelayMinutes && minDelayMinutes == 180 ? "3" : "2";
  const coverageCopy = coverageAmount
    ? getTotalPriceText({
        price: coverageAmount,
        priceFormatter: (price) => price.toLocaleString("en-US"),
      })
    : undefined;

  const getTopContent = (): GenericDetailsCardTopSectionComponent => {
    return {
      className: "disruption-protection-details-header-with-image",
      image: DisruptionProtectionImageMobile,
      icon: IconName.DisruptionProtection,
      location: constants.LOCATION_TEXT,
      header: DISRUPTION_PROTECTION_HEADER({
        useBreak: matchesMobile,
        punctuation: matchesDesktop ? "." : undefined,
      }),
      component:
        GenericDetailsCardTopSectionComponentEnum.HeaderWithImageBackground,
    };
  };

  const handleViewTerms = () => {
    window.open(PATH_DISRUPTION_PROTECTION, "_blank")?.focus();
  };

  const MainContent = useMemo((): GenericDetailsCardComponent[] => {
    if (!currentOffer) {
      return [];
    }

    const mainContent: GenericDetailsCardComponent[] = [];
    if (isWrapperUIAvailable && matchesMobile) {
      mainContent.push({
        component: GenericDetailsCardComponentEnum.Custom,
        content: (
          <div className="disruption-protection-details-top-content">
            <Box className="icon-section">
              <Box className="icon-wrapper">
                <Icon
                  name={IconName.DisruptionProtectionNoCircle}
                  className="add-on-item-icon"
                />
              </Box>
            </Box>
            <p className="title">
              <b>Flight disruption assistance</b>
            </p>
          </div>
        ),
      });
    }
    const continueCta: GenericDetailsCardComponent = {
      message: constants.CONTINUE_BUTTON_COPY,
      onClick: onContinue,
      onClickWhenDisabled: () => {
        if (!selectedValue) {
          setErrorFromInsideComponent(
            ErrorFromInsideComponentType.NoOptionSelected
          );
        }
      },
      ariaLabel: constants.CONTINUE_BUTTON_COPY,
      disabled: isUnselected,
      floating: matchesMobile,
      hidden: matchesMobile && isUnselected,
      fill: "blue",
      component: GenericDetailsCardComponentEnum.GenericCta,
    };
    const addGenericCopyWithIcon = ({
      copy,
      icon,
      className,
      disabled,
      sparse,
    }: {
      copy: string;
      icon: IconName;
      className?: string;
      disabled?: boolean;
      sparse?: boolean;
    }) => {
      mainContent.push({
        className: clsx(
          "disruption-protection-details-check-mark-icon",
          className,
          {
            fade: disabled,
            sparse,
          }
        ),
        type: "secondary",
        copy: copy,
        icon: icon,
        component: GenericDetailsCardComponentEnum.GenericCopy,
      });
    };

    // Error can be tracked from:
    // 1) The continue button outside of this modal clicked. This is signaled via the error
    //    passed along in the props
    // 2) The continue button of this modal clicked.
    const hasError =
      error === ErrorOutsideComponentType.NoOptionSelected ||
      errorFromInsideComponent ===
        ErrorFromInsideComponentType.NoOptionSelected;

    if (!isAirOfferRedesignEnabled) {
      mainContent.push({
        className: clsx("disruption-protection-details-generic-copy", {
          fade: disabled,
        }),
        type: "secondary",
        copy: constants.CHOOSE_THE_FOLLOWING_COPY,
        component: GenericDetailsCardComponentEnum.GenericCopy,
      });

      if (coverageCopy) {
        addGenericCopyWithIcon({
          copy: constants.POINT_ONE_V2_TEXT(coverageCopy),
          icon: IconName.CalendarRightArrowBlue,
          className: "with-calendar-icon",
          disabled,
          sparse: true,
        });
      }
      addGenericCopyWithIcon({
        copy: constants.POINT_TWO_V2_TEXT,
        icon: IconName.MoneyCounterClockwiseBlue,
        className: "with-money-icon",
        disabled,
        sparse: true,
      });

      mainContent.push({
        className: clsx("disruption-protection-details-generic-copy", {
          fade: disabled,
        }),
        type: "secondary",
        copy: constants.DISRUPTED_FLIGHT_DESCRIPTION_COPY(
          delayThresholdStringInHours
        ),
        component: GenericDetailsCardComponentEnum.GenericCopy,
        newSection: true,
      });

      if (hasError) {
        mainContent.push({
          className: "disruption-protection-details-error-message",
          type: "error",
          copy: constants.DISRUPTION_PROTECTION_NO_OPTION_SELECTED_COPY,
          component: GenericDetailsCardComponentEnum.GenericCopy,
          newSection: true,
        });
      }

      mainContent.push({
        radioButtons: [
          {
            value: DisruptionProtectionOption.Yes,
            copy: constants.YES_OPTION_COPY(
              currentOffer.premiumPricing.perPassengerPrices.fiat,
              selectedAccountReferenceId
                ? currentOffer.premiumPricing.perPassengerPrices.rewards[
                    selectedAccountReferenceId
                  ]
                : undefined
            ),
            disabled: isAgentPortal,
            leftContent: isCfarCoMerchEnabled
              ? constants.YES_OPTION_COPY_SHORT
              : undefined,
            rightContent: isCfarCoMerchEnabled ? (
              <>
                {getCoMerchPriceWithRewardsUILabel({
                  price: currentOffer.premiumPricing.perPassengerPrices.fiat,
                  priceFormatter: twoDecimalFormatter,
                  label: constants.PER_TRAVELER,
                  rewards: selectedAccountReferenceId
                    ? currentOffer.premiumPricing.perPassengerPrices.rewards[
                        selectedAccountReferenceId
                      ]
                    : undefined,
                  mobile: matchesMobile,
                })}
              </>
            ) : undefined,
          },
          {
            value: DisruptionProtectionOption.No,
            copy: constants.NO_OPTION_COPY,
          },
        ],
        selectedValue,
        setSelectedValue: (value: number) => {
          console.log("value", value);
          switch (value) {
            case DisruptionProtectionOption.Yes:
              !isAgentPortal &&
                setSelectedDisruptionProtectionId(currentOffer.id);
              break;
            case DisruptionProtectionOption.No:
              setSelectedDisruptionProtectionId({
                policyId: DO_NOT_APPLY_OPTION_KEY,
                productId: DO_NOT_APPLY_OPTION_KEY,
              });
              break;
            default:
              break;
          }

          setErrorFromInsideComponent(null);

          if (onSelectValueCallback) {
            onSelectValueCallback();
          }
        },
        // Collapse only after activation to allow keyboard users
        // to still hear the option selected and possibly navigate to
        // other options.
        onConfirmSelectedValue: () => {
          if (onConfirmSelectedValueCallback) {
            onConfirmSelectedValueCallback();
          }
        },
        hasError: hasError,
        disabled: disabled,
        radioGroupName: constants.DISRUPTION_PROTECTION_RADIO_BUTTONS_GROUP,
        component: GenericDetailsCardComponentEnum.BoxedRadioGroup,
      });
    } else {
      mainContent.push({
        component: GenericDetailsCardComponentEnum.Custom,
        content: (
          <>
            <div className="info-box-row">
              <div className="info-box-item-1-3">
                <InfoBox
                  title={constants.INFO_BOX_PRIMARY_TITLE}
                  bodySections={[
                    <div className="info-box-item-primary">
                      {renderFDABulletList({
                        delayThresholdHours: delayThresholdStringInHours,
                      })}
                    </div>,
                  ]}
                  mobile={matchesMobile}
                />
              </div>
              <div className="info-box-item-2-3">
                <InfoBox
                  className="secondary-info-box"
                  mobile={matchesMobile}
                  title={constants.INFO_BOX_SECONDARY_TITLE}
                  bodySections={[
                    renderFDAInfoBoxOptionOne(),
                    renderFDAInfoBoxOptionTwo(),
                  ]}
                />
              </div>
            </div>
          </>
        ),
      });
      mainContent.push({
        className: clsx("disruption-body-air-offer-redesign"),
        title: constants.MORE_DETAILS_TITLE,
        items: [
          constants.MORE_DETAILS_LINE_ONE,
          constants.MORE_DETAILS_LINE_TWO,
          constants.MORE_DETAILS_LINE_THREE,
          constants.MORE_DETAILS_LINE_FOUR,
        ],
        isOrdered: false,
        component: GenericDetailsCardComponentEnum.PointsGroup,
        newSection: true,
      });

      if (hasSelectedCorpFintech) {
        mainContent.push({
          content: (
            <Alert
              icon={<CheckCircleOutlined fontSize="inherit" />}
              severity="success"
              className="corp-fintech-credit-alert"
            >
              <AlertTitle className="corp-fintech-credit-alert-title">
                $30 travel credit
              </AlertTitle>
              As a thank you for participating in our test, a $30 travel credit
              has been added to your wallet. Apply it at checkout for this
              flight or future bookings.
            </Alert>
          ),
          component: GenericDetailsCardComponentEnum.Custom,
        });
      }

      mainContent.push({
        radioButtons: [
          {
            value: DisruptionProtectionOption.Yes,
            copy: constants.YES_OPTION_COPY(
              currentOffer.premiumPricing.perPassengerPrices.fiat,
              selectedAccountReferenceId
                ? currentOffer.premiumPricing.perPassengerPrices.rewards[
                    selectedAccountReferenceId
                  ]
                : undefined
            ),
            disabled: isAgentPortal,
            leftContent: isCfarCoMerchEnabled
              ? constants.YES_OPTION_COPY_SHORT
              : undefined,
            rightContent: isCfarCoMerchEnabled ? (
              <>
                {getCoMerchPriceWithRewardsUILabel({
                  price: currentOffer.premiumPricing.perPassengerPrices.fiat,
                  priceFormatter: twoDecimalFormatter,
                  label: constants.PER_TRAVELER,
                  rewards: selectedAccountReferenceId
                    ? currentOffer.premiumPricing.perPassengerPrices.rewards[
                        selectedAccountReferenceId
                      ]
                    : undefined,
                  mobile: matchesMobile,
                })}
              </>
            ) : undefined,
          },
          ...(showCorpFintechSubscription
            ? [
                {
                  value: DisruptionProtectionOption.Subscription,
                  title: (
                    <Typography
                      variant="h6"
                      className="fintech-subscription-title"
                    >
                      {constants.CORP_SUBSCRIPTION_TITLE}
                      <span className="fintech-subscription-chip">
                        {constants.CORP_SUBSCRIPTION_CHIP}
                      </span>
                    </Typography>
                  ),
                  copy: constants.CORP_SUBSCRIPTION_COPY(
                    currentOffer.premiumPricing.perPassengerPrices.fiat,
                    selectedAccountReferenceId
                      ? currentOffer.premiumPricing.perPassengerPrices.rewards[
                          selectedAccountReferenceId
                        ]
                      : undefined
                  ),
                  disabled: isAgentPortal,
                },
              ]
            : []),
          {
            value: DisruptionProtectionOption.No,
            copy: constants.NO_OPTION_COPY,
          },
        ],
        selectedValue,
        setSelectedValue: (value: number) => {
          switch (value) {
            case DisruptionProtectionOption.Yes:
              !isAgentPortal &&
                setSelectedDisruptionProtectionId(currentOffer.id);
              break;
            case DisruptionProtectionOption.No:
              setSelectedDisruptionProtectionId({
                policyId: DO_NOT_APPLY_OPTION_KEY,
                productId: DO_NOT_APPLY_OPTION_KEY,
              });
              break;
            case DisruptionProtectionOption.Subscription:
              trackEvent({
                eventName: "selected_fintech_subscription",
                properties: {
                  test_type: corpSmoketestVariant,
                  subscription_type: "fda_10dollarsoff3bookings",
                },
              });
              setSelectedDisruptionProtectionId({
                policyId: CORP_FINTECH_SUBSCRIPTION_KEY,
                productId: CORP_FINTECH_SUBSCRIPTION_KEY,
              });
              break;
            default:
              break;
          }

          setErrorFromInsideComponent(null);

          if (onSelectValueCallback) {
            onSelectValueCallback();
          }
        },
        // Collapse only after activation to allow keyboard users
        // to still hear the option selected and possibly navigate to
        // other options.
        onConfirmSelectedValue: () => {
          if (onConfirmSelectedValueCallback) {
            onConfirmSelectedValueCallback();
          }
        },
        hasError: hasError,
        disabled: disabled,
        radioGroupName: constants.DISRUPTION_PROTECTION_RADIO_BUTTONS_GROUP,
        component: GenericDetailsCardComponentEnum.BoxedRadioGroup,
      });
    }
    if (!hideContinueCta && (!usePartialScroll || matchesMobile)) {
      mainContent.push(continueCta);
    }

    if (matchesMobile) {
      mainContent.push({
        content: [
          {
            title: constants.TERMS_AND_CONDITIONS_COPY,
            body: DISRUPTION_PROTECTION_BODY,
          },
        ],
        component: GenericDetailsCardComponentEnum.AccordionGroup,
      });
    } else if (!usePartialScroll) {
      mainContent.push({
        message: constants.TERMS_AND_CONDITIONS_COPY,
        onClick: handleViewTerms,
        position: readTermsAndConditionsLinkPosition ?? "center",
        disabled: disabled,
        underline: true,
        component: GenericDetailsCardComponentEnum.ClickableLink,
      });
    }

    return mainContent;
  }, [
    currentOffer,
    selectedAccountReferenceId,
    isAgentPortal,
    selectedValue,
    onContinue,
    isUnselected,
    matchesMobile,
    usePartialScroll,
    hideContinueCta,
    readTermsAndConditionsLinkPosition,
    onConfirmSelectedValueCallback,
    error,
  ]);

  const BottomContent = useMemo(():
    | GenericDetailsCardComponent[]
    | undefined => {
    if (!currentOffer) {
      return [];
    }

    if (!usePartialScroll || matchesMobile) {
      return undefined;
    }

    return [
      {
        message: constants.VIEW_TERMS_AND_CONDITIONS_COPY,
        onClick: handleViewTerms,
        component: GenericDetailsCardComponentEnum.ClickableLink,
      },
      {
        className:
          "disruption-protection-details-bottom-banner-continue-button",
        message: constants.CONTINUE_BUTTON_COPY,
        onClick: onContinue,
        ariaLabel: constants.CONTINUE_BUTTON_COPY,
        disabled: isUnselected,
        onClickWhenDisabled: () => {
          if (!selectedValue) {
            setErrorFromInsideComponent(
              ErrorFromInsideComponentType.NoOptionSelected
            );
          }
        },
        floating: false,
        fill: "blue",
        component: GenericDetailsCardComponentEnum.GenericCta,
      },
    ];
  }, [onContinue, isUnselected, matchesMobile, usePartialScroll, currentOffer]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // note: scroll to top upon arriving at this screen on mobile.
    if (matchesMobile) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [matchesMobile]);

  useEffect(() => {
    if (isAgentPortal) {
      setSelectedDisruptionProtectionId({
        policyId: DO_NOT_APPLY_OPTION_KEY,
        productId: DO_NOT_APPLY_OPTION_KEY,
      });
    }
  }, [isAgentPortal]);

  // There are 3 cases:
  // 1) Call finished, but failed or without DISRUPTION_PROTECTION offer ->
  //    Do not return any element and just call onNoDisruptionProtectionOffer(), which should direct user to the next page)
  // 2) Call not finished -> Show LoadingPopup
  // 3) Call finished, with DISRUPTION_PROTECTION offer -> Show GenericDetailsCard
  useEffect(() => {
    const noDisruptionProtectionOffer =
      fetchAncillaryOfferCallState === CallState.Failed ||
      (fetchAncillaryOfferCallState === CallState.Success &&
        !hasDisruptionProtectionOffer);

    if (noDisruptionProtectionOffer) {
      onNoDisruptionProtectionOffer();
    }
  }, [fetchAncillaryOfferCallState, hasDisruptionProtectionOffer]);

  useEffect(() => {
    if (showCorpFintechSubscription) {
      trackEvent({
        eventName: "viewed_fintech_subscription",
        properties: {
          test_type: corpSmoketestVariant,
          subscription_type: "fda_10dollarsoff3bookings",
        },
      });
    }
  }, [showCorpFintechSubscription]);

  if (fetchAncillaryOfferCallState === CallState.InProcess) {
    return (
      <LoadingPopup
        indicatorSize="medium"
        verticalAlignment="center"
        indicator={B2BSpinner}
        open={true}
        popupSize="small"
        fullScreen={matchesMobile}
        message="Loading offer"
      />
    );
  } else {
    return (
      <>
        {fetchAncillaryOfferCallState === CallState.Success &&
          hasDisruptionProtectionOffer && (
            <GenericDetailsCard
              topContent={!hideTopContent ? getTopContent() : undefined}
              mainContent={MainContent}
              bottomContent={BottomContent}
              // note: it's not rendered as a modal on mobile view
              openModal={openDisruptionProtectionDetails ?? matchesMobile}
              onClose={onClose}
              isMobile={matchesMobile}
              contentOnly={contentOnly ?? matchesMobile}
              scrollOption={
                usePartialScroll ? "partial-scroll" : "default-scroll"
              }
              redesignClassName={
                isAirOfferRedesignEnabled
                  ? constants.AIR_OFFER_REDESIGN_CLASSNAME
                  : undefined
              }
            />
          )}
      </>
    );
  }
};
