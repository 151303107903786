import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  FlightShopStep,
  ITripTerminus,
  RegionType,
  FlightShopType,
  CHANGED_TRIP_TYPE,
  IPassengerCounts,
  CHANGED_PAX_COUNT,
  TripCategory,
  CLEAR_ALL_FILTERS,
} from "redmond";
import "./styles.scss";
import { CalendarPickerButton } from "../FlightSearchControlV2/components";
import { FlightShopSearchFilter } from "./components/FlightShopSearchFilter";
import { AppliedFilterTags } from "./components/AppliedFilterTags";
import { FlightSearchButton } from "../SearchButton";
import * as textConstants from "./textConstants";
import { RouteComponentProps } from "react-router";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../../../shop/utils/parseQueryString";
import { TodayTomorrowToggle } from "../../../ancillary/components";
import { FlightShopSearchControlConnectorProps } from "./container";
import {
  ActionButton,
  B2BButton,
  DesktopPopupModal,
  FlightCategoryRadio,
  Icon,
  IconName,
  PassengerCountPicker,
  PassengerCountPickerType,
  UserPreferencesAppliedBanner,
} from "halifax";
import { isEqual } from "lodash-es";
import dayjs from "dayjs";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { MulticityEditSearchControl } from "./components/MulticityEditSearchControl";
import { MulticityFlightSearchRouteList } from "../MulticityFlightSearchRouteList";
import { MulticityFlightShopStep } from "../../../shop/reducer";
import { MulticityRoute } from "../../reducer";
import { OriginDestinationSearch } from "../FlightSearchControlV2/components/OriginDestinationSearch";
import { useFilterLabelValues } from "../FlightShopSearchControl/useFilterLabelValues";
import { FlightShopFilter } from "../../../shop/components/FlightShopFilter";
import {
  AirCXV3VariantType,
  CONTROL,
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import { isCorpTenant } from "@capone/common";

export interface IFlightShopSearchControlProps
  extends RouteComponentProps,
    FlightShopSearchControlConnectorProps {
  isFlightListOptimizationExperiment?: boolean;
  isMultiCityEnabled?: boolean;
  airCXV3Variant?: AirCXV3VariantType;
  isEditMulticitySearchModalOpen?: boolean;
  setIsEditMulticitySearchModalOpen?: (arg: boolean) => void;
  allFiltersModalOpen: boolean;
  setAllFiltersModalOpen: (open: boolean) => void;
}

export const FlightShopSearchControl = (
  props: IFlightShopSearchControlProps
) => {
  const {
    departureDate,
    destination,
    fetchDepartureCalendar,
    history,
    origin,
    returnDate,
    setCalendar,
    setDepartureDate,
    setDestination,
    setOrigin,
    setReturnDate,
    tripCategory,
    resetAll,
    disabled,
    populateFlightShopQueryParams,
    flightShopProgress,
    flightShopType,
    isFlightListOptimizationExperiment = false,
    isMultiCityEnabled = false,
    setTripCategory,
    numTravelers,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    setPassengerCounts,
    resetSelectedTrip,
    airEntryProperties,
    openCalendar,
    multicityRoutes,
    setMulticityOrigin,
    setMulticityDestination,
    setMulticityDepartureDate,
    setAllMulticityRoutes,
    fareClassFilter,
    stopsOption,
    airlineFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    airlines,
    maxPriceFilter,
    airCXV3Variant,
    isEditMulticitySearchModalOpen,
    setIsEditMulticitySearchModalOpen,
    durationFilter,
    activeFiltersCount,
    shouldApplyUserFlightPreferences,
    setApplyUserFlightPreferences,
    setUserPreferencesNotAvailable,
    policyFilter,
    allFiltersModalOpen,
    setAllFiltersModalOpen,
  } = props;
  const extractCode = (terminus: ITripTerminus | null) =>
    terminus && terminus.id && terminus.id.code ? terminus.id.code.code : "";
  const [initialMulticityRoutes, setInitialMulticityRoutes] =
    useState(multicityRoutes);
  const [hasChangedSearchParams, setHasChangedSearchParams] = useState(false);

  const [hasEditMulticitySearchError, setHasEditMulticitySearchError] =
    useState(false);
  const [hasEditOWRTSearchError, setHasEditOWRTSearchError] = useState(false);
  const [passCounts, setPassCounts] = useState<PassengerCountPickerType>({
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  });
  const [tripType, setTripType] = useState(tripCategory);
  const [dep, setDep] = useState(departureDate);
  const [ret, setRet] = useState(returnDate);
  const [org, setOrg] = useState(origin);
  const [dest, setDest] = useState(destination);
  const [passengerCountPickerOpen, setPassengerCountPickerOpen] =
    useState(false);

  const expState = useExperiments();

  const filterLabelValues = useFilterLabelValues({
    stopsOption,
    airlineFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    maxPriceFilter,
    airlines,
    fareclassFilter: fareClassFilter,
    durationFilter,
    policyFilter,
  });

  const parsedSearchParams = parseQueryString(
    history
  ) as IFlightShopParsedQuery;
  const {
    destination: searchDest,
    origin: searchOrigin,
    returnDate: searchReturn,
    departureDate: searchDeparture,
    adultsCount: searchAdultsCount,
    childrenCount: searchChildrenCount,
    infantsInSeatCount: searchInfantsInSeatCount,
    infantsOnLapCount: searchInfantsOnLapCount,
    tripCategory: searchTripCategory,
    fareClass: searchFareClass,
  } = parsedSearchParams;

  // if MC values empty, populate the MC search state based on OW/RT origin, destination, departureDate, returnDate values
  const mcRoute1 = multicityRoutes[0];
  const mcRoute2 = multicityRoutes[1];

  useEffect(() => {
    if (org && !mcRoute1?.origin) setMulticityOrigin(org, 0);
    if (org && !mcRoute2?.destination) setMulticityDestination(org, 1);
    setInitialMulticityRoutes(multicityRoutes);
  }, [org]);

  useEffect(() => {
    if (dest && !mcRoute1?.destination) setMulticityDestination(dest, 0);
    if (dest && !mcRoute2?.origin) setMulticityOrigin(dest, 1);
    setInitialMulticityRoutes(multicityRoutes);
  }, [dest]);

  useEffect(() => {
    if (dep && !mcRoute1?.departureDate) setMulticityDepartureDate(dep, 0);
    if (dep && !mcRoute2?.departureDate) {
      if (!!ret) {
        setMulticityDepartureDate(ret, 1);
      } else {
        const nextDay = dep
          ? dayjs(dep)
              .add(dayjs.duration({ days: 1 }))
              .toDate()
          : null;
        setMulticityDepartureDate(nextDay, 1);
      }
    }
    setInitialMulticityRoutes(multicityRoutes);
  }, [dep, ret]);

  // fill empty route origins with previous flight destination on multicity routes change
  useEffect(() => {
    if (mcRoute1?.departureDate && !mcRoute2?.departureDate) {
      const nextDay = dayjs(mcRoute1?.departureDate)
        .add(dayjs.duration({ days: 1 }))
        .toDate();
      setMulticityDepartureDate(nextDay, 1);
    }
    for (let i = 0; i < multicityRoutes.length - 1; i++) {
      const currentRoute = multicityRoutes[i];
      const nextRoute = multicityRoutes[i + 1];
      if (currentRoute?.destination && !nextRoute?.origin)
        setMulticityOrigin(currentRoute?.destination, i + 1);
    }
  }, [multicityRoutes]);

  const numTravelerString =
    numTravelers < 2 ? `${numTravelers} Traveler` : `${numTravelers} Travelers`;

  // Set the UI component values based on the values in the state.
  // Note that values in the state may not be available at component load time (e.g. in case they are
  // from the query string), so we need these updates in addition to the initial settings.
  useEffect(() => {
    !isEqual(org, origin) && setOrg(origin);
  }, [origin]);
  useEffect(() => {
    !isEqual(dest, destination) && setDest(destination);
  }, [destination]);
  useEffect(() => {
    if (tripCategory !== tripType) {
      setTripType(tripCategory);
    }
  }, [tripCategory]);
  useEffect(() => {
    if (dep !== departureDate) {
      setDep(departureDate);
    }
  }, [departureDate]);
  useEffect(() => {
    if (ret !== returnDate) {
      setRet(returnDate);
    }
  }, [returnDate]);

  useEffect(() => {
    if (
      searchAdultsCount !== (passCounts as IPassengerCounts).adultsCount ||
      searchChildrenCount !== (passCounts as IPassengerCounts).childrenCount ||
      searchInfantsInSeatCount !==
        (passCounts as IPassengerCounts).infantsInSeatCount ||
      searchInfantsOnLapCount !==
        (passCounts as IPassengerCounts).infantsOnLapCount
    ) {
      setPassCounts({
        adultsCount: searchAdultsCount ?? 0,
        childrenCount: searchChildrenCount ?? 0,
        infantsInSeatCount: searchInfantsInSeatCount ?? 0,
        infantsOnLapCount: searchInfantsOnLapCount ?? 0,
      });
    }
  }, [
    searchAdultsCount,
    childrenCount,
    searchInfantsInSeatCount,
    searchInfantsOnLapCount,
  ]);

  // End of UI component update settings

  useEffect(() => {
    const originCode = extractCode(org);
    const destinationCode = extractCode(dest);
    const hasState = destinationCode && originCode && departureDate?.toString();

    if (
      searchTripCategory !== tripType ||
      (hasState &&
        ((searchDest && searchDest !== destinationCode) ||
          (searchOrigin && searchOrigin !== originCode) ||
          (searchDeparture &&
            !dayjs(dep).isSame(dayjs(searchDeparture), "day")) ||
          (searchReturn && !dayjs(ret).isSame(dayjs(searchReturn), "day")) ||
          searchAdultsCount !== (passCounts as IPassengerCounts).adultsCount ||
          searchChildrenCount !==
            (passCounts as IPassengerCounts).childrenCount ||
          searchInfantsInSeatCount !==
            (passCounts as IPassengerCounts).infantsInSeatCount ||
          searchInfantsOnLapCount !==
            (passCounts as IPassengerCounts).infantsOnLapCount))
    ) {
      setHasChangedSearchParams(true);
    } else {
      setHasChangedSearchParams(false);
    }
  }, [
    searchDest,
    searchOrigin,
    searchReturn,
    searchDeparture,
    searchAdultsCount,
    searchChildrenCount,
    searchInfantsInSeatCount,
    searchInfantsOnLapCount,
    searchFareClass,
    org,
    dest,
    departureDate,
    returnDate,
    tripType,
    fareClassFilter,
  ]);

  useEffect(() => {
    if (org && dest) {
      fetchDepartureCalendar(org as ITripTerminus, dest as ITripTerminus);
    } else {
      setCalendar();
    }
  }, [org, dest, tripType]);

  useEffect(() => {
    if (
      tripType !== searchTripCategory &&
      tripType === TripCategory.ROUND_TRIP
    ) {
      openCalendar(true);
    }
  }, [tripType]);

  useEffect(() => {
    if (tripType === TripCategory.ROUND_TRIP && ret)
      setHasEditOWRTSearchError(false);
  }, [tripType, ret]);

  const isAllFieldsPopulated = (routes: MulticityRoute[]) => {
    return routes.every((r) =>
      Object.values(r).every((field) => field !== null)
    );
  };

  const isAllMulticityFieldsPopulated = isAllFieldsPopulated(multicityRoutes);

  useEffect(() => {
    if (
      !isAllFieldsPopulated(initialMulticityRoutes) &&
      isAllMulticityFieldsPopulated
    )
      setInitialMulticityRoutes(multicityRoutes);
  }, [isAllMulticityFieldsPopulated]);

  useEffect(() => {
    if (
      isAllMulticityFieldsPopulated ||
      !(tripType === TripCategory.MULTI_CITY)
    )
      setHasEditMulticitySearchError(false);
  }, [multicityRoutes, tripType]);

  const hasMissingOWRTInfo = useMemo(() => {
    const isRoundTrip = tripType === TripCategory.ROUND_TRIP;
    return !org || !dest || !dep || (isRoundTrip ? !ret : false);
  }, [org, dest, dep, ret, tripType]);

  const onSetReduxStatesAndSearch = (passCountsToUse?: IPassengerCounts) => {
    const passengerCountsChanged = // If user updated the num of travelers in the passenger count picker modal, do a new search on the modal CTA click
      passCountsToUse &&
      (searchAdultsCount !== passCountsToUse?.adultsCount ||
        searchChildrenCount !== passCountsToUse?.childrenCount ||
        searchInfantsInSeatCount !== passCountsToUse?.infantsInSeatCount ||
        searchInfantsOnLapCount !== passCountsToUse?.infantsOnLapCount);
    if (passengerCountsChanged || hasChangedSearchParams) {
      setTripCategory(tripType);
      setDestination(dest);
      setOrigin(org);
      setDepartureDate(dep);
      setPassengerCounts(passCountsToUse ? passCountsToUse : passCounts);
      setReturnDate(tripType == TripCategory.ONE_WAY ? null : ret);
      resetSelectedTrip();
      setHasChangedSearchParams(false);
      populateFlightShopQueryParams({
        history,
        useHistoryPush:
          flightShopProgress === FlightShopStep.ChooseReturn ? false : true,
        forceQueryUpdate: true,
        newQueryParams: {
          flightShopProgress: FlightShopStep.ChooseDeparture,
          isFromFlightWatch: undefined,
        },
      });

      resetAll(airCXV3Variant === CONTROL);
    }
  };

  const isFlightListOptimizationExperimentEnabled =
    isFlightListOptimizationExperiment &&
    flightShopType !== FlightShopType.DISRUPTION_PROTECTION_EXERCISE;

  return (
    <Box
      className={clsx("flight-shop-search-root", {
        "flight-list-optimization-experiment":
          isFlightListOptimizationExperimentEnabled,
      })}
    >
      <Box className="flight-shop-search-container">
        <Box className="flight-shop-search">
          {(isFlightListOptimizationExperimentEnabled ||
            isMultiCityEnabled) && (
            <Box className="flight-category-travelers">
              <FlightCategoryRadio
                selectedCategory={tripType}
                setTripCategory={(newTripCategory) => {
                  setTripType(newTripCategory);
                  trackEvent({
                    eventName: CHANGED_TRIP_TYPE,
                    properties: {
                      ...airEntryProperties,
                      trip_type: newTripCategory,
                    },
                  });
                }}
                isMultiCityFlightsAvailable={isMultiCityEnabled}
              />
              <B2BButton
                aria-label={numTravelerString}
                className="num-travelers-input b2b"
                variant="traveler-selector"
                onClick={() => setPassengerCountPickerOpen(true)}
              >
                <Box className="num-traveler-content">
                  <Icon
                    aria-hidden={true}
                    className="icon-start"
                    name={IconName.B2BUser}
                    ariaLabel=""
                  />
                  <Box className="text">{numTravelerString}</Box>
                  <Icon
                    aria-hidden={true}
                    className="icon-end"
                    name={IconName.Dropdown}
                    ariaLabel=""
                  />
                </Box>
              </B2BButton>

              <DesktopPopupModal
                open={passengerCountPickerOpen}
                className="flight-desktop-passenger-count-picker-popup"
                contentClassName="desktop-passenger-count-picker-popup-container"
                onClose={() => setPassengerCountPickerOpen(false)}
                invisibleBackdrop={false}
                headerElement={textConstants.EDIT_TRAVELERS_TITLE}
              >
                <PassengerCountPicker
                  minimumAdultsCount={1}
                  onClickApply={(counts: PassengerCountPickerType) => {
                    setPassCounts(counts as IPassengerCounts);
                    trackEvent({
                      eventName: CHANGED_PAX_COUNT,
                      properties: {
                        ...airEntryProperties,
                        pax_total:
                          adultsCount +
                          childrenCount +
                          infantsInSeatCount +
                          infantsOnLapCount,
                      },
                    });
                    onSetReduxStatesAndSearch(counts as IPassengerCounts);
                    setPassengerCountPickerOpen(false);
                  }}
                  className="b2b"
                  counts={passCounts}
                  setPassengerCounts={setPassCounts}
                  buttonText={textConstants.SEARCH_AGAIN}
                  enableButtonOnChangeOnly
                  includeChildrenInMaxCount
                />
              </DesktopPopupModal>

              {isEditMulticitySearchModalOpen &&
                setIsEditMulticitySearchModalOpen && (
                  <DesktopPopupModal
                    open={isEditMulticitySearchModalOpen}
                    headerElement={textConstants.EDIT_MULTICITY_TITLE}
                    className="flight-desktop-edit-multicity-search-popup"
                    contentClassName="flight-desktop-edit-multicity-search-popup-content-container"
                    invisibleBackdrop={false}
                    onClose={() => {
                      setAllMulticityRoutes(initialMulticityRoutes);
                      setIsEditMulticitySearchModalOpen(false);
                    }}
                  >
                    <Typography className="edit-multicity-subtitle">
                      {textConstants.EDIT_MULTICITY_SUBTITLE}
                    </Typography>
                    <MulticityFlightSearchRouteList
                      hasMissingMulticitySearchInfoError={
                        hasEditMulticitySearchError
                      }
                    />
                    <ActionButton
                      fill="blue"
                      buttonClassName="multicity-edit-flight-search-again-button"
                      onClick={() => {
                        if (isAllMulticityFieldsPopulated) {
                          setInitialMulticityRoutes(multicityRoutes);
                          setIsEditMulticitySearchModalOpen(false);
                          setTripCategory(tripType);
                          setPassengerCounts(passCounts);
                          resetSelectedTrip();
                          populateFlightShopQueryParams({
                            history,
                            forceQueryUpdate: true,
                            newQueryParams: {
                              multicityFlightShopProgress:
                                MulticityFlightShopStep.ChooseDeparture0,
                            },
                          });
                          resetAll(airCXV3Variant === CONTROL);
                        } else {
                          setHasEditMulticitySearchError(true);
                        }
                      }}
                      message={textConstants.SEARCH_AGAIN}
                    />
                  </DesktopPopupModal>
                )}
            </Box>
          )}

          {flightShopType !== FlightShopType.DISRUPTION_PROTECTION_EXERCISE && (
            <>
              {tripType === TripCategory.MULTI_CITY &&
                setIsEditMulticitySearchModalOpen && (
                  <MulticityEditSearchControl
                    multicityRoutes={initialMulticityRoutes}
                    setEditMulticitySearchModalOpen={() =>
                      setIsEditMulticitySearchModalOpen(true)
                    }
                  />
                )}
              {tripType !== TripCategory.MULTI_CITY && (
                <>
                  <Box className="location-and-date-pickers">
                    <OriginDestinationSearch
                      origin={org}
                      setOrigin={setOrg}
                      destination={dest}
                      setDestination={setDest}
                      disabled={disabled}
                      hasMissingSearchInfoError={hasEditOWRTSearchError}
                      withSwap={tripType === TripCategory.ROUND_TRIP}
                    />
                    <CalendarPickerButton
                      departureDate={dep}
                      returnDate={ret}
                      setDepartureDate={(date) => setDep(date)}
                      setReturnDate={(date) => setRet(date)}
                      classes={["departure-date-input"]}
                      disabled={disabled}
                      tripCategory={tripType}
                      hasMissingSearchInfoError={hasEditOWRTSearchError}
                    />
                    <Box className="search-button">
                      {hasChangedSearchParams && !disabled && (
                        <FlightSearchButton
                          enabled
                          className={clsx("flight-search-button", "b2b")}
                          message={textConstants.SEARCH_AGAIN}
                          onClick={() => {
                            if (hasMissingOWRTInfo)
                              return setHasEditOWRTSearchError(true);
                            onSetReduxStatesAndSearch();
                            resetAll(true);
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  {hasEditOWRTSearchError && (
                    <Typography className="incomplete-search-values-warning">
                      {textConstants.INCOMPLETE_SEARCH_VALUES_WARNING}
                    </Typography>
                  )}
                </>
              )}
            </>
          )}

          {!isFlightListOptimizationExperimentEnabled && (
            <>
              <Box
                className={clsx("filter-pickers", {
                  "capone-corporate": isCorpTenant(config.TENANT),
                })}
              >
                <FlightShopSearchFilter
                  hideAirportFilter={
                    origin?.id.code.regionType === RegionType.Airport
                  }
                />
              </Box>
              <Box className="filter-reset-chips">
                <AppliedFilterTags />
              </Box>
            </>
          )}
        </Box>
        {airCXV3Variant !== CONTROL && (
          <Box className={"filter-modal-entry-points air-cx-v3"}>
            <FlightShopFilter
              openAllFiltersModal={allFiltersModalOpen}
              setOpenAllFiltersModal={setAllFiltersModalOpen}
              isFlightListOptimizationExperiment={
                isFlightListOptimizationExperiment
              }
              airCXV3Variant={airCXV3Variant}
            />
            <ActionButton
              className={clsx("filters-modal-action-button")}
              defaultStyle="h4r-secondary"
              message={textConstants.FILTERS_MODAL_CTA_TEXT(activeFiltersCount)}
              icon={<Icon name={IconName.Settings} />}
              onClick={() => setAllFiltersModalOpen(true)}
            />
            <Box className="filters">
              <FlightShopSearchFilter
                hideAirportFilter={
                  origin?.id.code.regionType === RegionType.Airport
                }
                isFlightListOptimizationExperiment={
                  isFlightListOptimizationExperiment
                }
                filterLabelValues={filterLabelValues}
                airCXV3Variant={airCXV3Variant}
              />
            </Box>
          </Box>
        )}
        {shouldApplyUserFlightPreferences && (
          <Box className="flight-preferences-applied-banner-container">
            <UserPreferencesAppliedBanner
              resetFilters={() => {
                resetAll(true);
                setApplyUserFlightPreferences(false);
                setUserPreferencesNotAvailable(false);

                trackEvent({
                  eventName: CLEAR_ALL_FILTERS,
                  properties: addTrackingProperties(
                    expState.trackingProperties
                  ),
                });
              }}
              type={"flight"}
            />
          </Box>
        )}
      </Box>
      {flightShopType === FlightShopType.DISRUPTION_PROTECTION_EXERCISE && (
        <Box className="date-toggle-switch-section">
          <TodayTomorrowToggle size="small" />
        </Box>
      )}
    </Box>
  );
};
