import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { priceDropProtectionCandidateIdSelector } from "../../../reducer/selectors";
import { PriceFreezeFlightDetailsModal } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../../book/actions/actions";
import { isPriceFreezeDurationPopupEnabledSelector, priceFreezeMiddleOfferSelector, priceFreezeDefaultDurationsSelector } from "../../../../freeze/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../rewards/reducer";
const mapStateToProps = (state: IStoreState) => {
  return {
    priceDropProtectionCandidateId:
      priceDropProtectionCandidateIdSelector(state),
    isPriceFreezeDurationPopupEnabled: isPriceFreezeDurationPopupEnabledSelector(state),
    priceFreezeMiddleOffer: priceFreezeMiddleOfferSelector(state),
    priceFreezeDefaultDurationsVariant: priceFreezeDefaultDurationsSelector(state),
    selectedRewardsAccountId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeFlightDetailsModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeFlightDetailsModal = withRouter(
  connector(PriceFreezeFlightDetailsModal)
);
