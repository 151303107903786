import React from "react";
import { MobileFlightDetailsModal } from "halifax";
import { FareDetails, TripDetails } from "redmond";

import { getSliceIndex } from "../../../../../../utils/flights";
import { getSummaryPanelHeader } from "../../../FlightShopSummaryPanel/constants";
import "./styles.scss";
import { getPlusDays } from "../../../../reducer/utils";
import clsx from "clsx";

interface IMobileItineraryModalProps {
  isMulticity?: boolean;
  multicityTripSliceIndex?: number;
  isDeparture: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails | undefined;
  openModal: boolean;
  onClose: () => void;
  buttonText?: string;
  isMixedCabinClass?: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  flightCombinationBanner?: React.ReactNode;
  missedConnectionGuarantee?: React.ReactNode;
}

export const MobileItineraryDetailsModal = (
  props: IMobileItineraryModalProps
) => {
  const {
    isDeparture,
    tripDetails,
    openModal,
    isMixedCabinClass,
    isMulticity,
    multicityTripSliceIndex,
    isSeatsUXOptimizationExperiment,
  } = props;

  if (!openModal) return null;

  const sliceIndex = isMulticity
    ? multicityTripSliceIndex!
    : tripDetails
      ? getSliceIndex(isDeparture, tripDetails)
      : 0;
  const tripSlice = tripDetails?.slices[sliceIndex];

  const renderCardHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");
    return (
      <>
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
      </>
    );
  };
  return (
    <MobileFlightDetailsModal
      {...props}
      departure={isDeparture}
      title={renderCardHeader(
        getSummaryPanelHeader(
          props.isDeparture,
          tripSlice?.destinationCode,
          tripSlice?.departureTime,
          true
        )
      )}
      plusDays={getPlusDays(tripSlice)}
      isMixedCabinClass={isMixedCabinClass}
      className={clsx(
        "mobile-itinerary-details-modal-root",
        "b2b",
        "b2b-secondary"
      )}
      showAvailableSeats={isSeatsUXOptimizationExperiment}
    />
  );
};
