import { connect } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { PassengerCountPicker } from "halifax";
import { IPassengerCounts, GuestsSelection } from "redmond";
import { setPassengerCounts } from "../../../../actions/actions";
import {
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
} from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const counts: IPassengerCounts = {
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
  };
  return {
    counts,
  };
};

const mapDispatchToProps = {
  setPassengerCounts: (counts: IPassengerCounts | GuestsSelection) =>
    setPassengerCounts(counts as IPassengerCounts),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ConnectedPassengerCountPicker = connector(PassengerCountPicker);
