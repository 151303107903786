import axios from "axios";
import { TripDetails } from "redmond";
import { airShoppingApi } from "@b2bportal/air-shopping-api";

export const fetchTripDetails = async (
  tripId: string
): Promise<TripDetails> => {
  const req = { value: tripId };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airShoppingApi(axios as any).apiV0TripDetailPost(req);
      resolve(res.data as any);
    } catch (e) {
      reject(e);
    }
  });
};
