import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import { FlightAncillary } from "./component";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightAncillaryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightAncillary = connector(withRouter(FlightAncillary));
