import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { FlightCustomize } from "./component";
import {
  fetchAncillaryOfferCallStateSelector,
  isAddOnOptionAvailableSelector,
} from "../../../reducer/selectors";
import { isMultiPageMarketplaceEnabledSelector } from "../../../../ancillary/reducer/selectors";
import { getTripCategory } from "../../../../search/reducer";
import { setFlightShopProgress } from "../../../actions/actions";
import { IStoreState } from "../../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    isMultiPageMarketplaceEnabled: isMultiPageMarketplaceEnabledSelector(state),
    tripCategory: getTripCategory(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
  };
};

const mapDispatchToProps = {
  setFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightCustomizeConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightCustomize = connector(withRouter(FlightCustomize));
