import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import {
  FlightGridRow,
  B2BFlightCard,
  FlightShopRow,
  formatInterval,
  useDeviceTypes,
  getTotalPriceText,
  getPricesWithComma,
  TriangleIcon,
  ButtonWrap,
} from "halifax";
import {
  TagType,
  getFlightGridFares,
  mapAlgomerchTexts,
  AlgomerchTag,
  IFlightGridFares,
} from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";
import { isCorpTenant } from "@capone/common";
import { FareclassShelfBrandName } from "redmond";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { FlightAlgomerchModal } from "../../../FlightAlgomerchModal";
import { FlightListInfoConnectorProps } from "./container";
import {
  AIR_CX_V3_1,
  AIR_CX_V3_1_VARIANTS,
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  INTERNATIONAL_NGS_EXPERIMENT,
  useExperiments,
} from "../../../../../../context/experiments";
import { config } from "../../../../../../api/config";

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}
interface IFlightListInfoBaseProps {
  className?: string;
  type: FlightCardType;
}

export interface IFlightListInfoContentProps
  extends IFlightListInfoBaseProps,
    FlightListInfoConnectorProps {
  onClick?: () => void;
  onFareClick: (fareId: string, sliceId: string) => void;
  isExpanded?: boolean;
  type: FlightCardType.content;
}

export interface IFlightListInfoSkeletonProps extends IFlightListInfoBaseProps {
  type: FlightCardType.skeleton;
}
const FARE_GRID_TAG_LIMIT = 2;

export const FlightListInfoContent = ({
  isReturn,
  outgoingFareRating,
  tripSummary,
  onClick,
  fareDetails,
  tripType,
  onFareClick,
  maxPriceFilter,
  fareClassFilter,
  rewardsKey,
  isExpanded,
}: IFlightListInfoContentProps) => {
  const { matchesLargeDesktop, matchesDesktop, matchesMobile } =
    useDeviceTypes();
  const expState = useExperiments();
  const ngsEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        INTERNATIONAL_NGS_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const airCXV3Enabled = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        AIR_CX_V3_1,
        AIR_CX_V3_1_VARIANTS
      ) !== CONTROL,
    [expState]
  );

  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [selectedTagValue, setSelectedTagValue] = React.useState<AlgomerchTag>(
    AlgomerchTag.Cheapest
  );
  const [gridFares, setGridFares] = React.useState<IFlightGridFares>({
    basic: null,
    standard: null,
    enhanced: null,
    premium: null,
    luxury: null,
  });

  React.useEffect(() => {
    if (fareDetails) {
      setGridFares(
        getFlightGridFares(
          fareDetails,
          isReturn ? outgoingFareRating : undefined,
          isReturn,
          rewardsKey
        )
      );
    }
  }, [outgoingFareRating, rewardsKey]);

  const handleClickAlgomerchTag = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag = allTags.find((tag) =>
      tagText.includes(mapAlgomerchTexts[tag])
    );

    setSelectedTagValue((selectedTag as AlgomerchTag) ?? AlgomerchTag.Cheapest);
  };

  if (!tripSummary) return null;
  const flightInfo =
    isReturn && tripSummary.returningSlice
      ? tripSummary.returningSlice
      : tripSummary.outgoingSlice;
  const tripId = tripSummary.tripId;

  const showListView = !tripSummary?.domestic && !ngsEnabled;

  const renderGridDesktopFlightListInfo = () => {
    return (
      <FlightGridRow
        key={tripId}
        tripType={tripType}
        className={clsx("flight-grid-row", `row-${tripId}`)}
        airlineCode={flightInfo.segmentDetails[0].airlineCode}
        airlineName={flightInfo.segmentDetails[0].airlineName}
        fareClassFilter={fareClassFilter}
        selectedMaxPrice={maxPriceFilter}
        fares={gridFares}
        departureTime={flightInfo.departureTime}
        arrivalTime={flightInfo.arrivalTime}
        originCode={flightInfo.originCode}
        destinationCode={flightInfo.destinationCode}
        duration={formatInterval(
          dayjs(flightInfo.arrivalTime).diff(
            dayjs(flightInfo.departureTime),
            "minute",
            true
          )
        )}
        layoverString={textConstants.getStopsString(flightInfo.stops)}
        onFareClick={(fareId) => onFareClick(fareId, flightInfo.id)}
        algomerchModalOpen={openModal}
        onAlgomerchInfoClick={(label: string) => {
          handleClickAlgomerchTag(label);
          setOpenModal(true);
        }}
        tagsLimit={FARE_GRID_TAG_LIMIT}
        isExpanded={isExpanded}
        fareCardClassName="b2b"
        type="content"
        isAirCXV3Experiment={airCXV3Enabled}
        viNotificationBannerBox={() => <Box />}
      />
    );
  };

  const renderRowDesktopFlightListInfo = () => {
    const selectedFareClass = Object.keys(fareClassFilter).find(
      (fare) => fareClassFilter[fare]
    );
    let [displayFare] = fareDetails;
    if (selectedFareClass) {
      const allFareDetails = gridFares;
      const selectedFare = allFareDetails[selectedFareClass];
      displayFare =
        fareDetails.find((fare) => fare.fareId === selectedFare?.fareId) ||
        displayFare;
    }
    const tags = [
      displayFare.fareScore.isBest ? AlgomerchTag.BestFlight : null,
      displayFare.fareScore.isCheapest ? AlgomerchTag.Cheapest : null,
      displayFare.fareScore.isBestQuality ? AlgomerchTag.BestQuality : null,
      displayFare.fareScore.isFastest ? AlgomerchTag.Fastest : null,
    ]
      .filter((t): t is AlgomerchTag => t !== null)
      .map((value) => ({ value, type: TagType.Algomerch }));

    const segments = flightInfo.segmentDetails;

    return (
      <ButtonWrap
        className="flight-row-wrapper"
        aria-label="More flight details"
        onClick={onClick}
      >
        <FlightShopRow
          airlineCode={flightInfo.segmentDetails[0].airlineCode}
          airlineName={flightInfo.segmentDetails[0].airlineName}
          arrivalTime={segments[segments.length - 1].arrivalTime}
          className={clsx("small-flight-shop-row", "b2b")}
          currentPriceText={getPricesWithComma(
            getTotalPriceText({ price: displayFare.amount.fiat })
          )}
          rewardsPriceText={
            rewardsKey && displayFare.amount.rewards[rewardsKey]
              ? `${Math.ceil(
                  displayFare.amount.rewards[rewardsKey].value
                ).toLocaleString()} ${
                  displayFare.amount.rewards[rewardsKey].currency
                }`
              : undefined
          }
          departureTime={segments[0].departureTime}
          destinationCode={flightInfo.destinationCode}
          duration={formatInterval(
            dayjs(flightInfo.arrivalTime).diff(
              dayjs(flightInfo.departureTime),
              "minute",
              true
            )
          )}
          key={tripId}
          layoverString={textConstants.getStopsString(flightInfo.stops)}
          onClickTag={(label: string) => {
            handleClickAlgomerchTag(label);
            setOpenModal(true);
          }}
          originCode={flightInfo.originCode}
          tags={isCorpTenant(config.TENANT) ? [] : tags}
          tagsLimit={FARE_GRID_TAG_LIMIT}
          isAirCXV3Experiment={airCXV3Enabled}
        />
        <TriangleIcon
          className={clsx("expand-flight-row-icon", {
            "is-expanded": isExpanded,
          })}
        />
      </ButtonWrap>
    );
  };

  const renderMobileFlightListInfo = () => {
    const selectedFareClass = Object.keys(fareClassFilter).find(
      (fare) => fareClassFilter[fare]
    );
    return (
      <Box className="flight-card-wrapper">
        <B2BFlightCard
          flightInfo={flightInfo}
          fareDetails={fareDetails}
          isReturn={isReturn}
          selectedFareClass={selectedFareClass as FareclassShelfBrandName}
          airlineName={flightInfo.segmentDetails[0].airlineName}
          rewardsKey={rewardsKey}
          onClickTag={(label: string) => {
            handleClickAlgomerchTag(label);
            setOpenModal(true);
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <Box
        className={"flight-list-info-root"}
        onClick={
          (matchesLargeDesktop && !showListView) || matchesMobile
            ? onClick
            : undefined
        }
      >
        {matchesLargeDesktop &&
          !showListView &&
          renderGridDesktopFlightListInfo()}
        {matchesLargeDesktop &&
          showListView &&
          renderRowDesktopFlightListInfo()}
        {matchesMediumDesktopOnly && renderRowDesktopFlightListInfo()}
        {matchesMobile && renderMobileFlightListInfo()}
      </Box>
      <FlightAlgomerchModal
        selectedCategory={selectedTagValue}
        setSelectedCategory={setSelectedTagValue}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export type IFlightListInfoProps =
  | IFlightListInfoContentProps
  | IFlightListInfoSkeletonProps;

export const FlightListInfo = (props: any) => {
  switch (props.type) {
    case "content":
      return <FlightListInfoContent {...props} />;
    case "skeleton":
      return (
        <Box className={"flight-list-info-root"}>
          <FlightGridRow {...props} />
        </Box>
      );
    default:
      return <></>;
  }
};
