import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import {
  ADJUST_FILTERS,
  CHANGE_DATES,
  NO_DATA_ERROR_SUBTITLE,
  NO_DATA_ERROR_TITLE,
  NO_FLIGHTS_ERROR_SUBTITLE,
  NO_FLIGHTS_ERROR_TITLE,
  NO_FLIGHTS_FILTER_ERROR_SUBTITLE,
  PDP_HEADER,
  PDP_TITLE,
  PRICE_PREDICTION_HEADER,
  PRICE_PREDICTION_SUBTITLE,
  READ_TERMS_CONDITION_TEXT,
  UNSUPPORTED_FILTER_COPY,
  WATCH_THIS_TRIP,
  WATCHING,
  getLowestPriceInFiat,
  getLowestPriceInRewards,
  getPriceFreezeTitles,
  getPriceWatchCopy,
  getSubtitle,
  WatchState,
  getPricePredictionCurrentPrice,
  WATCH_TRIP,
  SLASH_SEPARATOR,
} from "../../constants";
import {
  PricePredictionCard,
  PriceWatchCard,
  MobilePopoverCard,
  NoResults,
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  PriceDropProtection,
  MobilePriceDropProtectionImage,
  getPricesWithComma,
  getTotalPriceText,
  B2BSpinner,
  PriceDropProtectionImage,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import {
  TripCategory,
  PriceDropProtectionEnum,
  VIEWED_FORECAST,
  PRICE_DROP_PROTECTION_BODY,
  PRICE_PREDICTION_PRICE_WATCH_BODY,
  PRICE_DROP_VIEWED,
  FiatPrice,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { PriceFreezeEntry } from "../priceFreezeComponents/PriceFreezeEntry";
import { getPriceFreezeRewardsString } from "../../utils/helpers";
import clsx from "clsx";
import {
  AVAILABLE,
  getExperimentVariant,
  PRICE_FREEZE,
  PRICE_DROP_CREDIT,
  useExperiments,
  PRICE_PREDICTION_GRADIENT_V2,
  AIR_CX_V3_1_VARIANT_1,
  AirCXV3VariantType,
} from "../../../../context/experiments";
import PricePredictionBar from "./PricePredictionBar";
import {
  MobilePricePredictionFull,
  MobilePricePredictionFullTitle,
} from "./MobilePricePredictionFull";
import { MobilePricePredictionConnectorProps } from "./container";
import {
  IPriceFreezeFlightDetailsModalOwnPropsForButton,
  IPriceFreezeEntryButtonOwnProps,
} from "../priceFreezeComponents/PriceFreezeEntryButton";
import {
  IPriceFreezeFlightDetailsModalOwnPropsForInfo,
  IPriceFreezeExplanationModalOwnPropsForInfo,
  IPriceFreezeEntryInfoOwnProps,
} from "../priceFreezeComponents/PriceFreezeEntryInfo";
import { PriceWatchOptInCard } from "../PriceWatchOptInCard";
import "./styles.scss";
import { PATH_PRICE_PREDICTION_PRICE_WATCH } from "../../../../utils/urlPaths";
import { PRICE_PREDICTION_APPLIED_FILTERS_NOTICE_TEXT } from "./constants";
import { FlightShopStep } from "../../reducer";
import { IPriceFreezeDurationRadioGroupOwnProps } from "../priceFreezeComponents/PriceFreezeDurationRadioGroup";
import { getConfigTenant } from "@capone/common";

export interface IMobilePricePredictionProps
  extends MobilePricePredictionConnectorProps {
  useLockPriceLanguage?: boolean;
}

export interface IMobilePricePredictionSectionProps
  extends IMobilePricePredictionProps {
  highlightPriceFreezeBox?: boolean;
  useDetailsPrefixPriceFreeze?: boolean;
  showFreezeIconPriceFreeze?: boolean;
  useChevronButtonPriceFreeze?: boolean;
  useLockPriceLanguage?: boolean;
  airCXV3Variant?: AirCXV3VariantType;
}

export const MobilePricePredictionSection = ({
  hasFilters,
  prediction,
  setFiltersOpen,
  refreshPrediction,
  history,
  tripCategory,
  isWatching,
  hasUnsupportedPredictionFilters,
  fetchTripSummaries,
  deleteWatch,
  rewardsKey,
  viewedForecastProperties,
  priceDropViewedProperties,
  setOpenFlightShopCalendarMobile,
  priceFreezeOffer,
  priceFreezeFiat,
  priceFreezeRewards,
  selectedRewardsAccountId,
  priceFreezeCap,
  tripDetails,
  fareId,
  priceFreezeDuration,
  viewedPriceFreezeProperties,
  airports,
  cheapestFrozenPrice,
  rewardsAccounts,
  fetchRewardsAccounts,
  isPriceFreezeOfferIncludedInShopSummary,
  isTripDetailsLoading,
  flightList,
  highlightPriceFreezeBox,
  showFreezeIconPriceFreeze,
  useDetailsPrefixPriceFreeze,
  useChevronButtonPriceFreeze,
  useLockPriceLanguage,
  pricePredictionGradientVariant,
  predictionLoading,
  hasActiveFilters,
  airCXV3Variant,
  canRedeemRewards,
}: IMobilePricePredictionSectionProps) => {
  const [watchModalOpen, setWatchModalOpen] = useState(false);
  const [priceDropProtectionModalOpen, setPriceDropProtectionModalOpen] =
    useState(false);
  const [hasViewedForecast, setHasViewedForecast] = useState(false);
  const [priceFullPredictionModalOpen, setPriceFullPredictionModalOpen] =
    useState(false);
  const [pricePredictionModalOpen, setPricePredictionModalOpen] =
    useState(false);

  const { experiments } = useExperiments();

  const priceFreezeGroup = getExperimentVariant(experiments, PRICE_FREEZE);

  const priceDropCreditGroup = getExperimentVariant(
    experiments,
    PRICE_DROP_CREDIT
  );
  const isPriceDropCreditEnabled = priceDropCreditGroup === AVAILABLE;

  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  useEffect(() => {
    if (refreshPrediction) {
      fetchTripSummaries(history, true);
    }
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      document!.getElementById("nebula_div_btn")!.style.display = "none";
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, []);

  useEffect(() => {
    if (!!rewardsAccounts && rewardsAccounts.length > 0) {
      if (prediction && isPDPEligible) {
        trackEvent({
          eventName: PRICE_DROP_VIEWED,
          properties: {
            ...priceDropViewedProperties,
            page: "flight_list",
          },
        });
      }
    } else {
      fetchRewardsAccounts();
    }
  }, [prediction, isPDPEligible, priceDropViewedProperties, rewardsAccounts]);

  useEffect(() => {
    if (prediction && viewedForecastProperties && !hasViewedForecast) {
      if (isPriceFreezeOfferIncludedInShopSummary) {
        // note: isTripDetailsLoading is of type boolean | null
        if (isTripDetailsLoading === false) {
          trackEvent({
            eventName: VIEWED_FORECAST,
            properties: {
              ...viewedForecastProperties,
              ...(isPriceFreeze ? viewedPriceFreezeProperties : undefined),
            },
          });
          setHasViewedForecast(true);
        }
      } else {
        trackEvent({
          eventName: VIEWED_FORECAST,
          properties: {
            ...viewedForecastProperties,
          },
        });
        setHasViewedForecast(true);
      }
    }
  }, [
    prediction,
    viewedForecastProperties,
    viewedPriceFreezeProperties,
    hasViewedForecast,
    isPriceFreezeOfferIncludedInShopSummary,
    isTripDetailsLoading,
  ]);

  const renderCloseModalButton = (
    modalContent:
      | "priceDropProtection"
      | "pricePrediction"
      | "priceFullPrediction"
  ) => {
    switch (modalContent) {
      case "pricePrediction":
        return (
          <ActionLink
            className="price-drop-protection-close-button"
            onClick={() => setPriceDropProtectionModalOpen(false)}
            content={<CloseButtonIcon className="close-button-icon" />}
            label="Close"
            showTappableArea={true}
          />
        );
      case "priceFullPrediction":
        return (
          <ActionLink
            className="price-drop-protection-close-button"
            onClick={() => setPriceFullPredictionModalOpen(false)}
            content={<CloseButtonIcon className="close-button-icon" />}
            label="Close"
            showTappableArea={true}
          />
        );
      default:
        return (
          <ActionLink
            className="price-drop-protection-close-button"
            onClick={() => setPriceDropProtectionModalOpen(false)}
            content={<CloseButtonIcon className="close-button-icon" />}
            label="Close"
            showTappableArea={true}
          />
        );
    }
  };

  useEffect(() => {
    if (prediction && viewedForecastProperties && !hasViewedForecast) {
      if (isPriceFreezeOfferIncludedInShopSummary) {
        // note: isTripDetailsLoading is of type boolean | null
        if (isTripDetailsLoading === false) {
          trackEvent({
            eventName: VIEWED_FORECAST,
            properties: {
              ...viewedForecastProperties,
              ...(isPriceFreeze ? viewedPriceFreezeProperties : undefined),
            },
          });
          setHasViewedForecast(true);
        }
      } else {
        trackEvent({
          eventName: VIEWED_FORECAST,
          properties: {
            ...viewedForecastProperties,
          },
        });
        setHasViewedForecast(true);
      }
    }
  }, [
    prediction,
    viewedForecastProperties,
    viewedPriceFreezeProperties,
    hasViewedForecast,
    isPriceFreezeOfferIncludedInShopSummary,
    isTripDetailsLoading,
  ]);

  const onAdjustFilters = () => {
    setFiltersOpen(true);
  };

  const onChangeDates = () => {
    setOpenFlightShopCalendarMobile(true);
  };

  const renderCloseButton = () => {
    return (
      <ActionLink
        className="watch-opt-in-popup-close-button"
        onClick={() => setWatchModalOpen(false)}
        content={<CloseButtonIcon className="close-button-icon" />}
        label="Close"
        showTappableArea={true}
      />
    );
  };

  const isPriceFreeze = useMemo(() => {
    return priceFreezeGroup === AVAILABLE;
  }, [priceFreezeGroup]);

  const priceFreezeFlightDetailsModalPropsForButton: IPriceFreezeFlightDetailsModalOwnPropsForButton =
    {
      priceFreezeOffer: priceFreezeOffer,
      frozenPrice: cheapestFrozenPrice,
      duration: priceFreezeDuration,
      tripDetails: tripDetails,
      fareId: fareId,
      airports: airports,
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeFlightDetailsModalPropsForInfo: IPriceFreezeFlightDetailsModalOwnPropsForInfo =
    priceFreezeFlightDetailsModalPropsForButton;

  const PriceFreezeExplanationModalPropsForInfo: IPriceFreezeExplanationModalOwnPropsForInfo =
    {
      showPriceFreezeFlightDetails: true,
      priceFreezeTitles: getPriceFreezeTitles({
        priceFreezeFiat,
        priceFreezeRewards,
        selectedRewardsAccountId,
        priceFreezeCap,
        duration: priceFreezeDuration,
        useLockPriceLanguage,
      }),
      useLockPriceLanguage: useLockPriceLanguage,
    };

  const priceFreezeEntryInfoProps: IPriceFreezeEntryInfoOwnProps = {
    useLowestPriceText: true,
    fiatPrice: getTotalPriceText({
      price: priceFreezeFiat as FiatPrice,
    }),
    rewards: getPriceFreezeRewardsString(
      priceFreezeRewards,
      selectedRewardsAccountId
    ),
    duration: priceFreezeDuration,
    showFreezeIcon: showFreezeIconPriceFreeze,
    useDetailsPrefix: useDetailsPrefixPriceFreeze,
    useLockPriceLanguage: useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForInfo,
    priceFreezeExplanationModalProps: PriceFreezeExplanationModalPropsForInfo,
    isFromFlightShopReviewItinerary: false,
    prediction: prediction,
  };

  const priceFreezeEntryButtonProps: IPriceFreezeEntryButtonOwnProps = {
    showPriceFreezeDetails: true,
    tripDetails: tripDetails,
    fareId: fareId,
    useChevronButton: useChevronButtonPriceFreeze,
    useLockPriceLanguage: useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForButton,
    flightShopStep: FlightShopStep.PricePrediction,
    setCheapestEligibleOfferData: true,
  };

  const priceFreezeDurationRadioProps: IPriceFreezeDurationRadioGroupOwnProps =
    {
      isFromFlightsReviewItineraryScreen: false,
    };

  return (
    <>
      <MobilePopoverCard
        role="tooltip"
        id="mobile-price-prediction-section-popup"
        topRightButton={renderCloseModalButton("pricePrediction")}
        open={priceDropProtectionModalOpen}
        className="mobile-price-prediction-popup"
        contentClassName="mobile-flight-price-prediction-content-wrapper"
        onClose={() => setPriceDropProtectionModalOpen(false)}
      >
        <PriceDropProtection
          className="price-prediction-card"
          image={MobilePriceDropProtectionImage}
          title={PDP_TITLE}
          subtitle={PRICE_PREDICTION_SUBTITLE}
          header={PRICE_PREDICTION_HEADER}
          ctaText={READ_TERMS_CONDITION_TEXT}
          isMobile={true}
          mobileTermsConditionCopy={PRICE_PREDICTION_PRICE_WATCH_BODY(
            getConfigTenant()
          )}
          onClose={() => setPriceDropProtectionModalOpen(false)}
          icon={<Icon className="icon" name={IconName.PricePredictionIcon} />}
        />
      </MobilePopoverCard>
      {prediction && (
        <MobilePopoverCard
          headerElement={<MobilePricePredictionFullTitle />}
          topRightButton={renderCloseModalButton("priceFullPrediction")}
          open={priceFullPredictionModalOpen}
          onClose={() => setPriceFullPredictionModalOpen(false)}
          centered={true}
        >
          <MobilePricePredictionFull
            prediction={prediction}
            tripCategory={tripCategory}
            isWatching={isWatching}
            hasUnsupportedPredictionFilters={hasUnsupportedPredictionFilters}
            deleteWatch={deleteWatch}
            rewardsKey={rewardsKey}
            priceFreezeOffer={priceFreezeOffer}
            priceFreezeFiat={priceFreezeFiat}
            priceFreezeRewards={priceFreezeRewards}
            priceFreezeCap={priceFreezeCap}
            selectedRewardsAccountId={selectedRewardsAccountId}
            tripDetails={tripDetails}
            fareId={fareId}
            priceFreezeDuration={priceFreezeDuration}
            airports={airports}
            cheapestFrozenPrice={cheapestFrozenPrice}
            isPriceFreeze={isPriceFreeze}
            onOpenPriceDropProtection={() => setPricePredictionModalOpen(true)}
            onOpenWatchModal={() => setWatchModalOpen(true)}
            onClose={() => setPriceFullPredictionModalOpen(true)}
            useLockPriceLanguage={useLockPriceLanguage}
            canRedeemRewards={canRedeemRewards}
          />
        </MobilePopoverCard>
      )}
      <MobilePopoverCard
        open={pricePredictionModalOpen}
        onClose={() => setPricePredictionModalOpen(false)}
        className="mobile-price-prediction-modal"
      >
        <PriceDropProtection
          className="price-prediction-card"
          image={PriceDropProtectionImage}
          title={PDP_TITLE}
          onClick={() =>
            window.open(`${PATH_PRICE_PREDICTION_PRICE_WATCH} `, "_blank")
          }
          onClose={() => setPricePredictionModalOpen(false)}
          subtitle={PRICE_PREDICTION_SUBTITLE}
          header={PRICE_PREDICTION_HEADER}
          ctaText={READ_TERMS_CONDITION_TEXT}
          icon={<Icon className="icon" name={IconName.PricePredictionIcon} />}
        />
      </MobilePopoverCard>
      {predictionLoading ? (
        <Box className={clsx("mobile-price-prediction-container", "section")}>
          <Box className="prediction-loading">
            <B2BSpinner />
          </Box>
        </Box>
      ) : prediction && prediction.predictionCopy ? (
        <>
          {hasUnsupportedPredictionFilters ? (
            <Box
              className={clsx("mobile-price-prediction-container", "section")}
            >
              {/*TODO: Bring back if experiment moves to 100%*/}
              {/*<div className="unsupported-filters-banner">*/}
              {/*  {UNSUPPORTED_FILTERS}*/}
              {/*</div>*/}
              {/*<PricePredictionRecommendation*/}
              {/*  hideButton={false}*/}
              {/*  className="price-recommendation"*/}
              {/*  buttonCopy={RESET_FILTERS}*/}
              {/*  onButtonClick={() => {*/}
              {/*    resetFilters();*/}
              {/*    rerunPrediction();*/}
              {/*  }}*/}
              {/*  title={PREDICTION_UNAVAILABLE}*/}
              {/*  subtitle={ADJUST_OR_RESET_SUBTITLE}*/}
              {/*/>*/}
              <PriceWatchCard
                className="price-prediction-watch"
                title={getPriceWatchCopy(
                  isWatching ? WatchState.Watched : WatchState.NotWatching,
                  hasUnsupportedPredictionFilters,
                  prediction.predictionCopy
                )}
                buttonCopy={isWatching ? WATCHING : WATCH_THIS_TRIP}
                onClick={() =>
                  isWatching ? deleteWatch() : setWatchModalOpen(true)
                }
                unsupportedFilterCopy={UNSUPPORTED_FILTER_COPY}
                isWatching={isWatching}
              />
            </Box>
          ) : (
            <>
              <Box
                className={clsx("mobile-price-prediction-container", "section")}
              >
                {airCXV3Variant === AIR_CX_V3_1_VARIANT_1 &&
                  hasActiveFilters && (
                    <NotificationBanner
                      label={PRICE_PREDICTION_APPLIED_FILTERS_NOTICE_TEXT}
                      severity={BannerSeverity.WARNING}
                      icon={<Icon name={IconName.MagnifyingGlass} />}
                      className="price-prediction-applied-filters-notice-banner"
                    />
                  )}
                <PricePredictionBar
                  prediction={prediction}
                  onOpenPrediction={() => setPriceFullPredictionModalOpen(true)}
                  onOpenPriceDrop={() => setPriceDropProtectionModalOpen(true)}
                  expandedGreen={
                    pricePredictionGradientVariant ===
                    PRICE_PREDICTION_GRADIENT_V2
                  }
                />

                <MobilePopoverCard
                  role="tooltip"
                  id="mobile-price-prediction-section-price-drop-protection-popup"
                  topRightButton={renderCloseModalButton("priceDropProtection")}
                  open={priceDropProtectionModalOpen}
                  className="mobile-price-drop-protection-popup"
                  contentClassName="mobile-flight-price-drop-protection-content-wrapper"
                  onClose={() => setPriceDropProtectionModalOpen(false)}
                >
                  <PriceDropProtection
                    className="price-drop-protection-card"
                    image={MobilePriceDropProtectionImage}
                    title={PDP_TITLE}
                    subtitle={getSubtitle(prediction, isPriceDropCreditEnabled)}
                    header={PDP_HEADER}
                    ctaText={READ_TERMS_CONDITION_TEXT}
                    isMobile={true}
                    mobileTermsConditionCopy={PRICE_DROP_PROTECTION_BODY}
                  />
                </MobilePopoverCard>

                <PricePredictionCard
                  className={clsx("current-price", {
                    "no-bottom-border": highlightPriceFreezeBox,
                  })}
                  title=""
                  subtitle={getPricePredictionCurrentPrice(
                    tripCategory === TripCategory.ONE_WAY
                  )}
                  content={
                    <Typography variant="h5">
                      <span className="current-price-lowestprice">
                        <b>
                          {getPricesWithComma(
                            getLowestPriceInFiat(prediction?.lowestPrice)
                          )}
                        </b>
                      </span>
                      {canRedeemRewards &&
                        getLowestPriceInRewards(
                          prediction?.lowestPrice,
                          rewardsKey,
                          SLASH_SEPARATOR
                        )}
                    </Typography>
                  }
                />
                {isPriceFreeze && !!priceFreezeOffer && (
                  <Box
                    className={clsx("price-freeze-section", {
                      "highlight-price-freeze-box": highlightPriceFreezeBox,
                    })}
                  >
                    <PriceFreezeEntry
                      priceFreezeEntryInfoProps={priceFreezeEntryInfoProps}
                      priceFreezeEntryButtonProps={priceFreezeEntryButtonProps}
                      priceFreezeDurationRadioGroupProps={
                        priceFreezeDurationRadioProps
                      }
                    />
                  </Box>
                )}
                {prediction && (
                  <PriceWatchCard
                    className="price-prediction-watch"
                    title={getPriceWatchCopy(
                      isWatching ? WatchState.Watched : WatchState.NotWatching,
                      hasUnsupportedPredictionFilters,
                      prediction.predictionCopy
                    )}
                    buttonCopy={isWatching ? WATCHING : WATCH_TRIP}
                    onClick={() =>
                      isWatching ? deleteWatch() : setWatchModalOpen(true)
                    }
                    isWatching={isWatching}
                  />
                )}
              </Box>
            </>
          )}
          <MobilePopoverCard
            topRightButton={renderCloseButton()}
            open={watchModalOpen}
            className="mobile-flight-watch-opt-in-popup"
            contentClassName="mobile-flight-watch-opt-in-content-wrapper"
            onClose={() => setWatchModalOpen(false)}
            centered={true}
          >
            <PriceWatchOptInCard
              isMobile={true}
              setWatchModalOpen={setWatchModalOpen}
            />
          </MobilePopoverCard>
        </>
      ) : (
        <Box className="mobile-price-prediction-section-error-container">
          {flightList.length > 0 ? (
            <>
              <NoResults
                className="price-prediction-error price-prediction-no-data"
                title={
                  <Typography variant="h6" className="prediction-no-data-title">
                    {NO_DATA_ERROR_TITLE}
                  </Typography>
                }
                subtitle={NO_DATA_ERROR_SUBTITLE}
                hideIcon={true}
              />
              <Button
                onClick={onChangeDates}
                className={clsx(
                  "change-dates-button",
                  "no-data-change-dates-button"
                )}
              >
                {CHANGE_DATES}
              </Button>
            </>
          ) : (
            <>
              <NoResults
                className="price-prediction-error price-prediction-no-flights"
                title={NO_FLIGHTS_ERROR_TITLE}
                subtitle={
                  hasFilters
                    ? NO_FLIGHTS_FILTER_ERROR_SUBTITLE
                    : NO_FLIGHTS_ERROR_SUBTITLE
                }
              />
              <Button
                onClick={hasFilters ? onAdjustFilters : onChangeDates}
                className={"change-dates-button"}
              >
                {hasFilters ? ADJUST_FILTERS : CHANGE_DATES}
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  );
};
