import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { PolicyFilterProps } from "./container";
import {GenericDropdown, PolicyStatusSelection} from "halifax";
import * as textConstants from "./textConstants";

interface IPolicyFilterProps extends PolicyFilterProps {
  showDropdownContentOnly?: boolean;
}

export const PolicyFilterSelection = (props: IPolicyFilterProps) => {
  const {
    policyFilter = false,
    setPolicyFilter,
    showDropdownContentOnly,
  } = props;

  return (
    <Box className="flight-number-selection-dropdown">
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName="b2b-shop-filter"
          popoverClassName={clsx("flight-number-selection-popover", "b2b")}
          dropdownLabel={textConstants.POLICY_FILTER_LABEL}
          ariaLabel="Policy status filter"
          dropdownContent={
            <PolicyStatusSelection
              isInPolicy={policyFilter}
              setIsInPolicy={setPolicyFilter}
            />
          }
        />
      )}
      {!!showDropdownContentOnly && (
        <PolicyStatusSelection
          isInPolicy={policyFilter}
          setIsInPolicy={setPolicyFilter}
        />
      )}
    </Box>
  );
};
