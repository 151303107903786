import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { priceFreezePath } from "../paths";
import { GetPriceFreezeResponse, GetPriceFreezeRequest } from "redmond";

const getPriceFreezePath = `${priceFreezePath}/get`;

export const getPriceFreeze = (
  body: GetPriceFreezeRequest
): Promise<GetPriceFreezeResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<GetPriceFreezeResponse> = await axios.post(getPriceFreezePath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getPriceFreeze;
