import dayjs from "dayjs";
import { MulticityRoute as StoreMulticityRoute } from "..";

export interface MulticityQueryStringValues {
  origin0: string;
  origin1: string;
  origin2?: string;
  origin3?: string;
  origin4?: string;
  destination0: string;
  destination1: string;
  destination2?: string;
  destination3?: string;
  destination4?: string;
  departureDate0: string;
  departureDate1: string;
  departureDate2?: string;
  departureDate3?: string;
  departureDate4?: string;
}

export const getQueryStringValuesFromMcRoutes = (
  multicityRoutes: StoreMulticityRoute[]
) => {
  return multicityRoutes.reduce((acc, mcRoute, index) => {
    const { origin, destination, departureDate } = mcRoute;
    const routeValues = {
      [`origin${index}`]: origin?.id.code.code,
      [`destination${index}`]: destination?.id.code.code,
      [`departureDate${index}`]: dayjs(departureDate).format("YYYY-MM-DD"),
    };
    return { ...acc, ...routeValues };
  }, {}) as MulticityQueryStringValues;
};
