import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { DisruptionProtectionDetails } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import {
  disruptionProtectionOfferSelector,
  selectedDisruptionProtectionIdSelector,
  fetchAncillaryOfferCallStateSelector,
  viewBookingAddOnPropertiesSelector,
  dpOfferPropertiesForViewBookingAddOnSelector,
  hasDisruptionProtectionOfferSelector,
  isCorpFintechEligibleSelector,
} from "../../../../reducer/selectors";
import { setSelectedDisruptionProtectionId } from "../../../../actions/actions";
import { populateFlightBookQueryParams } from "../../../../../book/actions/actions";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer/selectors";
import {
  isAirOfferRedesignEnabledSelector,
  isCfarCoMerchEnabledSelector,
} from "../../../../../ancillary/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedDisruptionProtectionId:
      selectedDisruptionProtectionIdSelector(state),
    disruptionProtectionOffer: disruptionProtectionOfferSelector(state),
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    viewBookingAddOnProperties: viewBookingAddOnPropertiesSelector(state),
    dpOfferPropertiesForViewBookingAddOn:
      dpOfferPropertiesForViewBookingAddOnSelector(state),
    hasDisruptionProtectionOffer: hasDisruptionProtectionOfferSelector(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    isCfarCoMerchEnabled: isCfarCoMerchEnabledSelector(state),
    isCorpFintechEligible: isCorpFintechEligibleSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setSelectedDisruptionProtectionId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DisruptionProtectionDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDisruptionProtectionDetails = connector(
  withRouter(DisruptionProtectionDetails)
);
