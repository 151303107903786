import React from "react";
import clsx from "clsx";
import { IMobile } from "../types";
import { Box, Typography } from "@material-ui/core";
import { ActionButton, BackButton, CloseButtonIcon, DefaultStyle, MobilePopoverCard } from "halifax";
import { RemindersSection } from "../components/RemindersSection";
import { BaggageSection } from "../components/BaggageSection";
import * as textConstants from "../textConstants"
import "./styles.scss";
import { MultipleAirlinesFareDetails } from "../../MultipleAirlinesFareModal";

export const MobileSelfTransferReminder = ({
  isOpen,
  onClose,
  slices,
  airports,
  setVariant,
  selectedTripLeg,
  setSelectedTripLeg,
  buttons,
  modalStep,
  setModalStep,
  onClickContinue,
  fareDetails,
  tripDetails
}: IMobile) => {
  const isReminderStep = modalStep === "reminder";
  return (
    <MobilePopoverCard
      className="mobile-self-transfer-reminder-modal-root b2b-secondary"
      contentClassName="mobile-self-transfer-reminder-modal-root"
      open={isOpen}
      onClose={onClose}
      centered={true}
      topRightButton={
        isReminderStep ? (
          <CloseButtonIcon onClick={onClose} />
        ) : undefined
      }
      topLeftButton={
        !isReminderStep ? (
          <BackButton
            className="flight-offers-popup-back-button"
            onClick={() => setModalStep("reminder")}
          />
        ) : undefined
      }
      headerElement={
        <Typography className="header-title">
          {isReminderStep ? textConstants.REMINDER : textConstants.RESTRICTIONS}
        </Typography>
      }
      bottomButton={
        <Box className={clsx("mobile-wrapper-reminder-buttons", {
          "fixed": !isReminderStep
        })}>
          {isReminderStep ? (
            buttons.map(({
              buttonText,
              buttonWrapperClassName,
              onClick,
              defaultStyle,
            }) => (
              <ActionButton
                className={buttonWrapperClassName}
                buttonClassName={buttonWrapperClassName}
                onClick={onClick}
                message={buttonText}
                defaultStyle={defaultStyle as DefaultStyle}
              />
            ))
          ) : (
            <ActionButton
              buttonClassName={clsx(
                "self-transfer-reminder-modal-button",
                "primary"
              )}
              onClick={() => onClickContinue()}
              message={textConstants.CONTINUE}
              defaultStyle="h4r-primary"
            />
          )}

        </Box>
      }
    >
      {isReminderStep ? (
        <Box className="content-container">
          <RemindersSection
            setVariant={setVariant}
            buttons={[]}
            slices={slices}
          />
          <BaggageSection
            selectedTripLeg={selectedTripLeg}
            setSelectedTripLeg={setSelectedTripLeg}
            slices={slices}
            fareDetails={fareDetails}
            airports={airports}
          />
        </Box>
      ) : (
        <Box className="fare-details-step-mobile">
          <MultipleAirlinesFareDetails airports={airports!} tripDetails={tripDetails} />
        </Box>
      )}
    </MobilePopoverCard>
  );
};
