import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { travelWalletApiPrefix } from "../paths";
import { WalletSummaryResponse } from "redmond";

const END_POINT: string = `${travelWalletApiPrefix}`;

export const fetchTravelWalletSummary = (): Promise<WalletSummaryResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<WalletSummaryResponse> = await axios.get(
        END_POINT,
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
