import axios from "axios";
import { VIEWED_FLIGHT_SHOP_LOADING } from "redmond";

import dayjs from "dayjs";
import { trackEvent } from "../analytics/trackEvent";
import {
  airShoppingApi,
  ShopMulticitySummaryRequest,
  ShopMulticitySummaryResponse,
} from "@b2bportal/air-shopping-api";

export const fetchMulticityTripSummaries = async (
  requestBody: ShopMulticitySummaryRequest
): Promise<ShopMulticitySummaryResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const startTime = dayjs();
      const res = await airShoppingApi(
        axios as any
      ).apiV0ShopMulticitySummaryPost(
        requestBody as ShopMulticitySummaryRequest
      );

      trackEvent({
        eventName: VIEWED_FLIGHT_SHOP_LOADING,
        properties: {
          runtime: dayjs().diff(startTime, "seconds", true),
        },
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
};
