import { convertSecondsToDays } from "../../../utils";

export const CHOOSE_DURATION_TITLE = (step: number) =>
  `Step ${step}: Choose freeze duration`;

export const CHOOSE_DURATION_SUBTITLE = (
  minDurationInSeconds: number | undefined,
  maxDurationInSeconds: number | undefined,
  includeDurations: boolean
): string => {
  // Use 14 as a fallback in case there is any issue retrieving the max duration dynamically
  const minDurationInDays = minDurationInSeconds
  ? convertSecondsToDays(minDurationInSeconds)
  : 0;
  const maxDurationInDays = maxDurationInSeconds
    ? convertSecondsToDays(maxDurationInSeconds)
    : 14;
  const minHoursOrDays = minDurationInDays === 0.5 || 0 ? 12 : minDurationInDays
  const minHoursOrDaysText = minDurationInDays === 0.5 || 0 ? "hours" : "days"
  return (
    "Choose how long you want to freeze the price of this trip" +
    (includeDurations
      ? `, from ${minHoursOrDays} ${minHoursOrDaysText} up to ${maxDurationInDays} ${
          maxDurationInDays === 1 ? "day" : "days"
        }.`
      : ".")
  );
};
  
export const CHOOSE_DURATION_FOOTER = (isRoundTrip: boolean) =>
  `*Prices are per traveler, ${isRoundTrip ? "round-trip" : "one-way"}`;
