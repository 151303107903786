import { FiatPrice } from "redmond";
import { getTotalPriceText, twoDecimalFormatter } from "halifax";

export const REFUND_MODAL_TITLE =
  "Would you like to cancel and get a refund for your frozen price?";
export const REFUND_MODAL_SUB = (
  amount: FiatPrice | undefined,
  travelers: number
) =>
  `Your flight will no longer be frozen. You will be refunded the amount you paid of <span>${
    amount
      ? getTotalPriceText({
          price: amount,
          priceFormatter: twoDecimalFormatter,
        })
      : ""
  } for ${travelers} ${
    travelers > 1 ? "travelers" : "traveler"
  }.</span> This refund will appear on your original method of payment.`;
export const YES_GET_A_REFUND = "Yes, Get a Refund";
export const LOADING_TEXT = "Refunding your frozen price...";
export const REFUND_SUCCESS = "Your refund is on its way!";
export const DONE = "Done";
export const REFUND_SUCCESS_SUB =
  "You’ll receive a refund to your original payment method within the next 1-2 billing cycles, but in some cases it may take longer. A confirmation email will be sent to you regarding this refund.";
export const ERROR_REFUND_TITLE = "Something went wrong.";
export const ERROR_REFUND_SUBTITLE =
  "We were unable to process your refund, please contact support for further assistance with your refund or try again.";
export const CONTACT_SUPPORT = "Contact Support";
export const TRY_AGAIN = "Try Again";
