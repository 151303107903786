import { getTotalPriceText, getRewardText } from "halifax";
import { RewardsPrice, FiatPrice, RewardsAccount } from "redmond";
import { DO_NOT_APPLY_REWARDS_KEY } from "../../../book/reducer";
export const CURRENT_PRICE_FROZEN = (useLockPriceLanguage?: boolean) =>
  `Current price being ${useLockPriceLanguage ? "locked" : "frozen"}:`;
export const FROZEN_DURATION = (useLockPriceLanguage?: boolean) =>
  `${useLockPriceLanguage ? "Locked" : "Frozen"} price duration:`;
export const FEE_TRAVELER = "Fee per traveler:";

export const PRICE_FREEZE_EXPLANATION_TEXTS = (
  cap: string,
  useLockPriceLanguage?: boolean
) => {
  return {
    increaseText: `<strong>If the price goes up after you ${
      useLockPriceLanguage ? "lock it" : "freeze"
    },</strong> we’ll cover the difference up to ${cap} per traveler when you book with us.`,
    decreaseText: `<strong>If the price goes down after you ${
      useLockPriceLanguage ? "lock it" : "freeze"
    },</strong> you pay the lower price when you book with us!`,
    similarFlightText:
      "<strong>If there aren’t enough available seats when you try to book</strong>, you can apply your freeze to a similar flight if available, or get a refund for your price freeze fee.",
  };
};

export const READ_TERMS = "Read terms and conditions";

export const FREEZE_REWARDS = (
  priceFreezeOfferRewards: { [key: string]: RewardsPrice } | undefined,
  rewardsKey: string | undefined | null,
  largestRewardsAccount: RewardsAccount | undefined
) => {
  let reward: RewardsPrice | undefined;
  if (priceFreezeOfferRewards) {
    if (rewardsKey && rewardsKey !== DO_NOT_APPLY_REWARDS_KEY) {
      reward = priceFreezeOfferRewards[rewardsKey];
    } else if (largestRewardsAccount) {
      reward =
        priceFreezeOfferRewards[largestRewardsAccount.accountReferenceId];
    }
  }
  return reward ? `or ${getRewardText({ reward })}` : "";
};

export const SINGLE_PASSENGER_PRICES_TEXT = (
  fiat: FiatPrice,
  rewards: { [key: string]: RewardsPrice } | undefined,
  rewardsKey: string | undefined | null,
  largestRewardsAccount: RewardsAccount | undefined
) => {
  let reward: RewardsPrice | undefined;
  if (rewards) {
    if (rewardsKey && rewardsKey !== DO_NOT_APPLY_REWARDS_KEY) {
      reward = rewards[rewardsKey];
    } else if (largestRewardsAccount) {
      reward = rewards[largestRewardsAccount.accountReferenceId];
    }
  }
  return `<strong>${getTotalPriceText({
    price: fiat,
  })}</strong>${reward ? ` or ${getRewardText({ reward })}` : ""}`;
};

export const EDIT_DURATION = "Edit duration";
export const DURATION_VOID_WINDOW_TEXT =
  "If you choose to book this flight instead of freezing the price, you can cancel within 24 hours of booking for a full refund of the flight fare (per the airline's policy). Refunds usually take 1-2 billing cycles, but sometimes longer.";
export const PRICE_FREEZE_DURATION_12_HOURS_IN_SECONDS = 43200;
export const PRICE_FREEZE_DURATION_24_HOURS_IN_SECONDS = 86400;

export const PRICE_FREEZE_HEADER = "Price Freeze";
