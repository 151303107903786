import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IFareclassOptionDetailsProps {
  className?: string;
}

export const FareclassOptionDetails = ({
  className,
}: IFareclassOptionDetailsProps) => {
  return (
    <Box className={clsx(className, "fareclass-option-details-root")}>
      <Box className="fareclass-option-details-container">
        <Box className="fareclass-options-container">
          {renderFareclassOptions()}
        </Box>
      </Box>
    </Box>
  );
};

interface IFareclassOption {
  label: string;
  details: string;
}

const fareclassOptions: IFareclassOption[] = [
  {
    label: textConstants.BASIC_TEXT,
    details: textConstants.BASIC_DETAILS_TEXT,
  },
  {
    label: textConstants.STANDARD_TEXT,
    details: textConstants.STANDARD_DETAILS_TEXT,
  },
  {
    label: textConstants.ENHANCED_TEXT,
    details: textConstants.ENHANCED_DETAILS_TEXT,
  },
  {
    label: textConstants.PREMIUM_TEXT,
    details: textConstants.PREMIUM_DETAILS_TEXT,
  },
  {
    label: textConstants.LUXURY_TEXT,
    details: textConstants.LUXURY_DETAILS_TEXT,
  },
];

const renderFareclassOptions = () =>
  fareclassOptions.map(({ label, details }) => (
    <Box key={label} className="fareclass-option-container">
      <Box className="fareclass-option-name">{label}</Box>
      <Box className="fareclass-option-description">{details}</Box>
    </Box>
  ));
