import React, { useContext, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import {
  PriceWatchOptIn,
  IPriceWatchOptInState,
  IPriceWatchOptInFailureReason,
  B2BMobileWatchImage,
  B2BWatchImage,
} from "halifax";
import {
  CallState,
  ShopFilter,
  SliceStopCountFilter,
  PushNotificationFilter,
  VIEWED_WATCH,
  ViewedWatchProperties,
  MODAL_ALERT,
  ModalScreens,
  SELECTED_WATCH_PUSH,
  SelectedWatchPushProperties,
} from "redmond";
import {
  getPriceWatchTitles,
  READ_TERMS_CONDITION_TEXT,
} from "../../constants";
import { ClientContext } from "../../../../App";
import { PATH_PRICE_PREDICTION_PRICE_WATCH } from "../../../../utils/urlPaths";
import { generateFilterTrackingEvent } from "../../../search/actions/actions";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  EXCLUDE_BASIC_FARES_COPY,
  NONSTOP_FLIGHTS_ONLY_COPY,
  EXCLUDE_BASIC_FARES_ID,
  NONSTOP_FLIGHTS_ONLY_ID,
  INCLUDE_EMAIL_COPY,
  INCLUDE_APP_NOTIFICATIONS_COPY,
  INCLUDE_EMAIL_ID,
  INCLUDE_APP_NOTIFICATIONS_ID,
  OPT_IN_BANNER_COPY,
} from "./constants";
import { PriceWatchOptInCardConnectorProps } from "./container";

export interface IPriceWatchOptInCardProps
  extends PriceWatchOptInCardConnectorProps,
    RouteComponentProps {
  setJustWatched?: (justWatched: boolean) => void;
  setWatchModalOpen: (watchModalOpen: boolean) => void;
  isMobile: boolean;
}

export const PriceWatchOptInCard = (props: IPriceWatchOptInCardProps) => {
  const {
    listWatchCallState,
    createWatchCallState,
    createWatchFailureCodes,
    isWatching,
    prediction,
    areBasicFaresFilteredOut,
    stopsOptionFilter,
    isPriceWatchPushNotificationEnabled,
    isPriceWatchPushNotificationDefaultOptInEnabled,
    setJustWatched,
    setWatchModalOpen,
    createWatch,
    setCreateWatchCallState,
    isMobile,
  } = props;
  const isInitialAppNotificationOptionSelected: boolean =
    isPriceWatchPushNotificationDefaultOptInEnabled;
  // We initialize banner text as populated iff we initialize the App Notificiations option as selected.
  const [bannerText, setBannerText] = useState<string | undefined>(
    isInitialAppNotificationOptionSelected ? OPT_IN_BANNER_COPY : undefined
  );
  const { sessionInfo } = useContext(ClientContext);
  const userEmail = sessionInfo?.userInfo?.email || "";

  const initialShopOptions = {
    [EXCLUDE_BASIC_FARES_ID]: {
      label: EXCLUDE_BASIC_FARES_COPY,
      isSelected: areBasicFaresFilteredOut,
      onClickCheckbox: (checked: boolean) =>
        trackEvent(
          generateFilterTrackingEvent(
            checked ? "exclude_LCC" : "include_LCC",
            "list"
          )
        ),
    },
    [NONSTOP_FLIGHTS_ONLY_ID]: {
      label: NONSTOP_FLIGHTS_ONLY_COPY,
      isSelected: stopsOptionFilter === SliceStopCountFilter.NONE,
      onClickCheckbox: (checked: boolean) =>
        trackEvent(
          generateFilterTrackingEvent(
            checked ? "stops_watch" : "any_stops_watch",
            "list"
          )
        ),
    },
  };

  const getShopFilterFromOptions = (options: {
    // it returns true iff the option is selected
    [optionId in string]: boolean;
  }) => {
    const filterOutBasicFares = options[EXCLUDE_BASIC_FARES_ID];
    const nonstopFlightsOnly = options[NONSTOP_FLIGHTS_ONLY_ID];

    if (filterOutBasicFares && nonstopFlightsOnly) {
      return ShopFilter.NonStopNoLCC;
    } else if (filterOutBasicFares) {
      return ShopFilter.NoLCC;
    } else if (nonstopFlightsOnly) {
      return ShopFilter.NonStop;
    }

    return ShopFilter.NoFilter;
  };

  const initialPushOptions = {
    [INCLUDE_EMAIL_ID]: {
      label: INCLUDE_EMAIL_COPY,
      isSelected: true,
      isDisabled: true,
    },
    [INCLUDE_APP_NOTIFICATIONS_ID]: {
      label: INCLUDE_APP_NOTIFICATIONS_COPY,
      isSelected: isInitialAppNotificationOptionSelected,
      onClickCheckbox: (checked: boolean) => {
        setBannerText(checked ? OPT_IN_BANNER_COPY : undefined);
        trackEvent({
          eventName: SELECTED_WATCH_PUSH,
          properties: {
            action: checked ? "opt-in" : "opt-out",
          } as SelectedWatchPushProperties,
        });
      },
    },
  };

  const getPushNotificationFilterFromOptions = (options: {
    // it returns true iff the option is selected
    [optionId in string]: boolean;
  }) => {
    const enableAppNotifications = options[INCLUDE_APP_NOTIFICATIONS_ID];
    return enableAppNotifications
      ? PushNotificationFilter.AppNotifications
      : PushNotificationFilter.EmailOnly;
  };

  const getOptInState = () => {
    if (
      createWatchCallState === CallState.InProcess ||
      listWatchCallState === CallState.InProcess
    ) {
      return IPriceWatchOptInState.InProcess;
    } else if (isWatching) {
      return IPriceWatchOptInState.Watching;
    } else if (createWatchCallState === CallState.Failed) {
      return IPriceWatchOptInState.Failure;
    } else {
      return IPriceWatchOptInState.NotWatching;
    }
  };

  const getOptInFailureReason = () => {
    if (getOptInState() === IPriceWatchOptInState.Failure) {
      if (
        createWatchFailureCodes?.length === 1 &&
        createWatchFailureCodes?.find(
          (errorCode) => errorCode.code === "NoRecentPrice"
        )
      ) {
        return IPriceWatchOptInFailureReason.NoRecentPrice;
      }

      return IPriceWatchOptInFailureReason.General;
    }

    return undefined;
  };

  const onWatchClick = (
    email: string,
    shopFilter?: ShopFilter,
    pushNotificationFilter?: PushNotificationFilter
  ) => {
    createWatch({ email, shopFilter, pushNotificationFilter, isMobile });
    if (setJustWatched) {
      setJustWatched(true);
    }
  };

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_WATCH,
      properties: {
        push_eligibile: isPriceWatchPushNotificationEnabled,
      } as ViewedWatchProperties,
    });
  }, []);

  const onFiltersTooRestrictiveCase = () => {
    trackEvent({
      eventName: MODAL_ALERT,
      properties: {
        type: "watch_filter_error",
        screen: ModalScreens.ADD_WATCH_FLIGHT_LIST,
      },
    });
  };

  return (
    <PriceWatchOptIn
      className="price-watch-opt-in-card-root"
      titles={getPriceWatchTitles(prediction?.predictionCopy)}
      optInState={getOptInState()}
      failureReason={getOptInFailureReason()}
      onClick={onWatchClick}
      onGoBack={() => {
        setCreateWatchCallState(CallState.NotCalled);
      }}
      onFiltersTooRestrictiveCase={onFiltersTooRestrictiveCase}
      userEmail={userEmail}
      buttonClassName={"b2b"}
      ctaText={READ_TERMS_CONDITION_TEXT}
      onClose={() => {
        setWatchModalOpen(false);
        setCreateWatchCallState(CallState.NotCalled);
      }}
      image={!isMobile ? B2BWatchImage : B2BMobileWatchImage}
      onTermsConditionClick={
        !isMobile
          ? () => window.open(`${PATH_PRICE_PREDICTION_PRICE_WATCH} `, "_blank")
          : undefined
      }
      shopFilterProps={{
        initialOptions: initialShopOptions,
        getShopFilterFromOptions,
      }}
      pushNotificationFilterProps={
        isPriceWatchPushNotificationEnabled
          ? {
              initialOptions: initialPushOptions,
              getPushNotificationFilterFromOptions,
            }
          : undefined
      }
      notificationBannerText={bannerText}
      isMobile={isMobile}
    />
  );
};
