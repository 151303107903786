import React from "react";
import {
  RewardsAccountSelection as AccountSelection,
  useDeviceTypes,
} from "halifax";
import { RewardsAccountSelectionConnectorProps } from "./container";
import { INELIGIBLE_REWARDS_REDEMPTION_NOTICE } from "./constants";

export interface IRewardsAccountSelectionProps
  extends RewardsAccountSelectionConnectorProps {
  className?: string;
  popoverClassName?: string;
}

export const RewardsAccountSelection = (
  props: IRewardsAccountSelectionProps
) => {
  const {
    className,
    rewardsAccounts,
    selectedAccountReferenceId,
    fetchRewardsAccounts,
    setSelectedAccountReferenceId,
    popoverClassName,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  React.useEffect(() => {
    fetchRewardsAccounts();
    let accountId = localStorage.getItem("referenceId");
    if (accountId !== selectedAccountReferenceId) {
      setSelectedAccountReferenceId(accountId);
    }
  }, []);

  const onSelectedRewardsAccount = (accountId: string | null) => {
    localStorage.setItem("referenceId", accountId ?? "");
  };

  const selectedAccount = React.useMemo(
    () =>
      rewardsAccounts.find(
        (account) => account.accountReferenceId === selectedAccountReferenceId
      ),
    [rewardsAccounts, selectedAccountReferenceId]
  );

  return (
    <AccountSelection
      className={className}
      selectedAccountReferenceId={selectedAccountReferenceId || null}
      setSelectedAccountReferenceId={(accountId) => {
        setSelectedAccountReferenceId(accountId);
        onSelectedRewardsAccount(accountId);
      }}
      rewardsAccounts={rewardsAccounts}
      isMobile={matchesMobile}
      popoverClassName={popoverClassName}
      texts={{
        hiddenRewardsText: INELIGIBLE_REWARDS_REDEMPTION_NOTICE(
          selectedAccount?.customerAccountRole
        ),
      }}
    />
  );
};
