import { put } from "redux-saga/effects";
import { CallState } from "redmond";

import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { refundPriceFreezeApi } from "../../../api/v0/price-freeze/priceFreezeRefund";

export function* refundPriceFreezeSaga(action: actions.IRefundPriceFreeze) {
  try {
    yield refundPriceFreezeApi({
      id: action.frozenPriceId,
    });

    //TODO: check for error states when https://hopper-jira.atlassian.net/browse/CFTC-31
    yield put(
      actions.setRefundPriceFreeze({
        refundPriceFreezeCallState: CallState.Success,
      })
    );
  } catch (e) {
    yield put(
      actions.setRefundPriceFreeze({
        refundPriceFreezeCallState: CallState.Failed,
      })
    );
    Logger.debug(e);
  }
}
