import axios from "axios";
import {
  ShopFilter,
  ShopSummaryRequest,
  ShopSummaryResponse,
  SliceStopCountFilter,
  VIEWED_FLIGHT_SHOP_LOADING,
  Platform,
} from "redmond";
import { IShopParams } from "../../../modules/shop/actions/actions";

import dayjs from "dayjs";
import { trackEvent } from "../analytics/trackEvent";
import { airShoppingApi } from "@b2bportal/air-shopping-api";

export const fetchTripSummaries = async (
  shopParams: IShopParams,
  isMobile: boolean | undefined
): Promise<ShopSummaryResponse> => {
  return new Promise(async (resolve, reject) => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter,
    } = shopParams;
    const passengerObj = {};

    if (adultsCount > 0) passengerObj["ADT"] = adultsCount;
    if (infantsInSeatCount > 0) passengerObj["INS"] = infantsInSeatCount;
    if (infantsOnLapCount > 0) passengerObj["INF"] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj["CNN"] = childrenCount;

    let tripFilter = ShopFilter.NoFilter;
    const filterOutBasicFares =
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard;
    if (fareclassOptionFilter && filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }
    const request: ShopSummaryRequest = {
      passengers: passengerObj,
      departureDate,
      ...(returnDate && { returnDate }),
      route: {
        origin,
        destination,
      },
      tripFilter,
      platform: isMobile ? Platform.Mobile : Platform.Desktop,
    };
    try {
      const startTime = dayjs();
      const res = await airShoppingApi(axios as any).apiV0ShopSummaryPost(
        request as any
      );
      trackEvent({
        eventName: VIEWED_FLIGHT_SHOP_LOADING,
        properties: {
          runtime: dayjs().diff(startTime, "seconds", true),
        },
      });
      resolve(res.data as any);
    } catch (e) {
      reject(e);
    }
  });
};
