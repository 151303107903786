import { connect, ConnectedProps } from "react-redux";
import { AirEntryProperties, ITripTerminus } from "redmond";
import { IStoreState } from "../../../../../../reducers/types";
import {
  ISetMulticityOrigin,
  ISetMulticityDestination,
  ISetMulticityDepartureDate,
  IAddMulticityRoute,
  IRemoveMulticityRoute,
  setMulticityOrigin,
  setMulticityDestination,
  setMulticityDepartureDate,
  addMulticityRoute,
  removeMulticityRoute,
} from "../../../../actions/actions";

import { MulticityRoute } from "../../../../reducer";
import { MulticityEditSearchControl } from "./component";
import { Dispatch } from "@reduxjs/toolkit";
import { withRouter } from "react-router";
import { getAirEntryProperties } from "../../../../../shop/reducer";
interface IDispatchProps {
  setMulticityOrigin: (
    origin: ITripTerminus | null,
    index: number
  ) => ISetMulticityOrigin;
  setMulticityDestination: (
    destination: ITripTerminus | null,
    index: number
  ) => ISetMulticityDestination;
  setMulticityDepartureDate: (
    date: Date | null,
    index: number
  ) => ISetMulticityDepartureDate;
  addMulticityRoute: () => IAddMulticityRoute;
  removeMulticityRoute: (index: number) => IRemoveMulticityRoute;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setMulticityOrigin: (origin: ITripTerminus | null, index: number) =>
    dispatch(setMulticityOrigin(origin, index)),
  setMulticityDestination: (destination: ITripTerminus | null, index: number) =>
    dispatch(setMulticityDestination(destination, index)),
  setMulticityDepartureDate: (date: Date | null, index: number) =>
    dispatch(setMulticityDepartureDate(date, index)),
  addMulticityRoute: () => dispatch(addMulticityRoute()),
  removeMulticityRoute: (index: number) =>
    dispatch(removeMulticityRoute(index)),
});

interface IStateProps {
  airEntryProperties: AirEntryProperties;
}
const mapStateToProps = (state: IStoreState) => {
  return {
    airEntryProperties: getAirEntryProperties(state),
  };
};

interface IOwnProps {
  setEditMulticitySearchModalOpen: () => void;
  multicityRoutes: MulticityRoute[];
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export type MulticityEditSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMulticityEditSearchControl = connector(
  withRouter(MulticityEditSearchControl)
);
