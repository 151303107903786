import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getTripCategory,
  getDepartureDate,
  getReturnDate,
} from "../../../search/reducer";
import {
  selectedTripSelector,
  tripDetailsLoadingSelector,
  tripDetailsSelector,
  airportsSelector,
  isOutgoingMultiTicketSelector,
  isReturnMultiTicketSelector,
  cfarOfferSelector,
  currentCfarChangePolicySelector,
  getOffersByTripId,
  selectedFareDetailsSelector,
  perPaxRefundableFarePricesSelector,
} from "../../reducer/selectors";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { MobileFareDetails } from "./component";
import { IStoreState } from "../../../../reducers/types";

import { populateFlightBookQueryParams } from "../../../book/actions/actions";
import {
  setFlightShopProgress,
  setMulticityFlightShopProgress,
} from "../../actions/actions";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import { hasTravelFusionInSelectedTripDetailsSelector } from "../../../book/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const airports = airportsSelector(state, tripId);

  return {
    tripCategory: getTripCategory(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    tripDetails,
    airports,
    fareDetails: selectedFareDetailsSelector(state),
    perPaxRefundableFarePrices: perPaxRefundableFarePricesSelector(state),
    tripDetailsLoading: tripDetailsLoadingSelector(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    isMultiTicket:
      isOutgoingMultiTicketSelector(state) ||
      isReturnMultiTicketSelector(state),
    cfarOffer: cfarOfferSelector(state),
    changePolicy: currentCfarChangePolicySelector(state),
    offersByTripId: getOffersByTripId(state),
    credit: getTravelWalletCredit(state),
    hasTravelFusionFareBrand:
      hasTravelFusionInSelectedTripDetailsSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setFlightShopProgress,
  setMulticityFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFareDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileFareDetails = connector(
  withRouter(MobileFareDetails)
);
