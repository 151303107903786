import { put } from "redux-saga/effects";
import { WalletSummaryResponse } from "redmond";
import { actions } from "../actions";
import { fetchTravelWalletSummary } from "../../../api/v0/travel-wallet/fetchTravelWalletSummary";

export function* fetchApplicablePriceFreezeTravelWalletItemsSaga(
  _: actions.IFetchApplicableTravelWalletItems
) {
  try {
    const summaryResponse: WalletSummaryResponse =
      yield fetchTravelWalletSummary();

    yield put(
      actions.setTravelWalletCredit(summaryResponse.credit)
    );
    yield put(actions.setFetchApplicableTravelWalletItemsCallStateSuccess());
  } catch (e) {
    yield put(actions.setFetchApplicableTravelWalletItemsCallStateFailure());
  }
}