import React from "react";
import { Box, Typography } from "@material-ui/core";
import * as constants from "./constants";
import "./styles.scss";
import { Dayjs } from "dayjs";

export interface IRebookHeaderProps {
  isSelectingReturnFlightForRebook: boolean;
  departureDate: Dayjs | null;
}

export const RebookHeader = (props: IRebookHeaderProps) => {
  const { isSelectingReturnFlightForRebook, departureDate } = props;

  return (
    <Box className="rebook-header-root">
      <Box className="rebook-header-container">
        <Typography
          variant="h2"
          className="title-copy"
          dangerouslySetInnerHTML={{
            __html: constants.REBOOK_HEADER_TITLE(
              isSelectingReturnFlightForRebook,
              departureDate
            ),
          }}
        />
        <Typography variant="subtitle2" className="subtitle-copy">
          {constants.REBOOK_HEADER_SUBTITLE}
        </Typography>
      </Box>
    </Box>
  );
};
