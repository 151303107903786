export const SUCCESS_SUBTEXT =
  "Visit dashboard to verify that the Booking Fee was charged correctly";
export const SUCCESS_TITLE = "$25 Booking Fee Successfully Charged";

export const FAILURE_SUBTEXT =
  "You'll need to create a Custom Charge in Dashboard to charge this fee";
export const FAILURE_TITLE = "$25 Booking Fee Failed to Charge";
export const FAILURE_DESCRIPTION =
  "Please head over to the dashboard to charge the user";

export const getDashboardUrl = (locatorCode?: string) => {
  const BASE_URL = window.__mclean_env__
    ? window.__mclean_env__.REACT_APP_DASHBOARD_URL
    : "";
  if (!BASE_URL || !locatorCode) return "";

  return `${BASE_URL}/#/locator/${locatorCode}`;
};
