import {
  AirlineCode,
  Flights,
  isSouthwestAirlines,
  ITimeRange,
  Prices,
  Slice,
  SliceStopCountFilter,
} from "redmond";
import dayjs from "dayjs";

import { TripSlice, TripSummary, FlightRatingsEnum } from "redmond";
import {
  IFlightNumberFilter,
  initialFilterOptions,
} from "../../../search/reducer";
import { IFlightListData } from "../../v2/components/FlightList/component";
import { removeTimezone } from "halifax";
import { MulticityFlights, MulticitySlice } from "@b2bportal/air-shopping-api";

export const performStopOptionFilter = (
  flight: TripSlice,
  stopsOption: SliceStopCountFilter
): boolean => {
  switch (stopsOption) {
    case SliceStopCountFilter.ONE_OR_LESS:
      return flight.stops <= 1;
    case SliceStopCountFilter.TWO_OR_LESS:
      return flight.stops <= 2;
    case SliceStopCountFilter.NONE:
      return flight.stops === 0;
    default:
      return true;
  }
};

export const performMaxPriceFilter = (
  trip: TripSummary,
  maxPriceFilter: number
) => {
  if (maxPriceFilter === initialFilterOptions.maxPriceFilter) return true;

  return trip.tripFares.some(
    (fare) => fare.amount.fiat.value <= maxPriceFilter
  );
};

export const performTimeRangeFilter = (
  flight: TripSlice,
  departureTime: ITimeRange,
  arrivalTime: ITimeRange
) => {
  const flightDeparture = dayjs(removeTimezone(flight.departureTime));
  const flightArrival = dayjs(removeTimezone(flight.arrivalTime));

  const flightDepartureMinutes =
    flightDeparture.hour() * 60 + flightDeparture.minute();
  const flightArrivalMinutes =
    flightArrival.hour() * 60 + flightArrival.minute();

  const validDeparture =
    departureTime.max >= flightDepartureMinutes &&
    flightDepartureMinutes >= departureTime.min;
  const validArrival =
    arrivalTime.max >= flightArrivalMinutes &&
    flightArrivalMinutes >= arrivalTime.min;

  return validDeparture && validArrival;
};

export const performAirlineFilter = (
  flight: TripSlice,
  airlineFilter: AirlineCode[]
) => {
  return airlineFilter.includes(flight.segmentDetails[0].airlineCode);
};

export const performAirportFilter = (
  slice: TripSlice,
  airportFilter: string[]
) => {
  return airportFilter.includes(slice.originCode);
};

export const performPolicyFilter = (
  slice: TripSlice,
  policyFilter: boolean
) => {
  // TODO(replat)
  if (policyFilter) {
    return slice ? true : false;
  }

  return true;
};

export const performFlightNumberFilter = (
  flight: TripSlice,
  flightNumberFilter: IFlightNumberFilter[]
) => {
  return flightNumberFilter.some((filter) => {
    const [firstSegment] = flight.segmentDetails;
    return (
      firstSegment.airlineCode === filter.airlineCode &&
      firstSegment.flightNumber === filter.flightNumber
    );
  });
};

export const performFareClassFilter = (
  flight: TripSummary,
  fareClassOptionFilter: string[],
  isReturn: boolean,
  outgoingRating?: number
) => {
  const getAllFlightRating = () => {
    return flight.tripFares.reduce<Record<string, boolean>>(
      (flightRating, fare) => {
        //Only add return rating if outgoing flight rating matches in the fare.
        const rating =
          isReturn &&
          (outgoingRating === undefined ||
            outgoingRating === fare.fareShelf?.outgoing.rating)
            ? fare.fareShelf?.returning?.rating
            : !isReturn
            ? fare.fareShelf?.outgoing.rating
            : null;

        //zero should not be considered as false
        if (rating || rating === 0) flightRating[rating] = true;
        return flightRating;
      },
      {}
    );
  };

  // If the flight has *any* of the users selected fare classes, return true.
  return fareClassOptionFilter.some((fare) => {
    return getAllFlightRating()[FlightRatingsEnum[fare.toLocaleLowerCase()]];
  });
};

///
export const performStopOptionFilterV2 = (
  flight: Slice | MulticitySlice,
  stopsOption: SliceStopCountFilter
): boolean => {
  switch (stopsOption) {
    case SliceStopCountFilter.ONE_OR_LESS:
      return flight.stops <= 1;
    case SliceStopCountFilter.TWO_OR_LESS:
      return flight.stops <= 2;
    case SliceStopCountFilter.NONE:
      return flight.stops === 0;
    default:
      return true;
  }
};

export const performMaxPriceFilterV2 = (
  fares: { amount?: Prices }[],
  maxPriceFilter: number
) => {
  if (maxPriceFilter === initialFilterOptions.maxPriceFilter) return true;

  return fares.some((fare) => (fare.amount?.fiat.value || 0) <= maxPriceFilter);
};

export const performTimeRangeFilterV2 = (
  flight: Slice | MulticitySlice,
  departureTime: ITimeRange,
  arrivalTime: ITimeRange
) => {
  const flightDeparture = dayjs(removeTimezone(flight.departure));
  const flightArrival = dayjs(removeTimezone(flight.arrival));

  const flightDepartureMinutes =
    flightDeparture.hour() * 60 + flightDeparture.minute();
  const flightArrivalMinutes =
    flightArrival.hour() * 60 + flightArrival.minute();

  const validDeparture =
    departureTime.max >= flightDepartureMinutes &&
    flightDepartureMinutes >= departureTime.min;
  const validArrival =
    arrivalTime.max >= flightArrivalMinutes &&
    flightArrivalMinutes >= arrivalTime.min;

  return validDeparture && validArrival;
};

export const performAirlineFilterV2 = (
  flight: Slice | MulticitySlice,
  airlineFilter: AirlineCode[]
) => {
  return airlineFilter.includes(flight.segments[0].marketingAirline);
};

export const doesFlightHaveFilteredAirlines = (
  flight: Slice | MulticitySlice,
  airlineFilter: AirlineCode[]
) => {
  const flightAirlines = flight.segments
    .flatMap((segment) => [segment.marketingAirline, segment.operatingAirline])
    .concat(flight.marketingAirline);
  return flightAirlines.some((airline) => airlineFilter.includes(airline));
};

export const performIsSouthwestAirlines = (flight: Slice) => {
  return isSouthwestAirlines(flight.marketingAirline);
};

export const performAirportFilterV2 = (
  slice: Slice | MulticitySlice,
  airportFilter: string[]
) => {
  return airportFilter.includes(slice.origin);
};

export const performPolicyFilterV2 = (
  fares: any,
  policyFilter: boolean
): boolean => {
  if (policyFilter) {
    return fares.some(
      (fare: any) => fare.corporateTravel.policyCompliance.isInPolicy
    );
  }
  return true;
};

export const performFlightNumberFilterV2 = (
  flight: Slice | MulticitySlice,
  flightNumberFilter: IFlightNumberFilter[]
) => {
  return flightNumberFilter.some((filter) => {
    const [firstSegment] = flight.segments;
    return (
      firstSegment.marketingAirline === filter.airlineCode &&
      firstSegment.flightNumber === filter.flightNumber
    );
  });
};

export const performFareClassFilterV2 = (
  flights: Flights | MulticityFlights,
  flight: IFlightListData,
  fareClassOptionFilter: string[]
) => {
  const getAllFlightRating = () => {
    const getFareSliceId = (fare: any) => fare?.fareSlice || fare.return;
    return flight.fares.reduce((flightRating: any, fare: any) => {
      const rating = flights.fareSlices[getFareSliceId(fare)].fareShelf.value;
      if (rating || rating === 0) flightRating[rating] = true;
      return flightRating;
    }, {});
  };

  // If the flight has *any* of the users selected fare classes, return true.
  return fareClassOptionFilter.some((fare) => {
    return getAllFlightRating()[FlightRatingsEnum[fare.toLocaleLowerCase()]];
  });
};

export const performBaggageTransfersFilter = (
  flight: IFlightListData,
  slices: Record<string, Slice>
) => {
  return !slices[flight.slice].hasSelfTransferLayover;
};

export const performDurationFilter = (
  slice: Slice | MulticitySlice,
  durationFilter: number
) => {
  if (!slice.totalDurationMinutes) return false;
  return slice.totalDurationMinutes < durationFilter;
};
