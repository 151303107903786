import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  RewardsAccount,
  ConvertRewardsToUsdSuccess,
  RewardsAccountMinimumRequirementState,
  FiatPrice,
  RewardsPrice,
  TravelWalletOffer,
  TravelWalletCredit,
  AccountPrice,
  CreditCard,
} from "redmond";
import {
  RewardsCheckoutWorkflowRow,
  RewardsCheckoutWorkflow,
  SplitPay,
  useDeviceTypes,
} from "halifax";
import * as textConstants from "./textConstants";
import { convertRewardsAmountToUsd } from "../../../api/v0/rewards/convertRewardsAmountToUsd";
import { DO_NOT_APPLY_REWARDS_KEY } from "../../book/reducer";
import "./styles.scss";
import { convertUsdToRewards } from "../../../api/v0/rewards/convertUsdToRewards";
import {
  useExperiments,
  getExperimentVariant,
  CREDIT_OFFER_STACKING_V1,
  AVAILABLE,
} from "../../../context/experiments";
import { Prices } from "@b2bportal/air-shopping-api";
import { RewardDisplayType } from "../type";

export interface RewardsSelectionProps extends RouteComponentProps {
  disabled?: boolean;
  rewardsTitleId: string;
  rewardsSubtitleId: string;
  rewardsAccounts: RewardsAccount[];
  selectedRewardsPaymentAccountId: string | null | undefined;
  rewardsPaymentInFiatCurrency: FiatPrice | null;
  rewardsPaymentInRewardsCurrency: RewardsPrice | null;
  isCreditCardPaymentRequired: boolean;
  rewardsAccountMinimumRequirementState: RewardsAccountMinimumRequirementState;
  totalCreditCardPaymentRequiredNumber: number | null;
  total: Prices;
  offerToApply: TravelWalletOffer | undefined;
  creditToApply: TravelWalletCredit | undefined;
  setSelectedRewardsAccountId: (
    rewardsAccountReferenceId: string | null
  ) => any;
  setRewardsPaymentAmount: (
    accountReferenceId: string,
    rewardsPaymentTotal: AccountPrice | null,
    rewardsPaymentInFiatCurrency: FiatPrice | null,
    total?: Prices | undefined
  ) => any;
  fetchProductToEarn: () => any;
  setRewardsConversionFailed: () => any;
  setPaymentMethod: (args: { paymentMethod?: CreditCard | undefined }) => any;
  setEarnValue: (productEarnValue: number) => any;
  setQuotedRewardsTotal: (quotedRewardsTotal: RewardsPrice | null) => any;
  type?: RewardDisplayType;
}

export const RewardsSelection = (props: RewardsSelectionProps) => {
  const {
    rewardsAccounts,
    selectedRewardsPaymentAccountId,
    rewardsPaymentInFiatCurrency,
    rewardsPaymentInRewardsCurrency,
    isCreditCardPaymentRequired,
    setSelectedRewardsAccountId,
    setRewardsPaymentAmount,
    fetchProductToEarn,
    rewardsAccountMinimumRequirementState,
    disabled,
    total,
    totalCreditCardPaymentRequiredNumber,
    setPaymentMethod,
    setEarnValue,
    rewardsTitleId,
    rewardsSubtitleId,
    setQuotedRewardsTotal,
    setRewardsConversionFailed,
    offerToApply,
    creditToApply,
    type = "flight",
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();
  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  React.useEffect(() => {
    if (!totalCreditCardPaymentRequiredNumber) {
      setPaymentMethod({ paymentMethod: undefined });
      setEarnValue(0);
    } else {
      // only fetch product to earn if credit card payment is required
      fetchProductToEarn();
    }
  }, [totalCreditCardPaymentRequiredNumber, fetchProductToEarn]);

  React.useEffect(() => {
    if (rewardsAccounts.length > 0) {
      const everyAccountIneligible = rewardsAccounts.every(
        (account) => !(account.allowRewardsRedemption ?? true)
      );

      if (everyAccountIneligible) {
        setTimeout(() => {
          handleSwitchAccount(null);
          setSelectedRewardsAccountId(DO_NOT_APPLY_REWARDS_KEY);
        });
      }
    }
  }, [total, rewardsAccounts]);

  React.useEffect(() => {
    if (
      rewardsAccountMinimumRequirementState ===
      RewardsAccountMinimumRequirementState.ALL_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT
    ) {
      setTimeout(() => {
        handleSwitchAccount(null);
        setSelectedRewardsAccountId(DO_NOT_APPLY_REWARDS_KEY);
      });
    }
  }, [rewardsAccountMinimumRequirementState]);

  const handleSetCustomRewardsAmount = async (
    customRewardsValue: number,
    account: RewardsAccount
  ) => {
    try {
      const fiatPrice = await convertRewardsAmountToUsd({
        rewardsAmount: customRewardsValue,
        accountReferenceId: account.accountReferenceId,
      });

      const convertedPrice = (fiatPrice as ConvertRewardsToUsdSuccess).value;

      if (!convertedPrice) {
        throw new Error("Could not fetch split pay conversion.");
      }

      // TODO: seats + flight not calculated correctly for tiered account so call BE for accurate pricing
      if (account.isTiered && total) {
        const rewardsTotal = await convertUsdToRewards({
          amount: total?.fiat.value || 0,
          accountReferenceId: account.accountReferenceId,
        });
        total.accountSpecific[account.accountReferenceId] = rewardsTotal;
        setQuotedRewardsTotal(rewardsTotal);
      } else {
        setQuotedRewardsTotal(null);
      }

      setRewardsPaymentAmount(
        account.accountReferenceId,
        {
          value: customRewardsValue,
          currency: account.rewardsBalance.currency,
        },
        {
          value: convertedPrice,
          currencyCode: account.rewardsCashEquivalent.currencyCode,
          currencySymbol: account.rewardsCashEquivalent.currencySymbol,
        },
        total
      );
    } catch (err) {
      // TODO: Figure out error handling with product, for now, set the rewards to the full account balance if we cannot convert.
      setRewardsConversionFailed();
      setRewardsPaymentAmount(
        account.accountReferenceId,
        account.rewardsBalance,
        account.rewardsCashEquivalent,
        total
      );
    }
  };

  const handleSwitchAccount = async (account: RewardsAccount | null) => {
    if (!account) {
      setRewardsPaymentAmount(DO_NOT_APPLY_REWARDS_KEY, null, null, total);
      return;
    }

    const { rewardsBalance, rewardsCashEquivalent, accountReferenceId } =
      account;

    // If the account is not the same as the existing selected account.
    if (accountReferenceId !== selectedRewardsPaymentAccountId) {
      // TODO: seats + flight not calculated correctly for tiered account so call BE for accurate pricing
      if (account.isTiered && total) {
        const rewardsTotal = await convertUsdToRewards({
          amount: total?.fiat.value || 0,
          accountReferenceId: account.accountReferenceId,
        });
        total.accountSpecific[account.accountReferenceId] = rewardsTotal;
        setQuotedRewardsTotal(rewardsTotal);
      } else {
        setQuotedRewardsTotal(null);
      }
      setRewardsPaymentAmount(
        account.accountReferenceId,
        rewardsBalance,
        rewardsCashEquivalent,
        total
      );
    }
  };

  const renderRewardAccountRow = ({
    account,
    isSelectedAccount,
    isExpanded,
  }: {
    account?: RewardsAccount;
    isSelectedAccount: boolean;
    isExpanded?: boolean;
  }) => {
    const minRewardsAmountNotMatched =
      account && account.redemptionMin?.value > account.rewardsBalance.value;
    const emptyRewards = account && account.rewardsBalance?.value <= 0;

    return (
      <RewardsCheckoutWorkflowRow
        account={account}
        titles={{
          rewardsApplyText: textConstants.SPLIT_PAY_REWARDS_APPLY_TEXT,
          doNotApplyRewardsText: textConstants.SPLIT_PAY_DO_NOT_APPLY_TEXT,
        }}
        aria-expanded={isExpanded}
        setSelectedAccountReferenceId={setSelectedRewardsAccountId}
        doNotApplyRewardsKey={DO_NOT_APPLY_REWARDS_KEY}
        onSwitchAccount={(account) => handleSwitchAccount(account)}
        isSelectedAccount={isSelectedAccount}
        isMobile={matchesMobile}
        disabled={disabled || minRewardsAmountNotMatched || emptyRewards}
      />
    );
  };

  const renderSplitPay = (account: RewardsAccount) => (
    <SplitPay
      account={account}
      splitPayCoversTotal={!isCreditCardPaymentRequired}
      customAmount={rewardsPaymentInRewardsCurrency?.value}
      setCustomAmount={(value) => handleSetCustomRewardsAmount(value, account)}
      rewardsPaymentInFiatCurrency={rewardsPaymentInFiatCurrency}
      titles={{
        applyText: textConstants.SPLIT_PAY_APPLY_TEXT,
        editButtonText: textConstants.SPLIT_PAY_EDIT_TEXT,
        saveButtonText: textConstants.SPLIT_PAY_SAVE_TEXT,
        cancelButtonText: textConstants.SPLIT_PAY_CANCEL_TEXT,
        customAmountTooHighError:
          textConstants.SPLIT_PAY_CUSTOM_AMOUNT_TOO_HIGH,
        customAmountGenericError:
          textConstants.SPLIT_PAY_CUSTOM_AMOUNT_GENERIC_ERROR,
        saveNotification: textConstants.SPLIT_PAY_SAVE_NOTIFICATION,
        coversTotalCostNotification: isCreditAndOfferStackingExperimentV1
          ? textConstants.getSplitPayCoverageNotification(
              !!offerToApply,
              !!creditToApply,
              type
            )
          : textConstants.SPLIT_PAY_COVERS_TOTAL_COST_NOTIFICATION(type),
      }}
      isMobile={matchesMobile}
      disabled={disabled}
    />
  );

  return (
    <>
      <RewardsCheckoutWorkflow
        rewardsAccounts={rewardsAccounts}
        renderRewardAccountRow={renderRewardAccountRow}
        renderSplitPay={renderSplitPay}
        selectedAccountReferenceId={selectedRewardsPaymentAccountId ?? null}
        doNotApplyRewardsKey={DO_NOT_APPLY_REWARDS_KEY}
        isMobile={matchesMobile}
        disabled={disabled}
        shouldDisableAccount={(_r) => false}
        {...{ rewardsTitleId, rewardsSubtitleId }}
      />
    </>
  );
};
