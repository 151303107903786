import axios from "axios";
import { IPerson } from "redmond";

import { airBookingApi, PersonListSuccess } from "@b2bportal/air-booking-api";

export const fetchUserPassengers = (
  body: IListUserPassengersRequest
): Promise<IListUserPassengersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(axios as any).apiV0FlightPassengersListPut(body);

      const responseBody: IPerson[] = (res.data as PersonListSuccess)
        .value as IPerson[];
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IListUserPassengersRequest {}

export interface IListUserPassengersResponse extends Array<IPerson> {}
