import {
  FareclassOptionFilter,
  FareclassShelfBrandName,
  FareclassShelfBrandNameMapping,
} from "redmond";

export function getCheapestSelectedFareclass(
  fareclassOptionFilter: FareclassOptionFilter,
  defaultFareclass: FareclassShelfBrandName
): FareclassShelfBrandName {
  const selectedFareClasses = Object.entries(fareclassOptionFilter)
    .filter(([, isSelected]) => isSelected)
    .map(([fareclass]) => fareclass) as ReadonlyArray<FareclassShelfBrandName>;

  return selectedFareClasses.length
    ? selectedFareClasses.reduce((prev, curr) =>
        FareclassShelfBrandNameMapping[prev] <
        FareclassShelfBrandNameMapping[curr]
          ? prev
          : curr
      )
    : defaultFareclass;
}
