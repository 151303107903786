import { put } from "redux-saga/effects";
import {
  CallState,
  GetEligibilityResponse,
  GetEligibilityRequestEnum,
} from "redmond";

import Logger from "../../../../helpers/Logger";
import { actions } from "../../actions";
import { fetchPriceDropEligibility } from "../../../../api/v0/price-drop/fetchPriceDropEligibility";

export function* fetchPriceDropEligibilitySaga(
  action: actions.IFetchPriceDropEligibility
) {
  try {
    const priceDrop: GetEligibilityResponse = yield fetchPriceDropEligibility({
      GetEligibilityRequest: GetEligibilityRequestEnum.ByCandidate,
      id: action.candidateId,
    });

    yield put(
      actions.setPriceDropProtection({
        priceDropProtection: priceDrop.eligibility,
        fetchPriceDropEligibilityCallState: CallState.Success,
      })
    );
  } catch (e) {
    yield put(
      actions.setPriceDropProtection({
        priceDropProtection: null,
        fetchPriceDropEligibilityCallState: CallState.Failed,
      })
    );
    Logger.debug(e);
  }
}
