import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { PRICE_FREEZE_ID_QUERY_PARAM, TripCategory, CallState } from "redmond";
import { Box, Typography } from "@material-ui/core";
import queryStringParser from "query-string";
import { Header, ActionLink, useDeviceTypes } from "halifax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import {
  AddOnCustomize,
  AddOnHeader,
  MobileAddOnCustomize,
  AddOnCustomizeStepAlias,
} from "../../../shop/components/addOnComponents";
import { DesktopRewardsHeader } from "../../../rewards/components";
import { PATH_PRICE_FREEZE_OVERVIEW } from "../../../../utils/urlPaths";
import { FlightFreezeProgressBar } from "../FlightFreezeProgressBar";
import { FlightFreezeCustomizeConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

export interface IFlightFreezeCustomizeProps
  extends FlightFreezeCustomizeConnectorProps,
    RouteComponentProps {}

export const FlightFreezeCustomize = (props: IFlightFreezeCustomizeProps) => {
  const {
    isAddOnOptionAvailable,
    currentPriceFreeze,
    tripCategory,
    isCustomizePageMarketplaceEnabled,
    isMultiPageMarketplaceEnabled,
    isCfarEnabled,
    isChfarEnabled,
    isDisruptionProtectionEnabled,
    fetchAncillaryOfferCallState,
    populateFlightBookQueryParams,
    history,
  } = props;
  const [currentCustomizeStep, setCurrentCustomizeStep] =
    useState<AddOnCustomizeStepAlias>(AddOnCustomizeStepAlias.AddOn1);

  const { matchesMobile } = useDeviceTypes();

  // note: use the priceFreezeId value from the URL query as a fallback
  const priceFreezeId =
    currentPriceFreeze?.priceFreeze.id ??
    new URLSearchParams(history.location.search).get(
      PRICE_FREEZE_ID_QUERY_PARAM
    );
  const goToPriceFreezeOverview = () => {
    history.push({
      pathname: PATH_PRICE_FREEZE_OVERVIEW,
      search: queryStringParser.stringify({
        [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeId,
      }),
    });
  };
  const goToCheckout = () => {
    populateFlightBookQueryParams({
      history,
      newQueryParams: {
        [PRICE_FREEZE_ID_QUERY_PARAM]: priceFreezeId ?? undefined,
      },
    });
  };

  useEffect(() => {
    if (
      (!isAddOnOptionAvailable &&
        fetchAncillaryOfferCallState !== CallState.InProcess) ||
      (!isCustomizePageMarketplaceEnabled && !matchesMobile)
    ) {
      goToPriceFreezeOverview();
    }
  }, [
    matchesMobile,
    isAddOnOptionAvailable,
    isCustomizePageMarketplaceEnabled,
    fetchAncillaryOfferCallState,
    goToPriceFreezeOverview,
  ]);

  const renderDesktopContent = () => {
    return (
      <>
        <DesktopRewardsHeader
          title={constants.REWARDS_BANNER_TITLE_COPY}
          subtitle={constants.REWARDS_BANNER_SUBTITLE_COPY}
        />
        <AddOnHeader />
        <Box className="progress-bar-wrapper">
          <FlightFreezeProgressBar />
        </Box>
        <AddOnCustomize
          tripCategory={tripCategory ?? TripCategory.ONE_WAY}
          isPriceFreezeExercise={true}
          onContinueSuccess={goToCheckout}
          isDisruptionProtectionEnabled={isDisruptionProtectionEnabled}
          hideGoToCheckoutButton={isMultiPageMarketplaceEnabled}
          useMultiPageVariant={isMultiPageMarketplaceEnabled}
          goBackToPreviousPage={goToPriceFreezeOverview}
        />
      </>
    );
  };

  const renderMobileContent = () => {
    return (
      <>
        <Header
          className="mobile-flight-freeze-customize-header static-header"
          center={
            <Box className="header-content">
              <Typography variant="body1">
                {constants.CUSTOMIZE_HEADER_TITLE}
              </Typography>
            </Box>
          }
          left={
            <ActionLink
              onClick={() =>
                setCurrentCustomizeStep((step) => Math.max(step - 1, 0))
              }
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          }
          isMobile={true}
          fullWidth={true}
        />
        <MobileAddOnCustomize
          goToCheckout={goToCheckout}
          goBack={goToPriceFreezeOverview}
          currentStep={currentCustomizeStep}
          setCurrentStep={setCurrentCustomizeStep}
          isCfarEnabled={isCfarEnabled}
          isChfarEnabled={isChfarEnabled}
          isDisruptionProtectionEnabled={isDisruptionProtectionEnabled}
        />
      </>
    );
  };

  return (
    <Box
      className={clsx("flight-freeze-customize-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="flight-freeze-customize-container">
        {matchesMobile ? renderMobileContent() : renderDesktopContent()}
      </Box>
    </Box>
  );
};
