import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  createWatchCallStateSelector,
  listWatchCallStateSelector,
  isWatchingSelector,
  predictionSelector,
  areBasicFaresFilteredOutSelector,
  createWatchFailureCodesSelector,
} from "../../reducer";
import { isPriceWatchPushNotificationDefaultOptInEnabledSelector, isPriceWatchPushNotificationEnabledSelector } from "../../reducer/selectors/experiments";
import { getStopsOptionFilter } from "../../../search/reducer";
import { PriceWatchOptInCard } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  createWatchCallState: createWatchCallStateSelector(state),
  createWatchFailureCodes: createWatchFailureCodesSelector(state),
  listWatchCallState: listWatchCallStateSelector(state),
  isWatching: isWatchingSelector(state),
  prediction: predictionSelector(state),
  areBasicFaresFilteredOut: areBasicFaresFilteredOutSelector(state),
  stopsOptionFilter: getStopsOptionFilter(state),
  isPriceWatchPushNotificationEnabled:
    isPriceWatchPushNotificationEnabledSelector(state),
  isPriceWatchPushNotificationDefaultOptInEnabled:
    isPriceWatchPushNotificationDefaultOptInEnabledSelector(state),
});

const mapDispatchToProps = {
  createWatch: actions.createWatch,
  setCreateWatchCallState: actions.setCreateWatchCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceWatchOptInCardConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceWatchOptInCard = withRouter(
  connector(PriceWatchOptInCard)
);
