import React, { useState, useEffect, useMemo } from "react";
import {
  ActionButton,
  ActionLink,
  B2BSpinner,
  CloseButtonIcon,
  DesktopPopupModal,
  MobilePopoverCard,
  useDeviceTypes,
  Icon,
  IconName,
} from "halifax";
import { CallState, FiatPrice, FareBrand, FlightShopStep } from "redmond";
import { Box, Button, Typography, Radio } from "@material-ui/core";
import clsx from "clsx";

import { InfantSeatPickerModalConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { PATH_BOOK } from "../../../../utils/urlPaths";
import { RouteComponentProps } from "react-router";
import {
  AMADEUS_FRONTER_EXPERIMENT,
  getExperimentVariantCustomVariants,
  UC_AVAILABLE,
  UC_DEFAULT_VARIANTS,
  useExperiments,
} from "../../../../context/experiments";

export interface IInfantSeatPickerModalProps
  extends InfantSeatPickerModalConnectorProps,
    RouteComponentProps {
  currentInfantToSelectSeat: string | boolean;
  setCurrentInfantToSelectSeat: React.Dispatch<
    React.SetStateAction<string | boolean>
  >;
  userSelectedPassengerIds: string[];
  userSelectedLapInfantIds: string[];
  setUserSelectedPassengerIds: (ids: string[]) => void;
  setUserSelectedLapInfantIds: (ids: string[]) => void;
}

enum SeatType {
  OnLap = "OnLap",
  OwnSeat = "OwnSeat",
}

const formatFiatValue = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
const getTotalPrice = (pricing: FiatPrice[]) =>
  pricing.reduce((sum, cur) => sum + cur.value, 0);

export const InfantSeatPickerModal = ({
  tripPricing,
  tripPricingCallState,
  currentInfantToSelectSeat,
  setCurrentInfantToSelectSeat,
  userSelectedPassengerIds,
  userSelectedLapInfantIds,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
  fareDetails,
  populateFlightShopQueryParams,
  history,
}: IInfantSeatPickerModalProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { experiments } = useExperiments();
  const [infantSeatPricing, setInfantSeatPricing] = useState({
    lapSeatPricing: fareDetails?.paxPricings!.find(
      (pax) => pax.paxType === "INF"
    ),
    ownSeatPricing: fareDetails?.paxPricings!.find(
      (pax) => pax.paxType === "INS"
    ),
  });
  const brand: FareBrand | undefined =
    fareDetails &&
    fareDetails.slices.length > 0 &&
    fareDetails.slices[0].fareDetails.segments.length > 0
      ? fareDetails?.slices[0].fareDetails.segments[0].brand
      : undefined;
  const isTravelFusion = brand?.travelFusionFareBrand ? true : false;

  const isAmadeusFrontierExperiment = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        experiments,
        AMADEUS_FRONTER_EXPERIMENT,
        UC_DEFAULT_VARIANTS
      ) === UC_AVAILABLE,
    [experiments]
  );

  const isAmadeusFrontier =
    !!fareDetails?.slices.find((slice) =>
      slice.fareDetails.segments.find(
        (segment) => segment.validatingCarrierCode === "F9"
      )
    ) && isAmadeusFrontierExperiment;

  const closePopup = () => setCurrentInfantToSelectSeat(false);

  useEffect(() => {
    if (tripPricingCallState === CallState.Failed) {
      closePopup();
    }
  }, [tripPricingCallState]);

  useEffect(() => {
    setInfantSeatPricing({
      lapSeatPricing: fareDetails?.paxPricings!.find(
        (pax) => pax.paxType === "INF"
      ),
      ownSeatPricing: fareDetails?.paxPricings!.find(
        (pax) => pax.paxType === "INS"
      ),
    });
  }, [fareDetails]);

  const totalPricing = {
    lapSeatTotal: infantSeatPricing?.lapSeatPricing
      ? getTotalPrice([
          infantSeatPricing.lapSeatPricing.pricing.baseAmount.fiat,
          infantSeatPricing.lapSeatPricing.pricing.taxAmount.fiat,
        ])
      : undefined,
    ownSeatPricing: infantSeatPricing?.ownSeatPricing
      ? getTotalPrice([
          infantSeatPricing.ownSeatPricing.pricing.baseAmount.fiat,
          infantSeatPricing.ownSeatPricing.pricing.taxAmount.fiat,
        ])
      : undefined,
  };

  const currencySymbol =
    tripPricing?.totalPricing.total.fiat.currencySymbol || "$";

  const InfantSeatPickerModalContent = () => {
    const [seatType, setSeatType] = useState<SeatType>(SeatType.OnLap);

    const onContinue = () => {
      if (seatType === SeatType.OwnSeat) {
        if (
          !userSelectedPassengerIds.includes(
            currentInfantToSelectSeat as string
          )
        ) {
          setUserSelectedLapInfantIds(
            userSelectedLapInfantIds.filter(
              (id) => id !== (currentInfantToSelectSeat as string)
            )
          );
          setUserSelectedPassengerIds([
            ...userSelectedPassengerIds,
            currentInfantToSelectSeat as string,
          ]);
        }
      } else {
        if (
          !userSelectedLapInfantIds.includes(
            currentInfantToSelectSeat as string
          )
        ) {
          setUserSelectedLapInfantIds([
            ...userSelectedLapInfantIds,
            currentInfantToSelectSeat as string,
          ]);
          setUserSelectedPassengerIds([...userSelectedPassengerIds]);
        }
      }
      closePopup();
    };

    return (
      <>
        {tripPricingCallState === CallState.InProcess && (
          <Box className="loading-container">
            <B2BSpinner />
            <Typography className="subheading">
              {constants.LOADING_TEXT}
            </Typography>
          </Box>
        )}
        {[CallState.Success, CallState.NotCalled].includes(
          tripPricingCallState
        ) &&
          (isAmadeusFrontier ? (
            <Box className="infant-not-avail-container">
              <Typography className="heading">
                {constants.INFANT_ONBOARD}
              </Typography>
              <Icon className="error-icon" name={IconName.UnableToProcess} />
              <Typography className="subheading">
                {constants.INFANTS_NOT_SUPPORTED}
              </Typography>
              <Box className="ctas-container">
                <ActionButton
                  onClick={() => {
                    populateFlightShopQueryParams({
                      history,
                      prevPath: PATH_BOOK,
                      useHistoryPush: true,
                      forceQueryUpdate: true,
                      newQueryParams: {
                        flightShopProgress: FlightShopStep.ChooseDeparture,
                      },
                    });
                  }}
                  className="choose-another-flight-cta"
                  defaultStyle="h4r-primary"
                  message={constants.CHOOSE_ANOTHER_FLIGHT}
                />
                <ActionButton
                  onClick={closePopup}
                  className="edit-travelers-cta"
                  message={constants.EDIT_TRAVELERS}
                  defaultStyle="h4r-secondary"
                />
              </Box>
            </Box>
          ) : (
            <Box className="lap-infant-seat-picker-container">
              <Typography className="heading">
                {constants.INFANT_ONBOARD}
              </Typography>
              <Typography className="subheading">
                {constants.SELECT_SEAT_TYPE}
              </Typography>
              <SelectRow
                label={constants.ON_LAP}
                description={constants.ON_LAP_DESCRIPTION}
                amount={totalPricing.lapSeatTotal}
                selected={seatType === SeatType.OnLap}
                onClick={() => setSeatType(SeatType.OnLap)}
                currencySymbol={currencySymbol}
              />
              {!isTravelFusion && (
                <SelectRow
                  label={constants.OWN_SEAT}
                  description={constants.OWN_SEAT_DESCRIPTION}
                  amount={totalPricing.ownSeatPricing}
                  selected={seatType === SeatType.OwnSeat}
                  onClick={() => setSeatType(SeatType.OwnSeat)}
                  currencySymbol={currencySymbol}
                />
              )}
              {(!fareDetails?.paxPricings!.find(
                (pax) => pax.paxType === "INS"
              ) ||
                !fareDetails?.paxPricings!.find(
                  (pax) => pax.paxType === "INF"
                )) && (
                <Typography
                  className="infant-unavail-pricing-text"
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: constants.INFANT_PRICING_UNAVAIL_TEXT,
                  }}
                />
              )}
              <Button
                onClick={onContinue}
                className="continue-button"
                variant="contained"
              >
                {constants.CONTINUE}
              </Button>
            </Box>
          ))}
      </>
    );
  };

  return (
    <>
      {matchesMobile ? (
        <MobilePopoverCard
          open={Boolean(currentInfantToSelectSeat)}
          onClose={closePopup}
          centered={true}
          className="infant-seat-picker-modal"
          contentClassName="infant-seat-picker-popover-content"
          topRightButton={
            <ActionLink
              className="filter-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={closePopup}
            />
          }
        >
          <InfantSeatPickerModalContent />
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          open={Boolean(currentInfantToSelectSeat)}
          onClose={closePopup}
          className="lap-infant-seat-select-popup-root"
          invisibleBackdrop={false}
        >
          <InfantSeatPickerModalContent />
        </DesktopPopupModal>
      )}
    </>
  );
};

interface ISelectRow {
  selected: boolean;
  label: string;
  amount?: number;
  description: string;
  onClick: () => void;
  currencySymbol: string;
}

const SelectRow = ({
  selected,
  label,
  amount,
  description,
  onClick,
  currencySymbol,
}: ISelectRow) => {
  const labelSubText =
    amount === undefined
      ? "Price available at checkout"
      : amount === 0
      ? constants.FREE_DESCRIPTION
      : `${currencySymbol}${formatFiatValue(amount)} ${description}`;
  return (
    <Box className="select-row">
      <Radio
        className={clsx("radio", { selected })}
        checked={selected}
        onClick={() => onClick()}
      />
      <Box
        className={clsx("select-row-text-container", { selected })}
        onClick={onClick}
      >
        <Typography>
          <strong>{label}</strong>
          {` - ${labelSubText}`}
        </Typography>
      </Box>
    </Box>
  );
};
