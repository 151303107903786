import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../reducers/types";
import { tripCategoryFromSelectedTripDetailsSelector } from "../../../../shop/reducer";
import {
  priceFreezeMaxDurationSelector,
  priceFreezeMinOfferSelector,
  priceFreezeUserSelectedDurationSelectionMethodSelector,
} from "../../../reducer";
import { DesktopPriceFreezeDurationDetails } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    tripCategory: tripCategoryFromSelectedTripDetailsSelector(state),
    priceFreezeMaxDuration: priceFreezeMaxDurationSelector(state),
    priceFreezeUserSelectedDurationSelectionMethod:
      priceFreezeUserSelectedDurationSelectionMethodSelector(state),
    priceFreezeMinOffer: priceFreezeMinOfferSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopPriceFreezeDurationDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopPriceFreezeDurationDetails = withRouter(
  connector(DesktopPriceFreezeDurationDetails)
);
