import { DateToggleFilter } from "../../reducer";

const TODAY_DISPLAY_TEXT = "Same day";
const TOMORROW_DISPLAY_TEXT = "Next day";

export const getValueText = (date: DateToggleFilter) =>
  date === DateToggleFilter.TODAY ? TODAY_DISPLAY_TEXT : TOMORROW_DISPLAY_TEXT;

export const getAriaValueText = (date: DateToggleFilter) =>
  `Display flights that depart ${
    date === DateToggleFilter.TODAY ? TODAY_DISPLAY_TEXT : TOMORROW_DISPLAY_TEXT
  }`;

export const TODAY_TOMORROW_TOGGLE_ARIA_LABEL = `${TODAY_DISPLAY_TEXT} or ${TOMORROW_DISPLAY_TEXT}`;
