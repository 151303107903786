import {
  TripCategory,
  SliceStopCountFilter,
  TripDetails,
  ILocationQueryLabel,
  IResponse,
  ITripTerminus,
  LocationQueryEnum,
  FLIGHT_SHOP_TYPE,
  FlightShopType,
  PRICE_FREEZE_ID_QUERY_PARAM,
  DisruptionProtectionQueryParam,
} from "redmond";
import { put, putResolve, select } from "redux-saga/effects";
import dayjs from "dayjs";

import { fetchTripDetails as fetchDetails } from "../../../api/v0/shop/fetchTripDetails";
import { IStoreState } from "../../../reducers/types";
import {
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
  getTripCategory,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getFareclassOptionFilter,
  getStopsOptionFilter,
  getMulticityRoutes,
} from "../../search/reducer/selectors";
import { actions as searchActions } from "../../search/actions";
import { actions } from "../actions";
import {
  IPopulateFlightShopQueryParams,
  IFlightShopOverwriteQueryParams,
} from "../actions/actions";
import * as H from "history";
import queryStringParser from "query-string";
import {
  IFlightShopParsedQuery,
  IMulticityFlightShopParsedQuery,
  parseQueryString,
} from "../utils/parseQueryString";
import { PATH_BOOK, PATH_SHOP } from "../../../utils/urlPaths";
import {
  flightShopProgressSelector,
  flightShopMulticityProgressSelector,
  selectedMulticityTripsSelector,
  selectedTripSelector,
} from "../reducer/selectors";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import { ITripTerminusCategory } from "../../search/types";
import { isDefined } from "halifax";
import { getQueryStringValuesFromMcRoutes } from "../../search/reducer/utils/multicityParams";
import { MulticityFlightShopStep } from "../reducer";
import { isEqual } from "lodash-es";

const isRoundtrip = (tripCategory: TripCategory): boolean =>
  tripCategory === TripCategory.ROUND_TRIP;
const isMulticity = (tripCategory: TripCategory): boolean =>
  tripCategory === TripCategory.MULTI_CITY;

export function* populateFlightShopQueryParamsSaga(
  setUpQueryParamsAction: IPopulateFlightShopQueryParams
) {
  const {
    history,
    prevPath,
    useHistoryPush,
    forceQueryUpdate,
    newQueryParams,
  } = setUpQueryParamsAction;
  const state: IStoreState = yield select();
  if (
    newQueryParams?.flightShopProgress === undefined &&
    (isMulticity(state.flightSearch.tripCategory) ||
      newQueryParams?.multicityFlightShopProgress !== undefined)
  ) {
    yield populateFlightShopMulticityQueryParametersFromState({
      state,
      history,
      prevPath,
      useHistoryPush,
      forceQueryUpdate,
      newQueryParams,
    });
  } else {
    yield populateFlightShopQueryParametersFromState({
      state,
      history,
      prevPath,
      useHistoryPush,
      forceQueryUpdate,
      newQueryParams,
    });
  }
}

export function* populateFlightShopQueryParametersFromState({
  state,
  history,
  prevPath,
  useHistoryPush,
  forceQueryUpdate,
  newQueryParams,
  isV2,
}: {
  state: IStoreState;
  history: H.History;
  prevPath?: string;
  useHistoryPush?: boolean;
  forceQueryUpdate?: boolean;
  newQueryParams?: IFlightShopOverwriteQueryParams;
  isV2?: boolean;
}) {
  let {
    departureDate,
    returnDate,
    origin,
    destination,
    tripCategory,
    stopsOption,
    noLCC,
    flightShopProgress,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    fareClass,
  } = getExistingStateVariables(state);

  let location: any;
  const selectedTrip = selectedTripSelector(state);
  const updatedTripCategory = newQueryParams?.tripCategory;
  const parsedQueryString = parseQueryString(
    history,
    updatedTripCategory
  ) as IFlightShopParsedQuery;

  if (
    selectedTrip.tripId &&
    ((!origin && !parsedQueryString.origin) ||
      (!destination && !parsedQueryString.destination) ||
      (!tripCategory && !parsedQueryString.tripCategory))
  ) {
    const tripDetails: TripDetails = yield fetchDetails(selectedTrip.tripId);
    yield populateStateFromTripDetails(tripDetails, state);
  }

  const newFlightShopType =
    newQueryParams?.flightShopType ?? parsedQueryString?.flightShopType;

  switch (newFlightShopType) {
    case FlightShopType.PRICE_FREEZE_EXERCISE:
      yield putResolve(
        actions.setFlightShopType(
          !isV2 ? FlightShopType.PRICE_FREEZE_EXERCISE : FlightShopType.DEFAULT
        )
      );
      break;
    default:
      // note: reset flight shop type to default when it's not given in newQueryParams nor queryString
      yield putResolve(
        actions.setFlightShopType(newFlightShopType ?? FlightShopType.DEFAULT)
      );
      break;
  }

  // note: updating flight shop progress state prior to updating URL query is mandatory
  const newFlightShopProgress =
    newQueryParams?.flightShopProgress ?? parsedQueryString?.flightShopProgress;
  if (newFlightShopProgress !== undefined) {
    yield putResolve(actions.setFlightShopProgress(newFlightShopProgress));
  }

  if (parsedQueryString.productRedeemChoice) {
    yield putResolve(
      actions.setProductRedeemChoice(parsedQueryString.productRedeemChoice)
    );
  }

  // Note: If this query is made from Price Watch, we treat parsedQueryString as the source of truth unless this is
  // a forced query update (e.g. from Search Again via the search control)
  if (
    forceQueryUpdate ||
    (stateDiffersFromQueryParams(state, parsedQueryString) &&
      !parsedQueryString.isFromFlightWatch)
  ) {
    tripCategory = tripCategory || parsedQueryString.tripCategory;
    departureDate = departureDate || parsedQueryString.departureDate;
    returnDate = returnDate || parsedQueryString.returnDate;
    stopsOption = stopsOption || parsedQueryString.stopsOption;
    flightShopProgress =
      flightShopProgress || parsedQueryString.flightShopProgress;
    const flightShopType = parsedQueryString.flightShopType;
    const newQuery = {
      tripCategory: tripCategory || parsedQueryString.tripCategory,
      // if origin/destination does not exist we should use the values from queryparams
      origin: !origin ? parsedQueryString.origin : origin?.id?.code?.code,
      destination: !destination
        ? parsedQueryString.destination
        : destination?.id?.code?.code,
      departureDate: departureDate && dayjs(departureDate).format("YYYY-MM-DD"),
      returnDate:
        isRoundtrip(tripCategory) && returnDate
          ? dayjs(returnDate).format("YYYY-MM-DD")
          : undefined,
      stopsOption:
        // note: when origin/destination does not exist, it has to be a page refresh;
        // do not overwrite stopsOption in the query param when it happens.
        !origin || !destination ? parsedQueryString.stopsOption : stopsOption,
      noLCC: !origin || !destination ? parsedQueryString.noLCC : noLCC,
      flightShopProgress,
      [FLIGHT_SHOP_TYPE]: flightShopType,
      adultsCount: adultsCount || parsedQueryString.adultsCount,
      childrenCount:
        (childrenCount ?? parsedQueryString.childrenCount) || undefined,
      infantsInSeatCount:
        (infantsInSeatCount ?? parsedQueryString.infantsInSeatCount) ||
        undefined,
      infantsOnLapCount:
        (infantsOnLapCount ?? parsedQueryString.infantsOnLapCount) || undefined,
      fareClass: (fareClass ?? parsedQueryString.fareClass) || undefined,
      ...newQueryParams,
    };
    const newSimilarFlightsQuery = {
      flightShopProgress,
      [FLIGHT_SHOP_TYPE]: flightShopType,
      [PRICE_FREEZE_ID_QUERY_PARAM]: parsedQueryString.priceFreezeId,
      ...newQueryParams,
    };
    const newRebookFlightsQuery = {
      flightShopProgress,
      [FLIGHT_SHOP_TYPE]: flightShopType,
      [DisruptionProtectionQueryParam.ItineraryId]:
        parsedQueryString.itineraryId,
      [DisruptionProtectionQueryParam.SliceIndex]: parsedQueryString.sliceIndex,
      [DisruptionProtectionQueryParam.SegmentIndex]:
        parsedQueryString.segmentIndex,
      [DisruptionProtectionQueryParam.ProductRedeemChoice]:
        parsedQueryString.productRedeemChoice,
      origin: parsedQueryString.origin,
      destination: parsedQueryString.destination,
      ...newQueryParams,
    };

    const newQueryByFlightShopType = (() => {
      switch (newFlightShopType) {
        case FlightShopType.PRICE_FREEZE_EXERCISE:
          return newSimilarFlightsQuery;
        case FlightShopType.DISRUPTION_PROTECTION_EXERCISE:
          return newRebookFlightsQuery;
        default:
          return newQuery;
      }
    })();

    location = {
      pathname: PATH_SHOP,
      search: queryStringParser.stringify(newQueryByFlightShopType),
    };

    // the populateFlightShopQueryParams action is sometimes called from places where a "prevPath" state needs to be set
    // (e.g.: FlightsBook - when it's going back to FlightShop)
    if (prevPath) {
      location["state"] = { prevPath };
    }

    if (useHistoryPush) {
      history.push(location);
    } else {
      history.replace(location);
    }
  }

  return { ...parsedQueryString, ...newQueryParams };
}

export function* populateFlightShopMulticityQueryParametersFromState({
  state,
  history,
  prevPath,
  useHistoryPush,
  forceQueryUpdate,
  newQueryParams,
  isBookingStep,
}: {
  state: IStoreState;
  history: H.History;
  prevPath?: string;
  useHistoryPush?: boolean;
  forceQueryUpdate?: boolean;
  newQueryParams?: IFlightShopOverwriteQueryParams;
  isBookingStep?: boolean;
}) {
  let {
    tripCategory,
    stopsOption,
    noLCC,
    multicityFlightShopProgress,
    multicityRoutes,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
  } = getExistingStateVariables(state);

  let location: any;
  const selectedMulticityTrips = selectedMulticityTripsSelector(state);
  const selectedTripIndex =
    multicityFlightShopProgress <= 5
      ? multicityFlightShopProgress
      : multicityRoutes.length - 1;
  const selectedTrip = selectedMulticityTrips[selectedTripIndex];
  const parsedQueryString = parseQueryString(
    history
  ) as IMulticityFlightShopParsedQuery;

  // populate from selected trip if missing values
  const hasMissingValues = multicityRoutes.find((mcRoute, idx) => {
    const { origin, destination, departureDate } = mcRoute;
    return (
      (!origin && !parsedQueryString[`origin${idx}`]) ||
      (!destination && !parsedQueryString[`destination${idx}`]) ||
      (!departureDate && !parsedQueryString[`departureDate${idx}`])
    );
  });

  if (selectedTrip?.tripId && hasMissingValues) {
    const tripDetails: TripDetails = yield fetchDetails(
      selectedTrip.tripId as string
    );
    yield populateStateFromTripDetails(tripDetails, state);
  }

  //note: updating flight shop progress state prior to updating URL query is mandatory
  const newFlightShopProgress =
    newQueryParams?.multicityFlightShopProgress ??
    parsedQueryString?.multicityFlightShopProgress;
  if (newFlightShopProgress !== undefined) {
    yield putResolve(
      actions.setMulticityFlightShopProgress(newFlightShopProgress)
    );
  }

  if (
    forceQueryUpdate ||
    stateDiffersFromQueryParams(state, parsedQueryString)
  ) {
    const stateValues = getQueryStringValuesFromMcRoutes(multicityRoutes);

    const {
      origin0,
      origin1,
      origin2,
      origin3,
      origin4,
      destination0,
      destination1,
      destination2,
      destination3,
      destination4,
      departureDate0,
      departureDate1,
      departureDate2,
      departureDate3,
      departureDate4,
    } = stateValues;

    const newQuery = {
      tripCategory: tripCategory || parsedQueryString.tripCategory,
      // if origin/destination does not exist we should use the values from queryparams
      origin0: origin0 || parsedQueryString.origin0,
      origin1: origin1 || parsedQueryString.origin1,
      origin2: origin2 || parsedQueryString.origin2 || undefined,
      origin3: origin3 || parsedQueryString.origin3 || undefined,
      origin4: origin4 || parsedQueryString.origin4 || undefined,
      destination0: destination0 || parsedQueryString.destination0,
      destination1: destination1 || parsedQueryString.destination1,
      destination2: destination2 || parsedQueryString.destination2 || undefined,
      destination3: destination3 || parsedQueryString.destination3 || undefined,
      destination4: destination4 || parsedQueryString.destination4 || undefined,
      departureDate0: departureDate0
        ? dayjs(departureDate0).format("YYYY-MM-DD")
        : undefined,
      departureDate1: departureDate1
        ? dayjs(departureDate1).format("YYYY-MM-DD")
        : undefined,
      departureDate2: departureDate2
        ? dayjs(departureDate2).format("YYYY-MM-DD")
        : undefined,
      departureDate3: departureDate3
        ? dayjs(departureDate3).format("YYYY-MM-DD")
        : undefined,
      departureDate4: departureDate4
        ? dayjs(departureDate4).format("YYYY-MM-DD")
        : undefined,
      stopsOption: parsedQueryString.stopsOption || stopsOption,
      noLCC,
      multicityFlightShopProgress,
      adultsCount: adultsCount || parsedQueryString.adultsCount,
      childrenCount:
        (childrenCount ?? parsedQueryString.childrenCount) || undefined,
      infantsInSeatCount:
        (infantsInSeatCount ?? parsedQueryString.infantsInSeatCount) ||
        undefined,
      infantsOnLapCount:
        (infantsOnLapCount ?? parsedQueryString.infantsOnLapCount) || undefined,
      ...newQueryParams,
    };

    yield put(
      searchActions.setStopsOption(
        (parsedQueryString.stopsOption as SliceStopCountFilter) || stopsOption
      )
    );

    location = {
      pathname: isBookingStep ? PATH_BOOK : PATH_SHOP,
      search: queryStringParser.stringify(newQuery),
    };

    // the populateFlightShopQueryParams action is sometimes called from places where a "prevPath" state needs to be set
    // (e.g.: FlightsBook - when it's going back to FlightShop)
    if (prevPath) {
      location["state"] = { prevPath };
    }

    if (useHistoryPush) {
      history.push(location, { fromPage: location.pathname });
    } else {
      history.replace(location, { fromPage: location.pathname });
    }
  }

  return { ...parsedQueryString, ...newQueryParams };
}

export function getExistingStateVariables(state: IStoreState) {
  const fareclassOptionFilter = getFareclassOptionFilter(state);
  const noLCC =
    !fareclassOptionFilter.basic &&
    fareclassOptionFilter.luxury &&
    fareclassOptionFilter.enhanced &&
    fareclassOptionFilter.premium &&
    fareclassOptionFilter.standard;
  return {
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    tripCategory: getTripCategory(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    stopsOption: getStopsOptionFilter(state),
    flightShopProgress: flightShopProgressSelector(state),
    multicityFlightShopProgress: flightShopMulticityProgressSelector(state),
    multicityRoutes: getMulticityRoutes(state),
    noLCC,
    fareClass: Object.keys(fareclassOptionFilter).filter(
      (fareClassKey) => fareclassOptionFilter[fareClassKey]
    ),
  };
}

export function stateDiffersFromQueryParams(
  state: IStoreState,
  parsedQueryString: IFlightShopParsedQuery | IMulticityFlightShopParsedQuery
) {
  const {
    departureDate,
    returnDate,
    origin,
    destination,
    tripCategory,
    stopsOption,
    noLCC,
    flightShopProgress,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    multicityFlightShopProgress,
    multicityRoutes,
    fareClass,
  } = getExistingStateVariables(state);

  const diffPaxCounts =
    adultsCount !== parsedQueryString.adultsCount ||
    (parsedQueryString.childrenCount &&
      childrenCount !== parsedQueryString.childrenCount) ||
    (parsedQueryString.infantsInSeatCount &&
      infantsInSeatCount !== parsedQueryString.infantsInSeatCount) ||
    (parsedQueryString.infantsOnLapCount &&
      infantsOnLapCount !== parsedQueryString.infantsOnLapCount);
  /**
   * ANY_NUMBER is default value so we cannot check for undefined.
   * Instead we check if destination is undefined for page refresh senario.
   **/
  const diffStopOptions =
    stopsOption !== SliceStopCountFilter.ANY_NUMBER ||
    (destination && parsedQueryString.stopsOption !== stopsOption);
  const diffNoLCC = parsedQueryString.noLCC !== noLCC;
  const diffTripCategory = parsedQueryString.tripCategory !== tripCategory;

  if (tripCategory === TripCategory.MULTI_CITY) {
    const parsedMulticityShopQueryString =
      parsedQueryString as IMulticityFlightShopParsedQuery;

    const {
      origin0,
      origin1,
      origin2,
      origin3,
      origin4,
      destination0,
      destination1,
      destination2,
      destination3,
      destination4,
      departureDate0,
      departureDate1,
      departureDate2,
      departureDate3,
      departureDate4,
    } = parsedMulticityShopQueryString;

    const stateMcRoutesLength = multicityRoutes.length;

    const diffNumDefinedOrigins =
      [origin0, origin1, origin2, origin3, origin4].filter(isDefined).length !==
      stateMcRoutesLength;
    const diffNumDefinedDestinations =
      [
        destination0,
        destination1,
        destination2,
        destination3,
        destination4,
      ].filter(isDefined).length !== stateMcRoutesLength;
    const diffNumDefinedDepartureDates =
      [
        departureDate0,
        departureDate1,
        departureDate2,
        departureDate3,
        departureDate4,
      ].filter(isDefined).length !== stateMcRoutesLength;

    const diffMcRouteValues = multicityRoutes.find((route, index) => {
      const { origin, destination, departureDate } = route;
      const diffOrigin =
        origin?.id.code.code !==
        parsedMulticityShopQueryString[`origin${index}`];
      const diffDestination =
        destination?.id.code.code !==
        parsedMulticityShopQueryString[`destination${index}`];
      const diffDepartureDate =
        departureDate &&
        !dayjs(parsedMulticityShopQueryString[`departureDate${index}`]).isSame(
          dayjs(departureDate)
        );
      return diffOrigin || diffDestination || diffDepartureDate;
    });

    const diffFlightShopProgress =
      parsedMulticityShopQueryString.multicityFlightShopProgress !==
      multicityFlightShopProgress;

    return (
      diffStopOptions ||
      diffNoLCC ||
      diffTripCategory ||
      diffPaxCounts ||
      diffNumDefinedOrigins ||
      diffNumDefinedDestinations ||
      diffNumDefinedDepartureDates ||
      diffMcRouteValues ||
      diffFlightShopProgress
    );
  } else {
    const parsedShopQueryString = parsedQueryString as IFlightShopParsedQuery;

    const diffOrigin =
      !origin ||
      (origin && parsedShopQueryString.origin !== origin.id.code.code);
    const diffDestination =
      !destination ||
      (destination &&
        parsedShopQueryString.destination !== destination.id.code.code);
    const diffDepartureDate =
      departureDate &&
      !dayjs(parsedShopQueryString.departureDate).isSame(dayjs(departureDate));
    const diffReturnDate =
      returnDate &&
      parsedShopQueryString.returnDate &&
      isRoundtrip(tripCategory) &&
      !dayjs(parsedShopQueryString.returnDate).isSame(dayjs(returnDate));
    const diffFlightShopProgress =
      parsedShopQueryString.flightShopProgress !== flightShopProgress;
    const diffFareClass = !isEqual(
      typeof parsedShopQueryString.fareClass === "string"
        ? [parsedShopQueryString.fareClass]
        : parsedShopQueryString.fareClass || [],
      fareClass
    );

    return !!(
      diffOrigin ||
      diffDestination ||
      diffReturnDate ||
      diffDepartureDate ||
      diffStopOptions ||
      diffNoLCC ||
      diffFlightShopProgress ||
      diffTripCategory ||
      diffPaxCounts ||
      diffFareClass
    );
  }
}

function* populateStateFromTripDetails(
  tripDetails: TripDetails,
  state: IStoreState
) {
  const {
    departureDate,
    returnDate,
    origin,
    destination,
    multicityRoutes,
    multicityFlightShopProgress,
    tripCategory,
  } = getExistingStateVariables(state);

  if (tripCategory === TripCategory.MULTI_CITY) {
    const numMcRoutes = multicityRoutes.length;
    const routeIndexToSearch = [
      MulticityFlightShopStep.FareDetails,
      MulticityFlightShopStep.ReviewItinerary,
      MulticityFlightShopStep.BookTrip,
    ].includes(multicityFlightShopProgress)
      ? numMcRoutes - 1 // 0-indexed
      : multicityFlightShopProgress;

    const {
      origin: mcRouteOrigin,
      destination: mcRouteDestination,
      departureDate: mcRouteDepartureDate,
    } = multicityRoutes[routeIndexToSearch];

    if (!mcRouteOrigin || !mcRouteDestination) {
      const { correspondingOrigin, correspondingDestination } =
        yield fetchOriginDestination(
          tripDetails.slices[routeIndexToSearch].originCode,
          tripDetails.slices[routeIndexToSearch].destinationCode
        );
      yield put(
        searchActions.setMulticityOrigin(
          correspondingOrigin,
          routeIndexToSearch
        )
      );
      yield put(
        searchActions.setMulticityDestination(
          correspondingDestination,
          routeIndexToSearch
        )
      );
    }
    if (!mcRouteDepartureDate) {
      yield put(
        searchActions.setMulticityDepartureDate(
          dayjs(tripDetails.slices[routeIndexToSearch].departureTime).toDate(),
          routeIndexToSearch
        )
      );
    }
  } else {
    if (!origin || !destination) {
      const { correspondingOrigin, correspondingDestination } =
        yield fetchOriginDestination(
          tripDetails.slices[0].originCode,
          tripDetails.slices[0].destinationCode
        );
      yield put(searchActions.setOrigin(correspondingOrigin));
      yield put(searchActions.setDestination(correspondingDestination));
    }
    if (!departureDate) {
      yield put(
        searchActions.setDepartureDate(
          dayjs(tripDetails.slices[0].departureTime).toDate()
        )
      );
    }
    if (!returnDate && tripDetails.slices.length > 1) {
      yield put(
        searchActions.setReturnDate(
          dayjs(tripDetails.slices[1].departureTime).toDate()
        )
      );
    }
  }
}

function* fetchOriginDestination(origin: string, destination: string) {
  const originRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: origin,
  };

  const { categories: originCategories }: IResponse =
    yield fetchLocationAutocomplete(originRequestBody);
  const correspondingOrigin = originCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find((result) => result.id.code.code === origin) as ITripTerminus;

  const destinationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: destination,
  };

  const { categories: destinationCategories }: IResponse =
    yield fetchLocationAutocomplete(destinationRequestBody);
  const correspondingDestination = destinationCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find((result) => result.id.code.code === destination) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
