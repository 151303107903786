import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RebookReviewItinerary } from "./component";
import {
  getFinalizedItinerary,
  getPriceQuoteErrors,
  rebookedItineraryDpExerciseItineraryFactsPropertiesSelector,
  rebookedItineraryDpRebookingCompleteBuyFactsPropertiesSelector,
} from "../../../book/reducer";
import { resetBookingErrors } from "../../../book/actions/actions";
import { flightShopProductRedeemChoice, selectedSliceFlightSummaryPropsForRebookSelector } from "../../../shop/reducer";
import {
  setFlightShopProgress,
  populateFlightShopQueryParams,
} from "../../../shop/actions/actions";
import {
  singleFlightItineraryRebookCoverageSelector,
  singleFlightItineraryPassengerNamesAndIdsSelector,
  exerciseDisruptionProtectionRebookCallStateSelector,
  originalItineraryDpExerciseItineraryFactsPropertiesSelector,
  reviewItineraryEligibilityDpExerciseFactsPropertiesSelector,
  isSelectingReturnFlightSelector,
  disruptedFlightDpExerciseFactsPropertiesSelector,
  dpExercisePassengerPropertiesSelector,
  dateToggleFilterSelector,
  rebookSummarySelector,
  exerciseDisruptionProtectionRebookCallFailureSelector,
} from "../../reducer";
import {
  exerciseDisruptionProtectionRebook,
  setExerciseDisruptionProtectionRebookCallStateNotCalled,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    rebookSummary: rebookSummarySelector(state),
    finalizedItinerary: getFinalizedItinerary(state),
    flightSummaryProps: selectedSliceFlightSummaryPropsForRebookSelector(state),
    productRedeemChoice: flightShopProductRedeemChoice(state),
    exerciseDisruptionProtectionRebookCallState:
      exerciseDisruptionProtectionRebookCallStateSelector(state),
    exerciseDisruptionProtectionRebookCallFailureReason:
      exerciseDisruptionProtectionRebookCallFailureSelector(state),
    rebookCoverage: singleFlightItineraryRebookCoverageSelector(state),
    passengerNamesAndIds:
      singleFlightItineraryPassengerNamesAndIdsSelector(state),
    priceQuoteErrors: getPriceQuoteErrors(state),
    originalItineraryDpExerciseItineraryFactsProperties:
      originalItineraryDpExerciseItineraryFactsPropertiesSelector(state),
    reviewItineraryEligibilityDpExerciseFactsProperties:
      reviewItineraryEligibilityDpExerciseFactsPropertiesSelector(state),
    rebookedItineraryDpExerciseItineraryFactsProperties:
      rebookedItineraryDpExerciseItineraryFactsPropertiesSelector(state),
    rebookedItineraryDpRebookingCompleteBuyFactsProperties:
      rebookedItineraryDpRebookingCompleteBuyFactsPropertiesSelector(state),
    isSelectingReturnFlight: isSelectingReturnFlightSelector(state),
    disruptedFlightDpExerciseFactsProperties:
      disruptedFlightDpExerciseFactsPropertiesSelector(state),
    dpExercisePassengerProperties: dpExercisePassengerPropertiesSelector(state),
    dateToggleFilter: dateToggleFilterSelector(state),
  };
};

const mapDispatchToProps = {
  resetBookingErrors,
  setFlightShopProgress,
  populateFlightShopQueryParams,
  exerciseDisruptionProtectionRebook,
  setExerciseDisruptionProtectionRebookCallStateNotCalled,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RebookReviewItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedRebookReviewItinerary = connector(
  withRouter(RebookReviewItinerary)
);
