import { put, select, putResolve, call } from "redux-saga/effects";

import { actions as searchActions } from "../../search/actions";
import { fetchTripSummaries as fetchFlights } from "../../../api/v3/shop/fetchTripSummaries";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { actions as ancillaryActions } from "../../ancillary/actions/";
import {
  getAirEntryProperties,
  getPriceFreezeOfferCheapestTripTripId,
  getPriceFreezeOfferCheapestTripFareId,
  flightShopProgressSelector,
  FlightShopStep,
} from "../reducer";
import { isRefundableFaresEnabledSelector } from "../../ancillary/reducer/selectors";
import { IShopParams } from "../actions/actions";
import {
  AirEntryProperties,
  AIR_ENTRY,
  getWatchFilterTypeFromFlightShopParams,
  ILocationQueryLabel,
  IResponse,
  ITripTerminus,
  LocationQueryEnum,
  SliceStopCountFilter,
  TripCategory,
  WatchEntryProperties,
  WATCH_ENTRY,
  ShopSummaryRequest,
  ShopFilter,
} from "redmond";
import { ITripTerminusCategory } from "../../search/types";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import { getExistingStateVariables } from "./populateShopQueryParamsSaga";
import { initializeOfferDataAndCustomOffer } from "../../freeze/sagas/initializeOfferDataAndCustomOfferSaga";
import { IStoreState } from "../../../reducers/types";
import dayjs from "dayjs";
import { PATH_HOME } from "../../../utils/urlPaths";
import {
  MobileFlightSearchStep,
  initialFilterOptions,
} from "../../search/reducer";
import { setMobileSearchProgress } from "../../search/actions/actions";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../utils/parseQueryString";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { ShopSummaryResponseV2 } from "@b2bportal/air-shopping-api";

const shouldRedirect = ({
  origin,
  destination,
  departureDate,
  tripCategory,
  returnDate,
  stopsOption,
}: any) =>
  !origin ||
  !destination ||
  !departureDate ||
  !stopsOption ||
  (tripCategory === TripCategory.ROUND_TRIP && !returnDate);

export function* fetchTripSummariesV3(action: actions.IFetchTripSummariesV3) {
  try {
    const {
      departureDate,
      returnDate,
      origin,
      destination,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
      fareClass,
    } = yield call(setUpFlightShopParams, action);

    const requestBody: IShopParams = {
      origin: { ...origin.id.code },
      destination: { ...destination.id.code },
      ...(returnDate && {
        returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption:
        action.includeStopsFilter === false
          ? initialFilterOptions.stopsOption
          : stopsOption,
      fareclassOptionFilter: {
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      },
      originLocationLabel:
        action.includeLocationLabels && !!origin?.label
          ? origin?.label
          : undefined,
      destinationLocationLabel:
        action.includeLocationLabels && !!destination?.label
          ? destination?.label
          : undefined,
      fareClass,
    };

    const response: ShopSummaryResponseV2 = yield fetchFlights(
      requestBody,
      action.isMobile
    );

    const properties: AirEntryProperties | null = yield select(
      getAirEntryProperties
    );

    if (!action.trackNonStopFilter) delete properties?.non_stop;

    yield trackEvent({
      eventName: AIR_ENTRY,
      properties,
    });

    const state: IStoreState = yield select();
    const isRefundableFaresEnabled = isRefundableFaresEnabledSelector(state);
    const flightShopProgress = flightShopProgressSelector(state);
    if (
      isRefundableFaresEnabled &&
      flightShopProgress === FlightShopStep.ChooseDeparture
    ) {
      // reset refundable fare states on new flight search
      yield put(actions.setHasSelectedRefundableFare(false));
      yield put(ancillaryActions.resetCfarOffers());
      yield put(actions.resetRefundableFaresProperties());
    }

    // offers must be set before flights

    // TODO use proper types
    yield putResolve(
      actions.setBestOverallOffer(response.offers?.bestOfferOverall as any)
    );
    yield putResolve(
      actions.setOffersByTripId(response.offers?.offersByTripId as any)
    );

    // Corporate Travel
    const passengerObj = {};

    if (adultsCount > 0) passengerObj["ADT"] = adultsCount;
    if (infantsInSeatCount > 0) passengerObj["INS"] = infantsInSeatCount;
    if (infantsOnLapCount > 0) passengerObj["INF"] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj["CNN"] = childrenCount;

    let tripFilter = ShopFilter.NoFilter;
    const filterOutBasicFares =
      !requestBody.fareclassOptionFilter.basic &&
      requestBody.fareclassOptionFilter.luxury &&
      requestBody.fareclassOptionFilter.enhanced &&
      requestBody.fareclassOptionFilter.premium &&
      requestBody.fareclassOptionFilter.standard;
    if (requestBody.fareclassOptionFilter && filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }

    const shopRequest: ShopSummaryRequest = {
      route: {
        origin: requestBody.origin,
        destination: requestBody.destination,
      },
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      ...(returnDate && { returnDate: dayjs(returnDate).format("YYYY-MM-DD") }),
      tripFilter,
      passengers: passengerObj,
    };
    // ====== //

    // note: the order does matter here! having redux set priceFreezeOffer first will allow it to update priceFreezeOffer
    // related selectors prior to that of the prediction
    yield putResolve(actions.setFlights(response.flights));
    yield putResolve(actions.setShopRequest(shopRequest));
    yield putResolve(
      actions.setPriceFreezeOffer(response.priceFreezeOffer as any)
    );

    const cheapestTripTripId: string = yield select(
      getPriceFreezeOfferCheapestTripTripId
    );
    const cheapestTripFareId: string = yield select(
      getPriceFreezeOfferCheapestTripFareId
    );
    yield initializeOfferDataAndCustomOffer({
      departureDate,
      tripId: cheapestTripTripId,
      fareId: cheapestTripFareId,
      history: action.history,
      isFromFetchTripSummariesV3: true,
    });

    // We need both the prediction data (from flights) and PF custom offer data to render
    // price prediction card without flickering data, so we set prediction last (as that
    // switches off predictionLoading).
    yield putResolve(actions.setPrediction(response.prediction as any));

    if (cheapestTripTripId) {
      yield put(actions.fetchTripDetails({ tripId: cheapestTripTripId }));
    }

    yield put(searchActions.setAwaitingRefetch(false));
  } catch (e) {
    const errorCode: string = (e as any)[0]?.code;
    yield put(actions.setTripSummariesError(errorCode));
    yield put(actions.setPredictionError());
    Logger.debug(e);
  }
}

function* setUpFlightShopParams(
  fetchTripSummaries: actions.IFetchTripSummariesV3
) {
  const state: IStoreState = yield select();
  const history = fetchTripSummaries.history;

  let {
    departureDate,
    returnDate,
    origin,
    destination,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
    fareClass,
  } = getExistingStateVariables(state);
  const parsedQueryString = parseQueryString(history) as IFlightShopParsedQuery;
  let destinationToSearch: ITripTerminus | null = destination;
  let originToSearch: ITripTerminus | null = origin;
  if (
    !origin ||
    !destination ||
    // note: when it's from FlightWatch, it should treat parsedQueryString as the source of truth.
    parsedQueryString.isFromFlightWatch ||
    (origin &&
      parsedQueryString.origin &&
      origin.id.code.code !== parsedQueryString.origin) ||
    (destination &&
      parsedQueryString.destination &&
      destination.id.code.code !== parsedQueryString.destination)
  ) {
    const { correspondingDestination, correspondingOrigin } =
      yield fetchOriginDestination(parsedQueryString);
    destinationToSearch = correspondingDestination;
    originToSearch = correspondingOrigin;
  }

  origin = originToSearch ?? origin;
  destination = destinationToSearch ?? destination;
  tripCategory = parsedQueryString.tripCategory ?? tripCategory;
  departureDate = parsedQueryString.departureDate ?? departureDate;
  if (tripCategory === TripCategory.ONE_WAY) {
    returnDate = null;
  } else {
    returnDate = parsedQueryString.returnDate ?? returnDate;
  }

  stopsOption =
    (parsedQueryString.stopsOption as SliceStopCountFilter) ?? stopsOption;
  noLCC = parsedQueryString.noLCC ?? noLCC;
  adultsCount = parsedQueryString.adultsCount || adultsCount;
  childrenCount = parsedQueryString.childrenCount || childrenCount;
  infantsInSeatCount =
    parsedQueryString.infantsInSeatCount || infantsInSeatCount;
  infantsOnLapCount = parsedQueryString.infantsOnLapCount || infantsOnLapCount;
  fareClass = parsedQueryString.fareClass || fareClass;
  fareClass = typeof fareClass === "string" ? [fareClass] : fareClass;
  const flightShopEntryPoint = parsedQueryString.flightShopEntryPoint;

  // If we are missing the data we need from both the state and the query params
  // we should redirect the user home.
  if (
    shouldRedirect({
      departureDate,
      origin,
      destination,
      returnDate,
      tripCategory,
      stopsOption,
    })
  ) {
    yield putResolve(
      setMobileSearchProgress(MobileFlightSearchStep.LocationSearch)
    );
    fetchTripSummaries.history.push(PATH_HOME);
    return;
  }
  // Order here matters because of the reducer structure.
  // Set trip category resets the returnDate field.
  yield putResolve(searchActions.setTripCategory(tripCategory));
  yield putResolve(searchActions.setOrigin(origin));
  yield putResolve(searchActions.setDestination(destination));
  yield putResolve(searchActions.setDepartureDate(departureDate));
  yield putResolve(searchActions.setReturnDate(returnDate));
  yield putResolve(searchActions.setStopsOption(stopsOption));
  yield putResolve(
    searchActions.setPassengerCounts({
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    })
  );
  yield putResolve(
    searchActions.setFareclassOptionFilter({
      basic: fareClass.includes("basic") || false,
      standard: fareClass.includes("standard") || noLCC,
      enhanced: fareClass.includes("enhanced") || noLCC,
      premium: fareClass.includes("premium") || noLCC,
      luxury: fareClass.includes("luxury") || noLCC,
    })
  );

  if (parsedQueryString.isFromFlightWatch) {
    trackEvent({
      eventName: WATCH_ENTRY,
      properties: {
        advance: dayjs(departureDate).diff(dayjs(), "days"),
        departure_date: departureDate ?? "",
        return_date: returnDate ?? undefined,
        origin: origin?.id.code.code ?? "",
        destination: destination?.id.code.code ?? "",
        trip_type: tripCategory,
        filter_type: getWatchFilterTypeFromFlightShopParams(stopsOption, noLCC),
      } as WatchEntryProperties,
    });
  }
  if (flightShopEntryPoint) {
    yield putResolve(actions.setFlightShopEntryPoint(flightShopEntryPoint));
  }

  return {
    departureDate,
    returnDate,
    origin,
    destination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
    fareClass,
  };
}

function* fetchOriginDestination(parsedQueryString: IFlightShopParsedQuery) {
  const originRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.origin,
  };

  const { categories: originCategories }: IResponse =
    yield fetchLocationAutocomplete(originRequestBody);
  const correspondingOrigin = originCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find(
      (result) => result.id.code.code === parsedQueryString.origin
    ) as ITripTerminus;

  const destinationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.destination,
  };

  const { categories: destinationCategories }: IResponse =
    yield fetchLocationAutocomplete(destinationRequestBody);
  const correspondingDestination = destinationCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find(
      (result) => result.id.code.code === parsedQueryString.destination
    ) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
