import { put, putResolve, select } from "redux-saga/effects";
import queryStringParser from "query-string";
import { History } from "history";
import { FlightBookType } from "redmond";

import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { GET_PRICE_FREEZE_OFFER } from "../actions/constants";
import {
  setUpFlightFreezeParamsCallStateSucceeded,
  setUpFlightFreezeParamsCallStateFailed,
} from "../actions/actions";
import { getPriceFreezeOfferSaga } from "./getPriceFreezeOfferSaga";
import { setUpFlightBookParamsSaga } from "../../book/sagas/session/setUpFlightBookParamsSaga";
import { SET_UP_FLIGHT_BOOK_PARAMS } from "../../book/actions/constants";
import { setFlightBookType } from "../../book/actions/actions";
import { getPriceFreezeCustomOffer, getPriceFreezeOfferWithSuggested } from "../../shop/reducer/selectors";
import { PATH_HOME } from "../../../utils/urlPaths";
import { IStoreState } from "../../../reducers/types";

export function* setUpFlightFreezeParamsSaga(
  action: actions.ISetUpFlightFreezeParams
) {
  try {
    yield putResolve(setFlightBookType(FlightBookType.PRICE_FREEZE_PURCHASE));

    yield setUpFlightBookParamsSaga({
      history: action.history,
      type: SET_UP_FLIGHT_BOOK_PARAMS,
      flightBookType: FlightBookType.PRICE_FREEZE_PURCHASE,
    });

    const state: IStoreState = yield select();
    const { offerId } = populateFlightFreezeQueryParametersFromState(
      state,
      action.history
    );

    if (!offerId) {
      action.history.push(PATH_HOME);
      return;
    }

    yield getPriceFreezeOfferSaga({
      offerId: offerId,
      history: action.history,
      type: GET_PRICE_FREEZE_OFFER,
    });

    yield put(setUpFlightFreezeParamsCallStateSucceeded());
  } catch (e) {
    Logger.debug(e);
    yield put(setUpFlightFreezeParamsCallStateFailed());
  }
}

interface IFlightFreezeParsedQuery {
  offerId: string;
}

const populateFlightFreezeQueryParametersFromState = (
  state: IStoreState,
  history: History
) => {
  const { offerId } = getExistingStateVariables(state);

  const queryString = parseQueryString(history);

  const newQuery = {
    offerId: queryString.offerId || offerId,
  };

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  history.replace({
    pathname: history.location.pathname,
    state: history.location.state,
    search: queryStringParser.stringify({
      ...parsedQueryStringPrimitive,
      ...newQuery,
    }),
  });

  return newQuery;
};

export function getExistingStateVariables(state: IStoreState) {
  const offerIdFromCustom: string | undefined = getPriceFreezeCustomOffer(state)?.id;
  const offerId = offerIdFromCustom ? offerIdFromCustom : getPriceFreezeOfferWithSuggested(state)?.offer.id;
  return {
    offerId,
  };
}

export const parseQueryString = (
  history: History
): IFlightFreezeParsedQuery => {
  const queryString = history?.location?.search || "";

  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    offerId: parsedQueryStringPrimitive.offerId as string,
  };
};
