import React from "react";
import { Prices } from "redmond";
import { getPriceFreezeRewardsString as getRewardsString } from "../../../utils/helpers";
import { getFormattedTotalPriceText } from "../helpers";

export const CfarAttachedContent = ({
  cfarOfferPrices,
  rewardsKey,
}: {
  cfarOfferPrices: Prices | undefined;
  rewardsKey: string | undefined;
}) => {
  const cfarRewardsString =
    cfarOfferPrices != null
      ? getRewardsString(cfarOfferPrices.rewards, rewardsKey)
      : undefined;
  return (
    <>
      {cfarOfferPrices != null ? (
        <span>
          <span className="bold">Cancel for any reason</span> added for{" "}
          <span className="bold">
            {getFormattedTotalPriceText(cfarOfferPrices.fiat)}
          </span>
          {cfarRewardsString != null && cfarRewardsString != ""
            ? ` / ${cfarRewardsString} `
            : " "}
          per traveler.
        </span>
      ) : null}
    </>
  );
};
