import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { MobileFlightSearchV2ConnectorProps } from "./container";
import "./styles.scss";
import {
  MobileSearchFieldButton,
  Icon,
  IconName,
  DatePickerButton,
  MobileOutlinedTextInput,
  MobilePopoverCard,
  BannerSeverity,
  NotificationBanner,
  FlightCategoryRadio,
  ActionLink,
  CloseButtonIcon,
} from "halifax";
import { MobileLocationSearchModal } from "./components/MobileLocationSearchModal";
import { FlightSearchButton } from "../SearchButton";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import { MobileCalendarPickerModal } from "./components/MobileCalendarPickerModal";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { PassengerCountPicker } from "../FlightSearchControl/components/PassengerCountPicker";
import { ITripTerminus, RecentFlightSearch, TripCategory } from "redmond";

export interface IMobileFlightSearchControlV2Props
  extends MobileFlightSearchV2ConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
}

export const MobileFlightSearchControlV2 = (
  props: IMobileFlightSearchControlV2Props
) => {
  const {
    originCode,
    destinationCode,
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    origin,
    destination,
    departureDate,
    returnDate,
    history,
    numTravelers,
    populateFlightShopQueryParams,
    setTripCategory,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
  } = props;
  const [openOriginModal, setOpenOriginModal] = useState(false);
  const [openDestinationModal, setOpenDestinationModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    useState(false);
  useEffect(() => {
    if (originCode && destinationCode) {
      fetchDepartureCalendar(
        origin as ITripTerminus,
        destination as ITripTerminus
      );
    } else {
      setCalendar();
    }
  }, [originCode, destinationCode, tripCategory]);

  const numTravelerString =
    numTravelers === 1
      ? `${numTravelers} Traveler`
      : `${numTravelers} Travelers`;

  const isReadyToSearch =
    !!destination &&
    !!origin &&
    !!departureDate &&
    (tripCategory === TripCategory.ROUND_TRIP ? !!returnDate : true);
  useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [destination, origin, departureDate, tripCategory, returnDate]);

  const handleSearchClick = () => {
    isReadyToSearch
      ? populateFlightShopQueryParams({
          history,
          useHistoryPush: true,
          forceQueryUpdate: false,
        })
      : setHasMissingSearchInfoError(true);
    onSelectDates?.(departureDate, returnDate);
  };

  return (
    <Box className="mobile-flight-search-v2">
      <MobilePopoverCard
        open={openPassengerCountPicker}
        className={clsx("mobile-passenger-count-picker-popup", "b2b")}
        contentClassName="mobile-passenger-count-picker-popup-container"
        onClose={() => setOpenPassengerCountPicker(false)}
        centered={true}
        topRightButton={
          <ActionLink
            className="modal-close-button"
            onClick={() => setOpenPassengerCountPicker(false)}
            content={<CloseButtonIcon className="close-button-icon" />}
            label="Close"
            showTappableArea
          />
        }
      >
        <PassengerCountPicker
          minimumAdultsCount={1}
          onClickApply={() => setOpenPassengerCountPicker(false)}
          className="b2b"
        />
      </MobilePopoverCard>
      <FlightCategoryRadio
        setTripCategory={setTripCategory}
        selectedCategory={tripCategory}
      />
      <MobileOutlinedTextInput
        className={clsx("num-traveler-count", "mobile")}
        prefixString={numTravelerString}
        icon={faUser}
        showCloseIcon={false}
        customEndIcon={<Icon name={IconName.B2BEditPencil} />}
        onClick={() => setOpenPassengerCountPicker(true)}
        onChange={() => {}}
        value={""}
      />
      <MobileSearchFieldButton
        value={origin?.label}
        label={textConstants.WHERE_FROM}
        icon={<Icon name={IconName.B2BMapPin} />}
        renderPopup={() => (
          <MobileLocationSearchModal
            openLocationModal={openOriginModal}
            handleClose={() => setOpenOriginModal(false)}
            searchType="origin"
            setOpenLocationModal={setOpenOriginModal}
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
            onSelectLocation={onSelectLocation}
          />
        )}
        onClick={() => setOpenOriginModal(true)}
        isMissingSearchInfo={hasMissingSearchInfoError && !origin}
      />
      <MobileSearchFieldButton
        value={destination?.label}
        label={textConstants.WHERE_TO}
        icon={<Icon name={IconName.B2BMapPin} />}
        renderPopup={() => (
          <MobileLocationSearchModal
            openLocationModal={openDestinationModal}
            handleClose={() => setOpenDestinationModal(false)}
            searchType="destination"
            setOpenLocationModal={setOpenDestinationModal}
            history={history}
            onSelectLocation={onSelectLocation}
          />
        )}
        onClick={() => setOpenDestinationModal(true)}
        isMissingSearchInfo={hasMissingSearchInfoError && !destination}
      />
      {!departureDate ||
      (tripCategory === TripCategory.ROUND_TRIP && !returnDate) ? (
        <MobileSearchFieldButton
          label={textConstants.CHOOSE_DATE}
          icon={<Icon name={IconName.Calendar} />}
          renderPopup={() => (
            <MobileCalendarPickerModal
              openCalendarModal={openCalendarModal}
              setOpenCalendarModal={setOpenCalendarModal}
              handleIncompleteSearch={setHasMissingSearchInfoError}
              calendarTripType={tripCategory}
            />
          )}
          onClick={() => setOpenCalendarModal(true)}
          isMissingSearchInfo={
            hasMissingSearchInfoError &&
            (!departureDate || tripCategory === TripCategory.ROUND_TRIP
              ? !returnDate
              : true)
          }
        />
      ) : (
        <DatePickerButton
          startDate={departureDate}
          endDate={returnDate}
          startLabel={"Outbound"}
          endLabel={"Return"}
          classes={["flights-inline-juncture-input"]}
          renderCalendarPopup={() => (
            <MobileCalendarPickerModal
              openCalendarModal={openCalendarModal}
              setOpenCalendarModal={setOpenCalendarModal}
              handleIncompleteSearch={setHasMissingSearchInfoError}
              calendarTripType={tripCategory}
            />
          )}
          onClick={() => setOpenCalendarModal(true)}
          dateFormat={"ddd, MMM D"}
          hideEndDate={tripCategory === TripCategory.ONE_WAY}
        />
      )}

      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className={clsx("missing-info-search-error-banner")}
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
      <FlightSearchButton
        className={clsx("mobile-flights-search-control-button", "b2b")}
        message={textConstants.SEARCH_FLIGHTS}
        onClick={handleSearchClick}
        enabled
      />
    </Box>
  );
};
