import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceFreezeEntryInfo } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../../book/actions/actions";
import {
  isPriceFreezeDurationActiveSelector,
  isPriceFreezeDurationDefault12HrCopyActiveSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  isPriceFreezeDurationNewCopyActiveSelector,
  changedPriceFreezeDurationPropertiesSelector,
  priceFreezeMinOfferSelector,
  priceFreezeMiddleOfferSelector,
  priceFreezeDefaultDurationsSelector,
  priceFreezeOfferDataLengthSelector,
  isCheapestPriceFreezeOfferSelector,
  isPriceFreezeShowDurationsVariant1Selector,
  customPriceFreezeOfferSelector,
} from "../../../../freeze/reducer/selectors";
import {
  flightShopProgressSelector,
  priceDropProtectionCandidateIdSelector,
} from "../../../reducer/selectors";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../rewards/reducer";
import { FlightShopStep } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  const currentFlightShopStep = flightShopProgressSelector(state);
  const isPriceFreezeFlightDetailsPopupEnabled = !(
    currentFlightShopStep === FlightShopStep.ReviewItinerary
  );

  return {
    isPriceFreezeDurationActive: isPriceFreezeDurationActiveSelector(state),
    isPriceFreezeDurationNewCopyActive:
      isPriceFreezeDurationNewCopyActiveSelector(state),
    isPriceFreezeDurationDefault12HrCopyActiveSelector:
      isPriceFreezeDurationDefault12HrCopyActiveSelector(state),
    isPriceFreezeDurationPopupEnabled:
      isPriceFreezeDurationPopupEnabledSelector(state),
    /**
     * We want to have different behaviour from the Continue button depending on the `FlightShopStep` the customer is on.
     * We shouldn't show the `PriceFreezeFlightDetailsModal` on continue if the customer is on ReviewItinerary as then have already actively selected their flight.
     */
    isPriceFreezeFlightDetailsPopupEnabled,
    priceDropProtectionCandidateId:
      priceDropProtectionCandidateIdSelector(state),
    changedPriceFreezeDurationProperties:
      changedPriceFreezeDurationPropertiesSelector(state),
    priceFreezeMinOffer: priceFreezeMinOfferSelector(state),
    selectedRewardsAccountId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    priceFreezeMiddleOffer: priceFreezeMiddleOfferSelector(state),
    /**
     * This is the current price freeze offer that is ready to purchase.
     * This data will be used to populate the PriceFreezeExplanationModal.
     */
    customPriceFreezeOffer: customPriceFreezeOfferSelector(state),
    /**
     * We have different behaviour in the modal details depending on the `FlightShopStep`.
     * If a customer is on ReviewItinerary, we'll update the modal with the actual offer they've selected in the radio buttons.
     */
    currentFlightShopStep,
    priceFreezeDefaultDurationsVariant:
      priceFreezeDefaultDurationsSelector(state),
    priceFreezeOfferDataLength: priceFreezeOfferDataLengthSelector(state),
    isCheapestPriceFreezeOffer: isCheapestPriceFreezeOfferSelector(state),
    isPriceFreezeShowDurationsVariant1Active:
      isPriceFreezeShowDurationsVariant1Selector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeEntryInfoConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeEntryInfo = withRouter(
  connector(PriceFreezeEntryInfo)
);
