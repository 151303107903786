import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { Dispatch } from "@reduxjs/toolkit";
import {
  Cap,
  FiatPrice,
  Prediction,
  PriceDropViewedProperties,
  RewardsPrice,
  TripCategory,
  ViewedFlightListProperties,
  ViewedPriceFreezeProperties,
  Airport,
  TimeToLive,
  TripDetails,
} from "redmond";
import { IPriceFreezeOfferEntries } from "halifax";
import { isCorpTenant } from "@capone/common";

import { DesktopPricePrediction } from "./component";
import {
  allTripSummariesSelector,
  getViewedFlightListProperties,
  isWatchingSelector,
  maxFlightPriceSelector,
  predictionLoadingSelector,
  predictionSelector,
  getPriceFreezeOffer,
  getPriceFreezeOfferFiat,
  getPriceFreezeOfferRewards,
  getPriceFreezeOfferCap,
  getPriceFreezeOfferCheapestTripTripId,
  airportsSelector,
  tripDetailsSelector,
} from "../../../../reducer/selectors";

import { IStoreState } from "../../../../../../reducers/types";
import {
  getActiveFiltersCount,
  getHasSetFareClassFilter,
  getHasSetStopsOption,
  getTripCategory,
  hasUnsupportedPredictionFilters,
  initialFilterOptions,
} from "../../../../../search/reducer";
import { actions } from "../../../../../search/actions";
import { actions as shopActions } from "../../../../actions";
import {
  IDeleteWatch,
  ISetOpenFlightShopCalendarDesktop,
} from "../../../../actions/actions";
import {
  ITripSummariesById,
  getPriceFreezeOfferCheapestTripFareId,
  getPriceFreezeOfferDuration,
  getViewedPriceFreezeProperties,
  getPriceFreezeCheapestFrozenPrice,
  isPriceFreezeOfferIncludedInShopSummarySelector,
  tripDetailsLoadingSelector,
  getPriceDropProperties,
} from "../../../../reducer";
import {
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../../../rewards/reducer";
import { PricePredictionGradientVariantType } from "../../../../../../context/experiments";
import { pricePredictionGradientVariantSelector } from "../../../../reducer/selectors/experiments";
import { config } from "../../../../../../api/config";
import { isCheapestPriceFreezeOfferSelector } from "../../../../../freeze/reducer";

const mapStateToProps = (state: IStoreState): IStateProps => {
  const tripDetails = tripDetailsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );

  const airports = airportsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );
  return {
    prediction: predictionSelector(state),
    tripCategory: getTripCategory(state),
    hasUnsupportedPredictionFilters: hasUnsupportedPredictionFilters(state),
    hasPredictionFilters:
      getHasSetStopsOption(state) || getHasSetFareClassFilter(state),
    data: {
      maxFlightPrice: maxFlightPriceSelector(state),
    },
    isWatching: isWatchingSelector(state),
    tripSummaries: allTripSummariesSelector(state),
    selectedRewardsAccountId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    predictionLoading: predictionLoadingSelector(state),
    viewedForecastProperties: getViewedFlightListProperties(state),
    viewedPriceFreezeProperties: getViewedPriceFreezeProperties(state),
    priceDropViewedProperties: getPriceDropProperties(state),
    priceFreezeOffer: getPriceFreezeOffer(state),
    priceFreezeFiat: getPriceFreezeOfferFiat(state),
    priceFreezeRewards: getPriceFreezeOfferRewards(state),
    priceFreezeCap: getPriceFreezeOfferCap(state),
    fareId: getPriceFreezeOfferCheapestTripFareId(state),
    tripDetails,
    airports,
    priceFreezeDuration: getPriceFreezeOfferDuration(state),
    cheapestFrozenPrice: getPriceFreezeCheapestFrozenPrice(state),
    isPriceFreezeOfferIncludedInShopSummary:
      isPriceFreezeOfferIncludedInShopSummarySelector(state),
    isTripDetailsLoading: tripDetailsLoadingSelector(state),
    pricePredictionGradientVariant:
      pricePredictionGradientVariantSelector(state),
    hasActiveFilters: getActiveFiltersCount(state) > 0,
    canRedeemRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
    isCheapestPriceFreezeOffer: isCheapestPriceFreezeOfferSelector(state),
  };
};

interface IStateProps {
  data: { maxFlightPrice: number };
  prediction: Prediction | null;
  tripCategory: TripCategory;
  hasUnsupportedPredictionFilters: boolean;
  hasPredictionFilters: boolean;
  isWatching: boolean;
  predictionLoading: boolean | null;
  tripSummaries: ITripSummariesById;
  selectedRewardsAccountId: string | undefined;
  viewedForecastProperties: ViewedFlightListProperties | null;
  priceFreezeOffer: IPriceFreezeOfferEntries | null;
  priceFreezeFiat: FiatPrice | undefined;
  priceFreezeRewards: { [key: string]: RewardsPrice } | null | undefined;
  tripDetails: TripDetails;
  fareId: string;
  priceFreezeCap: Cap | undefined;
  priceDropViewedProperties: Omit<PriceDropViewedProperties, "page">;
  priceFreezeDuration: TimeToLive | undefined;
  viewedPriceFreezeProperties: ViewedPriceFreezeProperties | null;
  airports: { [key: string]: Airport };
  cheapestFrozenPrice:
    | { fiat: FiatPrice; rewards: RewardsPrice | undefined }
    | undefined;
  isPriceFreezeOfferIncludedInShopSummary: boolean;
  isTripDetailsLoading: boolean | null;
  pricePredictionGradientVariant:
    | PricePredictionGradientVariantType
    | undefined;
  hasActiveFilters: boolean;
  canRedeemRewards: boolean;
  isCheapestPriceFreezeOffer: boolean,
}

interface IDispatchProps {
  resetFilters: () => void;
  deleteWatch: () => IDeleteWatch;
  setOpenFlightShopCalendarDesktop: (
    openFlightShopCalendarDesktop: boolean
  ) => ISetOpenFlightShopCalendarDesktop;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  deleteWatch: () => dispatch(shopActions.deleteWatch()),
  resetFilters: () => {
    dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
    dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(actions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(actions.setPolicyFilter(initialFilterOptions.policyFilter));
    dispatch(
      actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
    );
    dispatch(
      actions.setOutboundArrivalTimeRange(
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
    dispatch(
      actions.setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnDepartureTimeRange(
        initialFilterOptions.returnDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnArrivalTimeRange(
        initialFilterOptions.returnArrivalTimeRange
      )
    );
    dispatch(
      actions.setFareclassOptionFilter(
        initialFilterOptions.fareclassOptionFilter
      )
    );
    dispatch(actions.setApplyUserFlightPreferences(false));
    dispatch(actions.setUserPreferencesNotAvailable(false));
  },
  setOpenFlightShopCalendarDesktop: (openFlightShopCalendarDesktop: boolean) =>
    dispatch(
      shopActions.setOpenFlightShopCalendarDesktop(
        openFlightShopCalendarDesktop
      )
    ),
});

interface IOwnProps {
  hidden?: boolean;
  highlightedPriceFreezeBox?: boolean;
  highlightedPriceFreezeButton?: boolean;
  showPriceFreezeIcon?: boolean;
  highlightedRewards?: boolean;
  useLockPriceLanguage?: boolean;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    resetFilters: () => dispatchProps.resetFilters(),
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type DesktopPricePredictionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopPricePrediction = connector(
  withRouter(DesktopPricePrediction)
);
