import { connect, ConnectedProps } from "react-redux";
import { BookingInProgressModal } from "./component";
import {
  bookingInProgressSelector,
  getFinalizedItinerary,
  getFinalizedPriceFreeze,
  getIsWaitingPriceQuote,
  getFlightBookType,
  getSeatMapLoading,
  getIsSettingUpFlightFreezeParams,
  isRefetchingPriceQuoteSelector,
  getSchedulePaymentCallState,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => ({
  inProgress: bookingInProgressSelector(state),
  finalizedItinerary: getFinalizedItinerary(state),
  finalizedPriceFreeze: getFinalizedPriceFreeze(state),
  isWaitingPriceQuote: getIsWaitingPriceQuote(state),
  isRefetchingPriceQuote: isRefetchingPriceQuoteSelector(state),
  schedulePaymentCallState: getSchedulePaymentCallState(state),
  isSettingUpFlightFreezeParams: getIsSettingUpFlightFreezeParams(state),
  flightBookType: getFlightBookType(state),
  seatMapLoading: getSeatMapLoading(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingInProgressModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingInProgressModal = connector(
  BookingInProgressModal
);
