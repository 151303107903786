import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { FlightTravelWalletDetailsBanner as TravelWalletDetailsBanner } from "./component";

import {
  getCreditBreakdown,
  getTravelCreditsHistoryTransaction,
  getTravelWalletCredit,
} from "../../reducer";
import { getRewardsAccounts } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    credit: getTravelWalletCredit(state),
    creditBreakdown: getCreditBreakdown(state),
    creditsTransactionHistory: getTravelCreditsHistoryTransaction(state),
    rewardsAccounts: getRewardsAccounts(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelWalletDetailsBannerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedTravelWalletDetailsBanner = withRouter(
  connector(TravelWalletDetailsBanner)
);
