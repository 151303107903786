import axios, { AxiosResponse } from "axios";
import { config } from "../../config";

import { AvailabilityRequest, AvailabilityResponse } from "redmond";
import { hotelAvailabilityApiPrefix } from "../paths";

export const fetchCrossSellHotelAvailability = (
  body: AvailabilityRequest
): Promise<AvailabilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<AvailabilityResponse> = await axios.post(hotelAvailabilityApiPrefix, body, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
