import React, { useState } from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import {
  PriceDropProtection as PriceDropProtectionCard,
  PriceDropProtectionImage,
  MobilePriceDropProtectionImage,
  Icon,
  IconName,
  MobilePopoverCard,
  CloseButtonIcon,
  ActionLink,
  useDeviceTypes,
  ButtonWrap,
} from "halifax";
import {
  PriceDropProtection as IPriceDropProtection,
  PRICE_DROP_PROTECTION_BODY,
} from "redmond";
import {
  AVAILABLE,
  PRICE_DROP_CREDIT,
  useExperiments,
  getExperimentVariant,
} from "../../../../context/experiments";

import clsx from "clsx";

import "./styles.scss";
import {
  PDP_TITLE,
  PDP_CTA_TEXT,
  PDP_HEADER,
  getSubtitle,
  FREE_PRICE_DROP_PROTECTION_INCLUDED,
  FREE_PRICE_DROP_PROTECTION_SUBTITLE,
  VIEW_PRICE_DROP_ARIA_LABEL,
  getMaxRefund,
} from "./textConstants";
import { PATH_PRICE_DROP_PROTECTION } from "../../../../../../terms-module/src/utils/paths";

export interface IPriceDropProtectionProps {
  priceDropProtection: IPriceDropProtection;
  onClick?: () => void;
  hideSubtitle?: boolean;
  className?: string;
}

export const PriceDropProtection = ({
  priceDropProtection,
  onClick,
  hideSubtitle,
  className,
}: IPriceDropProtectionProps) => {
  const [priceDropPredictionModalOpen, setPriceDropPredictionModalOpen] =
    useState(false);
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const priceDropCreditGroup = getExperimentVariant(
    expState.experiments,
    PRICE_DROP_CREDIT
  );
  const isPriceDropCreditEnabled = priceDropCreditGroup === AVAILABLE;

  const renderPriceDropProtectionPopover = React.useCallback(() => {
    if (matchesMobile) {
      return (
        <MobilePopoverCard
          role="tooltip"
          id="price-drop-protection-modal"
          topRightButton={
            <ActionLink
              className="price-drop-protection-close-button"
              onClick={() => setPriceDropPredictionModalOpen(false)}
              content={<CloseButtonIcon className="close-button-icon" />}
              label="Close"
              showTappableArea={true}
            />
          }
          open={priceDropPredictionModalOpen}
          className="mobile-price-drop-protection-popup"
          contentClassName="mobile-flight-price-drop-protection-content-wrapper"
          onClose={() => setPriceDropPredictionModalOpen(false)}
        >
          <PriceDropProtectionCard
            className="price-drop-protection-card"
            image={MobilePriceDropProtectionImage}
            title={PDP_TITLE}
            subtitle={getSubtitle(
              priceDropProtection,
              isPriceDropCreditEnabled
            )}
            header={PDP_HEADER}
            ctaText={PDP_CTA_TEXT}
            isMobile={true}
            mobileTermsConditionCopy={PRICE_DROP_PROTECTION_BODY}
          />
        </MobilePopoverCard>
      );
    } else {
      return (
        <Dialog
          role="tooltip"
          id="price-drop-protection-modal"
          open={priceDropPredictionModalOpen}
          className="price-drop-protection-popup"
          /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
          TransitionProps={{ role: "none" } as never}
          PaperProps={{
            /* eslint-disable */
            // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
            tabIndex: 0,
            /* eslint-enable */
          }}
          onClose={() => setPriceDropPredictionModalOpen(false)}
        >
          <Box className="price-drop-protection-wrapper">
            <PriceDropProtectionCard
              className="price-drop-protection-card"
              image={PriceDropProtectionImage}
              title={PDP_TITLE}
              onClick={() =>
                window.open(`${PATH_PRICE_DROP_PROTECTION} `, "_blank")
              }
              onClose={() => setPriceDropPredictionModalOpen(false)}
              subtitle={getSubtitle(
                priceDropProtection,
                isPriceDropCreditEnabled
              )}
              header={PDP_HEADER}
              ctaText={PDP_CTA_TEXT}
            />
          </Box>
        </Dialog>
      );
    }
  }, [matchesMobile, priceDropPredictionModalOpen, priceDropProtection]);

  return (
    <Box
      className={clsx("price-drop-protection-root", className, {
        mobile: matchesMobile,
      })}
    >
      <Box className="price-drop-protection-container">
        <ButtonWrap
          onClick={() => {
            onClick && onClick();
            setPriceDropPredictionModalOpen(true);
          }}
          aria-labelledby="price-drop-protection-modal"
          className="pdp-modal-link"
        >
          <Typography className="pdp-modal-link-copy">
            <strong>{FREE_PRICE_DROP_PROTECTION_INCLUDED} </strong>
            <span className="pdp-modal-icon">
              <Icon
                name={IconName.InfoCircle}
                ariaLabel={VIEW_PRICE_DROP_ARIA_LABEL}
              />
            </span>
          </Typography>
        </ButtonWrap>
        {!hideSubtitle && (
          <Typography className="pdp-subtitle" variant="body2">
            {FREE_PRICE_DROP_PROTECTION_SUBTITLE(
              getMaxRefund(priceDropProtection),
              isPriceDropCreditEnabled
            )}
          </Typography>
        )}
        {renderPriceDropProtectionPopover()}
      </Box>
    </Box>
  );
};
