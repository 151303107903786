import React, { useEffect, useState, useMemo } from "react";
import {
  ActionLink,
  B2BSpinner,
  CloseButtonIcon,
  FlightCategoryRadio,
  LoadingPopup,
  MobilePopoverCard,
  PassengerCountPickerType,
} from "halifax";
import { LocationSearch } from "../../../search/components/MobileFlightSearchControlV3/components";
import { Box, Typography } from "@material-ui/core";
import "./styles.scss";
import clsx from "clsx";
import {
  FlightShopStep,
  IPassengerCounts,
  ITripTerminus,
  TripCategory,
} from "redmond";
import { MobileEditLocationModalConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { MulticityFlightShopStep } from "../../reducer";
import * as textConstants from "./textConstants";
import { MulticityRoute } from "../../../search/reducer";
import dayjs from "dayjs";

export interface IMobileEditLocationModalProps
  extends MobileEditLocationModalConnectorProps,
    RouteComponentProps {
  openLocationModal: boolean;
  setOpenLocationModal: (arg: boolean) => void;
  setOpenCalendarModal: (arg: boolean) => void;
  setCalendarTripType: (arg: TripCategory) => void;
  isMultiCityEnabled?: boolean;
  isAirCXV3Experiment?: boolean;
}
export const MobileEditLocationModal = (
  props: IMobileEditLocationModalProps
) => {
  const {
    openLocationModal,
    setOpenLocationModal,
    history,
    tripCategory,
    setTripCategory,
    origin,
    setOrigin,
    destination,
    setDestination,
    departureDate,
    returnDate,
    passengersCount,
    setPassengerCounts,
    populateFlightShopQueryParams,
    setOpenCalendarModal,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    isMultiCityEnabled,
    resetAll,
    fetchDepartureCalendar,
    setCalendarTripType,
    resetFlightShopProgress,
    resetMulticityFlightShopProgress,
    departureDateBucketsLoading,
    setMulticityOrigin,
    setMulticityDestination,
    setMulticityDepartureDate,
    multicityRoutes,
    setAllMulticityRoutes,
    isAirCXV3Experiment,
  } = props;
  const [hasChangedSearchParams, setHasChangedSearchParams] = useState(false);
  const [currTripCategory, setCurrTripCategory] =
    useState<TripCategory>(tripCategory);
  const [currOrigin, setCurrOrigin] = useState<ITripTerminus | null>(origin);
  const [currDestination, setCurrDestination] = useState<ITripTerminus | null>(
    destination
  );
  const [initialMulticityRoutes, setInitialMulticityRoutes] =
    useState(multicityRoutes);
  const [currPassengersCount, setCurrPassengersCount] =
    useState<PassengerCountPickerType>(passengersCount);

  useEffect(() => {
    setCurrTripCategory(tripCategory);
  }, [tripCategory]);

  useEffect(() => {
    setCurrOrigin(origin);
  }, [origin]);

  useEffect(() => {
    setCurrDestination(destination);
  }, [destination]);

  useEffect(() => {
    setCurrPassengersCount(passengersCount);
  }, [adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount]);

  const currNumTravelers = useMemo(() => {
    return (
      (currPassengersCount as IPassengerCounts).adultsCount +
      (currPassengersCount as IPassengerCounts).childrenCount +
      (currPassengersCount as IPassengerCounts).infantsInSeatCount +
      (currPassengersCount as IPassengerCounts).infantsOnLapCount
    );
  }, [currPassengersCount]);

  useEffect(() => {
    if (
      tripCategory !== currTripCategory ||
      origin !== currOrigin ||
      destination !== currDestination ||
      adultsCount !== (currPassengersCount as IPassengerCounts).adultsCount ||
      childrenCount !==
        (currPassengersCount as IPassengerCounts).childrenCount ||
      infantsInSeatCount !==
        (currPassengersCount as IPassengerCounts).infantsInSeatCount ||
      infantsOnLapCount !==
        (currPassengersCount as IPassengerCounts).infantsOnLapCount
    ) {
      setHasChangedSearchParams(true);
    } else {
      setHasChangedSearchParams(false);
    }
  }, [
    tripCategory,
    currTripCategory,
    origin,
    currOrigin,
    destination,
    currDestination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    currNumTravelers,
    currPassengersCount,
  ]);

  //
  const isMulticity = currTripCategory === TripCategory.MULTI_CITY;

  const isAllFieldsPopulated = (routes: MulticityRoute[]) => {
    return routes.every((r) =>
      Object.values(r).every((field) => field !== null)
    );
  };

  const isAllMulticityFieldsPopulated = isAllFieldsPopulated(multicityRoutes);

  useEffect(() => {
    if (
      !isAllFieldsPopulated(initialMulticityRoutes) &&
      isAllMulticityFieldsPopulated
    )
      setInitialMulticityRoutes(multicityRoutes);
  }, [isAllMulticityFieldsPopulated]);

  // if MC values empty, populate the MC flights based on OW/RT origin & destination values
  const mcRoute1 = multicityRoutes[0];
  const mcRoute2 = multicityRoutes[1];

  useEffect(() => {
    if (!mcRoute1?.origin) setMulticityOrigin(origin, 0);
    if (!mcRoute2?.destination) setMulticityDestination(origin, 1);
    setInitialMulticityRoutes(multicityRoutes);
  }, [origin]);

  useEffect(() => {
    if (!mcRoute1?.destination) setMulticityDestination(destination, 0);
    if (!mcRoute2?.origin) setMulticityOrigin(destination, 1);
    setInitialMulticityRoutes(multicityRoutes);
  }, [destination]);

  useEffect(() => {
    if (departureDate && !mcRoute1?.departureDate)
      setMulticityDepartureDate(departureDate, 0);
    if (!mcRoute2?.departureDate) {
      if (returnDate) {
        setMulticityDepartureDate(returnDate, 1);
      } else {
        const nextDay = dayjs(departureDate)
          .add(dayjs.duration({ days: 1 }))
          .toDate();
        setMulticityDepartureDate(nextDay, 1);
      }
    }
    setInitialMulticityRoutes(multicityRoutes);
  }, [departureDate, returnDate]);

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">Flights</Typography>
        <ActionLink
          className="modal-close-button"
          onClick={() => {
            setAllMulticityRoutes(initialMulticityRoutes);
            setOpenLocationModal(false);
          }}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  const handleContinue = (passCountsToUse?: IPassengerCounts) => {
    const isMissingDates =
      !isMulticity &&
      (!departureDate ||
        (currTripCategory === TripCategory.ROUND_TRIP && !returnDate));
    const isChangedFromMulticity =
      tripCategory === TripCategory.MULTI_CITY && hasChangedSearchParams;

    // PROBLEM: if we set the tripcategory while reshop from MC > OW/RT we will dismount the MC shop funnel and redirect home
    // we still need to provide this context to the calendar to determine whether we are choosing 1, or 2 dates
    // we will instead read from this locallized state for the trip category as a temporary workaround
    setCalendarTripType(currTripCategory);
    setOpenLocationModal(false);

    const passengerCountChanged = // If user updated the num of travelers in the passenger count picker modal, do a new search on the modal CTA click
      passCountsToUse &&
      (adultsCount !== passCountsToUse?.adultsCount ||
        childrenCount !== passCountsToUse.childrenCount ||
        infantsInSeatCount !== passCountsToUse.infantsInSeatCount ||
        infantsOnLapCount !== passCountsToUse.infantsOnLapCount);
    // multicity route values are set in the LocationSearch component
    if (!isMulticity) {
      setOrigin(currOrigin);
      setDestination(currDestination);
    }

    // the multicity date selections are in the edit modal
    if (isMissingDates || isChangedFromMulticity) {
      setOpenCalendarModal(true);
      fetchDepartureCalendar(
        currOrigin as ITripTerminus,
        currDestination as ITripTerminus
      );
    } else if (
      !isMulticity &&
      !hasChangedSearchParams &&
      !passengerCountChanged
    ) {
      return;
    } else {
      // reset flights, sorting, filters, progress & selected trips
      resetAll(!isAirCXV3Experiment);
      isMulticity && setInitialMulticityRoutes(multicityRoutes);
      setTripCategory(currTripCategory);
      setPassengerCounts(
        passCountsToUse ? passCountsToUse : currPassengersCount
      );
      resetFlightShopProgress();
      resetMulticityFlightShopProgress();
      populateFlightShopQueryParams({
        history,
        useHistoryPush: false,
        forceQueryUpdate: true,
        newQueryParams: {
          flightShopProgress: !isMulticity
            ? FlightShopStep.ChooseDeparture
            : undefined,
          multicityFlightShopProgress: isMulticity
            ? MulticityFlightShopStep.ChooseDeparture0
            : undefined,
          tripCategory: currTripCategory,
        },
      });
    }
  };

  return (
    <MobilePopoverCard
      open={openLocationModal}
      onClose={() => {
        setAllMulticityRoutes(initialMulticityRoutes);
        setOpenLocationModal(false);
      }}
      headerElement={<MobileSearchPopoverHeader />}
      className={clsx("mobile-flight-shop-edit-location-popup")}
      fullScreen
    >
      <Box className="mobile-flight-shop-edit-location-pop-content">
        <FlightCategoryRadio
          setTripCategory={setCurrTripCategory}
          selectedCategory={currTripCategory}
          isMultiCityFlightsAvailable={isMultiCityEnabled}
        />
        <LocationSearch
          history={history}
          localOrigin={currOrigin}
          setLocalOrigin={setCurrOrigin}
          setLocalDestination={setCurrDestination}
          localDestination={currDestination}
          localPassengersCount={currPassengersCount}
          setLocalPassengersCount={setCurrPassengersCount}
          onComplete={handleContinue}
          localNumTravelers={currNumTravelers}
          currentTripCategory={currTripCategory}
          searchOnContinue
          paxCountApplyButtonText={textConstants.SEARCH_AGAIN}
          onPaxCountButtonClick={handleContinue}
        />
        <LoadingPopup
          indicatorSize={"small"}
          indicator={B2BSpinner}
          open={!!departureDateBucketsLoading}
          message="Updating your calendar"
        />
      </Box>
    </MobilePopoverCard>
  );
};
