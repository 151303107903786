import React, { useEffect, useMemo } from "react";
import {
  LoadingPopup,
  B2BSpinner,
  PoweredByHopper,
  useDeviceTypes,
} from "halifax";
import { CallState, FlightBookType } from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";
import { BookingInProgressModalConnectorProps } from "./container";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { VIEWED_FLIGHT_BOOK_PROCESSING } from "redmond";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  AVAILABLE,
  getExperimentVariant,
  PRICE_FREEZE,
  useExperiments,
} from "../../../../context/experiments";

export interface IBookingInProgressModalProps
  extends BookingInProgressModalConnectorProps {
  useHighZIndex?: boolean;
  hidePriceQuoteInProgress?: boolean;
}

export const BookingInProgressModal = ({
  inProgress,
  finalizedItinerary,
  // TODO: use finalizedPriceFreeze for tracking
  // finalizedPriceFreeze,
  isWaitingPriceQuote,
  isRefetchingPriceQuote,
  schedulePaymentCallState,
  useHighZIndex,
  isSettingUpFlightFreezeParams,
  flightBookType,
  seatMapLoading,
  hidePriceQuoteInProgress,
}: IBookingInProgressModalProps) => {
  const runtime = dayjs();
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();

  const priceFreezeGroup = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE
  );
  const isPriceFreeze = useMemo(() => {
    return priceFreezeGroup === AVAILABLE;
  }, [priceFreezeGroup]);

  useEffect(() => {
    switch (flightBookType) {
      case FlightBookType.DEFAULT:
        if (finalizedItinerary) {
          trackEvent({
            eventName: VIEWED_FLIGHT_BOOK_PROCESSING,
            properties: {
              runtime: dayjs().diff(runtime, "second"),
            },
          });
        }
        break;
      default:
        break;
    }
  }, [inProgress, finalizedItinerary, flightBookType]);

  const getMessage = () => {
    switch (flightBookType) {
      case FlightBookType.PRICE_FREEZE_EXERCISE:
      case FlightBookType.DEFAULT: {
        if (
          isRefetchingPriceQuote &&
          schedulePaymentCallState === CallState.InProcess
        ) {
          return textConstants.BOOK_YOUR_FLIGHT;
        } else if (isWaitingPriceQuote || seatMapLoading) {
          return textConstants.SAVING_TRAVELERS_AND_CHECKING_AVAILABILITY;
        }
        return textConstants.BOOK_YOUR_FLIGHT;
      }
      case FlightBookType.PRICE_FREEZE_PURCHASE: {
        if (schedulePaymentCallState === CallState.InProcess) {
          return textConstants.FREEZING_THE_PRICE;
        } else if (isSettingUpFlightFreezeParams) {
          return textConstants.CHECKING_AVAILABILITY;
        } else if (isWaitingPriceQuote) {
          return textConstants.SAVING_TRAVELERS_AND_CHECKING_AVAILABILITY;
        }
        return textConstants.FREEZING_THE_PRICE;
      }
      default:
        return "";
    }
  };

  return (
    <LoadingPopup
      classes={[
        clsx("flights-book-booking-in-progress-modal", {
          mobile: matchesMobile,
          "high-z-index": useHighZIndex,
        }),
      ]}
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={
        inProgress ||
        (isWaitingPriceQuote && !hidePriceQuoteInProgress) ||
        (isPriceFreeze &&
          flightBookType === FlightBookType.PRICE_FREEZE_PURCHASE &&
          isSettingUpFlightFreezeParams) ||
        seatMapLoading
      }
      popupSize={"small"}
      message={getMessage()}
      footer={PoweredByHopper}
      textAlign={"center"}
    />
  );
};
