import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { 
  chargeAgentBookingFee,
  ChargeAgentBookingFeeRequest,
  ChargeAgentBookingFeeResponse,
} from "../../../../api/v0/book/book-flow/chargeAgentBookingFee";
import {
  CHARGE_BOOKING_AGENT_FEE,
  ChargeBookingAgentFeeProperties,
} from "redmond";
import Logger from "../../../../helpers/Logger";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyAirProperties,
  getFinalizedItinerary,
} from "../../reducer";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { AGENT_FEE } from "../../components";

const getAgentUsername = (identifier: string) => {
  const regex = /(.*)@(.*)/gm;
  const subst = "$1";
  return identifier.replace(regex, subst);
};

export function* chargeAgentBookingFeeSaga({
  agentEmail,
}: actions.IChargeAgentBookingFee) {
  const state: IStoreState = yield select();
  const completeBuyAirProperties = getCompleteBuyAirProperties(state);

  try {
    const finalizedItinerary = getFinalizedItinerary(state);
    const agentUsername = getAgentUsername(agentEmail);

    const requestBody = {
      transactionStoreId: finalizedItinerary?.transactionStoreId!,
      initiator: {
        id: agentUsername,
        Initiator: "Automation",
      },
    } as ChargeAgentBookingFeeRequest;
    const response: ChargeAgentBookingFeeResponse = yield chargeAgentBookingFee(requestBody);
    if (response?.ChargeBookingFeeResponse === "Success") {
      yield put(actions.chargeAgentBookingFeeSuccess());
      const properties: ChargeBookingAgentFeeProperties = {
        ...completeBuyAirProperties,
        agent_booking_fee_amount_usd: AGENT_FEE,
        success: true,
      };
      trackEvent({
        eventName: CHARGE_BOOKING_AGENT_FEE,
        properties,
      });
    } else {
      throw Error(response?.ChargeBookingFeeResponse);
    }
  } catch (e) {
    yield put(actions.chargeAgentBookingFeeFailed());
    Logger.debug(e);
    const properties: ChargeBookingAgentFeeProperties = {
      ...completeBuyAirProperties,
      agent_booking_fee_amount_usd: 0,
      success: false,
    };
    trackEvent({
      eventName: CHARGE_BOOKING_AGENT_FEE,
      properties,
    });
  }
}
