import { getRewardText, twoDecimalFormatter } from "halifax";
import { IPerson, RewardsPrice } from "redmond";
import { Prices } from "@b2bportal/air-booking-api";
import { HopperSeatMapOptions } from "@capone/checkout";

export const SEAT_SELECTION_TITLE = "Choose seats";
export const SEAT_SELECTION_SUBTITLE = "Book seats ahead for your journey.";
export const SELECT_SEATS_CTA_TEXT = "Select Your Seats";
export const SKIP_SEAT_SELECTION_TITLE = "Skip seat selection";
export const SKIP_SEAT_SELECTION_SUBTITLE =
  "Skip seats. Seats can be added on the airline website after booking, or will be assigned at check-in.";
export const UNAVAIL_SEAT_SELECTION_WARNING =
  "Seat selection is currently unavailable for your journey.";

export const GORDIAN_SEAT_MAP_OPTIONS = {
  modal: false,
  fontFamily: "Optimist",
  theme: {
    textPrimary: "#141414",
    textSecondary: "#676868",
    title: "#141414",
    primary: "#0276B1",
    secondary: "#0276B1",
    alert: "#CC2427",
    buttonBorderRadius: "4px",
    seats: {
      1: {
        // Extra Legroom
        stopColor: "#008140",
        labelColor: "#FFFFFF",
        borderColor: "#FFFFFF",
      },
      2: {
        // Exit row
        stopColor: "#7BB475",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      3: {
        // Preferred
        stopColor: "#BAD4B4",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      4: {
        // Standard on CQ
        stopColor: "#8AB0C7",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      5: {
        // Standard on UA
        stopColor: "#B3CDDE",
        labelColor: "#141414",
        borderColor: "#FFFFFF",
      },
      not_available: {
        fill: "#CECFCD",
        stopColor: "#CECFCD",
        borderColor: "#CECFCD",
      },
    },
    selectedSeat: {
      color: "#141414",
      background: "#00C0E5",
      borderColor: "#FFFFFF",
    },
    confirmedSeat: {
      color: "white",
      background: "#0276B1",
      borderColor: "#FFFFFF",
    },
  },
};

export const HOPPER_SEAT_MAP_OPTIONS: HopperSeatMapOptions = {
  seats: {
    standard: {
      background: "#CEE4F2",
      label: "#141414",
    },
    extraLegRoom: {
      background: "#E7E1FF",
      label: "#141414",
    },
    exitRow: {
      background: "#D6E7D3",
      label: "#141414",
    },
    preferred: {
      background: "#FFE4B1",
      label: "#141414",
    },
    unavailable: {
      background: "#CECFCD",
      label: "#141414",
    },
    hovered: {
      background: "#00132B",
      label: "white",
    },
    selected: {
      background: "#00132B",
      label: "white",
    },
  },
};

export const SEAT_MAP_MODAL_TITLE = "Choose Seats";

export const getCheapestSeatString = (
  cheapestSeat: Prices,
  selectedRewardsAccountId?: string
) => {
  if (cheapestSeat.fiat.value === 0) {
    return `<strong>Free</strong> seat selection`;
  }
  if (selectedRewardsAccountId) {
    return `Starting from <strong>${cheapestSeat.fiat.currencySymbol}${
      cheapestSeat.fiat.value
    }</strong> / ${getRewardText({
      reward: cheapestSeat.accountSpecific[selectedRewardsAccountId],
    })} per traveler`;
  } else {
    return `Starting from <strong>${cheapestSeat.fiat.currencySymbol} ${cheapestSeat.fiat.value}</strong> per traveler`;
  }
};
export const getTotalSeatPricingString = (
  passengers: IPerson[],
  totalSeatPricing: number,
  totalSeatRewards?: RewardsPrice
): string => {
  if (totalSeatRewards) {
    return `You've reserved seats for ${passengers.length} ${
      passengers.length > 1 ? "passengers" : "passenger"
    } for <span class="total-pricing-fiat"><strong>$${twoDecimalFormatter(
      totalSeatPricing
    )}</strong></span> / ${getRewardText({
      reward: totalSeatRewards,
    })}.`;
  } else {
    return `You've reserved seats for ${passengers.length} ${
      passengers.length > 1 ? "passengers" : "passenger"
    } for <span class="total-pricing-fiat"><strong>$${twoDecimalFormatter(
      totalSeatPricing
    )}</strong></span>.`;
  }
};

export const HOPPER_SEAT_MAP_STYLE = `
      @font-face {
        font-family: "Optimist";
        font-style: normal;
        font-weight: normal;
        src: url(${window.location.origin}/email-assets/fonts/Optimist_Rg.ttf)
          format("truetype");
      }
      @font-face {
        font-family: "Optimist";
        font-style: normal;
        font-weight: 600;
        src: url(${window.location.origin}/email-assets/fonts/Optimist_W_SBd.ttf)
          format("truetype");
      }

      body {
        font-family: "Optimist", sans-serif;
      }

      .tenant-styles {
        #wrapper {
          height: 100vh;
        }

        button {
          font-family: "Optimist";
        }

        .text-primary {
          color: #141414;
        }

        .dropdown-icon {
          color: #013D58;
          width: 24px;
          height: 24px;
          align-self: center;
        }

        .dropdown {
          border-bottom: 0.5px solid #E0E0E0;
          &+.dropdown {
            border-left: 0.5px solid #E0E0E0;
          }
          .select {
            .text-primary {
              color: #141414;
              font-size: 16px;
              font-weight: 600;
              &:not(:last-child) {
                margin-bottom: 8px;
              }
            }
            .text-secondary {
              color: #676868;
              font-size: 14px;
            }
          }
        }

        .seat-map {

          .jets-seat-map {
            font-family: "Optimist" !important;
          }

          .legend {
            border: 0.5px solid #E0E0E0;
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
            padding: 0px 20px 0px 4px;
            border-radius: 8px;
            .categories {
              grid-template-columns: 5em 1.5fr 1fr;
              padding: 14px 0px;
              hr {
                display: none;
              }
              .category-description {
                .text-primary {
                  color: #141414;
                  font-size: 16px;
                  margin-bottom: 4px;
                }
                .text-secondary {
                  color: #676868;
                  font-size: 14px;
                }
              }
              .category-price {
                color: #141414;
                font-size: 16px;
                font-weight: 600;
              }
              .icon {
                .jets-seat-svg {
                  svg {
                    path {
                      stroke: none;
                    }
                  }
                }
              }
            }
          }

        }

        .hover-popout {
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
          padding: 18px 21px;
          .cell {
            .text-primary {
              font-weight: 600;
              color: #141414;
              margin-bottom: 8px;
              font-size: 14px;
            }
            .text-secondary {
              color: #676868;
              font-size: 14px;
            }
          }
        }

        .selected-popout {
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
          padding: 20px 30px;
          border-radius: 8px;

          .cell {
            .text-primary {
              font-weight: 600;
              margin-bottom: 8px;
              color: #141414;
              font-size: 14px;
            }
            .text-secondary {
              color: #676868;
              font-size: 14px;
            }
          }

          .text-button {
            color: #013D58;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          }

          .button.unselect {
            background: none;
            color: #013D58;
            cursor: pointer;
          }
        }

        .footer {
          border: 0.5px solid #E0E0E0;
          .text-primary {
            color: #141414;
            font-size: 18px;
            font-weight: 600;
          }
          .text-secondary {
            color: #141414;
            font-size: 14px;
          }
          .button {
            max-width: 224px;
            height: 48px;
            background: #0276B1;
            font-size: 14px;
            cursor: pointer;
          }
        }

        .exit-row-disclaimer {
          z-index: 2;
          grid-template-rows: 5em 1fr 5em;
          border-radius: 8px;
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
          border: 0.5px solid #E0E0E0;
          &:not(:last-child) {
            margin-right: 0px;
          }
          &:last-child {
            margin-left: 0px;
          }
          header {
            border-bottom: 0.5px solid #E0E0E0;
          }
          .exit-row-disclaimer-actions {
            padding: 10px;
            border-top: 0.5px solid #E0E0E0;
            .button {
              background: #0276B1;
              height: 48px;
              border: none;
              font-size: 14px;
              &:hover {
                cursor: pointer;
              }
              &.button-secondary {
                background: white;
                border: 0.5px solid #E0E0E0;
              }
            }
          }
        }

        .modal-shadow {
          z-index: 2;
        }

        .seat-details {
          box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          border: none;
          bottom: 13.5em;
          .seat-details-body {
            padding: 24px 28px 20px 28px;
            .seat-details-header {
              margin-bottom: 22px;
              .seat-details-header-title {
                font-size: 20px;
                font-weight: 600;
                &:after {
                  content: " - ";
                  margin-right: 4px;
                }
              }
              .seat-details-header-price {
                font-size: 20px;
                font-weight: 600;
                flex: 1;
              }
              .seat-details-header-close {
                width: 24px;
                height: 24px;
                margin-left: 16px;
                svg {
                  margin-right: -12px;
                  path {
                    fill: #013D58;
                  }
                }
              }
            }

            .seat-details-features {
              .seat-details-feature {
                svg {
                  path[fill="#11d900"] {
                    fill: #0E6018;
                  }
                  path[fill="red"] {
                    fill: #AB332B;
                  }
                }
                .seat-details-feature-text {
                  margin-left: 16px;
                  font-size: 14px;
                }
              }
            }

            .seat-details-measurements {
              margin-top: 22px;
              .seat-details-measurement {
                border: 1px solid #4f6f8f;
                background: #F3F5F8;
                padding: 10px 24px;
                svg {
                  path {
                    fill: #4f6f8f;
                  }
                }
              }
            }
          }
        }
      }
    `;
