import axios, { AxiosResponse } from "axios";
import { config } from "../../config";

import {
  PotentialCrossSellOffersRequest,
  PotentialCrossSellOffersResponse,
} from "redmond";
import { potentialCrossSellOffersApiPrefix } from "../paths";

export const fetchPotentialCrossSellOffers = (
  body: PotentialCrossSellOffersRequest
): Promise<PotentialCrossSellOffersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<PotentialCrossSellOffersResponse> =
        await axios.post(potentialCrossSellOffersApiPrefix, body, {
          baseURL: config.baseURL,
        });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
