import { DP_SUPPORT_PHONE_NUMBER, GENERAL_SUPPORT_PHONE_NUMBER, RebookSummaryTripType } from "redmond";

export const REBOOK_REVIEW_ITINERARY_TITLE =
  "Review and confirm your new booking";
export const REBOOK_REVIEW_ITINERARY_SUBTITLE =
  "Review your flight itinerary and passenger information and continue on to rebook your new flight.";

export const TRAVELERS = "Travelers";
export const IMPORTANT_INFORMATION = "Important information";

const IMPORTANT_INFORMATION_MCP_VI_PART_ONE = `Online rebooking is only available if rebooking for all original travelers. If you would only like to rebook some of the passengers included in your original booking, please call us at ${GENERAL_SUPPORT_PHONE_NUMBER.boldText}.`
const IMPORTANT_INFORMATION_MCP_VI_PART_TWO = "When rebooking, you'll choose from flights that depart on the same day or following day of your original flight, arrive at the final intended destination as your original flight, and are the same or a lower fare class."
const IMPORTANT_INFORMATION_MCP_VI_PART_THREE = "You can choose from flights that are within a <b>$5,000 cap per traveler</b>."
export const IMPORTANT_INFORMATION_MCP_VI_STEPS = [
  {message:IMPORTANT_INFORMATION_MCP_VI_PART_ONE}, 
  {message:IMPORTANT_INFORMATION_MCP_VI_PART_TWO}, 
  {message:IMPORTANT_INFORMATION_MCP_VI_PART_THREE},
]

export const IMPORTANT_INFORMATION_POINT_ONE = `Online booking is only available if booking for all original travelers. If you would only like to rebook some of the passengers included in your original booking, please call us at ${DP_SUPPORT_PHONE_NUMBER.boldText}.`;
export const IMPORTANT_INFORMATION_POINT_TWO = `When rebooking, you'll choose from flights that: depart on the same day or following day as your original flight, arrive and depart from the same airports and are the same or a lower fare class.`;
export const IMPORTANT_INFORMATION_POINT_THREE = (cap: string) =>
  `You can choose from flights that are within the ${cap} cap per traveler limit.`;
export const IMPORTANT_INFORMATION_POINT_FOUR = `You can only rebook with this service <strong>one time</strong> per trip.`;

export const CONFIRM_AND_REBOOK = "Confirm rebooking";
export const CONFIRM_AND_REBOOK_ARIA_LABEL = "Confirm rebooking";

export const VIEW_DETAILS = "View details";
export const VIEW_DETAILS_ARIA_LABEL = "View flight details";
export const CHANGE = "Change";
export const CHANGE_ARIA_LABEL = "Change selected flight";

export const NEW_FLIGHT = "New flight";

export const LOADING_POPOVER_TITLE = "Your booking is being processed";
export const LOADING_POPOVER_SUBTITLE =
  "Please do not exit until the booking is complete.";

export const ERROR_MODAL_TITLE = "Rebooking failed";
export const ERROR_MODAL_SUBTITLE = `Please try again or contact support at ${DP_SUPPORT_PHONE_NUMBER.boldLink}.`;

export const NO_AVAILABILITY_MODAL_TITLE = (passengers: number) =>
  `There’s no availability for (${passengers}) ${
    passengers > 1 ? "travelers" : "traveler"
  }.`;
export const NO_AVAILABILITY_MODAL_SUBTITLE = "Search for new flights.";

export const TRY_AGAIN = "Try again";
export const SEARCH_AGAIN = "Search again";
export const KNOW_BEFORE = "Know before you rebook"

const KNOW_BEFORE_STEP_1 = "Your return flight is operating as normal."
const KNOW_BEFORE_STEP_2 = "After you confirm and rebook this connecting flight, it will appear as its own one-way flight in my trips."
const KNOW_BEFORE_STEP_3 = "You will need to collect and re-check any baggage onto your new connecting flight. Additional baggage fees may apply in some cases."
const KNOW_BEFORE_STEPS_REBOOK = [
  KNOW_BEFORE_STEP_1,
  KNOW_BEFORE_STEP_2,
  KNOW_BEFORE_STEP_3,
];

export const KNOW_BEFORE_STEPS = (tripType?: RebookSummaryTripType) => {
  if (!tripType) {
    return KNOW_BEFORE_STEPS_REBOOK;
  }
  const isroundTrip = tripType === RebookSummaryTripType.ROUND_TRIP;
  return KNOW_BEFORE_STEPS_REBOOK.slice(!isroundTrip ? 1 : 0);
};
