import React from "react";
import { useDeviceTypes } from "halifax";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  FARE_CLASS_DETAILS,
  FARE_CLASSES,
} from "../../../FlightShopHeader/components/FareClassOptionSelection/textConstants";
import "./styles.scss";
import { MobileFilterFareDetailsConnectorProps } from "./container";

export const MobileFilterFareDetails = (
  props: MobileFilterFareDetailsConnectorProps
) => {
  const { fareClassFilters } = props;
  const selectedFilter = Object.keys(fareClassFilters).find(
    (key) => fareClassFilters[key]
  );
  const { matchesMobile } = useDeviceTypes();

  if (!matchesMobile || !selectedFilter) return null;

  return (
    <Box className="fare-class-details-card">
      <Typography className="fare-class-details-title">
        {`${FARE_CLASSES[selectedFilter]} Fares`}
      </Typography>
      <Typography className="fare-class-details-body">
        {FARE_CLASS_DETAILS[selectedFilter]}
      </Typography>
    </Box>
  );
};
