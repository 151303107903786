import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
} from "react";
import queryStringParser from "query-string";
import { RouteComponentProps } from "react-router";
import {
  CLP_MODAL_VIEW,
  CLPModalViewProperties,
  getHopperFareRatingName,
  Airport,
  CLP_CLICK,
  CLPClickEnum,
  CLPClickProperties,
  PriceFreezeEntryEnum,
  EntryButtonEnum,
  PreviousFlightEnum,
  FiatPrice,
  RewardsPrice,
  TimeToLive,
  TripDetails,
} from "redmond";
import {
  ActionLink,
  MobilePopoverCard,
  CloseButtonIcon,
  useDeviceTypes,
  IPriceFreezeOfferEntries,
  B2BSpinner,
  LoadingIndicator,
  PriceFreezeFlightDetails,
  IFlightSummaryPanelProps,
  ButtonWrap,
} from "halifax";
import { Box, Dialog } from "@material-ui/core";
import { PATH_PRICE_FREEZE } from "../../../../../utils/urlPaths";
import * as constants from "../textConstants";
import { PriceFreezeFlightDetailsModalConnectorProps } from "./container";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { goToPriceFreezeCheckout } from "../sharedUtils";

export interface IPriceFreezeFlightDetailsModalProps
  extends PriceFreezeFlightDetailsModalConnectorProps,
    IPriceFreezeFlightDetailsModalOwnProps,
    RouteComponentProps {}

export interface IPriceFreezeFlightDetailsModalOwnProps {
  priceFreezeOffer?: IPriceFreezeOfferEntries | null;
  frozenPrice?: { fiat: FiatPrice; rewards: RewardsPrice | undefined } | null;
  duration?: TimeToLive;
  tripDetails?: TripDetails;
  fareId?: string;
  // note: this prop is used to overwrite the default behaviour of goToPriceFreezeCheckout
  handleGoToPriceFreezeCheckout?: () => void;
  // note: this prop is used to overwrite the Continue cta logic that does goToPriceFreezeCheckout
  handleContinue?: () => void;
  airports?: { [key: string]: Airport };
  priceFreezeFlightDetailsModalOpen: boolean;
  setPriceFreezeFlightDetailsModalOpen: Dispatch<SetStateAction<boolean>>;
  priceFreezeFlightDetails?: {
    header?: string;
    subtitle?: string;
    departureFlight: IFlightSummaryPanelProps;
    returnFlight?: IFlightSummaryPanelProps;
    frozenPrice: {
      duration: string;
    };
    hideSecondaryButton?: boolean;
    entry?: PriceFreezeEntryEnum;
    entryButton?: EntryButtonEnum;
    prevFlight?: PreviousFlightEnum;
  };
  useLockPriceLanguage?: boolean;
}

export const PriceFreezeFlightDetailsModal = (
  props: IPriceFreezeFlightDetailsModalProps
) => {
  const {
    history,
    priceFreezeOffer,
    frozenPrice,
    duration,
    tripDetails,
    fareId,
    handleGoToPriceFreezeCheckout,
    handleContinue,
    airports,
    priceDropProtectionCandidateId,
    priceFreezeFlightDetailsModalOpen,
    setPriceFreezeFlightDetailsModalOpen,
    priceFreezeFlightDetails,
    useLockPriceLanguage,
    isPriceFreezeDurationPopupEnabled,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const onTrackEventClpClick = (prop: CLPClickEnum) => {
    trackEvent({
      eventName: CLP_CLICK,
      properties: {
        selected_button: prop,
      } as CLPClickProperties,
    });
  };

  const handleCloseModal = useCallback(() => {
    onTrackEventClpClick(CLPClickEnum.EXIT);
    setPriceFreezeFlightDetailsModalOpen(false);
  }, [setPriceFreezeFlightDetailsModalOpen]);

  const renderPriceFreezeFlightDetails = () => {
    const details = `${
      priceFreezeOffer?.returnFlight ? constants.ROUND_TRIP : ""
    } ${constants.PER_TRAVELER}`;
    const buttonsProps = [
      {
        content: constants.PRICE_FREEZE_DETAILS_BUTTON_TEXT,
        ariaLabelText:
          constants.PRICE_FREEZE_DETAILS_BUTTON_LABEL(useLockPriceLanguage),
        buttonStyle: "h4r-primary",
        onClick: () => {
          onTrackEventClpClick(CLPClickEnum.CONTINUE);
          if (handleContinue) {
            handleContinue();
          } else {
            goToPriceFreezeCheckout({
              tripDetails,
              fareId,
              handleGoToPriceFreezeCheckout,
              priceDropProtectionCandidateId,
              history,
              entry: priceFreezeFlightDetails?.entry,
              entryButton:
                priceFreezeFlightDetails?.entryButton ??
                EntryButtonEnum.CLP_MODAL,
              prevFlight: priceFreezeFlightDetails?.prevFlight,
            });
          }
        },
      },
    ];
    if (!priceFreezeFlightDetails?.hideSecondaryButton) {
      const secondaryButton = {
        content: constants.PRICE_FREEZE_CHOOSE_ANOTHER_BUTTON_TEXT,
        ariaLabelText: constants.PRICE_FREEZE_CHOOSE_ANOTHER_BUTTON_LABEL,
        buttonStyle: "h4r-secondary",
        onClick: () => {
          onTrackEventClpClick(CLPClickEnum.CHOOSE_ANOTHER_FLIGHT);
          setPriceFreezeFlightDetailsModalOpen(false);
        },
      }
      if (isPriceFreezeDurationPopupEnabled) {
        buttonsProps.unshift(secondaryButton);
      } else {
        buttonsProps.push(secondaryButton);
      }
    }

    if (!tripDetails)
      return (
        <LoadingIndicator
          className="price-freeze-trip-loading"
          indicatorSize="small"
          indicator={B2BSpinner}
          message={constants.LOADING_PRICE_FREEZE(useLockPriceLanguage)}
        />
      );
    return (
      <PriceFreezeFlightDetails
        header={
          priceFreezeFlightDetails?.header ??
          constants.PRICE_FREEZE_FLIGHT_DETAILS_MODAL_TITLE
        }
        subtitle={
          priceFreezeFlightDetails?.subtitle ??
          constants.PRICE_FREEZE_FLIGHT_DETAILS_MODAL_SUBTITLE(
            useLockPriceLanguage
          )
        }
        departureFlight={
          priceFreezeFlightDetails
            ? priceFreezeFlightDetails.departureFlight
            : priceFreezeOffer?.departureFlight
        }
        returnFlight={
          priceFreezeFlightDetails
            ? priceFreezeFlightDetails.returnFlight
            : priceFreezeOffer?.returnFlight
        }
        frozenPrice={{
          duration:
            priceFreezeFlightDetails?.frozenPrice.duration ??
            constants.PRICE_FREEZE_DETAILS_FROZEN_PRICE_DURATION(
              duration,
              useLockPriceLanguage
            ),
          price: constants.PRICE_FREEZE_DETAILS_FROZEN_PRICE_VALUE(frozenPrice),
          details,
        }}
        buttonsProps={buttonsProps}
        termsProps={{
          content: constants.PRICE_FREEZE_DETAILS_READ_TERMS_TEXT,
          ariaLabelText: constants.PRICE_FREEZE_DETAILS_READ_TERMS_LABEL,
          onClick: () => window.open(`${PATH_PRICE_FREEZE}`, "_blank"),
        }}
        isMobile={matchesMobile}
        variant={isPriceFreezeDurationPopupEnabled ? "variant-2" : "default"}
      />
    );
  };

  React.useEffect(() => {
    const { flightShopProgress } = queryStringParser.parse(
      history.location.search
    );

    if (
      priceFreezeFlightDetailsModalOpen &&
      flightShopProgress &&
      airports &&
      tripDetails
    ) {
      // BF-1552 Support properties for multi-city flights once needed.
      const outgoingSlice = tripDetails?.slices[0];
      const returnSlice = tripDetails?.slices[1];
      const { originCode, destinationCode } = outgoingSlice;
      trackEvent({
        eventName: CLP_MODAL_VIEW,
        properties: {
          origin: originCode || outgoingSlice?.originName,
          destination: destinationCode || outgoingSlice?.destinationName,
          origin_country_code:
            airports[originCode]?.geography?.countryCode || "",
          destination_country_code:
            airports[destinationCode]?.geography?.countryCode || "",
          departure_date: outgoingSlice?.departureTime,
          return_date: returnSlice?.departureTime,
          trip_type: returnSlice?.departureTime ? "round_trip" : "one_way",
          fare_class: getHopperFareRatingName(
            tripDetails?.fareDetails[0]?.slices[0]?.fareShelf?.rating
          ),
        } as CLPModalViewProperties,
      });
    }
  }, [
    priceFreezeFlightDetailsModalOpen,
    airports,
    tripDetails,
    history.location.search,
  ]);

  const PriceFreezeDetailsPopup = (
    <Dialog
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      className="desktop-price-freeze-entry-details-popup"
      open={priceFreezeFlightDetailsModalOpen}
      role="dialog"
      onClose={handleCloseModal}
    >
      <Box className="top-button-right">
        <ButtonWrap
          aria-label="Close"
          onClick={handleCloseModal as MouseEventHandler<HTMLButtonElement>}
        >
          <CloseButtonIcon />
        </ButtonWrap>
      </Box>
      {renderPriceFreezeFlightDetails()}
    </Dialog>
  );

  const PriceFreezeDetailsMobilePopover = (
    <MobilePopoverCard
      className="mobile-price-freeze-entry-details-popup"
      open={priceFreezeFlightDetailsModalOpen}
      topRightButton={
        <ActionLink
          className="filter-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={handleCloseModal}
        />
      }
    >
      {renderPriceFreezeFlightDetails()}
    </MobilePopoverCard>
  );

  return (
    <>
      {matchesMobile
        ? PriceFreezeDetailsMobilePopover
        : PriceFreezeDetailsPopup}
    </>
  );
};
