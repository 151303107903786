import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RefundableFareOptionsBanner } from "./component";
import { cfarOfferFromRefundableFaresSelector } from "../../../shop/reducer";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    cfarOffer: cfarOfferFromRefundableFaresSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RefundableFareOptionsBannerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedRefundableFareOptionsBanner = connector(
  withRouter(RefundableFareOptionsBanner)
);
