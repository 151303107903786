import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  NotificationBanner,
  BannerSeverity,
  FlightSummaryPanel,
  ActionLink,
} from "halifax";
import { CHANGE_FLIGHT, ChangeFlightTrackingProps } from "redmond";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FareDetails } from "redmond";
import { FlightFreezeReviewItineraryConnectorProps } from "./container";
import {
  DesktopFlightReviewDetailsPopover,
  MobileItineraryDetailsModal,
} from "../../../shop/components";
import { FlightShopStep } from "../../../shop/reducer";
import * as t from "./textConstants";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IFlightFreezeReviewItineraryProps
  extends FlightFreezeReviewItineraryConnectorProps,
    RouteComponentProps {
  title?: string;
  isMobile?: boolean;
  disableChangeFlight: boolean;
}

export const FlightFreezeReviewItinerary = ({
  title,
  isMobile,
  history,
  populateFlightShopQueryParams,
  departureFlight,
  returnFlight,
  tripDetails,
  airports,
  fareDetails,
  isOutgoingMultiTicket,
  isReturnMultiTicket,
  isCurrentLowestPrice,
  location,
  disableChangeFlight,
  hasTravelFusionFareBrand,
}: IFlightFreezeReviewItineraryProps) => {
  const [openDetailsModal, setOpenDetailsModal] =
    React.useState<boolean>(false);
  const [isDepartureClicked, setIsDepartureClicked] =
    React.useState<boolean>(true);
  const [trackingParams, setTrackingParams] = React.useState<string | null>(
    null
  );

  React.useEffect(() => {
    const { prevFlight }: any = location?.state || {};
    if (prevFlight) {
      setTrackingParams(prevFlight);
    }
  }, []);

  const handleChangeFlight = (step: FlightShopStep) => {
    populateFlightShopQueryParams({
      history,
      useHistoryPush: true,
      forceQueryUpdate: true,
      newQueryParams: {
        flightShopProgress: step,
      },
    });
    if (trackingParams) {
      trackEvent({
        eventName: CHANGE_FLIGHT,
        properties: {
          previous_flight: trackingParams,
          success: true,
        } as ChangeFlightTrackingProps,
      });
    }
  };

  // TODO: correct flight type once the corresponding state value is known
  const renderFlightSummaryPanel = (flight: any, isDeparture: boolean) => {
    return (
      <>
        <FlightSummaryPanel
          className="price-freeze-flight-summary"
          {...flight}
          isMobile={isMobile}
        />
        <ActionLink
          className="flight-summary-details-button"
          content={
            isMobile ? (
              <FontAwesomeIcon className="arrow-icon" icon={faChevronRight} />
            ) : (
              <Typography className="view-details-text" variant="button">
                {t.VIEW_DETAILS_TEXT}
              </Typography>
            )
          }
          label={t.SHOW_FLIGHT_DETAILS_TEXT}
          onClick={() => {
            setIsDepartureClicked(isDeparture);
            setOpenDetailsModal(true);
          }}
        />
      </>
    );
  };

  return (
    <>
      <Box
        className={clsx("flight-freeze-review-itinerary-root", {
          mobile: isMobile,
          "from-review-itinerary": !isCurrentLowestPrice,
        })}
      >
        <Box className="flight-freeze-review-itinerary-container">
          <Box className="title-and-change-button-section">
            {title && !(!isCurrentLowestPrice && isMobile) && (
              <Box className="title-wrapper">
                <Typography className="title-text" variant="h2">
                  {title}
                </Typography>
              </Box>
            )}
            {!isCurrentLowestPrice && (
              <ActionLink
                className="change-button"
                content={
                  <Typography className="change-text" variant="button">
                    {t.CHANGE_TEXT}
                  </Typography>
                }
                label={t.CHANGE_FLIGHT_TEXT}
                onClick={() =>
                  handleChangeFlight(FlightShopStep.ChooseDeparture)
                }
              />
            )}
          </Box>
          <Box
            className={clsx("banner-section", {
              hidden: !isCurrentLowestPrice,
            })}
          >
            <NotificationBanner
              className="lowest-price-banner"
              severity={BannerSeverity.NOTICE}
              content={
                <Typography
                  className="lowest-price-banner-label"
                  variant="caption"
                >
                  <span className="label-copy">
                    {t.LOWEST_PRICE_BANNER_TEXT}
                  </span>
                  <ActionLink
                    className="lowest-price-banner-link"
                    content={t.LOWEST_PRICE_BANNER_BUTTON_TEXT}
                    onClick={() =>
                      handleChangeFlight(FlightShopStep.ChooseDeparture)
                    }
                    showTappableArea={false}
                    ariaProps={{
                      role: "button",
                    }}
                  />
                  <span className="label-copy">{t.PERIOD_TEXT}</span>
                </Typography>
              }
            />
          </Box>
          <Box className="review-itinerary-section">
            <Box
              className={clsx("flight-summary-panel-wrapper", "departure", {
                "one-way": !returnFlight,
              })}
            >
              {renderFlightSummaryPanel(departureFlight, true)}
            </Box>
            {returnFlight && (
              <Box className={clsx("flight-summary-panel-wrapper", "return")}>
                {renderFlightSummaryPanel(returnFlight, false)}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {isMobile
        ? openDetailsModal && (
            <MobileItineraryDetailsModal
              isDeparture={isDepartureClicked}
              tripDetails={tripDetails}
              fareDetails={fareDetails}
              openModal={openDetailsModal}
              onClose={() => setOpenDetailsModal(false)}
            />
          )
        : openDetailsModal && (
            <DesktopFlightReviewDetailsPopover
              departure={isDepartureClicked}
              tripDetails={tripDetails}
              fareDetails={fareDetails as FareDetails}
              isMultiTicket={
                isDepartureClicked ? isOutgoingMultiTicket : isReturnMultiTicket
              }
              openPopover={openDetailsModal}
              onClose={() => setOpenDetailsModal(false)}
              onClickEdit={() =>
                handleChangeFlight(
                  isDepartureClicked
                    ? FlightShopStep.ChooseDeparture
                    : FlightShopStep.ChooseReturn
                )
              }
              airports={airports}
              hasActiveRefundableFare={false}
              // TODO: If supported for Air Price Freeze.
              isVITripSelected={false}
              setOpenVIVariantModal={() => {}}
              setOpenMultipleAirlinesFares={() => {}}
              disableChangeFlight={disableChangeFlight}
              hasTravelFusionFareBrand={hasTravelFusionFareBrand}
            />
          )}
    </>
  );
};
