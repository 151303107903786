import React, { useState, useContext, useMemo } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Box, Typography } from "@material-ui/core";
import { B2BLoadingPopup, useDeviceTypes, Header } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import { FlightShopStep } from "./reducer";
import {
  FlightList,
  FlightShopHeader,
  FlightShopReviewItinerary,
  FlightShopProgressHeader,
  MobilePricePredictionSection,
  MobileFareDetails,
} from "./components";
import { FlightShopConnectorProps } from "./container";
import { FlightShopSearchControl } from "../search/components/FlightShopSearchControl";
import { FlightShopSearchFilter } from "../search/components/FlightShopSearchControl/components";
import { SortOptionSelection } from "./components/FlightShopHeader/components/SortOptionSelection";
import { AppliedFilterTags } from "../search/components/FlightShopSearchControl/components/AppliedFilterTags";
import { FareClassOptionSelection } from "./components/FlightShopHeader/components/FareClassOptionSelection";
import { RewardsAccountSelection } from "../rewards/components";
import { SimilarFlightsModal } from "./components/SimilarFlightsModal";
import {
  SEARCHING_FOR_FLIGHTS,
  SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE,
  CHOOSING_FLIGHT_TEXT,
  FLIGHT_PRICES_TEXT,
  REVIEW_ITINERARY_TITLE_TEXT,
  REVIEW_ITINERARY_SUBTITLE_TEXT,
} from "./constants";
import { ClientContext } from "../../App";
import { PATH_HOME, PATH_SHOP, PATH_FREEZE } from "../../utils/urlPaths";
import {
  pushToPathWithExistingQueryParams,
  parseQueryString,
  IFlightShopParsedQuery,
} from "./utils/parseQueryString";
import {
  RegionType,
  FlightShopType,
  CallState,
  TransferRequest,
  VIEWED_PRICE_FREEZE_SIMILAR_FLIGHT_LIST,
} from "redmond";
import {
  AVAILABLE,
  PRICE_FREEZE,
  SIMILAR_FLIGHTS,
  getExperimentVariant,
  useExperiments,
} from "../../context/experiments";
import { ItineraryEnum } from "redmond/apis/tysons/similar-flights";
import {
  CHOOSE_DEPARTURE_TITLE,
  CHOOSE_RETURN_TITLE,
  CHOOSE_SIMILAR_OUTBOUND,
  CHOOSE_SIMILAR_RETURN,
  PORTAL_TITLE,
  REVIEW_ITINERARY_TITLE,
} from "../../lang/textConstants";
import { trackEvent } from "../../api/v0/analytics/trackEvent";

export interface IFlightShopProps
  extends FlightShopConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {}

export interface IFlightShopProps extends FlightShopConnectorProps {}

export const FlightShop = (props: IFlightShopProps) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const {
    currentStep,
    tripSummariesLoading,
    history,
    fetchSimilarFlights,
    fetchTripSummaries,
    fetchTripSummariesForPrediction,
    fetchTransferToSimilarFlights,
    setFlightShopProgress,
    populateFlightBookQueryParams,
    tripSummaries,
    refreshPrediction,
    origin,
    destination,
    tripCategory,
    fetchSimilarFlightsCallState,
    flightShopType,
    sortOption,
    setSortOption,
    airports,
  } = props;
  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();
  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;
  const clientContext = useContext(ClientContext);

  const { experiments } = useExperiments();

  const isInReviewStep = currentStep === FlightShopStep.ReviewItinerary;
  const isInFareDetailsStep = currentStep === FlightShopStep.FareDetails;
  const isInPredictionStep = currentStep === FlightShopStep.PricePrediction;
  const isInChooseDepartureStep =
    currentStep === FlightShopStep.ChooseDeparture;
  const isInChooseReturnStep = currentStep === FlightShopStep.ChooseReturn;
  const [isTripSummariesEmpty, setIsTripSummariesEmpty] = useState(
    Object.keys(tripSummaries).length === 0
  );
  const [priceFreezeId, setPriceFreezeId] = useState<string>("");

  const [transferToSimilarFlightsRequest, setTransferToSimilarFlightsRequest] =
    useState<TransferRequest | null>(null);
  const priceFreezeGroup = getExperimentVariant(experiments, PRICE_FREEZE);
  const isPriceFreezeEnabled = useMemo(() => {
    return (
      priceFreezeGroup === AVAILABLE &&
      flightShopType === FlightShopType.PRICE_FREEZE_PURCHASE
    );
  }, [priceFreezeGroup, flightShopType]);
  const similarFlightsGroup = getExperimentVariant(
    experiments,
    SIMILAR_FLIGHTS
  );
  const isSimilarFlightsEnabled = useMemo(() => {
    return (
      similarFlightsGroup === AVAILABLE &&
      flightShopType === FlightShopType.PRICE_FREEZE_EXERCISE
    );
  }, [similarFlightsGroup, flightShopType]);

  React.useEffect(() => {
    setIsTripSummariesEmpty(Object.keys(tripSummaries).length === 0);
  }, [tripSummaries]);

  React.useEffect(() => {
    const prevPath = history.location.state?.prevPath;
    const queryString = parseQueryString(history) as IFlightShopParsedQuery;
    const flightShopType = queryString.flightShopType;
    switch (flightShopType) {
      case FlightShopType.PRICE_FREEZE_EXERCISE:
        const priceFreezeId = queryString.priceFreezeId;
        if (!priceFreezeId) {
          // note: when it's on PF exercise but priceFreezeId is not given, push the user to the home page
          history.push(PATH_HOME);
          return;
        }
        if (!prevPath) {
          fetchSimilarFlights({
            history,
            id: priceFreezeId,
          });
        } else {
          // note: location.state is now preserved on refresh; therefore, the FE needs to clean up 'prevPath' after it's consumed.
          delete history.location.state?.prevPath;
        }
        setPriceFreezeId(priceFreezeId);
        break;
      case FlightShopType.PRICE_FREEZE_PURCHASE:
      case FlightShopType.DEFAULT:
      default:
        if (
          isTripSummariesEmpty ||
          (!prevPath && !isInReviewStep && !isInFareDetailsStep)
        ) {
          fetchTripSummaries(history, matchesMobile);
        }
        break;
    }
  }, []);

  React.useEffect(() => {
    switch (flightShopType) {
      // note: fetching prediction is not relevant in terms of PF exercise (similar-flights)
      case FlightShopType.PRICE_FREEZE_PURCHASE:
      case FlightShopType.DEFAULT:
        if (
          refreshPrediction &&
          currentStep === FlightShopStep.ChooseDeparture
        ) {
          fetchTripSummariesForPrediction(history, false);
        }
        break;
      default:
        break;
    }
  }, [refreshPrediction, flightShopType]);

  //Update state depending on query param
  React.useEffect(() => {
    const queryString = parseQueryString(history) as IFlightShopParsedQuery;

    if (!!queryString.flightShopProgress) {
      setFlightShopProgress(queryString.flightShopProgress);
    }
  }, [history.location.search]);

  //Update query sting if flightShopProgress does not match state
  React.useEffect(() => {
    const queryString = parseQueryString(history) as IFlightShopParsedQuery;
    if (queryString.flightShopProgress !== currentStep) {
      pushToPathWithExistingQueryParams(
        history,
        PATH_SHOP,
        {
          flightShopProgress: currentStep,
        },
        false
      );
    }
    switch (currentStep) {
      case FlightShopStep.ChooseDeparture:
        document.title = isSimilarFlightsEnabled
          ? CHOOSE_SIMILAR_OUTBOUND
          : CHOOSE_DEPARTURE_TITLE;
        break;
      case FlightShopStep.ChooseReturn:
        document.title = isSimilarFlightsEnabled
          ? CHOOSE_SIMILAR_RETURN
          : CHOOSE_RETURN_TITLE;
        break;
      case FlightShopStep.ReviewItinerary:
        document.title = REVIEW_ITINERARY_TITLE;
        break;
    }
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, [currentStep]);

  // note: when currentStep === PricePrediction, and it's on desktop or is in PF - push to ChooseDeparture
  React.useEffect(() => {
    if (currentStep === FlightShopStep.PricePrediction) {
      //Needs to replace the query param and not push to history.
      pushToPathWithExistingQueryParams(
        history,
        PATH_SHOP,
        {
          flightShopProgress: FlightShopStep.ChooseDeparture,
        },
        true
      );
      setFlightShopProgress(FlightShopStep.ChooseDeparture);
    }
  }, [
    currentStep,
    matchesMobile,
    isPriceFreezeEnabled,
    isSimilarFlightsEnabled,
  ]);

  React.useEffect(() => {
    if (
      isSimilarFlightsEnabled &&
      fetchSimilarFlightsCallState === CallState.Success
    ) {
      trackEvent({
        eventName: VIEWED_PRICE_FREEZE_SIMILAR_FLIGHT_LIST,
        properties: {
          entry_screen: matchesMobile
            ? "choose_travelers"
            : "review_flight_details",
        },
      });
    }
  }, [isSimilarFlightsEnabled, fetchSimilarFlightsCallState]);

  // Scroll the screen to the top when the progress changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleOnCompleteFareSelect = (selectedFare: {
    tripId: string;
    fareId: string;
  }) => {
    if (isSimilarFlightsEnabled) {
      fetchTransferToSimilarFlights({
        id: priceFreezeId,
        tripId: selectedFare.tripId,
        fareId: selectedFare.fareId,
        history,
      });
      setTransferToSimilarFlightsRequest({
        id: priceFreezeId,
        itinerary: {
          tripId: selectedFare.tripId,
          fareId: selectedFare.fareId,
          Itinerary: ItineraryEnum.SingleItinerary,
        },
      });
    } else if (isPriceFreezeEnabled) {
      populateFlightBookQueryParams({
        history,
        pathname: PATH_FREEZE,
        preserveQuery: true,
      });
    }
    // note: the review itinerary step only exists in the default flight shop funnel
    else {
      setFlightShopProgress(FlightShopStep.ReviewItinerary);
    }
  };

  const renderDesktopFlightShopRewardsHeader = () => {
    return (
      <Header
        className="desktop-flight-shop-rewards-header-container"
        left={
          <Box className={"rewards-account-section-left-content"}>
            <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
              {clientContext.logo}
            </Box>
            <Box className={"rewards-account-section-travel-details"}>
              <Typography variant={"body1"}>
                {isInReviewStep
                  ? REVIEW_ITINERARY_TITLE_TEXT
                  : origin && destination
                  ? CHOOSING_FLIGHT_TEXT(
                      currentStep,
                      origin.label,
                      destination.label
                    )
                  : ""}
              </Typography>
              <Typography variant={"body2"}>
                {isInReviewStep
                  ? REVIEW_ITINERARY_SUBTITLE_TEXT
                  : FLIGHT_PRICES_TEXT(tripCategory)}
              </Typography>
            </Box>
          </Box>
        }
        right={
          <Box className="desktop-flight-shop-rewards-account-contents">
            <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
          </Box>
        }
      />
    );
  };

  const renderDesktopFlightShop = () => {
    return (
      <>
        {renderDesktopFlightShopRewardsHeader()}
        <Box className="flight-shop-result-container">
          {!isInReviewStep && (
            <FlightShopSearchControl
              disabled={isPriceFreezeEnabled || isSimilarFlightsEnabled}
            />
          )}
          {!isTripSummariesEmpty && (
            <FlightShopHeader
              isMobile={false}
              airports={airports}
              isMediumDesktop={matchesMediumDesktopOnly}
              isInPriceFreezePurchase={isPriceFreezeEnabled}
              isInSimilarFlights={isSimilarFlightsEnabled}
              useLockPriceLanguage={false}
              history={history}
            />
          )}
          {!isInReviewStep && (
            <FlightList
              onCompleteFareSelect={handleOnCompleteFareSelect}
              isInPriceFreezePurchase={isPriceFreezeEnabled}
              isInSimilarFlights={isSimilarFlightsEnabled}
            />
          )}
          {isInReviewStep && (
            <FlightShopReviewItinerary
              isMobile={false}
              useLockPriceLanguage={false}
              useDetailsPrefix={true}
            />
          )}
        </Box>
      </>
    );
  };

  const renderMobileFlightShop = () => {
    return (
      <>
        <Box className="progress-header-menu-wrapper">
          <FlightShopProgressHeader
            onFiltersClick={() => setFiltersOpen(true)}
            className={
              isInPredictionStep
                ? "mobile-prediction-header"
                : "mobile-shop-header"
            }
            isEditHidden={isPriceFreezeEnabled || isSimilarFlightsEnabled}
            allFiltersModalOpen={filtersOpen}
            setAllFiltersModalOpen={setFiltersOpen}
          />
        </Box>
        {(isInChooseDepartureStep || isInChooseReturnStep) && (
          <>
            <Box className="mobile-flight-shop-search-filters-and-sort-section">
              <FlightShopSearchFilter
                hideAirportFilter={
                  origin?.id.code.regionType === RegionType.Airport
                }
              />
              <SortOptionSelection
                sortOption={sortOption}
                setSortOption={setSortOption}
              />
            </Box>
            {isInChooseDepartureStep && !isSimilarFlightsEnabled && (
              <Box>
                <MobilePricePredictionSection
                  filtersOpen={filtersOpen}
                  setFiltersOpen={setFiltersOpen}
                  history={history}
                  useLockPriceLanguage={false}
                />
              </Box>
            )}
            <Box className={"mobile-filter-reset-chips"}>
              <AppliedFilterTags />
            </Box>
            <FareClassOptionSelection />
            {!isTripSummariesEmpty && (
              <FlightShopHeader
                isMobile={true}
                airports={airports}
                isInSimilarFlights={isSimilarFlightsEnabled}
                useLockPriceLanguage={false}
                history={history}
              />
            )}
            <FlightList
              onCompleteFareSelect={handleOnCompleteFareSelect}
              isInPriceFreezePurchase={isPriceFreezeEnabled}
              isInSimilarFlights={isSimilarFlightsEnabled}
            />
          </>
        )}
        {isInReviewStep && (
          <>
            <FlightShopHeader
              isMobile={true}
              airports={airports}
              useLockPriceLanguage={false}
              history={history}
            />
            <FlightShopReviewItinerary
              isMobile
              useLockPriceLanguage={false}
              useDetailsPrefix={true}
            />
          </>
        )}
        {isInFareDetailsStep && (
          <MobileFareDetails hasActiveRefundableFare={false} />
        )}
      </>
    );
  };

  return (
    <>
      <Box className={clsx("flight-shop-root", { mobile: matchesMobile })}>
        <Box className="flight-shop-container">
          {matchesDesktop && renderDesktopFlightShop()}
          {matchesMobile && renderMobileFlightShop()}
          {tripSummariesLoading && (
            <B2BLoadingPopup
              open={tripSummariesLoading}
              message={SEARCHING_FOR_FLIGHTS}
              secondaryMessage={SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE}
              image={clientContext.searchImage ?? ""}
              className="flight-search-loading-popup"
              popupSize={matchesMobile ? "mobile" : "desktop"}
            />
          )}
        </Box>
      </Box>
      <SimilarFlightsModal
        transferToSimilarFlightsRequest={transferToSimilarFlightsRequest}
      />
    </>
  );
};
