import {
  RebookSummarySuccess,
  SingleFlightItineraryResponse,
  GetExerciseEligibilitiesResponseV1,
  FetchCfarOfferRequestV2,
  FetchCfarOfferResponseV2,
  AncillaryPostBookingPurchaseRequest,
  AncillaryPostBookingPurchaseSuccess,
  AncillaryPostBookingFetchOfferRequest,
  AncillaryPostBookingFetchOfferSuccess,
  AncillaryPostBookingPurchaseFailure,
} from "redmond";
import { History } from "history";
import * as actionTypes from "./constants";
import { DateToggleFilter, IFlightAncillaryExperiments } from "../reducer";

export interface ISetOriginalFlightItineraryId {
  type: actionTypes.SET_ORIGINAL_FLIGHT_ITINERARY_ID;
  itineraryId: string;
}

export const setOriginalFlightItineraryId = (
  itineraryId: string
): ISetOriginalFlightItineraryId => ({
  type: actionTypes.SET_ORIGINAL_FLIGHT_ITINERARY_ID,
  itineraryId,
});

export interface IFetchRebookSummary {
  type: actionTypes.FETCH_REBOOK_SUMMARY;
  origin: string;
  destination: string;
  itineraryId: string;
  history: History;
  isMobile?: boolean;
}

export const fetchRebookSummary = (args: {
  origin: string;
  destination: string;
  itineraryId: string;
  history: History;
  isMobile?: boolean;
}): IFetchRebookSummary => ({
  type: actionTypes.FETCH_REBOOK_SUMMARY,
  ...args,
});

export interface ISetRebookSummaryCallStateFailed {
  type: actionTypes.SET_REBOOK_SUMMARY_CALL_STATE_FAILED;
}

export const setRebookSummaryCallStateFailed =
  (): ISetRebookSummaryCallStateFailed => ({
    type: actionTypes.SET_REBOOK_SUMMARY_CALL_STATE_FAILED,
  });

export interface ISetRebookSummaryResponse {
  type: actionTypes.SET_REBOOK_SUMMARY_RESPONSE;
  rebookSummary: RebookSummarySuccess;
}

export const setRebookSummaryResponse = (args: {
  rebookSummary: RebookSummarySuccess;
}): ISetRebookSummaryResponse => ({
  type: actionTypes.SET_REBOOK_SUMMARY_RESPONSE,
  ...args,
});

export interface ISetTodayTomorrowDateFilter {
  type: actionTypes.SET_TODAY_TOMORROW_DATE_FILTER;
  dateFilter: DateToggleFilter;
}

export const setTodayTomorrowDateFilter = (args: {
  dateFilter: DateToggleFilter;
}): ISetTodayTomorrowDateFilter => ({
  type: actionTypes.SET_TODAY_TOMORROW_DATE_FILTER,
  ...args,
});

export interface IFetchSingleFlightItinerary {
  type: actionTypes.FETCH_SINGLE_FLIGHT_ITINERARY;
  itineraryId: string;
}

export const fetchSingleFlightItinerary = (
  itineraryId: string
): IFetchSingleFlightItinerary => ({
  type: actionTypes.FETCH_SINGLE_FLIGHT_ITINERARY,
  itineraryId,
});

export interface ISetSingleFlightItinerary {
  type: actionTypes.SET_SINGLE_FLIGHT_ITINERARY;
  response: SingleFlightItineraryResponse;
}

export const setSingleFlightItinerary = (args: {
  response: SingleFlightItineraryResponse;
}): ISetSingleFlightItinerary => ({
  type: actionTypes.SET_SINGLE_FLIGHT_ITINERARY,
  ...args,
});

export interface ISetFetchSingleFlightItineraryCallStateFailed {
  type: actionTypes.SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED;
}

export const setFetchSingleFlightItineraryCallStateFailed =
  (): ISetFetchSingleFlightItineraryCallStateFailed => ({
    type: actionTypes.SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED,
  });

export interface ISetDisruptedFlightItinerarySliceAndSegmentIds {
  type: actionTypes.SET_DISRUPTED_FLIGHT_ITINERARY_SLICE_AND_SEGMENT_INDEXES;
  sliceIndex: number;
  segmentIndex: number;
}

export const setDisruptedFlightItinerarySliceAndSegmentIds = (
  sliceIndex: number,
  segmentIndex: number
) => ({
  type: actionTypes.SET_DISRUPTED_FLIGHT_ITINERARY_SLICE_AND_SEGMENT_INDEXES,
  sliceIndex,
  segmentIndex,
});

export interface IExerciseDisruptionProtectionRebook {
  type: actionTypes.EXERCISE_DISRUPTION_PROTECTION_REBOOK;
  agentFee?: number;
}

export const exerciseDisruptionProtectionRebook = (args: {
  agentFee?: number;
}): IExerciseDisruptionProtectionRebook => ({
  type: actionTypes.EXERCISE_DISRUPTION_PROTECTION_REBOOK,
  ...args,
});

export interface ISetExerciseDisruptionProtectionRebookCallStateSuccess {
  type: actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_SUCCESS;
}

export const setExerciseDisruptionProtectionRebookCallStateSuccess =
  (): ISetExerciseDisruptionProtectionRebookCallStateSuccess => ({
    type: actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_SUCCESS,
  });

export interface ISetExerciseDisruptionProtectionRebookCallStateFailed {
  type: actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_FAILED;
  failureReason: string,
}

export const setExerciseDisruptionProtectionRebookCallStateFailed = (
  failureReason: string
): ISetExerciseDisruptionProtectionRebookCallStateFailed => ({
    type: actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_FAILED,
    failureReason,
  });

export interface ISetExerciseDisruptionProtectionRebookCallStateNotCalled {
  type: actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_NOT_CALLED;
}

export const setExerciseDisruptionProtectionRebookCallStateNotCalled =
  (): ISetExerciseDisruptionProtectionRebookCallStateNotCalled => ({
    type: actionTypes.SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_NOT_CALLED,
  });

export interface IFetchFlightDisruptionsByItineraryId {
  type: actionTypes.FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID;
  itineraryId: string;
}

export const fetchFlightDisruptionsByItineraryId = (
  itineraryId: string
): IFetchFlightDisruptionsByItineraryId => ({
  type: actionTypes.FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID,
  itineraryId,
});

export interface ISetFlightDisruptions {
  type: actionTypes.SET_FLIGHT_DISRUPTIONS;
  flightDisruptions: GetExerciseEligibilitiesResponseV1;
}

export const setFlightDisruptions = (
  flightDisruptions: GetExerciseEligibilitiesResponseV1
): ISetFlightDisruptions => ({
  type: actionTypes.SET_FLIGHT_DISRUPTIONS,
  flightDisruptions,
});

export interface ISetFetchFlightDisruptionsCallStateFailed {
  type: actionTypes.SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED;
}

export const setFetchFlightDisruptionsCallStateFailed =
  (): ISetFetchFlightDisruptionsCallStateFailed => ({
    type: actionTypes.SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED,
  });

export interface IBatchFetchCfarOffers {
  type: actionTypes.BATCH_FETCH_CFAR_OFFERS;
  variant: "return-flights" | "default";
  requests: FetchCfarOfferRequestV2[];
}

export const batchFetchCfarOffers = (
  requests: FetchCfarOfferRequestV2[],
  variant?: "return-flights" | "default"
): IBatchFetchCfarOffers => ({
  type: actionTypes.BATCH_FETCH_CFAR_OFFERS,
  variant: variant ?? "default",
  requests,
});

export interface ISetCfarOffers {
  type: actionTypes.SET_CFAR_OFFERS;
  variant: "return-flights" | "default";
  cfarOffers: {
    [tripId: string]: { [fareId: string]: FetchCfarOfferResponseV2 };
  };
}

export const setCfarOffers = (
  cfarOffers: {
    [tripId: string]: { [fareId: string]: FetchCfarOfferResponseV2 };
  },
  variant?: "return-flights" | "default"
): ISetCfarOffers => ({
  type: actionTypes.SET_CFAR_OFFERS,
  variant: variant ?? "default",
  cfarOffers,
});

export interface IResetCfarOffers {
  type: actionTypes.RESET_CFAR_OFFERS;
}

export const resetCfarOffers = (): IResetCfarOffers => ({
  type: actionTypes.RESET_CFAR_OFFERS,
});

export interface ISetBatchCfarOffersCallStateFailed {
  type: actionTypes.SET_BATCH_CFAR_OFFERS_CALL_STATE_FAILED;
}

export const setBatchCfarOffersCallStateFailed =
  (): ISetBatchCfarOffersCallStateFailed => ({
    type: actionTypes.SET_BATCH_CFAR_OFFERS_CALL_STATE_FAILED,
  });

export interface ISetAncillaryExperiments {
  type: actionTypes.SET_ANCILLARY_EXPERIMENTS;
  experiments: IFlightAncillaryExperiments;
}

export const setAncillaryExperiments = (
  experiments: IFlightAncillaryExperiments
): ISetAncillaryExperiments => {
  return {
    type: actionTypes.SET_ANCILLARY_EXPERIMENTS,
    experiments,
  };
};

export interface IFetchAncillaryPostBookingOffer {
  type: actionTypes.FETCH_ANCILLARY_POST_BOOKING_OFFER;
  request?: AncillaryPostBookingFetchOfferRequest;
}

export const fetchPostBookingOffer = (args?: {
  request?: AncillaryPostBookingFetchOfferRequest;
}): IFetchAncillaryPostBookingOffer => ({
  type: actionTypes.FETCH_ANCILLARY_POST_BOOKING_OFFER,
  ...args,
});

export interface ISetFetchAncillaryPostBookingOfferResponse {
  type: actionTypes.SET_FETCH_ANCILLARY_POST_BOOKING_OFFER_RESPONSE;
  response: AncillaryPostBookingFetchOfferSuccess;
}

export const setFetchAncillaryPostBookingOfferResponse = (
  response: AncillaryPostBookingFetchOfferSuccess
): ISetFetchAncillaryPostBookingOfferResponse => ({
  type: actionTypes.SET_FETCH_ANCILLARY_POST_BOOKING_OFFER_RESPONSE,
  response,
});

export interface IResetFetchAncillaryPostBookingOfferState {
  type: actionTypes.RESET_FETCH_ANCILLARY_POST_BOOKING_OFFER_STATE;
}

export const resetFetchAncillaryPostBookingOfferState =
  (args?: {}): IResetFetchAncillaryPostBookingOfferState => ({
    type: actionTypes.RESET_FETCH_ANCILLARY_POST_BOOKING_OFFER_STATE,
    ...args,
  });

export interface IPurchaseAncillaryPostBookingOffer {
  type: actionTypes.PURCHASE_ANCILLARY_POST_BOOKING_OFFER;
  offer: AncillaryPostBookingFetchOfferSuccess;
  request: AncillaryPostBookingPurchaseRequest;
}

export const purchasePostBookingOffer = (args: {
  offer: AncillaryPostBookingFetchOfferSuccess;
  request: AncillaryPostBookingPurchaseRequest;
}): IPurchaseAncillaryPostBookingOffer => ({
  type: actionTypes.PURCHASE_ANCILLARY_POST_BOOKING_OFFER,
  ...args,
});

export interface ISetPurchaseAncillaryPostBookingOfferResponse {
  type: actionTypes.SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE;
  response: AncillaryPostBookingPurchaseSuccess;
}

export const setPurchaseAncillaryPostBookingOfferResponse = (
  response: AncillaryPostBookingPurchaseSuccess
): ISetPurchaseAncillaryPostBookingOfferResponse => ({
  type: actionTypes.SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE,
  response,
});

export interface ISetPurchasencillaryPostBookingOfferCallStateFailed {
  offer: AncillaryPostBookingFetchOfferSuccess;
  request: AncillaryPostBookingPurchaseRequest;
  response: AncillaryPostBookingPurchaseFailure;
  type: actionTypes.SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE_CALL_STATE_FAILED;
}

export const setPurchasencillaryPostBookingOfferCallStateFailed = (args: {
  offer: AncillaryPostBookingFetchOfferSuccess;
  request: AncillaryPostBookingPurchaseRequest;
  response: AncillaryPostBookingPurchaseFailure;
}): ISetPurchasencillaryPostBookingOfferCallStateFailed => ({
  type: actionTypes.SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE_CALL_STATE_FAILED,
  ...args,
});

export interface ICompletePostBookingOfferScreen {
  type: actionTypes.COMPLETE_POST_BOOKING_OFFER_SCREEN;
}

export const postBookingOfferComplete =
  (): ICompletePostBookingOfferScreen => ({
    type: actionTypes.COMPLETE_POST_BOOKING_OFFER_SCREEN,
  });

export interface ISetAutoApproval {
  type: actionTypes.SET_AUTO_APPROVAL;
  isAutoApprovalEnabled: boolean;
}

export const setAutoApproval = (args: {
  isAutoApprovalEnabled: boolean;
}): ISetAutoApproval => ({
  type: actionTypes.SET_AUTO_APPROVAL,
  ...args,
});

export type FlightAncillaryActions =
  | ISetOriginalFlightItineraryId
  | IFetchRebookSummary
  | ISetRebookSummaryCallStateFailed
  | ISetRebookSummaryResponse
  | ISetTodayTomorrowDateFilter
  | IFetchSingleFlightItinerary
  | ISetSingleFlightItinerary
  | ISetFetchSingleFlightItineraryCallStateFailed
  | ISetDisruptedFlightItinerarySliceAndSegmentIds
  | IExerciseDisruptionProtectionRebook
  | ISetExerciseDisruptionProtectionRebookCallStateSuccess
  | ISetExerciseDisruptionProtectionRebookCallStateFailed
  | ISetExerciseDisruptionProtectionRebookCallStateNotCalled
  | IFetchFlightDisruptionsByItineraryId
  | ISetFlightDisruptions
  | ISetFetchFlightDisruptionsCallStateFailed
  | IBatchFetchCfarOffers
  | ISetCfarOffers
  | IResetCfarOffers
  | ISetBatchCfarOffersCallStateFailed
  | ISetAncillaryExperiments
  | IFetchAncillaryPostBookingOffer
  | ISetFetchAncillaryPostBookingOfferResponse
  | IResetFetchAncillaryPostBookingOfferState
  | IPurchaseAncillaryPostBookingOffer
  | ISetPurchaseAncillaryPostBookingOfferResponse
  | ISetPurchasencillaryPostBookingOfferCallStateFailed
  | ICompletePostBookingOfferScreen
  | ISetAutoApproval;
