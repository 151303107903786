import React from "react";
import { MobileFlightDetailsModal as Modal, useDeviceTypes } from "halifax";
import { FareDetails, TripDetails } from "redmond";
import { getSliceIndex } from "../../../../../../utils/flights";
import { getSummaryPanelHeader } from "../../../FlightShopSummaryPanel/constants";

import clsx from "clsx";
import { getPlusDays } from "../../../../reducer/utils";
import { getIsMixedClass } from "../../../../constants";
export interface IMobileFlightDetailsModalProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails | undefined;
  openModal: boolean;
  onClose: () => void;
  onClick: () => void;
  buttonText?: string;
}

export const MobileFlightDetailsModal = (
  props: IMobileFlightDetailsModalProps
) => {
  const { matchesMobile } = useDeviceTypes();
  const { departure, tripDetails, fareDetails } = props;
  const sliceIndex = tripDetails ? getSliceIndex(departure, tripDetails) : 0;
  const tripSlice = tripDetails?.slices[sliceIndex];
  const [isMixedCabinClass, setIsMixedCabinClass] = React.useState(false);

  React.useEffect(() => {
    if (fareDetails) {
      departure
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  const renderCardHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");
    return (
      <>
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
      </>
    );
  };
  return (
    <>
      {matchesMobile && (
        <Modal
          {...props}
          title={renderCardHeader(
            getSummaryPanelHeader(
              props.departure,
              tripSlice?.destinationCode,
              tripSlice?.departureTime,
              true
            )
          )}
          className={clsx("b2b", "b2b-secondary")}
          plusDays={tripSlice ? getPlusDays(tripSlice) : 0}
          isMixedCabinClass={isMixedCabinClass}
        />
      )}
    </>
  );
};
