import * as actionTypes from "./constants";
import { History } from "history";
import {
  Offer,
  CallState,
  IPassengerCounts,
  GetPriceFreezeResponse,
  PriceFreezePriceQuote,
  PriceFreezeOfferDataWithRewards,
  QuoteTokenKey,
  PollFareQuoteOutcome,
  TravelWalletCredit,
  PriceFreezeEntryPointEnum,
} from "redmond";
import {
  PriceFreezeFareQuoteErrorTitlesType,
  IFlightFreezeExperiments,
} from "../reducer";

export const setPriceFreezeOffer = (args: {
  priceFreezeOfferCallState: CallState;
  offer: Offer | null;
}): ISetPriceFreezeOffer => ({
  type: actionTypes.SET_PRICE_FREEZE_OFFER,
  ...args,
});

export interface ISetPriceFreezeOffer {
  type: actionTypes.SET_PRICE_FREEZE_OFFER;
  priceFreezeOfferCallState: CallState;
  offer: Offer | null;
}

export const getPriceFreezeOffer = (
  offerId: string,
  history: History
): IGetPriceFreezeOffer => ({
  type: actionTypes.GET_PRICE_FREEZE_OFFER,
  offerId,
  history,
});

export interface IGetPriceFreezeOffer {
  type: actionTypes.GET_PRICE_FREEZE_OFFER;
  offerId: string;
  history: History;
}

export const setPriceFreezeOfferCallStateFailed =
  (): ISetPriceFreezeOfferCallStateFailed => ({
    type: actionTypes.SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED,
  });

export interface ISetPriceFreezeOfferCallStateFailed {
  type: actionTypes.SET_PRICE_FREEZE_OFFER_CALL_STATE_FAILED;
}

export const setUpFlightFreezeParams = (args: {
  history: History;
  isMobile?: boolean;
}): ISetUpFlightFreezeParams => ({
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS,
  ...args,
});

export interface ISetUpFlightFreezeParams {
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS;
  history: History;
  isMobile?: boolean;
}

export const setUpFlightFreezeParamsCallStateFailed =
  (): ISetUpFlightFreezeParamsCallStateFailed => ({
    type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED,
  });

export interface ISetUpFlightFreezeParamsCallStateFailed {
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_FAILED;
}

export const setUpFlightFreezeParamsCallStateSucceeded =
  (): ISetUpFlightFreezeParamsCallStateSucceeded => ({
    type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED,
  });

export interface ISetUpFlightFreezeParamsCallStateSucceeded {
  type: actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS_CALL_STATE_SUCCEEDED;
}

export const setPassengerCounts = (
  counts: IPassengerCounts
): ISetPassengerCounts => ({
  type: actionTypes.SET_PASSENGER_COUNTS,
  counts,
});

export interface ISetPassengerCounts {
  type: actionTypes.SET_PASSENGER_COUNTS;
  counts: IPassengerCounts;
}

export const setPriceFreezeQuoteData = (
  quoteData: PriceFreezePriceQuote
): ISetPriceFreezeQuoteData => ({
  type: actionTypes.SET_PRICE_FREEZE_QUOTE_DATA,
  quoteData,
});

export interface ISetPriceFreezeQuoteData {
  type: actionTypes.SET_PRICE_FREEZE_QUOTE_DATA;
  quoteData: PriceFreezePriceQuote;
}

export const getPriceFreezeOfferData = (
  departureDate: string,
  tripId: string,
  fareId: string,
  knockOutDurations: boolean,
  showPriceFreezeEntryPointDurations: boolean,
  history: History,
  entryPoint?: PriceFreezeEntryPointEnum,
): IGetPriceFreezeOfferData => ({
  type: actionTypes.GET_PRICE_FREEZE_OFFER_DATA,
  departureDate,
  tripId,
  fareId,
  knockOutDurations,
  showPriceFreezeEntryPointDurations,
  history,
  entryPoint,
});

export interface IGetPriceFreezeOfferData {
  type: actionTypes.GET_PRICE_FREEZE_OFFER_DATA;
  departureDate: string;
  tripId: string;
  fareId: string;
  knockOutDurations: boolean;
  showPriceFreezeEntryPointDurations: boolean;
  history: History;
  entryPoint?: PriceFreezeEntryPointEnum;
}

export const setGetPriceFreezeOfferDataCallStateFailed =
  (): ISetGetPriceFreezeOfferDataCallStateFailed => ({
    type: actionTypes.SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED,
  });

export interface ISetGetPriceFreezeOfferDataCallStateFailed {
  type: actionTypes.SET_GET_PRICE_FREEZE_OFFER_DATA_CALL_STATE_FAILED;
}

export const setPriceFreezeOfferData = (
  offerData: PriceFreezeOfferDataWithRewards[]
): ISetPriceFreezeOfferData => ({
  type: actionTypes.SET_PRICE_FREEZE_OFFER_DATA,
  offerData,
});

export interface ISetPriceFreezeOfferData {
  type: actionTypes.SET_PRICE_FREEZE_OFFER_DATA;
  offerData: PriceFreezeOfferDataWithRewards[];
}

export const setPriceFreezeOfferRadioDurations = (
  radioDurations: PriceFreezeOfferDataWithRewards[]
): ISetPriceFreezeOfferRadioDurations => ({
  type: actionTypes.SET_PRICE_FREEZE_OFFER_RADIO_DURATIONS,
  radioDurations,
});

export interface ISetPriceFreezeOfferRadioDurations {
  type: actionTypes.SET_PRICE_FREEZE_OFFER_RADIO_DURATIONS;
  radioDurations: PriceFreezeOfferDataWithRewards[];
}

export const setSelectedPriceFreezeOfferDataIndex = (
  index: number | null
): ISetSelectedPriceFreezeOfferDataIndex => ({
  type: actionTypes.SET_SELECTED_PRICE_FREEZE_OFFER_DATA_INDEX,
  index,
});

export interface ISetSelectedPriceFreezeOfferDataIndex {
  type: actionTypes.SET_SELECTED_PRICE_FREEZE_OFFER_DATA_INDEX;
  index: number | null;
}

export const setSelectedPriceFreezeRadioDataIndex = (
  index: number
): ISetSelectedPriceFreezeRadioDataIndex => ({
  type: actionTypes.SET_SELECTED_PRICE_FREEZE_RADIO_INDEX,
  index,
});

export interface ISetSelectedPriceFreezeRadioDataIndex {
  type: actionTypes.SET_SELECTED_PRICE_FREEZE_RADIO_INDEX;
  index: number;
}

export const setInitialSelectedPriceFreezeRadioDataIndex = (
  index: number
): ISetInitialSelectedPriceFreezeRadioDataIndex => ({
  type: actionTypes.SET_INITIAL_SELECTED_PRICE_FREEZE_RADIO_INDEX,
  index,
});

export interface ISetInitialSelectedPriceFreezeRadioDataIndex {
  type: actionTypes.SET_INITIAL_SELECTED_PRICE_FREEZE_RADIO_INDEX;
  index: number;
}

export const setInitialPriceFreezeOfferDataWithRewards = (
  offerData: PriceFreezeOfferDataWithRewards
): ISetInitialPriceFreezeOfferDataWithRewards => ({
  type: actionTypes.SET_INITIAL_PRICE_FREEZE_OFFER_DATA_WITH_REWARDS,
  offerData,
});

export interface ISetInitialPriceFreezeOfferDataWithRewards {
  type: actionTypes.SET_INITIAL_PRICE_FREEZE_OFFER_DATA_WITH_REWARDS;
  offerData: PriceFreezeOfferDataWithRewards;
}

export const generateCustomPriceFreezeOffer = (
  offerData: PriceFreezeOfferDataWithRewards,
  history: History,
  isFromTripSummariesV3: boolean
): IGenerateCustomPriceFreezeOffer => ({
  type: actionTypes.GENERATE_CUSTOM_PRICE_FREEZE_OFFER,
  offerData,
  history,
  isFromTripSummariesV3,
});

export interface IGenerateCustomPriceFreezeOffer {
  type: actionTypes.GENERATE_CUSTOM_PRICE_FREEZE_OFFER;
  offerData: PriceFreezeOfferDataWithRewards;
  history: History;
  isFromTripSummariesV3: boolean;
}

export const setCheapestEligibleFlightCustomPriceFreezeOffer = (
  offer: Offer
): ISetCheapestEligibleFlightCustomPriceFreezeOffer => ({
  type: actionTypes.SET_CHEAPEST_ELIGIBLE_FLIGHT_CUSTOM_PRICE_FREEZE_OFFER,
  offer,
});

export interface ISetCheapestEligibleFlightCustomPriceFreezeOffer {
  type: actionTypes.SET_CHEAPEST_ELIGIBLE_FLIGHT_CUSTOM_PRICE_FREEZE_OFFER;
  offer: Offer;
}

export const setGenerateCustomPriceFreezeOfferCallStateSuccess =
  (): ISetGenerateCustomPriceFreezeOfferCallStateSuccess => ({
    type: actionTypes.SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_SUCCESS,
  });

export interface ISetGenerateCustomPriceFreezeOfferCallStateSuccess {
  type: actionTypes.SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_SUCCESS;
}

export const setGenerateCustomPriceFreezeOfferCallStateFailed =
  (): ISetGenerateCustomPriceFreezeOfferCallStateFailed => ({
    type: actionTypes.SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED,
  });

export interface ISetGenerateCustomPriceFreezeOfferCallStateFailed {
  type: actionTypes.SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED;
}

export const setCustomPriceFreezeOffer = (
  offer: Offer
): ISetCustomPriceFreezeOffer => ({
  type: actionTypes.SET_CUSTOM_PRICE_FREEZE_OFFER,
  offer,
});

export interface ISetCustomPriceFreezeOffer {
  type: actionTypes.SET_CUSTOM_PRICE_FREEZE_OFFER;
  offer: Offer;
}

export const resetPriceFreezePurchase = (): IResetPriceFreezePurchase => ({
  type: actionTypes.RESET_PRICE_FREEZE_PURCHASE,
});

export interface IResetPriceFreezePurchase {
  type: actionTypes.RESET_PRICE_FREEZE_PURCHASE;
}

export const initializeOfferDataAndCustomOffer = (args: {
  departureDate: string;
  tripId: string;
  fareId: string;
  history: History;
  entryPoint?: PriceFreezeEntryPointEnum,
}): IInitializeOfferDataAndCustomOffer => ({
  type: actionTypes.INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER,
  ...args,
});

export interface IInitializeOfferDataAndCustomOffer {
  type: actionTypes.INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER;
  departureDate: string;
  tripId: string;
  fareId: string;
  history: History;
  entryPoint?: PriceFreezeEntryPointEnum,
}

export type FlightFreezeActions =
  | IGetPriceFreezeOffer
  | ISetPriceFreezeOffer
  | ISetPriceFreezeOfferCallStateFailed
  | ISetUpFlightFreezeParams
  | ISetUpFlightFreezeParamsCallStateFailed
  | ISetUpFlightFreezeParamsCallStateSucceeded
  | ISetPassengerCounts
  | ISetPriceFreeze
  | ISetPriceFreezeCallStateFailed
  | IFetchPriceFreeze
  | IRefundPriceFreeze
  | ISetRefundPriceFreeze
  | IDisplayRefundPriceFreezeModal
  | IGetPriceFreeze
  | ISetPriceFreezeQuoteData
  | IGetPriceFreezeOfferData
  | ISetGetPriceFreezeOfferDataCallStateFailed
  | ISetPriceFreezeOfferData
  | ISetSelectedPriceFreezeOfferDataIndex
  | IGenerateCustomPriceFreezeOffer
  | ISetGenerateCustomPriceFreezeOfferCallStateFailed
  | ISetCustomPriceFreezeOffer
  | IResetPriceFreezePurchase
  | IInitializeOfferDataAndCustomOffer
  | IFetchPriceFreezeFareQuote
  | ISetPriceFreezeFareQuoteCallStateFailed
  | ISetPriceFreezeFareQuoteErrorTitlesType
  | ISetPriceFreezeFareQuote
  | IFetchPriceFreezeFareQuoteReset
  | IAcknowledgePriceFreezeFareQuoteDetails
  | ISetPriceFreezeExperiments
  | ISetGenerateCustomPriceFreezeOfferCallStateSuccess
  | ISetPriceFreezeEntryPointStep
  | ISetPriceFreezeOfferRadioDurations
  | ISetSelectedPriceFreezeRadioDataIndex
  | ISetInitialPriceFreezeOfferDataWithRewards
  | ISetInitialSelectedPriceFreezeRadioDataIndex
  | ISetCheapestEligibleFlightCustomPriceFreezeOffer
  | IFetchApplicableTravelWalletItems
  | IFetchApplicableTravelWalletItemsCallStateSuccess
  | IFetchApplicableTravelWalletItemsCallStateFailure
  | ISetTravelWalletCredit
  | ISetCreditToApply;

export interface IFetchPriceFreeze {
  type: actionTypes.FETCH_PRICE_FREEZE;
  priceFreezeId: string;
}

export const fetchPriceFreeze = (priceFreezeId: string): IFetchPriceFreeze => ({
  type: actionTypes.FETCH_PRICE_FREEZE,
  priceFreezeId,
});

export interface IGetPriceFreeze {
  type: actionTypes.GET_PRICE_FREEZE;
  frozenPriceId: string;
}

export const getPriceFreeze = (frozenPriceId: string): IGetPriceFreeze => ({
  type: actionTypes.GET_PRICE_FREEZE,
  frozenPriceId,
});

export interface IRefundPriceFreeze {
  type: actionTypes.REFUND_PRICE_FREEZE;
  frozenPriceId: string;
}

export const refundPriceFreeze = (
  frozenPriceId: string
): IRefundPriceFreeze => ({
  type: actionTypes.REFUND_PRICE_FREEZE,
  frozenPriceId,
});

export interface IDisplayRefundPriceFreezeModal {
  type: actionTypes.DISPLAY_PF_REFUND_MODAL_FLOW;
  value: boolean;
}

export const setDisplayPriceFreezeRefundModal = (
  value: boolean
): IDisplayRefundPriceFreezeModal => ({
  type: actionTypes.DISPLAY_PF_REFUND_MODAL_FLOW,
  value,
});

export interface ISetRefundPriceFreeze {
  type: actionTypes.SET_REFUND_PRICE_FREEZE;
  refundPriceFreezeCallState: CallState;
}

export const setRefundPriceFreeze = (args: {
  refundPriceFreezeCallState: CallState;
}): ISetRefundPriceFreeze => ({
  type: actionTypes.SET_REFUND_PRICE_FREEZE,
  ...args,
});

export interface ISetPriceFreeze {
  type: actionTypes.SET_PRICE_FREEZE;
  priceFreeze: GetPriceFreezeResponse;
  priceFreezeCallState: CallState;
}

export const setPriceFreeze = (args: {
  priceFreezeCallState: CallState;
  priceFreeze: GetPriceFreezeResponse;
}): ISetPriceFreeze => ({
  type: actionTypes.SET_PRICE_FREEZE,
  ...args,
});

export interface ISetPriceFreezeCallStateFailed {
  type: actionTypes.SET_PRICE_FREEZE_CALL_STATE_FAILED;
}

export const setPriceFreezeCallStateFailed =
  (): ISetPriceFreezeCallStateFailed => ({
    type: actionTypes.SET_PRICE_FREEZE_CALL_STATE_FAILED,
  });

export interface IFetchPriceFreezeFareQuote {
  type: actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE;
  passengerCountByType: { [key: string]: number };
  tripId: string;
  fareId: string;
  isInPurchaseOnQuoteOnlyXp?: boolean;
}

export const fetchPriceFreezeFareQuote = (args: {
  passengerCountByType: { [key: string]: number };
  tripId: string;
  fareId: string;
  isInPurchaseOnQuoteOnlyXp?: boolean;
}): IFetchPriceFreezeFareQuote => ({
  type: actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE,
  ...args,
});

export interface IFetchPriceFreezeFareQuoteReset {
  type: actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE_STATE_RESET;
}

export const resetFetchPriceFreezeFareQuote = () => ({
  type: actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE_STATE_RESET,
});
export const cancelFetchPriceFreezeFareQuote = () => ({
  type: actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE_CANCELLED,
});

export interface ISetPriceFreezeFareQuoteCallStateFailed {
  type: actionTypes.SET_PRICE_FREEZE_FARE_QUOTE_CALL_STATE_FAILED;
}

export const setPriceFreezeFareQuoteCallStateFailed =
  (): ISetPriceFreezeFareQuoteCallStateFailed => ({
    type: actionTypes.SET_PRICE_FREEZE_FARE_QUOTE_CALL_STATE_FAILED,
  });

export interface ISetPriceFreezeFareQuote {
  type: actionTypes.SET_PRICE_FREEZE_FARE_QUOTE;
  priceFreezeFareQuoteTokenKey: QuoteTokenKey;
  priceFreezeFareQuoteOutcome: PollFareQuoteOutcome;
  shouldIgnoreQuote: boolean;
}

export const setPriceFreezeFareQuote = (args: {
  priceFreezeFareQuoteTokenKey: QuoteTokenKey;
  priceFreezeFareQuoteOutcome: PollFareQuoteOutcome;
  shouldIgnoreQuote: boolean;
}): ISetPriceFreezeFareQuote => ({
  type: actionTypes.SET_PRICE_FREEZE_FARE_QUOTE,
  ...args,
});

export interface ISetPriceFreezeFareQuoteErrorTitlesType {
  type: actionTypes.SET_PRICE_FREEZE_FARE_QUOTE_ERROR_TITLES_TYPE;
  errorTitlesType: PriceFreezeFareQuoteErrorTitlesType | null;
}

export const setPriceFreezeFareQuoteErrorTitlesType = (
  errorTitlesType: PriceFreezeFareQuoteErrorTitlesType | null
): ISetPriceFreezeFareQuoteErrorTitlesType => ({
  type: actionTypes.SET_PRICE_FREEZE_FARE_QUOTE_ERROR_TITLES_TYPE,
  errorTitlesType,
});

export interface IAcknowledgePriceFreezeFareQuoteDetails {
  type: actionTypes.ACKNOWLEDGE_PRICE_FREEZE_FARE_QUOTE_DETAILS;
  acknowledged?: boolean;
}

export const acknowledgePriceFreezeFareQuoteDetails = (
  acknowledged?: boolean
): IAcknowledgePriceFreezeFareQuoteDetails => ({
  type: actionTypes.ACKNOWLEDGE_PRICE_FREEZE_FARE_QUOTE_DETAILS,
  acknowledged,
});

export interface ISetPriceFreezeExperiments {
  type: actionTypes.SET_PRICE_FREEZE_EXPERIMENTS;
  experiments: IFlightFreezeExperiments;
}

export const setPriceFreezeExperiments = (
  experiments: IFlightFreezeExperiments
): ISetPriceFreezeExperiments => ({
  type: actionTypes.SET_PRICE_FREEZE_EXPERIMENTS,
  experiments,
});

export interface ISetPriceFreezeEntryPointStep {
  type: actionTypes.SET_PRICE_FREEZE_ENTRY_POINT_STEP;
  priceFreezeEntryPointStep: number;
}

export const setPriceFreezeEntryPointStep = (
  priceFreezeEntryPointStep: number
): ISetPriceFreezeEntryPointStep => ({
  type: actionTypes.SET_PRICE_FREEZE_ENTRY_POINT_STEP,
  priceFreezeEntryPointStep,
});

export const fetchApplicableTravelWalletItems =
  (): IFetchApplicableTravelWalletItems => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
  });

export interface IFetchApplicableTravelWalletItems {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS;
}

export const setFetchApplicableTravelWalletItemsCallStateSuccess =
  (): IFetchApplicableTravelWalletItemsCallStateSuccess => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS,
  });

export interface IFetchApplicableTravelWalletItemsCallStateSuccess {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS;
}

export const setFetchApplicableTravelWalletItemsCallStateFailure =
  (): IFetchApplicableTravelWalletItemsCallStateFailure => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE,
  });

export interface IFetchApplicableTravelWalletItemsCallStateFailure {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE;
}

export const setTravelWalletCredit = (
  credit?: TravelWalletCredit
): ISetTravelWalletCredit => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT,
  credit,
});

export interface ISetTravelWalletCredit {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT;
  credit?: TravelWalletCredit;
}

export const setCreditToApply = (
  credit?: TravelWalletCredit
): ISetCreditToApply => ({
  type: actionTypes.SET_CREDIT_TO_APPLY,
  credit,
});

export interface ISetCreditToApply {
  type: actionTypes.SET_CREDIT_TO_APPLY;
  credit?: TravelWalletCredit;
}
