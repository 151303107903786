import React, { useEffect } from "react";
import { TravelWalletSelectionConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import {

  TravelWalletSelectionWorkflow,
  getRestrictedATCText,
} from "halifax";
import {
  APPLY_TRAVEL_CREDIT_TITLE,
  APPLY_TRAVEL_OFFER_TITLE,
  getSelectedCreditDescription,
  getSelectedOfferDescription,
  TRAVEL_CREDIT_SUBTITLE,
  TRAVEL_CREDIT_TOTAL_PAYMENT_TEXT,
  TRAVEL_OFFER_SUBTITLE,
} from "./textConstants";

interface ITravelWalletSelectionProps
  extends TravelWalletSelectionConnectorProps,
  RouteComponentProps {
  className?: string;
  isMobile?: boolean;
  disabled?: boolean;
  isAnnualTravelCreditsExperiment?: boolean;
  isCreditAndOfferStackingExperimentV1?: boolean;
  isTravelSale?: boolean;
}

export const FlightFreezeTravelWalletSelection = (props: ITravelWalletSelectionProps) => {
  const {
    credits,
    creditsToApply,
    setCreditToApply,
    disabled,
    isTravelCreditPaymentOnly,
    isMobile,
    selectedPaymentMethodId,
    selectedRewardsPaymentAccountId,
    setSelectedRewardsPaymentTotal,
    resetPaymentCardSelectedAccounts,
    rewardsAccounts,
    isAnnualTravelCreditsExperiment,
    isCreditAndOfferStackingExperimentV1,
    maxApplicableTravelWalletCreditAmount,
    isTravelSale,
  } = props;

  if (!credits) return null;

  useEffect(() => {
    if (selectedPaymentMethodId || selectedRewardsPaymentAccountId) {
      resetPaymentCardSelectedAccounts();
      setSelectedRewardsPaymentTotal("", null, null);
    }
  }, [creditsToApply]);

  const maxApplicableTravelWalletCredit = {
    amount: { amount: maxApplicableTravelWalletCreditAmount, currency: credits.amount.currency },
    id: credits.id,
    breakdown: credits.breakdown,
    trackingPropertiesV2: credits.trackingPropertiesV2,
  }

  return (
    <TravelWalletSelectionWorkflow
      isMobile={isMobile}
      offersAvailable={[]}
      creditsAvailable={maxApplicableTravelWalletCredit}
      creditsToApply={creditsToApply}
      setOfferToApply={() => undefined}
      setCreditToApply={setCreditToApply}
      titles={{
        creditTitle: APPLY_TRAVEL_CREDIT_TITLE,
        creditSubtitle: TRAVEL_CREDIT_SUBTITLE,
        offersTitle: APPLY_TRAVEL_OFFER_TITLE,
        offersSubtitle: TRAVEL_OFFER_SUBTITLE,
      }}
      getCreditDescription={getSelectedCreditDescription}
      getOfferDescription={getSelectedOfferDescription}
      disabled={disabled}
      totalCoverageTexts={{
        creditsCoverTotalText: isTravelCreditPaymentOnly
          ? TRAVEL_CREDIT_TOTAL_PAYMENT_TEXT
          : undefined,
        offerCoversTotalText: undefined,
        travelWalletCoverTotalText: undefined
      }}
      isTravelWalletCreditPaymentOnly={isTravelCreditPaymentOnly}
      maxApplicableCreditAmount={credits.amount.amount}
      isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
      isCreditAndOfferStackingExperimentV1={
        isCreditAndOfferStackingExperimentV1
      }
      restrictedATCText={getRestrictedATCText(
        rewardsAccounts,
        isAnnualTravelCreditsExperiment ?? false
      )}
      isTravelSale={isTravelSale}
    />
  );
};
