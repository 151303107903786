import { connect, ConnectedProps } from "react-redux";
import { TripCategory } from "redmond";

import { IStoreState } from "../../../../../../reducers/types";
import { populateFlightShopQueryParams } from "../../../../../shop/actions/actions";
import {
  getDepartureDate,
  getReturnDate,
  getTripCategory,
} from "../../../../reducer";
import { MobileCalendarPicker } from "./component";

const mapDispatchToProps = {
  populateFlightShopQueryParams,
};
const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);
  const tripCategory = getTripCategory(state);

  const readyToSearch =
    (tripCategory === TripCategory.ONE_WAY && departureDate) ||
    (tripCategory === TripCategory.ROUND_TRIP && departureDate && returnDate);

  return {
    readyToSearch,
    tripCategory
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCalendarPickerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPicker = connector(MobileCalendarPicker);
