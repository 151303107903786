import React, { PropsWithChildren } from "react";
import { ButtonWrap, Icon, IconName } from "halifax";
import clsx from "clsx";
import "./styles.scss";
import { Box } from "@material-ui/core";

interface CardWrapperProps extends PropsWithChildren {
  title: string;
  displayEdit: boolean;
  onEdit?: () => void;
  disabled: boolean;
  subtitle?: string;
  progress?: number;
  headerImage?: string;
  mobile?: boolean;
  location?: string;
}

const EDIT = "Edit";

export const CardWrapper = ({
  title,
  displayEdit,
  onEdit,
  children,
  disabled,
  subtitle,
  mobile,
  headerImage,
  progress,
  location,
}: CardWrapperProps) => {
  return (
    <div
      className={clsx("ancillary-card-wrapper-container", { disabled, mobile })}
    >
      <div
        className="header-section"
        style={
          headerImage != null ? { backgroundImage: `url(${headerImage})` } : {}
        }
      >
        {headerImage != null ? <Box className="color-layer" /> : null}
        {mobile ? (
          <div className="top-row">
            {progress != null ? (
              progress == 1 ? (
                <span>
                  <Icon
                    name={IconName.OneCircleFilled}
                    className="icon-spacing"
                  />
                  <Icon name={IconName.TwoCircleNotFilled} />
                </span>
              ) : (
                <span>
                  <Icon
                    name={IconName.OneCircleNotFilled}
                    className="icon-spacing"
                  />
                  <Icon name={IconName.TwoCircleFilled} />
                </span>
              )
            ) : null}
            <span className="location-text">
              <Icon className="image-location-icon" name={IconName.B2BMapPin} />
              <span>{location}</span>
            </span>
          </div>
        ) : null}
        <div className="header-title-row">
          <span className="header-title">{title}</span>
          {displayEdit && (
            <ButtonWrap className="edit-button" onClick={onEdit}>
              {EDIT}
            </ButtonWrap>
          )}
        </div>
        <div className="subtitle-row">{subtitle}</div>
      </div>
      {!disabled && <div className="body-section">{children}</div>}
    </div>
  );
};
