import React, { useState, useRef } from "react";
import { Box } from "@material-ui/core";
import {
  MobileOutlinedTextInput,
  MobileAutocompleteOptions,
  MobilePopoverCard,
  IconName,
  Icon,
  ActionButton,
  InfoIcon,
  RecentlySearchedList,
} from "halifax";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { PATH_HOME } from "../../../../../../utils/urlPaths";
import * as H from "history";

import "./styles.scss";
import { PassengerCountPicker } from "../../../FlightSearchControl/components/PassengerCountPicker";
import { LocationSearchConnectorProps } from "./container";
import { RecentFlightSearch } from "redmond";

export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: () => void;
  history: H.History;
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
  onSelectLocation?: (value: any) => void;
}
export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    origin,
    destination,
    numTravelers,
    loading,
    originCategories,
    destinationCategories,
    fetchOriginCategories,
    fetchDestinationCategories,
    setOrigin,
    setDestination,
    onComplete,
    readyToContinue,
    hasOriginAutocompleteError,
    hasDestinationAutocompleteError,
    history,
    setOriginAutocompleteError,
    setDestinationAutocompleteError,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
  } = props;
  const [originFocused, setOriginFocused] = useState<boolean>(false);
  const [destinationFocused, setDestinationFocused] = useState<boolean>(false);
  const [originSearchString, setOriginSearchString] = useState<string>(
    origin?.label || ""
  );
  const [scrollLock, setScrollLock] = React.useState<boolean>(false);
  const [originLock, setOriginLock] = React.useState<boolean>(false);
  const [destinationLock, setDestinationLock] = React.useState<boolean>(false);
  const [destinationSearchString, setDestinationSearchString] =
    useState<string>(destination?.label || "");
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState<boolean>(false);
  const numTravelerString =
    numTravelers === 1
      ? `${numTravelers} Traveler`
      : `${numTravelers} Travelers`;

  const onOriginSearch = (searchVal: string) => {
    hasOriginAutocompleteError && setOriginAutocompleteError(false);
    setOrigin(null);
    setOriginSearchString(searchVal);
    fetchOriginCategories(searchVal);
  };

  const onDestinationSearch = (searchVal: string) => {
    hasDestinationAutocompleteError && setDestinationAutocompleteError(false);
    setDestination(null);
    setDestinationSearchString(searchVal);
    fetchDestinationCategories(searchVal);
  };

  const onOriginSelected = (value: any) => {
    setOriginFocused(false);
    setOriginSearchString(value.label);
    setOrigin(value);
    onSelectLocation?.(value);
    if (destination && onComplete) {
      onComplete();
    }
  };

  const element = useRef<HTMLInputElement | null>(null);
  const onDestinationSelected = (value: any) => {
    setDestinationFocused(false);
    setDestinationSearchString(value.label);
    setDestination(value);
    onSelectLocation?.(value);
    if (origin && onComplete) {
      onComplete();
    }
    if (!origin) {
      setOriginFocused(true);
      setOrigin(null);
      if (element.current) {
        element.current.focus();
      }
    }
  };

  const handleScrollToTop = () => {
    // there are roughly 110px above location fields; delay is required to overwrite iOS default scrolling
    // for more info: https://stackoverflow.com/questions/60797340/ios-safari-prevent-or-control-scroll-on-input-focus
    // TODO: figure out how to properly overwrite scroll position for focused inputs on iOS and Android
    setTimeout(() => !scrollLock && window.scrollTo(0, 110), 60);
  };

  React.useEffect(() => {
    // while one of the fields is being focused, clicking on the other field will trigger onBlur first, which will cause
    // setScrollLock(false) and therefore window.scrollTo(0, 110). When window.screenTop is close to 100, running scrollTo
    // will cause a jiggling motion. Relying on setTimeout as a workaround to avoid causing the jiggle.
    if (!originLock && !destinationLock) {
      setTimeout(() => setScrollLock(false), 30);
    } else {
      setTimeout(() => setScrollLock(true), 35);
    }
  }, [originLock, destinationLock]);

  return (
    <Box className="mobile-flight-search-location">
      <Box className="mobile-location-pickers">
        <MobileOutlinedTextInput
          className={clsx("num-traveler-count", "mobile")}
          prefixString={numTravelerString}
          icon={faUser}
          showCloseIcon={false}
          customEndIcon={<Icon name={IconName.B2BEditPencil} />}
          onClick={() => setOpenPassengerCountPicker(true)}
          onChange={() => {}}
          value={""}
        />
        <MobileOutlinedTextInput
          ref={element}
          className={clsx("origin-auto-complete", "mobile", {
            active: originFocused || (!origin && !destinationFocused),
          })}
          prefixString={origin ? origin.label : "Where from?"}
          customIcon={
            <Box className="icon-wrapper">
              <Icon className="circle-icon" name={IconName.B2BOriginIcon} />
              <Icon className="line-icon" name={IconName.DottedLine} />
            </Box>
          }
          onFocus={() => {
            setOriginFocused(true);
            setDestinationFocused(false);
            setOriginLock(true);
            handleScrollToTop();
          }}
          onBlur={() => setOriginLock(false)}
          onChange={(value: string) => onOriginSearch(value)}
          value={originSearchString}
          autoFocus={true}
        />
        <MobileOutlinedTextInput
          className={clsx("destination-auto-complete", "mobile", {
            active: destinationFocused || (!destination && !originFocused),
          })}
          prefixString={destination ? destination.label : "Where to?"}
          customIcon={
            <Box className="icon-wrapper">
              <Icon className="plane-icon" name={IconName.B2BAirplaneIcon} />
              <Icon className="line-icon" name={IconName.DottedLine} />
            </Box>
          }
          onFocus={() => {
            setDestinationFocused(true);
            setOriginFocused(false);
            setDestinationLock(true);
            handleScrollToTop();
          }}
          onBlur={() => setDestinationLock(false)}
          onChange={(value: string) => onDestinationSearch(value)}
          value={destinationSearchString}
        />
        {(hasOriginAutocompleteError || hasDestinationAutocompleteError) && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span
              onClick={() => {
                history.push(PATH_HOME);
                hasOriginAutocompleteError && setOriginAutocompleteError(false);
                hasDestinationAutocompleteError &&
                  setDestinationAutocompleteError(false);
              }}
              className="try-again-text"
            >
              Try again.
            </span>
          </Box>
        )}
      </Box>
      <MobilePopoverCard
        open={openPassengerCountPicker}
        className={clsx("mobile-passenger-count-picker-popup", "b2b")}
        contentClassName="mobile-passenger-count-picker-popup-container"
        onClose={() => setOpenPassengerCountPicker(false)}
        centered={true}
      >
        <PassengerCountPicker
          minimumAdultsCount={1}
          onClickApply={() => setOpenPassengerCountPicker(false)}
          className="b2b"
        />
      </MobilePopoverCard>
      {originFocused && (
        <MobileAutocompleteOptions
          value={null}
          valueCategories={originCategories}
          setValue={(value: any) => onOriginSelected(value)}
          loading={loading}
          loadingText={"Loading"}
        />
      )}
      {destinationFocused && (
        <MobileAutocompleteOptions
          value={null}
          valueCategories={destinationCategories}
          setValue={(value: any) => onDestinationSelected(value)}
          loading={loading}
          loadingText={"Loading"}
        />
      )}
      {!loading &&
        originFocused &&
        originSearchString.length === 0 &&
        originCategories &&
        (originCategories?.length === 0 ||
          originCategories[0]?.results.length === 0) &&
        !!recentSearches &&
        recentSearches.length > 0 &&
        !!onRecentSearchClick && (
          <div
            className={clsx("mobile-recently-searched-flights-container", {
              "has-results": originCategories?.length > 0,
            })}
          >
            <RecentlySearchedList
              recentSearches={recentSearches}
              onRecentSearchClick={(recentSearch) =>
                onRecentSearchClick(recentSearch as RecentFlightSearch)
              }
            />
          </div>
        )}
      {readyToContinue && (
        <ActionButton
          className={clsx("mobile-autocomplete-continue-button", "b2b")}
          message={"Continue"}
          onClick={() => onComplete && onComplete()}
        />
      )}
    </Box>
  );
};
