import React from "react";
import {
  TimeToLive,
  FiatPrice,
  RewardsPrice,
  Airport,
  ITripTerminus,
} from "redmond";
import { getTotalPriceText } from "halifax";
import { getDurationText } from "../../constants";
import { getPriceFreezeRewardsString } from "../../utils/helpers";
import dayjs from "dayjs";
import { getCityNameAndAirportCodeFromTerminus } from "../../../../utils/terminus";

export const getChooseFlightHeaderText = (
  isDeparture: boolean,
  location: string
) => {
  // TODO: we should get the location without state/airport code from BE since we cannot guarantee split method to work 100%
  const getShortLabel = (label: string) => {
    return label.split(",")[0];
  };
  return `<strong>Choose ${
    isDeparture ? "outbound" : "return"
  } </strong>flight to ${getShortLabel(location)}`;
};

export const getChooseFlightSubheader = (isOneWay: boolean) =>
  `Prices are ${isOneWay ? "single trip" : "round trip"}, per traveler`;

export const REVIEW_ITINERARY_HEADER_TEXT = "Review itinerary";
export const REVIEW_ITINERARY_SUBHEADER_TEXT =
  "Review the details of your trip before you continue";

export const SELECT_FROZEN_RETURN_FLIGHT = (useLockPriceLanguage?: boolean) =>
  `Select your ${useLockPriceLanguage ? "locked" : "frozen"} return flight`;
export const getFrozenReturnFlightSubtitle = ({
  price,
  rewards,
  selectedRewardsAccountId,
  timeToLive,
  useLockPriceLanguage,
}: {
  price: FiatPrice;
  rewards?: { [key: string]: RewardsPrice } | null;
  selectedRewardsAccountId?: string | null;
  timeToLive?: TimeToLive;
  useLockPriceLanguage?: boolean;
}) =>
  `Pay a fee of ${getPrices({
    price,
    rewards,
    selectedRewardsAccountId,
    isRewardsBold: true,
  })} per traveler to ${
    useLockPriceLanguage ? "lock" : "freeze"
  } the current price of this trip for ${
    timeToLive ? getDurationText(timeToLive) : ""
  }.`;

export const YOUR_FROZEN_OUTBOUND_FLIGHT = (useLockPriceLanguage?: boolean) =>
  `Your ${useLockPriceLanguage ? "locked" : "frozen"} outbound flight`;
export const PRICE_PER_TRAVELER = "Price per traveler:";
export const ROUND_TRIP = "Round trip";
export const getPrices = ({
  price,
  rewards,
  selectedRewardsAccountId,
  isRewardsBold,
}: {
  price: FiatPrice;
  rewards: { [key: string]: RewardsPrice } | undefined | null;
  selectedRewardsAccountId: string | null | undefined;
  isRewardsBold?: boolean;
}) => {
  const priceCopy = getTotalPriceText({
    price,
  });
  const rewardsCopy = getPriceFreezeRewardsString(
    rewards,
    selectedRewardsAccountId
  );
  const getBold = (input: string) => `<strong>${input}</strong>`;

  return `${getBold(priceCopy)}${
    rewards ? ` or ${isRewardsBold ? getBold(rewardsCopy) : rewardsCopy}` : ""
  }`;
};

export const REFUND_ENTRYPOINT_HEADER =
  "Not satisfied with your rebooking options?";
export const REFUND_ENTRYPOINT_SUBHEADER =
  "Get a refund for the base fare and taxes of your flight.";
export const REFUND_ENTRYPOINT_BUTTON = "Get a refund now";

export const FLIGHT_LIST_TITLE = (
  originAirport?: Airport,
  destinationAirport?: Airport,
  origin?: ITripTerminus,
  destination?: ITripTerminus
) => {
  let originCity = originAirport?.cityName;
  let destinationCity = destinationAirport?.cityName;
  let originAirportCode = originAirport?.code;
  let destinationAirportCode = destinationAirport?.code;

  if (origin && destination) {
    const [orgCityName, orgAirportCode] =
      getCityNameAndAirportCodeFromTerminus(origin);
    const [destCityName, destAirportCode] =
      getCityNameAndAirportCodeFromTerminus(destination);
    originCity = orgCityName;
    destinationCity = destCityName;
    originAirportCode = orgAirportCode.slice(1, -1);
    destinationAirportCode = destAirportCode.slice(1, -1);
  }

  return (
    <>
      <strong>{`${originCity} `}</strong>
      {originAirportCode && ` (${originAirportCode}) `}
      <strong>to {`${destinationCity} `}</strong>
      {destinationAirportCode && ` (${destinationAirportCode}) `}
    </>
  );
};

export const MOBILE_MULTICITY_FLIGHT_LIST_TITLE = (
  originAirport?: Airport,
  destinationAirport?: Airport,
  origin?: ITripTerminus,
  destination?: ITripTerminus
) => {
  if (!(origin || originAirport) || !(destination || destinationAirport))
    return "";
  return `${
    originAirport?.cityName ||
    origin?.label.substring(0, origin?.label.indexOf(","))
  } to ${
    destinationAirport?.cityName ||
    destination?.label.substring(0, destination?.label.indexOf(","))
  }`;
};

export const MOBILE_MULTICITY_FLIGHT_LIST_DATE = (departureDate: Date) =>
  dayjs(departureDate).format("ddd, MMM DD");

export const MOBILE_MULTICITY_FLIGHT_LIST_SUBTITLE =
  "Prices are per one-way flight, per traveler";

export const CORP_MOBILE_MULTICITY_FLIGHT_LIST_SUBTITLE =
  "Prices are for entire multi-city trip, per traveler";

export const FILTERS_MODAL_CTA_TEXT = (appliedFiltersCount: number) =>
  `All filters${appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ""}`;
