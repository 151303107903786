import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { pollFareQuoteApiPrefix } from "../paths";
import { PollFareQuoteRequest, PollFareQuoteResult } from "redmond";

export const pollFareQuote = (
  body: PollFareQuoteRequest
): Promise<PollFareQuoteResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<PollFareQuoteResult> = await axios.post(pollFareQuoteApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve((res.data as any).value);
    } catch (e) {
      reject(e);
    }
  });

export default pollFareQuote;
