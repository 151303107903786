import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  Icon,
  IconName,
  ButtonWrap,
  ActionButton,
  PolicyInfoModal,
  GenericFlightSummary,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { pushToMyTrips, handleRebookingCompleteBuyEvent } from "../../utils";
import { RebookConfirmationConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { RebookSummaryTripType } from "redmond";

export interface IRebookConfirmationProps
  extends RebookConfirmationConnectorProps,
    RouteComponentProps {}

export const RebookConfirmation = (props: IRebookConfirmationProps) => {
  const {
    finalizedItinerary,
    finalizedItineraryId,
    hasUpcomingReturnFlight,
    finalizedRebookItineraryFlightSummaryData,
    reviewItineraryEligibilityDpExerciseFactsProperties,
    rebookedItineraryDpExerciseItineraryFactsProperties,
    rebookedItineraryDpRebookingCompleteBuyFactsProperties,
    isSelectingReturnFlight,
    disruptedFlightDpExerciseFactsProperties,
    history,
    rebookSummary,
    isFintechCsatEnabled,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const [openLuggageModal, setOpenLuggageModal] = useState<boolean>(false);
  const [openReturnFlightModal, setOpenReturnFlightModal] =
    useState<boolean>(false);
  const isRebookSummaryRoundTrip =
    rebookSummary?.tripType === RebookSummaryTripType.ROUND_TRIP;
  const nextStepProps: IListItemProps[] = (() => {
    const steps = [
      {
        index: 1,
        title: constants.IMPORTANT_NEXT_STEP_POINT_ONE_TITLE,
        message: constants.IMPORTANT_NEXT_STEP_POINT_ONE_SUBTITLE,
        button: {
          message: constants.LEARN_MORE,
          ariaLabel: constants.IMPORTANT_NEXT_STEP_POINT_ONE_ARIA_LABEL,
          onClick: () => setOpenLuggageModal(true),
        },
      },
      {
        index: 2,
        title: constants.IMPORTANT_NEXT_STEP_POINT_TWO_TITLE,
        message: constants.IMPORTANT_NEXT_STEP_POINT_TWO_SUBTITLE,
      },
    ];

    if (hasUpcomingReturnFlight && isRebookSummaryRoundTrip) {
      steps.push({
        index: 3,
        title: constants.IMPORTANT_NEXT_STEP_POINT_THREE_TITLE,
        message: constants.IMPORTANT_NEXT_STEP_POINT_THREE_SUBTITLE,
        button: {
          message: constants.LEARN_MORE,
          ariaLabel: constants.IMPORTANT_NEXT_STEP_POINT_THREE_ARIA_LABEL,
          onClick: () => setOpenReturnFlightModal(true),
        },
      });
    }

    return steps;
  })();

  useEffect(() => {
    if (!finalizedItinerary) {
      history.push(PATH_HOME);
    } else {
      handleRebookingCompleteBuyEvent(
        {
          ...rebookedItineraryDpExerciseItineraryFactsProperties,
          ...rebookedItineraryDpRebookingCompleteBuyFactsProperties,
          ...reviewItineraryEligibilityDpExerciseFactsProperties,
          ...disruptedFlightDpExerciseFactsProperties,
          success: true,
        },
        null
      );
    }
  }, [finalizedItinerary]);

  const renderCsatSurvey = () => {
    if (isFintechCsatEnabled) {
      (window as any).cempDataLayer["pageName"] = "FDA_REBOOK";
      const SURVEY_ID =
        window.__mclean_env__.ENV === "production"
          ? "capitalonetravel-flightdisruption"
          : "capitalonetravel-flightdisruption-test";
      return <div id={SURVEY_ID} />;
    }
    return undefined;
  };

  return (
    <>
      <Box
        className={clsx("rebook-confirmation-root", { mobile: matchesMobile })}
      >
        <Box className="rebook-confirmation-container">
          <Box className={clsx("confirm-content", "header-section")}>
            <Icon
              className="check-mark-icon"
              name={IconName.ConfirmationCheckMark}
            />
            <Box className="header-container">
              <Typography variant="h1" className="title-copy">
                {constants.REBOOK_CONFIRMATION_TITLE}
              </Typography>
              <Typography variant="subtitle1" className="subtitle-copy">
                {constants.REBOOK_CONFIRMATION_SUBTITLE}
              </Typography>
            </Box>
          </Box>
          {finalizedRebookItineraryFlightSummaryData && (
            <Box className={clsx("confirm-content", "flight-summary-section")}>
              <GenericFlightSummary
                {...finalizedRebookItineraryFlightSummaryData}
                headerType={isSelectingReturnFlight ? "return" : "outbound"}
                buttons={
                  !matchesMobile
                    ? [
                        {
                          message: constants.VIEW_DETAILS,
                          onClick: "open-modal",
                        },
                      ]
                    : undefined
                }
                useChevron={matchesMobile}
                useFullWidth={!matchesMobile}
                isMobile={matchesMobile}
                airlineProps={{
                  WN: {
                    hideFlightDetailsSummaryBanner: true,
                  },
                }}
              />
            </Box>
          )}
          <Box className={clsx("confirm-content", "next-steps-section")}>
            <Typography variant="h2" className="next-steps-copy">
              {constants.WHAT_IS_NEXT}
            </Typography>
            <Box className="next-steps-container">
              {nextStepProps.map((itemProps, index) => (
                <ListItem {...itemProps} key={index} isMobile={matchesMobile} />
              ))}
            </Box>
          </Box>
          <Box
            className={clsx("confirm-content", "cta-section", {
              floating: matchesMobile,
            })}
          >
            <ActionButton
              className="go-to-trips-button"
              defaultStyle="h4r-primary"
              onClick={() => {
                pushToMyTrips({
                  history,
                  itineraryId: finalizedItineraryId,
                });
              }}
              message={
                matchesMobile
                  ? constants.BACK_TO_MY_TRIPS_COPY
                  : constants.GO_TO_MY_TRIPS_COPY
              }
              ariaLabelText={constants.GO_TO_MY_TRIPS_COPY}
            />
          </Box>
          {renderCsatSurvey()}
        </Box>
      </Box>
      <PolicyInfoModal
        isMobile={matchesMobile}
        preset="disruption-protection-luggage"
        openModal={openLuggageModal}
        onClose={() => setOpenLuggageModal(false)}
      />
      {hasUpcomingReturnFlight && (
        <PolicyInfoModal
          isMobile={matchesMobile}
          preset="disruption-protection-return-flight"
          openModal={openReturnFlightModal}
          onClose={() => setOpenReturnFlightModal(false)}
        />
      )}
    </>
  );
};

interface IListItemProps {
  index: number;
  title: string;
  message: string;
  button?: {
    message: string;
    ariaLabel?: string;
    onClick: () => void;
  };
  isMobile?: boolean;
}

const ListItem = ({
  index,
  title,
  message,
  button,
  isMobile,
}: IListItemProps) => {
  return (
    <Box
      className={clsx("rebook-confirmation-list-item-root", {
        mobile: isMobile,
      })}
    >
      <Box className="rebook-confirmation-list-item-container">
        <Box className="index-section">
          <Box className="index-container">
            <Typography className="index-copy">{index}</Typography>
          </Box>
        </Box>
        <Box className="content-section">
          <Typography variant="subtitle2" className="title-copy">
            {title}
          </Typography>
          <Box className="body-container">
            <Typography variant="body2" className="message-copy">
              <Typography
                variant="inherit"
                className={clsx("message-copy", "main-copy")}
              >
                {message}
              </Typography>
              {button && (
                <>
                  <ButtonWrap
                    className="underlined-button"
                    aria-label={button.ariaLabel ?? button.message}
                    onClick={button.onClick}
                  >
                    <Typography
                      variant="inherit"
                      className={clsx("message-copy", "button-copy")}
                    >
                      {button.message}
                    </Typography>
                  </ButtonWrap>
                  <Typography variant="inherit" className="message-copy">
                    {constants.PERIOD_TEXT}
                  </Typography>
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
