export const SET_TRIP_CATEGORY = "flightSearch/SET_TRIP_CATEGORY";
export type SET_TRIP_CATEGORY = typeof SET_TRIP_CATEGORY;

export const SET_ORIGIN = "flightSearch/SET_ORIGIN";
export type SET_ORIGIN = typeof SET_ORIGIN;

export const SET_ORIGIN_CATEGORIES = "flightSearch/SET_ORIGIN_CATEGORIES";
export type SET_ORIGIN_CATEGORIES = typeof SET_ORIGIN_CATEGORIES;

export const FETCH_ORIGIN_CATEGORIES = "flightSearch/FETCH_ORIGIN_CATEGORIES";
export type FETCH_ORIGIN_CATEGORIES = typeof FETCH_ORIGIN_CATEGORIES;

export const SET_DESTINATION = "flightSearch/SET_DESTINATION";
export type SET_DESTINATION = typeof SET_DESTINATION;

export const SET_DESTINATION_CATEGORIES =
  "flightSearch/SET_DESTINATION_CATEGORIES";
export type SET_DESTINATION_CATEGORIES = typeof SET_DESTINATION_CATEGORIES;

export const FETCH_DESTINATION_CATEGORIES =
  "flightSearch/FETCH_DESTINATION_CATEGORIES";
export type FETCH_DESTINATION_CATEGORIES = typeof FETCH_DESTINATION_CATEGORIES;

export const SET_DEPARTURE_DATE = "flightSearch/SET_DEPARTURE_DATE";
export type SET_DEPARTURE_DATE = typeof SET_DEPARTURE_DATE;

export const SET_RETURN_DATE = "flightSearch/SET_RETURN_DATE";
export type SET_RETURN_DATE = typeof SET_RETURN_DATE;

export const SET_MULTICITY_ORIGIN = "flightSearch/SET_MULTICITY_ORIGIN";
export type SET_MULTICITY_ORIGIN = typeof SET_MULTICITY_ORIGIN;

export const SET_MULTICITY_DESTINATION =
  "flightSearch/SET_MULTICITY_DESTINATION";
export type SET_MULTICITY_DESTINATION = typeof SET_MULTICITY_DESTINATION;

export const SET_MULTICITY_DEPARTURE_DATE =
  "flightSearch/SET_MULTICITY_DEPARTURE_DATE";
export type SET_MULTICITY_DEPARTURE_DATE = typeof SET_MULTICITY_DEPARTURE_DATE;

export const SET_ALL_MULTICITY_ROUTES = "flightSearch/SET_ALL_MULTICITY_ROUTES";
export type SET_ALL_MULTICITY_ROUTES = typeof SET_ALL_MULTICITY_ROUTES;

export const ADD_MULTICITY_ROUTE = "flightSearch/ADD_MULTICITY_ROUTE";
export type ADD_MULTICITY_ROUTE = typeof ADD_MULTICITY_ROUTE;

export const REMOVE_MULTICITY_ROUTE = "flightSearch/REMOVE_MULTICITY_ROUTE";
export type REMOVE_MULTICITY_ROUTE = typeof REMOVE_MULTICITY_ROUTE;

export const FETCH_DEPARTURE_CALENDAR = "flightSearch/FETCH_DEPARTURE_CALENDAR";
export type FETCH_DEPARTURE_CALENDAR = typeof FETCH_DEPARTURE_CALENDAR;

export const SET_DEPARTURE_CALENDAR = "flightSearch/SET_DEPARTURE_CALENDAR";
export type SET_DEPARTURE_CALENDAR = typeof SET_DEPARTURE_CALENDAR;

// search filter actions
export const SET_STOPS_OPTION = "flightSearch/SET_STOPS_OPTION";
export type SET_STOPS_OPTION = typeof SET_STOPS_OPTION;

export const SET_MAX_PRICE_FILTER = "flightSearch/SET_MAX_PRICE_FILTER";
export type SET_MAX_PRICE_FILTER = typeof SET_MAX_PRICE_FILTER;

export const SET_FARECLASS_OPTION_FILTER =
  "flightSearch/SET_FARECLASS_OPTION_FILTER";
export type SET_FARECLASS_OPTION_FILTER = typeof SET_FARECLASS_OPTION_FILTER;

export const SET_BAGGAGE_TRANSFERS_FILTER =
  "flightSearch/SET_BAGGAGE_TRANSFERS_FILTER";
export type SET_BAGGAGE_TRANSFERS_FILTER = typeof SET_BAGGAGE_TRANSFERS_FILTER;

export const SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER =
  "flightSearch/SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER";
export type SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER =
  typeof SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER;

export const SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER =
  "flightSearch/SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER";
export type SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER =
  typeof SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER;

export const SET_RETURN_DEPARTURE_TIME_RANGE_FILTER =
  "flightSearch/SET_RETURN_DEPARTURE_TIME_RANGE_FILTER";
export type SET_RETURN_DEPARTURE_TIME_RANGE_FILTER =
  typeof SET_RETURN_DEPARTURE_TIME_RANGE_FILTER;

export const SET_RETURN_ARRIVAL_TIME_RANGE_FILTER =
  "flightSearch/SET_RETURN_ARRIVAL_TIME_RANGE_FILTER";
export type SET_RETURN_ARRIVAL_TIME_RANGE_FILTER =
  typeof SET_RETURN_ARRIVAL_TIME_RANGE_FILTER;

export const SET_AIRLINE_FILTER = "flightSearch/SET_AIRLINE_FILTER";
export type SET_AIRLINE_FILTER = typeof SET_AIRLINE_FILTER;

export const SET_AIRPORT_FILTER = "flightSearch/SET_AIRPORT_FILTER";
export type SET_AIRPORT_FILTER = typeof SET_AIRPORT_FILTER;

export const SET_FLIGHT_NUMBER_FILTER = "flightSearch/SET_FLIGHT_NUMBER_FILTER";
export type SET_FLIGHT_NUMBER_FILTER = typeof SET_FLIGHT_NUMBER_FILTER;

export const SET_DURATION_FILTER = "flightSearch/SET_DURATION_FILTER";
export type SET_DURATION_FILTER = typeof SET_DURATION_FILTER;

export const SET_PASSENGER_COUNTS = "flightSearch/SET_PASSENGER_COUNTS";
export type SET_PASSENGER_COUNTS = typeof SET_PASSENGER_COUNTS;

export const SET_AWAITING_REFETCH = "flightSearch/SET_AWAITING_REFETCH";
export type SET_AWAITING_REFETCH = typeof SET_AWAITING_REFETCH;

export const SET_MOBILE_SEARCH_PROGRESS =
  "flightSearch/SET_MOBILE_SEARCH_PROGRESS";
export type SET_MOBILE_SEARCH_PROGRESS = typeof SET_MOBILE_SEARCH_PROGRESS;

export const SET_OPEN_DATES_MODAL = "flightSearch/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_ORIGIN_AUTOCOMPLETE_ERROR =
  "flightSearch/SET_ORIGIN_AUTOCOMPLETE_ERROR";
export type SET_ORIGIN_AUTOCOMPLETE_ERROR =
  typeof SET_ORIGIN_AUTOCOMPLETE_ERROR;

export const SET_DESTINATION_AUTOCOMPLETE_ERROR =
  "flightSearch/SET_DESTINATION_AUTOCOMPLETE_ERROR";
export type SET_DESTINATION_AUTOCOMPLETE_ERROR =
  typeof SET_DESTINATION_AUTOCOMPLETE_ERROR;

export const FETCH_USER_FLIGHT_PREFERENCES =
  "flightSearch/FETCH_USER_FLIGHT_PREFERENCES";
export type FETCH_USER_FLIGHT_PREFERENCES =
  typeof FETCH_USER_FLIGHT_PREFERENCES;

export const SET_USER_FLIGHT_PREFERENCES =
  "flightSearch/SET_USER_FLIGHT_PREFERENCES";
export type SET_USER_FLIGHT_PREFERENCES = typeof SET_USER_FLIGHT_PREFERENCES;

export const SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED =
  "flightSearch/SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED";
export type SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED =
  typeof SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED;

export const SET_APPLY_USER_FLIGHT_PREFERENCES =
  "flightSearch/SET_APPLY_USER_FLIGHT_PREFERENCES";
export type SET_APPLY_USER_FLIGHT_PREFERENCES =
  typeof SET_APPLY_USER_FLIGHT_PREFERENCES;

export const SET_USER_PREFERENCES_NOT_AVAILABLE =
  "flightSearch/SET_USER_PREFERENCES_NOT_AVAILABLE";
export type SET_USER_PREFERENCES_NOT_AVAILABLE =
  typeof SET_USER_PREFERENCES_NOT_AVAILABLE;

// Corporate
export const SET_POLICY_FILTER = "flightSearch/SET_POLICY_FILTER";
export type SET_POLICY_FILTER = typeof SET_POLICY_FILTER;
