import { connect, ConnectedProps } from "react-redux";
import {
  AirEntryProperties,
  CallState,
  ITripTerminus,
  SliceStopCountFilter,
  TripCategory,
  UserFlightPreferencesPayload,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  // types
  IFetchDepartureCalendar,
  ISetTripCategory,
  ISetDepartureCalendarArgs,
  ISetDepartureCalendar,
  ISetOrigin,
  ISetDestination,
  ISetDepartureDate,
  ISetReturnDate,
  ISetMulticityOrigin,
  ISetMulticityDestination,
  ISetMulticityDepartureDate,
  ISetFareclassOptionFilter,

  // set state props
  setTripCategory,
  fetchDepartureCalendar,
  setCalendar,
  setOrigin,
  setDestination,
  setDepartureDate,
  setReturnDate,
  setMulticityOrigin,
  setMulticityDestination,
  setMulticityDepartureDate,

  // set filter props
  setStopsOption,
  setAirlineFilter,
  setFareclassOptionFilter,
  setMaxPriceFilter,
  setAirportFilter,
  setFlightNumberFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  ISetStopsOption,
  setApplyUserFlightPreferences,
} from "../../actions/actions";

import {
  getTripCategory,
  getDestination,
  getOrigin,
  getNumTravelers,
  getActiveFiltersCount,
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getReturnDate,
  initialFilterOptions,
  getMulticityRoutes,
  MulticityRoute,
  FareclassOptionFilter,
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getStopsOption,
  getHasUserSetFlightPreferences,
  getUserFlightPreferencesCallState,
  getApplyUserFlightPreferences,
  getUserFlightPreferences,
} from "../../reducer";
import { FlightSearchControlV2 } from "./component";
import { Dispatch } from "@reduxjs/toolkit";
import { withRouter } from "react-router";
import {
  IFlightShopOverwriteQueryParams,
  IPopulateFlightShopQueryParams,
  populateFlightShopQueryParams,
} from "../../../shop/actions/actions";
import { getAirEntryProperties } from "../../../shop/reducer";
interface IDispatchProps {
  setTripCategory: (tripCategory: TripCategory) => ISetTripCategory;
  fetchDepartureCalendar: (
    origin: ITripTerminus,
    destination: ITripTerminus
  ) => IFetchDepartureCalendar;
  setCalendar: (report?: ISetDepartureCalendarArgs) => ISetDepartureCalendar;
  setOrigin: (origin: ITripTerminus | null) => ISetOrigin;
  setDestination: (destination: ITripTerminus | null) => ISetDestination;
  setDepartureDate: (date: Date | null) => ISetDepartureDate;
  setReturnDate: (date: Date | null) => ISetReturnDate;
  setMulticityOrigin: (
    origin: ITripTerminus | null,
    index: number
  ) => ISetMulticityOrigin;
  setMulticityDestination: (
    destination: ITripTerminus | null,
    index: number
  ) => ISetMulticityDestination;
  setMulticityDepartureDate: (
    date: Date | null,
    index: number
  ) => ISetMulticityDepartureDate;
  resetFilters: (
    resetStopsFilter: boolean,
    resetAirlineFilter: boolean,
    resetFareclassFilter: boolean
  ) => void;
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => IPopulateFlightShopQueryParams;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => ISetFareclassOptionFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => ISetStopsOption;
  setApplyUserFlightPreferences: (applyPreferences: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTripCategory: (tripCategory: TripCategory) =>
    dispatch(setTripCategory(tripCategory)),
  fetchDepartureCalendar: (origin: ITripTerminus, destination: ITripTerminus) =>
    dispatch(fetchDepartureCalendar(origin, destination)),
  setCalendar: (report?: ISetDepartureCalendarArgs) =>
    dispatch(setCalendar(report)),
  setOrigin: (origin: ITripTerminus | null) => dispatch(setOrigin(origin)),
  setDestination: (destination: ITripTerminus | null) =>
    dispatch(setDestination(destination)),
  setDepartureDate: (date: Date | null) => dispatch(setDepartureDate(date)),
  setReturnDate: (date: Date | null) => dispatch(setReturnDate(date)),
  setMulticityOrigin: (origin: ITripTerminus | null, index: number) =>
    dispatch(setMulticityOrigin(origin, index)),
  setMulticityDestination: (destination: ITripTerminus | null, index: number) =>
    dispatch(setMulticityDestination(destination, index)),
  setMulticityDepartureDate: (date: Date | null, index: number) =>
    dispatch(setMulticityDepartureDate(date, index)),
  resetFilters: (
    resetStopsFilter: boolean,
    resetAirlineFilter: boolean,
    resetFareclassFilter: boolean
  ) => {
    if (resetStopsFilter) {
      dispatch(setStopsOption(initialFilterOptions.stopsOption));
    }
    if (resetAirlineFilter) {
      dispatch(setAirlineFilter(initialFilterOptions.airlineFilter));
    }
    if (resetFareclassFilter) {
      dispatch(
        setFareclassOptionFilter({
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        })
      );
    }
    dispatch(setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(setFlightNumberFilter(initialFilterOptions.flightNumberFilter));
    dispatch(
      setOutboundArrivalTimeRange(initialFilterOptions.outboundArrivalTimeRange)
    );
    dispatch(
      setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      setReturnDepartureTimeRange(initialFilterOptions.returnDepartureTimeRange)
    );
    dispatch(
      setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange)
    );
  },
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => dispatch(populateFlightShopQueryParams(args)),
  setFareclassOptionFilter: (fareclassOptionFilter: FareclassOptionFilter) =>
    dispatch(setFareclassOptionFilter(fareclassOptionFilter, "search")),
  setStopsOption: (stopsOption: SliceStopCountFilter) =>
    dispatch(setStopsOption(stopsOption, "search")),
  setApplyUserFlightPreferences: (applyPreferences: boolean) =>
    dispatch(setApplyUserFlightPreferences(applyPreferences)),
});

interface IStateProps {
  tripCategory: TripCategory;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  numTravelers: number;
  filtersCount: number;
  departureDate: Date | null;
  returnDate: Date | null;
  multicityRoutes: MulticityRoute[];
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  airEntryProperties: AirEntryProperties;
  fareclassOptionFilter: FareclassOptionFilter;
  hasSetFareclassFilter: boolean;
  stopsOption: SliceStopCountFilter;
  userHasSetFlightPreferences?: boolean;
  userFlightPreferences?: UserFlightPreferencesPayload;
  userFlightPreferencesCallState?: CallState;
  shouldApplyUserFlightPreferences: boolean;
}
const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  return {
    tripCategory: getTripCategory(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    numTravelers: getNumTravelers(state),
    filtersCount: getActiveFiltersCount(state),
    departureDate: departureDate ? new Date(departureDate) : null,
    returnDate: returnDate ? new Date(returnDate) : null,
    multicityRoutes: getMulticityRoutes(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    airEntryProperties: getAirEntryProperties(state),
    fareclassOptionFilter: getFareclassOptionFilter(state),
    hasSetFareclassFilter: getHasSetFareClassFilter(state),
    stopsOption: getStopsOption(state),
    userHasSetFlightPreferences: getHasUserSetFlightPreferences(state),
    userFlightPreferencesCallState: getUserFlightPreferencesCallState(state),
    shouldApplyUserFlightPreferences: getApplyUserFlightPreferences(state),
    userFlightPreferences: getUserFlightPreferences(state),
  };
};

interface IOwnProps {
  isMultiCityEnabled?: boolean;
  isCustomerProfileExperiment?: boolean;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export type FlightSearchControlV2ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightSearchControlV2 = connector(
  withRouter(FlightSearchControlV2)
);
