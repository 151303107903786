import {
  getTotalPriceText,
  IPriceFreezeOfferEntries,
  FlightIconCard,
  getRewardsString,
  Icon,
  IconName,
} from "halifax";
import React from "react";
import { FiatPrice, RewardsPrice, TripDetails } from "redmond";
import "./styles.scss";
import {
  IPriceFreezeEntryInfoOwnProps,
  PriceFreezeEntryInfo,
} from "../PriceFreezeEntryInfo";
import {
  IPriceFreezeEntryButtonOwnProps,
  PriceFreezeEntryButton,
} from "../PriceFreezeEntryButton";
import { Box, Typography } from "@material-ui/core";
import {
  CONNECTOR,
  CURRENT_PRICE_TEXT,
  DEPARTURE_FLIGHT_HEADER,
  ONE_WAY_TEXT,
  PER_TRAVELER_TEXT,
  RETURN_FLIGHT_HEADER,
  ROUND_TRIP_TEXT,
} from "./textConstants";

export interface IPriceFreezeEntryBannerProps {
  priceFreezeOfferCheapestTripTripDetails: TripDetails;
  priceFreezeOffer: IPriceFreezeOfferEntries | null;
  cheapestFrozenPrice?: { fiat: FiatPrice; rewards: RewardsPrice | undefined };
  useLockPriceLanguage?: boolean;
  priceFreezeEntryButtonProps: IPriceFreezeEntryButtonOwnProps;
  priceFreezeEntryInfoProps: IPriceFreezeEntryInfoOwnProps;
}

export const PriceFreezeEntryBanner = (props: IPriceFreezeEntryBannerProps) => {
  const {
    priceFreezeOfferCheapestTripTripDetails,
    priceFreezeOffer,
    cheapestFrozenPrice,
    useLockPriceLanguage,
    priceFreezeEntryButtonProps,
    priceFreezeEntryInfoProps,
  } = props;

  if (!priceFreezeOffer || !priceFreezeOffer.departureFlight) {
    return <></>;
  }

  const flightIconCardDeparture = (
    <Box className="departure-flight-card">
      <Box className="departure-flight-header">
        <Typography
          className="departure-flight-header-text"
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: DEPARTURE_FLIGHT_HEADER,
          }}
        />
      </Box>
      <FlightIconCard
        departureTime={
          priceFreezeOfferCheapestTripTripDetails.slices[0].departureTime
        }
        arrivalTime={
          priceFreezeOfferCheapestTripTripDetails.slices[0].arrivalTime
        }
        airlineCode={priceFreezeOffer.departureFlight.airlineCode}
        originCode={
          priceFreezeOfferCheapestTripTripDetails.slices[0].originCode
        }
        destinationCode={
          priceFreezeOfferCheapestTripTripDetails.slices[0].destinationCode
        }
        duration={priceFreezeOffer.departureFlight.duration}
        airlineName={priceFreezeOffer.departureFlight.airline}
        layoverString={priceFreezeOffer.departureFlight.stopString}
        plusDays={priceFreezeOffer.departureFlight.plusDays}
        type={"content"}
        iconSize={"1x"}
      ></FlightIconCard>
    </Box>
  );

  const flightIconCardReturn = priceFreezeOffer.returnFlight ? (
    <Box className="return-flight-card">
      <Box className="return-flight-header">
        <Typography
          className="return-flight-header-text"
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: RETURN_FLIGHT_HEADER,
          }}
        />
      </Box>
      <FlightIconCard
        departureTime={
          priceFreezeOfferCheapestTripTripDetails.slices[1].departureTime
        }
        arrivalTime={
          priceFreezeOfferCheapestTripTripDetails.slices[1].arrivalTime
        }
        airlineCode={priceFreezeOffer.returnFlight.airlineCode}
        originCode={
          priceFreezeOfferCheapestTripTripDetails.slices[1].originCode
        }
        destinationCode={
          priceFreezeOfferCheapestTripTripDetails.slices[1].destinationCode
        }
        duration={priceFreezeOffer.returnFlight.duration}
        airlineName={priceFreezeOffer.returnFlight.airline}
        layoverString={priceFreezeOffer.returnFlight.stopString}
        plusDays={priceFreezeOffer.returnFlight.plusDays}
        type={"content"}
        iconSize={"1x"}
      ></FlightIconCard>
    </Box>
  ) : (
    <></>
  );

  const currentPriceDisplay = cheapestFrozenPrice ? (
    <Box className="price-freeze-banner-current-price-display">
      <Box className="price-freeze-banner-current-price-display-title">
        {CURRENT_PRICE_TEXT(useLockPriceLanguage)}
      </Box>
      <Box className="price-freeze-banner-current-price-display-price">
        <Box className="price-freeze-banner-current-price-display-fiat">
          {getTotalPriceText({
            price: cheapestFrozenPrice.fiat,
          })}
        </Box>
        {cheapestFrozenPrice.rewards && (
          <Box className="price-freeze-banner-current-price-display-separator">
            {"/"}
          </Box>
        )}
        {cheapestFrozenPrice.rewards && (
          <Box className="price-freeze-banner-current-price-display-rewards">
            {getRewardsString(cheapestFrozenPrice.rewards)}
          </Box>
        )}
      </Box>
      <Box className="price-freeze-banner-current-price-display-trip-category">
        {priceFreezeOffer.returnFlight ? ROUND_TRIP_TEXT : ONE_WAY_TEXT}
        {CONNECTOR}
        {PER_TRAVELER_TEXT}
      </Box>
    </Box>
  ) : (
    <></>
  );

  const priceFreezeEntryInfoPropsOverrideText: IPriceFreezeEntryInfoOwnProps = {
    ...priceFreezeEntryInfoProps,
    isAirPriceFreezeNewReviewCTAEnabled: false,
    isFromFlightShopReviewItinerary: false,
  };

  return (
    <>
      <Box className="price-freeze-banner">
        <Box className="price-freeze-banner-left-column-container">
          <Icon
            className="price-freeze-icon"
            name={
              useLockPriceLanguage
                ? IconName.LockWithKeyhole
                : IconName.B2bPriceFreezeNoCircle
            }
          />
          <PriceFreezeEntryInfo {...priceFreezeEntryInfoPropsOverrideText} />
        </Box>
        <Box className="price-freeze-banner-center-column-container">
          {flightIconCardDeparture}
          {flightIconCardReturn}
          {/* BF-1552 Add support for multi-city flights once needed */}
        </Box>
        <Box className="price-freeze-banner-right-column-container">
          <PriceFreezeEntryButton {...priceFreezeEntryButtonProps} />
          {currentPriceDisplay}
        </Box>
      </Box>
    </>
  );
};
