import { connect, ConnectedProps } from "react-redux";
import { RebookBanner } from "./component";
import { getOrigin, getDestination } from "../../../search/reducer";
import {
  hasSelectedLowerFareClassForRebookSelector,
  flightShopProgressSelector,
} from "../../../shop/reducer";
import { disruptionProtectionOriginalSliceContextSelector } from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
    originalSliceContext:
      disruptionProtectionOriginalSliceContextSelector(state),
    hasSelectedLowerFareClass:
      hasSelectedLowerFareClassForRebookSelector(state),
    flightShopProgress: flightShopProgressSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RebookBannerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRebookBanner = connector(RebookBanner);
