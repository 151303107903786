export const EXCLUDE_BASIC_FARES_COPY = "Exclude Basic fares";
export const EXCLUDE_BASIC_FARES_ID = "exclude-basic-fares-id";
export const NONSTOP_FLIGHTS_ONLY_COPY = "Nonstop flights only";
export const NONSTOP_FLIGHTS_ONLY_ID = "nonstop-flights-only-id";

export const INCLUDE_EMAIL_COPY = "Email (default)";
export const INCLUDE_EMAIL_ID = "email-default-id";
export const INCLUDE_APP_NOTIFICATIONS_COPY = "App notifications";
export const INCLUDE_APP_NOTIFICATIONS_ID = "app-notifications-id";

export const OPT_IN_BANNER_COPY = `Please ensure you’ve enabled notifications on the Capital One mobile app.`;
