import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  getTripCategory,
  getDestination,
  getOrigin,
  getNumTravelers,
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getReturnDate,
  initialFilterOptions,
} from "../../reducer";
import {
  fetchTripSummaries,
  IFetchTripSummaries,
  ISetFlightShopProgress,
  ISetOpenFlightShopCalendarDesktop,
  setChosenOutgoingSlice,
  setFlightShopProgress,
} from "../../../shop/actions/actions";
import { FlightShopSearchControl } from "./component";
import { withRouter } from "react-router";
import {
  setOrigin,
  setDestination,
  setDepartureDate,
  setReturnDate,
} from "../../actions/actions";
import {
  maxFlightPriceSelector,
  openFlightShopCalendarDesktopSelector,
} from "../../../shop/reducer/selectors";
import { setOpenFlightShopCalendarDesktop } from "../../../shop/actions/actions";
import {
  fetchDepartureCalendar,
  setCalendar,
} from "../../../search/actions/actions";
import { Dispatch } from "@reduxjs/toolkit";
import { ITripTerminus, TripCategory } from "redmond";
import { FlightShopStep } from "../../../shop/reducer";

interface IDispatchProps {
  fetchDepartureCalendar: (
    origin: ITripTerminus,
    destination: ITripTerminus
  ) => actions.IFetchDepartureCalendar;
  fetchTripSummaries: (history: any, isMobile: boolean) => IFetchTripSummaries;
  setCalendar: (report?: any) => actions.ISetDepartureCalendar;
  setDepartureDate: (date: Date | null) => actions.ISetDepartureDate;
  setDestination: (
    destination: ITripTerminus | null
  ) => actions.ISetDestination;
  setOpenFlightShopCalendarDesktop: (
    open: boolean
  ) => ISetOpenFlightShopCalendarDesktop;
  setOrigin: (origin: ITripTerminus | null) => actions.ISetOrigin;
  setReturnDate: (date: Date | null) => actions.ISetReturnDate;
  setTripCategory: (tripCategory: TripCategory) => actions.ISetTripCategory;
  resetAll: () => void;
  setFlightShopProgress: (step: FlightShopStep) => ISetFlightShopProgress;
}

const { setTripCategory } = actions;
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchDepartureCalendar: (origin: ITripTerminus, destination: ITripTerminus) =>
    dispatch(fetchDepartureCalendar(origin, destination)),
  fetchTripSummaries: (history: any, isMobile: boolean) =>
    dispatch(fetchTripSummaries(history, isMobile)),
  setCalendar: (report?: any) => dispatch(setCalendar(report)),
  setDepartureDate: (date: Date | null) => dispatch(setDepartureDate(date)),
  setDestination: (destination: ITripTerminus | null) =>
    dispatch(setDestination(destination)),
  setOpenFlightShopCalendarDesktop: (open: boolean) =>
    dispatch(setOpenFlightShopCalendarDesktop(open)),
  setOrigin: (origin: ITripTerminus | null) => dispatch(setOrigin(origin)),
  setReturnDate: (date: Date | null) => dispatch(setReturnDate(date)),
  setTripCategory: (tripCategory: TripCategory) =>
    dispatch(setTripCategory(tripCategory)),
  resetAll: () => {
    dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
    dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(actions.setPolicyFilter(initialFilterOptions.policyFilter));
    dispatch(
      actions.setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
      })
    );
    dispatch(actions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(
      actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
    );
    dispatch(
      actions.setOutboundArrivalTimeRange(
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
    dispatch(
      actions.setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnDepartureTimeRange(
        initialFilterOptions.returnDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnArrivalTimeRange(
        initialFilterOptions.returnArrivalTimeRange
      )
    );
    dispatch(
      setChosenOutgoingSlice({
        tripId: "",
        outgoingSliceId: "",
        outgoingFareId: "",
        outgoingFareRating: undefined,
        resetReturnIds: true,
      })
    );
  },
  setFlightShopProgress: (step: FlightShopStep) =>
    dispatch(setFlightShopProgress(step)),
});

interface IStateProps {
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  originCode: string | undefined;
  destinationCode: string | undefined;
  adultsCount: number;
  childrenCount: number;
  departureDate: Date | null;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  numTravelers: number;
  openCalendarDesktop: boolean;
  returnDate: Date | null;
  tripCategory: TripCategory;
  maxFlightPrice: number;
}

const mapStateToProps = (state: IStoreState) => {
  const destination = getDestination(state);
  const origin = getOrigin(state);

  return {
    origin,
    destination,
    originCode: origin?.id.code.code,
    destinationCode: destination?.id.code.code,
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    departureDate: getDepartureDate(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    numTravelers: getNumTravelers(state),
    openCalendarDesktop: openFlightShopCalendarDesktopSelector(state),
    returnDate: getReturnDate(state),
    tripCategory: getTripCategory(state),
    maxFlightPrice: maxFlightPriceSelector(state),
  };
};

interface IOwnProps {
  disabled?: boolean;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    resetAll: () => dispatchProps.resetAll(),
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type FlightShopSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopSearchControl = connector(
  withRouter(FlightShopSearchControl)
);
