import React, { useRef, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  MobileOutlinedTextInput,
  MobilePopoverCard,
  IconName,
  Icon,
  ActionButton,
  MobileSearchFieldButton,
  PassengerCountPickerType,
  PassengerCountPicker,
} from "halifax";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import * as H from "history";

import "./styles.scss";
import { LocationSearchConnectorProps } from "./container";
import { MobileLocationSearchModal } from "../../../MobileFlightSearchControlV2/components";
import * as textConstants from "./textConstants";
import {
  IPassengerCounts,
  ITripTerminus,
  RecentFlightSearch,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import { MulticityRoute } from "../../../../reducer";
import { getCityNameAndAirportCodeFromTerminus } from "../../../../../../utils/terminus";
import { MobileCalendarPicker } from "../MobileCalendarPicker";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getMinAllowedDate,
  getMaxAllowedDate,
} from "../../../../../shop/utils/getUpdatedMultiCityRoutes";
import { NonStopToggle } from "../../../FlightSearchControlV2/components/NonstopToggle";
import { MobileFareClassSelection } from "../MobileFareClassSelection";

export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: () => void;
  history: H.History;
  isEditLocationModal?: boolean;
  localOrigin?: ITripTerminus | null;
  setLocalOrigin?: (origin: ITripTerminus | null) => void;
  localDestination?: ITripTerminus | null;
  setLocalDestination?: (origin: ITripTerminus | null) => void;
  localPassengersCount?: PassengerCountPickerType;
  setLocalPassengersCount?: (count: PassengerCountPickerType) => void;
  localNumTravelers?: number;
  searchOnContinue?: boolean;
  currentTripCategory: TripCategory;
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
  paxCountApplyButtonText?: string;
  onPaxCountButtonClick?: (passCountsToUse?: IPassengerCounts) => void;
  onSelectLocation?: (value: any) => void;
  isAirCXV3Experiment?: boolean;
}
export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    origin,
    destination,
    numTravelers,
    onComplete,
    history,
    localOrigin,
    setLocalOrigin,
    localDestination,
    setLocalDestination,
    localPassengersCount,
    setLocalPassengersCount,
    localNumTravelers,
    counts,
    setPassengerCounts,
    searchOnContinue = false,
    multicityRoutes,
    setMulticityOrigin,
    setMulticityDestination,
    addMulticityRoute,
    removeMulticityRoute,
    fetchDepartureCalendar,
    setCalendar,
    currentTripCategory,
    recentSearches,
    onRecentSearchClick,
    setMulticityDepartureDate,
    paxCountApplyButtonText,
    onPaxCountButtonClick,
    onSelectLocation,
    isAirCXV3Experiment,
    stopsOption,
    setStopsOption,
    fareclassOptionsFilter,
    setFareclassOptionFilter,
  } = props;
  const [openOriginModal, setOpenOriginModal] = useState(false);
  const [openDestinationModal, setOpenDestinationModal] = useState(false);
  const [openMulticityOriginModal, setOpenMulticityOriginModal] = useState<
    number | null
  >(null);
  const [openMulticityDestinationModal, setOpenMulticityDestinationModal] =
    useState<number | null>(null);
  const [openMulticityCalendarModal, setOpenMulticityCalendarModal] = useState<
    number | null
  >(null);
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState<boolean>(false);
  const [
    hasMissingMulticitySearchInfoError,
    setHasMissingMulticitySearchInfoError,
  ] = useState(false);

  useEffect(() => {
    if (
      !isSearchingMulticity ||
      (isAllMulticityFieldsPopulated && hasMissingMulticitySearchInfoError)
    )
      setHasMissingMulticitySearchInfoError(false);
  }, [multicityRoutes, currentTripCategory]);

  const mcRoutesContainerBottomRef = useRef(null);

  const isOWRTReadyToContinue = useMemo(() => {
    return (origin && destination) || (localOrigin && localDestination);
  }, [origin, destination, localOrigin, localDestination]);

  const MAX_MULTICITY_ROUTES = 5;
  const isSearchingMulticity = currentTripCategory === TripCategory.MULTI_CITY;
  const isMaxRoutes = multicityRoutes.length >= MAX_MULTICITY_ROUTES;
  const isAllMulticityFieldsPopulated = multicityRoutes.every(
    (r: MulticityRoute) => Object.values(r).every((field) => field !== null)
  );

  const numTravelerString =
    localNumTravelers === 1 || numTravelers === 1
      ? `${localNumTravelers ?? numTravelers} Traveler`
      : `${localNumTravelers ?? numTravelers} Travelers`;

  const renderRouteSearch = () => {
    return (
      <Box className="mobile-location-pickers">
        <MobileSearchFieldButton
          value={localOrigin?.label || origin?.label}
          label={textConstants.WHERE_FROM}
          icon={<Icon name={IconName.B2BMapPin} />}
          renderPopup={() => (
            <MobileLocationSearchModal
              openLocationModal={openOriginModal}
              handleClose={() => {
                setOpenOriginModal(false);
              }}
              searchType="origin"
              setOpenLocationModal={setOpenOriginModal}
              history={history}
              headerText={textConstants.WHERE_FROM}
              localOrigin={localOrigin}
              setLocalOrigin={setLocalOrigin}
              recentSearches={recentSearches}
              onRecentSearchClick={onRecentSearchClick}
              onSelectLocation={onSelectLocation}
            />
          )}
          onClick={() => setOpenOriginModal(true)}
        />
        <MobileSearchFieldButton
          value={localDestination?.label || destination?.label}
          label={textConstants.WHERE_TO}
          icon={<Icon name={IconName.B2BMapPin} />}
          renderPopup={() => (
            <MobileLocationSearchModal
              openLocationModal={openDestinationModal}
              handleClose={() => setOpenDestinationModal(false)}
              searchType="destination"
              setOpenLocationModal={setOpenDestinationModal}
              history={history}
              headerText={textConstants.WHERE_TO}
              localDestination={localDestination}
              setLocalDestination={setLocalDestination}
              onDestinationSelection={
                !searchOnContinue ? onComplete : undefined
              }
              onSelectLocation={onSelectLocation}
            />
          )}
          onClick={() => setOpenDestinationModal(true)}
        />
      </Box>
    );
  };

  const getCalendarHeader = (
    origin: ITripTerminus | null,
    destination: ITripTerminus | null
  ) => {
    const getHeaderText = () => {
      let originCityName, destinationCityName;
      if (origin) {
        originCityName = getCityNameAndAirportCodeFromTerminus(
          origin as ITripTerminus
        )[0];
      }
      if (destination) {
        destinationCityName = getCityNameAndAirportCodeFromTerminus(
          destination as ITripTerminus
        )[0];
      }

      if (!origin && !destination) return textConstants.CHOOSE_OUTBOUND_DATE;
      if (!origin) return `${textConstants.OUTBOUND_TO} ${destinationCityName}`;
      if (!destination)
        return `${textConstants.OUTBOUND_FROM} ${originCityName}`;
      return `${originCityName} to ${destinationCityName}`;
    };

    return (
      <Typography className="mobile-multicity-calendar-modal-header">
        {getHeaderText()}
      </Typography>
    );
  };

  const renderMulticityRouteSearch = (route: MulticityRoute, index: number) => {
    const {
      origin: routeOrigin,
      destination: routeDestination,
      departureDate: routeDepartureDate,
    } = route;

    const setRouteOrigin = (origin: ITripTerminus | null) =>
      setMulticityOrigin(origin, index);
    const setRouteDestination = (destination: ITripTerminus | null) => {
      setMulticityDestination(destination, index);
      const nextRoute = multicityRoutes[index + 1];
      if (!!nextRoute && !nextRoute.origin)
        setMulticityOrigin(destination, index + 1);
    };

    const minAllowedDate = getMinAllowedDate(multicityRoutes, index);
    const maxAllowedDate = getMaxAllowedDate(multicityRoutes, index);
    const focusedMonthIndex = dayjs(routeDepartureDate ?? minAllowedDate).diff(
      dayjs(),
      "month"
    );

    const onCloseCalendar = () => {
      setOpenMulticityCalendarModal(null);
      setCalendar();
    };
    const routeHasMissingFieldsError =
      hasMissingMulticitySearchInfoError &&
      (!routeOrigin || !routeDestination || !routeDepartureDate);

    return (
      <Box
        key={`multicity-route-picker-${index}`}
        className="mobile-multicity-route-picker"
      >
        <Box className="multicity-route-number-header">
          <Icon name={IconName.DiagonalGreyAirplane} />
          <Typography>Flight {index + 1}</Typography>
        </Box>
        <MobileSearchFieldButton
          value={routeOrigin?.label}
          label={textConstants.WHERE_FROM}
          classes={[
            hasMissingMulticitySearchInfoError && !routeOrigin
              ? "missing-search-field-info"
              : "",
          ]}
          icon={<Icon name={IconName.B2BMapPin} />}
          renderPopup={() => (
            <MobileLocationSearchModal
              openLocationModal={openMulticityOriginModal === index}
              handleClose={() => {
                setOpenMulticityOriginModal(null);
              }}
              searchType="origin"
              setOpenLocationModal={() => setOpenMulticityOriginModal(index)}
              history={history}
              headerText={textConstants.WHERE_FROM}
              localOrigin={routeOrigin}
              setLocalOrigin={setRouteOrigin}
              recentSearches={recentSearches}
              onRecentSearchClick={onRecentSearchClick}
            />
          )}
          onClick={() => setOpenMulticityOriginModal(index)}
        />
        <MobileSearchFieldButton
          value={routeDestination?.label}
          label={textConstants.WHERE_TO}
          classes={[
            hasMissingMulticitySearchInfoError && !routeDestination
              ? "missing-search-field-info"
              : "",
          ]}
          icon={<Icon name={IconName.B2BMapPin} />}
          renderPopup={() => (
            <MobileLocationSearchModal
              openLocationModal={openMulticityDestinationModal === index}
              handleClose={() => setOpenMulticityDestinationModal(null)}
              searchType="destination"
              setOpenLocationModal={() =>
                setOpenMulticityDestinationModal(index)
              }
              history={history}
              headerText={textConstants.WHERE_TO}
              localDestination={routeDestination}
              setLocalDestination={setRouteDestination}
            />
          )}
          onClick={() => setOpenMulticityDestinationModal(index)}
        />
        <MobileSearchFieldButton
          classes={[
            "mobile-multicity-date-button",
            hasMissingMulticitySearchInfoError && !routeDepartureDate
              ? "missing-search-field-info"
              : "",
          ]}
          icon={<Icon name={IconName.Calendar} />}
          label={
            routeDepartureDate
              ? textConstants.OUTBOUND_DATE
              : textConstants.OUTBOUND
          }
          value={
            routeDepartureDate
              ? dayjs(routeDepartureDate).format("ddd, MMM D")
              : undefined
          }
          onClick={() => {
            if (routeOrigin && routeDestination) {
              fetchDepartureCalendar(
                routeOrigin as ITripTerminus,
                routeDestination as ITripTerminus
              );
              const prevRouteDepartureDate =
                multicityRoutes[index - 1]?.departureDate;
              if (!routeDepartureDate && prevRouteDepartureDate) {
                const nextDay = dayjs(prevRouteDepartureDate)
                  .add(dayjs.duration({ days: 1 }))
                  .toDate();
                setMulticityDepartureDate(nextDay, index);
              }
            }
            setOpenMulticityCalendarModal(index);
          }}
        />
        <MobilePopoverCard
          open={openMulticityCalendarModal === index}
          headerElement={getCalendarHeader(routeOrigin, routeDestination)}
          onClose={onCloseCalendar}
          topLeftButton={
            <Box onClick={onCloseCalendar}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Box>
          }
          topRightButton={
            <Box onClick={onCloseCalendar}>
              <Icon name={IconName.BlueX} />
            </Box>
          }
        >
          <MobileCalendarPicker
            focusedMonthIndex={focusedMonthIndex}
            departure={routeDepartureDate}
            returnDate={null}
            multicityRouteIndex={index}
            onComplete={onCloseCalendar}
            minAllowedDate={minAllowedDate}
            maxAllowedDate={maxAllowedDate}
          />
        </MobilePopoverCard>
        {routeHasMissingFieldsError && (
          <Typography className="missing-multicity-fields-warning-message">
            {textConstants.MISSING_ROUTE_FIELDS_ERROR}
          </Typography>
        )}
        {index > 1 && (
          <Typography
            className="remove-flight-button"
            onClick={() => removeMulticityRoute(index)}
          >
            {textConstants.REMOVE_FLIGHT_BUTTON_TEXT}
          </Typography>
        )}
      </Box>
    );
  };

  const renderMulticityRoutes = () => {
    return (
      <Box
        className={clsx("mobile-multicity-location-pickers", {
          "max-routes-list": isMaxRoutes,
        })}
      >
        {multicityRoutes.map(renderMulticityRouteSearch)}
        <div
          className="multicity-list-bottom-container"
          ref={mcRoutesContainerBottomRef}
        />
      </Box>
    );
  };

  const renderBottomButtons = () => {
    return (
      <Box className="mobile-flight-search-buttons-container">
        {isSearchingMulticity && (
          <Box
            className={clsx("add-flight-button", {
              disabled: isMaxRoutes,
            })}
            onClick={() => {
              if (!isMaxRoutes) {
                addMulticityRoute();
                // @ts-ignore
                mcRoutesContainerBottomRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            {textConstants.ADD_FLIGHT_BUTTON_TEXT}
          </Box>
        )}
        {isSearchingMulticity && isMaxRoutes && (
          <Typography className="max-routes-message">
            {textConstants.MAX_ROUTES_MESSAGE}
          </Typography>
        )}
        {(isOWRTReadyToContinue || isSearchingMulticity) && (
          <ActionButton
            className={clsx("mobile-autocomplete-continue-button", "b2b")}
            message={
              isSearchingMulticity
                ? textConstants.SEARCH_FLIGHTS
                : textConstants.CONTINUE
            }
            onClick={() => {
              if (isSearchingMulticity && !isAllMulticityFieldsPopulated) {
                setHasMissingMulticitySearchInfoError(true);
              } else {
                onComplete && onComplete();
              }
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box className="mobile-flight-search-location redesign-v3">
      <Box
        className={clsx("mobile-traveler-count-container", {
          "air-cx-v3": isAirCXV3Experiment,
        })}
      >
        <Box className="mobile-traveler-count">
          <MobileOutlinedTextInput
            className={clsx("num-traveler-count", "mobile")}
            prefixString={numTravelerString}
            icon={faUser}
            showCloseIcon={false}
            customEndIcon={
              isAirCXV3Experiment ? (
                <Icon name={IconName.Dropdown} />
              ) : (
                <Icon name={IconName.B2BEditPencil} />
              )
            }
            onClick={() => setOpenPassengerCountPicker(true)}
            value={""}
          />
        </Box>
        {isAirCXV3Experiment && (
          <>
            <Box className="mobile-fareclass-wrapper">
              <MobileFareClassSelection
                fareclassOptionFilter={fareclassOptionsFilter}
                setFareclassOptionFilter={(fareclassOptionFilter) =>
                  setFareclassOptionFilter(fareclassOptionFilter, "search")
                }
              />
            </Box>
            <Box className="mobile-nonstop-toggle-wrapper">
              <NonStopToggle
                checked={stopsOption === SliceStopCountFilter.NONE}
                onClick={(checked) =>
                  setStopsOption(
                    checked
                      ? SliceStopCountFilter.NONE
                      : SliceStopCountFilter.ANY_NUMBER,
                    "search"
                  )
                }
              />
            </Box>
          </>
        )}
      </Box>
      {!isSearchingMulticity && renderRouteSearch()}
      {isSearchingMulticity && renderMulticityRoutes()}
      <MobilePopoverCard
        open={openPassengerCountPicker}
        className={clsx("mobile-passenger-count-picker-popup", "b2b")}
        contentClassName="mobile-passenger-count-picker-popup-container"
        onClose={() => setOpenPassengerCountPicker(false)}
        centered={true}
      >
        <PassengerCountPicker
          minimumAdultsCount={1}
          onClickApply={(count) => {
            setOpenPassengerCountPicker(false);
            onPaxCountButtonClick &&
              onPaxCountButtonClick(count as IPassengerCounts);
          }}
          className="b2b"
          counts={localPassengersCount ?? counts}
          setPassengerCounts={setLocalPassengersCount ?? setPassengerCounts}
          buttonText={paxCountApplyButtonText}
          includeChildrenInMaxCount
        />
      </MobilePopoverCard>
      {renderBottomButtons()}
    </Box>
  );
};
