import {
  CFAR_MULTI_CITY,
  PRICE_WATCH_PUSH_DEFAULT_OPT_IN,
  THEBES_VIRTUAL_INTERLINING_IN_CAP1,
  PRICE_FREEZE_NEW_REVIEW_CTA,
} from "./../../../../context/experiments";
import { createSelector } from "@reduxjs/toolkit";
import {
  PRICE_PREDICTION_GRADIENT,
  PRICE_WATCH_PUSH,
  AVAILABLE,
  THEBES_HACKER_FARES_IN_CAP1,
  VI_EXPERIMENT_AVAILABLE,
} from "../../../../context/experiments";
import { IStoreState } from "../../../../reducers/types";

export const flightShopExperimentsSelector = (state: IStoreState) =>
  state.flightShop.experiments;

export const pricePredictionGradientVariantSelector = createSelector(
  flightShopExperimentsSelector,
  (experiments) => {
    return experiments?.[PRICE_PREDICTION_GRADIENT];
  }
);

export const priceWatchPushNotificationVariantSelector = createSelector(
  flightShopExperimentsSelector,
  (experiments) => {
    return experiments?.[PRICE_WATCH_PUSH];
  }
);

export const isPriceWatchPushNotificationEnabledSelector = createSelector(
  priceWatchPushNotificationVariantSelector,
  (priceWatchPushNotificationVariant) => {
    return priceWatchPushNotificationVariant === AVAILABLE;
  }
);

export const priceWatchPushNotificationDefaultOptInVariantSelector =
  createSelector(flightShopExperimentsSelector, (experiments) => {
    return experiments?.[PRICE_WATCH_PUSH_DEFAULT_OPT_IN];
  });

export const isPriceWatchPushNotificationDefaultOptInEnabledSelector =
  createSelector(
    priceWatchPushNotificationDefaultOptInVariantSelector,
    (priceWatchPushNotificationDefaultOptInVariant) => {
      return priceWatchPushNotificationDefaultOptInVariant === AVAILABLE;
    }
  );

export const isThebesHackerFaresV2Cap1ExperimentAvailable = createSelector(
  flightShopExperimentsSelector,
  (experiments) => {
    return (
      experiments?.[THEBES_HACKER_FARES_IN_CAP1] === VI_EXPERIMENT_AVAILABLE
    );
  }
);

export const isThebesVirtualInterliningInCap1ExperimentAvailable =
  createSelector(flightShopExperimentsSelector, (experiments) => {
    return (
      experiments?.[THEBES_HACKER_FARES_IN_CAP1] === VI_EXPERIMENT_AVAILABLE &&
      experiments?.[THEBES_VIRTUAL_INTERLINING_IN_CAP1] ===
        VI_EXPERIMENT_AVAILABLE
    );
  });

export const cfarMulticityVariantSelector = createSelector(
  flightShopExperimentsSelector,
  (experiments) => {
    return experiments?.[CFAR_MULTI_CITY];
  }
);

export const isCfarMulticityEnabledSelector = createSelector(
  cfarMulticityVariantSelector,
  (cfarMulticityVariant) => {
    return cfarMulticityVariant === AVAILABLE;
  }
);

export const airPriceFreezeNewReviewCTAVariantSelector = createSelector(
  flightShopExperimentsSelector,
  (experiments) => {
    return experiments?.[PRICE_FREEZE_NEW_REVIEW_CTA];
  }
);

export const isAirPriceFreezeNewReviewCTAEnabledSelector = createSelector(
  airPriceFreezeNewReviewCTAVariantSelector,
  (airPriceFreezeNewReviewCTAVariant) => {
    return airPriceFreezeNewReviewCTAVariant === AVAILABLE;
  }
);
