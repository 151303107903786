import axios from "axios";

import {
  GetAirChfarOfferRequestV1,
  GetAirChfarOfferResponseV1,
  airChfarApi,
} from "@b2bportal/air-chfar-api";

export async function getAirChfarOffer(
  request: GetAirChfarOfferRequestV1
): Promise<GetAirChfarOfferResponseV1> {
  const response = await airChfarApi(axios).apiV1ChfarAirOfferGetPost(request);

  return response.data;
}
