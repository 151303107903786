import { connect, ConnectedProps } from "react-redux";
import { FlightList } from "./component";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../reducers/types";
import {
  generateCustomPriceFreezeOffer,
  setPriceFreezeOfferData,
} from "../../../../freeze/actions/actions";
import {
  setChosenOutgoingSlice,
  setChosenReturnSlice,
} from "../../../actions/actions";
import {
  selectIsPriceFreezeCurrentEntryDisabled,
  selectIsPriceFreezeFlightListCtaEnabled,
} from "../../../../freeze/experiments/c1-price-freeze-new-flights-list-entry/selectors";
import {
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
} from "../../../../search/reducer";

const mapStateToProps = (state: IStoreState) => ({
  isPriceFreezeFlightListCtaEnabled:
    selectIsPriceFreezeFlightListCtaEnabled(state),
  isPriceFreezeCurrentEntryDisabled:
    selectIsPriceFreezeCurrentEntryDisabled(state),
  adultsCount: getAdultsCount(state),
  childrenCount: getChildrenCount(state),
  infantsOnLapCount: getInfantsOnLapCount(state),
  infantsInSeatCount: getInfantsInSeatCount(state),
});

const mapDispatchToProps = {
  generateCustomPriceFreezeOffer,
  setPriceFreezeOfferData,
  setChosenReturnSlice,
  setChosenOutgoingSlice,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightList = connector(withRouter(FlightList));
