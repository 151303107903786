import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  NONSTOP_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT,
  NONSTOP_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT,
} from "../../constants";
import "./styles.scss";
import clsx from "clsx";

interface NonstopFlightListSeparatorProps {
  isMobile?: boolean;
}

export const NonstopFlightListSeparator = ({
  isMobile,
}: NonstopFlightListSeparatorProps) => (
  <Box className={clsx("nonstop-flight-list-separator", { mobile: isMobile })}>
    <Box className="nonstop-flight-list-separator-top-row">
      <Box className="dashed-line" />
      <Typography className="nonstop-flight-list-separator-primary-text">
        {NONSTOP_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT}
      </Typography>
      <Box className="dashed-line" />
    </Box>

    <Typography className="nonstop-flight-list-separator-secondary-text">
      {NONSTOP_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT}
    </Typography>
  </Box>
);
