import { CfarId, ChfarId, FiatPrice } from "redmond";
import { getTotalPriceText, twoDecimalFormatter } from "halifax";
import { DO_NOT_APPLY_OPTION_KEY } from "../../reducer";

export const getFormattedTotalPriceText = (fiat: FiatPrice | undefined) => {
  if (fiat) {
    return getTotalPriceText({
      price: fiat,
      priceFormatter: twoDecimalFormatter,
    });
  }
  return "";
};

export const DO_NOT_APPLY_CFAR_OPTION: CfarId = {
  policyId: DO_NOT_APPLY_OPTION_KEY,
  productId: DO_NOT_APPLY_OPTION_KEY,
};

export const DO_NOT_APPLY_CHFAR_OPTION: ChfarId = {
  quoteId: DO_NOT_APPLY_OPTION_KEY,
};
