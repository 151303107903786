import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { setContactInfo } from "../../../book/actions/actions";
import { getConfirmationContactInfo } from "../../../book/reducer/selectors";
import { FlightFreezeContactInfoForm } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    contactInfo: getConfirmationContactInfo(state),
  };
};

const mapDispatchToProps = {
  setContactInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezeContactInfoFormConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezeContactInfoForm = withRouter(
  connector(FlightFreezeContactInfoForm)
);
