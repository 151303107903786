import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../../../reducers/types";
import { allAirportsSelector } from "../../../../../../../shop/reducer/selectors";
import { setAirportFilter } from "../../../../../../actions/actions";
import { getAirportFilter } from "../../../../../../reducer/index";
import { AirportFilterSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const airportFilter = getAirportFilter(state);
  const allAirports = allAirportsSelector(state);
  return {
    airportFilter,
    allAirports,
  };
};

const mapDispatchToProps = {
  setAirportFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AirportFilterProps = ConnectedProps<typeof connector>;

export const ConnectedAirportFilterSelection = connector(
  AirportFilterSelection
);
