import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AncillaryPostBookingConfirmation } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { postBookingOfferStateSelector } from "../../reducer/selectors/postBooking";
import {
  fetchPostBookingOffer,
  purchasePostBookingOffer,
  postBookingOfferComplete,
  resetFetchAncillaryPostBookingOfferState,
} from "../../actions/actions";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer/selectors";

const mapStateToProps = (state: IStoreState) => ({
  postBookingOfferState: postBookingOfferStateSelector(state),
  selectedAccountReferenceId:
    getSelectedAccountReferenceIdIfRedemptionEnabled(state),
});

const mapDispatchToProps = {
  fetchPostBookingOffer,
  purchasePostBookingOffer,
  postBookingOfferComplete,
  resetFetchAncillaryPostBookingOfferState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PostBookingConfirmationConnectorProps = ConnectedProps<
  typeof connector
>;

export const PostBookingConfirmation = withRouter(
  connector(AncillaryPostBookingConfirmation)
);
