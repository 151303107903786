import { Dayjs } from "dayjs";

export const REBOOK_HEADER_TITLE = (
  isSelectingReturnFlightForRebook: boolean,
  departureDate: Dayjs | null
) => {
  return `Choose a new ${
    isSelectingReturnFlightForRebook ? "return" : "outbound"
  } flight${
    departureDate
      ? ` on <strong>${departureDate.format("ddd, MMM DD")}</strong>`
      : ""
  }`;
};
export const REBOOK_HEADER_SUBTITLE = `Rebook a new flight now at no additional cost.`;
