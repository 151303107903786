export const SHOW_MORE_NUM = 5;
export const INITIAL_RESULT_SET_SIZE = 5;
export const LOADING_FLIGHTS_STRING = "Loading Flights..";
export const LOADING_FLIGHT_DETAILS_STRING = "Loading Flight Details";

export const FLIGHTS_NOT_FOUND_TITLE = "No flights found";
export const GENERIC_FLIGHTS_NOT_FOUND_SUBTITLE =
  "There are no flights available.";
export const FARE_CLASS_FLIGHTS_NOT_FOUND_SUBTITLE =
  "There are no flights available in this fare category.";
export const FITLERED_FLIGHTS_NOT_FOUND_SUBTITLE =
  "There are no flights available for this filter set.";

export const ERROR_TITLE = "Oops…something went wrong!";
export const ERROR_SUBTITLE =
  "Check to see if you are connected to a network right now or try reloading.";
export const RELOAD = "Reload";
export const NO_FLIGHTS_FOUND = "No flights found";
export const ADJUST_DATES = "Adjust Dates";
export const RESET_FILTERS = "Reset Filters";
