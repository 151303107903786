import { createSelector } from "@reduxjs/toolkit";
import { priceFreezeExperimentsSelector } from "../../../reducer";
import {
  PRICE_FREEZE_FLIGHTS_LIST_CONTROL,
  PRICE_FREEZE_FLIGHTS_LIST_CURRENT_ENTRY,
  PRICE_FREEZE_FLIGHTS_LIST_EXPERIMENT,
  PRICE_FREEZE_FLIGHTS_LIST_REMOVE_CURRENT_ENTRY,
} from "../../../../../context/experiments";

export const selectPriceFreezeFlightListVariation = createSelector(
  priceFreezeExperimentsSelector,
  (flightFreezeExperiments) => {
    if (!flightFreezeExperiments) {
      return PRICE_FREEZE_FLIGHTS_LIST_CONTROL;
    }
    return flightFreezeExperiments[PRICE_FREEZE_FLIGHTS_LIST_EXPERIMENT];
  }
);

export const selectIsPriceFreezeFlightListCtaEnabled = createSelector(
  selectPriceFreezeFlightListVariation,
  (variation) => {
    return (
      variation === PRICE_FREEZE_FLIGHTS_LIST_CURRENT_ENTRY ||
      variation === PRICE_FREEZE_FLIGHTS_LIST_REMOVE_CURRENT_ENTRY
    );
  }
);

export const selectIsPriceFreezeCurrentEntryDisabled = createSelector(
  selectPriceFreezeFlightListVariation,
  (variation) => {
    return variation === PRICE_FREEZE_FLIGHTS_LIST_REMOVE_CURRENT_ENTRY;
  }
);
