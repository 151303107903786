import React from "react";
import { RouteComponentProps } from "react-router";
import { AlertOptIn } from "halifax";

import { OptInConnectorProps } from "./container";
import { IAlertOptInCore } from "redmond";

export interface IOptInProps
  extends IAlertOptInCore,
    RouteComponentProps,
    OptInConnectorProps {
  smsBeenSelected: boolean;
  appNotifBeenSelected: boolean;
  hasSelectedDisruption: boolean;
  disruptionOptInEnabled: boolean;
  disrutpionDelayThresholdStringInHours: string;
  nonFDAdisruptionOptInEnabled: boolean;
}

export const OptIn = ({
  hasSelectedDisruption,
  smsBeenSelected,
  appNotifBeenSelected,
  toggleAppNotifOptIn,
  toggleSMSOptIn,
  disabled,
  disruptionOptInEnabled,
  nonFDAdisruptionOptInEnabled,
  tcpaConsentPhoneNumber,
  disrutpionDelayThresholdStringInHours,
}: IOptInProps) => {
  if (!disruptionOptInEnabled && !nonFDAdisruptionOptInEnabled) {
    return null;
  }

  return (
    <AlertOptIn
      isMobile={false}
      appNotifBeenSelected={appNotifBeenSelected}
      smsBeenSelected={smsBeenSelected}
      toggleAppNotifOptIn={toggleAppNotifOptIn}
      toggleSMSOptIn={toggleSMSOptIn}
      hasSelectedDisruption={hasSelectedDisruption}
      disabled={disabled}
      disruptionOptInEnabled={disruptionOptInEnabled}
      nonFDAdisruptionOptInEnabled={nonFDAdisruptionOptInEnabled}
      tcpaConsentPhoneNumber={tcpaConsentPhoneNumber}
      disruptionDelayThresholdStringInHours={disrutpionDelayThresholdStringInHours}
    />
  );
};
