export const FLIGHTS_HEADER = "Flights";

export const ROUND_TRIP_TEXT = "Round Trip";

export const ONE_WAY_TEXT = "One Way";

export const CHOOSE_DATE = "Choose your dates";

export const SEARCH_FLIGHTS = "Search Flights";

export const WHERE_FROM = "Where from?";
export const WHERE_TO = "Where to?";

export const MISSING_INFO_SEARCH_ERROR =
  "To continue, please complete all required fields.";
