import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { disruptionsExerciseApiPrefix } from "../paths";
import { RebookSummaryRequest, RebookSummaryResponse } from "redmond";

const END_POINT: string = `${disruptionsExerciseApiPrefix}/rebookSummary`;

export const fetchRebookSummary = (
  body: RebookSummaryRequest
): Promise<RebookSummaryResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<RebookSummaryResponse> = await axios.post(END_POINT, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchRebookSummary;
