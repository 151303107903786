import { connect, ConnectedProps } from "react-redux";
import { FlightSearch } from "./component";
import { resetState } from "../../actions/actions";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import { IStoreState } from "../../reducers/types";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../rewards/reducer";
import { withRouter } from "react-router";
import { fetchUpcomingFlightCrossSell } from "../cross-sell/actions/actions";
import {
  fetchUserFlightPreferences,
  setAirlineFilter,
  setApplyUserFlightPreferences,
  setFareclassOptionFilter,
  setOrigin,
  setStopsOption,
} from "./actions/actions";
import {
  getApplyUserFlightPreferences,
  getHasUserSetFlightPreferences,
  getMobileSearchProgress,
  getOrigin,
  getUserFlightPreferences,
  getUserFlightPreferencesCallState,
} from "./reducer";
import { listPaymentMethods } from "../shop/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  rewardsAccounts: getRewardsAccounts(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
  currentStep: getMobileSearchProgress(state),
  shouldApplyUserFlightPreferences: getApplyUserFlightPreferences(state),
  userFlightPreferences: getUserFlightPreferences(state),
  origin: getOrigin(state),
  hasUserSetFlightPreferences: getHasUserSetFlightPreferences(state),
  userFlightPreferencesCallState: getUserFlightPreferencesCallState(state),
});

const mapDispatchToProps = {
  resetState,
  fetchRewardsAccounts,
  fetchUpcomingFlightCrossSell,
  setFareclassOptionFilter,
  fetchUserFlightPreferences,
  setAirlineFilter,
  setStopsOption,
  setOrigin,
  setApplyUserFlightPreferences,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightSearch = withRouter(connector(FlightSearch));
