import React from "react";
import { Box, Typography } from "@material-ui/core";
import { MonthAndDatePicker, MonthAndDatePickerType } from "../../../index";
import { FlightSearchButton } from "../../../SearchButton";
import { MobileCalendarPickerConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import clsx from "clsx";
import { Icon, IconName, isDefined, PickerType } from "halifax";
import { TripCategory } from "redmond";
import dayjs from "dayjs";

export interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  departure: Date | null;
  returnDate: Date | null;
  focusedMonthIndex: number;
  onComplete?: () => void;
  isHistoryPushDisabled?: boolean;
  multicityRouteIndex?: number;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    readyToSearch,
    focusedMonthIndex,
    onComplete,
    setDepartureDate,
    setReturnDate,
    setMulticityDepartureDate,
    multicityRouteIndex,
    departure,
    returnDate,
    isHistoryPushDisabled,
    populateFlightShopQueryParams,
    tripCategory,
    minAllowedDate,
    maxAllowedDate,
    onSelectDates,
  } = props;

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="mobile-calendar-picker-root redesign-v3">
      <MonthAndDatePicker
        className="b2b-flights"
        pickerType={
          tripCategory === TripCategory.ROUND_TRIP
            ? PickerType.RANGE
            : PickerType.DAY
        }
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={
          isDefined(multicityRouteIndex)
            ? (date) => {
                setMulticityDepartureDate(date, multicityRouteIndex);
                onComplete && onComplete();
              }
            : setDepartureDate
        }
        setEndDate={isDefined(multicityRouteIndex) ? () => {} : setReturnDate}
        startDate={departure}
        endDate={returnDate}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
        header={
          <Box className="flights-mobile-calendar-header">
            <DatePickerHeader
              label={textConstants.OUTBOUND}
              value={
                departure ? dayjs(departure).format("ddd, MMM D") : undefined
              }
            />
            {tripCategory === TripCategory.ROUND_TRIP ? (
              <DatePickerHeader
                label={textConstants.RETURN}
                value={
                  returnDate
                    ? dayjs(returnDate).format("ddd, MMM D")
                    : undefined
                }
              />
            ) : null}
          </Box>
        }
      />
      {readyToSearch && (
        <FlightSearchButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={textConstants.SEARCH_FLIGHTS_TEXT}
          onClick={(history) => {
            !isHistoryPushDisabled &&
              populateFlightShopQueryParams &&
              populateFlightShopQueryParams({
                history,
                useHistoryPush: true,
                forceQueryUpdate: false,
              });
            onComplete && onComplete();
            onSelectDates?.(departure, returnDate);
          }}
        />
      )}
    </Box>
  );
};
