import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingSuccessModal } from "./component";
import {
  getEarnedString,
  getFinalizedItinerary,
  getConfirmationEmail,
  getPriceDropProperties,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { redoSearch, chargeAgentBookingFee } from "../../actions/actions";
import {
  airportsSelector,
  selectedTripSelector,
  tripDetailsSelector,
  predictionSelector,
} from "../../../shop/reducer/selectors";
import {
  getHasUserSetFlightPreferences,
  getTripCategory,
  getUserFlightPreferencesCallState,
} from "../../../search/reducer";
import { TripCategory } from "redmond";
import {
  getHotelAvailabilityFromDate,
  getAvailableCrossSellHotels,
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityUntilDate,
  getPotentialCrossSellOffers,
  getHotelAvailabilityNextPageToken,
} from "../../../cross-sell/reducer/selectors";
import { fetchMoreCrossSellHotelAvailability } from "../../../cross-sell/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  itinerary: getFinalizedItinerary(state),
  airports: airportsSelector(state, selectedTripSelector(state)?.tripId || ""),
  tripDetails: tripDetailsSelector(
    state,
    selectedTripSelector(state)?.tripId || ""
  ),
  isRoundTrip: getTripCategory(state) === TripCategory.ROUND_TRIP,
  earnString: getEarnedString(state),
  confirmationEmail: getConfirmationEmail(state),
  prediction: predictionSelector(state),
  priceDropViewedProperties: getPriceDropProperties(state),
  lodgings: getAvailableCrossSellHotels(state),
  hasUserSetFlightPreferences: getHasUserSetFlightPreferences(state),
  userFlightPreferencesCallState: getUserFlightPreferencesCallState(state),
  potentialCrossSellOffers: getPotentialCrossSellOffers(state),
  searchLocationResult: getHotelAvailabilitySearchLocationResult(state),
  fromDate: getHotelAvailabilityFromDate(state),
  untilDate: getHotelAvailabilityUntilDate(state),
  nextPageToken: getHotelAvailabilityNextPageToken(state),
});

const mapDispatchToProps = {
  chargeAgentBookingFee,
  redoSearch,
  fetchMoreCrossSellHotelAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingSuccessModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingSuccessModal = withRouter(
  connector(BookingSuccessModal)
);
