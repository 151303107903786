import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getDestination,
  getOrigin,
  getTripCategory,
  getDepartureDateBucketsLoading,
  getDepartureDate,
  getReturnDate,
  getHasOriginAutocompleteError,
  getHasDestinationAutocompleteError,
  getNumTravelers,
} from "../../reducer";
import {
  fetchDepartureCalendar,
  setCalendar,
  setDestinationAutocompleteError,
  setOriginAutocompleteError,
  setTripCategory,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileFlightSearchControlV2 } from "./component";
import { populateFlightShopQueryParams } from "../../../shop/actions/actions";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  setTripCategory,
  setOriginAutocompleteError,
  setDestinationAutocompleteError,
  populateFlightShopQueryParams,
};
const mapStateToProps = (state: IStoreState) => ({
  originCode: getOrigin(state)?.id.code.code,
  destinationCode: getDestination(state)?.id.code.code,
  tripCategory: getTripCategory(state),
  departureDateBucketsLoading: getDepartureDateBucketsLoading(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  hasOriginAutocompleteError: getHasOriginAutocompleteError(state),
  hasDestinationAutocompleteError: getHasDestinationAutocompleteError(state),
  origin: getOrigin(state),
  destination: getDestination(state),
  numTravelers: getNumTravelers(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightSearchV2ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFlightSearchControlV2 = withRouter(
  connector(MobileFlightSearchControlV2)
);
