import React from "react";
import { ButtonWrap, Icon, IconName, SliderDropdown } from "halifax";
import "./styles.scss";
import {
  DURATION_CONTENT_HEADING_TEXT,
  DURATION_DROPDOWN_LABEL_TEXT,
  DURATION_POPOVER_HEADING_TEXT,
} from "./textConstants";
import { debounce } from "lodash-es";
import { trackEvent } from "../../../../../../../../api/v0/analytics/trackEvent";
import { generateFilterTrackingEvent } from "../../../../../../actions/actions";
import { initialFilterOptions } from "../../../../../../reducer";
import clsx from "clsx";

interface DurationSelectionProps {
  value: number;
  setValue: (duration: number) => void;
  minValue: number;
  maxValue: number;
  flightCount: number;
  appliedLabel?: string;
  showDropdownContentOnly?: boolean;
  isMobile?: boolean;
}

export const DurationSelection = ({
  value,
  setValue,
  minValue,
  maxValue,
  flightCount,
  appliedLabel,
  showDropdownContentOnly,
  isMobile,
}: DurationSelectionProps) => {
  const [isMinValue, setIsMinValue] = React.useState<boolean>(false);
  const debouncedAction = debounce((duration: number) => {
    setValue(duration);
    trackEvent(generateFilterTrackingEvent("duration", "list"));
  }, 300);
  const [stateDebounceDispatchAction] = React.useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const checkIfMinValue = (value: number) => {
    if (!showDropdownContentOnly) {
      if (value === initialFilterOptions.durationFilter) {
        setIsMinValue(false);
      } else {
        const sliderValuePercentage =
          (value - roundedMin) / (roundedMax - roundedMin);

        setIsMinValue(sliderValuePercentage <= 0.05);
      }
    }
  };

  const handleChange = (newValue: number) => {
    stateDebounceDispatchAction(newValue);

    checkIfMinValue(newValue);
  };

  const handleReset = () => {
    setValue(initialFilterOptions.durationFilter);
    setIsMinValue(false);
  };

  const roundedMin = React.useMemo(
    () => minValue + (60 - (minValue % 60)), // round up to nearest hour
    [minValue]
  );
  const roundedMax = React.useMemo(
    () => maxValue + (60 - (maxValue % 60)),
    [maxValue]
  );

  React.useEffect(() => {
    if (value >= maxValue) {
      handleReset();
    }

    checkIfMinValue(value);
  }, [value]);

  const sliderLabelFormat = React.useCallback(
    (newValue: number) => {
      return newValue < maxValue ? `Under ${newValue / 60}h` : "Any";
    },
    [maxValue]
  );

  const minMaxLabelFormat = React.useCallback((newValue: number) => {
    return `${newValue / 60}h`;
  }, []);

  const valueToShow = value || roundedMax;

  return (
    <div className="flight-shop-duration-filter-container" tabIndex={0}>
      {showDropdownContentOnly && (
        <div className="header-container">
          <Icon name={IconName.StopwatchOutline} />
          <div className="titles-wrapper">{DURATION_CONTENT_HEADING_TEXT}</div>
        </div>
      )}
      <SliderDropdown
        className={clsx("b2b-shop-filter", "duration", {
          "has-value": !!value,
          "content-only": showDropdownContentOnly,
          mobile: isMobile,
          "min-value": isMinValue,
        })}
        popoverClassName="b2b duration-selection-popover"
        dropdownLabel={DURATION_DROPDOWN_LABEL_TEXT(appliedLabel)}
        sliderHeader={
          showDropdownContentOnly
            ? undefined
            : DURATION_POPOVER_HEADING_TEXT(flightCount)
        }
        minValue={roundedMin}
        maxValue={roundedMax}
        value={valueToShow}
        setValue={handleChange}
        alwaysShowTooltip
        showResetButton={value !== 0}
        showMinLabel
        sliderLabelFormat={sliderLabelFormat}
        minMaxLabelFormat={minMaxLabelFormat}
        reset={handleReset}
        dropdownIcon={
          appliedLabel ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                handleReset();
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        showDropdownContentOnly={showDropdownContentOnly}
      />
    </div>
  );
};
