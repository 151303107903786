import dayjs from "dayjs";

export const getSummaryPanelHeader = (
  isDeparture: boolean,
  location: string,
  date: string,
  parsable = false
) =>
  `${isDeparture ? "Outbound" : "Return"} to ${location} ${
    parsable ? ":" : ""
  } on ${dayjs(date).format("ddd, MMM D")}`;

export const getStopString = (numStops: number) =>
  numStops > 1
    ? `${numStops} stops`
    : numStops === 1
    ? `${numStops} stop`
    : "Nonstop";

export const VIEW_DETAILS_TEXT = "View details";
export const VIEW_MULTICITY_DETAILS_TEXT = "View multi-city flight details";
export const VIEW_MULTICITY_SUMMARY = "View multi-city flight summary";
export const MULTICITY_SUMMARY_MODAL_TITLE = "Multi-city flight summary";
export const CHANGE_TEXT = "Change";
export const CHANGE_FLIGHT_TEXT = "Change Flight";
export const AIRLINE_SPECIFIC_RESTRICTIONS =
    "Compare airline-specific restrictions";
