import {
  ChoosePriceFreezeProperties,
  CHOOSE_PRICE_FREEZE,
  EntryButtonEnum,
  FreezePriceClickProperties,
  FREEZE_PRICE_CLICK,
  PreviousFlightEnum,
  PriceFreezeEntryEnum,
  TripDetails,
} from "redmond";
import { FlightShopStep } from "../../reducer";
import queryStringParser from "query-string";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { Dispatch, SetStateAction } from "react";
import { History } from "history";
import { PATH_FREEZE } from "../../../../utils/urlPaths";

export const getMapProgressToEvent = (progress: number) => {
  const mapProgressToEvent = {
    [FlightShopStep.PricePrediction]: PriceFreezeEntryEnum.FORECAST,
    [FlightShopStep.ChooseDeparture]: PriceFreezeEntryEnum.FLIGHT_LIST,
    [FlightShopStep.ReviewItinerary]: PriceFreezeEntryEnum.TRIP_SUMMARY,
  };
  return mapProgressToEvent[progress];
};

export const handleClickOpenInfoModalLink = (
  setPriceFreezeExplanationModalOpen: Dispatch<SetStateAction<boolean>>,
  history: History
) => {
  const { flightShopProgress } = queryStringParser.parse(
    history.location.search
  );
  if (flightShopProgress) {
    trackEvent({
      eventName: CHOOSE_PRICE_FREEZE,
      properties: {
        price_freeze_entry: getMapProgressToEvent(+flightShopProgress),
      } as ChoosePriceFreezeProperties,
    });
  }

  setPriceFreezeExplanationModalOpen(true);
};

export interface IHandleFreezePriceProps extends IGoToPriceFreezeCheckoutProps {
  // note: this callback overwrites the default goToPriceFreezeCheckout behaviour
  handleContinue?: () => void;
  flightShopStep?: FlightShopStep;
}

export const handleFreezePrice = (args: IHandleFreezePriceProps) => {
  const {
    tripDetails,
    fareId,
    handleGoToPriceFreezeCheckout,
    handleContinue,
    history,
    entryButton,
    priceDropProtectionCandidateId,
    flightShopStep,
  } = args;
  const { flightShopProgress } = queryStringParser.parse(
    history.location.search
  )
  // we pass in the specific shopStep when we want to identify a certain entry point
  if (flightShopStep != undefined) {
    trackEvent({
      eventName: FREEZE_PRICE_CLICK,
      properties: {
        price_freeze_entry: getMapProgressToEvent(+flightShopStep),
      } as FreezePriceClickProperties,
    });
  } else if (flightShopProgress) {
    trackEvent({
      eventName: FREEZE_PRICE_CLICK,
      properties: {
        price_freeze_entry: getMapProgressToEvent(+flightShopProgress),
      } as FreezePriceClickProperties,
    });
  }

  if (handleContinue) {
    handleContinue();
  } else {
    goToPriceFreezeCheckout({
      tripDetails,
      fareId,
      history,
      handleGoToPriceFreezeCheckout,
      entryButton,
      priceDropProtectionCandidateId,
    });
  }
};

export interface IGoToPriceFreezeCheckoutProps {
  tripDetails?: TripDetails;
  tripId?: string;
  fareId?: string;
  handleGoToPriceFreezeCheckout?: () => void;
  priceDropProtectionCandidateId?: string;
  history: History;
  // note: entry, entryButton and prevFlight are used for overwriting default values
  entry?: PriceFreezeEntryEnum;
  entryButton?: EntryButtonEnum;
  prevFlight?: PreviousFlightEnum;
}

export const goToPriceFreezeCheckout = (
  args: IGoToPriceFreezeCheckoutProps
) => {
  const {
    tripDetails,
    tripId,
    fareId,
    history,
    handleGoToPriceFreezeCheckout,
    entry,
    entryButton,
    prevFlight,
    priceDropProtectionCandidateId,
  } = args;

  if (handleGoToPriceFreezeCheckout) {
    handleGoToPriceFreezeCheckout();
  } else {
    let extraParams = "";
    const { flightShopProgress } = queryStringParser.parse(
      history.location.search
    );
    const progress = getMapProgressToEvent(+(flightShopProgress || ""));

    if ((tripDetails || tripId) && fareId) {
      const qs = queryStringParser.stringify({
        tripId: tripDetails?.id ?? tripId,
        outgoingFareId: fareId,
        returnFareId: fareId,
        priceDropCandidateId: priceDropProtectionCandidateId,
      });
      extraParams = `&${qs}`;
    }

    history.push({
      pathname: PATH_FREEZE,
      search: `${history.location.search}${extraParams}`,
      state: {
        entry: entry ?? progress,
        entryButton: entryButton ?? EntryButtonEnum.INFO_MODAL,
        prevFlight:
          prevFlight ??
          (progress === PriceFreezeEntryEnum.TRIP_SUMMARY
            ? PreviousFlightEnum.CHOSEN_FLIGHT
            : PreviousFlightEnum.CLP_FLIGHT),
      },
    });
  }
};
