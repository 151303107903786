import * as React from "react";
import { Box, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { FareclassShelfBrandName } from "redmond";
import {
  DisplayOnHoverLabel,
  InfoIcon,
  useDeviceTypes,
  RadioDropdown,
} from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { FareclassOptionDetails } from "../index";
import { FareclassOptionFilter } from "../../../../reducer";

export interface IFareclassOptionSelectionBase {
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: React.Dispatch<
    React.SetStateAction<FareclassOptionFilter>
  >;
}

interface IFareclassOptionSelectionProps extends IFareclassOptionSelectionBase {
  className?: string;
  hideTitle?: boolean;
}

export const FareclassOptionSelection = (
  props: IFareclassOptionSelectionProps
) => {
  const {
    className,
    hideTitle,
    fareclassOptionFilter,
    setFareclassOptionFilter,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setFareclassOptionFilter((filter) => {
      return {
        ...filter,
        [name as FareclassShelfBrandName]: checked,
      };
    });
  };

  const handleChangeMobile = (fareClass: string) => {
    const newFilters = { ...fareclassOptionFilter };
    for (const fare in newFilters) {
      if (fare === fareClass) {
        newFilters[fare] = true;
      } else {
        newFilters[fare] = false;
      }
    }

    setFareclassOptionFilter({ ...newFilters });
  };

  return (
    <Box className={clsx("fareclass-option-selection-root", className)}>
      <Box className="fareclass-option-selection-container">
        {!hideTitle && (
          <Box className="header-container">
            {textConstants.FARECLASS_HEADER_TEXT}
            <DisplayOnHoverLabel
              className="fareclass-details"
              label={<InfoIcon className="fareclass-details-info-icon" />}
            >
              <FareclassOptionDetails />
            </DisplayOnHoverLabel>
          </Box>
        )}
        <Box className="selection-container">
          <FormGroup className="fareclass-option-filter">
            {matchesMobile ? (
              <FareClassRadios
                fareClassOptionFilter={fareclassOptionFilter}
                handleChangeMobile={handleChangeMobile}
              />
            ) : (
              renderCheckboxes(fareclassOptionFilter, handleChange)
            )}
          </FormGroup>
        </Box>
      </Box>
    </Box>
  );
};

interface IFareclassOption {
  label: string;
  option: FareclassShelfBrandName;
}

const fareclassOptions: IFareclassOption[] = [
  { label: textConstants.BASIC_TEXT, option: "basic" },
  { label: textConstants.STANDARD_TEXT, option: "standard" },
  { label: textConstants.ENHANCED_TEXT, option: "enhanced" },
  { label: textConstants.PREMIUM_TEXT, option: "premium" },
  { label: textConstants.LUXURY_TEXT, option: "luxury" },
];

interface IFareClassRadioProps {
  fareClassOptionFilter: FareclassOptionFilter;
  handleChangeMobile: (option: string) => void;
}

const FareClassRadios = ({
  fareClassOptionFilter,
  handleChangeMobile,
}: IFareClassRadioProps) => {
  const mobileFareclassOptions = [
    { label: textConstants.BASIC_TEXT, value: "basic" },
  ];

  const checked =
    mobileFareclassOptions.find(({ value }) => fareClassOptionFilter[value])
      ?.value || "all";

  return (
    <RadioDropdown
      dropdownLabel={""}
      options={mobileFareclassOptions}
      setOption={handleChangeMobile}
      selected={checked}
      showDropdownContentOnly={true}
      buttonClassName="b2b-shop-filter"
      groupAriaLabel={textConstants.CHOOSE_FARECLASS_TEXT}
      buttonAriaLabel={textConstants.CHANGE_FARECLASS_TEXT}
    />
  );
};

const renderCheckboxes = (
  fareclassOptionFilter: FareclassOptionFilter,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
) =>
  fareclassOptions.map(({ label, option }) => (
    <FormControlLabel
      className="fareclass-option"
      key={option}
      value={option}
      label={<span className="fareclass-option-label">{label}</span>}
      name={option}
      labelPlacement="start"
      control={
        <Checkbox
          className="fareclass-option-checkbox"
          checked={fareclassOptionFilter[option]}
          name={option}
          onChange={handleChange}
        />
      }
    />
  ));
