export const apiVersionPrefix: string = "/api/v2";

const ancillariesApiPrefix: string = `${apiVersionPrefix}/ancillaries`;
export const ancillaryOfferPath = `${ancillariesApiPrefix}/offer/get`;
export const cfarOfferBatchPath = `${ancillariesApiPrefix}/refundable/cfar/get/batch`;

const ancillariesPostBookingApiPrefix: string = `${ancillariesApiPrefix}/postbooking`;
export const ancillaryPostBookingOfferPath = `${ancillariesPostBookingApiPrefix}/offer/get`;
export const ancillaryPostBookingOfferPurchasePath = `${ancillariesPostBookingApiPrefix}/offer/purchase`;

export const tripSummariesApiPrefix: string = `${apiVersionPrefix}/shopSummary`;
