import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { priceFreezeOfferDataPath } from "../paths";
import {
  PriceFreezeOfferDataRequest,
  PriceFreezeOfferDataResponse,
} from "redmond";

export const getPriceFreezeOfferData = (
  body: PriceFreezeOfferDataRequest
): Promise<PriceFreezeOfferDataResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      if (body.tripId) {
        const res: AxiosResponse<PriceFreezeOfferDataResponse> = await axios.post(priceFreezeOfferDataPath, body, {
          baseURL: config.baseURL,
        });

        resolve(res.data);
      } else {
        resolve({ offerData: [] });
      }
    } catch (e) {
      reject(e);
    }
  });

export default getPriceFreezeOfferData;
