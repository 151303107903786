import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RebookNoResults } from "./component";
import { rebookSummaryCallStateSelector } from "../../reducer/selectors";
import { fetchRebookSummary } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { flightShopProductRedeemChoice } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    rebookSummaryCallState: rebookSummaryCallStateSelector(state),
    productRedeemChoice: flightShopProductRedeemChoice(state),
  };
};

const mapDispatchToProps = {
  fetchRebookSummary,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RebookNoResultsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRebookNoResults = connector(withRouter(RebookNoResults));
