import { takeLatest } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { batchFetchCfarOffersSaga } from "./cfar";
import {
  fetchRebookSummarySaga,
  exerciseDisruptionProtectionRebookSaga,
  fetchFlightDisruptionsSaga,
} from "./disruptionProtection";
import { fetchSingleFlightItinerarySaga } from "./fetchSingleFlightItinerarySaga";
import { fetchAncillaryPostBookingOfferSaga } from "./postBooking/fetchAncillaryOfferPostBookingSaga";
import { purchaseAncillaryPostBookingOfferSaga } from "./postBooking/purchaseAncillaryOfferPostBookingSaga";

export function* watchFetchRebookSummary() {
  yield takeLatest(actionTypes.FETCH_REBOOK_SUMMARY, fetchRebookSummarySaga);
}

export function* watchExerciseDisruptionProtectionRebook() {
  yield takeLatest(
    actionTypes.EXERCISE_DISRUPTION_PROTECTION_REBOOK,
    exerciseDisruptionProtectionRebookSaga
  );
}

export function* watchFetchSingleFlightItinerary() {
  yield takeLatest(
    actionTypes.FETCH_SINGLE_FLIGHT_ITINERARY,
    fetchSingleFlightItinerarySaga
  );
}

export function* watchFetchFlightDisruptionByItineraryId() {
  yield takeLatest(
    actionTypes.FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID,
    fetchFlightDisruptionsSaga
  );
}

export function* watchBatchFetchCfarOffers() {
  yield takeLatest(
    actionTypes.BATCH_FETCH_CFAR_OFFERS,
    batchFetchCfarOffersSaga
  );
}

export function* watchFetchAncillaryPostBookingOffer() {
  yield takeLatest(
    actionTypes.FETCH_ANCILLARY_POST_BOOKING_OFFER,
    fetchAncillaryPostBookingOfferSaga
  );
}

export function* purchaseAncillaryPostBookingOffer() {
  yield takeLatest(
    actionTypes.PURCHASE_ANCILLARY_POST_BOOKING_OFFER,
    purchaseAncillaryPostBookingOfferSaga
  );
}
