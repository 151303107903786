import {
  PriceDropProtectionEnum,
  PriceDropProtection,
  IsEligible,
  PRICE_DROP_PROTECTION_MODAL_SUBTITLE,
} from "redmond";

export const PDP_TITLE = "How it works";
export const PDP_CTA_TEXT = "Read terms and conditions";
export const PDP_HEADER =
  "Book now with <span class='font-regular'>free price drop protection</span>";

const getMonitoringDaysCount = (priceDropProtection: PriceDropProtection) => {
  if (priceDropProtection) {
    const days =
      priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible
        ? (priceDropProtection as IsEligible).monitoringDuration.inSeconds /
          86400
        : 0;
    return days;
  } else {
    return "";
  }
};

export const getMaxRefund = (priceDropProtection: PriceDropProtection) => {
  if (
    priceDropProtection.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible
  ) {
    return priceDropProtection.maximumRefund.display;
  } else {
    return "";
  }
};

export const getSubtitle = (priceDropProtection: PriceDropProtection, isPriceDropCreditEnabled: boolean) => {
  const monitoringDays = getMonitoringDaysCount(priceDropProtection);
  const maxRefund = getMaxRefund(priceDropProtection);
  return PRICE_DROP_PROTECTION_MODAL_SUBTITLE(monitoringDays, maxRefund, isPriceDropCreditEnabled)
};

export const FREE_PRICE_DROP_PROTECTION_INCLUDED =
  "Free price drop protection included";

export const FREE_PRICE_DROP_PROTECTION_SUBTITLE = (difference: string, isPriceDropCredit: boolean) => {
  let subtitle = `If the price drops within 10 days, you'll get back up to ${difference} of the difference.`;
  if(isPriceDropCredit) {
    subtitle = `If the price drops within 10 days, you'll get back up to ${difference} of the difference as a travel credit`;
  }
  return subtitle;
}
  

export const CHECKOUT_PRICE_BREAKDOWN_HEADER = "Checkout Breakdown";
export const VIEW_PRICE_DROP_ARIA_LABEL = "View price drop details";
