import { put } from "redux-saga/effects";

import { fetchTripDetails as fetchDetails } from "../../../api/v0/shop/fetchTripDetails";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import type { TripDetails } from "redmond";

export function* fetchTripDetailsSaga({
  tripId,
  priceFreezeOffersEnabled,
  filterFareDetail,
}: actions.IFetchTripDetails) {
  try {
    const tripDetails: TripDetails = yield fetchDetails(tripId);

    if (filterFareDetail) {
      tripDetails.fareDetails = tripDetails.fareDetails.filter((fare) =>
        filterFareDetail(fare)
      );
    }

    yield put(actions.setTripDetails(tripDetails));

    // For experiment "c1-price-freeze-new-flights-list-entry"
    if (priceFreezeOffersEnabled) {
      for (const fareDetail of tripDetails.fareDetails) {
        yield put(
          actions.fetchPriceFreezeOfferForFareDetails({
            fareId: fareDetail.id,
            tripId,
          })
        );
      }
    }
  } catch (e) {
    Logger.debug(e);
  }
}
