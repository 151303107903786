import * as actionTypes from "./constants";
import {
  CreditDetail,
  Transaction,
  TravelWalletCredit,
  TravelWalletOffer,
} from "redmond";

export const fetchTravelWalletDetails = (): IFetchTravelOffersDetails => ({
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS,
});

export interface IFetchTravelOffersDetails {
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS;
}

export const fetchTravelWalletDetailsCallStateFailed =
  (): IFetchTravelWalletDetailsCallStateFailed => ({
    type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED,
  });

export interface IFetchTravelWalletDetailsCallStateFailed {
  type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED;
}

export const setTravelWalletOffers = (
  offers: TravelWalletOffer[]
): ISetTravelWalletOffers => ({
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS,
  payload: offers,
});

export interface ISetTravelWalletOffers {
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS;
  payload: TravelWalletOffer[];
}

export const setTravelWalletCredit = (
  creditBreakdown: CreditDetail[],
  credit?: TravelWalletCredit
): ISetTravelWalletCredit => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT,
  creditBreakdown,
  credit,
});

export interface ISetTravelWalletCredit {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT;
  creditBreakdown: CreditDetail[];
  credit?: TravelWalletCredit;
}

export const fetchTravelWalletCreditHistory =
  (): IFetchTravelWalletCreditHistory => ({
    type: actionTypes.FETCH_TRAVEL_WALLET_CREDIT_HISTORY,
  });

export interface IFetchTravelWalletCreditHistory {
  type: actionTypes.FETCH_TRAVEL_WALLET_CREDIT_HISTORY;
}
export const fetchTravelWalletCreditHistoryCallStateSuccess =
  (): IFetchTravelWalletCreditHistoryCallStateSuccess => ({
    type: actionTypes.SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE,
  });

export interface IFetchTravelWalletCreditHistoryCallStateSuccess {
  type: actionTypes.SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE;
}

export const fetchTravelWalletCreditHistoryCallStateFailure =
  (): IFetchTravelWalletCreditHistoryCallStateFailure => ({
    type: actionTypes.SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE,
  });

export interface IFetchTravelWalletCreditHistoryCallStateFailure {
  type: actionTypes.SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE;
}

export const setTravelWalletCreditHistory = (
  creditHistory: Transaction[]
): ISetTravelWalletCreditHistory => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT_HISTORY,
  creditHistory,
});

export interface ISetTravelWalletCreditHistory {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT_HISTORY;
  creditHistory: Transaction[];
}

export type FlightTravelWalletActions =
  | IFetchTravelOffersDetails
  | IFetchTravelWalletDetailsCallStateFailed
  | ISetTravelWalletOffers
  | ISetTravelWalletCredit
  | IFetchTravelWalletCreditHistory
  | IFetchTravelWalletCreditHistoryCallStateSuccess
  | IFetchTravelWalletCreditHistoryCallStateFailure
  | ISetTravelWalletCreditHistory;
