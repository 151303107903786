import React, { useEffect, useState, useMemo, useContext } from "react";
import "./styles.scss";
import "./styles.scss";
import { RouteComponentProps } from "react-router";
import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  MobileFloatingButton,
  MobilePopoverCard,
  PassportInfoForm,
  useDeviceTypes,
} from "halifax";
import { INationality, IPassport, IPerson } from "redmond";
import { PassportModalConnectorProps } from "./container";
import {
  getTitle,
  SUBTITLE,
  getPassengerName,
  PASSPORT_MODAL_ENHANCEMENT_SUBTITLE,
  PASSPORT_MODAL_ENHANCEMENT_TITLE,
} from "./textConstants";
import {
  useExperiments,
  getExperimentVariant,
  PASSPORT_ENHANCEMENT,
  AVAILABLE,
} from "../../../../context/experiments";
import { ClientContext } from "../../../../App";
import clsx from "clsx";
import dayjs from "dayjs";

export interface PassportModalProps
  extends PassportModalConnectorProps,
    RouteComponentProps {
  onCloseMobileModal?: () => void;
}

export const PassportModal = ({
  userPassengers,
  infantPassengers,
  selectedTravelerIds,
  selectedInfantIds,
  updateUserPassenger,
  validatePassengers,
  isMissingPassport,
  scheduleQuote,
  onCloseMobileModal,
}: PassportModalProps) => {
  const { matchesDesktop } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  const [userIndex, setUserIndex] = useState(0);
  const [passengers, setPassengers] = useState<IPerson[]>([]);
  const [passportInfo, setPassportInfo] = useState<IPassport | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [saveAndContinuePressed, setSavedAndContinuePressed] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const expState = useExperiments();

  const passportEnhancementExperiment = getExperimentVariant(
    expState.experiments,
    PASSPORT_ENHANCEMENT
  );
  const isPassportEnhancementExperiment = useMemo(() => {
    return passportEnhancementExperiment === AVAILABLE;
  }, [passportEnhancementExperiment]);

  useEffect(() => {
    setPassengers(
      userPassengers
        .concat(infantPassengers)
        .map((pax) => Object.assign({ ...pax }))
    );
  }, [userPassengers, infantPassengers]);

  useEffect(() => {
    setOpenModal(isMissingPassport);
  }, [isMissingPassport]);

  useEffect(() => {
    setSavedAndContinuePressed(false);
  }, [openModal]);

  const handleAddPassportInfo = () => {
    const person = passengers[userIndex];
    if (passportInfo) {
      person.passport = passportInfo;
    }
    if (userIndex < passengers.length - 1) {
      updateUserPassenger({ person }, false, true, true);
      setUserIndex(userIndex + 1);
    }
    if (userIndex === passengers.length - 1) {
      validatePassengers(selectedTravelerIds, selectedInfantIds);
      updateUserPassenger({ person }, false, true);
    }
    setPassportInfo(null);
  };

  const handleSaveAndContinue = () => {
    setSavedAndContinuePressed(true);
    const hasMissingInfo = passengers.some((pax) => {
      return (
        !pax.passport ||
        !pax.passport?.countryOfIssue ||
        !pax.passport?.expiration ||
        (dayjs(pax.passport?.expiration, "YYYY-MM-DD", true).isValid()
          ? dayjs(pax.passport?.expiration).isBefore(dayjs())
          : true) ||
        !pax.passport?.number ||
        !pax.nationality
      );
    });
    setShowErrors(hasMissingInfo);
    if (!hasMissingInfo) {
      passengers.map((pax, index) => {
        if (index !== passengers.length - 1) {
          setUserIndex(userIndex + 1);
          updateUserPassenger({ person: pax }, false, true, true);
        } else {
          validatePassengers(selectedTravelerIds, selectedInfantIds);
          updateUserPassenger({ person: pax }, false, true, true);
        }
      });
      validatePassengers(selectedTravelerIds, selectedInfantIds);
      scheduleQuote({
        agentFee: isAgentPortal ? 25 : 0,
        pollQuoteOnly: true,
      });
    }
  };

  const isDisabled = () => {
    return (
      !passportInfo ||
      !passportInfo?.countryOfIssue ||
      !passportInfo?.expiration ||
      !passportInfo?.number
    );
  };

  if (passengers.length < 1) {
    return <></>;
  }
  return (
    <>
      {matchesDesktop ? (
        <DesktopPopupModal
          className="passport-form-popup"
          open={openModal}
          hideXButton={!isPassportEnhancementExperiment}
          onClose={() => {
            setPassengers(
              userPassengers
                .concat(infantPassengers)
                .map((pax) => Object.assign({ ...pax }))
            ); // reset local state when modal closes (only saves info on Save and Continue CTA)
            setOpenModal(false);
            setSavedAndContinuePressed(false);
          }}
        >
          {isPassportEnhancementExperiment ? (
            <>
              {passengers.map((pax, index) => {
                return (
                  <PassportInfoForm
                    title={
                      index === 0 ? PASSPORT_MODAL_ENHANCEMENT_TITLE : undefined
                    }
                    subtitle={
                      index === 0
                        ? PASSPORT_MODAL_ENHANCEMENT_SUBTITLE
                        : undefined
                    }
                    subtitle2={getPassengerName(
                      `${pax.givenName} ${pax.surname}`,
                      index + 1
                    )}
                    passportInfo={pax.passport ?? null}
                    onPassportInfoChange={(info: IPassport) => {
                      if (info) pax.passport = info;
                    }}
                    onNationalityChange={(nationality?: INationality) => {
                      pax.nationality = nationality;
                    }}
                    nationality={pax.nationality}
                    useEnhancedPassportForm
                    hasErrors={saveAndContinuePressed && showErrors}
                  />
                );
              })}
              <ActionButton
                className="save-passport-button enhanced"
                message="Save and Continue"
                onClick={handleSaveAndContinue}
                defaultStyle="h4r-primary"
              />
            </>
          ) : (
            <>
              <PassportInfoForm
                title={getTitle(passengers.length, userIndex)}
                subtitle={SUBTITLE}
                subtitle2={getPassengerName(
                  `${passengers[userIndex].givenName} ${passengers[userIndex].surname}`
                )}
                passportInfo={passportInfo}
                onPassportInfoChange={(info: IPassport) =>
                  setPassportInfo(info)
                }
              />
              <ActionButton
                className="save-passport-button"
                message="Save"
                disabled={isDisabled()}
                onClick={handleAddPassportInfo}
                defaultStyle="h4r-secondary"
              />
            </>
          )}
        </DesktopPopupModal>
      ) : (
        <MobilePopoverCard
          className={clsx("passport-form-popup", {
            enhanced: isPassportEnhancementExperiment,
          })}
          open={openModal}
          centered={true}
          topRightButton={
            <ActionLink
              className="popup-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={() => {
                setPassengers(
                  userPassengers
                    .concat(infantPassengers)
                    .map((pax) => Object.assign({ ...pax }))
                ); // reset local state when modal closes (only saves info on Save and Continue CTA)
                setOpenModal(false);
                setSavedAndContinuePressed(false);
                onCloseMobileModal && onCloseMobileModal();
              }}
            />
          }
        >
          {isPassportEnhancementExperiment ? (
            <>
              {passengers.map((pax, index) => {
                return (
                  <PassportInfoForm
                    className={
                      index === passengers.length - 1 ? "last-pax" : undefined
                    }
                    title={
                      index === 0 ? PASSPORT_MODAL_ENHANCEMENT_TITLE : undefined
                    }
                    subtitle={
                      index === 0
                        ? PASSPORT_MODAL_ENHANCEMENT_SUBTITLE
                        : undefined
                    }
                    subtitle2={getPassengerName(
                      `${pax.givenName} ${pax.surname}`,
                      index + 1
                    )}
                    passportInfo={pax.passport ?? null}
                    onPassportInfoChange={(info: IPassport) => {
                      if (info) pax.passport = info;
                    }}
                    onNationalityChange={(nationality?: INationality) => {
                      pax.nationality = nationality;
                    }}
                    nationality={pax.nationality}
                    useEnhancedPassportForm
                    isMobile
                    hasErrors={saveAndContinuePressed && showErrors}
                  />
                );
              })}
              <MobileFloatingButton
                className="save-passport-button b2b"
                onClick={handleSaveAndContinue}
              >
                Save and Continue
              </MobileFloatingButton>
            </>
          ) : (
            <>
              <PassportInfoForm
                title={getTitle(passengers.length, userIndex)}
                subtitle={SUBTITLE}
                subtitle2={getPassengerName(
                  `${passengers[userIndex].givenName} ${passengers[userIndex].surname}`
                )}
                passportInfo={passportInfo}
                onPassportInfoChange={(info: IPassport) =>
                  setPassportInfo(info)
                }
              />

              <ActionButton
                className="save-passport-button"
                message="Save"
                disabled={isDisabled()}
                onClick={handleAddPassportInfo}
                defaultStyle="h4r-secondary"
              />
            </>
          )}
        </MobilePopoverCard>
      )}
    </>
  );
};
