import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { priceFreezeRefund } from "../paths";
import { RefundPriceFreezeRequest, RefundPriceFreezeResponse } from "redmond";

export const refundPriceFreezeApi = (
  body: RefundPriceFreezeRequest
): Promise<RefundPriceFreezeResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<RefundPriceFreezeResponse> = await axios.post(priceFreezeRefund, body, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
