import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { priceFreezePassengerCountsSelector } from "../../reducer/selectors";
import { setPassengerCounts } from "../../actions/actions";
import { resetPaymentCardSelectedAccounts } from "../../../book/actions/actions";
import { FlightFreezeTravelers } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    counts: priceFreezePassengerCountsSelector(state),
  };
};

const mapDispatchToProps = {
  setPassengerCounts,
  resetPaymentCardSelectedAccounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezeTravelersConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezeTravelers = withRouter(
  connector(FlightFreezeTravelers)
);
