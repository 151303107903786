import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { actions } from "./actions";
import { populateFlightBookQueryParams } from "../book/actions/actions";
import {
  flightShopProgressSelector,
  refreshPredictionSelector,
  tripSummariesByIdSelector,
  isFlightShopLoadingTripSummaries,
  flightShopTypeSelector,
  fetchSimilarFlightsCallStateSelector,
  sortOptionSelector,
  airportsSelector,
  selectedTripSelector,
} from "./reducer/selectors";
import {
  getOrigin,
  getDestination,
  getTripCategory,
} from "../search/reducer/selectors";
import { IStoreState } from "../../reducers/types";
import { FlightShop } from "./component";
import { setSortOption } from "./actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";

  return {
    currentStep: flightShopProgressSelector(state),
    tripSummariesLoading: isFlightShopLoadingTripSummaries(state),
    tripSummaries: tripSummariesByIdSelector(state),
    refreshPrediction: refreshPredictionSelector(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    tripCategory: getTripCategory(state),
    fetchSimilarFlightsCallState: fetchSimilarFlightsCallStateSelector(state),
    flightShopType: flightShopTypeSelector(state),
    sortOption: sortOptionSelector(state),
    airports: airportsSelector(state, tripId),
  };
};

const mapDispatchToProps = {
  fetchTransferToSimilarFlights: actions.fetchTransferToSimilarFlights,
  fetchTripSummariesForPrediction: actions.fetchTripSummariesForPrediction,
  fetchTripSummaries: actions.fetchTripSummaries,
  fetchSimilarFlights: actions.fetchSimilarFlights,
  setFlightShopProgress: actions.setFlightShopProgress,
  populateFlightBookQueryParams,
  setSortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShop = withRouter(connector(FlightShop));
