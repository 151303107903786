import dayjs from "dayjs";

export const getReviewCardHeaderWithType = ({
  isDeparture,
  location,
  date,
  airportCode,
  bold,
}: {
  isDeparture: boolean;
  location: string;
  date: string;
  airportCode?: string;
  bold?: boolean;
}) => {
  const locationWithAirportCode = `to ${location}${
    airportCode ? ` (${airportCode})` : ""
  }`;

  return {
    type: isDeparture ? "Outbound" : "Return",
    description: `${
      bold
        ? `<strong>${locationWithAirportCode}</strong>`
        : locationWithAirportCode
    } on ${dayjs(date).format("ddd, MMM D")}`,
  };
};

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";
  
export const USE_SLIDER_THRESHOLD = 2;
