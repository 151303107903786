import {
  FetchCfarOfferResponseV2,
  FetchCfarOfferSuccessV2,
  FetchCfarOfferResponseV2Enum,
} from "redmond";

export const REFUNDABLE_FARE_SUFFIX = "-refundable-fare";

export const getRefundableFareIdFromFareId = (fareId: string): string => {
  if (getIsRefundableFare(fareId)) {
    return getBaseFareIdFromRefundableFareId(fareId) + REFUNDABLE_FARE_SUFFIX;
  } else {
    return fareId + REFUNDABLE_FARE_SUFFIX;
  }
};

export const getIsRefundableFare = (fareId: string): boolean =>
  fareId.includes(REFUNDABLE_FARE_SUFFIX);

export const getBaseFareIdFromRefundableFareId = (fareId: string): string =>
  fareId.split(REFUNDABLE_FARE_SUFFIX)[0];

export const isCfarEligible = (
  tripId: string,
  fareId: string,
  cfarOffers: {
    [key: string]: {
      [key: string]: FetchCfarOfferResponseV2;
    };
  }
): boolean => {
  const response: FetchCfarOfferSuccessV2 | undefined = cfarOffers[tripId]?.[
    fareId
  ] as FetchCfarOfferSuccessV2;

  switch (response?.FetchCfarOfferResponseV2) {
    case FetchCfarOfferResponseV2Enum.MultiTicketV2:
    case FetchCfarOfferResponseV2Enum.SingleTicket:
      return !!response.cfarOffer;
    default:
      return false;
  }
};

export const getNumberOfRefundableFlights = (cfarOffers: {
  [key: string]: {
    [key: string]: FetchCfarOfferResponseV2;
  };
}): number => {
  let count = 0;

  Object.keys(cfarOffers).forEach((tripId) => {
    const offersByFareId = cfarOffers[tripId];

    if (
      Object.keys(offersByFareId).find((fareId) =>
        isCfarEligible(tripId, fareId, cfarOffers)
      )
    ) {
      count += 1;
    }
  });

  return count;
};

export const getNumberOfCfarOffers = (cfarOffers: {
  [tripId: string]: { [fareId: string]: FetchCfarOfferResponseV2 };
}): number => {
  let count = 0;

  Object.keys(cfarOffers).forEach((tripId) => {
    const offersByFareId = cfarOffers[tripId];

    Object.keys(offersByFareId).forEach((fareId) => {
      const cfarOffer = (
        cfarOffers[tripId]?.[fareId] as FetchCfarOfferSuccessV2
      )?.cfarOffer;

      if (cfarOffer !== undefined) {
        count += 1;
      }
    });
  });

  return count;
};
