import React from "react";
import { getTotalPriceText, getRewardText, twoDecimalFormatter } from "halifax";
import {
  Box,
  Typography,
  Popover,
  Link,
  InputAdornment,
} from "@material-ui/core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PriceDropProtectionEnum } from "redmond";
import clsx from "clsx";

import { PriceBreakdownDropdownConnectorProps } from "./container";
import {
  TRIP_TOTAL_TEXT,
  CHECKOUT_PRICE_BREAKDOWN_HEADER,
} from "./textConstants";
import "./styles.scss";
import { PriceDropProtection } from "../PriceDropProtection";
import { PriceBreakdown } from "../PriceBreakdownCard";
import { FrozenPriceSummary } from "../FrozenPriceSummary";

export interface IPriceBreakdownDropdownProps
  extends PriceBreakdownDropdownConnectorProps {
  handlePriceDropTracking?: () => void;
  popoverClassName?: string;
}

export const PriceBreakdownDropdown = ({
  flightTotal,
  isPriceFreezeExerciseEnabled,
  priceDropProtection,
  handlePriceDropTracking,
  popoverClassName,
}: IPriceBreakdownDropdownProps) => {
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const isPDPEligible =
    priceDropProtection &&
    priceDropProtection.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;

  const togglePopover = () => {
    setOpenPopover((open) => !open);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  if (!flightTotal) {
    return null;
  }

  // TODO: refactor GenericDropdown component in Halifax so that it can display ReactNode for label, and then refactor this
  // to use the reworked GenericDropdown component.
  return (
    <>
      <Link
        className="price-breakdown-dropdown-root"
        onClick={togglePopover}
        component={"button"}
      >
        {/* note: this is dropdownLabel */}
        <Box className="total-price-label-container">
          <Typography
            className="trip-total"
            variant="subtitle2"
          >{`${TRIP_TOTAL_TEXT}:`}</Typography>
          <Typography className={clsx("value", "fiat")} variant="subtitle1">
            {`${flightTotal.fiat.currencyCode} ${getTotalPriceText({
              price: flightTotal.fiat,
              priceFormatter: twoDecimalFormatter,
            })}`}
          </Typography>
          {flightTotal.rewards && (
            <>
              <Typography className="separator" variant="subtitle2">
                /
              </Typography>
              <Typography
                className={clsx("value", "rewards")}
                variant="subtitle2"
              >
                {getRewardText({
                  reward: flightTotal.rewards,
                })}
              </Typography>
            </>
          )}
        </Box>
        <InputAdornment
          className="total-price-adornment-icon-container"
          position="end"
        >
          <Box>
            <FontAwesomeIcon
              className={clsx("caret-icon", { open: openPopover })}
              icon={faCaretDown}
            />
          </Box>
        </InputAdornment>
      </Link>
      <Popover
        className={clsx("price-breakdown-popover-root", popoverClassName)}
        open={openPopover}
        PaperProps={{
          className: "price-breakdown-popover-paper",
        }}
        BackdropProps={{
          className: "price-breakdown-popover-backdrop",
          invisible: false,
        }}
        hideBackdrop={false}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableEnforceFocus
      >
        {/* note: this is dropdownContent */}
        {isPriceFreezeExerciseEnabled && (
          <Box className="frozen-price-summary-wrapper">
            <FrozenPriceSummary />
          </Box>
        )}
        <Box className="pricing-breakdown-header-wrapper">
          <Box className="pricing-breakdown-header-container">
            <Typography className="header" variant="h6">
              {CHECKOUT_PRICE_BREAKDOWN_HEADER}
            </Typography>
          </Box>
        </Box>
        {priceDropProtection && isPDPEligible && (
          <PriceDropProtection
            priceDropProtection={priceDropProtection}
            onClick={handlePriceDropTracking}
          />
        )}
        <PriceBreakdown isMobile={true} />
      </Popover>
    </>
  );
};
