import React from "react";
import { GenericShopListFooter, useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import "./styles.scss";
import { FlightFindMoreResultsConnectorProps } from "./container";
import { PATH_HOME, PATH_HOME_SEARCH } from "../../../../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../../../../search/reducer";
import * as constants from "./textConstants";

export interface IFlightFindMoreResultsProps
  extends FlightFindMoreResultsConnectorProps,
    RouteComponentProps {
  className?: string;
}

export const FlightFindMoreResults = (props: IFlightFindMoreResultsProps) => {
  const {
    className,
    resetFilters,
    setMobileSearchProgress,
    history,
    setOpenFlightShopCalendarDesktop,
    filters,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const [appliedFilters, setAppliedFilters] = React.useState<string[]>([]);

  React.useEffect(() => {
    const appliedFilters = Object.keys(filters).filter(
      (key) => props.filters[key]
    );
    setAppliedFilters(appliedFilters);
  }, [filters]);

  return (
    <>
      <GenericShopListFooter
        className={clsx("find-more-results", className, "b2b")}
        title={constants.NO_MORE_FLIGHTS_FOUND}
        subtitle={
          appliedFilters.length > 0
            ? constants.NO_FLIGHTS_FILTER
            : constants.NO_MORE_FLIGHTS_FOUND_SUBTITLE
        }
        buttons={
          appliedFilters.length > 0
            ? [
                {
                  title: constants.RESET_FILTERS,
                  onClick: () => resetFilters(),
                  className: "b2b",
                },
                {
                  title: constants.ADJUST_DATES,
                  isPrimary: true,
                  onClick: () => {
                    if (matchesMobile) {
                      setMobileSearchProgress(
                        MobileFlightSearchStep.CalendarPicker
                      );
                      history.push(
                        matchesMobile ? PATH_HOME_SEARCH : PATH_HOME
                      );
                    } else {
                      setOpenFlightShopCalendarDesktop(true);
                    }
                  },
                  className: "b2b",
                },
              ]
            : [
                {
                  title: constants.ADJUST_DATES,
                  isPrimary: true,
                  onClick: () => {
                    if (matchesMobile) {
                      setMobileSearchProgress(
                        MobileFlightSearchStep.CalendarPicker
                      );
                      history.push(
                        matchesMobile ? PATH_HOME_SEARCH : PATH_HOME
                      );
                    } else {
                      setOpenFlightShopCalendarDesktop(true);
                    }
                  },
                  className: "b2b",
                },
              ]
        }
        isMobile={matchesMobile}
      />
    </>
  );
};
