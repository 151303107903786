import axios, { AxiosResponse } from "axios";
import { config } from "../../../config";

import { paymentMethodsApiPrefix } from "../../paths";
import {
  VerifyPaymentMethodRequest,
  PaymentVerifyResult,
  Payment,
} from "redmond";

const END_POINT: string = `${paymentMethodsApiPrefix}/verify`;

export const verifyPaymentMethod = (
  req: VerifyPaymentMethodRequest
): Promise<PaymentVerifyResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<PaymentVerifyResult> = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody: PaymentVerifyResult = (res.data as any).value;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IListPaymentMethodsResponse extends Array<Payment> {}
