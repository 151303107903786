import { put, select, putResolve, call } from "redux-saga/effects";

import { actions as searchActions } from "../../search/actions";
import { fetchTripSummaries as fetchFlights } from "../../../api/v2/shop/fetchTripSummaries";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import {
  getAirEntryProperties,
  getPriceFreezeOfferCheapestTripTripId,
  getPriceFreezeOfferCheapestTripFareId,
} from "../reducer/selectors";
import { IShopParams } from "../actions/actions";
import {
  AirEntryProperties,
  AIR_ENTRY,
  ILocationQueryLabel,
  IResponse,
  ITripTerminus,
  LocationQueryEnum,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import { ITripTerminusCategory } from "../../search/types";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import { getExistingStateVariables } from "./populateShopQueryParamsSaga";
import { initializeOfferDataAndCustomOffer } from "../../freeze/sagas/initializeOfferDataAndCustomOfferSaga";
import { IStoreState } from "../../../reducers/types";
import dayjs from "dayjs";
import { PATH_HOME } from "../../../utils/urlPaths";
import {
  getFareclassOptionFilter,
  MobileFlightSearchStep,
} from "../../search/reducer";
import { setMobileSearchProgress } from "../../search/actions/actions";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../utils/parseQueryString";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";

const shouldRedirect = ({
  origin,
  destination,
  departureDate,
  tripCategory,
  returnDate,
  stopsOption,
}: any) =>
  !origin ||
  !destination ||
  !departureDate ||
  !stopsOption ||
  (tripCategory === TripCategory.ROUND_TRIP && !returnDate);

export function* fetchTripSummariesV2(
  action: actions.IFetchTripSummariesV2
): Generator<any, any, any> {
  try {
    const {
      departureDate,
      returnDate,
      origin,
      destination,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      noLCC,
    } = yield call(setUpFlightShopParams, action);

    const requestBody: IShopParams = {
      origin: { ...origin.id.code },
      destination: { ...destination.id.code },
      ...(returnDate && {
        returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
      }),
      departureDate: dayjs(departureDate).format("YYYY-MM-DD"),
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter: {
        basic: false,
        standard: noLCC,
        enhanced: noLCC,
        premium: noLCC,
        luxury: noLCC,
      },
    };
    const response = yield fetchFlights(requestBody, action.isMobile);

    const properties: AirEntryProperties | null = yield select(
      getAirEntryProperties
    );
    yield trackEvent({
      eventName: AIR_ENTRY,
      properties,
    });

    // note: the order does matter here! having redux set priceFreezeOffer first will allow it to update priceFreezeOffer
    // related selectors prior to that of the prediction
    yield putResolve(
      actions.setBestOverallOffer(response.offers.bestOfferOverall)
    );
    yield putResolve(actions.setFlights(response.flights));
    yield putResolve(actions.setPriceFreezeOffer(response.priceFreezeOffer));

    const cheapestTripTripId: string = yield select(
      getPriceFreezeOfferCheapestTripTripId
    );
    const cheapestTripFareId: string = yield select(
      getPriceFreezeOfferCheapestTripFareId
    );
    yield initializeOfferDataAndCustomOffer({
      departureDate,
      tripId: cheapestTripTripId,
      fareId: cheapestTripFareId,
      history: action.history,
      isFromFetchTripSummariesV3: false,
    });

    yield putResolve(actions.setPrediction(response.prediction));
    yield putResolve(actions.setOffersByTripId(response.offers.offersByTripId));

    if (cheapestTripTripId) {
      yield put(actions.fetchTripDetails({ tripId: cheapestTripTripId }));
    }

    yield put(searchActions.setAwaitingRefetch(false));
  } catch (e) {
    yield put(actions.setTripSummariesError());
    yield put(actions.setPredictionError());
    Logger.debug(e);
  }
}

function* setUpFlightShopParams(
  fetchTripSummaries: actions.IFetchTripSummariesV2
) {
  const state: IStoreState = yield select();
  const history = fetchTripSummaries.history;

  let {
    departureDate,
    returnDate,
    origin,
    destination,
    tripCategory,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  } = getExistingStateVariables(state);
  const fareclassOptionFilter = getFareclassOptionFilter(state);
  const parsedQueryString = parseQueryString(history) as IFlightShopParsedQuery;
  let destinationToSearch: ITripTerminus | null = destination;
  let originToSearch: ITripTerminus | null = origin;
  if (
    !origin ||
    !destination ||
    // note: when it's from FlightWatch, it should treat parsedQueryString as the source of truth.
    parsedQueryString.isFromFlightWatch ||
    (origin &&
      parsedQueryString.origin &&
      origin.id.code.code !== parsedQueryString.origin) ||
    (destination &&
      parsedQueryString.destination &&
      destination.id.code.code !== parsedQueryString.destination)
  ) {
    const { correspondingDestination, correspondingOrigin } =
      yield fetchOriginDestination(parsedQueryString);
    destinationToSearch = correspondingDestination;
    originToSearch = correspondingOrigin;
  }

  origin = originToSearch ?? origin;
  destination = destinationToSearch ?? destination;
  departureDate = parsedQueryString.departureDate ?? departureDate;
  returnDate = parsedQueryString.returnDate ?? returnDate;
  tripCategory = parsedQueryString.tripCategory ?? tripCategory;
  stopsOption =
    (parsedQueryString.stopsOption as SliceStopCountFilter) ?? stopsOption;
  noLCC = parsedQueryString.noLCC ?? noLCC;

  // If we are missing the data we need from both the state and the query params
  // we should redirect the user home.
  if (
    shouldRedirect({
      departureDate,
      origin,
      destination,
      returnDate,
      tripCategory,
      stopsOption,
    })
  ) {
    yield putResolve(
      setMobileSearchProgress(MobileFlightSearchStep.LocationSearch)
    );
    fetchTripSummaries.history.push(PATH_HOME);
    return;
  }
  // Order here matters because of the reducer structure.
  // Set trip category resets the returnDate field.
  yield putResolve(searchActions.setTripCategory(tripCategory));
  yield putResolve(searchActions.setOrigin(origin));
  yield putResolve(searchActions.setDestination(destination));
  yield putResolve(searchActions.setDepartureDate(departureDate));
  yield putResolve(searchActions.setReturnDate(returnDate));
  yield putResolve(searchActions.setStopsOption(stopsOption));
  yield putResolve(
    searchActions.setFareclassOptionFilter({
      basic: fareclassOptionFilter.basic || false,
      standard: fareclassOptionFilter.standard || noLCC,
      enhanced: fareclassOptionFilter.enhanced || noLCC,
      premium: fareclassOptionFilter.premium || noLCC,
      luxury: fareclassOptionFilter.luxury || noLCC,
    })
  );

  return {
    departureDate,
    returnDate,
    origin,
    destination,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    stopsOption,
    noLCC,
  };
}
function* fetchOriginDestination(parsedQueryString: IFlightShopParsedQuery) {
  const originRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.origin,
  };

  const { categories: originCategories }: IResponse =
    yield fetchLocationAutocomplete(originRequestBody);
  const correspondingOrigin = originCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find(
      (result) => result.id.code.code === parsedQueryString.origin
    ) as ITripTerminus;

  const destinationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: parsedQueryString.destination,
  };

  const { categories: destinationCategories }: IResponse =
    yield fetchLocationAutocomplete(destinationRequestBody);
  const correspondingDestination = destinationCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find(
      (result) => result.id.code.code === parsedQueryString.destination
    ) as ITripTerminus;

  return { correspondingDestination, correspondingOrigin };
}
