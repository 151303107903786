import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";
import { FlightAncillaryConnectorProps } from "./container";
import { RebookConfirmation } from "./components";
import { 
  PATH_DISRUPTION_PROTECTION_REBOOK_CONFIRMATION, 
  PATH_DISRUPTION_PROTECTION_VIRTUAL_INTERLINE_REBOOK_CONFIRMATION 
} from "../../utils/urlPaths";
import { PORTAL_TITLE } from "../../lang/textConstants";
import "./styles.scss";

export interface IFlightAncillaryProps
  extends RouteComponentProps,
    FlightAncillaryConnectorProps {}

export const FlightAncillary = (props: IFlightAncillaryProps) => {
  const { history } = props;
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  return (
    <Box
      className={clsx("flight-ancillary-root", {
        confirm:
          history.location.pathname ===
          PATH_DISRUPTION_PROTECTION_REBOOK_CONFIRMATION ||
          history.location.pathname === 
          PATH_DISRUPTION_PROTECTION_VIRTUAL_INTERLINE_REBOOK_CONFIRMATION,
        mobile: matchesMobile,
      })}
    >
      <Route path={[PATH_DISRUPTION_PROTECTION_REBOOK_CONFIRMATION, PATH_DISRUPTION_PROTECTION_VIRTUAL_INTERLINE_REBOOK_CONFIRMATION]}>
        <RebookConfirmation />
      </Route>
    </Box>
  );
};
