import React from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  TripCategory,
  PriceDropProtectionEnum,
  VIEWED_PRICE_DROP_DETAILS,
  Currency,
} from "redmond";
import {
  ActionButton,
  tripTypeText,
  PricingBreakdown,
  FlightPricingLineItem,
  ISummaryLineItem,
  PricingBreakdownAncillariesProps,
  ITotalLineItem,
} from "halifax";

import clsx from "clsx";
import { PriceDropProtection as PriceDropProtectionDetails } from "../../../../book/components/PriceDropProtection";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import * as constants from "./constants";
import { AddOnPricingBreakdownConnectorProps } from "./container";
import "./styles.scss";
import { CORP_FINTECH_SUBSCRIPTION_KEY } from "../../../reducer";
import { getCorpFintechSubscriptionPricesNums } from "../AddOnCustomize/constants";

export interface IAddOnPricingBreakdownProps
  extends AddOnPricingBreakdownConnectorProps,
    RouteComponentProps {
  isPriceFreezeExercise?: boolean;
  actionButtonProps?: {
    message: string | JSX.Element;
    disabled?: boolean;
    onContinue: () => void;
    onClickWhenDisabled?: () => void;
  };
  isMobile?: boolean;
  hasError?: boolean;
}

export const AddOnPricingBreakdown = (props: IAddOnPricingBreakdownProps) => {
  const {
    cfarOfferPrices,
    chfarOfferPrices,
    discountedCfarOffer,
    disruptionProtectionOfferPrices,
    actionButtonProps,
    isMobile,
    isAirOfferRedesignEnabled,
    hasError,
    cfarOfferCoverage,
    isCfarCoMerchEnabled,
    selectedDisruptionProtectionId,
    priceDropRefundType,
    isVITripSelected,
  } = props;

  const { selectedFareAmount, totalPrices, tripCategory, priceDropProtection } =
    getFareDetails(props);

  const flightType = constants.getPerTravelerCopy(tripTypeText[tripCategory]);

  const isCorpFintechSubscription =
    selectedDisruptionProtectionId?.productId ===
      CORP_FINTECH_SUBSCRIPTION_KEY && disruptionProtectionOfferPrices != null;

  const currencySymbol = totalPrices?.fiat.currencySymbol ?? "$";
  const currencyCode = totalPrices?.fiat.currencyCode ?? Currency.USD;

  return (
    <Box
      className={clsx("add-on-pricing-breakdown-root", { mobile: isMobile })}
    >
      <Box className="add-on-pricing-breakdown-container">
        <Box className="checkout-breakdown-header-section">
          <Typography className="header-copy" variant="h2">
            {constants.CHECKOUT_BREAKDOWN_HEADER}
          </Typography>
        </Box>
        {priceDropProtection &&
          priceDropProtection?.PriceDropProtection ===
            PriceDropProtectionEnum.IsEligible && (
            <Box className="pdp-section">
              <PriceDropProtectionDetails
                hideSubtitle
                priceDropProtection={priceDropProtection}
                onClick={() =>
                  trackEvent({
                    eventName: VIEWED_PRICE_DROP_DETAILS,
                    properties: {
                      page: "customize",
                      refund_type: priceDropRefundType,
                    },
                  })
                }
              />
            </Box>
          )}
        <Box className="pricing-breakdown-section">
          <PricingBreakdown
            pricingItems={buildPricingItems()}
            summaryItems={buildSummaryItems()}
            currencySymbol={currencySymbol}
            currencyCode={currencyCode}
            isVITripSelected={isVITripSelected}
            ancillaries={buildAncillariesProps()}
          />
        </Box>
        <Box className="total-price-section">
          <Typography
            variant="body2"
            className="flight-type-copy"
            dangerouslySetInnerHTML={{
              __html: flightType,
            }}
          />
        </Box>
        {!!actionButtonProps && (
          <Box className="continue-cta-section">
            <ActionButton
              className="continue-cta"
              defaultStyle="h4r-primary"
              onClick={actionButtonProps.onContinue}
              onClickWhenDisabled={actionButtonProps.onClickWhenDisabled}
              message={actionButtonProps.message}
              disabled={actionButtonProps.disabled}
            />
          </Box>
        )}
        {isAirOfferRedesignEnabled && actionButtonProps?.disabled && (
          <div
            className={clsx("air-offer-redesign-validation-text", {
              hasError: hasError,
            })}
          >
            {constants.VALIDATION_TEXT}
          </div>
        )}
      </Box>
    </Box>
  );

  function buildPricingItems(): FlightPricingLineItem[] {
    return [
      {
        baseAmount: selectedFareAmount?.baseAmount?.fiat.value,
        taxesAndFees: selectedFareAmount?.taxAmount?.fiat.value,
      },
    ];
  }

  function buildAncillariesProps(): PricingBreakdownAncillariesProps {
    const props: PricingBreakdownAncillariesProps = {
      cfarFee: cfarOfferPrices?.fiat.value,
      cfarText: isCfarCoMerchEnabled
        ? `${constants.CFAR_COPY}, ${cfarOfferCoverage?.cashCoveragePercentage}% coverage`
        : undefined,
      origCfarFee: discountedCfarOffer?.originalPremiumAmount.fiat.value,
      chfarFee: chfarOfferPrices?.fiat.value,
      disruptionProtectionFee: disruptionProtectionOfferPrices?.fiat.value,
    };

    if (isCorpFintechSubscription) {
      const { origPrice, discountedPrice } =
        getCorpFintechSubscriptionPricesNums(
          disruptionProtectionOfferPrices?.fiat
        );

      props.origDisruptionProtectionFee = origPrice.value;
      props.disruptionProtectionFee = discountedPrice.value;
    }

    return props;
  }

  function buildSummaryItems(): ISummaryLineItem[] {
    return [
      {
        type: "total",
        fiatPrice: totalPrices?.fiat,
        rewardsPrice: totalPrices?.rewards,
      } as ITotalLineItem,
    ];
  }
};

function getFareDetails(props: IAddOnPricingBreakdownProps) {
  if (props.isPriceFreezeExercise) {
    return {
      selectedFareAmount: props.priceFreezeChargeAmount
        ? { baseAmount: props.priceFreezeChargeAmount }
        : undefined,
      tripCategory: props.tripCategoryFromPriceFreeze ?? TripCategory.ONE_WAY,
      totalPrices: props.priceFreezeTotalPrices,
      priceDropProtection: props.priceDropProtectionFromPriceFreeze,
    };
  }

  return {
    selectedFareAmount: props.selectedFare
      ? {
          baseAmount: props.selectedFare?.paxPricings?.[0]?.pricing?.baseAmount,
          taxAmount: props.selectedFare?.paxPricings?.[0]?.pricing?.taxAmount,
        }
      : undefined,
    tripCategory: props.tripCategory,
    totalPrices: props.totalPrices,
    priceDropProtection: props.prediction?.priceDropProtection,
  };
}
