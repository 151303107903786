import React, { useState, useEffect, useMemo } from "react";
import {
  useDeviceTypes,
  ExpandableCard,
  NoResults,
  B2BSpinner,
  LoadingIndicator,
  FareDetailsCardCtaType,
  FareDetailsCardCtaTitles,
  HotelCrossSellAwarenessCard,
} from "halifax";
import { Box, Button } from "@material-ui/core";
import * as H from "history";
import clsx from "clsx";

import "./styles.scss";
import { FlightListInfo } from "./components/FlightListInfo";
import { FlightCardType } from "./components/FlightListInfo/component";
import * as constants from "./constants";
import { RESET_FILTERS } from "../../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { FareclassOptionFilter } from "../../../../search/reducer";
import {
  TripDetails,
  FlightShopType,
  TravelWalletOffer,
  TravelWalletCredit,
  RewardsAccount,
  FlightSortOption,
  SliceStopCountFilter,
  PotentialCrossSellOffer,
  PolicyViolation,
  VIEWED_POLICY_DESCRIPTOR,
  POLICY_DESCRIPTOR,
  FiatPrice,
} from "redmond";
import { FlightDetails } from "./components/FlightDetails";
import {
  ISelectedMulticityTrip,
  MulticityFlightShopStep,
} from "../../../reducer";
import { FlightFindMoreResults } from "../../../components/FlightList/components/FlightFindMoreResults";

import {
  AIR_CX_V3_1_VARIANT_1,
  AIR_CX_V4,
  AirCXV3VariantType,
  AVAILABLE,
  CONTROL,
  FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  INTERNATIONAL_NGS_EXPERIMENT,
  SEATS_UX_OPTIMIZATION,
  useExperiments,
} from "../../../../../context/experiments";
import {
  IFlightBookOverwriteQueryParams,
  IPopulateFlightBookQueryParams,
} from "../../../../book/actions/actions";
import { PATH_HOME } from "../../../../../utils/urlPaths";
import ReactList from "react-list";
import { MulticityFlights, MulticitySlice } from "@b2bportal/air-shopping-api";
import { EDIT_SEARCH } from "../../../../search/components/FlightShopSearchControlV2/textConstants";
import { NonstopFlightListSeparator } from "../../../v2/components/FlightList/components/NonstopFlightListSeparator";
import { getMulticityFaresToShow } from "../../../utils/getFaresToShow";
import {
  ISetSelectedMarketingAirlineCodes,
  ISetSelectedOperatingAirlineCodes,
} from "../../../actions/actions";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";

const DESKTOP_OFFSET_SCROLL = 250;
const RESTRICTED_COUNTRY_ERROR_CODE = "RestrictedCountry";
const RESTRICTED_CITY_ERROR_CODE = "RestrictedCity";

export interface IMulticityFlightListProps {
  multicityFlights: MulticityFlights | null;
  flightsToRender: IFlightListData[] | [];
  invertedStopsFilterFlightList: IFlightListData[] | [];
  multicityFlightShopProgress: MulticityFlightShopStep;
  tripSummariesLoading: boolean | null;
  rewardsKey: string | undefined;
  handleFareSelect: (
    val1: any,
    val2: string,
    idx: number,
    limit?: FiatPrice | null
  ) => void;
  fareClassFilter: FareclassOptionFilter;
  handleFlightSelect: (val: any, val2: string) => void;
  expandedFareDetails: TripDetails | null;
  hasFlightsError: boolean;
  flightsErrorCode?: string | null;
  selectedTrip: ISelectedMulticityTrip;
  maxFlightPriceFilter: number;
  hasAppliedFareClassFilter: boolean;
  hasAppliedNonFareclassFilter: boolean;
  resetAllFilters: () => void;
  openMobileFlightDetailsModal: boolean;
  setOpenMobileFlightDetailsModal: (val: boolean) => void;
  history: H.History;

  populateFlightBookQueryParams: (args: {
    history: H.History;
    pathname?: string;
    preserveQuery?: boolean;
    newQueryParams?: IFlightBookOverwriteQueryParams;
  }) => IPopulateFlightBookQueryParams;
  flightShopType: FlightShopType;
  offersByTripId?: { [key: string]: TravelWalletOffer };
  credit?: TravelWalletCredit;
  largestValueAccount?: RewardsAccount;
  sortOption: FlightSortOption;
  hasSetMaxPriceFilter: boolean;
  setIsEditMulticitySearchModalOpen: (arg: boolean) => void;
  stopsOption?: SliceStopCountFilter;
  airCXV3Variant?: AirCXV3VariantType;
  setSelectedMarketingAirlineCodes: (
    marketingAirlineCodes: string[]
  ) => ISetSelectedMarketingAirlineCodes;
  setSelectedOperatingAirlineCodes: (
    operatingAirlineCodes: string[]
  ) => ISetSelectedOperatingAirlineCodes;
  isSpiritOrFrontierAirlinesSelected: boolean;
  potentialCrossSellOffers: PotentialCrossSellOffer[];
  isInPolicyFilter?: boolean;
}

export interface IFlightListData {
  slice: string;
  fares: any; //Todo: add type
}

export const FlightList = (props: IMulticityFlightListProps) => {
  const {
    flightsToRender,
    tripSummariesLoading,
    multicityFlights,
    rewardsKey,
    handleFareSelect,
    fareClassFilter,
    handleFlightSelect,
    multicityFlightShopProgress,
    expandedFareDetails,
    hasFlightsError,
    flightsErrorCode,
    selectedTrip,
    maxFlightPriceFilter,
    hasAppliedFareClassFilter,
    hasAppliedNonFareclassFilter,
    resetAllFilters,
    openMobileFlightDetailsModal,
    setOpenMobileFlightDetailsModal,
    history,
    flightShopType,
    offersByTripId,
    credit,
    largestValueAccount,
    sortOption,
    hasSetMaxPriceFilter,
    setIsEditMulticitySearchModalOpen,
    stopsOption,
    airCXV3Variant,
    invertedStopsFilterFlightList,
    setSelectedMarketingAirlineCodes,
    setSelectedOperatingAirlineCodes,
    isSpiritOrFrontierAirlinesSelected,
    potentialCrossSellOffers,
    isInPolicyFilter,
  } = props;
  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();
  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;
  const [flightsToShow, setFlightsToShow] = useState<IFlightListData[]>([]);
  const [clickedFareId, setClickedFareId] = useState("");
  const [policyDescriptorViewCount, setPolicyDescriptorViewCount] = useState(0);
  const expState = useExperiments();

  const isFlightListOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  const isAirCXV4Experiment = React.useMemo(
    () => getExperimentVariant(expState.experiments, AIR_CX_V4) === AVAILABLE,
    [expState]
  );

  const listRef = React.useRef<ReactList | null>(null);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  const isInDisruptionProtectionRebook =
    flightShopType === FlightShopType.DISRUPTION_PROTECTION_EXERCISE;

  const ngsEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        INTERNATIONAL_NGS_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const setFetchMoreData = () => {
    const newPageSize = flightsToShow.length + constants.SHOW_MORE_NUM;
    return setTimeout(
      () => setFlightsToShow(flightsToRender.slice(0, newPageSize)),
      500
    );
  };

  const [expandedFlight, setExpandedFlight] = useState("");

  useEffect(() => {
    if (flightsToRender.length > 0) {
      if (!matchesMobile) {
        setFlightsToShow(
          flightsToRender.slice(0, constants.INITIAL_RESULT_SET_SIZE)
        );
      } else {
        setFlightsToShow(flightsToRender);
      }
      setExpandedFlight("");
    } else {
      setFlightsToShow([]);
    }
    if (!matchesMobile) {
      return clearTimeout(setFetchMoreData());
    }
  }, [flightsToRender]);

  useEffect(() => {
    if (tripSummariesLoading) setFlightsToShow([]);
  }, [tripSummariesLoading]);

  const onOpenPolicyDescriptor = (
    entryPoint: string,
    reasons: PolicyViolation[]
  ) => {
    if (policyDescriptorViewCount <= 5) {
      trackEvent({
        eventName: VIEWED_POLICY_DESCRIPTOR,
        properties: {
          type: POLICY_DESCRIPTOR,
          entry_point: entryPoint,
          funnel: "flights",
          policy_reason: reasons.join(", "),
        },
      });
      setPolicyDescriptorViewCount((prevState) => prevState + 1);
    }
  };

  const renderSkeletonFlights = () => {
    return (
      <ExpandableCard
        key={""}
        className={clsx("flight-list-item", "flight-row", "b2b")}
        isMobile={matchesMobile}
        expandedCardKey={"expandedFlight"}
        cardKey={""}
        handleCardKeyChange={() => {}}
        scrollExpandedIntoView={true}
        content={{
          title: (
            <FlightListInfo
              tripId={""}
              onFareClick={() => {}}
              type={FlightCardType.skeleton}
            />
          ),
          body: <></>,
        }}
      />
    );
  };

  const getFlightsErrorInfo = () => {
    let flightsErrorTitle = constants.ERROR_TITLE;
    let flightsErrorSubtitle = constants.ERROR_SUBTITLE;
    let flightErrorButton = constants.RELOAD;
    let flightErrorOnClickFunction = () => window.location.reload();

    if (flightsErrorCode === RESTRICTED_COUNTRY_ERROR_CODE) {
      flightsErrorTitle = constants.RESTRICTED_COUNTRY_TITLE;
      flightsErrorSubtitle = constants.RESTRICTED_COUNTRY_SUBTITLE;
      flightErrorButton = constants.SEARCH_AGAIN;
      flightErrorOnClickFunction = () => history.push(PATH_HOME);
    } else if (flightsErrorCode === RESTRICTED_CITY_ERROR_CODE) {
      flightsErrorTitle = constants.RESTRICTED_CITY_TITLE;
      flightsErrorSubtitle = constants.RESTRICTED_CITY_SUBTITLE;
      flightErrorButton = constants.SEARCH_AGAIN;
      flightErrorOnClickFunction = () => history.push(PATH_HOME);
    }

    return {
      flightsErrorTitle,
      flightsErrorSubtitle,
      flightErrorButton,
      flightErrorOnClickFunction,
    };
  };

  const renderNoFlightsMessaging = () => {
    let noFlightsString = constants.GENERIC_FLIGHTS_NOT_FOUND_SUBTITLE;

    if (hasAppliedFareClassFilter) {
      noFlightsString = constants.FARE_CLASS_FLIGHTS_NOT_FOUND_SUBTITLE;
    } else if (hasAppliedNonFareclassFilter) {
      noFlightsString = constants.FITLERED_FLIGHTS_NOT_FOUND_SUBTITLE;
    }

    const {
      flightsErrorTitle,
      flightsErrorSubtitle,
      flightErrorButton,
      flightErrorOnClickFunction,
    } = getFlightsErrorInfo();

    return hasFlightsError ? (
      <Box className="no-results-container">
        <NoResults
          className="flight-list-no-results"
          title={flightsErrorTitle}
          subtitle={flightsErrorSubtitle}
        />
        <button
          onClick={flightErrorOnClickFunction}
          className={"reload-button"}
        >
          {flightErrorButton}
        </button>
      </Box>
    ) : (
      <Box className="no-results-container">
        <NoResults
          className="flight-list-no-results"
          title={constants.FLIGHTS_NOT_FOUND_TITLE}
          subtitle={noFlightsString}
        />
        {hasAppliedNonFareclassFilter && matchesMobile ? (
          !hasAppliedFareClassFilter ? (
            <Box className="no-results-buttons-container">
              <Button
                onClick={resetAllFilters}
                className={clsx("reset-filters-dates-button", "b2b")}
              >
                {RESET_FILTERS}
              </Button>
              <Button
                onClick={() => setIsEditMulticitySearchModalOpen(true)}
                className={clsx("filters-change-dates-button", "b2b")}
              >
                {EDIT_SEARCH}
              </Button>
            </Box>
          ) : undefined
        ) : (
          <Button
            onClick={() => setIsEditMulticitySearchModalOpen(true)}
            className={clsx("change-dates-button", "b2b")}
          >
            {EDIT_SEARCH}
          </Button>
        )}
      </Box>
    );
  };

  const handleSliceSelect = (
    fareId: string,
    selectedFareClass: string,
    flight: IFlightListData
  ) => {
    if (fareId === expandedFlight) {
      setExpandedFlight("");
    } else {
      setExpandedFlight(fareId);
      handleFlightSelect(
        flight.fares.map((fare: any) => fare.example),
        selectedFareClass
      );
    }
  };

  const ctaTitles: FareDetailsCardCtaTitles = {
    primary: {
      getContent: constants.getSelectCtaCopy,
      type: FareDetailsCardCtaType.Function,
    },
  };

  const renderFlightListInfo = (
    selectedFare: any,
    showListView: boolean,
    slice: MulticitySlice,
    flight: IFlightListData,
    index: number
  ) => {
    const fareId = selectedFare.example?.fare || selectedFare.id;
    return (
      <Box
        id={fareId}
        className={clsx(
          "flight-list-item",
          "flight-row",
          {
            "row-view-desktop":
              matchesMediumDesktopOnly ||
              (matchesLargeDesktop && showListView) ||
              (matchesDesktop && isInDisruptionProtectionRebook),
            expanded: fareId === expandedFlight,
          },
          "b2b"
        )}
        key={fareId}
      >
        <FlightListInfo
          {...{
            selectedFare,
            slice,
            multicityFlights,
            flight,
            rewardsKey,
            fareClassFilter,
            maxFlightPriceFilter,
            offersByTripId,
            credit,
            largestValueAccount,
            isInPolicyFilter,
          }}
          type={FlightCardType.content}
          isExpanded={fareId === expandedFlight}
          onClick={(selectedFareClass: string) => {
            handleSliceSelect(fareId, selectedFareClass, flight);
            setTimeout(() => {
              if (matchesMobile) {
                listRef.current?.scrollTo(index);
              } else {
                const OFFSET = DESKTOP_OFFSET_SCROLL;
                const cardTop =
                  document?.getElementById(fareId)?.getBoundingClientRect()
                    .top || 0;
                window.scrollBy({
                  top: (cardTop as number) - OFFSET,
                  behavior: "smooth",
                });
              }
            }, 100);
          }}
          onAlgomerchClick={() => {}}
          onFareClick={(fareId: string) => {
            const marketingAirlineCodes = slice.segments.map(
              // confirmed this is the airline code, not the full name
              (segment) => segment.marketingAirline
            );
            const operatingAirlineCodes = slice.segments.map(
              (segment) => segment.operatingAirline
            );
            setSelectedMarketingAirlineCodes(marketingAirlineCodes);
            setSelectedOperatingAirlineCodes(operatingAirlineCodes);
            setClickedFareId(fareId);
          }}
          showPriceAndTags={!isInDisruptionProtectionRebook}
          useRowFlightListInfoOnly={isInDisruptionProtectionRebook}
          onOpenPolicyDescriptor={onOpenPolicyDescriptor}
        />
        {fareId === expandedFlight && expandedFareDetails && (
          <FlightDetails
            multicityFlightShopProgress={multicityFlightShopProgress}
            selectedFareId={clickedFareId}
            onFareClick={(_sliceId, fare, limit) => {
              handleFareSelect(flight, fare!.id, index, limit);
            }}
            onAlgomerchClick={() => {}}
            showFareDetailsTitle
            isPriceHidden={isInDisruptionProtectionRebook}
            isFlightListOptimizationExperiment={
              isFlightListOptimizationExperiment
            }
            {...{
              tripDetails: expandedFareDetails,
              rewardsKey,
              airports: multicityFlights!.airports,
              outgoingFareRating: selectedTrip?.departureFareRating,
              openMobileFlightDetailsModal,
              setOpenMobileFlightDetailsModal,
            }}
            ctaTitles={ctaTitles}
            isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
            isSpiritOrFrontierAirlinesSelected={
              isSpiritOrFrontierAirlinesSelected
            }
            isAirCXV4Experiment={isAirCXV4Experiment}
          />
        )}
        {fareId === expandedFlight && !expandedFareDetails && (
          <LoadingIndicator
            className="flight-shop-details-loading-indicator"
            indicatorSize={"small"}
            indicator={B2BSpinner}
            message={constants.LOADING_FLIGHT_DETAILS_STRING}
          />
        )}
      </Box>
    );
  };

  const renderFlights = () => {
    const faresToShow = getMulticityFaresToShow(
      flightsToShow,
      multicityFlights,
      hasAppliedFareClassFilter,
      fareClassFilter,
      matchesMobile,
      hasSetMaxPriceFilter,
      maxFlightPriceFilter,
      Boolean(isInPolicyFilter)
    );

    const invertedStopsFilterFaresToShow =
      airCXV3Variant === AIR_CX_V3_1_VARIANT_1
        ? getMulticityFaresToShow(
            invertedStopsFilterFlightList,
            multicityFlights,
            hasAppliedFareClassFilter,
            fareClassFilter,
            matchesMobile,
            hasSetMaxPriceFilter,
            maxFlightPriceFilter,
            Boolean(isInPolicyFilter)
          ).sort(
            (a, b) =>
              (multicityFlights?.slices[a.flight.slice].totalDurationMinutes ||
                0) -
              (multicityFlights?.slices[b.flight.slice].totalDurationMinutes ||
                0)
          )
        : [];

    if (matchesMobile) {
      // handle sort order
      if (sortOption === FlightSortOption.FareScore) {
        faresToShow.sort(
          (a, b) => (b?.fareScore || Infinity) - (a?.fareScore || Infinity)
        );
      } else if (sortOption === FlightSortOption.Price) {
        faresToShow.sort(
          (a, b) =>
            (a.fare?.amount?.fiat?.value || Infinity) -
            (b.fare?.amount?.fiat?.value || Infinity)
        );
      }
    }

    const allFaresToShow =
      airCXV3Variant === AIR_CX_V3_1_VARIANT_1 &&
      !!invertedStopsFilterFaresToShow.length &&
      stopsOption === SliceStopCountFilter.NONE
        ? (
            faresToShow as Array<
              | {
                  fare: any;
                  flight: IFlightListData;
                  fareScore: number;
                }
              | undefined
            >
          )
            .concat(undefined) // insert blank "fare" for list separator
            .concat(invertedStopsFilterFaresToShow)
        : faresToShow;

    return matchesMobile ? (
      <div ref={divRef} className="availability-list">
        <ReactList
          ref={listRef}
          itemRenderer={(index: number) => {
            const currentFare = allFaresToShow[index];
            if (!currentFare) {
              return (
                <NonstopFlightListSeparator
                  isMobile
                  key="nonstop-flight-separator"
                />
              );
            }
            const { fare, flight } = currentFare;
            const flightSliceId = flight.slice;
            const flightSlice = multicityFlights?.slices[flightSliceId];
            const isListView = !flightSlice?.domestic && !ngsEnabled;
            if (!flightSlice) {
              // itemRenderer does not allow for returning of undefined/null
              return (
                <Box
                  display="none"
                  key={`${fare.example?.fare || fare.id}-none`}
                />
              );
            }

            return renderFlightListInfo(
              fare,
              isListView,
              flightSlice,
              flight,
              index
            );
          }}
          length={allFaresToShow.length}
          type="variable"
        />
      </div>
    ) : (
      <InfiniteScroll
        dataLength={faresToShow.length}
        next={setFetchMoreData}
        hasMore={faresToShow.length < flightsToRender.length}
        loader={
          <Box className="loading-flights">
            <B2BSpinner classes={["loading-flights-bunny"]} />
          </Box>
        }
      >
        {faresToShow.map(({ fare, flight }, index) => {
          const flightSliceId = flight.slice;
          const flightSlice = multicityFlights?.slices[flightSliceId];
          if (!flightSlice) {
            return null;
          }
          const isListView = !flightSlice?.domestic && !ngsEnabled;
          return renderFlightListInfo(
            fare,
            isListView,
            flightSlice,
            flight,
            index
          );
        })}
        {airCXV3Variant === AIR_CX_V3_1_VARIANT_1 &&
          stopsOption === SliceStopCountFilter.NONE &&
          !!invertedStopsFilterFaresToShow.length && (
            <>
              <NonstopFlightListSeparator />
              {invertedStopsFilterFaresToShow.map(({ fare, flight }, index) => {
                const flightSliceId = flight.slice;
                const flightSlice = multicityFlights?.slices[flightSliceId];
                if (!flightSlice) {
                  return null;
                }
                const isListView = !flightSlice?.domestic && !ngsEnabled;
                return renderFlightListInfo(
                  fare,
                  isListView,
                  flightSlice,
                  flight,
                  index
                );
              })}
            </>
          )}
      </InfiniteScroll>
    );
  };

  return (
    <Box
      className={clsx(
        "multicity",
        "flight-list",
        { "flight-list-skeleton": tripSummariesLoading },
        { mobile: matchesMobile },
        {
          "flight-list-optimization-experiment":
            isFlightListOptimizationExperiment,
        }
      )}
    >
      {isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0 ? (
        <HotelCrossSellAwarenessCard
          isMobile={matchesMobile}
          offerText={potentialCrossSellOffers[0].mainDescription}
          offerPillText={potentialCrossSellOffers[0].subDescription}
        />
      ) : undefined}
      {tripSummariesLoading && renderSkeletonFlights()}
      {!!flightsToShow.length && !tripSummariesLoading && !!multicityFlights
        ? renderFlights()
        : null}
      {!tripSummariesLoading && !flightsToShow.length
        ? renderNoFlightsMessaging()
        : null}
      {!tripSummariesLoading &&
      flightsToRender.length === flightsToShow.length &&
      flightsToRender.length !== 0 &&
      (hasAppliedFareClassFilter || hasAppliedNonFareclassFilter) ? (
        <FlightFindMoreResults />
      ) : null}
    </Box>
  );
};
