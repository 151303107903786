import { createSelector } from "@reduxjs/toolkit";
import {
  PolicyDetailsEnum,
  Person,
  Cap1DpExerciseItineraryFactsProperties,
  Cap1DpExerciseFactsProperties,
  Cap1DpExerciseFlightsListFactsProperties,
  PartialRebookSummaryDpExerciseFlightsListFacts,
  PartialPassengerDpExerciseFlightsListFacts,
  PartialOriginalItineraryDpExerciseItineraryFacts,
  PartialFlightsListDpExerciseFacts,
  PartialEligibilityDpExerciseFacts,
  getDisruptionProductTypeFromAncillaries,
  getPartialEligibilityDpExerciseFactsPropertiesFromEligibility,
  getSlicesFromTravelItinerary,
  getSegmentCounts,
  getSegmentStopCounts,
  getDepartureSliceFromTravelItinerary,
  getReturnSliceFromTravelItinerary,
  PartialDisruptedFlightDpExerciseFacts,
  getItineraryDepartureDate,
  getItineraryReturnDate,
  getIsSelectingReturnFlightForRebook,
  PaymentMachinePassengerTypeEnum,
  FlightItinerarySegment,
  TripCategory,
} from "redmond";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import {
  ANCILLARY_MARKETPLACE_SINGLE_PAGE,
  ANCILLARY_MARKETPLACE_MULTI_PAGE,
  ANCILLARY_MARKETPLACE_FLIGHT_BOOK,
  ANCILLARY_MARKETPLACE,
  AVAILABLE,
  PRICE_DROP_CREDIT,
  AIR_OFFER_REDESIGN,
  AIR_OFFER_REDESIGN_TABLE_CONCEPT,
  POST_BOOKING_OFFER_EXPERIMENT,
  POST_BOOKING_OFFER_EXPERIMENT_REWARDS,
  CAP_ONE_DISRUPTION_OPT_IN,
  CAP_ONE_DISRUPTION_OPT_IN_3_HOUR_THRESHOLD,
  FINTECH_CSAT,
} from "../../../../context/experiments";
import { DateToggleFilter } from "../index";
import { getDepartureDateFromDateToggleAndOriginalSlice } from "../../utils";
import { getOrigin, getTripCategory } from "../../../search/reducer";
import {
  selectedFlightsFromRebookSummarySelector,
  dateToggleFilterSelector,
  disruptionProtectionOriginalSliceSelector,
  flightDisruptionsSelector,
} from "./disruptionProtection";
import { ancillaryExperimentsSelector } from "./experiments";

export const singleFlightItinerarySelector = (state: IStoreState) =>
  state.flightAncillary.singleFlightItinerary;

export const fetchSingleFlightItineraryCallStateSelector = (
  state: IStoreState
) => state.flightAncillary.fetchSingleFlightItineraryCallState;

export const ancillaryMarketplaceVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[ANCILLARY_MARKETPLACE]
);

export const priceDropCreditVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[PRICE_DROP_CREDIT]
);

export const isSinglePageMarketplaceEnabledSelector = createSelector(
  ancillaryMarketplaceVariantSelector,
  (ancillaryMarketplaceVariant) =>
    ancillaryMarketplaceVariant === ANCILLARY_MARKETPLACE_SINGLE_PAGE
);

export const isMultiPageMarketplaceEnabledSelector = createSelector(
  ancillaryMarketplaceVariantSelector,
  (ancillaryMarketplaceVariant) =>
    ancillaryMarketplaceVariant === ANCILLARY_MARKETPLACE_MULTI_PAGE
);

// it's the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting
export const isFlightBookWithAncillariesEnabledSelector = createSelector(
  getTripCategory,
  ancillaryMarketplaceVariantSelector,
  (tripCategory, ancillaryMarketplaceVariant) => {
    const isMulticity = tripCategory === TripCategory.MULTI_CITY;
    return (
      !isMulticity &&
      ancillaryMarketplaceVariant === ANCILLARY_MARKETPLACE_FLIGHT_BOOK
    );
  }
);

export const isPriceDropCreditEnabledSelector = createSelector(
  priceDropCreditVariantSelector,
  (priceDropCreditVariant) => priceDropCreditVariant === AVAILABLE
);

export const getPriceDropRefundTypeSelector = createSelector(
  isPriceDropCreditEnabledSelector,
  (isPriceDropCreditEnabled) => (isPriceDropCreditEnabled ? "credit" : "cash")
);

export const postBookingOffersSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[POST_BOOKING_OFFER_EXPERIMENT]
);

export const postBookingOffersRewardsSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[POST_BOOKING_OFFER_EXPERIMENT_REWARDS]
);

export const isPostBookingOffersEnabledSelector = createSelector(
  postBookingOffersSelector,
  (variant) => variant?.startsWith(AVAILABLE)
);

export const isPostBookingOffersRewardsEnabledSelector = createSelector(
  postBookingOffersRewardsSelector,
  (variant) => variant?.startsWith(AVAILABLE)
);

export const disruptionPushNotifSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CAP_ONE_DISRUPTION_OPT_IN]
);

export const isDisruptionPushNotifEnabled = createSelector(
  disruptionPushNotifSelector,
  (variant) => variant === CAP_ONE_DISRUPTION_OPT_IN_3_HOUR_THRESHOLD
);

export const isCustomizePageMarketplaceEnabledSelector = createSelector(
  isSinglePageMarketplaceEnabledSelector,
  isMultiPageMarketplaceEnabledSelector,
  (isSinglePageMarketplaceEnabled, isMultiPageMarketplaceEnabled) =>
    isSinglePageMarketplaceEnabled || isMultiPageMarketplaceEnabled
);

export const singleFlightItineraryRebookCoverageSelector = createSelector(
  singleFlightItinerarySelector,
  (itinerary) => {
    const delayCoverage =
      itinerary?.itinerary.ancillaries.delay?.policyDetails?.coverage;
    const missedConnectionPolicyDetails =
      itinerary?.itinerary.ancillaries.missedConnection?.policyDetails;
    const missedConnectionCoverage = (() => {
      switch (missedConnectionPolicyDetails?.PolicyDetails) {
        case PolicyDetailsEnum.FixedPolicyDetails:
          return missedConnectionPolicyDetails.coverage;
        case PolicyDetailsEnum.PercentagePolicyDetails:
        default:
          return undefined;
      }
    })();

    return delayCoverage ?? missedConnectionCoverage;
  }
);

export const singleFlightItineraryIdSelector = createSelector(
  singleFlightItinerarySelector,
  (singleFlightItinerary) => {
    return singleFlightItinerary?.itinerary.bookedItinerary.id;
  }
);

export const singleFlightItineraryPassengersSelector = createSelector(
  singleFlightItinerarySelector,
  (singleFlightItinerary) => {
    return singleFlightItinerary?.itinerary.bookedItinerary.passengers;
  }
);

export const singleFlightItineraryPassengerNamesAndIdsSelector = createSelector(
  singleFlightItineraryPassengersSelector,
  (passengers): { id: string; name: string }[] => {
    const seatedPassengersMap: { [key in string]: Person } = {};
    passengers?.alone.forEach((passenger) => {
      seatedPassengersMap[passenger.person.id] = passenger.person;
    });
    passengers?.withLapInfants.forEach((entry) => {
      seatedPassengersMap[entry.adult.person.id] = entry.adult.person;
    });

    const infants =
      passengers?.withLapInfants.map((infant) => infant.infant.person) ?? [];
    return Object.values(seatedPassengersMap)
      .concat(infants)
      .map((person) => ({
        id: person.id,
        name: `${person.givenName} ${person.surname}`,
      }));
  }
);

export const singleFlightItinerarySeatedPassengerNamesAndIdsSelector =
  createSelector(
    singleFlightItineraryPassengersSelector,
    (passengers): { id: string; name: string }[] => {
      const seatedPassengersMap: { [key in string]: Person } = {};
      passengers?.alone.forEach((passenger) => {
        seatedPassengersMap[passenger.person.id] = passenger.person;
      });
      passengers?.withLapInfants.forEach((entry) => {
        seatedPassengersMap[entry.adult.person.id] = entry.adult.person;
      });

      return Object.values(seatedPassengersMap).map((person) => ({
        id: person.id,
        name: `${person.givenName} ${person.surname}`,
      }));
    }
  );

export const singleFlightItinerarySlicesSelector = createSelector(
  singleFlightItinerarySelector,
  (itinerary) => {
    const travelItinerary =
      itinerary?.itinerary.bookedItinerary.travelItinerary;

    return travelItinerary ? getSlicesFromTravelItinerary(travelItinerary) : [];
  }
);

export const singleFlightItineraryDepartureSliceSelector = createSelector(
  singleFlightItinerarySlicesSelector,
  (slices) => {
    // note: the departure slice is located at index 0; when it's out of range, an undefined will be returned
    return slices?.[0];
  }
);

export const singleFlightItineraryReturnSliceSelector = createSelector(
  singleFlightItinerarySlicesSelector,
  (slices) => {
    // note: the return slice is located at index 1; when it's out of range, an undefined will be returned
    return slices?.[1];
  }
);

export const singleFlightItineraryDisruptionProtectionEligibilitySelectorSelector =
  createSelector(
    singleFlightItineraryIdSelector,
    flightDisruptionsSelector,
    (itineraryId, flightDisruptions) => {
      if (itineraryId) {
        return flightDisruptions.eligibilityByItinerary[itineraryId];
      }

      return undefined;
    }
  );

export const hasUpcomingReturnFlightSelector = createSelector(
  singleFlightItineraryReturnSliceSelector,
  (returnSlice): boolean => {
    const firstReturnSegment = returnSlice?.segments[0];

    return (
      !!firstReturnSegment &&
      dayjs().isBefore(
        dayjs(
          firstReturnSegment.zonedUpdatedDeparture ||
            firstReturnSegment.zonedScheduledDeparture ||
            firstReturnSegment.updatedDeparture ||
            firstReturnSegment.scheduledDeparture
        )
      )
    );
  }
);

export const isSelectingReturnFlightSelector = createSelector(
  singleFlightItineraryDisruptionProtectionEligibilitySelectorSelector,
  singleFlightItineraryDepartureSliceSelector,
  singleFlightItineraryReturnSliceSelector,
  (eligibility, departureSlice, returnSlice): boolean => {
    return getIsSelectingReturnFlightForRebook({
      eligibility,
      departureSlice,
      returnSlice,
    });
  }
);

export const rebookDepartureDateTimeSelector = createSelector(
  disruptionProtectionOriginalSliceSelector,
  dateToggleFilterSelector,
  getOrigin,
  (originalSlice, dateToggleFilter, origin): string | null => {
    if (originalSlice) {
      return getDepartureDateFromDateToggleAndOriginalSlice(
        originalSlice,
        dateToggleFilter,
        origin
      );
    }

    return null;
  }
);

export const rebookSummaryDpExerciseFlightsListFactsPropertiesSelector =
  createSelector(
    isSelectingReturnFlightSelector,
    selectedFlightsFromRebookSummarySelector,
    dateToggleFilterSelector,
    (
      isSelectingReturnFlight,
      selectedFlightsFromRebookSummary,
      dateToggleFilter
    ): PartialRebookSummaryDpExerciseFlightsListFacts | undefined => {
      if (selectedFlightsFromRebookSummary) {
        const flightsCount = selectedFlightsFromRebookSummary.outbound.length;
        const faresCount = selectedFlightsFromRebookSummary.outbound.reduce(
          (sum, trip) => sum + trip.fares.length,
          0
        );

        return {
          flights_shown: flightsCount,
          ...(isSelectingReturnFlight
            ? {
                fares_shown_return: faresCount,
              }
            : {
                fares_shown_outbound: faresCount,
              }),
          rebooking_tool_filter:
            dateToggleFilter === DateToggleFilter.TODAY ? "today" : "tomorrow",
        };
      }

      return undefined;
    }
  );

export const dpExercisePassengerPropertiesSelector = createSelector(
  singleFlightItineraryPassengersSelector,
  singleFlightItineraryPassengerNamesAndIdsSelector,
  singleFlightItinerarySeatedPassengerNamesAndIdsSelector,
  (
    passengers,
    passengerList,
    seatedPassengerList
  ): PartialPassengerDpExerciseFlightsListFacts | undefined => {
    if (passengers && passengerList && seatedPassengerList) {
      return {
        searched_pax_total: passengerList.length,
        searched_pax_total_infant_lap: passengers.withLapInfants.length,
        searched_pax_total_seated: seatedPassengerList.length,
        selected_pax_lap_infants: passengers.withLapInfants.length,
        selected_pax_seat_infants: passengers.alone.filter(
          (p) =>
            p.type.PassengerType ===
            PaymentMachinePassengerTypeEnum.SeatedInfant
        ).length,
        selected_pax_seated: seatedPassengerList.length,
        selected_pax_total: passengerList.length,
      };
    }

    return undefined;
  }
);

export const originalItineraryDpExerciseItineraryFactsPropertiesSelector =
  createSelector(
    isSelectingReturnFlightSelector,
    singleFlightItineraryReturnSliceSelector,
    singleFlightItinerarySelector,
    rebookDepartureDateTimeSelector,
    (
      isSelectingReturnFlight,
      returnSlice,
      itinerary,
      rebookDepartureDateTime
    ): PartialOriginalItineraryDpExerciseItineraryFacts | undefined => {
      if (itinerary && rebookDepartureDateTime) {
        return {
          rebooking_direction: isSelectingReturnFlight ? "return" : "outbound",
          rebooking_flow: "disruption",
          trip_type: !!returnSlice ? "round_trip" : "one_way",
        };
      }

      return undefined;
    }
  );

export const viewedRebookingFlightListPropertiesSelector = createSelector(
  rebookSummaryDpExerciseFlightsListFactsPropertiesSelector,
  dpExercisePassengerPropertiesSelector,
  originalItineraryDpExerciseItineraryFactsPropertiesSelector,
  (
    rebookSummaryDpExerciseFlightsListFactsProperties,
    dpExercisePassengerProperties,
    originalItineraryDpExerciseItineraryFactsProperties
  ): Cap1DpExerciseItineraryFactsProperties &
    Cap1DpExerciseFlightsListFactsProperties => {
    return {
      flights_shown:
        rebookSummaryDpExerciseFlightsListFactsProperties?.flights_shown,
      fares_shown_outbound:
        rebookSummaryDpExerciseFlightsListFactsProperties?.fares_shown_outbound,
      fares_shown_return:
        rebookSummaryDpExerciseFlightsListFactsProperties?.fares_shown_return,
      rebooking_tool_filter:
        rebookSummaryDpExerciseFlightsListFactsProperties?.rebooking_tool_filter,
      rebooking_direction:
        originalItineraryDpExerciseItineraryFactsProperties?.rebooking_direction,
      rebooking_flow:
        originalItineraryDpExerciseItineraryFactsProperties?.rebooking_flow,
      trip_type: originalItineraryDpExerciseItineraryFactsProperties?.trip_type,
      departure_date:
        originalItineraryDpExerciseItineraryFactsProperties?.departure_date,
      ...dpExercisePassengerProperties,
    };
  }
);

export const disruptedSliceIndexSelector = (state: IStoreState) =>
  state.flightAncillary.disruptedSliceIndex;

export const disruptedSegmentIndexSelector = (state: IStoreState) =>
  state.flightAncillary.disruptedSegmentIndex;

export const disruptedFlightDpExerciseFactsPropertiesSelector = createSelector(
  [
    singleFlightItinerarySelector,
    disruptedSliceIndexSelector,
    disruptedSegmentIndexSelector,
  ],
  (
    itinerary,
    sliceId,
    segmentId
  ): PartialDisruptedFlightDpExerciseFacts | undefined => {
    const travelItinerary =
      itinerary?.itinerary.bookedItinerary.travelItinerary;

    let segment: FlightItinerarySegment | undefined;

    if (travelItinerary && sliceId !== null && segmentId !== null) {
      segment =
        sliceId === 0
          ? getDepartureSliceFromTravelItinerary(travelItinerary).segments[
              segmentId
            ]
          : sliceId === 1
          ? getReturnSliceFromTravelItinerary(travelItinerary)?.segments[
              segmentId
            ]
          : undefined;
    }

    let travelItineraryEventProperties = {};
    let segmentEventProperties = {};

    if (travelItinerary) {
      const segmentCounts = getSegmentCounts(travelItinerary);
      const segmentStopCounts = getSegmentStopCounts(travelItinerary);

      travelItineraryEventProperties = {
        itinerary_departure_date: getItineraryDepartureDate(travelItinerary),
        itinerary_return_date: getItineraryReturnDate(travelItinerary),
        outbound_segments: segmentCounts.outboundFlight,
        outbound_stops: segmentStopCounts.outboundFlight,
        return_segments: segmentCounts.returnFlight,
        return_stops: segmentStopCounts.returnFlight,
        total_stops:
          segmentStopCounts.outboundFlight +
          (segmentStopCounts.returnFlight ?? 0),
      };
    }

    if (segment) {
      segmentEventProperties = {
        departure_date:
          segment.zonedUpdatedDeparture ?? segment.updatedDeparture,
        advance: dayjs(segment.zonedUpdatedDeparture).diff(dayjs(), "days"),
        destination: segment.destination.locationCode,
        origin: segment.origin.locationCode,
      };
    } else if (travelItinerary) {
      const departureSlice =
        getDepartureSliceFromTravelItinerary(travelItinerary);
      const firstDeparture =
        departureSlice.segments?.[0]?.zonedUpdatedDeparture ??
        departureSlice.segments?.[0]?.updatedDeparture;

      // Fallback to itinerary-level data if a specific segment cannot be retrieved.
      segmentEventProperties = {
        departure_date: firstDeparture,
        advance: dayjs(firstDeparture).diff(dayjs(), "days"),
      };
    }

    return { ...travelItineraryEventProperties, ...segmentEventProperties };
  }
);

export const rebookingViewedSlicePropertiesSelector = createSelector(
  rebookSummaryDpExerciseFlightsListFactsPropertiesSelector,
  viewedRebookingFlightListPropertiesSelector,
  originalItineraryDpExerciseItineraryFactsPropertiesSelector,
  dpExercisePassengerPropertiesSelector,
  disruptedFlightDpExerciseFactsPropertiesSelector,
  (
    rebookSummaryDpExerciseFlightsListFactsProperties,
    viewedRebookingFlightListProperties,
    originalItineraryDpExerciseItineraryFactsProperties,
    dpExercisePassengerProperties,
    disruptedFlightDpExerciseFactsProperties
  ): Cap1DpExerciseItineraryFactsProperties &
    Cap1DpExerciseFlightsListFactsProperties &
    Cap1DpExerciseFactsProperties => {
    return {
      flights_shown: viewedRebookingFlightListProperties.flights_shown,
      pax_total: viewedRebookingFlightListProperties.searched_pax_total,
      rebooking_tool_filter:
        rebookSummaryDpExerciseFlightsListFactsProperties?.rebooking_tool_filter,
      rebooking_direction:
        viewedRebookingFlightListProperties.rebooking_direction,
      rebooking_flow:
        originalItineraryDpExerciseItineraryFactsProperties?.rebooking_flow,
      ...disruptedFlightDpExerciseFactsProperties,
      ...dpExercisePassengerProperties,
    };
  }
);

export const rebookingConfirmedSlicePropertiesSelector = createSelector(
  rebookingViewedSlicePropertiesSelector,
  (
    rebookingViewedSliceProperties
  ): Cap1DpExerciseItineraryFactsProperties &
    Cap1DpExerciseFlightsListFactsProperties &
    Cap1DpExerciseFactsProperties => {
    return { ...rebookingViewedSliceProperties };
  }
);

export const reviewItineraryEligibilityDpExerciseFactsPropertiesSelector =
  createSelector(
    singleFlightItinerarySelector,
    singleFlightItineraryDisruptionProtectionEligibilitySelectorSelector,
    (
      itinerary,
      eligibility
    ):
      | (PartialFlightsListDpExerciseFacts & PartialEligibilityDpExerciseFacts)
      | undefined => {
      if (itinerary && eligibility) {
        return {
          ...getPartialEligibilityDpExerciseFactsPropertiesFromEligibility(
            eligibility
          ),
          disruption_product: getDisruptionProductTypeFromAncillaries(
            itinerary.itinerary.ancillaries
          ),
        };
      }

      return undefined;
    }
  );

export const airOfferRedesignSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[AIR_OFFER_REDESIGN]
);

export const isAirOfferRedesignEnabledSelector = createSelector(
  airOfferRedesignSelector,
  (experiment) => experiment === AIR_OFFER_REDESIGN_TABLE_CONCEPT
);

export const isFintechCsatEnabledSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[FINTECH_CSAT] === AVAILABLE
);

export * from "./cfar";
export * from "./disruptionProtection";
