import axios from "axios";
import {
  CREATED_TRAVELER_PROFILE,
  IPerson,
  TravelerProfileProperties,
  UPDATED_TRAVELER_PROFILE,
} from "redmond";

import { airBookingApi } from "@b2bportal/air-booking-api";
import { trackEvent } from "../../analytics/trackEvent";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../config";
import { ToggleActions } from "halifax";

export const updateUserPassenger = (
  body: IUpdateUserPassengerRequest
): Promise<IUpdateUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        isNewTraveler,
        setIsMyProfile,
        hideProfileAction,
        entryPoint,
        ...payload
      } = body;
      const res = await airBookingApi(
        axios as any
      ).apiV0FlightPassengersUpdatePut(payload);

      const responseBody: IUpdateUserPassengerResponse = res.data;
      resolve(responseBody);

      if (isCorpTenant(config.TENANT)) {
        const frequentFlyerProgramsAmount = Reflect.ownKeys(
          body.person.frequentFlyer
        ).length;
        const hotelLoyaltyProgramsAmount = Reflect.ownKeys(
          body.person.hotelLoyalty
        ).length;

        trackEvent({
          eventName: isNewTraveler
            ? CREATED_TRAVELER_PROFILE
            : UPDATED_TRAVELER_PROFILE,
          properties: {
            frequent_flyer_program_added: frequentFlyerProgramsAmount > 0,
            num_frequent_flyer_programs: frequentFlyerProgramsAmount,
            hotel_loyalty_program_added: hotelLoyaltyProgramsAmount > 0,
            num_hotel_loyalty_programs: hotelLoyaltyProgramsAmount,
            source: body.source,
            private: hideProfileAction === ToggleActions.ON,
            entry_point: entryPoint,
          } satisfies TravelerProfileProperties,
        });
      }
    } catch (e) {
      reject(e);
    }
  });

export interface IUpdateUserPassengerRequest {
  person: IPerson;
  isNewTraveler?: boolean;
  source?: string;
  setIsMyProfile?: boolean;
  hideProfileAction?: ToggleActions;
  entryPoint?: string;
}

export interface IUpdateUserPassengerResponse {}
