import { put, putResolve, select, take } from "redux-saga/effects";
import {
  CallState,
  FetchOfferResponse,
  TripDetails,
  PriceFreezeOfferDataWithRewards,
} from "redmond";

import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import {
  ISelectedTrip,
  selectedTripSelector,
  selectedTripDetailsSelector,
} from "../../shop/reducer";
import {
  getPriceFreezeOfferDataCallStateSelector,
  generateCustomPriceFreezeOfferCallStateSelector,
  selectedAndValidPriceFreezeOfferDataSelector,
  isPriceFreezeDurationEnabledSelector,
} from "../reducer/selectors";
import {
  SET_CUSTOM_PRICE_FREEZE_OFFER,
  SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED,
} from "../actions/constants";
import { getPriceFreezeOffer } from "../../../api/v0/price-freeze/getPriceFreezeOffer";
import { initializeOfferDataAndCustomOffer } from "./initializeOfferDataAndCustomOfferSaga";

export function* getPriceFreezeOfferSaga(action: actions.IGetPriceFreezeOffer) {
  try {
    const response: FetchOfferResponse = yield getPriceFreezeOffer({
      offerId: action.offerId,
    });
    const { offer } = response;

    yield putResolve(
      actions.setPriceFreezeOffer({
        offer,
        priceFreezeOfferCallState: CallState.Success,
      })
    );

    /*
      note: there are 2 ways to get to the PF purchase screen:
      
      1) through the flight shop funnel
      2) through the PF purchase URL directly (e.g: refreshing the page on PF purchase)

      The former involves executing the `/shopSummary` saga in flight shop, which causes 
      PF-offer-data states to be updated (e.g: it's for fetching a list of PF duration options), 
      so they don't need to be updated again once they get to the purchase page. 
      The latter doesn't have PF-offer-data states populated in the redux, so they need to be 
      addressed accordingly.
    */

    const isPriceFreezeDurationEnabled: boolean = yield select(
      isPriceFreezeDurationEnabledSelector
    );
    const getPriceFreezeOfferDataCallState: CallState = yield select(
      getPriceFreezeOfferDataCallStateSelector
    );
    const generateCustomPriceFreezeOfferCallState: CallState = yield select(
      generateCustomPriceFreezeOfferCallStateSelector
    );
    const selectedTrip: ISelectedTrip = yield select(selectedTripSelector);
    const selectedTripDetails: TripDetails | null = yield select(
      selectedTripDetailsSelector
    );
    const departureTime = selectedTripDetails?.slices[0]?.departureTime;

    if (
      getPriceFreezeOfferDataCallState === CallState.NotCalled &&
      departureTime
    ) {
      yield initializeOfferDataAndCustomOffer({
        departureDate: departureTime,
        tripId: selectedTrip.tripId ?? "",
        fareId: selectedTrip.returnFareId ?? selectedTrip.outgoingFareId ?? "",
        history: action.history,
        isFromFetchTripSummariesV3: false,
      });
    } else if (
      generateCustomPriceFreezeOfferCallState === CallState.NotCalled &&
      isPriceFreezeDurationEnabled
    ) {
      const priceFreezeOfferData: PriceFreezeOfferDataWithRewards | null =
        yield select(selectedAndValidPriceFreezeOfferDataSelector);
      if (priceFreezeOfferData) {
        yield putResolve(
          actions.generateCustomPriceFreezeOffer(
            priceFreezeOfferData,
            action.history,
            false,
          )
        );
        // note: wait for generateCustomPriceFreezeOffer to finish
        yield take([
          SET_CUSTOM_PRICE_FREEZE_OFFER,
          SET_GENERATE_CUSTOM_PRICE_FREEZE_OFFER_CALL_STATE_FAILED,
        ]);
      }
    }
  } catch (e) {
    yield put(actions.setPriceFreezeOfferCallStateFailed());
    Logger.debug(e);
  }
}
