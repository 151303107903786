export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const FARE_GRID_TAG_SEPARATOR = ", ";

export const getEarnTagText = (earnRate: string | number) =>
  `<span class="font-bold">Earn ${earnRate}X</span> miles on flights`;

export const SELF_CHECK_BANNER_LEGEND_GRID_ROW =
  "You are required to claim and re-check baggage during layovers.";

export const SELF_CHECK_BANNER_LEGEND_GRID_ROW_V2 =
  "This flight operates through multiple airlines. Layover protected by our";
export const SELF_CHECK_BANNER_LEGEND_GRID_ROW_STRONG_SUFFIX =
  " missed connection rebooking ⓘ";
export const LEARN_MORE = "Learn more";
