import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  priceFreezeQuoteSummaryLineItemsSelector,
  currencySelector,
} from "../../../book/reducer";
import { FlightFreezePriceBreakdown } from "./component";
// import { selectedTripSelector, tripDetailsSelector } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  // const selectedTrip = selectedTripSelector(state);
  // const tripId = selectedTrip.tripId || "";
  // const tripDetails = tripDetailsSelector(state, tripId);
  //
  // // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  // const isVITripSelected = tripDetails.fareDetails[0]?.slices.some((slice) =>
  //   slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  // );

  /**
   * This is a temporary workaround.
   *
   * As of December 14, 2023, 'tripDetailsSelector', in the preceding snippet, returns 'undefined'. Possibly due to 'selectedTrip.tripId' being undefined. It's unclear.
   *
   * In any case, as of writing, Price Freeze is not supported for Virtually Interlined (VI) itineraries. So, for now, safely assume this is always 'false' and prevent an exception in this component.
   *
   * @todo Assign based on actual VI itinerary once Price Freeze is supported.
   */
  const isVITripSelected = false;

  return {
    summaryLines: priceFreezeQuoteSummaryLineItemsSelector(state),
    isVITripSelected: isVITripSelected,
    currency: currencySelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezePriceBreakdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezePriceBreakdown = withRouter(
  connector(FlightFreezePriceBreakdown)
);
