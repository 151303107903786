import { connect, ConnectedProps } from "react-redux";
import { ITripTerminus } from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  // types
  IFetchDepartureCalendar,
  ISetDepartureCalendarArgs,
  ISetDepartureCalendar,
  ISetMulticityOrigin,
  ISetMulticityDestination,
  ISetMulticityDepartureDate,
  IAddMulticityRoute,
  IRemoveMulticityRoute,

  // set state props
  fetchDepartureCalendar,
  setCalendar,
  setMulticityOrigin,
  setMulticityDestination,
  setMulticityDepartureDate,
  addMulticityRoute,
  removeMulticityRoute,

  // set filter state props
  setStopsOption,
  setAirlineFilter,
  setFareclassOptionFilter,
  setMaxPriceFilter,
  setAirportFilter,
  setFlightNumberFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
} from "../../actions/actions";

import {
  initialFilterOptions,
  getMulticityRoutes,
  MulticityRoute,
} from "../../reducer";
import { MulticityFlightSearchRouteList } from "./component";
import { Dispatch } from "@reduxjs/toolkit";
import { withRouter } from "react-router";
import {
  IFlightShopOverwriteQueryParams,
  IPopulateFlightShopQueryParams,
  populateFlightShopQueryParams,
} from "../../../shop/actions/actions";
interface IDispatchProps {
  fetchDepartureCalendar: (
    origin: ITripTerminus,
    destination: ITripTerminus
  ) => IFetchDepartureCalendar;
  setCalendar: (report?: ISetDepartureCalendarArgs) => ISetDepartureCalendar;
  setMulticityOrigin: (
    origin: ITripTerminus | null,
    index: number
  ) => ISetMulticityOrigin;
  setMulticityDestination: (
    destination: ITripTerminus | null,
    index: number
  ) => ISetMulticityDestination;
  setMulticityDepartureDate: (
    date: Date | null,
    index: number
  ) => ISetMulticityDepartureDate;
  addMulticityRoute: () => IAddMulticityRoute;
  removeMulticityRoute: (index: number) => IRemoveMulticityRoute;
  resetFilters: () => void;
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => IPopulateFlightShopQueryParams;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchDepartureCalendar: (origin: ITripTerminus, destination: ITripTerminus) =>
    dispatch(fetchDepartureCalendar(origin, destination)),
  setCalendar: (report?: ISetDepartureCalendarArgs) =>
    dispatch(setCalendar(report)),
  setMulticityOrigin: (origin: ITripTerminus | null, index: number) =>
    dispatch(setMulticityOrigin(origin, index)),
  setMulticityDestination: (destination: ITripTerminus | null, index: number) =>
    dispatch(setMulticityDestination(destination, index)),
  setMulticityDepartureDate: (date: Date | null, index: number) =>
    dispatch(setMulticityDepartureDate(date, index)),
  addMulticityRoute: () => dispatch(addMulticityRoute()),
  removeMulticityRoute: (index: number) =>
    dispatch(removeMulticityRoute(index)),
  resetFilters: () => {
    dispatch(setStopsOption(initialFilterOptions.stopsOption));
    dispatch(setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(
      setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
      })
    );
    dispatch(setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(setFlightNumberFilter(initialFilterOptions.flightNumberFilter));
    dispatch(
      setOutboundArrivalTimeRange(initialFilterOptions.outboundArrivalTimeRange)
    );
    dispatch(
      setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      setReturnDepartureTimeRange(initialFilterOptions.returnDepartureTimeRange)
    );
    dispatch(
      setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange)
    );
  },
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => dispatch(populateFlightShopQueryParams(args)),
});

interface IStateProps {
  multicityRoutes: MulticityRoute[];
}
const mapStateToProps = (state: IStoreState) => {
  return {
    multicityRoutes: getMulticityRoutes(state),
  };
};

interface IOwnProps {
  hasMissingMulticitySearchInfoError?: boolean;
  renderErrorBanner?: () => JSX.Element | undefined;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export type MulticityFlightSearchRouteListConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMulticityFlightSearchRouteList = connector(
  withRouter(MulticityFlightSearchRouteList)
);
