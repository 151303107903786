import React from "react";
import {
  CallState,
  FlightShopType,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import { AirlineIcon, useDeviceTypes } from "halifax";

import {
  DesktopFlightShopSearchFilter,
  MobileFlightShopSearchFilter,
} from "./components";
import { FlightShopSearchFilterConnectorProps } from "./container";
import { FilterLabelValues } from "../../../FlightShopSearchControl/useFilterLabelValues";
import { AirCXV3VariantType } from "../../../../../../context/experiments";
import { initialFilterOptions } from "../../../../reducer";

export interface IFlightShopSearchFilterProps
  extends FlightShopSearchFilterConnectorProps {
  hideAirportFilter?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  filterLabelValues?: FilterLabelValues;
  airCXV3Variant?: AirCXV3VariantType;
  isCustomerProfileExperiment?: boolean;
}

export const FlightShopSearchFilter = (props: IFlightShopSearchFilterProps) => {
  const { matchesMobile, matchesDesktop } = useDeviceTypes();

  const {
    hideAirportFilter,
    flightShopFilters,
    predictionLoading,
    tripCategory,
    flightShopType,
    isFlightListOptimizationExperiment,
    filterLabelValues,
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    hasSetStopsOption,
    airlineFilter,
    setAirlineFilter,
    hasSetAirlineFilter,
    airportFilter,
    setAirportFilter,
    maxPriceFilter,
    setMaxPriceFilter,
    hasSetMaxPrice,
    flightNumberFilter,
    departureDateString,
    setFlightNumberFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    hasSetTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    fareclassOptionFilter,
    setFareclassOptionFilter,
    hasSetFareclassFilter,
    origin,
    destination,
    originAirport,
    destinationAirport,
    hasBaggageTransfersFilter,
    setBaggageTransfersFilter,
    airCXV3Variant,
    durationFilter,
    setDurationFilter,
    minDuration = 0,
    maxDuration = 0,
    setPolicyFilter,
    policyFilter,
    filteredFlightCount,
    resetAllFilters,
    hasActiveFilters,
    isCustomerProfileExperiment,
    userHasSetFlightPreferences = false,
    userFlightPreferencesCallState = CallState.NotCalled,
    shouldApplyUserFlightPreferences,
    setApplyUserFlightPreferences,
    userFlightPreferences,
    setUserPreferencesNotAvailable,
    userFlightPreferencesNotAvailable,
  } = props;

  const [currFlightNumberAirlineFilter, setCurrFlightNumberAirlineFilter] =
    React.useState<string>("");

  const { airlineOptions, flightNumbersByAirline, airportOptions } =
    flightShopFilters;

  const filtersVisibility = (() => {
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE:
        return {
          hideAirportFilter: true,
          hideMaxPriceFilter: true,
        };
      default:
        return {
          hideAirportFilter,
        };
    }
  })();

  const allAirlinesToDisplay = React.useMemo(() => {
    if (isFlightListOptimizationExperiment) {
      return airlineOptions.map((airline) => {
        return {
          ...airline,
          icon: <AirlineIcon airlineCode={airline.value} />,
        };
      });
    } else {
      return airlineOptions;
    }
  }, [airlineOptions]);

  React.useEffect(() => {
    if (
      airlineFilter.length &&
      airlineOptions.length &&
      !airlineOptions.some((airline) => airlineFilter.includes(airline.value))
    ) {
      if (shouldApplyUserFlightPreferences) {
        setUserPreferencesNotAvailable(true);
      }
      setAirlineFilter(initialFilterOptions.airlineFilter);
    }
  }, [airlineOptions, airlineFilter]);

  React.useEffect(() => {
    const airlines = Object.keys(flightNumbersByAirline);
    if (
      flightNumberFilter.length &&
      airlines.length &&
      !airlines.some((airline) =>
        flightNumberFilter.some((flightNumFilter) =>
          flightNumbersByAirline[airline].some(
            (airlineFlightNumber) =>
              airlineFlightNumber === flightNumFilter.flightNumber &&
              airline === flightNumFilter.airlineCode
          )
        )
      )
    ) {
      setFlightNumberFilter(initialFilterOptions.flightNumberFilter);
      setCurrFlightNumberAirlineFilter("");
    }
  }, [flightNumberFilter, flightNumbersByAirline]);

  React.useEffect(() => {
    if (
      airportFilter.length &&
      airportOptions.length &&
      !airportOptions.some((airport) =>
        airportFilter.some(
          (filteredAirport) => filteredAirport === airport.value
        )
      )
    ) {
      setAirportFilter(initialFilterOptions.airportFilter);
    }
  }, [airportFilter, airportOptions]);

  React.useEffect(() => {
    if (durationFilter < minDuration || durationFilter > maxDuration) {
      setDurationFilter(initialFilterOptions.durationFilter);
    }
  }, [durationFilter, minDuration, maxDuration]);

  React.useEffect(() => {
    if (shouldApplyUserFlightPreferences && userHasSetFlightPreferences) {
      if (
        (!!userFlightPreferences?.airlines.length &&
          !airlineFilter.length &&
          !userFlightPreferencesNotAvailable) ||
        (Object.values(userFlightPreferences?.fareClasses || {}).some(
          (applied) => applied
        ) &&
          !Object.values(fareclassOptionFilter).some((applied) => applied)) ||
        (userFlightPreferences?.nonStopOnly &&
          stopsOption !== SliceStopCountFilter.NONE)
      ) {
        setApplyUserFlightPreferences(false);
        setUserPreferencesNotAvailable(false);
      }
    }
  }, [
    fareclassOptionFilter,
    airlineFilter,
    stopsOption,
    userFlightPreferences,
    userHasSetFlightPreferences,
    shouldApplyUserFlightPreferences,
  ]);

  return (
    <>
      {matchesDesktop && (
        <DesktopFlightShopSearchFilter
          flightShopFilters={flightShopFilters}
          isFlightListOptimizationExperiment={
            isFlightListOptimizationExperiment
          }
          filterLabelValues={filterLabelValues}
          {...filtersVisibility}
          airlinesToDisplay={allAirlinesToDisplay}
          stopsOption={stopsOption}
          setStopsOption={setStopsOption}
          setRerunPrediction={setRerunPrediction}
          hasSetStopsOption={hasSetStopsOption}
          airlineFilter={airlineFilter}
          setAirlineFilter={setAirlineFilter}
          hasSetAirlineFilter={hasSetAirlineFilter}
          airportFilter={airportFilter}
          setAirportFilter={setAirportFilter}
          maxPriceFilter={maxPriceFilter}
          setMaxPriceFilter={setMaxPriceFilter}
          hasSetMaxPrice={hasSetMaxPrice}
          flightNumberFilter={flightNumberFilter}
          departureDateString={departureDateString}
          setFlightNumberFilter={setFlightNumberFilter}
          tripCategory={tripCategory}
          outboundDepartureTimeRange={outboundDepartureTimeRange}
          outboundArrivalTimeRange={outboundArrivalTimeRange}
          returnDepartureTimeRange={returnDepartureTimeRange}
          returnArrivalTimeRange={returnArrivalTimeRange}
          hasSetTimeRange={hasSetTimeRange}
          setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
          setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
          setReturnDepartureTimeRange={setReturnDepartureTimeRange}
          setReturnArrivalTimeRange={setReturnArrivalTimeRange}
          fareclassOptionFilter={fareclassOptionFilter}
          setFareclassOptionFilter={setFareclassOptionFilter}
          hasSetFareclassFilter={hasSetFareclassFilter}
          origin={origin}
          destination={destination}
          originAirport={originAirport}
          destinationAirport={destinationAirport}
          flightNumberAirlineFilter={currFlightNumberAirlineFilter}
          setFlightNumberAirlineFilter={setCurrFlightNumberAirlineFilter}
          hasBaggageTransfersFilter={hasBaggageTransfersFilter}
          setBaggageTransfersFilter={setBaggageTransfersFilter}
          airCXV3Variant={airCXV3Variant}
          durationFilter={durationFilter}
          setDurationFilter={setDurationFilter}
          minDuration={minDuration}
          maxDuration={maxDuration}
          filteredFlightCount={filteredFlightCount}
          resetAllFilters={resetAllFilters}
          hasActiveFilters={hasActiveFilters}
          showClearFiltersButton={!shouldApplyUserFlightPreferences}
          policyFilter={policyFilter}
          setPolicyFilter={setPolicyFilter}
        />
      )}
      {matchesMobile && (
        <MobileFlightShopSearchFilter
          flightShopFilters={flightShopFilters}
          predictionLoading={predictionLoading}
          hideArrivalFilter={tripCategory === TripCategory.ONE_WAY}
          {...filtersVisibility}
          stopsOption={stopsOption}
          setStopsOption={setStopsOption}
          setRerunPrediction={setRerunPrediction}
          hasSetStopsOption={hasSetStopsOption}
          airlineFilter={airlineFilter}
          setAirlineFilter={setAirlineFilter}
          hasSetAirlineFilter={hasSetAirlineFilter}
          airportFilter={airportFilter}
          setAirportFilter={setAirportFilter}
          maxPriceFilter={maxPriceFilter}
          setMaxPriceFilter={setMaxPriceFilter}
          hasSetMaxPrice={hasSetMaxPrice}
          flightNumberFilter={flightNumberFilter}
          departureDateString={departureDateString}
          setFlightNumberFilter={setFlightNumberFilter}
          tripCategory={tripCategory}
          outboundDepartureTimeRange={outboundDepartureTimeRange}
          outboundArrivalTimeRange={outboundArrivalTimeRange}
          returnDepartureTimeRange={returnDepartureTimeRange}
          returnArrivalTimeRange={returnArrivalTimeRange}
          hasSetTimeRange={hasSetTimeRange}
          setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
          setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
          setReturnDepartureTimeRange={setReturnDepartureTimeRange}
          setReturnArrivalTimeRange={setReturnArrivalTimeRange}
          flightNumberAirlineFilter={currFlightNumberAirlineFilter}
          setFlightNumberAirlineFilter={setCurrFlightNumberAirlineFilter}
          isCustomerProfileExperiment={isCustomerProfileExperiment}
          userHasSetFlightPreferences={userHasSetFlightPreferences}
          userFlightPreferencesCallState={userFlightPreferencesCallState}
          shouldApplyUserFlightPreferences={shouldApplyUserFlightPreferences}
          setApplyUserFlightPreferences={setApplyUserFlightPreferences}
        />
      )}
    </>
  );
};
