import { createSelector } from "@reduxjs/toolkit";
import {
  Flights,
  FlightItinerarySlice,
  TripSegment,
  getTripSegmentsFromItinerarySegments,
  getPlusDaysFromItinerarySegments,
  AirlineMap,
  AirportMap,
} from "redmond";
import { IFlightSummaryData } from "halifax";
import { IStoreState } from "../../../../reducers/types";
import {
  AVAILABLE,
  DISRUPTION,
  CFAR_SOCIAL,
  CAP_ONE_DISRUPTION_OPT_IN,
  CAP_ONE_DISRUPTION_OPT_IN_SCREEN_WITH_PUSH_OPT_IN,
  NON_FDA_CAP_ONE_DISRUPTION_OPT_IN,
  CAP_ONE_DISRUPTION_OPT_IN_3_HOUR_THRESHOLD,
} from "../../../../context/experiments";
import { ancillaryExperimentsSelector } from "./experiments";
import { DateToggleFilter } from "../index";

export const originalItineraryIdSelector = (state: IStoreState) =>
  state.flightAncillary.originalItineraryId;

export const rebookSummarySelector = (state: IStoreState) =>
  state.flightAncillary.rebookSummary;

export const rebookSummaryCallStateSelector = (state: IStoreState) =>
  state.flightAncillary.rebookSummaryCallState;

export const dateToggleFilterSelector = (state: IStoreState) =>
  state.flightAncillary.dateToggleFilter;

export const exerciseDisruptionProtectionRebookCallStateSelector = (
  state: IStoreState
) => state.flightAncillary.exerciseDisruptionProtectionRebookCallState;

export const exerciseDisruptionProtectionRebookCallFailureSelector = (
  state: IStoreState
) => state.flightAncillary.exerciseDisruptionProtectionRebookCallFailureReason;

export const flightDisruptionsSelector = (state: IStoreState) =>
  state.flightAncillary.flightDisruptions;

export const fetchFlightDisruptionsCallStateSelector = (state: IStoreState) =>
  state.flightAncillary.fetchFlightDisruptionsCallState;

export const disruptionProtectionVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[DISRUPTION]
);

export const cfarSocialVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CFAR_SOCIAL]
);

export const disruptionOptInVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[CAP_ONE_DISRUPTION_OPT_IN]
);

export const isDisruptionOptInEnabledSelector = createSelector(
  disruptionOptInVariantSelector,
  (variant) => {
    return (
      variant === CAP_ONE_DISRUPTION_OPT_IN_SCREEN_WITH_PUSH_OPT_IN ||
      variant === CAP_ONE_DISRUPTION_OPT_IN_3_HOUR_THRESHOLD
    );
  }
);
export const nonFDAdisruptionOptInVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[NON_FDA_CAP_ONE_DISRUPTION_OPT_IN]
);

export const isNonFDADisruptionOptInEnabledSelector = createSelector(
  nonFDAdisruptionOptInVariantSelector,
  (variant) => {
    return variant === AVAILABLE;
  }
);

export const isDisruptionProtectionEnabledSelector = createSelector(
  disruptionProtectionVariantSelector,
  (disruptionProtectionVariant) => disruptionProtectionVariant === AVAILABLE
);

export const sameDayFlightsFromRebookSummarySelector = createSelector(
  rebookSummarySelector,
  (rebookSummary): Flights | null => rebookSummary?.sameDayFlights ?? null
);

export const nextDayFlightsFromRebookSummarySelector = createSelector(
  rebookSummarySelector,
  (rebookSummary): Flights | null => rebookSummary?.nextDayFlights ?? null
);

export const selectedFlightsFromRebookSummarySelector = createSelector(
  sameDayFlightsFromRebookSummarySelector,
  nextDayFlightsFromRebookSummarySelector,
  dateToggleFilterSelector,
  (sameDayFlights, nextDayFlights, dateToggleFilter): Flights | null => {
    switch (dateToggleFilter) {
      case DateToggleFilter.TODAY:
        return sameDayFlights;
      case DateToggleFilter.TOMORROW:
        return nextDayFlights;
      default:
        return null;
    }
  }
);

export const contextFromRebookSummarySelector = createSelector(
  selectedFlightsFromRebookSummarySelector,
  (selectedFlights): { airlines: AirlineMap; airports: AirportMap } | null => {
    if (selectedFlights) {
      return {
        airlines: selectedFlights.airlines,
        airports: selectedFlights.airports,
      };
    }
    return null;
  }
);

export const disruptionProtectionOriginalSliceSelector = createSelector(
  rebookSummarySelector,
  (rebookSummary): FlightItinerarySlice | null => {
    return rebookSummary?.originalSlice ?? null;
  }
);

export const disruptionProtectionOriginalSliceFareRatingSelector =
  createSelector(
    disruptionProtectionOriginalSliceSelector,
    (originalSlice): number | undefined => {
      return originalSlice?.fareShelf?.rating;
    }
  );

export const disruptionProtectionOriginalSliceContextSelector = createSelector(
  rebookSummarySelector,
  (rebookSummary) => {
    return rebookSummary?.originalContext;
  }
);

export const originalSliceFlightSummaryPropsSelector = createSelector(
  disruptionProtectionOriginalSliceSelector,
  disruptionProtectionOriginalSliceContextSelector,
  (originalSlice, originalSliceContext): IFlightSummaryData => {
    const segments = getTripSegmentsFromItinerarySegments({
      segments: originalSlice?.segments ?? [],
      airportMap: originalSliceContext?.airports ?? {},
      airlineMap: originalSliceContext?.airlines ?? {},
      useCityName: true,
    });
    const stops = segments.length - 1;
    const plusDays = getPlusDaysFromItinerarySegments(segments);
    const firstTripSegment: TripSegment | undefined = segments[0];
    const lastTripSegment: TripSegment | undefined =
      segments[segments.length - 1];

    return {
      fareShelfRating: originalSlice?.fareShelf?.rating ?? 0,
      fareShelfBrandName: originalSlice?.fareShelf?.brandName ?? "",
      fareShelfShortBrandName: originalSlice?.fareShelf?.shortBrandName ?? "",
      tripSlice: {
        segments,
        cabinClassNames:
          originalSlice?.segments.map((segment) => segment.cabinClassName) ??
          [],
        airlineCode: firstTripSegment?.airlineCode,
        airlineName: firstTripSegment?.airlineName,
        departureTime: firstTripSegment?.departureTime,
        arrivalTime: lastTripSegment?.arrivalTime,
        originName: firstTripSegment?.originName,
        originCode: firstTripSegment?.originCode,
        destinationName: lastTripSegment?.destinationName,
        destinationCode: lastTripSegment?.destinationCode,
        stops,
        plusDays,
      },
    };
  }
);
