import {
  ActionButton,
  ActionLink,
  GenericInfoPopup,
  Icon,
  IconName,
  MobilePopoverCard,
  CloseButtonIcon,
} from "halifax";
import React, { useState, useEffect } from "react";
import {
  TRY_AGAIN,
  SKIP_SEAT_SELECTION,
  SEAT_SELECTION_ERROR_TITLE,
  SEAT_SELECTION_ERROR_SUBTITLE,
} from "./textConstants";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";

export interface ISeatSelectionErrorModalProps {
  hasError: boolean;
  onTryAgainClick: () => void;
  onSeatErrorSkipClick: () => void;
  isMobile?: boolean;
}

export const SeatSelectionErrorModal = ({
  hasError,
  onTryAgainClick,
  onSeatErrorSkipClick,
  isMobile,
}: ISeatSelectionErrorModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(hasError);
  }, [hasError]);

  return isMobile ? (
    <MobilePopoverCard
      className="mobile-seat-selection-error-modal"
      open={hasError}
      topRightButton={
        <ActionLink
          className="seat-selection-error-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={onTryAgainClick}
        />
      }
    >
      <Box className="mobile-seat-selection-error-modal-content">
        <Icon className="error-icon" name={IconName.ErrorState} />
        <Typography variant="h2" className="error-title">
          {SEAT_SELECTION_ERROR_TITLE}
        </Typography>
        <Typography variant="body2" className="error-subtitle">
          {SEAT_SELECTION_ERROR_SUBTITLE}
        </Typography>
        <ActionButton
          message={TRY_AGAIN}
          onClick={onTryAgainClick}
          defaultStyle="h4r-primary"
        ></ActionButton>
        <ActionButton
          message={SKIP_SEAT_SELECTION}
          onClick={onSeatErrorSkipClick}
          defaultStyle="h4r-secondary"
        ></ActionButton>
      </Box>
    </MobilePopoverCard>
  ) : (
    <GenericInfoPopup
      buttons={[
        {
          buttonText: TRY_AGAIN,
          onClick: onTryAgainClick,

          defaultStyle: "h4r-primary",
        },
        {
          buttonText: SKIP_SEAT_SELECTION,
          onClick: onSeatErrorSkipClick,
          defaultStyle: "h4r-secondary",
        },
      ]}
      className="seat-selection-error-modal"
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      open={isOpen}
      subtitle={SEAT_SELECTION_ERROR_SUBTITLE}
      title={SEAT_SELECTION_ERROR_TITLE}
      isMobile={isMobile}
    />
  );
};
