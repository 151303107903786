import { takeEvery } from "redux-saga/effects";
import { fetchPriceFreezeOfferDataForFareSaga } from "./fetchPriceFreezeOfferDataForFareSaga";
import { actionTypes } from "../../../../shop/actions";

export function* watchFetchPriceFreezeOfferDataForFareSaga() {
  yield takeEvery(
    actionTypes.FETCH_PRICE_FREEZE_OFFER_FOR_FARE_DETAILS,
    fetchPriceFreezeOfferDataForFareSaga
  );
}
