import { connect } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { tripDetailsSelector } from "../../../../reducer/selectors";
import { MobileFlightDetailsModal } from "./component";

export interface IMobileFlightDetailsProps {
  tripId: string;
}

const mapStateToProps = (
  state: IStoreState,
  props: IMobileFlightDetailsProps
) => ({
  tripDetails: tripDetailsSelector(state, props.tripId),
});

const mapDispatchToProps = {};

export const ConnectedMobileFlightDetailsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFlightDetailsModal);
