import { connect, ConnectedProps } from "react-redux";
import { TodayTomorrowToggle } from "./component";
import { dateToggleFilterSelector } from "../../reducer/selectors";
import { setTodayTomorrowDateFilter } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    dateToggleFilter: dateToggleFilterSelector(state),
  };
};

const mapDispatchToProps = {
  setTodayTomorrowDateFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TodayTomorrowToggleConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedTodayTomorrowToggle = connector(TodayTomorrowToggle);
