import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceFreezeExplanationModal } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { populateFlightBookQueryParams } from "../../../../book/actions/actions";
import { setPriceFreezeEntryPointStep } from "../../../../freeze/actions/actions";
import { flightShopProgressSelector } from "../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    flightShopStep: flightShopProgressSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  setPriceFreezeEntryPointStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeExplanationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeExplanationModal = withRouter(
  connector(PriceFreezeExplanationModal)
);
