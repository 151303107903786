import React from "react";
import { CloseButtonIcon, ActionLink, MobilePopoverCard } from "halifax";
import { ITripTerminus, TripCategory } from "redmond";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import dayjs from "dayjs";
import { MobileCalendarPicker } from "../../../search/components/MobileFlightSearchControl/components";
import { MobileFlightSearchConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { FlightShopStep } from "../../reducer";

export interface IMobileFlightShopSearchPopoverProps
  extends MobileFlightSearchConnectorProps,
  RouteComponentProps {
  open: boolean;
  onClose: () => void;
}

export const MobileFlightShopSearchPopover = (
  props: IMobileFlightShopSearchPopoverProps
) => {
  const {
    origin,
    destination,
    originCode,
    destinationCode,
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    departureDate,
    returnDate,
    open,
    onClose,
    flightShopProgress,
    history,
    populateFlightShopQueryParams,
    setDepartureDate,
    setReturnDate
  } = props;

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const handleSearch = () => {
    populateFlightShopQueryParams({
      history,
      useHistoryPush:
        flightShopProgress === FlightShopStep.ChooseReturn ? false : true,
      forceQueryUpdate: false,
      newQueryParams: {
        flightShopProgress: FlightShopStep.ChooseDeparture,
      },
    });
    onClose();
  };

  React.useEffect(() => {
    if (originCode && destinationCode) {
      fetchDepartureCalendar(origin as ITripTerminus, destination as ITripTerminus);
    } else {
      setCalendar();
    }
  }, [originCode, destinationCode, tripCategory]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MobilePopoverCard
      open={open}
      onClose={onClose}
      fullScreen={true}
      className="mobile-flight-shop-search-popover"
      contentClassName="mobile-flight-shop-search-wrapper"
      topRightButton={
        <ActionLink
          className="mobile-flight-shop-search-header-close-button"
          onClick={onClose}
          content={<CloseButtonIcon />}
          label="Close"
        />
      }
    >
      <Box
        className={clsx("mobile-flight-shop-search-root", "date-range-picker")}
      >
        <HeaderCenterSection
          originCode={originCode}
          destinationCode={destinationCode}
          tripCategory={tripCategory}
        />
        <MobileCalendarPicker
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          focusedMonthIndex={focusedMonthIndex}
          returnDate={returnDate}
          departure={departureDate}
          isHistoryPushDisabled={true}
          onComplete={handleSearch}
        />
      </Box>
    </MobilePopoverCard>
  );
};

interface IHeaderCenterSectionProps {
  originCode?: string;
  destinationCode?: string;
  tripCategory: TripCategory;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { originCode, destinationCode } = props;
  return (
    <Box className="header-center-section">
      {originCode && destinationCode && (
        <Box className="trip-origin-destination-summary">
          <Box className="origin-destination-container">
            <Typography variant="body1">{textConstants.CHOOSE_DATE}</Typography>
          </Box>
        </Box>
      )}
      {(!originCode || !destinationCode) && (
        <Box className="mobile-flight-shop-search-location-label">
          <span>{textConstants.FLIGHTS_HEADER}</span>
        </Box>
      )}
    </Box>
  );
};
