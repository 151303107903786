import { CallState, DeleteWatchAlertRequest } from "redmond";
import { put, select } from "redux-saga/effects";

import { deleteWatch } from "../../../api/v0/price-watch/deleteWatch";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { alertKeySelector } from "../reducer/selectors";

export function* deleteWatchSaga() {
  try {
    const request: DeleteWatchAlertRequest = {
      key: yield select(alertKeySelector),
    };
    yield deleteWatch(request);
    yield put(actions.listWatches());
    yield put(actions.setDeleteWatchCallState(CallState.Success));
  } catch (e) {
    yield put(actions.setDeleteWatchCallState(CallState.Failed));
    Logger.debug(e);
  }
}
