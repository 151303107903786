import dayjs from "dayjs";

export const getSummaryPanelHeader = (
  isDeparture: boolean,
  location: string,
  date: string,
  parsable = false
) =>
  `${isDeparture ? "Outbound" : "Return"} to ${location} ${
    parsable ? ":" : ""
  } on ${dayjs(date).format("ddd, MMM D")}`;

export const CHANGE_FLIGHT_TEXT = "Change Flight";
