import { all, call, put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { deleteUserPassenger } from "../../../../api/v0/book/user-passengers/deleteUserPassenger";
import {
  getUserSelectedPassengerIds,
  getUserSelectedLapInfantIds,
} from "../../reducer";
import {
  CallState,
  CorpSessionInfo,
  ICorpPerson,
  IPerson,
  SessionInfo,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  IListUserPassengersResponse,
  fetchUserPassengers,
} from "../../../../api/v0/book/user-passengers/fetchUserPassengers";
import { getViewedTripSummaryProperties } from "../../../shop/reducer";
import { isCaponeTenant } from "@capone/common";
import { buildCorpTravelers } from "b2b-base/src/utils/capone-corporate/travelersMappingUtils";
import fetchUserInfo from "../../../../api/v0/user/fetchUserInfo";
import fetchCorpUsersList, {
  CorpUsersListResponse,
} from "b2b-base/src/api/v1/user/fetchCorpUsersList";
import { config } from "../../../../api/config";

export function* deleteUserPassengerSaga(action: actions.IDeleteUserPassenger) {
  try {
    const state: IStoreState = yield select();

    yield deleteUserPassenger(action.payload);

    const selectedPassengerIds = getUserSelectedPassengerIds(state);
    const selectedLapInfantIds = getUserSelectedLapInfantIds(state);
    const viewedTripSummaryProperties = getViewedTripSummaryProperties(state);
    if (selectedPassengerIds.includes(action.payload.personId)) {
      const newSelectedPassengerIds = selectedPassengerIds.filter(
        (id) => id !== action.payload.personId
      );
      yield put(
        actions.setUserSelectedPassengerIds({
          userSelectedPassengerIds: newSelectedPassengerIds,
          multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
        })
      );
    } else if (selectedLapInfantIds.includes(action.payload.personId)) {
      const newSelectedLapInfantIds = selectedLapInfantIds.filter(
        (id) => id !== action.payload.personId
      );

      yield put(
        actions.setUserSelectedLapInfantIds({
          userSelectedLapInfantIds: newSelectedLapInfantIds,
        })
      );
    }

    const sessionInfo: SessionInfo = yield call(fetchUserInfo);
    const canViewUsers =
      "corporateInfo" in sessionInfo
        ? (sessionInfo as CorpSessionInfo).corporateInfo.permissions
            .canViewUsers
        : false;

    const travelerDefaultTravelerId =
      ("corporateInfo" in sessionInfo &&
        (sessionInfo as CorpSessionInfo).corporateInfo.defaultTravelerId) ??
      "";

    let userPassengers: IPerson[] | ICorpPerson[];
    if (isCaponeTenant(config.TENANT)) {
      userPassengers = yield fetchUserPassengers({});
    } else {
      const [corpUsersList, caponeUserPassengers]: [
        CorpUsersListResponse,
        IListUserPassengersResponse
      ] = yield all([
        canViewUsers
          ? call(fetchCorpUsersList)
          : {
              users: [
                {
                  id: sessionInfo.userId,
                  firstName: sessionInfo.userInfo.firstName,
                  lastName: sessionInfo.userInfo.lastName,
                  defaultTravelerId: travelerDefaultTravelerId,
                },
              ],
            },
        call(fetchUserPassengers, {}),
      ]);

      const hiddenTravelerIds =
        "corporateInfo" in sessionInfo
          ? (sessionInfo as CorpSessionInfo).corporateInfo.hiddenTravelerIds
          : [];

      const loggedInUserId = sessionInfo.userId;

      userPassengers = buildCorpTravelers(
        caponeUserPassengers,
        corpUsersList.users,
        hiddenTravelerIds,
        loggedInUserId
      );
    }

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
