import React, { useContext, useEffect, useState } from "react";
import { PostBookingViewOfferConnectorProps } from "./container";
import { Redirect, RouteComponentProps } from "react-router-dom";
import "./styles.scss";
import {
  AncillaryPostBookingKindEnum,
  getPostBookingScreen,
  CallState,
  postBookingViewCheckoutScreenProperties,
  postBookingPurchaseCompleteProperties,
} from "redmond";
import { ITINERARY_ID_QUERY_PARAM, PATH_FLIGHTS } from "../../constants";

import { PostBookingConfirmAndBook } from "../PostBookingConfirmAndBook/container";
import { PostBookingConfirmation } from "../PostBookingConfirmation/container";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  B2BSpinner,
  DesktopPopupModal,
  LoadingPopup,
  useDeviceTypes,
} from "halifax";
import * as textConstants from "./constants";
import { ClientContext } from "../../../../App";
import { FAQ_URL } from "../../../../utils/urlPaths";
import { SupportModalContent } from "./SupportModal";
import clsx from "clsx";
import dayjs from "dayjs";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IPostBookingViewOfferConnectorPropsProps
  extends PostBookingViewOfferConnectorProps,
    RouteComponentProps {}

export const AncillaryPostBookingViewOffer = (
  props: IPostBookingViewOfferConnectorPropsProps
) => {
  const {
    fetchPostBookingOffer,
    resetFetchAncillaryPostBookingOfferState,
    postBookingOfferState,
    history,
    isPostBookingOffersAvailable,
    listPaymentMethods,
    listPaymentMethodCallState,
  } = props;

  useEffect(() => {
    if (listPaymentMethodCallState === CallState.NotCalled) {
      listPaymentMethods();
    }
  }, []);

  const clientContext = useContext(ClientContext);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [purchaseCompleteEvent, setPurchaseCompleteEvent] = useState(false);
  const [viewCheckScreenEvent, setViewCheckScreenEvent] = useState(false);
  const [offerAttempted, setOfferAttempted] = useState(false);
  const { matchesMobile } = useDeviceTypes();

  const referenceScreen = getPostBookingScreen(history.location.search);

  useEffect(() => {
    if (purchaseCompleteEvent) return;
    if (
      postBookingOfferState.state != AncillaryPostBookingKindEnum.Confirmation
    )
      return;
    setPurchaseCompleteEvent(true);
    trackEvent(
      postBookingPurchaseCompleteProperties(
        referenceScreen,
        postBookingOfferState.response.trackingProperties
      )
    );
  }, [postBookingOfferState.state]);

  useEffect(() => {
    if (viewCheckScreenEvent) return;
    if (postBookingOfferState.state != AncillaryPostBookingKindEnum.ValidOffer)
      return;
    setViewCheckScreenEvent(true);
    trackEvent(
      postBookingViewCheckoutScreenProperties(
        referenceScreen,
        postBookingOfferState.offer.choices[0].policyDetails.trackingProperties
      )
    );
  }, [postBookingOfferState.state]);

  const handleSupportModalClose = () => setOpenContactModal(false);

  const itineraryId = new URLSearchParams(history.location.search).get(
    ITINERARY_ID_QUERY_PARAM
  );

  useEffect(() => {
    if (
      postBookingOfferState.state === AncillaryPostBookingKindEnum.FetchOffer
    ) {
      setOfferAttempted(true);
    }
  }, [postBookingOfferState.state]);

  useEffect(() => {
    if (!isPostBookingOffersAvailable) return;
    function fetchOffer() {
      const args = itineraryId
        ? {
            request: {
              itineraryId: itineraryId,
              referenceDateTime: dayjs().format(),
            },
          }
        : undefined;
      fetchPostBookingOffer(args);
    }
    if (postBookingOfferState.state == AncillaryPostBookingKindEnum.NotCalled) {
      fetchOffer();
    } else if (
      postBookingOfferState.state == AncillaryPostBookingKindEnum.ValidOffer &&
      postBookingOfferState.offer.itineraryId != itineraryId
    ) {
      resetFetchAncillaryPostBookingOfferState();
      fetchOffer();
    }
  }, [isPostBookingOffersAvailable]);

  if (!isPostBookingOffersAvailable) {
    return <></>;
  }

  function redirectToFlights() {
    return <Redirect to={PATH_FLIGHTS} />;
  }

  function header() {
    return (
      <Box className="post-booking-offer-header-container">
        <Box
          className="logo-container"
          onClick={() => {
            history.push({ pathname: PATH_FLIGHTS });
          }}
        >
          {clientContext.logo}
        </Box>
        <Box className="fda-container">
          <Typography className="title" variant="body1">
            {textConstants.FLIGHT_DISRUPTION_ASSISTANCE}
          </Typography>
          <Typography className="subheader" variant="body1">
            {textConstants.GET_HELP_WITH_YOUR_TRIP}
          </Typography>
        </Box>
        <div className="flex"></div>
        <Typography className="questions" variant="body1">
          {textConstants.QUESTIONS_ABOUT_YOUR_TRIP}
        </Typography>
        <ActionLink
          className="faq-cta"
          content={textConstants.VIEW_FAQS}
          onClick={() => {
            window.open(FAQ_URL, "_blank")?.focus();
          }}
        />
        <ActionButton
          defaultStyle="h4r-secondary"
          onClick={() => {
            setOpenContactModal(true);
          }}
          message={textConstants.CONTACT_SUPPORT}
          className="button-contact-support"
        />

        <DesktopPopupModal
          open={openContactModal}
          className="desktop-contact-support-popup"
          onClose={handleSupportModalClose}
          invisibleBackdrop={false}
        >
          <Box className="desktop-contact-support-popup-content">
            <SupportModalContent />
          </Box>
        </DesktopPopupModal>
      </Box>
    );
  }

  function loading() {
    return (
      <LoadingPopup
        indicatorSize={"small"}
        indicator={B2BSpinner}
        open={true}
        message={"Loading Offer"}
        classes={[
          "post-booking-view-offer-loading-modal",
          ...(matchesMobile ? ["mobile"] : []),
        ]}
      />
    );
  }

  function body() {
    switch (postBookingOfferState.state) {
      case AncillaryPostBookingKindEnum.ValidOffer:
        return <PostBookingConfirmAndBook validOffer={postBookingOfferState} />;
      case AncillaryPostBookingKindEnum.Confirmation:
        return (
          <PostBookingConfirmation offerPurchased={postBookingOfferState} />
        );
      case AncillaryPostBookingKindEnum.FetchOffer:
        return loading();
      case AncillaryPostBookingKindEnum.NotCalled:
        if (offerAttempted) {
          return redirectToFlights();
        }
        return loading();
      case AncillaryPostBookingKindEnum.NoOffer:
        return redirectToFlights();
    }
  }

  return (
    <Box
      className={clsx("post-booking-view-offer-root", {
        "pbv-mobile-root": matchesMobile,
      })}
    >
      {!matchesMobile && header()}
      {body()}
      {listPaymentMethodCallState === CallState.InProcess && loading()}
    </Box>
  );
};
