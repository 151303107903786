import { connect, ConnectedProps } from "react-redux";
import { IPassengerCounts } from "redmond";
import { IStoreState } from "../../../../../../reducers/types";
import {
  setPassengerCounts,
  setMulticityOrigin,
  setMulticityDestination,
  setMulticityDepartureDate,
  addMulticityRoute,
  removeMulticityRoute,
  fetchDepartureCalendar,
  setCalendar,
  setStopsOption,
  setFareclassOptionFilter,
} from "../../../../actions/actions";
import {
  getDestination,
  getOrigin,
  getNumTravelers,
  getDestinationCategoriesLoading,
  getOriginCategoriesLoading,
  getOriginCategories,
  getDestinationCategories,
  getHasOriginAutocompleteError,
  getHasDestinationAutocompleteError,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getMulticityRoutes,
  getTripCategory,
  getStopsOption,
  getFareclassOptionFilter,
} from "../../../../reducer";
import { LocationSearch } from "./component";

const mapDispatchToProps = {
  setPassengerCounts,
  setMulticityOrigin,
  setMulticityDestination,
  setMulticityDepartureDate,
  addMulticityRoute,
  removeMulticityRoute,
  fetchDepartureCalendar,
  setCalendar,
  setStopsOption,
  setFareclassOptionFilter,
};

const mapStateToProps = (state: IStoreState) => {
  const origin = getOrigin(state);
  const destination = getDestination(state);
  const counts: IPassengerCounts = {
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
  };
  return {
    origin,
    destination,
    tripCategory: getTripCategory(state),
    multicityRoutes: getMulticityRoutes(state),
    numTravelers: getNumTravelers(state),
    originCategories: getOriginCategories(state),
    destinationCategories: getDestinationCategories(state),
    loading:
      !!getOriginCategoriesLoading(state) ||
      !!getDestinationCategoriesLoading(state),
    hasOriginAutocompleteError: getHasOriginAutocompleteError(state),
    hasDestinationAutocompleteError: getHasDestinationAutocompleteError(state),
    counts,
    stopsOption: getStopsOption(state),
    fareclassOptionsFilter: getFareclassOptionFilter(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedLocationSearch = connector(LocationSearch);
