export const eventsToListen = {
  HOST_THEME_CHANGED: "HOST_THEME_CHANGED",
  HOST_LOCALE_CHANGED: "HOST_LOCALE_CHANGED",
  HOST_ROUTE_CHANGED: "ROUTE_CHANGED",
};

export interface RouteChangeEventData {
  referrer: string;
  destination: string;
}

export const eventsToDispatch = {
  ROUTE_CHANGED: "ROUTE_CHANGED",
};

const dispatchEvent = (eventName: string, data: any) =>
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }));

export default dispatchEvent;
