import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { cfarOfferBatchPath } from "../paths";
import {
  BatchFetchCfarOfferRequestV2,
  BatchFetchCfarOfferResponseV2,
} from "redmond";

export const getBatchCfarOffers = (
  body: BatchFetchCfarOfferRequestV2
): Promise<BatchFetchCfarOfferResponseV2> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<BatchFetchCfarOfferResponseV2> = await axios.post(cfarOfferBatchPath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getBatchCfarOffers;
