import React from "react";
import "./styles.scss";
import { Icon, IconName, RadioDropdown } from "halifax";
import clsx from "clsx";
import { FlightSortOption, ISortOptions } from "redmond";
import { Box } from "@material-ui/core";
import { defaultSortOptionsToDisplay } from "../../../../../../utils/sortAndFilter";

export interface ISortOptionSelectionProps {
  sortOption: FlightSortOption;
  setSortOption: (option: FlightSortOption) => void;
  showDropdownContentOnly?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  icon?: IconName;
  title?: string;
  radioLabelPlacement?: "start" | "end";
  sortOptions?: ISortOptions[];
}
export const SortOptionSelection = (props: ISortOptionSelectionProps) => {
  const {
    sortOption,
    setSortOption,
    showDropdownContentOnly,
    icon,
    title,
    isFlightListOptimizationExperiment = false,
    radioLabelPlacement,
    sortOptions = defaultSortOptionsToDisplay,
  } = props;

  const getDropdownLabel = () => {
    const label = sortOptions.find(
      (option) => option.value === sortOption
    )?.label;
    const [shortenedLabel] = label?.split(" ") || [];

    if (isFlightListOptimizationExperiment) {
      return (
        <>
          <strong>Sort:</strong> {shortenedLabel}
        </>
      );
    }

    return shortenedLabel;
  };

  return (
    <Box className="flight-shop-sort-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />} {title}
        </Box>
      )}
      <RadioDropdown
        buttonClassName={clsx(
          "desktop-availability-sort-button",
          "b2b-shop-filter",
          "flight-availability-sort-button",
          {
            "filter-experiment": isFlightListOptimizationExperiment,
          }
        )}
        dropdownLabel={getDropdownLabel()}
        options={sortOptions.map((option) => {
          return {
            value: option.value,
            label: option.label,
          };
        })}
        setOption={(option) => setSortOption(option as FlightSortOption)}
        selected={sortOption}
        groupAriaLabel={"Choose a Sort Option"}
        buttonAriaLabel={"Change Sort Option"}
        popoverClassName={clsx("b2b", "flight-availability-sort-popover", {
          "filter-experiment": isFlightListOptimizationExperiment,
        })}
        showDropdownContentOnly={showDropdownContentOnly}
        dropdownIcon={
          isFlightListOptimizationExperiment ? (
            <Icon name={IconName.Sort} />
          ) : undefined
        }
        anchorOrigin={
          isFlightListOptimizationExperiment
            ? {
                vertical: "bottom",
                horizontal: "left",
              }
            : undefined
        }
        transformOrigin={
          isFlightListOptimizationExperiment
            ? {
                vertical: "top",
                horizontal: "left",
              }
            : undefined
        }
        radioLabelPlacement={radioLabelPlacement}
      />
    </Box>
  );
};
