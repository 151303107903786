import dayjs from "dayjs";
import { FareDetails, TripDetails, TripSlice, FareSliceDetails } from "redmond";

export const getSliceIndex = (
  departure: boolean,
  details: TripDetails | FareDetails
): number => {
  return details.slices.findIndex((slice: TripSlice | FareSliceDetails) =>
    departure ? slice.outgoing : !slice.outgoing
  );
};

export const getMulticityParamsFromTripDetails = (tripDetails: TripDetails) => {
  return tripDetails.slices.reduce((acc, slice, idx) => {
    const { originCode, destinationCode, departureTime } = slice;
    acc[`origin${idx}`] = originCode;
    acc[`destination${idx}`] = destinationCode;
    acc[`departureDate${idx}`] = dayjs(departureTime).format("YYYY-MM-DD");
    return acc;
  }, {});
};
