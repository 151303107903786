import {
  DISRUPTION_ITINERARY_ID_QUERY_PARAM,
  DisruptionProtectionQueryParam,
} from "redmond";
import { History } from "history";
import queryStringParser from "query-string";
import {
  PATH_TRIPS,
  PATH_DISRUPTION_PROTECTION_OVERVIEW,
  PATH_DISRUPTION_PROTECTION_REFUND,
} from "../../../utils/urlPaths";

export const pushToDisruptionOverview = (props: {
  history: History;
  page?: "overview" | "refund";
}) => {
  const { history, page } = props;
  const queryString = history?.location?.search || "";
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  const itineraryId =
    parsedQueryStringPrimitive[DisruptionProtectionQueryParam.ItineraryId];

  const params = {
    [DISRUPTION_ITINERARY_ID_QUERY_PARAM]: itineraryId,
  };
  const pathname = (() => {
    switch (page) {
      case "refund":
        return PATH_DISRUPTION_PROTECTION_REFUND;
      default:
        return PATH_DISRUPTION_PROTECTION_OVERVIEW;
    }
  })();

  history.push({
    pathname: pathname,
    search: queryStringParser.stringify(params),
  });
};

export const pushToMyTrips = (props: {
  history: History;
  itineraryId?: string;
}) => {
  const { history, itineraryId } = props;

  const params = {
    // TODO: in my-trips, the query param for the itinerary id is incorrectly named
    tripId: itineraryId,
  };

  history.push({
    pathname: PATH_TRIPS,
    search: queryStringParser.stringify(params),
  });
};
