import { put, delay, select, putResolve } from "redux-saga/effects";
import {
  CallState,
  ErrorModalType,
  StartFareQuoteResult,
  PollFareQuoteResult,
  PollFareQuoteResultEnum,
  PollFareQuoteResultSuccess,
  PollFareQuoteOutcomeEnum,
  FareQuoteBookingErrorEnum,
  NoQuote,
  BrooklynTokenKey,
} from "redmond";

import Logger from "../../../helpers/Logger";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import { getSchedulePaymentCallState } from "../../book/reducer/selectors";
import { startFareQuote } from "../../../api/v0/price-freeze/startFareQuote";
import { scheduleFareQuote } from "../../../api/v0/price-freeze/scheduleFareQuote";
import { pollFareQuote } from "../../../api/v0/price-freeze/pollFareQuote";
import { selectFareDetailsSinglePassenger } from "../reducer";

/*
  Note: The delay times below should not be reduced. Doing so can increase OTM for price freeze.

  The delay times below correlate to how long we want to wait for the quote to finish before allowing the purchase on the shop price. 
  Once this process finishes, with either the successful quote price OR the fallback shop price, 
  we set it into state which is then read by the scheduleQuoteSaga. The scheduleQuoteSaga checks for an outcome every 1 sec.
*/
export const delayTimes = [2000, 4000, 6000, 8000, 10000, 20000, 30000, 30000];

export function* fetchPriceFreezeFareQuoteSaga(
  action: actions.IFetchPriceFreezeFareQuote
) {
  try {
    let totalDelayTime = delayTimes.reduce<number>(
      (sum, current) => sum + current,
      0
    );
    const { passengerCountByType, tripId, fareId } = action;
    const { tokenKey }: StartFareQuoteResult = yield startFareQuote();

    yield scheduleFareQuote({
      tokenKey,
      passengerCountByType,
      tripId,
      fareId,
    });

    let pollTerminated = false;
    let fareQuote: PollFareQuoteResultSuccess | null = null;

    while (!pollTerminated) {
      yield delay(1000);

      const response: PollFareQuoteResult = yield pollFareQuote({
        tokenKey,
      });

      switch (response.PollFareQuoteResult) {
        case PollFareQuoteResultEnum.Error: {
          pollTerminated = true;
          break;
        }
        case PollFareQuoteResultEnum.Success: {
          pollTerminated = true;
          fareQuote = response;
          break;
        }
        case PollFareQuoteResultEnum.Pending:
        default:
          break;
      }

      if (totalDelayTime <= 0) {
        Logger.debug("Price Freeze PollFareQuote Exceeded all delay times");
        pollTerminated = true;
      }
      totalDelayTime -= 1000;
    }

    let newOutcome;
    let newTokenKey;
    let brooklynTokenKey: BrooklynTokenKey;
    let skipQuoteOutcome: NoQuote;
    let skipQuote: boolean;

    if (!!action.isInPurchaseOnQuoteOnlyXp) {
      if (!fareQuote) {
        Logger.debug(
          `Price freeze fare quote failed due to no fareQuote returned. Token: ${tokenKey}`
        );
        yield put(actions.setPriceFreezeFareQuoteCallStateFailed());
        return;
      } else {
        skipQuote = false;
        newOutcome = fareQuote.outcome;
        newTokenKey = fareQuote.tokenKey;
      }
    } else {
      if (!fareQuote) {
        Logger.debug(
          `Price Freeze PollFareQuote Did not complete. Allowing purchase with NoQuote. Token: ${tokenKey}`
        );
        brooklynTokenKey = {
          userId: tokenKey.userId,
          client: tokenKey.sessionId,
        };
        skipQuoteOutcome = {
          tokenKey: brooklynTokenKey,
          errors: [],
          Outcome: PollFareQuoteOutcomeEnum.NoQuote,
        };
        skipQuote = true;
        newOutcome = skipQuoteOutcome;
        newTokenKey = tokenKey;
      } else {
        skipQuote = false;
        newOutcome = fareQuote.outcome;
        newTokenKey = fareQuote.tokenKey;
      }
    }

    if (!skipQuote) {
      switch (newOutcome.Outcome) {
        case PollFareQuoteOutcomeEnum.Quoted:
          const state: IStoreState = yield select();

          // Since we're running a new polling every time the customer loads this page, we can use the fare details as the price to compare the real quote to
          const fareDetailsSinglePassengerTotal =
            selectFareDetailsSinglePassenger(state)?.fiat.value;

          const newSinglePassengerTotal =
            newOutcome.tripPricing.pricingByPassenger[0]?.total.fiat.value;

          const isSchedulePaymentInProgress =
            getSchedulePaymentCallState(state) === CallState.InProcess;

          if (
            fareDetailsSinglePassengerTotal !== undefined &&
            fareDetailsSinglePassengerTotal !== newSinglePassengerTotal
          ) {
            const errorModalType = (() => {
              if (isSchedulePaymentInProgress) {
                if (fareDetailsSinglePassengerTotal > newSinglePassengerTotal) {
                  return ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED_DURING_PAYMENT;
                } else {
                  return ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED_DURING_PAYMENT;
                }
              } else {
                if (fareDetailsSinglePassengerTotal > newSinglePassengerTotal) {
                  return ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED;
                } else {
                  return ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED;
                }
              }
            })();

            yield putResolve(
              actions.setPriceFreezeFareQuoteErrorTitlesType(errorModalType)
            );
          }
          break;
        case PollFareQuoteOutcomeEnum.NoQuote:
          const error = newOutcome.errors[0];

          if (
            error?.BookingError === FareQuoteBookingErrorEnum.NoAvailability ||
            (error?.BookingError === FareQuoteBookingErrorEnum.ErrorCode &&
              error.code === FareQuoteBookingErrorEnum.NoAvailability)
          ) {
            Logger.debug(
              `Air PF Purchase blocked. PollFareQuote did not complete due to NoAvailability`
            );
            yield putResolve(
              actions.setPriceFreezeFareQuoteErrorTitlesType(
                ErrorModalType.PRICE_FREEZE_PURCHASE_HAS_NO_AVAILABILITY
              )
            );
          } else {
            yield putResolve(
              actions.setPriceFreezeFareQuoteErrorTitlesType(null)
            );
          }
          break;
        default:
          yield putResolve(
            actions.setPriceFreezeFareQuoteErrorTitlesType(null)
          );
          break;
      }
    }

    yield put(
      actions.setPriceFreezeFareQuote({
        priceFreezeFareQuoteTokenKey: newTokenKey,
        priceFreezeFareQuoteOutcome: newOutcome,
        shouldIgnoreQuote: skipQuote,
      })
    );
  } catch (e) {
    yield put(actions.setPriceFreezeFareQuoteCallStateFailed());
    Logger.debug(`Price freeze fare quote failed: ${e}`);
  }
}
