import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import * as H from "history";
import { IPriceFreezeOfferEntries } from "halifax";
import {
  Airport,
  Cap,
  FiatPrice,
  Prediction,
  PriceDropViewedProperties,
  RewardsAccount,
  RewardsPrice,
  TripCategory,
  ViewedFlightListProperties,
  ViewedPriceFreezeProperties,
  TimeToLive,
  TripDetails,
  FlightShopType,
  IFlightListData,
} from "redmond";
import { isCorpTenant } from "@capone/common";
import {
  allTripSummariesSelector,
  getPriceFreezeOffer,
  getPriceFreezeOfferCap,
  getPriceFreezeOfferFiat,
  getPriceFreezeOfferRewards,
  getViewedFlightListProperties,
  isWatchingSelector,
  maxFlightPriceSelector,
  predictionSelector,
  refreshPredictionSelector,
  getPriceFreezeOfferCheapestTripFareId,
  getPriceFreezeOfferCheapestTripTripId,
  getPriceFreezeOfferDuration,
  airportsSelector,
  getSortedAndFilteredFlights,
  predictionLoadingSelector,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import {
  getActiveFiltersCount,
  getHasSetFareClassFilter,
  getHasSetStopsOption,
  getTripCategory,
  hasUnsupportedPredictionFilters,
  initialFilterOptions,
} from "../../../search/reducer";
import { actions } from "../../../search/actions";
import { actions as shopActions } from "../../actions";
import {
  IDeleteWatch,
  IRerunPrediction,
  ISetFlightShopProgress,
  setFlightShopProgress,
  ISetOpenFlightShopCalendarMobile,
} from "../../actions/actions";
import {
  FlightShopStep,
  ITripSummariesById,
  getViewedPriceFreezeProperties,
  getPriceFreezeCheapestFrozenPrice,
  tripDetailsSelector,
  isPriceFreezeOfferIncludedInShopSummarySelector,
  tripDetailsLoadingSelector,
  getPriceDropProperties,
  flightShopTypeSelector,
} from "../../reducer";
import {
  getRewardsAccounts,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import {
  fetchRewardsAccounts,
  IFetchRewardsAccounts,
} from "../../../rewards/actions/actions";
import { MobilePricePredictionSection } from "./MobilePricePredictionSection";
import { pricePredictionGradientVariantSelector } from "../../reducer/selectors/experiments";
import { PricePredictionGradientVariantType } from "../../../../context/experiments";
import { config } from "../../../../api/config";

const mapStateToProps = (state: IStoreState): IStateProps => {
  const tripDetails = tripDetailsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );

  const airports = airportsSelector(
    state,
    getPriceFreezeOfferCheapestTripTripId(state)
  );

  return {
    refreshPrediction: refreshPredictionSelector(state),
    prediction: predictionSelector(state),
    tripCategory: getTripCategory(state),
    hasUnsupportedPredictionFilters: hasUnsupportedPredictionFilters(state),
    data: {
      maxFlightPrice: maxFlightPriceSelector(state),
    },
    isWatching: isWatchingSelector(state),
    hasFilters: getHasSetStopsOption(state) || getHasSetFareClassFilter(state),
    tripSummaries: allTripSummariesSelector(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    viewedForecastProperties: getViewedFlightListProperties(state),
    priceDropViewedProperties: getPriceDropProperties(state),
    priceFreezeOffer: getPriceFreezeOffer(state),
    priceFreezeFiat: getPriceFreezeOfferFiat(state),
    priceFreezeRewards: getPriceFreezeOfferRewards(state),
    selectedRewardsAccountId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    priceFreezeCap: getPriceFreezeOfferCap(state),
    fareId: getPriceFreezeOfferCheapestTripFareId(state),
    priceFreezeDuration: getPriceFreezeOfferDuration(state),
    viewedPriceFreezeProperties: getViewedPriceFreezeProperties(state),
    tripDetails,
    airports,
    cheapestFrozenPrice: getPriceFreezeCheapestFrozenPrice(state),
    rewardsAccounts: getRewardsAccounts(state),
    isPriceFreezeOfferIncludedInShopSummary:
      isPriceFreezeOfferIncludedInShopSummarySelector(state),
    isTripDetailsLoading: tripDetailsLoadingSelector(state),
    flightShopType: flightShopTypeSelector(state),
    flightList: getSortedAndFilteredFlights(state),
    pricePredictionGradientVariant:
      pricePredictionGradientVariantSelector(state),
    predictionLoading: predictionLoadingSelector(state),
    hasActiveFilters: getActiveFiltersCount(state) > 0,
    canRedeemRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
  };
};

interface IStateProps {
  rewardsAccounts: RewardsAccount[];
  data: { maxFlightPrice: number };
  prediction: Prediction | null;
  tripCategory: TripCategory;
  hasUnsupportedPredictionFilters: boolean;
  refreshPrediction: boolean;
  isWatching: boolean;
  hasFilters: boolean;
  rewardsKey: string | undefined;
  tripSummaries: ITripSummariesById;
  viewedForecastProperties: ViewedFlightListProperties | null;
  priceDropViewedProperties: Omit<PriceDropViewedProperties, "page">;
  priceFreezeOffer: IPriceFreezeOfferEntries | null;
  priceFreezeFiat: FiatPrice | undefined;
  selectedRewardsAccountId: string | undefined;
  priceFreezeCap: Cap | undefined;
  priceFreezeRewards: { [key: string]: RewardsPrice } | null | undefined;
  fareId: string;
  tripDetails: TripDetails | undefined;
  priceFreezeDuration: TimeToLive | undefined;
  viewedPriceFreezeProperties: ViewedPriceFreezeProperties | null;
  airports: { [key: string]: Airport };
  cheapestFrozenPrice:
    | { fiat: FiatPrice; rewards: RewardsPrice | undefined }
    | undefined;
  isPriceFreezeOfferIncludedInShopSummary: boolean;
  isTripDetailsLoading: boolean | null;
  flightShopType: FlightShopType;
  flightList: IFlightListData[];
  pricePredictionGradientVariant:
    | PricePredictionGradientVariantType
    | undefined;
  predictionLoading: boolean | null;
  hasActiveFilters: boolean;
  canRedeemRewards: boolean;
}

interface IDispatchProps {
  fetchTripSummaries: any;
  setFlightShopProgress: (step: FlightShopStep) => ISetFlightShopProgress;
  resetFilters: () => void;
  deleteWatch: () => IDeleteWatch;
  rerunPrediction: () => IRerunPrediction;
  setOpenFlightShopCalendarMobile: (
    openFlightShopCalendarMobile: boolean
  ) => ISetOpenFlightShopCalendarMobile;
  fetchRewardsAccounts: () => IFetchRewardsAccounts;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchRewardsAccounts: () => dispatch(fetchRewardsAccounts()),
  deleteWatch: () => dispatch(shopActions.deleteWatch()),
  setFlightShopProgress: (step: FlightShopStep) =>
    dispatch(setFlightShopProgress(step)),
  fetchTripSummaries: (history: any, isMobile?: boolean) =>
    dispatch(shopActions.fetchTripSummaries(history, isMobile)),
  resetFilters: () => {
    dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
    dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(actions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(
      actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
    );
    dispatch(
      actions.setOutboundArrivalTimeRange(
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
    dispatch(
      actions.setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnDepartureTimeRange(
        initialFilterOptions.returnDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnArrivalTimeRange(
        initialFilterOptions.returnArrivalTimeRange
      )
    );
  },
  rerunPrediction: () => dispatch(shopActions.setRerunPrediction()),
  setOpenFlightShopCalendarMobile: (openFlightShopCalendarMobile: boolean) =>
    dispatch(
      shopActions.setOpenFlightShopCalendarMobile(openFlightShopCalendarMobile)
    ),
});

interface OwnProps {
  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
  history: H.History;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: OwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    resetFilters: () => dispatchProps.resetFilters(),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type MobilePricePredictionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePriceSectionPrediction = connector(
  MobilePricePredictionSection
);
