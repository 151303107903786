import {
  CallState,
  GetSimilarFlightsResponse,
  PriceFreezeView,
  TripSummaryContext,
  TripCategory,
  FrozenPricePaxPricing,
} from "redmond";
import { put, putResolve, select, take } from "redux-saga/effects";
import dayjs from "dayjs";
import { getSimilarFlights } from "../../../api/v0/price-freeze/getSimilarFlights";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { actions as searchActions } from "../../search/actions";
import { actions as freezeActions } from "../../freeze/actions";
import {
  SET_PRICE_FREEZE,
  SET_PRICE_FREEZE_CALL_STATE_FAILED,
} from "../../freeze/actions/constants";
import {
  currentPriceFreezeSelector,
  currentPriceFreezeTripContextSelector,
} from "../../freeze/reducer/selectors";
import { IFetchSimilarFlights } from "../actions/actions";
import { fetchOriginDestination } from "./utils/setUpFlightShopParams";
import { PATH_HOME } from "../../../utils/urlPaths";
import { IStoreState } from "../../../reducers/types";
import { populateFlightShopQueryParametersFromState } from "./populateShopQueryParamsSaga";

export function* fetchSimilarFlightsSaga(action: IFetchSimilarFlights) {
  const { id, history, refreshPriceFreeze } = action;
  try {
    const state: IStoreState = yield select();
    if (history) {
      yield populateFlightShopQueryParametersFromState({
        state,
        history,
      });
    }

    if (refreshPriceFreeze) {
      yield put(freezeActions.fetchPriceFreeze(id));
      // note: wait for fetchPriceFreeze to finish
      yield take([SET_PRICE_FREEZE, SET_PRICE_FREEZE_CALL_STATE_FAILED]);
    }

    const priceFreeze: PriceFreezeView | undefined = yield select(
      currentPriceFreezeSelector
    );
    const tripContext: TripSummaryContext | undefined = yield select(
      currentPriceFreezeTripContextSelector
    );
    if ((!priceFreeze || !tripContext) && history) {
      history.push(PATH_HOME);
      return;
    }

    // note: populate flight search params based on the priceFreeze object
    const tripCategory =
      priceFreeze!.frozenFlight.slices.length > 1
        ? TripCategory.ROUND_TRIP
        : TripCategory.ONE_WAY;
    const departureSlice = priceFreeze!.frozenFlight.slices[0];
    const originCode = departureSlice.originCode;
    const destinationCode = departureSlice.destinationCode;

    const { correspondingDestination, correspondingOrigin } =
      yield fetchOriginDestination(originCode, destinationCode);
    yield put(searchActions.setOrigin(correspondingOrigin));
    yield put(searchActions.setDestination(correspondingDestination));
    yield put(
      searchActions.setDepartureDate(
        dayjs(departureSlice.departureTime).toDate()
      )
    );
    yield put(searchActions.setTripCategory(tripCategory));
    if (tripCategory === TripCategory.ROUND_TRIP) {
      const returnSlice = priceFreeze!.frozenFlight.slices[1];
      yield put(
        searchActions.setReturnDate(dayjs(returnSlice.departureTime).toDate())
      );
    }

    const passengers = priceFreeze?.frozenFare.paxPricings;
    if (!passengers) {
      yield put(actions.setTripSummariesError());
      yield put(actions.setFetchSimilarFlightsCallStateFailed());
      return;
    }

    const response: GetSimilarFlightsResponse = yield getSimilarFlights({
      id,
      passengers: getPassengerCounts(passengers),
    });

    yield putResolve(actions.setTripSummaries(response.trips));
    yield putResolve(
      actions.setSimilarFlightsResponse({
        response: {
          prices: response.prices,
        },
        callState: CallState.Success,
      })
    );

    yield put(searchActions.setAwaitingRefetch(false));
  } catch (e) {
    yield put(actions.setTripSummariesError());
    yield put(actions.setFetchSimilarFlightsCallStateFailed());
    Logger.debug(e);
  }
}

const getPassengerCounts = (
  passengers: FrozenPricePaxPricing[]
): { [key: string]: number } => {
  let result = {};

  passengers.forEach((passenger) => {
    const currentType = passenger.paxType;

    if (!result[currentType]) {
      result = {
        ...result,
        [currentType]: passenger.quantity,
      };
    } else {
      result = {
        ...result,
        [currentType]: result[currentType] + passenger.quantity,
      };
    }
  });

  return result;
};
