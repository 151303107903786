import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AddOnPricingBreakdown } from "./component";
import {
  predictionSelector,
  selectedFareDetailsSelector,
  selectedCfarOfferPricesSelector,
  selectedDisruptionProtectionOfferPricesSelector,
  customizeCheckoutBreakdownTotalPricesSelector,
  selectedDiscountedCfarOfferPricesSelector,
  selectedCfarOfferCoverageSelector,
  selectedDisruptionProtectionIdSelector,
  selectedChfarOfferPricesSelector,
} from "../../../reducer";
import {
  priceFreezeChargeAmountPricesSelector,
  getTripCategoryFromPriceFreeze,
  priceFreezeCustomizeCheckoutBreakdownTotalPricesSelector,
  currentPriceFreezePriceDropProtectionSelector,
} from "../../../../freeze/reducer";
import { getTripCategory } from "../../../../search/reducer";
import { IStoreState } from "../../../../../reducers/types";
import {
  isAirOfferRedesignEnabledSelector,
  isCfarCoMerchEnabledSelector,
  getPriceDropRefundTypeSelector,
} from "../../../../ancillary/reducer";
import { containsSelfTransferLayoverFareSegment } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  const selectedFare = selectedFareDetailsSelector(state);
  const isVITripSelected = selectedFare
    ? containsSelfTransferLayoverFareSegment([selectedFare])
    : false;

  return {
    selectedFare,
    priceFreezeChargeAmount: priceFreezeChargeAmountPricesSelector(state),
    totalPrices: customizeCheckoutBreakdownTotalPricesSelector(state),
    priceFreezeTotalPrices:
      priceFreezeCustomizeCheckoutBreakdownTotalPricesSelector(state),
    tripCategory: getTripCategory(state),
    tripCategoryFromPriceFreeze: getTripCategoryFromPriceFreeze(state),
    prediction: predictionSelector(state),
    priceDropProtectionFromPriceFreeze:
      currentPriceFreezePriceDropProtectionSelector(state),
    cfarOfferPrices: selectedCfarOfferPricesSelector(state),
    cfarOfferCoverage: selectedCfarOfferCoverageSelector(state),
    discountedCfarOffer: selectedDiscountedCfarOfferPricesSelector(state),
    chfarOfferPrices: selectedChfarOfferPricesSelector(state),
    disruptionProtectionOfferPrices:
      selectedDisruptionProtectionOfferPricesSelector(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    isCfarCoMerchEnabled: isCfarCoMerchEnabledSelector(state),
    selectedDisruptionProtectionId:
      selectedDisruptionProtectionIdSelector(state),
    priceDropRefundType: getPriceDropRefundTypeSelector(state),
    isVITripSelected,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AddOnPricingBreakdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAddOnPricingBreakdown = withRouter(
  connector(AddOnPricingBreakdown)
);
