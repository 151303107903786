import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";

import { FlightFreezeTravelWalletSelection } from "./component";
import { withRouter } from "react-router";
import { getCredit, getCreditToApply, getFetchApplicableTravelWalletItemsCallState, getIsTravelWalletCreditPaymentOnly, getMaxApplicableTravelWalletCreditAmount } from "../../reducer";
import { getRewardsAccounts } from "../../../rewards/reducer";
import { getRewardsPaymentAccountReferenceId, getSelectedPaymentMethodId } from "../../../book/reducer";
import { setCreditToApply } from "../../actions/actions";
import { resetPaymentCardSelectedAccounts, setSelectedRewardsPaymentTotal } from "../../../book/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    fetchApplicableTravelWalletItemsCallState:
      getFetchApplicableTravelWalletItemsCallState(state),
    selectedPaymentMethodId: getSelectedPaymentMethodId(state) ?? "",
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    credits: getCredit(state),
    creditsToApply: getCreditToApply(state),
    isTravelCreditPaymentOnly: getIsTravelWalletCreditPaymentOnly(state),
    rewardsAccounts: getRewardsAccounts(state),
    maxApplicableTravelWalletCreditAmount:
      getMaxApplicableTravelWalletCreditAmount(state),
  };
};

export const mapDispatchToProps = {
  resetPaymentCardSelectedAccounts,
  setSelectedRewardsPaymentTotal,
  setCreditToApply,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelWalletSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezeTravelWalletSelection = withRouter(
  connector(FlightFreezeTravelWalletSelection)
);
