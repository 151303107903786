import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { similarFlightApiPrefix } from "../paths";
import { GetSimilarFlightsRequest, GetSimilarFlightsResponse } from "redmond";

const getSimilarFlightsPath = `${similarFlightApiPrefix}/list`;

export const getSimilarFlights = (
  body: GetSimilarFlightsRequest
): Promise<GetSimilarFlightsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<GetSimilarFlightsResponse> = await axios.post(getSimilarFlightsPath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getSimilarFlights;
