import { Box, Typography } from "@material-ui/core";
import { ActionButton, Icon, IconName } from "halifax";
import { RouteComponentProps } from "react-router";
import React from "react";
import "./styles.scss";
import { MulticityEditSearchControlConnectorProps } from "./container";
import { ITripTerminus } from "redmond";
import dayjs from "dayjs";
import { getCityNameAndAirportCodeFromTerminus } from "../../../../../../utils/terminus";
import { EDIT_SEARCH } from "../../textConstants";

export interface IMulticityEditSearchControlProps
  extends RouteComponentProps,
    MulticityEditSearchControlConnectorProps {
  setEditMulticitySearchModalOpen: () => void;
}

export const MulticityEditSearchControl = ({
  multicityRoutes,
  setEditMulticitySearchModalOpen,
}: IMulticityEditSearchControlProps) => {
  const renderFlightSegments = () => {
    return (
      <Box className="searched-multicity-flight-segments-container">
        {multicityRoutes.map((route, index) => {
          const { origin, destination, departureDate } = route;

          if (!origin || !destination || !departureDate) return;

          const [originCityName, originAirportCode] =
            getCityNameAndAirportCodeFromTerminus(origin as ITripTerminus);
          const [destinationCityName, destinationAirportCode] =
            getCityNameAndAirportCodeFromTerminus(destination as ITripTerminus);
          const departureDateString =
            dayjs(departureDate).format("ddd, MMM DD");

          return (
            <Box
              key={`searched-flight-segment-${index + 1}`}
              className="searched-multicity-flight-segment"
            >
              <Box className="searched-flight-segment-number-with-icon">
                <Icon
                  name={IconName.DiagonalGreyAirplane}
                  className="multicity-flight-icon"
                />
                <Typography>Flight {index + 1}</Typography>
              </Box>
              <Typography className="searched-multicity-locations">
                <strong>{originCityName}</strong> {originAirportCode} to{" "}
                <strong>{destinationCityName}</strong> {destinationAirportCode}
              </Typography>
              <Typography className="searched-multicity-departure">
                Outbound on {departureDateString}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box className="multicity-edit-search-control">
      {renderFlightSegments()}
      <ActionButton
        fill="blue"
        buttonClassName="multicity-edit-flight-search-button"
        onClick={setEditMulticitySearchModalOpen}
        message={EDIT_SEARCH}
      />
    </Box>
  );
};
