import React from "react";
import {
  Typography,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { IOption, LabelDropDown, GenericDropdown } from "halifax";
import { IFlightNumberFilter } from "../../../../../../reducer";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { IFlightNumberFilterSelectionBase } from "./container";
import { generateFilterTrackingEvent } from "../../../../../../actions/actions";
import { trackEvent } from "../../../../../../../../api/v0/analytics/trackEvent";
import clsx from "clsx";
export const FlightNumberFilterSelection = (
  props: IFlightNumberFilterSelectionBase
) => {
  const {
    allAirlines,
    flightNumbersByAirline,
    setFlightNumberFilter,
    flightNumberFilter = [],
    departureDateString,
    showDropdownContentOnly,
  } = props;

  const [airlineValue, setAirlineValue] = React.useState("");

  const toOptions = (values: string[], labelPrefix?: string): IOption[] => {
    return values.reduce((result: IOption[], value) => {
      const label = labelPrefix ? `${labelPrefix} ${value}` : value;
      const option: IOption = { value, label };
      result.push(option);
      return result;
    }, []);
  };

  const handleAirlineChange = (airlineValue: string) => {
    setAirlineValue(airlineValue);
    setFlightNumberFilter([]);
  };

  const handleFlightNumberSelection = (newValue: string) => {
    const filterValue: IFlightNumberFilter = {
      airlineCode: airlineValue,
      flightNumber: newValue,
    };

    const shouldRemoveFlight = flightNumberFilter.some(
      (item) =>
        item.airlineCode === filterValue.airlineCode &&
        item.flightNumber === filterValue.flightNumber
    );

    if (shouldRemoveFlight) {
      // Read as: if flight number or airline code is not the same, it is not the same flight and we should not remove.
      const newFilters = flightNumberFilter.filter(
        (item) =>
          item.airlineCode !== filterValue.airlineCode ||
          item.flightNumber !== filterValue.flightNumber
      );
      setFlightNumberFilter(newFilters);
    } else {
      setFlightNumberFilter([...flightNumberFilter, filterValue]);
    }
    trackEvent(generateFilterTrackingEvent("flight number", "list"));
  };

  const isChecked = (option: string): boolean => {
    return flightNumberFilter.some((currentOption: IFlightNumberFilter) => {
      return (
        currentOption.flightNumber === option &&
        airlineValue === currentOption.airlineCode
      );
    });
  };

  const renderFlightNumberOptions = (options: IOption[]) => {
    return options.map((option: IOption) => (
      <FormControlLabel
        className={"flight-number-options"}
        key={option.value}
        control={
          <Checkbox
            checked={isChecked(option.value)}
            onChange={() => handleFlightNumberSelection(option.value)}
            value={option}
          />
        }
        labelPlacement={"start"}
        label={option.label}
      />
    ));
  };

  const renderDropdownContent = () => {
    return (
      <Box className={"flight-number-selection-root"}>
        <Box className="flight-number-selection-container">
          <Box className="header-container">
            <Typography variant="body1" className={"header-text"}>
              {textConstants.HEADER(departureDateString)}
            </Typography>
          </Box>
          <Box className="airline-selection">
            <Box>
              <LabelDropDown
                classes={["airline-selection-menu"]}
                options={allAirlines}
                label={""}
                ariaLabel={"Airline Selector"}
                onChange={(newValue) => handleAirlineChange(newValue)}
                value={airlineValue}
              />
            </Box>
          </Box>
          {airlineValue && (
            <Box className="flight-number-selection">
              <FormControl component="fieldset">
                <FormGroup>
                  {renderFlightNumberOptions(
                    toOptions(
                      flightNumbersByAirline[airlineValue] ?? [],
                      airlineValue
                    )
                  )}
                </FormGroup>
              </FormControl>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box className={"flight-number-selection-dropdown"}>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={"b2b-shop-filter"}
          popoverClassName={clsx("flight-number-selection-popover", "b2b")}
          ariaLabel={`Flight Number Filter`}
          dropdownLabel={textConstants.FLIGHT_NUMBER}
          dropdownContent={renderDropdownContent()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </Box>
  );
};
