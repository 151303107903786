import React, { useState, useEffect } from "react";
import {
  ToggleSwitch,
  getLayoverDetailTitle,
  AirlineIcon,
  IconContentView,
  restrictionIcon,
  sortFaresByKeys,
} from "halifax";

import { Box, Popover, Typography } from "@material-ui/core";
import * as constants from "../constants"
import {
  Airport,
  VIEWED_FARE_DETAILS_BREAKDOWN,
  ViewedFareDetailsBreakdownProperties,
  LayoverDetails,
  TripDetails,
  VirtualInterlineEntryPoint
} from "redmond";
import { OUTBOUND_RETURN__TOGGLE_ARIA_LABEL, getAriaValueText, getValueText } from "../../SelfTransferReminderModal/textConstants";
import { ITripLeg } from "../../SelfTransferReminderModal/types";
import { getSummaryPanelHeader } from "../../FlightShopSummaryPanel/constants";
import { getEmptyRestrictionsText, layoverDetails } from "../../../v2/components/FlightList/components/FlightDetails/component";
import './styles.scss'
import { getFareDetailsRestrictions } from "../utils";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";


interface MultipleAirlinesFareDetails {
  tripDetails: TripDetails;
  airports: { [key: string]: Airport };
}

export const MultipleAirlinesFareDetails = ({
  tripDetails,
  airports,
}: MultipleAirlinesFareDetails) => {
  const [selectedTripLeg, setSelectedTripLeg] = useState<ITripLeg>(ITripLeg.OUTBOUND);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const slice = tripDetails.slices[selectedTripLeg];
  const restrictions = tripDetails.fareDetails[0].restrictions![selectedTripLeg];
  const placeholderRestriction = getEmptyRestrictionsText(tripDetails.fareDetails[0].slices[selectedTripLeg].fareShelf?.rating);

  const segmentsDetails: LayoverDetails[] = layoverDetails({
    tripDetails,
    airports,
    isOutgoing: !Boolean(selectedTripLeg)
  })

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_FARE_DETAILS_BREAKDOWN,
      properties: {
        entry_point: VirtualInterlineEntryPoint.Details,
        multi_ticket_type: tripDetails?.fareDetails?.[0]?.multiTicketType,
      } as ViewedFareDetailsBreakdownProperties,
    });
  }, []);

  const handlePopoverOpen = (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box className="multiple-airlines-fare-details-container">
      <Box className="title-container">
        <Typography className="card-header" variant="h2">
          {constants.TITLE}
        </Typography>
        <Typography className="card-desc" variant="body2">
          {constants.DESCRIPTION}
        </Typography>
      </Box>
      <Box className="fares-container">
        {tripDetails.slices.length > 1 && (
          <ToggleSwitch
            className="trip-leg-toggle-root"
            size="small"
            ariaLabel={OUTBOUND_RETURN__TOGGLE_ARIA_LABEL}
            values={[ITripLeg.OUTBOUND, ITripLeg.RETURN]}
            selectedValue={selectedTripLeg}
            setSelectedValue={(selectedValue: number) => {
              setSelectedTripLeg(selectedValue)
            }}
            getValueText={(value: number) => getValueText(value)}
            getAriaValueText={(value: number) => getAriaValueText(value)}
          />
        )}
        <Box className="fares-slice-details">
          <Typography className="title" variant="h3">
            {getSummaryPanelHeader(
              selectedTripLeg === 0,
              slice?.destinationCode,
              slice?.departureTime,
            )}
          </Typography>

          <Box className="restrictions">
            {restrictions.amenitiesAndUtas.map((restriction, index) => {
              const sortedRestrctions = sortFaresByKeys(getFareDetailsRestrictions({ restriction }));
              const first = sortedRestrctions.slice(0, constants.MAX_RESTRISCTIONS_TO_SHOW);
              const rest = sortedRestrctions.slice(constants.MAX_RESTRISCTIONS_TO_SHOW);
              return (
                <Box key={index} className="container">
                  <Box className="fare-details-segment-container">
                    <Typography variant="body2" className="layover-title">
                      {getLayoverDetailTitle(segmentsDetails[index])}
                    </Typography>
                    <Box className="fare-details-segment-airline-details">
                      <AirlineIcon
                        size="1x"
                        airlineCode={segmentsDetails[index]?.airlineCode}
                      />
                      <Typography className="airline-name" variant="caption">
                        {segmentsDetails[index]?.airlineName}
                      </Typography>
                    </Box>
                  </Box>
                  {!first.length ? placeholderRestriction : (
                    first.map(({ description, symbol }) => (
                      <IconContentView
                        key={`${description}-${index}`}
                        className="fare-details-restriction"
                        icon={restrictionIcon[symbol]}
                        content={<Typography variant="body2">{description}</Typography>}
                      />
                    ))
                  )}
                  {rest.length > 0 && (
                    <Typography
                      aria-owns={
                        anchorEl && anchorEl.id == `trip-fare-details-${index}`
                          ? "mouse-over-popover"
                          : undefined
                      }
                      aria-haspopup="true"
                      id={`trip-fare-details-${index}`}
                      className="show-more"
                      variant="h6"
                      onClick={handlePopoverOpen}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handlePopoverOpen(e);
                        }
                      }}
                      tabIndex={0}
                    >
                      {`+${rest.length} more`}
                    </Typography>
                  )}
                  <Popover
                    onMouseLeave={handlePopoverClose}
                    id="mouse-over-popover"
                    className="additional-amenities-container"
                    onClose={handlePopoverClose}
                    disableEnforceFocus
                    open={
                      !!anchorEl &&
                      anchorEl.id == `trip-fare-details-${index}`
                    }
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box
                      className="additional-amenities"
                      onMouseLeave={handlePopoverClose}
                    >
                      {rest.map(({ description, symbol }, idx) => (
                        <IconContentView
                          key={`${description}-${idx}`}
                          className="fare-details-restriction"
                          icon={restrictionIcon[symbol]}
                          content={<Typography variant="body2">{description}</Typography>}
                        />
                      ))}
                    </Box>
                  </Popover>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

