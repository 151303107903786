import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { priceFreezeOfferPath } from "../paths";
import { FetchOfferRequest, FetchOfferResponse } from "redmond";

const getPriceFreezeOfferPath = `${priceFreezeOfferPath}/get`;

export const getPriceFreezeOffer = (
  body: FetchOfferRequest
): Promise<FetchOfferResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<FetchOfferResponse> = await axios.post(getPriceFreezeOfferPath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default getPriceFreezeOffer;
