import React from "react";
import {
  ButtonWrap,
  CloseButtonIcon,
  DesktopPopupModal,
} from "halifax";

import { Box } from "@material-ui/core";
import {
  Airport,
  TripDetails
} from "redmond";
import { MultipleAirlinesFareDetails } from "../MultipleAirlinesFareDetails/component";
import './styles.scss'

interface MultipleAirlinesFareModal {
  isOpen: boolean;
  onClose: () => void;
  tripDetails: TripDetails;
  airports: { [key: string]: Airport };
}

export const MultipleAirlinesFareModal = ({
  isOpen,
  onClose,
  tripDetails,
  airports,
}: MultipleAirlinesFareModal) => {
  return (
    <DesktopPopupModal
      className="multiple-airlines-fare-modal-root"
      onClose={onClose}
      invisibleBackdrop={false}
      hideXButton
      disableEnforceFocus
      open={isOpen}
    >
      <Box className="close-button">
        <ButtonWrap aria-label="Close" onClick={onClose}>
          <CloseButtonIcon />
        </ButtonWrap>
      </Box>

      <Box className="popup-grid-container">
        <MultipleAirlinesFareDetails tripDetails={tripDetails} airports={airports} />
      </Box>
    </DesktopPopupModal>
  )
}

