import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  getTripCategory,
  getDestination,
  getOrigin,
  getNumTravelers,
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getReturnDate,
  initialFilterOptions,
  getMulticityRoutes,
  MulticityRoute,
  getFareclassOptionFilter,
  getStopsOption,
  getAirlineFilter,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getMaxPriceFilter,
  FareclassOptionFilter,
  getHasSetStopsOption,
  getHasSetAirlineFilter,
  getHasSetFareClassFilter,
  getDurationFilter,
  getActiveFiltersCount,
  getApplyUserFlightPreferences,
  getPolicyFilter,
} from "../../reducer";
import {
  ISetOpenFlightShopCalendarDesktop,
  setOpenFlightShopCalendarDesktop,
  setChosenOutgoingSlice,
  IFlightShopOverwriteQueryParams,
  IPopulateFlightShopQueryParams,
  populateFlightShopQueryParams,
  ISetFlightShopProgress,
  setFlightShopProgress,
  resetSelectedTrip,
  IResetSelectedTrip,
} from "../../../shop/actions/actions";
import { FlightShopSearchControl } from "./component";
import { withRouter } from "react-router";
import {
  setOrigin,
  setDestination,
  setDepartureDate,
  setReturnDate,
  fetchDepartureCalendar,
  setCalendar,
  ISetPassengerCounts,
  setPassengerCounts,
  setOpenDatesModal,
  ISetMulticityOrigin,
  ISetMulticityDestination,
  ISetMulticityDepartureDate,
  setMulticityOrigin,
  setMulticityDestination,
  setMulticityDepartureDate,
  setAllMulticityRoutes,
  ISetAllMulticityRoutes,
  setDurationFilter,
  setApplyUserFlightPreferences,
  setUserPreferencesNotAvailable,
} from "../../actions/actions";
import {
  flightShopProgressSelector,
  maxFlightPriceSelectorV2,
  openFlightShopCalendarDesktopSelector,
  flightShopTypeSelector,
  getAirEntryProperties,
  allFlightShopFilterSelector,
  IAirlineOptions,
  hasSetMaxPriceFilterSelectorV2,
} from "../../../shop/reducer/selectors";
import { Dispatch } from "@reduxjs/toolkit";
import {
  ITripTerminus,
  FlightShopStep,
  FlightShopType,
  TripCategory,
  AirEntryProperties,
  SliceStopCountFilter,
} from "redmond";
import { PassengerCountPickerType } from "halifax";

interface IDispatchProps {
  fetchDepartureCalendar: (
    origin: ITripTerminus,
    destination: ITripTerminus
  ) => actions.IFetchDepartureCalendar;
  setCalendar: (report?: any) => actions.ISetDepartureCalendar;
  setDepartureDate: (date: Date | null) => actions.ISetDepartureDate;
  setDestination: (
    destination: ITripTerminus | null
  ) => actions.ISetDestination;
  setOpenFlightShopCalendarDesktop: (
    open: boolean
  ) => ISetOpenFlightShopCalendarDesktop;
  setOrigin: (origin: ITripTerminus | null) => actions.ISetOrigin;
  setReturnDate: (date: Date | null) => actions.ISetReturnDate;
  setMulticityOrigin: (
    origin: ITripTerminus | null,
    index: number
  ) => ISetMulticityOrigin;
  setMulticityDestination: (
    destination: ITripTerminus | null,
    index: number
  ) => ISetMulticityDestination;
  setMulticityDepartureDate: (
    date: Date | null,
    index: number
  ) => ISetMulticityDepartureDate;
  setAllMulticityRoutes: (
    multicityRoutes: MulticityRoute[]
  ) => ISetAllMulticityRoutes;
  setTripCategory: (tripCategory: TripCategory) => actions.ISetTripCategory;
  resetAll: (resetFilters: boolean) => void;
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => IPopulateFlightShopQueryParams;
  setFlightShopProgress: (step: FlightShopStep) => ISetFlightShopProgress;
  setPassengerCounts: (counts: PassengerCountPickerType) => ISetPassengerCounts;
  openCalendar: (open: boolean) => actions.ISetOpenDatesModal;
  resetSelectedTrip: () => IResetSelectedTrip;
  setApplyUserFlightPreferences: (applyPreferences: boolean) => void;
  setUserPreferencesNotAvailable: (
    userPreferencesNotAvailable: boolean
  ) => void;
}

const { setTripCategory } = actions;
const mapDispatchToProps = (dispatch: Dispatch) => ({
  openCalendar: (open: boolean) => dispatch(setOpenDatesModal(open)),
  fetchDepartureCalendar: (origin: ITripTerminus, destination: ITripTerminus) =>
    dispatch(fetchDepartureCalendar(origin, destination)),
  setCalendar: (report?: any) => dispatch(setCalendar(report)),
  setDepartureDate: (date: Date | null) => dispatch(setDepartureDate(date)),
  setDestination: (destination: ITripTerminus | null) =>
    dispatch(setDestination(destination)),
  setOpenFlightShopCalendarDesktop: (open: boolean) =>
    dispatch(setOpenFlightShopCalendarDesktop(open)),
  setOrigin: (origin: ITripTerminus | null) => dispatch(setOrigin(origin)),
  setReturnDate: (date: Date | null) => dispatch(setReturnDate(date)),
  setMulticityOrigin: (origin: ITripTerminus | null, index: number) =>
    dispatch(setMulticityOrigin(origin, index)),
  setMulticityDestination: (destination: ITripTerminus | null, index: number) =>
    dispatch(setMulticityDestination(destination, index)),
  setMulticityDepartureDate: (date: Date | null, index: number) =>
    dispatch(setMulticityDepartureDate(date, index)),
  setAllMulticityRoutes: (multicityRoutes: MulticityRoute[]) =>
    dispatch(setAllMulticityRoutes(multicityRoutes)),
  setTripCategory: (tripCategory: TripCategory) =>
    dispatch(setTripCategory(tripCategory)),
  resetAll: (resetFilters: boolean) => {
    if (resetFilters) {
      dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
      dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
      dispatch(
        actions.setFareclassOptionFilter({
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        })
      );
      dispatch(actions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
      dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
      dispatch(actions.setPolicyFilter(initialFilterOptions.policyFilter));
      dispatch(
        actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
      );
      dispatch(
        actions.setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        actions.setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnDepartureTimeRange(
          initialFilterOptions.returnDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnArrivalTimeRange(
          initialFilterOptions.returnArrivalTimeRange
        )
      );
      dispatch(setDurationFilter(initialFilterOptions.durationFilter));
      dispatch(
        setUserPreferencesNotAvailable(
          initialFilterOptions.userPreferencesNotAvailable
        )
      );
    }
    dispatch(
      setChosenOutgoingSlice({
        tripId: "",
        outgoingSliceId: "",
        outgoingFareId: "",
        outgoingFareRating: undefined,
        resetReturnIds: true,
      })
    );
  },
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => dispatch(populateFlightShopQueryParams(args)),
  setFlightShopProgress: (step: FlightShopStep) =>
    dispatch(setFlightShopProgress(step)),
  setPassengerCounts: (counts: PassengerCountPickerType) =>
    dispatch(setPassengerCounts(counts)),
  resetSelectedTrip: () => dispatch(resetSelectedTrip()),
  setApplyUserFlightPreferences: (applyPreferences: boolean) =>
    dispatch(setApplyUserFlightPreferences(applyPreferences)),
  setUserPreferencesNotAvailable: (userPreferencesNotAvailable: boolean) =>
    dispatch(setUserPreferencesNotAvailable(userPreferencesNotAvailable)),
});

interface IStateProps {
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  adultsCount: number;
  childrenCount: number;
  departureDate: Date | null;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
  numTravelers: number;
  openCalendarDesktop: boolean;
  returnDate: Date | null;
  tripCategory: TripCategory;
  maxFlightPrice: number;
  flightShopProgress: number;
  flightShopType: FlightShopType;
  airEntryProperties: AirEntryProperties;
  multicityRoutes: MulticityRoute[];
  fareClassFilter: FareclassOptionFilter;
  stopsOption: SliceStopCountFilter;
  airlineFilter: string[];
  hasSetOutboundTimeRange: boolean;
  hasSetReturnTimeRange: boolean;
  airlines: IAirlineOptions[];
  maxPriceFilter: number;
  hasSetStopsOption: boolean;
  hasSetAirlineFilter: boolean;
  hasSetMaxPrice: boolean;
  hasSetFareclassFilter: boolean;
  durationFilter: number;
  activeFiltersCount: number;
  shouldApplyUserFlightPreferences: boolean;
  policyFilter: boolean;
}

const mapStateToProps = (state: IStoreState) => {
  const destination = getDestination(state);
  const origin = getOrigin(state);
  const fareClassOptionFilter = getFareclassOptionFilter(state);

  return {
    origin,
    destination,
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    departureDate: getDepartureDate(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    numTravelers: getNumTravelers(state),
    openCalendarDesktop: openFlightShopCalendarDesktopSelector(state),
    returnDate: getReturnDate(state),
    tripCategory: getTripCategory(state),
    maxFlightPrice: maxFlightPriceSelectorV2(state),
    flightShopProgress: flightShopProgressSelector(state),
    flightShopType: flightShopTypeSelector(state),
    airEntryProperties: getAirEntryProperties(state),
    multicityRoutes: getMulticityRoutes(state),
    fareClassFilter: fareClassOptionFilter,
    stopsOption: getStopsOption(state),
    airlineFilter: getAirlineFilter(state),
    hasSetOutboundTimeRange: getHasSetOutboundTimeRange(state),
    hasSetReturnTimeRange: getHasSetReturnTimeRange(state),
    airlines: allFlightShopFilterSelector(state).airlineOptions,
    maxPriceFilter: getMaxPriceFilter(state),
    hasSetStopsOption: getHasSetStopsOption(state),
    hasSetAirlineFilter: getHasSetAirlineFilter(state),
    hasSetMaxPrice: hasSetMaxPriceFilterSelectorV2(state),
    hasSetFareclassFilter: getHasSetFareClassFilter(state),
    durationFilter: getDurationFilter(state),
    activeFiltersCount: getActiveFiltersCount(state),
    shouldApplyUserFlightPreferences: getApplyUserFlightPreferences(state),
    policyFilter: getPolicyFilter(state),
  };
};

interface IOwnProps {
  disabled?: boolean;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    resetAll: (resetFilters: boolean) => dispatchProps.resetAll(resetFilters),
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type FlightShopSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopSearchControl = connector(
  withRouter(FlightShopSearchControl)
);
