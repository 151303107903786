import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { travelWalletApiPrefix } from "../paths";
import { OffersForProductsRequest, WalletOffersResponse } from "redmond";

const END_POINT: string = `${travelWalletApiPrefix}/offers`;

export const fetchTravelWalletOffers = (
  offersForProduct: OffersForProductsRequest
): Promise<WalletOffersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<WalletOffersResponse> = await axios.put(END_POINT, offersForProduct, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
