import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Typography } from "@material-ui/core";
import {
  B2BSpinner,
  GenericInfoPopup,
  Icon,
  IconName,
  LoadingPopup,
  PoweredByHopper,
  useDeviceTypes,
} from "halifax";
import { SimilarFlightsModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import {
  CallState,
  PRICE_FREEZE_ID_QUERY_PARAM,
  TransferRequest,
} from "redmond";
import {
  PATH_PRICE_FREEZE_OVERVIEW,
  FAQ_URL,
} from "../../../../utils/urlPaths";
import queryStringParser from "query-string";
import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";

export interface ISimilarFlightsModalProps
  extends SimilarFlightsModalConnectorProps,
    RouteComponentProps {
  transferToSimilarFlightsRequest: TransferRequest | null;
}

export const SimilarFlightsModal = ({
  fetchTransferToSimilarFlightsCallState,
  setFetchTransferToSimilarFlightsCallStateNotCalled,
  fetchTransferToSimilarFlights,
  transferToSimilarFlightsRequest,
  transferToSimilarFlightResponse,
  history,
}: ISimilarFlightsModalProps) => {
  const { matchesMobile } = useDeviceTypes();
  let popupContent: Partial<IGenericInfoPopupProps>;

  useEffect(() => {
    return () => {
      // note: reset call state on destroy
      setFetchTransferToSimilarFlightsCallStateNotCalled();
    };
  }, []);

  switch (fetchTransferToSimilarFlightsCallState) {
    case CallState.InProcess:
      return (
        <LoadingPopup
          indicatorSize="small"
          indicator={B2BSpinner}
          open
          popupSize="small"
          message={textConstants.LOADING_TEXT}
          footer={PoweredByHopper}
        />
      );
    case CallState.Success:
      popupContent = {
        buttons: [
          {
            buttonText: textConstants.CONTINUE,
            onClick: () => {
              history.push({
                pathname: PATH_PRICE_FREEZE_OVERVIEW,
                search: queryStringParser.stringify({
                  [PRICE_FREEZE_ID_QUERY_PARAM]:
                    transferToSimilarFlightResponse?.priceFreeze.id,
                }),
                state: {
                  useRefetch: true,
                },
              });
            },
            defaultStyle: "h4r-primary",
          },
        ],
        image: <Icon className="success-icon" name={IconName.Checked} />,
        subtitle: textConstants.ITINERARY_UPDATED,
        title: (
          <Typography variant="h2" className="success-title">
            {textConstants.SUCCESS}
          </Typography>
        ),
      };
      break;
    case CallState.Failed:
      popupContent = {
        buttons: [
          {
            buttonText: textConstants.CONTACT_SUPPORT,
            onClick: () => {
              window.open(FAQ_URL, "_blank")?.focus();
            },
            defaultStyle: "h4r-primary",
          },
          {
            buttonText: textConstants.TRY_AGAIN,
            onClick: () => {
              if (transferToSimilarFlightsRequest) {
                fetchTransferToSimilarFlights({
                  id: transferToSimilarFlightsRequest.id,
                  fareId: transferToSimilarFlightsRequest.itinerary.fareId,
                  tripId: transferToSimilarFlightsRequest.itinerary.tripId,
                  history,
                });
              }
            },
            defaultStyle: "h4r-secondary",
          },
        ],
        image: <Icon className="error-state" name={IconName.ErrorState} />,
        subtitle: textConstants.UNABLE_TO_PROCESS,
        title: textConstants.FAIL,
        showCloseButton: true,
        onClose: () => {
          setFetchTransferToSimilarFlightsCallStateNotCalled();
        },
      };
      break;
    default:
      return null;
  }

  return (
    <GenericInfoPopup
      className="similar-flights-modal"
      isMobile={matchesMobile}
      open={true}
      zIndex={matchesMobile ? 1301 : 1300}
      {...popupContent}
    />
  );
};
