import React, { useContext, useState } from "react";
import { CallState } from "redmond";
import "./styles.scss";
import {
  LoadingPopup,
  B2BSpinner,
  PoweredByHopper,
  DesktopPopupModal,
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
  useDeviceTypes,
  Icon,
  IconName,
} from "halifax";
import { Typography, Box } from "@material-ui/core";
import { AgentBookingFeeModalConnectorProps } from "./container";
import * as constants from "./constants";
import { ClientContext } from "../../../../App";
import "./styles.scss";

export interface IAgentBookingFeeModalProps
  extends AgentBookingFeeModalConnectorProps {}

export const AgentBookingFeeModal = ({
  chargeAgentBookingFeeCallState,
  itinerary,
}: IAgentBookingFeeModalProps) => {
  const sessionInfo = useContext(ClientContext).sessionInfo;
  const locatorCode =
    itinerary?.travelItinerary.locators?.agent.unscopedValue ?? "";
  const dashboardUrl = constants.getDashboardUrl(locatorCode);

  return (
    <>
      {chargeAgentBookingFeeCallState === CallState.Success && (
        <AgentBookingFeeResponse
          open={chargeAgentBookingFeeCallState === CallState.Success}
          title={constants.SUCCESS_TITLE}
          icon={
            <Icon className="check-icon" name={IconName.CheckCircleFilled} />
          }
          description={
            <Box className="description-container">
              <Typography className="description-text">
                <strong className="description-key">{"user: "}</strong>
                <span className="description-value">
                  {sessionInfo?.userInfo?.email}
                </span>
              </Typography>
              <Typography paragraph className="description-text">
                <strong className="description-key">{"locator: "}</strong>
                {/* TODO: Change below when dashboard url has been deployed and test to leamouthc1 */}
                {dashboardUrl ? (
                  <a className="description-value" href={dashboardUrl}>
                    {locatorCode}
                  </a>
                ) : (
                  <span className="description-value">{locatorCode}</span>
                )}
              </Typography>
              <Typography>{constants.SUCCESS_SUBTEXT}</Typography>
            </Box>
          }
        />
      )}
      {chargeAgentBookingFeeCallState === CallState.Failed && (
        <AgentBookingFeeResponse
          open={chargeAgentBookingFeeCallState === CallState.Failed}
          title={constants.FAILURE_TITLE}
          icon={<Icon className="error-alert" name={IconName.ErrorAlert} />}
          description={
            <Box className="description-container">
              <Typography paragraph className="description-text">
                <strong className="description-key">{"user: "}</strong>
                <span className="description-value">
                  {sessionInfo?.userInfo?.email}
                </span>
              </Typography>
              <Typography>{constants.FAILURE_SUBTEXT}</Typography>
              <Typography>{constants.FAILURE_DESCRIPTION}</Typography>
            </Box>
          }
        />
      )}
      {chargeAgentBookingFeeCallState === CallState.InProcess && (
        <LoadingPopup
          indicatorSize={"small"}
          indicator={B2BSpinner}
          open={chargeAgentBookingFeeCallState === CallState.InProcess}
          popupSize={"small"}
          message={"Charging booking fee"}
          footer={PoweredByHopper}
        />
      )}
    </>
  );
};

const AgentBookingFeeResponse = (props: {
  open: boolean;
  title: string;
  description: JSX.Element;
  icon: JSX.Element;
}) => {
  const { matchesMobile } = useDeviceTypes();
  const [open, setOpen] = useState(props.open);
  const Content = () => (
    <>
      {props.icon}
      <Box>
        <Typography className="agent-fee-modal-title">{props.title}</Typography>
      </Box>
      <Box className="agent-fee-modal-description">{props.description}</Box>
    </>
  );

  return (
    <Box className="agent-booking-fee-modal-container">
      {matchesMobile ? (
        <MobilePopoverCard
          open={open}
          className={"agent-fee-popup-mobile"}
          contentClassName="agent-fee-wrapper"
          topRightButton={
            <ActionLink content={<CloseButtonIcon />} label="Close" />
          }
          centered={true}
          onClose={() => setOpen(false)}
        >
          <Content />
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          className="agent-fee-popup-desktop"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Content />
        </DesktopPopupModal>
      )}
    </Box>
  );
};
