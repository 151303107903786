import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../reducers/types";
import {
  setContactInfo,
  resetPaymentCardSelectedAccounts,
} from "../book/actions/actions";
import { FlightFreeze } from "./component";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import { generateCustomPriceFreezeOffer, setCreditToApply, setSelectedPriceFreezeRadioDataIndex } from "./actions/actions";
import { initialPriceFreezeOfferDataWithRewardsSelector, initialSelectedPriceFreezeRadioIndexSelector } from "./reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    initialPriceFreezeOfferDataWithRewards: initialPriceFreezeOfferDataWithRewardsSelector(state),
    initialSelectedPriceFreezeRadioIndex: initialSelectedPriceFreezeRadioIndexSelector(state),
  };
};

const mapDispatchToProps = {
  setContactInfo,
  fetchRewardsAccounts,
  resetPaymentCardSelectedAccounts,
  generateCustomPriceFreezeOffer,
  setSelectedPriceFreezeRadioDataIndex,
  setCreditToApply,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezeConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightFreeze = withRouter(connector(FlightFreeze));
