import { FiatPrice, RewardsPrice } from "redmond";
import { getTotalPriceText, getRewardText } from "halifax";

export const PRICE_FREEZE_DURATION_ARIA_LABEL = "Price Freeze Duration";
export const FROZEN_PRICE_DURATION_COPY = "Frozen price duration:";
export const FEE_PER_TRAVELER_COPY = "Fee per traveler:";

export const PRICE_FREEZE_DURATION_BUTTONS_RADIO_GROUP_NAME =
  "Price Freeze custom duration radio buttons group";

export const ALLOWED_BUTTON_DURATIONS: {
  timeAmount: number;
  timeUnit: "h" | "d";
}[] = [
  { timeAmount: 12, timeUnit: "h" },
  { timeAmount: 1, timeUnit: "d" },
  { timeAmount: 2, timeUnit: "d" },
  { timeAmount: 14, timeUnit: "d" },
];

export const getDurationValueText = (days: number) => {
  const duration = getFormattedDurationObject(days);
  return `${duration.displayValue} ${duration.unitName}`;
};

export const getPricesText = ({
  fiat,
  reward,
  separator = SLASH_SEPARATOR,
  bold,
  addAsterisk,
}: {
  fiat: FiatPrice;
  reward?: RewardsPrice;
  separator?: string;
  bold?: boolean;
  addAsterisk?: boolean;
}) => {
  const fiatCopy = getTotalPriceText({ price: fiat });
  return `${
    bold ? `<strong class="cash-price-text">${fiatCopy}</strong>` : fiatCopy
  }${reward ? ` ${separator} ${getRewardText({ reward })}` : ""}${
    addAsterisk ? "*" : ""
  }`;
};

export const OR_SEPARATOR = "or";
export const SLASH_SEPARATOR = "/";
export const FOR_SEPARATOR = "for";

export const USE_MULTI_PAGE_THRESHOLD = 7;

// TODO: Leverage day.js duration
export const DAY = "day";
export const DAY_PLURAL = "days";
export const DAY_UNIT = "d";
export const HOUR = "hour";
export const HOUR_PLURAL = "hours";
export const HOUR_UNIT = "h";

export const getFormattedDurationObject = (
  days: number
): {
  value: number;
  displayValue: number;
  unit: string;
  unitName: string;
  unitNameSingular: string;
} => {
  let value = days;
  let displayValue = days;
  let unit = DAY_UNIT;
  let unitName = DAY_PLURAL;
  let unitNameSingular = DAY;

  if (value < 1) {
    // note: value will be displayed in hours instead
    displayValue = days * 24;
    unit = HOUR_UNIT;
    unitName = HOUR_PLURAL;
    unitNameSingular = HOUR;

    if (value == 1) {
      unitName = HOUR;
    }
  } else if (value == 1) {
    unitName = DAY;
  }

  return {
    value,
    displayValue,
    unit,
    unitName,
    unitNameSingular,
  };
};

export const VOID_WINDOW_TEXT =
  "If you choose to book this flight instead of freezing the price, you can cancel within 24 hours of booking for a full refund of the flight fare (per the airline's policy). Refunds usually take 1-2 billing cycles, but sometimes longer.";
