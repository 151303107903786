import { connect, ConnectedProps } from "react-redux";
import { InfantSeatPickerModal } from "./component";
import { getTripPricingCallState, getTripPricing } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  ISelectedMulticityTrip,
  ISelectedTrip,
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer";
import { populateFlightShopQueryParams } from "../../../shop/actions/actions";
import { withRouter } from "react-router";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const selectedOWRTrip = selectedTrip as ISelectedTrip;
  const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  const fareDetails = tripDetails.fareDetails.find(
    (f) =>
      f.id ===
      (selectedOWRTrip.returnFareId ||
        selectedOWRTrip.outgoingFareId ||
        selectedMcTrip.departureFareId)
  );
  return {
    tripPricing: getTripPricing(state),
    tripPricingCallState: getTripPricingCallState(state),
    fareDetails: fareDetails,
  };
};

const mapDispatchToProps = { populateFlightShopQueryParams };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type InfantSeatPickerModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedInfantSeatPickerModal = withRouter(
  connector(InfantSeatPickerModal)
);
