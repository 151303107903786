import {
  CallState,
  PRICE_FREEZE_SIMILAR_FLIGHT_CONFIRMATION,
  PRICE_FREEZE_SIMILAR_FLIGHT_ERROR,
  TransferResponse,
} from "redmond";
import { ItineraryEnum } from "redmond/apis/tysons/similar-flights";

import { put, select } from "redux-saga/effects";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import transferToSimilarFlights from "../../../api/v0/price-freeze/transferToSimilarFlights";
import Logger from "../../../helpers/Logger";
import { IStoreState } from "../../../reducers/types";
import { getSimilarFlightSelectedTripDetailsProperties } from "../../freeze/reducer/selectors";
import { actions } from "../actions";
import { IFetchTransferToSimilarFlights } from "../actions/actions";

export function* fetchTransferSimilarFlightsSaga(
  action: IFetchTransferToSimilarFlights
) {
  try {
    const response: TransferResponse = yield transferToSimilarFlights({
      id: action.id,
      itinerary: {
        fareId: action.fareId,
        tripId: action.tripId,
        Itinerary: ItineraryEnum.SingleItinerary,
      },
    });
    yield put(
      actions.setTransferToSimilarFlightsResponse({
        response,
        callState: CallState.Success,
      })
    );

    const state: IStoreState = yield select();
    const properties = getSimilarFlightSelectedTripDetailsProperties(state);

    trackEvent({
      eventName: PRICE_FREEZE_SIMILAR_FLIGHT_CONFIRMATION,
      properties,
    });
  } catch (e) {
    yield put(actions.setFetchTransferToSimilarFlightsCallStateFailed());

    const state: IStoreState = yield select();
    const properties = getSimilarFlightSelectedTripDetailsProperties(state);

    trackEvent({
      eventName: PRICE_FREEZE_SIMILAR_FLIGHT_ERROR,
      properties,
    });
    Logger.debug(e);
  }
}
