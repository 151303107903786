import {
  ActionLink,
  B2BButton,
  CloseButtonIcon,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import React from "react";
import "./styles.scss";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  FareclassOptionFilter,
  initialFareclassOptionFilter,
} from "../../../../reducer";
import { FARE_CLASSES } from "../../../../../shop/components/FlightShopHeader/components/FareClassOptionSelection/textConstants";
import { FARECLASS_HEADER_TEXT } from "../../../FlightSearchFilter/components/FareclassOptionSelection/textConstants";
import { isEqual } from "lodash-es";
import {
  FARECLASS_BUTTON_LABEL,
  POPOVER_CTA_LABEL,
  RESET_CTA_TEXT,
} from "./textConstants";

interface MobileFareClassSelectionProps {
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
}

export const MobileFareClassSelection = ({
  fareclassOptionFilter,
  setFareclassOptionFilter,
}: MobileFareClassSelectionProps) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [localFareclassFilter, setLocalFareclassFilter] =
    React.useState<FareclassOptionFilter>(fareclassOptionFilter);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void = (_, value) =>
    setLocalFareclassFilter({ ...initialFareclassOptionFilter, [value]: true });

  const handleApply = () => {
    setFareclassOptionFilter(localFareclassFilter);
    closeModal();
  };

  const handleReset = () => {
    setLocalFareclassFilter(initialFareclassOptionFilter);
  };

  const appliedCount = Object.values(fareclassOptionFilter).filter(
    (applied) => applied
  ).length;

  const options = Object.keys(fareclassOptionFilter).map((fareclass) => ({
    value: fareclass,
    label: FARE_CLASSES[fareclass],
  }));

  const canApply = !isEqual(fareclassOptionFilter, localFareclassFilter);

  React.useEffect(() => {
    setLocalFareclassFilter(fareclassOptionFilter);
  }, [fareclassOptionFilter, modalOpen]);

  return (
    <>
      <B2BButton
        variant="b2b"
        onClick={openModal}
        className="mobile-fareclass-selection-button"
      >
        <div className="icon-label">
          <Icon name={IconName.FareIconFilled} />{" "}
          <Typography className="mobile-fareclass-selection-button-label">
            {FARECLASS_BUTTON_LABEL(appliedCount)}
          </Typography>
        </div>
        <Icon name={IconName.Dropdown} />
      </B2BButton>
      <MobilePopoverCard
        className="mobile-fareclass-selection-popover"
        open={modalOpen}
        onClose={closeModal}
        centered={true}
        topLeftButton={
          !isEqual(localFareclassFilter, initialFareclassOptionFilter) ? (
            <ActionLink content={RESET_CTA_TEXT} onClick={handleReset} />
          ) : undefined
        }
        topRightButton={<CloseButtonIcon onClick={closeModal} />}
        headerElement={
          <Typography
            variant="h2"
            className="mobile-fareclass-popover-header-text"
          >
            {FARECLASS_HEADER_TEXT}
          </Typography>
        }
      >
        <div className="mobile-fareclass-selection-popover-content">
          <RadioGroup
            value={
              Object.keys(localFareclassFilter).filter(
                (key) => localFareclassFilter[key]
              )[0]
            }
            onChange={handleChange}
          >
            {options.map((option) => (
              <FormControlLabel
                control={<Radio />}
                label={option.label}
                labelPlacement="start"
                className="mobile-fareclass-option-label"
                value={option.value}
              />
            ))}
          </RadioGroup>
        </div>
        {canApply && (
          <div className="apply-fareclass-cta-container">
            <B2BButton
              variant="b2b"
              onClick={handleApply}
              className="mobile-fareclass-selection-cta"
            >
              {POPOVER_CTA_LABEL}
            </B2BButton>
          </div>
        )}
      </MobilePopoverCard>
    </>
  );
};
