import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  isAddOnOptionAvailableSelector,
  isOptionSelectionCompleteSelector,
} from "../../../shop/reducer/selectors";
import { currentPriceFreezeSelector } from "../../reducer/selectors";
import { populateFlightBookQueryParams } from "../../../book/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { FlightFreezeProgressBar } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    isOptionSelectionComplete: isOptionSelectionCompleteSelector(state),
    currentPriceFreeze: currentPriceFreezeSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezeProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezeProgressBar = connector(
  withRouter(FlightFreezeProgressBar)
);
