import dayjs from "dayjs";

export const HEADER_TITLE = (isMobile: boolean, status: boolean) =>
  `${isMobile ? "" : "Review your "}${
    isMobile ? (status ? "Active" : "Inactive") : status ? "active" : "inactive"
  } frozen price`;

export const HEADER_SUBTITLE = (date: string) => {
  if (!date) return "Expires on ...";
  const day = dayjs(date).format("ddd MMMM DD");
  const time = dayjs(date).format("h:mma");
  return `Expires on ${day} at ${time}`;
};
export const FROZEN_PRICES_LIST_LINK = "/trips/?tripsFilter=Frozen%20Prices";
export const HEADER_SUPPORT_TEXT = "Questions about your trip?";
export const CONTACT_SUPPORT = "Contact support";

export const OVERVIEW_TITLE = (location: string) =>
  `Review the price for your trip to ${location}`;

export const OVERVIEW_SUBTITLE = (date: string) => {
  const day = dayjs(date).format("ddd MMMM DD");
  const time = dayjs(date).format("h:mma");
  return `The frozen price for this flight expires on <strong>${day} at ${time}</strong>`;
};

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string,
  airportCode: string,
  date: string
) => ({
  type: isDeparture ? "Outbound" : "Return",
  description: `<strong>to ${location}${
    airportCode ? ` (${airportCode})` : ""
  }</strong> on ${dayjs(date).format("ddd, MMM D")}`,
});

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const VIEW_DETAILS = "View details";
export const FROZEN_PRICE = "Frozen price";
export const CURRENT_PRICE = "Current price";

export const TRIP_PER_TRAVELER = (isRoundTrip: boolean) =>
  isRoundTrip ? "Round-trip, per traveler" : "One-way, per traveler";

export const TOTAL_PRICE_TEXT = (
  showCurrent: boolean,
  showYouWillPay: boolean,
  travelers: number
) =>
  `${showCurrent ? CURRENT_PRICE : FROZEN_PRICE}${
    showYouWillPay ? " you’ll pay" : ""
  } for <strong>${travelers} ${
    travelers > 1 ? "travelers" : "traveler"
  }</strong>:`;

export const FIAT_AND_REWARDS = (fiat: string, rewards: string) =>
  `${fiat}${rewards ? ` or ${rewards}` : ""}`;
export const SAVING_TEXT = (amount: string) =>
  `You’ll save <strong>${amount}</strong> per traveler if you book now.`;

export const PRICE_AFTER_CAP = (amount: string) =>
  `Your ticket price will be ${amount}`;
export const MAX_CAP_REACHED = (amount: string) =>
  `You hit the <strong>maximum savings of ${amount}</strong> per ticket.`;

export const CONTINUE = "Continue";
export const CONTINUE_TO_BOOK = "Continue to Booking";

export const FAQ_TITLE = "Questions about your frozen price?";

export const FAQ_SUBTITLE =
  "Read our support articles for help with your frozen price.";

export const FAQ_1 = "How do frozen prices work?";
export const FAQ_1_LINK =
  "https://www.capitalone.com/help-center/capital-one-travel/";
export const FAQ_2 = "How do I book a flight with my frozen price?";
export const FAQ_2_LINK =
  "https://www.capitalone.com/help-center/capital-one-travel/";

export const LOADING_FROZEN_PRICE_TEXT = "Loading frozen price";
export const ITINERARY_UPDATED = "Your itinerary has been updated.";

export const OR_SEPARATOR = "or";
