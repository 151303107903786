import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  GenericDropdown,
  Icon,
  IconName,
  PolicyStatusSelection,
} from "halifax";
import * as textConstants from "./textConstants";
import "./styles.scss";

export interface IPolicyFilterProps {
  policyFilter: boolean;
  setPolicyFilter: (policyFilter: boolean) => void;
  showDropdownContentOnly?: boolean;
  appliedLabel?: string;
  icon?: IconName;
  title?: string;
}

export const PolicyFilterSelection = (props: IPolicyFilterProps) => {
  const {
    policyFilter,
    setPolicyFilter,
    showDropdownContentOnly,
    appliedLabel,
    title,
    icon,
  } = props;

  return (
    <Box className="flight-policy-selection-dropdown">
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={clsx("b2b-shop-filter", {
            "has-value": policyFilter,
          })}
          popoverClassName={clsx("flight-policy-selection-popover", "b2b")}
          dropdownLabel={textConstants.POLICY_FILTER_LABEL(appliedLabel)}
          ariaLabel="Policy status filter"
          dropdownContent={
            <PolicyStatusSelection
              isInPolicy={policyFilter}
              setIsInPolicy={setPolicyFilter}
            />
          }
        />
      )}
      {!!showDropdownContentOnly && (
        <>
          {!!title && showDropdownContentOnly && (
            <Box className="header-container">
              {icon && <Icon name={icon} />} {title}
            </Box>
          )}
          <PolicyStatusSelection
            isInPolicy={policyFilter}
            setIsInPolicy={setPolicyFilter}
          />
        </>
      )}
    </Box>
  );
};
