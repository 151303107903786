import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  FlightDetailsSummary,
  IconContentView,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import {
  FareDetails,
  TripDetails,
  UtasPolicyCategory,
  FtcType,
  REFUNDABLE_FARES_POINT_ONE,
  REFUNDABLE_FARES_POINT_THREE,
  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
  VirtualInterlineEntryPoint,
  VirtualInterlineModalProperties,
  VIEWED_SELF_TRANSFER_MODAL,
} from "redmond";
import {
  DullesUta,
  UtasPolicyAssessment,
} from "redmond/apis/dulles/interfaces";
import { startCase } from "lodash-es";
import clsx from "clsx";
import { getPortalName } from "@capone/common";

import "./styles.scss";
import { getSliceIndex } from "../../../../utils/flights";
import * as constants from "./constants";
import { getPlusDays } from "../../reducer/utils";
import { getFtcTypeArr, secondBulletText } from "../../utils/helpers";
import { ClientContext } from "../../../../App";
import { FlightVICombinationBanner } from "../FlightVICombinationBanner";
import {
  FlightMissedConnectionGuarantee,
  SelfTransferBanner,
} from "../FlightMissedConnectionGuarantee";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { IVirtualInterliningVariant } from "../VirtualIinterliningModal";
import { airlinesCountTripSegment } from "../../v2/components/FlightList/components/FlightDetails/component";

interface IFlightShopReviewDetailsProps {
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMultiTicket?: boolean;
  isMixedCabinClass?: boolean;
  stacked?: boolean;
  nonHackerFareTitle?: string;
  hasActiveRefundableFare: boolean;
  isMulticity?: boolean;
  multicitySliceIndex?: number;
  isSeatsUXOptimizationExperiment?: boolean;
  isVITripSelected: boolean;
  hasTravelFusionFareBrand: boolean;
  setOpenVIVariantModal: (variant: IVirtualInterliningVariant) => void;
  setOpenMultipleAirlinesFares: (value: boolean) => void;
}

enum FlightShopSymbol {
  ALERT,
  INCLUDED,
  PURCHASABLE,
  UNAVAILABLE,
}

export interface IRestrictionProps {
  symbol: FlightShopSymbol;
  name: string;
  description: string | JSX.Element;
}

export const getRestrictions = ({
  fareDetails,
  sliceIndex,
  hasActiveRefundableFare,
  hasTravelFusionFareBrand,
  isSeatsUXOptimizationExperiment = false,
}: {
  fareDetails: FareDetails;
  sliceIndex: number;
  hasActiveRefundableFare: boolean;
  hasTravelFusionFareBrand: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
}): IRestrictionProps[] => {
  const utas = fareDetails.slices[sliceIndex].amenitiesUtas?.utas;
  const changePolicy =
    utas?.changePolicy?.beforeDeparture ?? utas?.changePolicy?.anytime;
  const cancelPolicy =
    utas?.cancellationPolicy?.beforeDeparture ??
    utas?.cancellationPolicy?.anytime;
  const ftcChangeTypes = changePolicy ? getFtcTypeArr([changePolicy]) : [];
  const ftcCancelTypes = cancelPolicy ? getFtcTypeArr([cancelPolicy]) : [];

  let advanceChange: IRestrictionProps | undefined;
  let cancellation: IRestrictionProps | undefined;

  switch (ftcChangeTypes[0]) {
    case FtcType.FtcNoFees:
      if (ftcCancelTypes[0] === FtcType.NoFtc) {
        // If flight is non-refundable, then check changePolicy if it's FTC for free/fee. If refundable, don't overwrite cancellation policy
        cancellation = {
          symbol: FlightShopSymbol.ALERT,
          name: constants.CANCELLATION,
          description: constants.TRAVEL_CREDIT_FOR_FREE,
        };
      }
      advanceChange = {
        symbol: FlightShopSymbol.INCLUDED,
        name: constants.ADVANCE_CHANGE,
        description: constants.ADVANCE_CHANGE_FOR_FREE,
      } as IRestrictionProps;
      break;
    case FtcType.FtcWithFees:
      if (ftcCancelTypes[0] === FtcType.NoFtc) {
        cancellation = {
          symbol: FlightShopSymbol.ALERT,
          name: constants.CANCELLATION,
          description: constants.TRAVEL_CREDIT_FOR_A_FEE,
        };
      }
      advanceChange = {
        symbol: FlightShopSymbol.PURCHASABLE,
        name: constants.ADVANCE_CHANGE,
        description: constants.ADVANCE_CHANGE_FOR_A_FEE,
      } as IRestrictionProps;
      break;
    case FtcType.NoFtc:
      advanceChange = {
        symbol: FlightShopSymbol.UNAVAILABLE,
        name: constants.ADVANCE_CHANGE,
        description: constants.ADVANCE_CHANGE_NOT_ALLOWED,
      } as IRestrictionProps;
      break;
    default:
      break;
  }

  const getUtaSymbol = (uta: DullesUta) => {
    switch (uta.assessment) {
      case UtasPolicyAssessment.Benefit:
        return FlightShopSymbol.INCLUDED;
      case UtasPolicyAssessment.Fee:
        return FlightShopSymbol.PURCHASABLE;
      default:
        return FlightShopSymbol.UNAVAILABLE;
    }
  };

  const restrictions: IRestrictionProps[] =
    utas?.utas.reduce(
      (restrictionsToDisplay: IRestrictionProps[], uta: DullesUta) => {
        let restriction: IRestrictionProps;
        // note: replace "advance-change" from the utas.utas list with changePolicy data
        if (
          uta.category === UtasPolicyCategory.AdvanceChange &&
          advanceChange
        ) {
          restriction = advanceChange;
        } else if (
          uta.category === UtasPolicyCategory.Cancellation &&
          cancellation
        ) {
          restriction = cancellation;
        } else if (uta.headline == "Checked or Carry-on Bags") {
          // Overriding bag allowance for Spirit and Frontier to avoid confusions
          // see: https://hopper-jira.atlassian.net/browse/CMKT-1000
          restriction = {
            symbol: FlightShopSymbol.PURCHASABLE,
            name: uta.headline,
            description: uta.description,
          };
        } else {
          restriction = {
            symbol: getUtaSymbol(uta),
            // TODO: probably need a different type for category
            name: uta.category
              .split("-")
              .reduce(
                (name: string, segment: string) =>
                  `${name}${name.length > 0 ? " " : ""}${startCase(segment)}`,
                ""
              ),
            description: uta.description,
          };
        }
        if (
          uta.category === "seat-selection" &&
          isSeatsUXOptimizationExperiment
        ) {
          restrictionsToDisplay.unshift(restriction);
        } else {
          restrictionsToDisplay.push(restriction);
        }
        return restrictionsToDisplay;
      },
      [] as IRestrictionProps[]
    ) ?? [];

  // note: when "advance-change" is not found in utas.utas, push in changePolicy data
  if (
    !utas?.utas.find(
      (policy) => policy.category === UtasPolicyCategory.AdvanceChange
    ) &&
    advanceChange
  ) {
    restrictions.push(advanceChange);
  }

  // TODO: Add customer support (find it from data)
  restrictions.push({
    symbol: FlightShopSymbol.INCLUDED,
    name: constants.CUSTOMER_SUPPORT_HEADER_TEXT,
    description: constants.CUSTOMER_SUPPORT_SUBHEADER_TEXT,
  });

  if (hasActiveRefundableFare) {
    const firstBullet = REFUNDABLE_FARES_POINT_ONE({
      variant: "v1",
      useStrong: false,
      punctuation: ".",
    });
    const secondBullet = secondBulletText({
      ftcTypes: ftcChangeTypes,
      cashCoveragePercentage: 80,
      useStrong: false,
      hasTravelFusionFareBrand: hasTravelFusionFareBrand,
    });
    const thirdBullet = REFUNDABLE_FARES_POINT_THREE(getPortalName(), {
      punctuation: ".",
    });

    restrictions.unshift({
      symbol: FlightShopSymbol.INCLUDED,
      name: thirdBullet,
      description: "",
    });
    restrictions.unshift({
      symbol: FlightShopSymbol.INCLUDED,
      name: secondBullet,
      description: "",
    });
    restrictions.unshift({
      symbol: FlightShopSymbol.INCLUDED,
      name: firstBullet,
      description: "",
    });
  }
  return restrictions;
};

export const FlightShopReviewDetails = (
  props: IFlightShopReviewDetailsProps
) => {
  const {
    departure,
    tripDetails,
    fareDetails,
    isMultiTicket,
    isMixedCabinClass,
    stacked,
    nonHackerFareTitle,
    hasActiveRefundableFare,
    isMulticity,
    multicitySliceIndex,
    isSeatsUXOptimizationExperiment = false,
    isVITripSelected,
    setOpenVIVariantModal,
    setOpenMultipleAirlinesFares,
    hasTravelFusionFareBrand,
  } = props;

  const sliceIndex = isMulticity
    ? multicitySliceIndex!
    : getSliceIndex(departure, tripDetails);
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const fareIndex = isMulticity
    ? multicitySliceIndex!
    : fareDetails.slices.findIndex((slice) =>
        departure ? slice.outgoing : !slice.outgoing
      );

  const slice = departure ? tripDetails.slices[0] : tripDetails.slices[1];
  const plusDays = getPlusDays(slice);

  const fare = fareDetails.slices[fareIndex];
  const fareSliceIndex = isMulticity ? multicitySliceIndex! : departure ? 0 : 1;
  const tripDetailsSlice = isMulticity
    ? tripDetails.slices[multicitySliceIndex!]
    : departure
    ? tripDetails.slices[0]
    : tripDetails.slices[tripDetails.slices.length - 1];

  return (
    <Box
      className={clsx("flight-shop-review-details-root", {
        mobile: matchesMobile,
      })}
    >
      <Box
        className={clsx("flight-shop-review-details-container", {
          stacked,
        })}
      >
        <FlightDetailsSummary
          className="review-itinerary-flight-details"
          showTitle={false}
          segments={tripDetailsSlice.segmentDetails}
          departureTime={tripDetailsSlice.departureTime}
          planeInfo={
            fare.amenitiesUtas?.amenities?.aircraft.info.displayText || ""
          }
          fareClass={fare.fareShelf?.shortBrandName || ""}
          plusDays={plusDays}
          fareSlice={fareDetails.slices[fareSliceIndex]}
          isMixedCabinClass={isMixedCabinClass}
          isAgentPortal={isAgentPortal}
          showAvailableSeats={isSeatsUXOptimizationExperiment}
          fareDetails={tripDetails.fareDetails}
          isOutgoing={departure}
          flightCombinationBanner={
            airlinesCountTripSegment(tripDetailsSlice.segmentDetails) > 0 &&
            setOpenMultipleAirlinesFares && (
              <FlightVICombinationBanner
                isMobile={matchesMobile}
                onClick={() => setOpenMultipleAirlinesFares(true)}
                ctaText={constants.AIRLINE_SPECIFIC_RESTRICTIONS}
              />
            )
          }
          missedConnectionGuarantee={
            isVITripSelected && (
              <>
                <FlightMissedConnectionGuarantee
                  isVITripSelected={isVITripSelected}
                  isMobile={matchesMobile}
                  onClick={() => {
                    trackEvent({
                      eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                      properties: {
                        entry_point: VirtualInterlineEntryPoint.Details,
                      } as VirtualInterlineModalProperties,
                    });
                    setOpenVIVariantModal &&
                      setOpenVIVariantModal("missedConnectionGuarantee");
                  }}
                />
                <SelfTransferBanner
                  iconName={IconName.BookTravel}
                  onClick={() => {
                    trackEvent({
                      eventName: VIEWED_SELF_TRANSFER_MODAL,
                      properties: {
                        entry_point: VirtualInterlineEntryPoint.Details,
                      } as VirtualInterlineModalProperties,
                    });
                    setOpenVIVariantModal && setOpenVIVariantModal("selfCheck");
                  }}
                />
              </>
            )
          }
        />
        {!isVITripSelected && (
          <Box className="restriction-details">
            <Box className="restriction-image-overlay-with-text">
              <Box className="itinerary-overlay-text">
                <Typography className="title">
                  {isMultiTicket
                    ? constants.REVIEW_ITINERARY_HEADER_TEXT_COMBINATION
                    : nonHackerFareTitle ?? constants.READY_TO_BOOK_HEADER_TEXT}
                </Typography>
                <Typography className="subtitle">
                  {isMultiTicket
                    ? constants.REVIEW_ITINERARY_SUBHEADER_TEXT_COMBINATION
                    : constants.REVIEW_ITINERARY_SUBHEADER_TEXT}
                </Typography>
              </Box>
            </Box>

            <Box className="restrictions-section">
              {getRestrictions({
                fareDetails,
                sliceIndex,
                hasActiveRefundableFare,
                isSeatsUXOptimizationExperiment,
                hasTravelFusionFareBrand,
              }).map((restriction) => (
                <Restriction
                  key={restriction.name}
                  symbol={restriction.symbol}
                  name={restriction.name}
                  description={
                    <Typography variant="subtitle2" className="description">
                      {restriction.description}
                    </Typography>
                  }
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const restrictionIcon: { [k in FlightShopSymbol]: JSX.Element } = {
  [FlightShopSymbol.ALERT]: (
    <Icon className="icon-alert" name={IconName.AlertCircleOutline} />
  ),
  [FlightShopSymbol.INCLUDED]: (
    <Icon className="icon-available" name={IconName.CheckCircleTransparent} />
  ),
  [FlightShopSymbol.PURCHASABLE]: (
    <Icon className="icon-paid" name={IconName.MoneyOutlineTransparentIcon} />
  ),
  [FlightShopSymbol.UNAVAILABLE]: (
    <Icon className="icon-unavailable" name={IconName.NotAllowedSign} />
  ),
};

export const Restriction = (props: IRestrictionProps) => {
  const { symbol, name, description } = props;

  return (
    <IconContentView
      className="restriction"
      icon={restrictionIcon[symbol]}
      content={
        <>
          <Typography className="title">{name}</Typography>
          {description}
        </>
      }
    />
  );
};
