import {
  AvailabilityResponse,
  AvailabilityRequestEnum,
  Airport,
  IResult,
  GuestsSelection,
  FlightItineraryRequest,
  BookedFlightItineraryWithDepartureTime,
  Airline,
  FlightItineraryState,
  PotentialCrossSellOffer,
} from "redmond";
import { BookedFlightItinerary } from "@b2bportal/air-booking-api";
import * as actionTypes from "./constants";

export interface IFetchCrossSellHotelAvailability {
  type:
    | actionTypes.FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY
    | actionTypes.FETCH_MORE_CROSS_SELL_HOTEL_AVAILABILITY;
  requestType: AvailabilityRequestEnum;
  bookedItinerary?: BookedFlightItinerary;
  airportMap?: { [key: string]: Airport };
}

export const fetchInitialCrossSellHotelAvailability = (
  bookedItinerary?: BookedFlightItinerary,
  airportMap?: { [key: string]: Airport }
): IFetchCrossSellHotelAvailability => ({
  type: actionTypes.FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY,
  requestType: AvailabilityRequestEnum.InitialSearch,
  bookedItinerary,
  airportMap,
});

export const fetchMoreCrossSellHotelAvailability =
  (): IFetchCrossSellHotelAvailability => ({
    type: actionTypes.FETCH_MORE_CROSS_SELL_HOTEL_AVAILABILITY,
    requestType: AvailabilityRequestEnum.FollowUpSearch,
  });

export interface ISetCrossSellHotelAvailabilityResults {
  type: actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS;
  payload: ISetCrossSellHotelAvailabilityResultsPayload;
  latency: number;
}

export type ISetCrossSellHotelAvailabilityResultsPayload = AvailabilityResponse;

export const setCrossSellHotelAvailabilityResults = (args: {
  payload: ISetCrossSellHotelAvailabilityResultsPayload;
  latency: number;
}): ISetCrossSellHotelAvailabilityResults => ({
  type: actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS,
  ...args,
});

export const setHotelAvailabilityCallStateFailed =
  (): ISetHotelAvailabilityCallStateFailed => ({
    type: actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface ISetHotelAvailabilityCallStateFailed {
  type: actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_CALL_STATE_FAILED;
}

export interface ISetSearchedDates {
  type: actionTypes.SET_SEARCHED_DATES;
  searchedFromDate: Date;
  searchedUntilDate: Date;
}

export const setSearchedDates = (
  searchedFromDate: Date,
  searchedUntilDate: Date
): ISetSearchedDates => ({
  type: actionTypes.SET_SEARCHED_DATES,
  searchedFromDate,
  searchedUntilDate,
});

export interface ISetSearchedLocationResult {
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT;
  searchedLocationResult: IResult | null;
}

export const setSearchedLocationResult = (
  searchedLocationResult: IResult | null
): ISetSearchedLocationResult => ({
  type: actionTypes.SET_SEARCHED_LOCATION_RESULT,
  searchedLocationResult,
});

export interface ISetSearchedOccupancyCounts {
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setSearchedOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetSearchedOccupancyCounts => ({
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS,
  counts,
});

export interface ISetSelectedLodgingIndex {
  type: actionTypes.SET_SELECTED_LODGING_INDEX;
  index: number;
}

export const setSelectedLodgingIndex = (index: number) => ({
  type: actionTypes.SET_SELECTED_LODGING_INDEX,
  index,
});

export interface IFetchUpcomingFlightCrossSell {
  type: actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL;
  request: FlightItineraryRequest;
}

export const fetchUpcomingFlightCrossSell = (
  request: FlightItineraryRequest
): IFetchUpcomingFlightCrossSell => ({
  type: actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL,
  request,
});

export interface IFetchUpcomingFlightCrossSellFailed {
  type: actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED;
}

export const fetchUpcomingFlightCrossSellFailed =
  (): IFetchUpcomingFlightCrossSellFailed => ({
    type: actionTypes.FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED,
  });

export interface ISetUpcomingFlights {
  type: actionTypes.SET_UPCOMING_FLIGHTS;
  flights: {
    [key: string]: BookedFlightItineraryWithDepartureTime[];
  };
  itineraryTypes: FlightItineraryState[];
  airports: { [key: string]: Airport | undefined };
  airlines: { [key: string]: Airline | undefined };
}

export const setUpcomingFlights = (
  flights: {
    [key: string]: BookedFlightItineraryWithDepartureTime[];
  },
  itineraryTypes: FlightItineraryState[],
  airports: { [key: string]: Airport | undefined },
  airlines: { [key: string]: Airline | undefined }
): ISetUpcomingFlights => ({
  type: actionTypes.SET_UPCOMING_FLIGHTS,
  flights,
  itineraryTypes,
  airports,
  airlines,
});

export interface ISetMostUpcomingFlight {
  type: actionTypes.SET_MOST_UPCOMING_FLIGHT;
  flight: BookedFlightItineraryWithDepartureTime;
}

export const setMostUpcomingFlight = (
  flight: BookedFlightItineraryWithDepartureTime
): ISetMostUpcomingFlight => ({
  type: actionTypes.SET_MOST_UPCOMING_FLIGHT,
  flight,
});

export interface IFetchPotentialCrossSellOffers {
  type: actionTypes.FETCH_POTENTIAL_CROSS_SELL_OFFERS;
}
export const fetchPotentialCrossSellOffers =
  (): IFetchPotentialCrossSellOffers => ({
    type: actionTypes.FETCH_POTENTIAL_CROSS_SELL_OFFERS,
  });

export interface ISetPotentialCrossSellOffers {
  type: actionTypes.SET_POTENTIAL_CROSS_SELL_OFFERS;
  offers: PotentialCrossSellOffer[];
}

export const setPotentialCrossSellOffers = (
  offers: PotentialCrossSellOffer[]
): ISetPotentialCrossSellOffers => ({
  type: actionTypes.SET_POTENTIAL_CROSS_SELL_OFFERS,
  offers,
});

export interface IFetchPotentialCrossSellOffersFailed {
  type: actionTypes.FETCH_POTENTIAL_CROSS_SELL_OFFERS_FAILED;
}

export const fetchPotentialCrossSellOffersFailed =
  (): IFetchPotentialCrossSellOffersFailed => ({
    type: actionTypes.FETCH_POTENTIAL_CROSS_SELL_OFFERS_FAILED,
  });

export type FlightToHotelCrossSellActions =
  | IFetchCrossSellHotelAvailability
  | ISetCrossSellHotelAvailabilityResults
  | ISetHotelAvailabilityCallStateFailed
  | ISetSearchedDates
  | ISetSearchedLocationResult
  | ISetSearchedOccupancyCounts
  | ISetSelectedLodgingIndex
  | IFetchUpcomingFlightCrossSell
  | IFetchUpcomingFlightCrossSellFailed
  | ISetUpcomingFlights
  | ISetMostUpcomingFlight
  | IFetchPotentialCrossSellOffers
  | ISetPotentialCrossSellOffers
  | IFetchPotentialCrossSellOffersFailed;
