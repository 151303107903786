import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
  AccordionCollection,
  ActionButton,
} from "halifax";

import {
  StopsOptionSelection,
  MaxPriceFilterSelection,
  AirlineFilterSelection,
  DepartureArrivalSelectionDropdown,
  AirportFilterSelection,
  FlightNumberFilterSelection,
} from "../index";
import * as constants from "./constants";
import "./styles.scss";
import clsx from "clsx";
import { APPLY_FILTERS_TEXT } from "../../../../../FlightSearchFilter/textConstants";

export interface IMobileFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
}
export const MobileFlightShopSearchFilter = (
  props: IMobileFlightShopSearchFilterProps
) => {
  const [openSearchFilter, setOpenSearchFilter] =
    React.useState<boolean>(false);

  const handleCloseFilter = () => {
    setOpenSearchFilter(false);
  };

  const { hideAirportFilter } = props;

  return (
    <Box className="mobile-flight-shop-search-filter-root">
      <Box className="mobile-flight-shop-search-filter-container">
        <ActionLink
          className="filter-open-button"
          content={
            <Typography variant="body1">
              {constants.FILTERS_BUTTON_TEXT}
            </Typography>
          }
          onClick={() => setOpenSearchFilter(true)}
        />
        <MobilePopoverCard
          open={openSearchFilter}
          onClose={handleCloseFilter}
          fullScreen={true}
          className={"mobile-flight-shop-search-filter"}
          contentClassName={"mobile-flight-shop-search-filter-wrapper"}
          bottomButton={
            <ActionButton
              className={clsx("mobile-flight-shop-filter-button")}
              onClick={() => {
                handleCloseFilter();
              }}
              message={APPLY_FILTERS_TEXT}
            />
          }
          topRightButton={
            <ActionLink
              className="filter-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={handleCloseFilter}
            />
          }
        >
          <Box className="mobile-flight-shop-search-filter-content-container">
            <AccordionCollection
              className="flight-shop-search-filter-accordions-container"
              accordionContents={
                hideAirportFilter
                  ? [
                      // StopsOption
                      {
                        title: (
                          <Box className="header-container">
                            {constants.STOPS_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <StopsOptionSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // MaxPrice
                      {
                        title: (
                          <Box className="header-container">
                            {constants.PRICE_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <MaxPriceFilterSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Airline
                      {
                        title: (
                          <Box className="header-container">
                            {constants.AIRLINE_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <AirlineFilterSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Departure
                      {
                        title: (
                          <Box className="header-container">
                            {constants.DEPARTURE_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <DepartureArrivalSelectionDropdown
                              showDropdownContentOnly={true}
                              showDepartureSelectionOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Arrival
                      {
                        title: (
                          <Box className="header-container">
                            {constants.ARRIVAL_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <DepartureArrivalSelectionDropdown
                              showDropdownContentOnly={true}
                              showArrivalSelectionOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Flight Number
                      {
                        title: (
                          <Box className="header-container">
                            {constants.FLIGHT_NUMBER_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <FlightNumberFilterSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                    ]
                  : [
                      // StopsOption
                      {
                        title: (
                          <Box className="header-container">
                            {constants.STOPS_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <StopsOptionSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // MaxPrice
                      {
                        title: (
                          <Box className="header-container">
                            {constants.PRICE_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <MaxPriceFilterSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Airline
                      {
                        title: (
                          <Box className="header-container">
                            {constants.AIRLINE_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <AirlineFilterSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Departure
                      {
                        title: (
                          <Box className="header-container">
                            {constants.DEPARTURE_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <DepartureArrivalSelectionDropdown
                              showDropdownContentOnly={true}
                              showDepartureSelectionOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Arrival
                      {
                        title: (
                          <Box className="header-container">
                            {constants.ARRIVAL_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <DepartureArrivalSelectionDropdown
                              showDropdownContentOnly={true}
                              showArrivalSelectionOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Airport
                      {
                        title: (
                          <Box className="header-container">
                            {constants.AIRPORTS_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <AirportFilterSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                      // Flight Number
                      {
                        title: (
                          <Box className="header-container">
                            {constants.FLIGHT_NUMBER_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <FlightNumberFilterSelection
                              showDropdownContentOnly={true}
                            />
                          </Box>
                        ),
                      },
                    ]
              }
            />
          </Box>
        </MobilePopoverCard>
      </Box>
    </Box>
  );
};
