import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { FlightFreezeCustomize } from "./component";
import {
  currentPriceFreezeSelector,
  getTripCategoryFromPriceFreeze,
} from "../../reducer/selectors";
import {
  isAddOnOptionAvailableSelector,
  fetchAncillaryOfferCallStateSelector,
} from "../../../shop/reducer/selectors";
import {
  isCustomizePageMarketplaceEnabledSelector,
  isMultiPageMarketplaceEnabledSelector,
  isCfarEnabledSelector,
  isChfarEnabledSelector,
  isDisruptionProtectionEnabledSelector,
} from "../../../ancillary/reducer/selectors";
import { populateFlightBookQueryParams } from "../../../book/actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    currentPriceFreeze: currentPriceFreezeSelector(state),
    tripCategory: getTripCategoryFromPriceFreeze(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    isCustomizePageMarketplaceEnabled:
      isCustomizePageMarketplaceEnabledSelector(state),
    isMultiPageMarketplaceEnabled: isMultiPageMarketplaceEnabledSelector(state),
    isCfarEnabled: isCfarEnabledSelector(state),
    isChfarEnabled: isChfarEnabledSelector(state),
    isDisruptionProtectionEnabled: isDisruptionProtectionEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightFreezeCustomizeConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFreezeCustomize = connector(
  withRouter(FlightFreezeCustomize)
);
