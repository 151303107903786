import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../reducers/types";
import { FlightBook } from "./component";
import {
  selectedTripSelector,
  tripDetailsSelector,
  isFlightBookWithAncillariesActiveSelector,
  flightsByFlightShopTypeSelector,
} from "../shop/reducer/selectors";
import { actions } from "./actions";
import {
  getFinalizedItinerary,
  getFlightBookType,
  getIsBookingValid,
  getReviewFlightDetailsProperties,
  getPriceQuoteWithUpdatedAncillary,
  getShopSummarySearchString,
  getViewItineraryRestrictionsProperties,
} from "./reducer";
import { getSelectedAccount } from "../rewards/reducer";
import { getDisplayPfRefundModalSelector } from "../freeze/reducer";
import {
  fetchTripSummariesV3,
  fetchMulticitySummaries,
} from "../shop/actions/actions";
import { getTripCategory } from "../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  const tripId = selectedTripSelector(state).tripId || "";
  return {
    tripDetails: tripDetailsSelector(state, tripId),
    tripCategory: getTripCategory(state),
    finalizedItinerary: getFinalizedItinerary(state),
    isBookingValid: getIsBookingValid(state),
    reviewFlightDetailsProperties: getReviewFlightDetailsProperties(state),
    viewItineraryRestrictionsProperties: getViewItineraryRestrictionsProperties(state),
    selectedAccount: getSelectedAccount(state),
    displayPriceFreezeRefundModal: getDisplayPfRefundModalSelector(state),
    isFlightBookWithAncillariesActive:
      isFlightBookWithAncillariesActiveSelector(state),
    priceQuote: getPriceQuoteWithUpdatedAncillary(state),
    flightBookType: getFlightBookType(state),
    airportMap: flightsByFlightShopTypeSelector(state)?.airports,
    shopSummarySearchString: getShopSummarySearchString(state),
  };
};

const mapDispatchToProps = {
  setUpFlightBookParams: actions.setUpFlightBookParams,
  resetFinalized: actions.resetFinalized,
  setQuote: actions.setQuote,
  setIsSimilarFlightsEnabled: actions.setIsSimilarFlightsEnabled,
  closeSession: actions.closeSession,
  fetchTripSummariesV3,
  fetchMulticitySummaries,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightBookConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightBook = withRouter(connector(FlightBook));
