import {
  takeLeading,
  takeLatest,
  take,
  put,
  race,
  call,
} from "redux-saga/effects";

import { actions, actionTypes } from "../actions";
import { setUpFlightFreezeParamsSaga } from "./setUpFlightFreezeParamsSaga";
import { getPriceFreezeOfferSaga } from "./getPriceFreezeOfferSaga";
import { getPriceFreezeOfferDataSaga } from "./getPriceFreezeOfferDataSaga";
import { generateCustomPriceFreezeOfferSaga } from "./generateCustomPriceFreezeOfferSaga";
import { initializeOfferDataAndCustomOfferSaga } from "./initializeOfferDataAndCustomOfferSaga";
import { fetchPriceFreezeSaga } from "./fetchPriceFreezeSaga";
import { refundPriceFreezeSaga } from "./refundPriceFreezeSaga";
import { fetchPriceFreezeFareQuoteSaga } from "./fetchPriceFreezeFareQuoteSaga";
import { fetchApplicablePriceFreezeTravelWalletItemsSaga } from "./fetchApplicablePriceFreezeTravelWalletItems";
import { cancelScheduledQuote } from "../../book/actions/actions";
import { cancelPriceFreezeFareQuoteSaga } from "./cancelPriceFreezeFareQuoteSaga";
import { watchFetchPriceFreezeOfferDataForFareSaga } from "../experiments/c1-price-freeze-new-flights-list-entry/sagas/watchFetchPriceFreezeOfferDataForFareSaga";

export function* watchSetUpFlightFreezeParams() {
  yield takeLeading(
    actionTypes.SET_UP_FLIGHT_FREEZE_PARAMS,
    setUpFlightFreezeParamsSaga
  );
}

export function* watchGetPriceFreezeOffer() {
  yield takeLatest(actionTypes.GET_PRICE_FREEZE_OFFER, getPriceFreezeOfferSaga);
}

export function* watchGetPriceFreezeOfferData() {
  yield takeLatest(
    actionTypes.GET_PRICE_FREEZE_OFFER_DATA,
    getPriceFreezeOfferDataSaga
  );
}

export function* watchGenerateCustomPriceFreezeOffer() {
  yield takeLatest(
    actionTypes.GENERATE_CUSTOM_PRICE_FREEZE_OFFER,
    generateCustomPriceFreezeOfferSaga
  );
}

export function* watchInitializeOfferDataAndCustomOffer() {
  yield takeLatest(
    actionTypes.INITIALIZE_OFFER_DATA_AND_CUSTOM_OFFER,
    initializeOfferDataAndCustomOfferSaga
  );
}

export function* watchFetchPriceFreeze() {
  yield takeLatest(actionTypes.FETCH_PRICE_FREEZE, fetchPriceFreezeSaga);
}

export function* watchRefundPriceFreeze() {
  yield takeLatest(actionTypes.REFUND_PRICE_FREEZE, refundPriceFreezeSaga);
}

export function* watchCancelPriceFreezeFareQuote() {
  yield takeLatest(
    actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE_CANCELLED,
    cancelPriceFreezeFareQuoteSaga
  );
}
export function* watchFetchPriceFreezeFareQuote() {
  yield takeLatest(
    actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE,
    function* (action: actions.IFetchPriceFreezeFareQuote) {
      const result: { task: unknown; cancel: unknown } = yield race({
        task: call(fetchPriceFreezeFareQuoteSaga, action),
        cancel: take(actionTypes.FETCH_PRICE_FREEZE_FARE_QUOTE_CANCELLED),
      });

      // If the cancel effect was completed first, we will dispatch the actions in this block
      if (result.cancel) {
        // Cancel scheduleQuoteSaga
        yield put(cancelScheduledQuote());
      }
    }
  );
}

export function* watchFetchTravelWalletCreditHistorySaga() {
  yield takeLeading(
    actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
    fetchApplicablePriceFreezeTravelWalletItemsSaga
  );
}

export const experimentsSagas = {
  watchFetchPriceFreezeOfferDataForFareSaga,
};
