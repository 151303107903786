import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  airportsSelector,
  selectedTripSelector,
  tripDetailsSelector,
  isSpiritOrFrontierAirlinesSelectedSelector,
} from "../../../../reducer/selectors";
import { ISelectedTrip } from "../../../../reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer";
import { FlightDetails } from "./component";
import { getDepartureDate, getReturnDate } from "../../../../../search/reducer";

export interface IFlightDetailsProps {
  tripId: string;
}

const mapStateToProps = (state: IStoreState, props: IFlightDetailsProps) => {
  const selectedOWRTrip = selectedTripSelector(state) as ISelectedTrip;
  return {
    tripDetails: tripDetailsSelector(state, props.tripId),
    outgoingFareRating: selectedOWRTrip.outgoingFareRating,
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    airports: airportsSelector(state, props.tripId),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    isSpiritOrFrontierAirlinesSelected:
      isSpiritOrFrontierAirlinesSelectedSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightDetails);
