import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import {
  selectedTripSelector,
  tripDetailsSelector,
  selectedChfarIdSelector,
  chfarOffersSelector,
} from "../../../../reducer/selectors";
import { setSelectedChfarId } from "../../../../actions/actions";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer/selectors";
import { populateFlightBookQueryParams } from "../../../../../book/actions/actions";
import { ChfarAirDetails } from "halifax";
import { PATH_CHFAR } from "../../../../../../utils/urlPaths";
import { DO_NOT_APPLY_OPTION_KEY } from "../../../../reducer";
import { ChfarId } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  return {
    doNotApplyChfarId: {
      quoteId: DO_NOT_APPLY_OPTION_KEY,
    } as ChfarId,
    tripDetails,
    selectedChfarId: selectedChfarIdSelector(state),
    chfarOffers: chfarOffersSelector(state),
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    handleViewTerms: () => {
      window.open(PATH_CHFAR, "_blank")?.focus();
    },
  };
};

const mapDispatchToProps = {
  setSelectedChfarId,
  populateFlightBookQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ChfarDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedChfarDetails = connector(withRouter(ChfarAirDetails));
