import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { customPriceFreezeOfferPath } from "../paths";
import {
  GenerateCustomOfferRequest,
  GenerateCustomOfferResponse,
  GenerateCustomOfferResponseEnum,
} from "redmond";

export const generateCustomPriceFreezeOffer = (
  body: GenerateCustomOfferRequest
): Promise<GenerateCustomOfferResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      if (body.tripId) {
        const res: AxiosResponse<GenerateCustomOfferResponse> = await axios.post(customPriceFreezeOfferPath, body, {
          baseURL: config.baseURL,
        });
        resolve(res.data);
      } else {
        resolve({
          GenerateCustomOfferResponse:
            GenerateCustomOfferResponseEnum.NotAvailable,
        });
      }
    } catch (e) {
      reject(e);
    }
  });

export default generateCustomPriceFreezeOffer;
