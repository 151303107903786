import React from "react";
import { SliceStopCountFilter } from "redmond";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { RadioDropdown } from "halifax";
import { StopsOptionSelectionProps } from "./container";

interface IStopsOptionSelectionProps extends StopsOptionSelectionProps {
  showDropdownContentOnly?: boolean;
}

export const StopsOptionSelection = (props: IStopsOptionSelectionProps) => {
  const {
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    showDropdownContentOnly,
  } = props;

  const handleChange = (value: string) => {
    setStopsOption(value as SliceStopCountFilter);
    setRerunPrediction();
  };

  return (
    <RadioDropdown
      dropdownLabel={textConstants.STOPS_HEADER_TEXT}
      options={stopsOptions}
      setOption={handleChange}
      selected={stopsOption}
      showDropdownContentOnly={showDropdownContentOnly}
      buttonClassName="b2b-shop-filter"
      groupAriaLabel={textConstants.CHOOSE_STOPS_OPTION_TEXT}
      buttonAriaLabel={textConstants.CHANGE_STOPS_OPTION_TEXT}
      popoverClassName="b2b"
    />
  );
};

interface IStopsOption {
  label: string;
  value: SliceStopCountFilter;
}

const stopsOptions: IStopsOption[] = [
  {
    label: textConstants.ANY_NUMBER_OF_STOPS_TEXT,
    value: SliceStopCountFilter.ANY_NUMBER,
  },
  {
    label: textConstants.NO_STOPS_ONLY,
    value: SliceStopCountFilter.NONE,
  },
  {
    label: textConstants.ONE_STOP_OR_FEWER,
    value: SliceStopCountFilter.ONE_OR_LESS,
  },
  {
    label: textConstants.TWO_STOPS_OR_FEWER,
    value: SliceStopCountFilter.TWO_OR_LESS,
  },
];
