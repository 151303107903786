import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { NoResults, ActionButton, useDeviceTypes } from "halifax";
import { CallState } from "redmond";
import { pushToDisruptionOverview } from "../../utils";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../../../shop/utils/parseQueryString";
import { RebookNoResultsConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

export interface IRebookNoResultsProps
  extends RebookNoResultsConnectorProps,
    RouteComponentProps {}

interface IModalProps {
  productRedeemChoice?: string;
  title: string;
  subtitle: string;
  buttonProps?: {
    message: string;
    onClick: () => void;
    defaultStyle: "h4r-primary" | "h4r-secondary";
  };
}

export const RebookNoResults = (props: IRebookNoResultsProps) => {
  const { rebookSummaryCallState, fetchRebookSummary, history, productRedeemChoice } = props;
  const [ modalProps, setModalProps] = useState<IModalProps>();
  const { matchesMobile } = useDeviceTypes();

  const handleReload = () => {
    const {
      origin,
      destination,
      itineraryId = "",
    } = parseQueryString(history) as IFlightShopParsedQuery;

    fetchRebookSummary({
      history,
      origin,
      destination,
      itineraryId,
      isMobile: matchesMobile,
    });
  };

  useEffect(() => {
    if (rebookSummaryCallState === CallState.Failed) {
      setModalProps({
        productRedeemChoice: productRedeemChoice,
        title: constants.SOMETHING_WENT_WRONG_TITLE,
        subtitle: constants.SOMETHING_WENT_WRONG_SUBTITLE,
        buttonProps: {
          message: constants.RELOAD,
          defaultStyle: "h4r-secondary",
          onClick: handleReload,
        },
      });
    } else {
      const isMissedConnectionVi : boolean = productRedeemChoice === "missed_connection_vi";

      setModalProps({
        productRedeemChoice: productRedeemChoice,
        title: constants.NO_FLIGHTS_FOUND_TITLE,
        subtitle: isMissedConnectionVi ? constants.NO_FLIGHTS_FOUND_SUBTITLE_VI : constants.NO_FLIGHTS_FOUND_SUBTITLE,
        buttonProps: (isMissedConnectionVi ? undefined : {
          message: constants.GET_A_REFUND,
          defaultStyle: "h4r-primary",
          onClick: () => {
            pushToDisruptionOverview({ history, page: "refund" });
          },
        }),
      });
    }
  }, [rebookSummaryCallState]);

  if (!modalProps) {
    return null;
  } else {
    return (
      <Box className="rebook-no-results-root">
        <Box className="rebook-no-results-container">
          <NoResults
            className="flight-list-no-results"
            title={modalProps.title}
            subtitle={modalProps.subtitle}
          />
          {modalProps.buttonProps &&
            <ActionButton
              className="flight-list-no-results-button"
              onClick={modalProps.buttonProps.onClick}
              message={modalProps.buttonProps.message}
              defaultStyle={modalProps.buttonProps.defaultStyle}
              ariaLabelText={modalProps.buttonProps.message}
            />
          }
        </Box>
      </Box>
    );
  }
};
