import axios from "axios";
import { config } from "../../config";

import { HotelItineraryRequest, HotelItineraryResponse } from "redmond";
import { hotelItinerariesApiPrefix } from "../paths";

export const fetchHotels = async (
  req: HotelItineraryRequest
): Promise<HotelItineraryResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(`${hotelItinerariesApiPrefix}`, req, {
        baseURL: config.baseURL,
      });
      resolve(res.data as HotelItineraryResponse);
    } catch (e) {
      reject(e);
    }
  });
};
