import React, { useState } from "react";
import { DatePickerButton } from "halifax";
import { TripCategory } from "redmond";
import { DesktopCalendarPicker } from "..";
import {
  ISetDepartureDate,
  ISetMulticityDepartureDate,
  ISetReturnDate,
} from "../../../../actions/actions";
import "./styles.scss";

export interface ICalendarPickerButtonProps {
  classes?: string[];
  onClick?: () => void;
  disabled?: boolean;
  saveDatesOnClose?: boolean;
  hasMissingSearchInfoError?: boolean;
  tripCategory: TripCategory;
  departureDate: Date | null;
  returnDate: Date | null;
  setDepartureDate:
    | ((date: Date | null) => ISetDepartureDate | void)
    | ((date: Date | null) => ISetMulticityDepartureDate);
  setReturnDate: (date: Date | null) => ISetReturnDate | void;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  headerTitle?: JSX.Element | string;
}

export const CalendarPickerButton = ({
  classes = [],
  departureDate,
  returnDate,
  tripCategory,
  disabled,
  saveDatesOnClose,
  hasMissingSearchInfoError,
  setDepartureDate,
  setReturnDate,
  headerTitle,
  onClick: onButtonClick,
  minAllowedDate,
  maxAllowedDate,
}: ICalendarPickerButtonProps) => {
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);

  const onClick = () => {
    if (!disabled) {
      onButtonClick && onButtonClick();
      setIsDatesModalOpen(true);
    }
  };

  const isRoundTrip = tripCategory === TripCategory.ROUND_TRIP;

  return (
    <DatePickerButton
      startDate={departureDate}
      startLabel={isRoundTrip ? "Departure" : "Departure date"}
      endDate={returnDate}
      endLabel="Return"
      classes={classes}
      renderCalendarPopup={(props) => (
        <DesktopCalendarPicker
          {...props}
          minAllowedDate={minAllowedDate}
          maxAllowedDate={maxAllowedDate}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          isOpen={isDatesModalOpen}
          closePopup={() => setIsDatesModalOpen(false)}
          tripCategory={tripCategory}
          saveDatesOnClose={saveDatesOnClose}
          headerTitle={headerTitle}
        />
      )}
      onClick={onClick}
      dateFormat={"ddd MMM D"}
      hideEndDate={!isRoundTrip}
      hasMissingSearchInfoError={hasMissingSearchInfoError}
    />
  );
};
