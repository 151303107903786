import axios from "axios";
import { CreateWatchAlertResponse } from "redmond";
import {
  CreateWatchAlertRequest,
  airPriceWatchApi,
} from "@b2bportal/air-price-watch-api";

export const createWatch = (
  request: CreateWatchAlertRequest
): Promise<CreateWatchAlertResponse> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await airPriceWatchApi(
        axios as any
      ).apiV0AlertWatchCreatePost(request);
      resolve(res.data as any);
    } catch (e: any) {
      // If there is a filed "Response" in the returned data (even though it's a 500), we treat this as a success case for the API
      // in order to retrieve the returned data CreateWatchAlertFailure.
      if ("Response" in e.response.data) {
        resolve(e.response.data);
      } else {
        reject(e);
      }
    }
  });
};
