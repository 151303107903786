import { put, select } from "redux-saga/effects";
import {
  GenerateCustomOfferResponse,
  GenerateCustomOfferResponseEnum,
} from "redmond";

import Logger from "../../../helpers/Logger";
import { actions } from "../actions";
import { PATH_SHOP } from "../../../utils/urlPaths";
import { FlightShopStep } from "../../shop/reducer";
import { generateCustomOfferRequestSelector } from "../reducer";
import { IStoreState } from "../../../reducers/types";
import { generateCustomPriceFreezeOffer } from "../../../api/v0/price-freeze/generateCustomPriceFreezeOffer";

export function* generateCustomPriceFreezeOfferSaga(
  action: actions.IGenerateCustomPriceFreezeOffer
) {
  try {
    const { history } = action;
    const state: IStoreState = yield select();
    const {
      flightShopProgress,
      priceFreezeOfferCheapestTripTripId,
      priceFreezeOfferCheapestTripFareId,
      selectedTripId,
      selectedFareId,
      defaultPriceFreezeOffer,
      selectedPassengers,
    } = generateCustomOfferRequestSelector(state);

    // note: tripId, fareId & passengersObj depend on which flight shop step the user is currently in
    const { tripId, fareId } =
      history.location.pathname === PATH_SHOP &&
      (flightShopProgress === FlightShopStep.ChooseDeparture ||
        flightShopProgress === FlightShopStep.PricePrediction)
        ? {
            tripId: priceFreezeOfferCheapestTripTripId,
            fareId: priceFreezeOfferCheapestTripFareId,
          }
        : { tripId: selectedTripId ?? "", fareId: selectedFareId ?? "" };

    const passengers =
      history.location.pathname === PATH_SHOP
        ? defaultPriceFreezeOffer?.passengers ?? selectedPassengers
        : selectedPassengers;

    const response: GenerateCustomOfferResponse =
      yield generateCustomPriceFreezeOffer({
        offerData: action.offerData,
        tripId,
        fareId,
        passengers,
      });

    switch (response.GenerateCustomOfferResponse) {
      case GenerateCustomOfferResponseEnum.Available: {
        yield put(actions.setCustomPriceFreezeOffer(response.offer));
        if ( action.isFromTripSummariesV3 ) {
          yield put(actions.setCheapestEligibleFlightCustomPriceFreezeOffer(response.offer));
        }
        break;
      }
      case GenerateCustomOfferResponseEnum.NotAvailable:
        yield put(actions.setGenerateCustomPriceFreezeOfferCallStateSuccess());
        break;
      case GenerateCustomOfferResponseEnum.InvalidOfferData:
      default: {
        yield put(actions.setGenerateCustomPriceFreezeOfferCallStateFailed());
        break;
      }
    }
  } catch (e) {
    yield put(actions.setGenerateCustomPriceFreezeOfferCallStateFailed());
    Logger.debug(e);
  }
}
