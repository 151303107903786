import * as React from "react";
import { Box } from "@material-ui/core";
import { GenericSlider } from "halifax";
import { ITimeRange } from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { TIME_RANGE_MAX } from "../../../../reducer";

export interface IDepartureArrivalSelectionBase {
  onChangeDepartureTimeRange: (min: number, max: number) => void;
  departureTimeRange: ITimeRange;
  onChangeArrivalTimeRange: (min: number, max: number) => void;
  arrivalTimeRange: ITimeRange;
}

interface IDepartureArrivalSelectionProps
  extends IDepartureArrivalSelectionBase {
  className?: string;
  type: "outbound" | "return";
  hideTitle?: boolean;
}

export const DepartureArrivalSelection = (
  props: IDepartureArrivalSelectionProps
) => {
  const {
    className,
    type,
    hideTitle,
    onChangeDepartureTimeRange,
    departureTimeRange,
    onChangeArrivalTimeRange,
    arrivalTimeRange,
  } = props;

  return (
    <Box className={clsx("departure-arrival-selection-root", className)}>
      <Box className="departure-arrival-selection-container">
        {!hideTitle && (
          <Box className="header-container">
            {type === "outbound"
              ? textConstants.TIMES_OUTBOUND_HEADER_TEXT
              : textConstants.TIMES_RETURN_HEADER_TEXT}
          </Box>
        )}
        <Box className="selection-container">
          <Box className={clsx("time-window-slider-container", "departure")}>
            <Box className="label-container">
              {textConstants.DEPARTURE_TEXT}
            </Box>
            <TimeWindowSlider
              timeRange={departureTimeRange}
              onChange={onChangeDepartureTimeRange}
            />
          </Box>
          <Box className={clsx("time-window-slider-container", "arrival")}>
            <Box className="label-container">{textConstants.ARRIVAL_TEXT}</Box>
            <TimeWindowSlider
              timeRange={arrivalTimeRange}
              onChange={onChangeArrivalTimeRange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface ITimeWindowSliderProps {
  className?: string;
  onChange: (min: number, max: number) => void;
  timeRange: ITimeRange;
}

const TimeWindowSlider = (props: ITimeWindowSliderProps) => {
  const { className, onChange, timeRange } = props;

  return (
    <GenericSlider
      className={clsx("time-window-slider-root", className)}
      onChange={onChange}
      sliderType={"doubleThumb"}
      step={1}
      chosenMin={timeRange.min}
      chosenMax={timeRange.max}
      sliderMin={0}
      sliderMax={TIME_RANGE_MAX}
      getLabel={getTimeLabel}
    />
  );
};

const getTimeLabel = (value: number) => {
  const totalTime = dayjs().hour(0).minute(value);
  return totalTime.format("HH:mm");
};
