import axios from "axios";

import { 
  airBookingApi,
  PassengersForFareRequest,
  TripPricingSummaryResponse,
} from "@b2bportal/air-booking-api";

export const fetchTripPricing = (
  req: PassengersForFareRequest
): Promise<TripPricingSummaryResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(axios as any).apiV0TripPricePost(req);

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
