import { fetchDepartureCalendarSaga } from "./fetchDepartureCalendarSaga";
import { fetchDestinationCategories } from "./fetchDestinationCategoriesSaga";
import { fetchOriginCategories } from "./fetchOriginCategoriesSaga";
import { actionTypes } from "../actions";
import { takeLatest } from "redux-saga/effects";
import { fetchUserFlightPreferencesSaga } from "./fetchUserFlightPreferencesSaga";

export function* watchFetchOriginCategories() {
  yield takeLatest(actionTypes.FETCH_ORIGIN_CATEGORIES, fetchOriginCategories);
}

export function* watchFetchDestinationCategories() {
  yield takeLatest(
    actionTypes.FETCH_DESTINATION_CATEGORIES,
    fetchDestinationCategories
  );
}

export function* watchFetchDepartureCalendar() {
  yield takeLatest(
    actionTypes.FETCH_DEPARTURE_CALENDAR,
    fetchDepartureCalendarSaga
  );
}

export function* watchFetchUserFlightPreferences() {
  yield takeLatest(
    actionTypes.FETCH_USER_FLIGHT_PREFERENCES,
    fetchUserFlightPreferencesSaga
  );
}
