import { getTotalPriceText, getRewardText } from "halifax";
import { FiatPrice, RewardsPrice, TripCategory } from "redmond";

export const CHOOSE_A_SIMILAR_FLIGHT_TITLE = "Choose a Similar Flight";
export const CHOOSE_A_SIMILAR_FLIGHT_TITLE_STRONG = `<strong>${CHOOSE_A_SIMILAR_FLIGHT_TITLE}</strong>`;
export const CHOOSE_A_SIMILAR_FLIGHT_DETAILS = (price: FiatPrice) =>
  `You can exchange your flight at no extra cost. Your original frozen price, the ${getTotalPriceText(
    { price }
  )} savings cap, and the remaining duration of the freeze will be transferred to this new flight.`;

export const UNAVAILABLE_FLIGHT_TITLE = (isSelectingReturn: boolean) =>
  `Unavailable <strong>${
    isSelectingReturn ? "Return" : "Outbound"
  } Flight</strong>`;

export const ORIGINAL_FROZEN_PRICE_TITLE = "Original frozen price:";

export const PRICE_FREEZE_DETAILS_FROZEN_PRICE_VALUE = (
  frozenPrice:
    | { fiat: FiatPrice; rewards: RewardsPrice | undefined }
    | undefined
) =>
  frozenPrice
    ? `<strong>${getTotalPriceText({
        price: frozenPrice.fiat,
      })}</strong>${
        frozenPrice.rewards
          ? ` or ${getRewardText({ reward: frozenPrice.rewards })}`
          : ""
      }`
    : "";
export const TRIP_CATEGORY_SUBTITLE = (tripCategory: TripCategory) =>
  `${
    tripCategory === TripCategory.ROUND_TRIP ? "Round" : "One-way"
  } trip, per traveler`;

export const CONTINUE_TEXT = "Continue";
