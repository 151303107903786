import { IPassengerCounts } from "redmond";

export const INPUT_LABEL = (counts: IPassengerCounts) => {
  const { adultsCount, infantsOnLapCount, infantsInSeatCount, childrenCount } =
    counts;
  const numTravelers =
    adultsCount + infantsOnLapCount + infantsInSeatCount + childrenCount;
  return numTravelers === 1
    ? `${numTravelers} traveler`
    : `${numTravelers} travelers`;
};
export const TRAVELERS_STEP = (step: number) => `Step ${step}: Travelers`;
export const CHANGE_TRAVELERS = "Change";
export const FREEZE_FOR = (useLockPriceLanguage?: boolean) =>
  `${useLockPriceLanguage ? "Locking" : "Freezing"} for:`;
export const TRAVELERS_AMOUNT = (useLockPriceLanguage?: boolean) =>
  `How many travelers are you ${
    useLockPriceLanguage ? "locking" : "freezing"
  } this price for?`;
export const MODAL_TITLES = {
  modalTitle: "Edit travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "Ages 2-11",
  infantSeatTitle: "Infants in seat",
  infantSeatSubtitle: "Under the age of 2",
  infantLapTitle: "Infants on lap",
  infantLapSubtitle: "Under the age of 2",
};
