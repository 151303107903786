import React from "react";
import { Prices } from "@b2bportal/air-chfar-api";
import { getPriceFreezeRewardsString as getRewardsString } from "../../../utils/helpers";
import { getFormattedTotalPriceText } from "../helpers";

export const ChfarAttachedContent = ({
  chfarOfferPrices,
  rewardsKey,
}: {
  chfarOfferPrices: Prices | undefined;
  rewardsKey: string | undefined;
}) => {
  const chfarRewardsString =
    chfarOfferPrices != null
      ? getRewardsString(chfarOfferPrices.accountSpecific, rewardsKey)
      : undefined;
  return (
    <>
      {chfarOfferPrices != null ? (
        <span>
          <span className="bold">Change your flight for any reason</span> added
          for{" "}
          <span className="bold">
            {getFormattedTotalPriceText(chfarOfferPrices.fiat)}
          </span>
          {chfarRewardsString != null && chfarRewardsString != ""
            ? ` / ${chfarRewardsString} `
            : " "}
          per traveler.
        </span>
      ) : null}
    </>
  );
};
