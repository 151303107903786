import axios from "axios";
import {
  ShopFilter,
  ShopSummaryRequest,
  SliceStopCountFilter,
  VIEWED_FLIGHT_SHOP_LOADING,
  Platform,
} from "redmond";
import { IShopParams } from "../../../modules/shop/actions/actions";

import dayjs from "dayjs";
import { trackEvent } from "../../v0/analytics/trackEvent";
import {
  airShoppingApi,
  Failure,
  ShopSummaryResponseV2Success,
  ShopSummaryResponseV2,
} from "@b2bportal/air-shopping-api";

export const fetchTripSummaries = async (
  shopParams: IShopParams,
  isMobile: boolean | undefined
): Promise<ShopSummaryResponseV2> => {
  return new Promise(async (resolve, reject) => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter,
      originLocationLabel,
      destinationLocationLabel,
      fareClass,
    } = shopParams;
    const passengerObj = {};

    if (adultsCount > 0) passengerObj["ADT"] = adultsCount;
    if (infantsInSeatCount > 0) passengerObj["INS"] = infantsInSeatCount;
    if (infantsOnLapCount > 0) passengerObj["INF"] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj["CNN"] = childrenCount;

    //TODO refactor
    let tripFilter = ShopFilter.NoFilter;
    const filterOutBasicFares =
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard;
    if (fareclassOptionFilter && filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }
    const request: ShopSummaryRequest = {
      passengers: passengerObj,
      departureDate,
      ...(returnDate && { returnDate }),
      route: {
        origin,
        destination,
      },
      tripFilter,
      platform: isMobile ? Platform.Mobile : Platform.Desktop,
      originLocationLabel,
      destinationLocationLabel,
      fareClass: fareClass?.length ? fareClass : undefined,
    };

    try {
      const startTime = dayjs();
      const res = await airShoppingApi(axios as any).apiV3ShopSummaryPost(
        request as any
      );

      const { Response } = res.data;

      trackEvent({
        eventName: VIEWED_FLIGHT_SHOP_LOADING,
        properties: {
          runtime: dayjs().diff(startTime, "seconds", true),
        },
      });

      // call can succeed with a "Failure" Response so handle both cases
      if (Response === "Success") {
        const { value } = res.data as ShopSummaryResponseV2Success;
        resolve(value as any);
      } else {
        const { errors } = res.data as Failure;
        reject(errors);
      }
    } catch (e) {
      reject(e);
    }
  });
};
