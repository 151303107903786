import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../..../../../../../reducers/types";
import { getFareclassOptionFilter } from "../../../../../search/reducer";
import { MobileFilterFareDetails } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  fareClassFilters: getFareclassOptionFilter(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFilterFareDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFilterFareDetails = connector(
  MobileFilterFareDetails
);
