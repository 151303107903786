import { Badge, Box, Divider } from "@material-ui/core";
import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
  UserPreferencesBanner,
} from "halifax";
import React from "react";
import {
  AirlineCode,
  Airport,
  CallState,
  FareclassOptionFilter,
  FlightSortOption,
  IFlightNumberFilter,
  ISortOptions,
  ITimeRange,
  ITripTerminus,
  RegionType,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import {
  StopsOptionSelection,
  AirlineFilterSelection,
  AirportFilterSelection,
  FlightNumberFilterSelection,
  MaxPriceFilterSelection,
  DepartureArrivalSelectionDropdown,
  BaggageTransfersFilter,
  DurationSelection,
  PolicyFilterSelection,
  FareclassOptionSelection,
} from "../../../../../search/components/FlightShopSearchControlV2/components/FlightShopSearchFilter/components";
import { IAirlineOptions, IShopFilterSelector } from "../../../../reducer";
import { SortOptionSelection } from "../../../FlightShopHeader/components/SortOptionSelection";
import {
  AIRLINE_SELECTION_SUBTITLE,
  BAGGAGE_FILTER_LABEL,
  FILTER_MODAL_TITLES,
  FLIGHT_NUMBER_SELECTION_SUBTITLE,
} from "../../textConstants";
import "./styles.scss";
import { FlightShopSortAndFilterType } from "../../../../../../utils/sortAndFilter";
import {
  AVAILABLE,
  AirCXV3VariantType,
  CONTROL,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../../../context/experiments";
import { Link } from "react-router-dom";
import { PATH_CUSTOMER_PROFILE } from "../../../../../../utils/urlPaths";

export interface IMobileFlightShopFilterProps {
  openAllFiltersModal: boolean;
  setOpenAllFiltersModal: (arg: boolean) => void;
  sortOptionsToDisplay: ISortOptions[];
  filtersToDisplay: FlightShopSortAndFilterType[];
  sortOption: FlightSortOption;
  setSortOption: (option: FlightSortOption) => void;
  stopsOption: SliceStopCountFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => void;
  setRerunPrediction: () => void;
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  allAirlines: IAirlineOptions[];
  airportFilter: string[];
  setAirportFilter: (airportFilter: string[]) => void;
  flightNumberFilter: IFlightNumberFilter[];
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
  departureDateString: string;
  maxPriceFilter: number;
  setMaxPriceFilter: (maxPrice: number) => void;
  optionsChanged: boolean;
  handleApply: () => void;
  flightShopFilters: IShopFilterSelector;
  filtersToApplyCount: number;
  isFlightListOptimizationExperiment?: boolean;
  tripCategory: TripCategory;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ) => void;
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) => void;
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) => void;
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) => void;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  originAirport?: Airport;
  destinationAirport?: Airport;
  flightNumberAirlineFilter: string;
  setFlightNumberAirlineFilter: (airline: string) => void;
  handleReset: () => void;
  readyToReset: boolean;
  hasAppliedFilters: boolean;
  baggageTransfersFilter: boolean;
  setBaggageTransfersFilter: (value: boolean) => void;
  isThebesVirtualInterliningInCap1?: boolean;
  airCXV3Variant?: AirCXV3VariantType;
  durationFilter: number;
  setDurationFilter: (duration: number) => void;
  minDuration: number;
  maxDuration: number;
  policyFilter: boolean;
  setPolicyFilter: (value: boolean) => void;
  filteredFlightCount: number;
  isCustomerProfileExperiment?: boolean;
  userHasSetFlightPreferences?: boolean;
  userFlightPreferencesCallState?: CallState;
  shouldApplyUserFlightPreferences: boolean;
  setApplyUserFlightPreferences: (applyPreferences: boolean) => void;
  filtersToApplyMatchDefaultState?: boolean;
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
}
export const MobileFlightShopFilter = (props: IMobileFlightShopFilterProps) => {
  const {
    openAllFiltersModal,
    setOpenAllFiltersModal,
    sortOptionsToDisplay,
    filtersToDisplay,
    sortOption,
    setSortOption,
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    airlineFilter,
    setAirlineFilter,
    airportFilter,
    setAirportFilter,
    allAirlines,
    flightNumberFilter,
    setFlightNumberFilter,
    departureDateString,
    maxPriceFilter,
    setMaxPriceFilter,
    optionsChanged,
    handleApply,
    flightShopFilters,
    filtersToApplyCount,
    isFlightListOptimizationExperiment,
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    origin,
    destination,
    originAirport,
    destinationAirport,
    flightNumberAirlineFilter,
    setFlightNumberAirlineFilter,
    handleReset,
    readyToReset,
    hasAppliedFilters,
    baggageTransfersFilter,
    setBaggageTransfersFilter,
    isThebesVirtualInterliningInCap1,
    airCXV3Variant,
    durationFilter,
    setDurationFilter,
    minDuration,
    maxDuration,
    policyFilter,
    setPolicyFilter,
    filteredFlightCount,
    isCustomerProfileExperiment,
    userHasSetFlightPreferences = false,
    userFlightPreferencesCallState = CallState.NotCalled,
    shouldApplyUserFlightPreferences,
    setApplyUserFlightPreferences,
    filtersToApplyMatchDefaultState,
    fareclassOptionFilter,
    setFareclassOptionFilter,
  } = props;

  const { airportOptions, flightNumbersByAirline, priceMax, priceMin } =
    flightShopFilters;

  const showUserHotelPreferencesBanner =
    isCustomerProfileExperiment &&
    userFlightPreferencesCallState === CallState.Success &&
    userHasSetFlightPreferences;

  const { experiments } = useExperiments();

  const globalMobileNavExperimentVariant = getExperimentVariant(
    experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const filtersToElementMap = {
    [FlightShopSortAndFilterType.Sort]: (
      <SortOptionSelection
        sortOptions={sortOptionsToDisplay}
        sortOption={sortOption}
        setSortOption={setSortOption}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.sortText}
        icon={IconName.Sort}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
      />
    ),
    [FlightShopSortAndFilterType.Fareclass]: (
      <FareclassOptionSelection
        fareclassOptionFilter={fareclassOptionFilter}
        setFareclassOptionFilter={setFareclassOptionFilter}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.fareclassText}
        icon={IconName.FareIcon}
        includeFareClassInfoModal
        isMobile
      />
    ),
    [FlightShopSortAndFilterType.Policy]: (
      <PolicyFilterSelection
        policyFilter={policyFilter}
        setPolicyFilter={setPolicyFilter}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.policyFilter}
        icon={IconName.DiagonalBlueAirplane}
      />
    ),
    [FlightShopSortAndFilterType.NumberOfStops]: (
      <StopsOptionSelection
        stopsOption={stopsOption}
        setStopsOption={setStopsOption}
        setRerunPrediction={
          airCXV3Variant === CONTROL ? setRerunPrediction : undefined
        }
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.numOfStopsText}
        icon={IconName.StopsFilterIcon}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        airCXV3Variant={airCXV3Variant}
      />
    ),
    [FlightShopSortAndFilterType.Airline]: (
      <AirlineFilterSelection
        allAirlines={allAirlines}
        airlineFilter={airlineFilter}
        setAirlineFilter={setAirlineFilter}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.airlineText}
        icon={IconName.DiagonalBlueAirplane}
        optionLabelPlacement="start"
      />
    ),
    // TODO: Instead of adding the condition here, the caller should pass the right set of filters to show.
    [FlightShopSortAndFilterType.BaggageTransfer]:
      isThebesVirtualInterliningInCap1 ? (
        <BaggageTransfersFilter
          title={FILTER_MODAL_TITLES.baggageTransfers}
          baggageTransfersFilter={baggageTransfersFilter}
          setBaggageTransfersFilter={setBaggageTransfersFilter}
          showDropdownContentOnly
          icon={IconName.BookTravel}
          label={BAGGAGE_FILTER_LABEL}
        />
      ) : null,
    [FlightShopSortAndFilterType.OutboundTimes]: (
      <DepartureArrivalSelectionDropdown
        tripCategory={tripCategory}
        outboundDepartureTimeRange={outboundDepartureTimeRange}
        outboundArrivalTimeRange={outboundArrivalTimeRange}
        returnDepartureTimeRange={returnDepartureTimeRange}
        returnArrivalTimeRange={returnArrivalTimeRange}
        setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
        setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
        setReturnDepartureTimeRange={setReturnDepartureTimeRange}
        setReturnArrivalTimeRange={setReturnArrivalTimeRange}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        showDropdownContentOnly
        icon={IconName.ClockIconThin}
        outboundTitle={FILTER_MODAL_TITLES.outboundTimesText}
        returnTitle={FILTER_MODAL_TITLES.returnTimesText}
        origin={origin}
        destination={destination}
        originAirport={originAirport}
        destinationAirport={destinationAirport}
      />
    ),
    [FlightShopSortAndFilterType.MaxPrice]: (
      <MaxPriceFilterSelection
        maximumPrice={priceMax.value}
        minimumPrice={priceMin.value}
        maxPriceFilter={maxPriceFilter}
        setMaxPriceFilter={setMaxPriceFilter}
        title={FILTER_MODAL_TITLES.priceRangeText}
        subtitle={FILTER_MODAL_TITLES.priceRangeSubtitleText}
        icon={IconName.MoneyOutlineThin}
        showDropdownContentOnly
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
      />
    ),
    [FlightShopSortAndFilterType.Duration]:
      airCXV3Variant && airCXV3Variant !== CONTROL ? (
        <DurationSelection
          value={durationFilter}
          setValue={setDurationFilter}
          minValue={minDuration}
          maxValue={maxDuration}
          flightCount={filteredFlightCount}
          showDropdownContentOnly
          isMobile
        />
      ) : null,
    // TODO: Instead of adding the condition here, the caller should pass the right set of filters to show.
    [FlightShopSortAndFilterType.Airport]:
      origin?.id.code.regionType !== RegionType.Airport ? (
        <AirportFilterSelection
          allAirports={airportOptions}
          airportFilter={airportFilter}
          setAirportFilter={setAirportFilter}
          showDropdownContentOnly
          title={FILTER_MODAL_TITLES.outboundAirportText}
          icon={IconName.DiagonalBlueAirplane}
          optionLabelPlacement="start"
          displayLabelAndValue
        />
      ) : null,
    [FlightShopSortAndFilterType.FlightNumber]: (
      <FlightNumberFilterSelection
        showDropdownContentOnly
        allAirlines={allAirlines}
        flightNumbersByAirline={flightNumbersByAirline}
        flightNumberFilter={flightNumberFilter}
        departureDateString={departureDateString}
        setFlightNumberFilter={setFlightNumberFilter}
        useAirlineCheckbox
        title={FILTER_MODAL_TITLES.flightNumberText}
        icon={IconName.DiagonalBlueAirplane}
        flightNumberAirlineFilter={flightNumberAirlineFilter}
        setFlightNumberAirlineFilter={setFlightNumberAirlineFilter}
        airlineSubtitle={AIRLINE_SELECTION_SUBTITLE}
        flightNumberSubtitle={FLIGHT_NUMBER_SELECTION_SUBTITLE}
      />
    ),
  };

  return (
    <>
      {!isGlobalMobileNavExperiment && (
        <ActionLink
          className="mobile-flight-shop-edit-filters-button"
          onClick={() => setOpenAllFiltersModal(true)}
          content={
            <Badge
              className="filter-count"
              badgeContent={hasAppliedFilters ? "" : undefined}
            >
              <Icon name={IconName.Settings} />
            </Badge>
          }
        />
      )}
      <MobilePopoverCard
        fullScreen
        open={openAllFiltersModal}
        onClose={() => setOpenAllFiltersModal(false)}
        className={"mobile-flight-shop-all-filters-modal"}
        contentClassName={"mobile-flight-shop-search-filter-wrapper"}
        bottomButton={
          <MobileFloatingButton
            className="apply-filters-floating-button"
            wrapperClassName="apply-filters-floating-button-container"
            disabled={!optionsChanged}
            onClick={() => {
              handleApply();
              setOpenAllFiltersModal(false);
            }}
          >
            {filtersToApplyMatchDefaultState || filtersToApplyCount === 0
              ? FILTER_MODAL_TITLES.applyText
              : FILTER_MODAL_TITLES.applyFiltersText(filtersToApplyCount)}
          </MobileFloatingButton>
        }
        topRightButton={
          <ActionLink
            className="filter-modal-close-button"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={() => setOpenAllFiltersModal(false)}
          />
        }
        topLeftButton={
          readyToReset ? (
            <ActionLink
              content={FILTER_MODAL_TITLES.clearAllFiltersText}
              onClick={handleReset}
            />
          ) : undefined
        }
      >
        {showUserHotelPreferencesBanner && (
          <UserPreferencesBanner
            type="flight"
            variant="modal"
            isMobile
            userPreferencesCallState={userFlightPreferencesCallState}
            userHasSetPreferences={userHasSetFlightPreferences}
            shouldApplyUserPreferences={shouldApplyUserFlightPreferences}
            setShouldApplyUserPreferences={setApplyUserFlightPreferences}
            bannerProfileCTA={
              <Link
                to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
                className="profile-cta"
              >
                Add travel preferences
              </Link>
            }
            modalProfileCTA={
              <Link
                to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
                className="info-modal-primary-cta"
              >
                Add travel preferences
              </Link>
            }
          />
        )}
        {filtersToDisplay
          .map((filter) => {
            return { filter: filter, element: filtersToElementMap[filter] };
          })
          .filter(({ element }) => element !== null)
          .map(({ filter, element }, index, { length }) => (
            <Box key={filter} className="mobile-flight-shop-filter-wrapper">
              {element}
              {index !== length - 1 && <Divider className="filter-divider" />}
            </Box>
          ))}
      </MobilePopoverCard>
    </>
  );
};
