export const INFANT_ONBOARD = "Infant Onboard";
export const SELECT_SEAT_TYPE = "Please select a seat type";
export const ON_LAP = "On lap";
export const OWN_SEAT = "Own seat";
export const ON_LAP_DESCRIPTION = "per infant, round-trip";
export const OWN_SEAT_DESCRIPTION = "per infant, round-trip";
export const FREE_DESCRIPTION = "Free, no additional cost";
export const CONTINUE = "Continue";
export const LOADING_TEXT = "Validating travelers";

export const INFANT_PRICING_UNAVAIL_TEXT = `<strong>Infant pricing will become available</strong> once you’ve added all travelers to the booking. Pricing will be displayed against each infant in the checkout breakdown after you Save and Continue.`;

export const INFANTS_NOT_SUPPORTED =
  "Infants are unsupported for the selected carrier at this time.";
export const CHOOSE_ANOTHER_FLIGHT = "Choose another flight";
export const EDIT_TRAVELERS = "Edit travelers";
