import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getDestination,
  getOrigin,
  getTripCategory,
  getDepartureDate,
  getReturnDate,
} from "../../../search/reducer";
import {
  fetchDepartureCalendar,
  setCalendar,
  setDepartureDate,
  setReturnDate,
} from "../../../search/actions/actions";
import { populateFlightShopQueryParams } from "../../actions/actions";
import {
  fetchTripSummariesV3,
  setFlightShopProgress
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileFlightShopSearchPopover } from "./component";
import { flightShopProgressSelector } from "../../reducer";

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  populateFlightShopQueryParams,
  fetchTripSummaries: fetchTripSummariesV3,
  setFlightShopProgress,
  setReturnDate,
  setDepartureDate
};
const mapStateToProps = (state: IStoreState) => ({
  originCode: getOrigin(state)?.id.code.code,
  destinationCode: getDestination(state)?.id.code.code,
  origin: getOrigin(state),
  destination: getDestination(state),
  tripCategory: getTripCategory(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  flightShopProgress: flightShopProgressSelector(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileFlightSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileFlightShopSearchPopover = withRouter(
  connector(MobileFlightShopSearchPopover)
);
