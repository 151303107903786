import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingErrorModal } from "./component";
import {
  getErrorTitles,
  getPriceDifference,
  getHasError,
  getHasFlightFreezeError,
  getIsMissingPassport,
  getSchedulePaymentCallState,
  getFlightBookType,
  getInfantOnlyError,
  getPriceQuoteWithUpdatedAncillary,
  getSeatMapAvailability,
  getUserSelectedLapInfantIds,
  getUserSelectedPassengerIds,
  getSession,
  getPaymentsV2,
  getAncillaries,
  getBookingProgressList,
  getModalEventType,
} from "../../reducer";
import {
  acknowledgePriceDifference,
  scheduleQuote,
  schedulePayment,
  resetBookingErrors,
  setUpFlightBookParams,
  setInfantOnlyError,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
  resetPaymentCardSelectedAccounts,
} from "../../actions/actions";
import {
  populateFlightShopQueryParams,
  resetSelectedTrip,
} from "../../../shop/actions/actions";
import { 
  getViewedTripSummaryProperties, 
  isFlightBookWithAncillariesActiveSelector,
} from "../../../shop/reducer";
import {
  generateCustomPriceFreezeOffer,
  setDisplayPriceFreezeRefundModal,
  setUpFlightFreezeParams,
  resetPriceFreezePurchase,
  acknowledgePriceFreezeFareQuoteDetails,
} from "../../../freeze/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { getOrigin } from "../../../search/reducer";
import {
  currentPriceFreezeSelector,
  selectedAndValidPriceFreezeOfferDataSelector,
} from "../../../freeze/reducer";

const mapStateToProps = (state: IStoreState) => ({
  bookingProgressList: getBookingProgressList(state),
  priceDifference: getPriceDifference(state),
  errorTitles: getErrorTitles(state),
  hasError: getHasError(state),
  hasFlightFreezeError: getHasFlightFreezeError(state),
  currentSession: getSession(state),
  payments: getPaymentsV2(state),
  ancillaries: getAncillaries(state),
  isMissingPassport: getIsMissingPassport(state),
  origin: getOrigin(state),
  modalType: getModalEventType(state),
  schedulePaymentCallState: getSchedulePaymentCallState(state),
  viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
  fightBookType: getFlightBookType(state),
  infantOnlyError: getInfantOnlyError(state),
  priceFreeze: currentPriceFreezeSelector(state),
  selectedPriceFreezeOfferData:
    selectedAndValidPriceFreezeOfferDataSelector(state),
  priceQuote: getPriceQuoteWithUpdatedAncillary(state),
  seatMapAvailability: getSeatMapAvailability(state),
  selectedLapInfantIds: getUserSelectedLapInfantIds(state),
  selectedPaxIds: getUserSelectedPassengerIds(state),
  isFlightBookWithAncillariesActive:
    isFlightBookWithAncillariesActiveSelector(state),
});

const mapDispatchToProps = {
  acknowledgePriceDifference,
  acknowledgePriceFreezeFareQuoteDetails,
  populateFlightShopQueryParams,
  scheduleQuote,
  schedulePayment,
  resetBookingErrors,
  resetSelectedTrip,
  setUpFlightBookParams,
  setUpFlightFreezeParams,
  resetPriceFreezePurchase,
  setDisplayPriceFreezeRefundModal,
  generateCustomPriceFreezeOffer,
  setInfantOnlyError,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
  resetPaymentCardSelectedAccounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedBookingErrorModal = withRouter(
  connector(BookingErrorModal)
);
