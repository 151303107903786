import { createSelector } from "@reduxjs/toolkit";
import { isCfarCoMerchEnabledSelector } from "../../../ancillary/reducer";
import { selectedCfarOfferSelector } from "../../../shop/reducer";
import { CFAR } from "./textConstants";

export const cfarOfferTextSelector = createSelector(
  isCfarCoMerchEnabledSelector,
  selectedCfarOfferSelector,
  (isCfarCoMerchEnabled, selectedCfarOffer): string =>
    isCfarCoMerchEnabled &&
    selectedCfarOffer?.policyData?.cashCoveragePercentage
      ? `${CFAR}, ${selectedCfarOffer?.policyData?.cashCoveragePercentage}% coverage`
      : CFAR
);
