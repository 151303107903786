import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../reducers/types";

import { PriceFreezeDurationModal } from "./component";
import {
  getCreditToApply,
  selectHasOfferDurationSelectionChanged,
} from "../../../reducer";
import { setCreditToApply } from "../../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    /**
     * It will be `true` if the `customPriceFreezeOffer` duration is different from the `selectedAndValidPriceFreezeOffer` duration.
     */
    hasOfferDurationSelectionChanged:
      selectHasOfferDurationSelectionChanged(state),
    /**
     * It will be `true` if the customer has applied some credits toward their purchase.
     */
    hasCustomerAppliedCreditToPurchase: !!getCreditToApply(state),
  };
};

const mapDispatchToProps = {
  setCreditToApply,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeDurationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeDurationModal = connector(
  PriceFreezeDurationModal
);
