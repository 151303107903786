import { FetchCfarOfferResponseV2, FlightRatingsEnum } from "redmond";
import { IFlightListData } from "../v2/components/FlightList";
import { FareclassOptionFilter } from "../../search/reducer";
import { isCfarEligible } from "../../ancillary/utils";
import { Flights, MulticityFlights } from "@b2bportal/air-shopping-api";

export const getFaresToShow = (
  flightsToShow: IFlightListData[],
  flights: Flights | null,
  hasAppliedFareClassFilter: boolean,
  fareClassFilter: FareclassOptionFilter,
  matchesMobile: boolean,
  cfarOffers:
    | {
        [key: string]: {
          [key: string]: FetchCfarOfferResponseV2;
        };
      }
    | undefined,
  isInChooseReturnStep: boolean,
  showRefundableFaresOnly: boolean | undefined,
  hasSetMaxPriceFilter: boolean,
  maxFlightPriceFilter: number,
  hasSetPolicyFilter: boolean
) => {
  const faresToShow: Array<{
    fare: any;
    flight: IFlightListData;
    fareScore: number;
  }> = [];
  flightsToShow.forEach((flight) => {
    if (hasAppliedFareClassFilter) {
      const filteredFare = flight.fares.find((fare: any) => {
        const fareSliceId = fare?.fareSlice || fare.return;
        const fareSlice = flights!.fareSlices[fareSliceId];
        return fareClassFilter[FlightRatingsEnum[fareSlice.fareShelf.value]];
      });

      if (!!filteredFare) {
        const fareId = filteredFare.example?.fare ?? filteredFare.id;
        const fareScore = flights!.fares[fareId]?.fareScore.value;
        faresToShow.push({ fare: filteredFare, flight, fareScore });
      }
    } else if (matchesMobile && flight.fares.length > 1) {
      const numFares = flight.fares.length > 2 ? 3 : flight.fares.length;
      flight.fares.slice(0, numFares).forEach((selectedFare: any) => {
        /*
              note: when it's showRefundableFaresOnly, the subset of fares (in a mobile flight drawer) must contain at least one RF to be considered as an itinerary;
              since the subset of fares is ordered, selectedFare is always the 1st one shown in a flight drawer; therefore, the logic is equivalent to "making sure
              that the selectedFare has CFAR offered before it can be considered as an itinerary".
            */
        if (
          !cfarOffers ||
          !isInChooseReturnStep ||
          !showRefundableFaresOnly ||
          isCfarEligible(selectedFare.tripId, selectedFare.id, cfarOffers) ||
          hasSetPolicyFilter
        ) {
          const fareId = selectedFare.example?.fare ?? selectedFare.id;
          const fareScore = flights!.fares[fareId]?.fareScore.value;
          const policyCompliance =
            flights!.fares[fareId]?.corporateTravel?.policyCompliance;
          if (
            hasSetMaxPriceFilter &&
            selectedFare.amount?.fiat?.value > maxFlightPriceFilter
          ) {
            return;
          } else if (hasSetPolicyFilter && !policyCompliance?.isInPolicy) {
            return;
          } else {
            faresToShow.push({ fare: selectedFare, flight, fareScore });
          }
        }
      });
    } else {
      const selectedFare = flight.fares[0];
      const fareId = selectedFare.example?.fare ?? selectedFare.id;
      const fareScore = flights!.fares[fareId]?.fareScore.value;
      faresToShow.push({ fare: selectedFare, flight, fareScore });
    }
  });

  return faresToShow;
};

export const getMulticityFaresToShow = (
  flightsToShow: IFlightListData[],
  multicityFlights: MulticityFlights | null,
  hasAppliedFareClassFilter: boolean,
  fareClassFilter: FareclassOptionFilter,
  matchesMobile: boolean,
  hasSetMaxPriceFilter: boolean,
  maxFlightPriceFilter: number,
  hasSetPolicyFilter: boolean
) => {
  const faresToShow: Array<{
    fare: any;
    flight: IFlightListData;
    fareScore: number;
  }> = [];
  flightsToShow.forEach((flight) => {
    if (hasAppliedFareClassFilter) {
      const filteredFare = flight.fares.find((fare: any) => {
        const fareSliceId = fare?.fareSlice || fare.return;
        const fareSlice = multicityFlights!.fareSlices[fareSliceId];
        return fareClassFilter[FlightRatingsEnum[fareSlice.fareShelf.value]];
      });

      if (!!filteredFare) {
        const fareId = filteredFare.example?.fare ?? filteredFare.id;
        const fareScore = multicityFlights!.fares[fareId]?.fareScore.value;
        faresToShow.push({ fare: filteredFare, flight, fareScore });
      }
    } else if (matchesMobile && flight.fares.length > 1) {
      const numFares = flight.fares.length > 2 ? 3 : flight.fares.length;
      flight.fares.slice(0, numFares).forEach((selectedFare: any) => {
        const fareId = selectedFare.example?.fare ?? selectedFare.id;
        const fareScore = multicityFlights!.fares[fareId]?.fareScore.value;
        const policyCompliance =
          multicityFlights!.fares[fareId]?.corporateTravel?.policyCompliance;
        if (
          hasSetMaxPriceFilter &&
          selectedFare.amount?.fiat?.value > maxFlightPriceFilter
        ) {
          return;
        } else if (hasSetPolicyFilter && !policyCompliance?.isInPolicy) {
          return;
        } else {
          faresToShow.push({ fare: selectedFare, flight, fareScore });
        }
      });
    } else {
      const selectedFare = flight.fares[0];
      const fareId = selectedFare.example?.fare ?? selectedFare.id;
      const fareScore = multicityFlights!.fares[fareId]?.fareScore.value;
      faresToShow.push({ fare: selectedFare, flight, fareScore });
    }
  });

  return faresToShow;
};
