import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  useDeviceTypes,
  FlightSummaryPanel,
  MobilePopoverCard,
  ActionButton,
} from "halifax";
import { CallState } from "redmond";
import clsx from "clsx";
import { FlightShopStep } from "../../reducer";
import { OriginalPriceFreezeInfoConnectorProps } from "./container";
import * as constants from "./textConstants";
import "./styles.scss";

interface IOriginalPriceFreezeInfoProps
  extends OriginalPriceFreezeInfoConnectorProps {}

export const OriginalPriceFreezeInfo = (
  props: IOriginalPriceFreezeInfoProps
) => {
  const {
    tripCategory,
    flightShopStep,
    flightSummary,
    frozenPrice,
    fetchSimilarFlightsCallState,
    frozenPriceSavingsCap,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const [showChooseSimilarFlightPopover, setShowChooseSimilarFlightPopover] =
    React.useState<boolean>(false);

  const renderChooseSimilarFlightCopy = () => {
    return (
      <>
        <Typography
          className="title"
          variant="h4"
          dangerouslySetInnerHTML={{
            __html: matchesMobile
              ? constants.CHOOSE_A_SIMILAR_FLIGHT_TITLE
              : constants.CHOOSE_A_SIMILAR_FLIGHT_TITLE_STRONG,
          }}
        />
        <Typography className="subtitle" variant="body2">
          {constants.CHOOSE_A_SIMILAR_FLIGHT_DETAILS(frozenPriceSavingsCap)}
        </Typography>
      </>
    );
  };

  const onClosePopover = () => setShowChooseSimilarFlightPopover(false);

  React.useEffect(() => {
    if (fetchSimilarFlightsCallState === CallState.Success) {
      setShowChooseSimilarFlightPopover(true);
    }
  }, [fetchSimilarFlightsCallState]);

  return (
    <>
      <Box
        className={clsx("original-price-freeze-info-root", {
          mobile: matchesMobile,
        })}
      >
        <Box className="original-price-freeze-info-container">
          <Box className="similar-flight-description-section">
            {renderChooseSimilarFlightCopy()}
          </Box>
          {(flightSummary || (frozenPrice && tripCategory)) && (
            <Box className="original-price-freeze-details-section">
              {flightSummary && (
                <Box className="unavailable-flight-details-section">
                  <Box className="unavailable-flight-details-wrapper">
                    <Typography
                      className="unavailable-flight-title"
                      variant="h4"
                      dangerouslySetInnerHTML={{
                        __html: constants.UNAVAILABLE_FLIGHT_TITLE(
                          flightShopStep === FlightShopStep.ChooseReturn
                        ),
                      }}
                    />
                    <FlightSummaryPanel
                      className="original-price-freeze-flight-summary"
                      {...flightSummary}
                      isMobile={matchesMobile}
                    />
                  </Box>
                </Box>
              )}
              {frozenPrice && tripCategory && (
                <>
                  <Box className="frozen-price-details-section">
                    <Box className="frozen-price-details-wrapper">
                      <Typography
                        className="title"
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: constants.ORIGINAL_FROZEN_PRICE_TITLE,
                        }}
                      />
                      <Typography
                        className="price"
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html:
                            constants.PRICE_FREEZE_DETAILS_FROZEN_PRICE_VALUE(
                              frozenPrice
                            ),
                        }}
                      />
                      <Typography
                        className="details"
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html:
                            constants.TRIP_CATEGORY_SUBTITLE(tripCategory),
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {matchesMobile && showChooseSimilarFlightPopover && (
        <MobilePopoverCard
          open={showChooseSimilarFlightPopover}
          onClose={onClosePopover}
          className={"mobile-choose-similar-flight-copy-popover-root"}
          contentClassName={
            "mobile-choose-similar-flight-copy-popover-content-wrapper"
          }
          bottomButton={
            <ActionButton
              className={clsx(
                "choose-similar-flight-continue-button-root",
                "b2b"
              )}
              buttonClassName="continue-button"
              onClick={onClosePopover}
              message={constants.CONTINUE_TEXT}
              defaultStyle={"h4r-primary"}
            />
          }
          centered={true}
        >
          <Box className="mobile-choose-similar-flight-copy-wrapper">
            {renderChooseSimilarFlightCopy()}
          </Box>
        </MobilePopoverCard>
      )}
    </>
  );
};
