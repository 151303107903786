import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { getMulticityRoutes } from "../../../search/reducer/selectors";
import {
  flightShopMulticityProgressSelector,
  selectedMulticityTripsSelector,
  selectedTripSelector,
  tripDetailsSelector,
  isFlightReviewReadySelector,
  isAddOnOptionAvailableSelector,
} from "../../reducer/selectors";
import {
  setMulticityFlightShopProgress,
  populateFlightShopQueryParams,
} from "../../actions/actions";
import { populateFlightBookQueryParams } from "../../../book/actions/actions";
import { MulticityFlightShopProgressBar } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  // TODO: remove once selected fare option is properly stored in redux
  const tripId =
    selectedTripSelector(state)?.tripId || "uvvDSXpSPPMkcvOKyOJcDqJTJIVbmq";

  return {
    multicityRoutes: getMulticityRoutes(state),
    selectedMulticityTrips: selectedMulticityTripsSelector(state),
    currentProgress: flightShopMulticityProgressSelector(state),
    isTripDetailsEmpty: tripDetailsSelector(state, tripId) === undefined,
    isFlightReviewReady: isFlightReviewReadySelector(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
  };
};

const mapDispatchToProps = {
  setMulticityFlightShopProgress,
  populateFlightBookQueryParams,
  populateFlightShopQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MulticityFlightShopProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMulticityFlightShopProgressBar = connector(
  withRouter(MulticityFlightShopProgressBar)
);
