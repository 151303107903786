import { GetSeatMapResponse } from "redmond";
import { put } from "redux-saga/effects";

import { fetchSeatMap } from "../../../../api/v0/book/book-flow/fetchSeatMap";
import Logger from "../../../../helpers/Logger";
import {
  IFetchSeatMap,
  setSeatMap,
  fetchSeatMapFailed,
  setCheapestSeat,
} from "../../actions/actions";

export function* fetchSeatMapSaga(action: IFetchSeatMap) {
  const { callback } = action;
  try {
    const seatMapResponse: GetSeatMapResponse = yield fetchSeatMap({
      callback,
    });
    yield put(setSeatMap(seatMapResponse.html));
    yield put(setCheapestSeat({
      fiat: seatMapResponse.cheapestSeat.fiat,
      accountSpecific: seatMapResponse.cheapestSeat.rewards,
    }));
  } catch (e) {
    Logger.debug(e);
    yield put(fetchSeatMapFailed());
  }
}
