import { AirportMap } from "redmond";

export const PARTIALLY_REBOOKING_TEXT = ({
  origin,
  destination,
  airports,
}: {
  origin: string | undefined;
  destination: string | undefined;
  airports: AirportMap | undefined;
}) =>
  `You’re partially rebooking from ${
    airports && origin
      ? `${airports[origin].cityName} (${origin})`
      : origin ?? ""
  } to ${
    airports && destination
      ? `${airports[destination].cityName} (${destination})`
      : destination ?? ""
  }`;

export const LOWER_FARE_CLASS_TEXT =
  "The new flight you have selected is a lower fare class than your previous booking.";

export const ITINERARY_ID_QUERY_PARAM = "itineraryId";

export const PATH_HOME = "/";
export const PATH_FLIGHTS = PATH_HOME + "flights/";
