import { AirlineIcon } from "halifax";
import { isEqual } from "lodash-es";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  AirlineCode,
  CLEAR_ALL_FILTERS,
  FlightSortOption,
  IFlightNumberFilter,
  ISortOptions,
  ITimeRange,
  SliceStopCountFilter,
} from "redmond";
import { FlightShopFilterConnectorProps } from "./container";
import { MobileFlightShopFilter } from "./components/MobileFlightShopFilter/component";
import { DesktopFlightShopFilter } from "./components/DesktopFlightShopFilter/component";
import {
  FareclassOptionFilter,
  initialFareclassOptionFilter,
  initialFilterOptions,
  initialTimeRange,
} from "../../../search/reducer";
import {
  FlightShopSortAndFilterType,
  airCXV3SortOptionsToDisplay,
  airCXV4FiltersToDisplay,
  defaultFiltersToDisplay,
  defaultSortOptionsToDisplay,
} from "../../../../utils/sortAndFilter";
import {
  AIR_CX_V3_1_VARIANT_2,
  AirCXV3VariantType,
  CONTROL,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  addTrackingProperties,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { setUserPreferencesNotAvailable } from "../../../search/actions/actions";

export interface IFlightShopFilterProps extends FlightShopFilterConnectorProps {
  openAllFiltersModal: boolean;
  setOpenAllFiltersModal: (arg: boolean) => void;
  filtersToDisplay?: FlightShopSortAndFilterType[];
  sortOptionsToDisplay?: ISortOptions[];
  isMobile?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  viExperimentsValue?: boolean;
  airCXV3Variant?: AirCXV3VariantType;
  isAirCXV4Experiment?: boolean;
}
export const FlightShopFilter = (props: IFlightShopFilterProps) => {
  const {
    openAllFiltersModal,
    flightShopFilters,
    setOpenAllFiltersModal,
    airCXV3Variant,
    sortOptionsToDisplay = airCXV3Variant !== CONTROL
      ? airCXV3SortOptionsToDisplay
      : defaultSortOptionsToDisplay,
    isAirCXV4Experiment,
    filtersToDisplay = isAirCXV4Experiment
      ? airCXV4FiltersToDisplay
      : defaultFiltersToDisplay,
    sortOption,
    stopsOption,
    airlineFilter,
    airportFilter,
    flightNumberFilter,
    departureDateString,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setRerunPrediction,
    setSortOption,
    setStopsOption,
    setAirlineFilter,
    setAirportFilter,
    setFlightNumberFilter,
    setMaxPriceFilter,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    isFlightListOptimizationExperiment,
    tripCategory,
    originAirport,
    destinationAirport,
    origin,
    destination,
    isMobile,
    fareclassOptionFilter,
    setFareclassOptionFilter,
    baggageTransfersFilter,
    setBaggageTransfersFilter,
    isThebesVirtualInterliningInCap1,
    durationFilter,
    setDurationFilter,
    minDuration = 0,
    maxDuration = 0,
    filteredFlightCount,
    userFlightPreferences,
    userFlightPreferencesCallState,
    userHasSetFlightPreferences,
    userFlightPreferencesNotAvailable,
    shouldApplyUserFlightPreferences,
    setApplyUserFlightPreferences,
    setPolicyFilter,
    policyFilter,
  } = props;
  const expState = useExperiments();

  const { airlineOptions, flightNumbersByAirline } = flightShopFilters;

  const useLocalStopsOption = airCXV3Variant !== CONTROL;

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const [
    localShouldApplyUserFlightPreferences,
    setLocalShouldApplyUserFlightPreferences,
  ] = React.useState<boolean>(!!shouldApplyUserFlightPreferences);

  const [
    localUserPreferencesNotAvailable,
    setLocalUserPreferencesNotAvailable,
  ] = React.useState<boolean>(false);

  const [currSortOption, setCurrSortOption] =
    useState<FlightSortOption>(sortOption);
  const [currAirlineFilter, setCurrAirlineFilter] =
    useState<AirlineCode[]>(airlineFilter);
  const [currAirportFilter, setCurrAirportFilter] =
    useState<string[]>(airportFilter);
  const [currFlightNumberAirlineFilter, setCurrFlightNumberAirlineFilter] =
    useState<string>("");
  const [currFlightNumberFilter, setCurrFlightNumberFilter] =
    useState<IFlightNumberFilter[]>(flightNumberFilter);
  const [currMaxPriceFilter, setCurrMaxPriceFilter] =
    useState<number>(maxPriceFilter);
  const [currOutboundDepartureTimeRange, setCurrOutboundDepartureTimeRange] =
    useState<ITimeRange>(outboundDepartureTimeRange);
  const [currOutboundArrivalTimeRange, setCurrOutboundArrivalTimeRange] =
    useState<ITimeRange>(outboundArrivalTimeRange);
  const [currReturnDepartureTimeRange, setCurrReturnDepartureTimeRange] =
    useState<ITimeRange>(returnDepartureTimeRange);
  const [currReturnArrivalTimeRange, setCurrReturnArrivalTimeRange] =
    useState<ITimeRange>(returnArrivalTimeRange);
  const [currFareclassOptionFilter, setCurrFareclassOptionFilter] =
    useState<FareclassOptionFilter>(fareclassOptionFilter);
  const [currBaggageTransfersFilter, setCurrBaggageTransfersFilter] = useState(
    baggageTransfersFilter
  );
  const [currDurationFilter, setCurrDurationFilter] = useState(durationFilter);
  const [currStopsOption, setCurrStopsOption] = useState(stopsOption);
  const [currPolicyFilter, setCurrPolicyFilter] = useState(policyFilter);

  useEffect(() => {
    setCurrSortOption(sortOption);
  }, [sortOption, openAllFiltersModal]);

  useEffect(() => {
    setCurrAirlineFilter(airlineFilter);
  }, [airlineFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrFlightNumberFilter(flightNumberFilter);
  }, [flightNumberFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrAirportFilter(airportFilter);
  }, [airportFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrMaxPriceFilter(maxPriceFilter);
  }, [maxPriceFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrOutboundDepartureTimeRange(outboundDepartureTimeRange);
  }, [outboundDepartureTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrOutboundArrivalTimeRange(outboundArrivalTimeRange);
  }, [outboundArrivalTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrReturnDepartureTimeRange(returnDepartureTimeRange);
  }, [returnDepartureTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrReturnArrivalTimeRange(returnArrivalTimeRange);
  }, [returnArrivalTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrFareclassOptionFilter(fareclassOptionFilter);
  }, [fareclassOptionFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrBaggageTransfersFilter(baggageTransfersFilter);
  }, [baggageTransfersFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrDurationFilter(durationFilter);
  }, [durationFilter, openAllFiltersModal]);

  useEffect(() => {
    if (useLocalStopsOption) {
      setCurrStopsOption(stopsOption);
    }
  }, [stopsOption, openAllFiltersModal]);

  useEffect(() => {
    setCurrPolicyFilter(policyFilter);
  }, [policyFilter, openAllFiltersModal]);

  const allAirlinesWithIcon = React.useMemo(() => {
    return airlineOptions.map((airline) => {
      return {
        ...airline,
        icon: <AirlineIcon airlineCode={airline.value} />,
      };
    });
  }, [airlineOptions]);

  const optionsChanged = useMemo(
    () =>
      !isEqual(sortOption, currSortOption) ||
      !isEqual(airlineFilter, currAirlineFilter) ||
      !isEqual(airportFilter, currAirportFilter) ||
      !isEqual(flightNumberFilter, currFlightNumberFilter) ||
      maxPriceFilter !== currMaxPriceFilter ||
      !isEqual(outboundDepartureTimeRange, currOutboundDepartureTimeRange) ||
      !isEqual(outboundArrivalTimeRange, currOutboundArrivalTimeRange) ||
      !isEqual(returnDepartureTimeRange, currReturnDepartureTimeRange) ||
      !isEqual(returnArrivalTimeRange, currReturnArrivalTimeRange) ||
      !isEqual(fareclassOptionFilter, currFareclassOptionFilter) ||
      !isEqual(baggageTransfersFilter, currBaggageTransfersFilter) ||
      !isEqual(durationFilter, currDurationFilter) ||
      (useLocalStopsOption ? !isEqual(stopsOption, currStopsOption) : false) ||
      localShouldApplyUserFlightPreferences !==
        shouldApplyUserFlightPreferences ||
      !isEqual(policyFilter, currPolicyFilter),
    [
      sortOption,
      currSortOption,
      airlineFilter,
      currAirlineFilter,
      airportFilter,
      currAirportFilter,
      flightNumberFilter,
      currFlightNumberFilter,
      maxPriceFilter,
      currMaxPriceFilter,
      outboundDepartureTimeRange,
      currOutboundDepartureTimeRange,
      outboundArrivalTimeRange,
      currOutboundArrivalTimeRange,
      returnDepartureTimeRange,
      currReturnDepartureTimeRange,
      returnArrivalTimeRange,
      currReturnArrivalTimeRange,
      fareclassOptionFilter,
      currFareclassOptionFilter,
      fareclassOptionFilter,
      baggageTransfersFilter,
      currBaggageTransfersFilter,
      currDurationFilter,
      stopsOption,
      currStopsOption,
      useLocalStopsOption,
      localShouldApplyUserFlightPreferences,
      shouldApplyUserFlightPreferences,
      currPolicyFilter,
      policyFilter,
    ]
  );

  const handleApply = useCallback(() => {
    isMobile && setSortOption(currSortOption); // sort option is on the all filters modal for mobile only
    setAirlineFilter(currAirlineFilter);
    setAirportFilter(currAirportFilter);
    !isEqual(currFlightNumberFilter, flightNumberFilter) && // only set flight number filter if the curr/redux states are different because this action sets airline filter to be [] (we only want it to override the airline filter if user is actually selecting a flight number filter)
      setFlightNumberFilter(currFlightNumberFilter);
    !currFlightNumberFilter.length && setCurrFlightNumberAirlineFilter("");
    setMaxPriceFilter(currMaxPriceFilter);
    setOutboundDepartureTimeRange(currOutboundDepartureTimeRange);
    setOutboundArrivalTimeRange(currOutboundArrivalTimeRange);
    setReturnDepartureTimeRange(currReturnDepartureTimeRange);
    setReturnArrivalTimeRange(currReturnArrivalTimeRange);
    setFareclassOptionFilter(currFareclassOptionFilter, "list");
    setBaggageTransfersFilter(currBaggageTransfersFilter);
    setDurationFilter(currDurationFilter);
    useLocalStopsOption && setStopsOption(currStopsOption, "list");
    setApplyUserFlightPreferences(localShouldApplyUserFlightPreferences);
    setUserPreferencesNotAvailable(localUserPreferencesNotAvailable);
    setPolicyFilter(currPolicyFilter);
    if (
      airCXV3Variant === AIR_CX_V3_1_VARIANT_2 &&
      currStopsOption !== stopsOption
    ) {
      setRerunPrediction();
    }
  }, [
    isMobile,
    sortOption,
    currSortOption,
    airlineFilter,
    currAirlineFilter,
    airportFilter,
    currAirportFilter,
    flightNumberFilter,
    currFlightNumberFilter,
    maxPriceFilter,
    currMaxPriceFilter,
    outboundDepartureTimeRange,
    currOutboundDepartureTimeRange,
    outboundArrivalTimeRange,
    currOutboundArrivalTimeRange,
    returnDepartureTimeRange,
    currReturnDepartureTimeRange,
    returnArrivalTimeRange,
    currReturnArrivalTimeRange,
    fareclassOptionFilter,
    currFareclassOptionFilter,
    baggageTransfersFilter,
    currBaggageTransfersFilter,
    currDurationFilter,
    currStopsOption,
    useLocalStopsOption,
    localShouldApplyUserFlightPreferences,
    localUserPreferencesNotAvailable,
    currPolicyFilter,
  ]);

  const filtersToApplyCount = useMemo(
    () =>
      [
        isMobile && !isEqual(sortOption, currSortOption),
        !isEqual(airlineFilter, currAirlineFilter),
        !isEqual(airportFilter, currAirportFilter),
        !isEqual(flightNumberFilter, currFlightNumberFilter),
        maxPriceFilter !== currMaxPriceFilter,
        !isEqual(outboundDepartureTimeRange, currOutboundDepartureTimeRange),
        !isEqual(outboundArrivalTimeRange, currOutboundArrivalTimeRange),
        !isEqual(returnDepartureTimeRange, currReturnDepartureTimeRange),
        !isEqual(returnArrivalTimeRange, currReturnArrivalTimeRange),
        !isEqual(fareclassOptionFilter, currFareclassOptionFilter),
        !isEqual(baggageTransfersFilter, currBaggageTransfersFilter),
        !isEqual(durationFilter, currDurationFilter),
        !isEqual(policyFilter, currPolicyFilter),
        useLocalStopsOption ? !isEqual(stopsOption, currStopsOption) : false,
      ].filter((hasChanged) => hasChanged).length,

    [
      isMobile,
      sortOption,
      currSortOption,
      airlineFilter,
      currAirlineFilter,
      airportFilter,
      currAirportFilter,
      flightNumberFilter,
      currFlightNumberFilter,
      maxPriceFilter,
      currMaxPriceFilter,
      outboundDepartureTimeRange,
      currOutboundDepartureTimeRange,
      outboundArrivalTimeRange,
      currOutboundArrivalTimeRange,
      returnDepartureTimeRange,
      currReturnDepartureTimeRange,
      returnArrivalTimeRange,
      currReturnArrivalTimeRange,
      fareclassOptionFilter,
      currFareclassOptionFilter,
      baggageTransfersFilter,
      currBaggageTransfersFilter,
      durationFilter,
      currDurationFilter,
      stopsOption,
      currStopsOption,
      useLocalStopsOption,
      currPolicyFilter,
    ]
  );

  const canReset = useMemo(
    () =>
      (isMobile && currSortOption !== FlightSortOption.FareScore) ||
      !isEqual(currAirlineFilter, []) ||
      !isEqual(currAirportFilter, []) ||
      !isEqual(currFlightNumberFilter, []) ||
      currFlightNumberAirlineFilter !== "" ||
      currMaxPriceFilter !== initialFilterOptions.maxPriceFilter ||
      !isEqual(currOutboundDepartureTimeRange, initialTimeRange) ||
      !isEqual(currOutboundArrivalTimeRange, initialTimeRange) ||
      !isEqual(currReturnDepartureTimeRange, initialTimeRange) ||
      !isEqual(currReturnArrivalTimeRange, initialTimeRange) ||
      !isEqual(currFareclassOptionFilter, initialFareclassOptionFilter) ||
      !isEqual(currBaggageTransfersFilter, true) ||
      !isEqual(currDurationFilter, initialFilterOptions.durationFilter) ||
      (useLocalStopsOption
        ? currStopsOption !== initialFilterOptions.stopsOption
        : false) ||
      !isEqual(currPolicyFilter, false),
    [
      isMobile,
      currSortOption,
      currAirlineFilter,
      currAirportFilter,
      currFlightNumberFilter,
      currFlightNumberAirlineFilter,
      currMaxPriceFilter,
      currOutboundDepartureTimeRange,
      currOutboundArrivalTimeRange,
      currReturnDepartureTimeRange,
      currReturnArrivalTimeRange,
      currFareclassOptionFilter,
      currBaggageTransfersFilter,
      currDurationFilter,
      currStopsOption,
      useLocalStopsOption,
      currPolicyFilter,
    ]
  );

  const handleReset = useCallback(() => {
    isMobile && setCurrSortOption(FlightSortOption.FareScore);
    setCurrAirlineFilter([]);
    setCurrFlightNumberAirlineFilter("");
    setCurrFlightNumberFilter([]);
    setCurrAirportFilter([]);
    setCurrMaxPriceFilter(initialFilterOptions.maxPriceFilter);
    setCurrOutboundDepartureTimeRange(initialTimeRange);
    setCurrOutboundArrivalTimeRange(initialTimeRange);
    setCurrReturnDepartureTimeRange(initialTimeRange);
    setCurrReturnArrivalTimeRange(initialTimeRange);
    setCurrFareclassOptionFilter(initialFareclassOptionFilter);
    setCurrBaggageTransfersFilter(true);
    setCurrDurationFilter(initialFilterOptions.durationFilter);
    useLocalStopsOption && setCurrStopsOption(initialFilterOptions.stopsOption);
    setLocalShouldApplyUserFlightPreferences(false);
    setLocalUserPreferencesNotAvailable(false);
    setCurrPolicyFilter(false);

    trackEvent({
      eventName: CLEAR_ALL_FILTERS,
      properties: addTrackingProperties(expState.trackingProperties),
    });
  }, [isMobile]);

  const hasAppliedFilters = useMemo(
    () =>
      (isMobile && currSortOption !== FlightSortOption.FareScore) ||
      !isEqual(airlineFilter, []) ||
      !isEqual(airportFilter, []) ||
      !isEqual(flightNumberFilter, []) ||
      maxPriceFilter !== initialFilterOptions.maxPriceFilter ||
      !isEqual(outboundDepartureTimeRange, initialTimeRange) ||
      !isEqual(outboundArrivalTimeRange, initialTimeRange) ||
      !isEqual(returnDepartureTimeRange, initialTimeRange) ||
      !isEqual(returnArrivalTimeRange, initialTimeRange) ||
      !isEqual(fareclassOptionFilter, initialFareclassOptionFilter) ||
      !isEqual(baggageTransfersFilter, true) ||
      !isEqual(durationFilter, initialFilterOptions.durationFilter) ||
      !isEqual(stopsOption, initialFilterOptions.stopsOption) ||
      !isEqual(policyFilter, false),
    [
      isMobile,
      sortOption,
      airlineFilter,
      airportFilter,
      flightNumberFilter,
      maxPriceFilter,
      outboundDepartureTimeRange,
      outboundArrivalTimeRange,
      returnDepartureTimeRange,
      returnArrivalTimeRange,
      fareclassOptionFilter,
      baggageTransfersFilter,
      durationFilter,
      stopsOption,
      policyFilter,
    ]
  );

  const filtersToApplyMatchDefaultState = React.useMemo(
    () =>
      currSortOption === FlightSortOption.FareScore &&
      isEqual(currAirlineFilter, []) &&
      isEqual(currAirportFilter, []) &&
      isEqual(currFlightNumberFilter, []) &&
      currFlightNumberAirlineFilter === "" &&
      currMaxPriceFilter === initialFilterOptions.maxPriceFilter &&
      isEqual(currOutboundDepartureTimeRange, initialTimeRange) &&
      isEqual(currOutboundArrivalTimeRange, initialTimeRange) &&
      isEqual(currReturnDepartureTimeRange, initialTimeRange) &&
      isEqual(currReturnArrivalTimeRange, initialTimeRange) &&
      isEqual(currFareclassOptionFilter, initialFareclassOptionFilter) &&
      isEqual(currBaggageTransfersFilter, true) &&
      isEqual(currDurationFilter, initialFilterOptions.durationFilter) &&
      (useLocalStopsOption
        ? currStopsOption === initialFilterOptions.stopsOption
        : true) &&
      isEqual(currPolicyFilter, false),
    [
      isMobile,
      currSortOption,
      currAirlineFilter,
      currAirportFilter,
      currFlightNumberFilter,
      currFlightNumberAirlineFilter,
      currMaxPriceFilter,
      currOutboundDepartureTimeRange,
      currOutboundArrivalTimeRange,
      currReturnDepartureTimeRange,
      currReturnArrivalTimeRange,
      currFareclassOptionFilter,
      currBaggageTransfersFilter,
      currDurationFilter,
      currStopsOption,
      useLocalStopsOption,
      currPolicyFilter,
    ]
  );

  React.useEffect(() => {
    const airlines = Object.keys(flightNumbersByAirline);
    if (
      airlines.length &&
      !airlines.some((airline) =>
        flightNumberFilter.some((flightNumFilter) =>
          flightNumbersByAirline[airline].some(
            (airlineFlightNumber) =>
              airlineFlightNumber === flightNumFilter.flightNumber &&
              airline === flightNumFilter.airlineCode
          )
        )
      )
    ) {
      setCurrFlightNumberAirlineFilter("");
    }
  }, [flightNumberFilter, flightNumbersByAirline]);

  React.useEffect(() => {
    if (shouldApplyUserFlightPreferences !== undefined) {
      setLocalShouldApplyUserFlightPreferences(
        shouldApplyUserFlightPreferences
      );
    }
  }, [shouldApplyUserFlightPreferences, openAllFiltersModal]);

  React.useEffect(() => {
    if (
      localShouldApplyUserFlightPreferences &&
      userFlightPreferences &&
      ((!isEqual(userFlightPreferences.airlines, currAirlineFilter) &&
        !localUserPreferencesNotAvailable) ||
        !isEqual(
          userFlightPreferences.fareClasses,
          currFareclassOptionFilter
        ) ||
        userFlightPreferences.nonStopOnly !==
          (currStopsOption === SliceStopCountFilter.NONE))
    ) {
      setLocalShouldApplyUserFlightPreferences(false);
    }
  }, [
    currFareclassOptionFilter,
    currAirlineFilter,
    currStopsOption,
    userFlightPreferences,
    localShouldApplyUserFlightPreferences,
  ]);

  React.useEffect(() => {
    if (
      shouldApplyUserFlightPreferences &&
      userFlightPreferences &&
      ((!isEqual(userFlightPreferences.airlines, airlineFilter) &&
        !userFlightPreferencesNotAvailable) ||
        !isEqual(userFlightPreferences.fareClasses, fareclassOptionFilter) ||
        userFlightPreferences.nonStopOnly !==
          (stopsOption === SliceStopCountFilter.NONE))
    ) {
      setApplyUserFlightPreferences(false);
      setUserPreferencesNotAvailable(false);
    }
  }, [
    fareclassOptionFilter,
    airlineFilter,
    stopsOption,
    userFlightPreferences,
    shouldApplyUserFlightPreferences,
  ]);

  const handleApplyPreferences = React.useCallback(
    (applyPreferences: boolean) => {
      setLocalShouldApplyUserFlightPreferences(applyPreferences);
      if (applyPreferences && userFlightPreferences) {
        setCurrFareclassOptionFilter(userFlightPreferences.fareClasses);
        setCurrAirlineFilter(userFlightPreferences.airlines);
        setCurrStopsOption(
          userFlightPreferences.nonStopOnly
            ? SliceStopCountFilter.NONE
            : SliceStopCountFilter.ANY_NUMBER
        );
      } else if (applyPreferences === false) {
        setCurrFareclassOptionFilter(initialFareclassOptionFilter);
        setCurrAirlineFilter([]);
        setCurrStopsOption(SliceStopCountFilter.ANY_NUMBER);
      }
    },
    [userFlightPreferences]
  );

  return (
    <>
      {!isMobile ? (
        <DesktopFlightShopFilter
          openAllFiltersModal={openAllFiltersModal}
          onCloseModal={() => setOpenAllFiltersModal(false)}
          // TODO: BF-2245 If custom sort and filter options are needed for Desktop, then we should propagate the value into DesktopFlightShopFilter as well.
          // filters={filtersToDisplay}
          // sortOptions={sortOptions}
          sortOption={currSortOption}
          setSortOption={setCurrSortOption}
          stopsOption={useLocalStopsOption ? currStopsOption : stopsOption}
          setStopsOption={
            useLocalStopsOption ? setCurrStopsOption : setStopsOption
          }
          setRerunPrediction={setRerunPrediction}
          airlineFilter={currAirlineFilter}
          setAirlineFilter={setCurrAirlineFilter}
          allAirlines={allAirlinesWithIcon}
          airportFilter={currAirportFilter}
          setAirportFilter={setCurrAirportFilter}
          flightNumberFilter={currFlightNumberFilter}
          setFlightNumberFilter={setCurrFlightNumberFilter}
          departureDateString={departureDateString}
          optionsChanged={optionsChanged}
          handleApply={handleApply}
          flightShopFilters={flightShopFilters}
          maxPriceFilter={currMaxPriceFilter}
          setMaxPriceFilter={setCurrMaxPriceFilter}
          isFlightListOptimizationExperiment={
            isFlightListOptimizationExperiment
          }
          outboundDepartureTimeRange={currOutboundDepartureTimeRange}
          outboundArrivalTimeRange={currOutboundArrivalTimeRange}
          returnDepartureTimeRange={currReturnDepartureTimeRange}
          returnArrivalTimeRange={currReturnArrivalTimeRange}
          setOutboundDepartureTimeRange={setCurrOutboundDepartureTimeRange}
          setOutboundArrivalTimeRange={setCurrOutboundArrivalTimeRange}
          setReturnDepartureTimeRange={setCurrReturnDepartureTimeRange}
          setReturnArrivalTimeRange={setCurrReturnArrivalTimeRange}
          tripCategory={tripCategory}
          originAirport={originAirport}
          destinationAirport={destinationAirport}
          origin={origin}
          destination={destination}
          flightNumberAirlineFilter={currFlightNumberAirlineFilter}
          setFlightNumberAirlineFilter={setCurrFlightNumberAirlineFilter}
          fareclassOptionFilter={currFareclassOptionFilter}
          setFareclassOptionFilter={setCurrFareclassOptionFilter}
          baggageTransfersFilter={currBaggageTransfersFilter}
          setBaggageTransfersFilter={setCurrBaggageTransfersFilter}
          handleReset={handleReset}
          readyToReset={canReset}
          isThebesVirtualInterliningInCap1={isThebesVirtualInterliningInCap1}
          minDuration={minDuration}
          maxDuration={maxDuration}
          filteredFlightCount={filteredFlightCount}
          durationFilter={currDurationFilter}
          setDurationFilter={setCurrDurationFilter}
          airCXV3Variant={airCXV3Variant}
          isCustomerProfileExperiment={isCustomerProfileExperiment}
          userHasSetFlightPreferences={userHasSetFlightPreferences}
          userFlightPreferencesCallState={userFlightPreferencesCallState}
          shouldApplyUserFlightPreferences={
            localShouldApplyUserFlightPreferences
          }
          setApplyUserFlightPreferences={handleApplyPreferences}
          policyFilter={currPolicyFilter}
          setPolicyFilter={setCurrPolicyFilter}
        />
      ) : (
        <MobileFlightShopFilter
          openAllFiltersModal={openAllFiltersModal}
          setOpenAllFiltersModal={setOpenAllFiltersModal}
          sortOptionsToDisplay={sortOptionsToDisplay}
          filtersToDisplay={filtersToDisplay}
          sortOption={currSortOption}
          setSortOption={setCurrSortOption}
          stopsOption={useLocalStopsOption ? currStopsOption : stopsOption}
          setStopsOption={
            useLocalStopsOption ? setCurrStopsOption : setStopsOption
          }
          setRerunPrediction={setRerunPrediction}
          airlineFilter={currAirlineFilter}
          setAirlineFilter={setCurrAirlineFilter}
          allAirlines={allAirlinesWithIcon}
          airportFilter={currAirportFilter}
          setAirportFilter={setCurrAirportFilter}
          flightNumberFilter={currFlightNumberFilter}
          setFlightNumberFilter={setCurrFlightNumberFilter}
          departureDateString={departureDateString}
          optionsChanged={optionsChanged}
          handleApply={handleApply}
          flightShopFilters={flightShopFilters}
          filtersToApplyCount={filtersToApplyCount}
          maxPriceFilter={currMaxPriceFilter}
          setMaxPriceFilter={setCurrMaxPriceFilter}
          isFlightListOptimizationExperiment={
            isFlightListOptimizationExperiment
          }
          outboundDepartureTimeRange={currOutboundDepartureTimeRange}
          outboundArrivalTimeRange={currOutboundArrivalTimeRange}
          returnDepartureTimeRange={currReturnDepartureTimeRange}
          returnArrivalTimeRange={currReturnArrivalTimeRange}
          setOutboundDepartureTimeRange={setCurrOutboundDepartureTimeRange}
          setOutboundArrivalTimeRange={setCurrOutboundArrivalTimeRange}
          setReturnDepartureTimeRange={setCurrReturnDepartureTimeRange}
          setReturnArrivalTimeRange={setCurrReturnArrivalTimeRange}
          tripCategory={tripCategory}
          originAirport={originAirport}
          destinationAirport={destinationAirport}
          origin={origin}
          destination={destination}
          flightNumberAirlineFilter={currFlightNumberAirlineFilter}
          setFlightNumberAirlineFilter={setCurrFlightNumberAirlineFilter}
          handleReset={handleReset}
          readyToReset={canReset}
          hasAppliedFilters={hasAppliedFilters}
          baggageTransfersFilter={currBaggageTransfersFilter}
          setBaggageTransfersFilter={setCurrBaggageTransfersFilter}
          isThebesVirtualInterliningInCap1={isThebesVirtualInterliningInCap1}
          minDuration={minDuration}
          maxDuration={maxDuration}
          filteredFlightCount={filteredFlightCount}
          durationFilter={currDurationFilter}
          setDurationFilter={setCurrDurationFilter}
          airCXV3Variant={airCXV3Variant}
          isCustomerProfileExperiment={isCustomerProfileExperiment}
          userHasSetFlightPreferences={userHasSetFlightPreferences}
          userFlightPreferencesCallState={userFlightPreferencesCallState}
          shouldApplyUserFlightPreferences={
            localShouldApplyUserFlightPreferences
          }
          setApplyUserFlightPreferences={handleApplyPreferences}
          filtersToApplyMatchDefaultState={filtersToApplyMatchDefaultState}
          policyFilter={currPolicyFilter}
          setPolicyFilter={setCurrPolicyFilter}
          fareclassOptionFilter={currFareclassOptionFilter}
          setFareclassOptionFilter={setCurrFareclassOptionFilter}
        />
      )}
    </>
  );
};
