import { ErrorTitles, FiatPrice, IPerson } from "redmond";
import { getTotalPriceText, twoDecimalFormatter } from "halifax";

// CHECKOUT COMPLETION TEXT
export const ADD_TRAVELERS = "Add Travelers";
export const ADD_TRAVELERS_SHORTENED = "Travelers";
export const CONTACT = "Contact Information";
export const CHOOSE_DURATION = "Choose duration";
export const REWARDS_AND_PAYMENT = "Rewards & payment";
export const REWARDS_AND_PAYMENT_SHORTENED = "Payment";
export const REVIEW_ITINERARY = "Review itinerary";
export const TRAVELERS = "Travelers";
export const SEATING = "Seating";
export const DISRUPTION_SUPPORT = "Disruption support";
export const CFAR = "Cancel for any reason";

export const GENERIC_ERROR_TITLE = "Uh oh! Something went wrong.";
export const SESSION_EXPIRED_TITLE = "Uh oh! Your booking session has expired";
export const GENERIC_ERROR_SUBTITLE = "Please search again";
export const EDIT_PAYMENT_METHOD = "Edit Payment Method";
export const EDIT_PAYMENT_INFO = "Edit Payment Information";
export const UPDATE_PAYMENT_INFO = "Update Payment";
export const SEARCH_AGAIN = "Search again";
export const RETRY = "Retry";
export const UPDATE_TRAVELERS = "Update Travelers";
export const GET_A_REFUND = "Get a Refund";
export const CHOOSE_A_SIMILAR_FLIGHT = "Choose a Similar Flight";
export const GO_TO_MY_TRIPS = "My Trips";
export const DUPLICATE_BOOKING = "Duplicate Booking";
export const DUPLICATE_BOOKING_SUBTITLE =
  "Looks like you already have a booking, please check your inbox for a confirmation e-mail";
export const PRICE_FREEZE_GENERATE_CUSTOM_OFFER_FAILED_TITLE =
  "Something went wrong.";
export const PRICE_FREEZE_GENERATE_CUSTOM_OFFER_FAILED_SUBTITLE =
  "We were unable to process your request to freeze this price. Please retry or search again.";
export const PRICE_FREEZE_GENERATE_CUSTOM_OFFER_FAILED_SUBTITLE_WITH_LINE_BREAK = `We were unable to process your request to freeze this price.<br/>Please retry or search again.`;
export const PRICE_FREEZE_SIMILAR_FLIGHTS_SUBTITLE =
  "Don’t worry, we will still honor your frozen price! Just pick a similar flight and continue booking. Or, you can choose to receive a full refund.";
export const PRICE_FREEZE_SIMILAR_FLIGHTS_WITH_REFUND_AMOUNT_SUBTITLE = (
  paid: FiatPrice,
  travelers: number
) =>
  `Don’t worry, we will still honor your frozen price. Just choose a similar flight and continue booking.<br><br>Or, you can choose to receive a full refund for the amount you paid of <strong>${getTotalPriceText(
    {
      price: paid,
      priceFormatter: twoDecimalFormatter,
    }
  )} for ${travelers} ${travelers > 1 ? "travelers" : "traveler"}.</strong>`;
export const PRICE_FREEZE_REFUND_SUBTITLE = (
  paid: FiatPrice,
  travelers: number
) =>
  `You will be refunded the amount you paid of <strong>${getTotalPriceText({
    price: paid,
    priceFormatter: twoDecimalFormatter,
  })} for ${travelers} ${
    travelers > 1 ? "travelers" : "traveler"
  }.</strong> This refund will appear on your original method of payment.`;
export const PRICE_FREEZE_DONT_WORRY_SUBTITLE =
  "Don’t worry, we will still honor your frozen price! Just pick a similar flight and continue booking. Or, you can choose to receive a full refund.";
export const PRICE_FREEZE_FROZEN_PRICE_INCREASE_TITLE = (price: string) =>
  `The frozen price has increased to ${price} per traveler.`;
export const PRICE_FREEZE_FROZEN_PRICE_DECREASE_TITLE = (price: string) =>
  `You’re in luck! The frozen price has decreased to ${price} per traveler.`;
export const PRICE_FREEZE_FROZEN_PRICE_CHANGE_SUBTITLE = (
  isIncrease: boolean
) =>
  `Sometimes airlines change the prices unexpectedly.${
    isIncrease ? ` Sorry about that!` : ""
  }<br/><br/>Would you like to continue to freeze the updated price per traveler?`;
export const CONTINUE_TO_FREEZE = "Continue to freeze";

export const PRICE_INCREASE_OFFER_COVERAGE_TITLE =
  "We’ve detected a price increase. Your Travel Offer no longer covers the total cost of your booking.";
export const PRICE_INCREASE_CREDITS_COVERAGE_TITLE =
  "We’ve detected a price increase. Your travel credits no longer cover the total cost of your booking.";
export const PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE =
  "Please add or select a payment method to complete the booking.";
export const PRICE_DECREASE_SUBTITLE =
  "Sometimes airlines change the prices unexpectedly. You can review the new trip total on the final screen before checkout.";
export const PRICE_INCREASE_SUBTITLE =
  "Sometimes airlines change the prices unexpectedly. Sorry about that! You can review the new trip total on the final screen before checkout.";
export const PRICE_INCREASE_WITH_ADD_ON_SUBTITLE = `<p>Sometimes airlines change the prices unexpectedly. Sorry about that! You can review the new trip total in checkout.</p>
<p><strong>Please review the cost of add-ons on checkout. The cost of add-ons may have changed due to the ticket price increase.</strong></p>`;
export const CONTINUE = "Continue";
export const CONFIRM_AND_BOOK = "Confirm and Book";
export const TRY_AGAIN = "Try Again";
export const CONTACT_US = "Contact Us";
export const INVALID_PASSENGERS = "Invalid passengers";
export const INVALID_PASSPORT_NUMBER = "Invalid passport number";
export const PASSENGER_ERROR = "Passenger Error";
export const UPDATE_PASSPORT_INFO =
  "The passport number you entered is invalid. Please update your traveler information.";
export const REDEMPTION_ISSUE = "Rewards redemption failure";
export const REDEMPTION_ISSUE_SUTITLE =
  "We were unable to redeem your rewards at this time. Please try again, and if the issue continues to persist, please try again later.";

// Icon types
export const ERROR_ICON = "error";
export const UNABLED_ICON = "unabled";
export const PRICE_DECREASE_ICON = "decreased";
export const PRICE_INCREASE_ICON = "increased";

export const getPriceDifferenceTitle = ({
  isIncrease,
  amount,
  currency,
  showPerTraveler,
}: {
  isIncrease: boolean;
  amount: string;
  currency: string | undefined;
  showPerTraveler?: boolean;
}) =>
  `${!isIncrease ? `You're in luck! ` : ``}The price has ${
    isIncrease ? "increased" : "decreased"
  } by ${currency ?? "$"}${amount}${showPerTraveler ? " per traveler" : ""}.`;

export const getNoAvailabilityTitle = (numTravelers: number) =>
  `There's no availability for (${numTravelers}) traveler${
    numTravelers > 1 ? "s" : ""
  }.`;
export const getPriceFreezeNoAvailabilityTitle = (numTravelers: number) =>
  `There's no availability for (${numTravelers}) traveler${
    numTravelers > 1 ? "s" : ""
  } on this flight.`;
export const getNoAvailabilitySubtitle = (numTravelers: number) =>
  numTravelers > 1
    ? "Change the number of travelers or search again for new flights."
    : "Search for new flights.";
export const NO_AVAILABILITY_EDIT_TRAVELER_TEXT = "Edit travelers";

export const CARD_DECLINED = "Uh oh! The card has been declined";
export const CARD_DECLINED_SUBTITLE = "Please update your payment information";

export const FRAUD_SUBTITLE =
  "There is an issue with your account. Please contact Capital One Support.";

export const EARN_STRING = (amount: number, currency?: string) =>
  `You’ll earn <strong>${amount.toLocaleString("en-US")} ${
    currency || "Miles"
  }</strong> on this trip!`;

export const EARNED_STRING = (amount: number, currency?: string) =>
  `You've earned <strong>${amount.toLocaleString("en-US")} ${
    currency || "Miles"
  }</strong> on this trip!`;

export const getSeatNotSelected = (passenger: IPerson) => {
  return {
    carrier: "",
    display_name: "",
    pax: `${passenger.givenName} ${passenger.surname}`,
    pax_id: "",
    person_id: passenger.id,
    seat_id: "",
    total: {
      amount: 0,
      amountUsd: 0,
      currency: "",
      decimalPlaces: 0,
      display: "",

      mantissa: 0,
      mantissaUsd: 0,
    },
  };
};
export const getSeatSelectionNotAvail = (passenger: IPerson) => {
  return {
    carrier: "",
    display_name: "",
    pax: `${passenger.givenName} ${passenger.surname}`,
    pax_id: "",
    person_id: passenger.id,
    seat_id: "Unavailable",
    total: {
      amount: 0,
      amountUsd: 0,
      currency: "",
      decimalPlaces: 0,
      display: "",

      mantissa: 0,
      mantissaUsd: 0,
    },
  };
};

export const TOTAL_AMOUNT_LABEL = (passengerCount: number) =>
  `Total${
    passengerCount
      ? ` (${passengerCount} ${passengerCount > 1 ? "travelers" : "traveler"})`
      : ""
  }`;
export const FEE_PER_TRAVELLER = "Fee per traveler";

export const EXCEEDED_MAX_CAP_NOTIFICATION = (
  sameAmountFiat: FiatPrice,
  afterCreditsAppliedFiat: FiatPrice,
  currentAmountFiat: FiatPrice
) =>
  `The current price for your flight is ${getTotalPriceText({
    price: currentAmountFiat,
  })} and you saved the maximum of ${getTotalPriceText({
    price: sameAmountFiat,
  })} per traveler for this trip. <strong>Your new price per traveler is ${getTotalPriceText(
    {
      price: afterCreditsAppliedFiat,
    }
  )}</strong>.`;

export const PRICE_DROP_NOTIFICATION = (
  sameAmountFiat: FiatPrice,
  afterCreditsAppliedFiat: FiatPrice
) =>
  `<strong>You’ll save ${getTotalPriceText({
    price: sameAmountFiat,
    priceFormatter: twoDecimalFormatter,
  })} per traveler</strong> if you book now. Your ticket price will be <strong>${getTotalPriceText(
    {
      price: afterCreditsAppliedFiat,
      priceFormatter: twoDecimalFormatter,
    }
  )} per traveler!</strong>`;

export const INFANT_ONLY_SUBTITLE =
  "Minors must be accompanied by at least one adult";

export const INACTIVITY_TITLE = "Unable to book flight due to inactivity";
export const ERROR_WITH_SELECTED_SEATS_SUBTITLE =
  "Please reselect seats & payment and try again";
export const INACTIVITY_SUBTITLE = "Please try booking again.";

export const PROVIDER_ERROR_TITLE = "Provider Error";
export const PROVIDER_ERROR_SUBTITLE =
  "The provider was unable to complete your booking. Please try again or select a new flight";
export const PRICE_FREEZE_EXERCISE_PROVIDER_ERROR_SUBTITLE =
  "The provider was unable to complete your booking. Please try again or contact support.";
export const CHOOSE_ANOTHER_FLIGHT = "Choose Another Flight";
export const CHOOSE_FLIGHT = "Change flight";
export const CONTACT_SUPPORT = "Contact Support";
export const NO_ADULT_PAX_TITLE = "Adult passenger required";
export const NO_ADULT_PAX_SUBTITLE =
  "We aren't able to arrange bookings for children (under 18) traveling alone due to airline restrictions. Please add an adult passenger to this booking or contact the airline directly.";
export const MODIFY_PAX = "Update Travelers";
export const TRY_AGAIN_OR_CONTACT_US = "Please try again or contact us.";

export const TRAVEL_OFFER_APPLIED = "Travel offer applied:";
export const TRAVEL_CREDITS_APPLIED = "Travel credits applied:";

export const LAP_INFANT_UNSUPPORTED =
  "This airline does not support lap infants for this trip.";

export const CONTINUE_WITH_OWN_SEAT = "Continue with Own Seat";

export const USER_CARD_ERROR_TITLES: ErrorTitles = {
  icon: ERROR_ICON,
  title: "We were unable to process your payment method.",
  subtitle:
    "Choose another payment method, or delete your original payment method and add it again. If the problem continues, please contact Customer Support at 844-422-6922 for further assistance.",
  primaryButtonText: CONTINUE,
};
