import React from "react";
import {
  Typography,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  IOption,
  LabelDropDown,
  GenericDropdown,
  Icon,
  IconName,
} from "halifax";
import { IFlightNumberFilter } from "../../../../../../reducer";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { generateFilterTrackingEvent } from "../../../../../../actions/actions";
import { trackEvent } from "../../../../../../../../api/v0/analytics/trackEvent";
import clsx from "clsx";
import {
  IAirlineOptions,
  IFlightNumbersByAirlineCode,
} from "../../../../../../../shop/reducer";

interface IFlightNumberFilterProps {
  allAirlines: IAirlineOptions[];
  flightNumberFilter: IFlightNumberFilter[];
  flightNumbersByAirline: IFlightNumbersByAirlineCode;
  departureDateString: string;
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
  flightNumberAirlineFilter: string;
  setFlightNumberAirlineFilter: (airline: string) => void;
  showDropdownContentOnly?: boolean;
  icon?: IconName;
  title?: string;
  useAirlineCheckbox?: boolean;
  airlineSubtitle?: string;
  flightNumberSubtitle?: string;
}

export const FlightNumberFilterSelection = (
  props: IFlightNumberFilterProps
) => {
  const {
    allAirlines,
    flightNumbersByAirline,
    setFlightNumberFilter,
    flightNumberFilter = [],
    departureDateString,
    showDropdownContentOnly,
    icon,
    title,
    useAirlineCheckbox,
    airlineSubtitle,
    flightNumberSubtitle,
    flightNumberAirlineFilter,
    setFlightNumberAirlineFilter,
  } = props;
  const toOptions = (values: string[], labelPrefix?: string): IOption[] => {
    return values.reduce((result: IOption[], value) => {
      const label = labelPrefix ? `${labelPrefix} ${value}` : value;
      const option: IOption = { value, label };
      result.push(option);
      return result;
    }, []);
  };

  const selectedAirlineOption = React.useMemo(() => {
    return allAirlines.find(
      (airline) => airline.value === flightNumberAirlineFilter
    );
  }, [allAirlines, flightNumberAirlineFilter]);

  const handleAirlineChange = (airlineValue: string) => {
    if (airlineValue === flightNumberAirlineFilter) {
      setFlightNumberAirlineFilter("");
    } else {
      setFlightNumberAirlineFilter(airlineValue);
    }
    setFlightNumberFilter([]);
  };

  const handleFlightNumberSelection = (newValue: string) => {
    const filterValue: IFlightNumberFilter = {
      airlineCode: flightNumberAirlineFilter,
      flightNumber: newValue,
    };

    const shouldRemoveFlight = flightNumberFilter.some(
      (item) =>
        item.airlineCode === filterValue.airlineCode &&
        item.flightNumber === filterValue.flightNumber
    );

    if (shouldRemoveFlight) {
      // Read as: if flight number or airline code is not the same, it is not the same flight and we should not remove.
      const newFilters = flightNumberFilter.filter(
        (item) =>
          item.airlineCode !== filterValue.airlineCode ||
          item.flightNumber !== filterValue.flightNumber
      );
      setFlightNumberFilter(newFilters);
    } else {
      setFlightNumberFilter([...flightNumberFilter, filterValue]);
    }
    trackEvent(generateFilterTrackingEvent("flight number", "list"));
  };

  const isChecked = (option: string): boolean => {
    return flightNumberFilter.some((currentOption: IFlightNumberFilter) => {
      return (
        currentOption.flightNumber === option &&
        flightNumberAirlineFilter === currentOption.airlineCode
      );
    });
  };

  const renderFlightNumberOptions = (options: IOption[]) => {
    return options.map((option: IOption) => (
      <FormControlLabel
        className={"flight-number-options"}
        key={option.value}
        control={
          <Checkbox
            className={clsx("flight-number-checkbox", {
              checked: isChecked(option.value),
            })}
            checked={isChecked(option.value)}
            onChange={() => handleFlightNumberSelection(option.value)}
            value={option}
          />
        }
        labelPlacement={"start"}
        label={
          <Box className="label-wrapper">
            {selectedAirlineOption &&
              selectedAirlineOption.icon &&
              selectedAirlineOption.icon}
            <Typography>{option.label}</Typography>
          </Box>
        }
      />
    ));
  };

  const renderAirlineAsCheckbox = (airlines: IAirlineOptions[]) => {
    return airlines.map((airline: IAirlineOptions) => (
      <FormControlLabel
        className={"airline-options"}
        key={airline.value}
        control={
          <Checkbox
            className="airline-checkbox"
            checked={flightNumberAirlineFilter === airline.value}
            onChange={(newValue) => handleAirlineChange(newValue.target.value)}
            value={airline.value}
          />
        }
        labelPlacement="start"
        label={
          <Box className="label-wrapper">
            {airline.icon && airline.icon}
            <Typography>{airline.label}</Typography>
          </Box>
        }
      />
    ));
  };

  const renderDropdownContent = () => {
    return (
      <Box className={"flight-number-selection-root"}>
        <Box className="flight-number-selection-container">
          {!!title && showDropdownContentOnly ? (
            <Box className="header-container">
              {icon && <Icon name={icon} />} {title}
            </Box>
          ) : (
            <Box className="header-container">
              <Typography variant="body1" className={"header-text"}>
                {textConstants.HEADER(departureDateString)}
              </Typography>
            </Box>
          )}
          <Box className="airline-and-flight-number-selection-wrapper">
            <Box className="airline-selection">
              {airlineSubtitle && (
                <Typography
                  className="airline-selection-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: airlineSubtitle,
                  }}
                />
              )}
              <Box>
                {useAirlineCheckbox ? (
                  <FormControl component="fieldset">
                    <FormGroup>
                      {renderAirlineAsCheckbox(allAirlines)}{" "}
                    </FormGroup>
                  </FormControl>
                ) : (
                  <LabelDropDown
                    classes={["airline-selection-menu"]}
                    options={allAirlines}
                    label={""}
                    ariaLabel={"Airline Selector"}
                    onChange={(newValue) => handleAirlineChange(newValue)}
                    value={flightNumberAirlineFilter}
                  />
                )}
              </Box>
            </Box>
            {flightNumberAirlineFilter && (
              <Box className="flight-number-selection">
                {flightNumberSubtitle && (
                  <Typography
                    className="flight-number-selection-subtitle"
                    dangerouslySetInnerHTML={{
                      __html: flightNumberSubtitle,
                    }}
                  />
                )}
                <FormControl component="fieldset">
                  <FormGroup>
                    {renderFlightNumberOptions(
                      toOptions(
                        flightNumbersByAirline[flightNumberAirlineFilter] ?? [],
                        flightNumberAirlineFilter
                      )
                    )}
                  </FormGroup>
                </FormControl>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={"flight-number-selection-dropdown"}>
      {!showDropdownContentOnly && (
        <GenericDropdown
          buttonClassName={"b2b-shop-filter"}
          popoverClassName={clsx("flight-number-selection-popover", "b2b")}
          ariaLabel={`Flight Number Filter`}
          dropdownLabel={textConstants.FLIGHT_NUMBER}
          dropdownContent={renderDropdownContent()}
        />
      )}
      {!!showDropdownContentOnly && renderDropdownContent()}
    </Box>
  );
};
