export const FETCH_REBOOK_SUMMARY = "flightAncillary/FETCH_REBOOK_SUMMARY";
export type FETCH_REBOOK_SUMMARY = typeof FETCH_REBOOK_SUMMARY;

export const SET_REBOOK_SUMMARY_CALL_STATE_FAILED =
  "flightAncillary/SET_REBOOK_SUMMARY_CALL_STATE_FAILED";
export type SET_REBOOK_SUMMARY_CALL_STATE_FAILED =
  typeof SET_REBOOK_SUMMARY_CALL_STATE_FAILED;

export const SET_REBOOK_SUMMARY_RESPONSE =
  "flightAncillary/SET_REBOOK_SUMMARY_RESPONSE";
export type SET_REBOOK_SUMMARY_RESPONSE = typeof SET_REBOOK_SUMMARY_RESPONSE;

export const SET_TODAY_TOMORROW_DATE_FILTER =
  "flightAncillary/SET_TODAY_TOMORROW_DATE_FILTER";
export type SET_TODAY_TOMORROW_DATE_FILTER =
  typeof SET_TODAY_TOMORROW_DATE_FILTER;

export const SET_ANCILLARY_EXPERIMENTS =
  "flightAncillary/SET_ANCILLARY_EXPERIMENTS";
export type SET_ANCILLARY_EXPERIMENTS = typeof SET_ANCILLARY_EXPERIMENTS;

export const BATCH_FETCH_CFAR_OFFERS =
  "flightAncillary/BATCH_FETCH_CFAR_OFFERS";
export type BATCH_FETCH_CFAR_OFFERS = typeof BATCH_FETCH_CFAR_OFFERS;

export const SET_CFAR_OFFERS = "flightAncillary/SET_CFAR_OFFERS";
export type SET_CFAR_OFFERS = typeof SET_CFAR_OFFERS;

export const RESET_CFAR_OFFERS = "flightAncillary/RESET_CFAR_OFFERS";
export type RESET_CFAR_OFFERS = typeof RESET_CFAR_OFFERS;

export const SET_BATCH_CFAR_OFFERS_CALL_STATE_FAILED =
  "flightAncillary/SET_BATCH_CFAR_OFFERS_CALL_STATE_FAILED";
export type SET_BATCH_CFAR_OFFERS_CALL_STATE_FAILED =
  typeof SET_BATCH_CFAR_OFFERS_CALL_STATE_FAILED;

export const SET_ORIGINAL_FLIGHT_ITINERARY_ID =
  "flightAncillary/SET_ORIGINAL_FLIGHT_ITINERARY_ID";
export type SET_ORIGINAL_FLIGHT_ITINERARY_ID =
  typeof SET_ORIGINAL_FLIGHT_ITINERARY_ID;

export const FETCH_SINGLE_FLIGHT_ITINERARY =
  "flightAncillary/FETCH_SINGLE_FLIGHT_ITINERARY";
export type FETCH_SINGLE_FLIGHT_ITINERARY =
  typeof FETCH_SINGLE_FLIGHT_ITINERARY;

export const SET_SINGLE_FLIGHT_ITINERARY =
  "flightAncillary/SET_SINGLE_FLIGHT_ITINERARY";
export type SET_SINGLE_FLIGHT_ITINERARY = typeof SET_SINGLE_FLIGHT_ITINERARY;

export const SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED =
  "flightAncillary/SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED";
export type SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED =
  typeof SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED;

export const SET_DISRUPTED_FLIGHT_ITINERARY_SLICE_AND_SEGMENT_INDEXES =
  "flightAncillary/SET_DISRUPTED_FLIGHT_ITINERARY_SLICE_AND_SEGMENT_INDEXES";
export type SET_DISRUPTED_FLIGHT_ITINERARY_SLICE_AND_SEGMENT_INDEXES =
  typeof SET_DISRUPTED_FLIGHT_ITINERARY_SLICE_AND_SEGMENT_INDEXES;

export const EXERCISE_DISRUPTION_PROTECTION_REBOOK =
  "flightAncillary/EXERCISE_DISRUPTION_PROTECTION_REBOOK";
export type EXERCISE_DISRUPTION_PROTECTION_REBOOK =
  typeof EXERCISE_DISRUPTION_PROTECTION_REBOOK;

export const SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_SUCCESS =
  "flightAncillary/SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_SUCCESS";
export type SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_SUCCESS =
  typeof SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_SUCCESS;

export const SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_FAILED =
  "flightAncillary/SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_FAILED";
export type SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_FAILED =
  typeof SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_FAILED;

export const SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_NOT_CALLED =
  "flightAncillary/SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_NOT_CALLED";
export type SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_NOT_CALLED =
  typeof SET_EXERCISE_DISRUPTION_PROTECTION_REBOOK_CALL_STATE_NOT_CALLED;

export const FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID =
  "tripsList/FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID";
export type FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID =
  typeof FETCH_FLIGHT_DISRUPTION_BY_ITINERARY_ID;

export const SET_FLIGHT_DISRUPTIONS = "tripsList/SET_FLIGHT_DISRUPTIONS";
export type SET_FLIGHT_DISRUPTIONS = typeof SET_FLIGHT_DISRUPTIONS;

export const SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED =
  "tripsList/SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED";
export type SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED =
  typeof SET_FETCH_FLIGHT_DISRUPTIONS_CALL_STATE_FAILED;

export const FETCH_ANCILLARY_POST_BOOKING_OFFER =
  "flightAncillary/FETCH_ANCILLARY_POST_BOOKING_OFFER";
export type FETCH_ANCILLARY_POST_BOOKING_OFFER =
  typeof FETCH_ANCILLARY_POST_BOOKING_OFFER;

export const SET_FETCH_ANCILLARY_POST_BOOKING_OFFER_RESPONSE =
  "flightAncillary/SET_FETCH_ANCILLARY_POST_BOOKING_OFFER_RESPONSE";
export type SET_FETCH_ANCILLARY_POST_BOOKING_OFFER_RESPONSE =
  typeof SET_FETCH_ANCILLARY_POST_BOOKING_OFFER_RESPONSE;

export const RESET_FETCH_ANCILLARY_POST_BOOKING_OFFER_STATE =
  "flightAncillary/RESET_FETCH_ANCILLARY_POST_BOOKING_OFFER_STATE";
export type RESET_FETCH_ANCILLARY_POST_BOOKING_OFFER_STATE =
  typeof RESET_FETCH_ANCILLARY_POST_BOOKING_OFFER_STATE;

export const PURCHASE_ANCILLARY_POST_BOOKING_OFFER =
  "flightAncillary/PURCHASE_ANCILLARY_POST_BOOKING_OFFER";
export type PURCHASE_ANCILLARY_POST_BOOKING_OFFER =
  typeof PURCHASE_ANCILLARY_POST_BOOKING_OFFER;

export const SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE =
  "flightAncillary/SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE";
export type SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE =
  typeof SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE;

export const SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE_CALL_STATE_FAILED =
  "flightAncillary/SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE_CALL_STATE_FAILED";
export type SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE_CALL_STATE_FAILED =
  typeof SET_PURCHASE_ANCILLARY_POST_BOOKING_OFFER_RESPONSE_CALL_STATE_FAILED;

export const COMPLETE_POST_BOOKING_OFFER_SCREEN =
  "flightAncillary/COMPLETE_POST_BOOKING_OFFER_SCREEN";
export type COMPLETE_POST_BOOKING_OFFER_SCREEN =
  typeof COMPLETE_POST_BOOKING_OFFER_SCREEN;

// Corporate Travel
export const SET_AUTO_APPROVAL = "flightAncillary/SET_AUTO_APPROVAL";
export type SET_AUTO_APPROVAL = typeof SET_AUTO_APPROVAL;
