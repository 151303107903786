import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../..../../../../../reducers/types";
import {
  getFareclassOptionFilter,
  getMaxPriceFilter,
  getTripCategory,
} from "../../../../../search/reducer";
import { FlightShopStep, ISelectedTrip } from "../../../../reducer";
import {
  flightInfoSelector,
  flightFareDetailsSelector,
  flightShopProgressSelector,
  selectedTripSelector,
} from "../../../../reducer/selectors";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer";
import { FlightListInfo } from "./component";

export interface IFlightListInfoProps {
  tripId: string;
  onFareClick: (fareId: string, sliceId: string) => void;
}

const mapStateToProps = (state: IStoreState, props: IFlightListInfoProps) => {
  const selectedOWRTrip = selectedTripSelector(state) as ISelectedTrip;
  return {
    tripSummary: flightInfoSelector(state, props.tripId),
    isReturn: flightShopProgressSelector(state) === FlightShopStep.ChooseReturn,
    fareDetails: flightFareDetailsSelector(state, props.tripId),
    outgoingFareRating: selectedOWRTrip.outgoingFareRating,
    tripType: getTripCategory(state),
    maxPriceFilter: getMaxPriceFilter(state),
    fareClassFilter: getFareclassOptionFilter(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    onFareClick: props.onFareClick,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightListInfoConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightListInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightListInfo);
