import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import {
  FlightFreezeConfirmation,
  formatInterval,
  useDeviceTypes,
  removeTimezone,
  ConfirmedFlightSummary,
} from "halifax";
import {
  Airport,
  FareDetails,
  Offer,
  TripSlice,
  SELECTED_PRICE_FREEZE_PURCHASE,
} from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";

import { BookingConfirmationPageConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { RouteComponentProps } from "react-router";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { getPlusDays } from "../../../shop/reducer/utils";
import { getDurationText } from "../../../shop/constants";
import {
  PORTAL_TITLE,
  PRICE_FREEZE_PURCHASE_CONFIRMATION,
} from "../../../../lang/textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import "./styles.scss";

export interface IFinalizedPriceFreezeDataForConfirmationPage {
  expiresAt: string;
  tripSlices: TripSlice | null;
  fareDetails: FareDetails | null;
  airports: { [key: string]: Airport } | null;
  offer: Offer | null;
  currentTripPricePerPax: { fiat: string; rewards: string } | null;
  isRoundTrip: boolean;
  totalPassengers: string;
  priceFreezeExternalId: string;
  userEmail: string;
}
export interface IBookingConfirmationPage
  extends BookingConfirmationPageConnectorProps,
    RouteComponentProps {}

export const BookingConfirmationPage = ({
  history,
  earnString,
  airports,
  priceFreezeConfirmationProps,
  selectedPriceFreezePurchaseProperties,
  isPriceFreezeDurationEnabled,
  isFintechCsatEnabled,
}: IBookingConfirmationPage) => {
  const { matchesMobile } = useDeviceTypes();

  const {
    priceFreezeTripSlices,
    expiresAt,
    frozenPriceDuration,
    priceFreezeFlightPerPax,
    priceFreezePerPax,
    priceFreezeExternalId,
    userEmail,
    isRoundTrip,
    totalTravelers,
  } = priceFreezeConfirmationProps;
  // TODO: since both 'tripDetails' and 'itinerary' are not preserved on refresh, it would cause undefined error;
  // discuss with PM regarding what the expected behaviour is (e.g.: handle it properly with error modals, or preserve those states).
  if (priceFreezeTripSlices === undefined) {
    history.push(PATH_HOME);
    return null;
  }

  const getSliceDetails = (
    tripSlices: TripSlice[],
    isOutgoing: boolean,
    isMobile: boolean,
    airports: { [key: string]: Airport }
  ): ConfirmedFlightSummary => {
    const slice = tripSlices.find((s) => s.outgoing === isOutgoing);
    const { type, description } = textConstants.getReviewCardHeaderWithType(
      isOutgoing,
      airports[slice!.destinationCode]
        ? airports[slice!.destinationCode].cityName
        : slice!.destinationName,
      removeTimezone(slice!.departureTime)
    );

    return {
      airlineCode: slice!.segmentDetails[0].airlineCode,
      airline: slice!.segmentDetails[0].airlineName,
      departureDescriptionBold: type,
      departureDescription: description,
      formattedDepartureTime: dayjs(
        removeTimezone(slice!.departureTime)
      ).format("h:mm A"),
      formattedArrivalTime: dayjs(removeTimezone(slice!.arrivalTime)).format(
        "h:mm A"
      ),
      duration: formatInterval(slice!.totalDurationMinutes || 0),
      stopString: textConstants.getStopsString(slice!.stops),
      plusDays: getPlusDays(slice!),
      isMobile,
    };
  };

  const flightFreezeProps = {
    bannerCopy: textConstants.BANNER_COPY,
    title: textConstants.TITLE(userEmail!),
    subtitle: textConstants.SUBTITLE(priceFreezeExternalId!),
    outgoing: getSliceDetails(
      priceFreezeTripSlices,
      true,
      matchesMobile,
      airports
    ),
    returning: isRoundTrip
      ? getSliceDetails(priceFreezeTripSlices, false, matchesMobile, airports)
      : undefined,
    earnString: earnString ? earnString : "",
    frozenPrice: `<strong>${priceFreezeFlightPerPax!.fiat}</strong>${
      priceFreezeFlightPerPax.rewards
        ? ` / ${priceFreezeFlightPerPax!.rewards}`
        : ""
    }`,
    frozenDuration: frozenPriceDuration
      ? getDurationText(frozenPriceDuration)
      : "",
    feePerTraveler: `<strong>${priceFreezePerPax.fiat}</strong>${
      priceFreezeFlightPerPax.rewards ? ` / ${priceFreezePerPax.rewards}` : ""
    }`,
    frozenTravelers: totalTravelers!,
    nextHeader: textConstants.WHATS_NEXT_HEADER,
    nextContent: textConstants.WHATS_NEXT_CONTENT(expiresAt!),
    onClick: () => history.push(textConstants.FROZEN_PRICES_LIST_LINK),
    isFintechCsatEnabled,
  };

  useEffect(() => {
    document.title = PRICE_FREEZE_PURCHASE_CONFIRMATION;
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, [history]);

  useEffect(() => {
    if (isPriceFreezeDurationEnabled) {
      trackEvent({
        eventName: SELECTED_PRICE_FREEZE_PURCHASE,
        properties: selectedPriceFreezePurchaseProperties,
      });
    }
  }, []);

  return (
    <Box
      className={clsx("flight-booking-success-container", {
        mobile: matchesMobile,
      })}
    >
      <FlightFreezeConfirmation
        isMobile={matchesMobile}
        {...flightFreezeProps}
      />
    </Box>
  );
};
