import {
  AncillaryPostBookingPurchaseResponse,
  AncillaryPostBookingPurchaseStateEnum,
  AncillaryPostBookingPurchaseResponseEnum,
} from "redmond";
import { delay, put } from "redux-saga/effects";
import { actions } from "../../actions";
import Logger from "../../../../helpers/Logger";
import purchasePostBookingOffer from "../../../../api/v2/ancillary/purchasePostBookingOffer";

export function* purchaseAncillaryPostBookingOfferSaga(
  props: actions.IPurchaseAncillaryPostBookingOffer
) {
  const { request, offer } = props;

  const POLL_DELAY_MS = 1000;
  const MAX_DELAY_MS = 60000;
  const maxPolls = MAX_DELAY_MS / POLL_DELAY_MS;

  try {
    var response: AncillaryPostBookingPurchaseResponse =
      yield purchasePostBookingOffer({
        state: {
          PostBookingState: AncillaryPostBookingPurchaseStateEnum.ScheduleQuote,
        },
        request: request,
      });

    var retyAttempts = 0;
    while (retyAttempts++ < maxPolls) {
      switch (response.PostBookingOfferPurchaseResponseV2) {
        case AncillaryPostBookingPurchaseResponseEnum.Polling:
          yield delay(POLL_DELAY_MS);
          response = yield purchasePostBookingOffer({
            state: response.state,
            request: request,
          });
          break;
        case AncillaryPostBookingPurchaseResponseEnum.PurchaseSuccess:
          yield put(
            actions.setPurchaseAncillaryPostBookingOfferResponse(response)
          );
          return;
        case AncillaryPostBookingPurchaseResponseEnum.PaymentFailed:
          yield put(
            actions.setPurchasencillaryPostBookingOfferCallStateFailed({
              offer: offer,
              request: request,
              response: response,
            })
          );
          return;
      }
    }
    throw new Error("Purchase Polling failed with too many attempts");
  } catch (e) {
    yield put(
      actions.setPurchasencillaryPostBookingOfferCallStateFailed({
        offer: offer,
        request: request,
        response: {
          PostBookingOfferPurchaseResponseV2:
            AncillaryPostBookingPurchaseResponseEnum.PaymentFailed,
        },
      })
    );
    Logger.debug(e);
  }
}
