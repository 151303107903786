import React from "react";
import { Icon } from "halifax";
import "./styles.scss";

export const CorpFintechSubscriptionBanner = () => (
  <div className="corp-fintech-subscription-banner">
    <Icon name="light-bulb" />
    <span>
      Protect your work-life balance—add the option to rebook a delayed or
      canceled flight on any airline we offer or get a refund.
    </span>
  </div>
);
