import { connect, ConnectedProps } from "react-redux";
import { ITripTerminus, TripCategory } from "redmond";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  setDepartureDate,
  setDestination,
  setOrigin,
  setReturnDate,
} from "../../actions/actions";
import {
  getTripCategory,
  getDestination,
  getOrigin,
  getNumTravelers,
  getActiveFiltersCount,
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getReturnDate,
  initialFilterOptions,
} from "../../reducer";
import { FlightSearchControl } from "./component";
import { Dispatch } from "@reduxjs/toolkit";
import { withRouter } from "react-router";
import {
  IFlightShopOverwriteQueryParams,
  populateFlightShopQueryParams,
} from "../../../shop/actions/actions";
import { getAirEntryProperties } from "../../../shop/reducer";

const { setTripCategory, fetchDepartureCalendar, setCalendar } = actions;
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTripCategory: (tripCategory: TripCategory) =>
    dispatch(setTripCategory(tripCategory)),
  fetchDepartureCalendar: (origin: ITripTerminus, destination: ITripTerminus) =>
    dispatch(fetchDepartureCalendar(origin, destination)),
  setCalendar: (report?: actions.ISetDepartureCalendarArgs) =>
    dispatch(setCalendar(report)),
  setOrigin: (origin: ITripTerminus | null) => dispatch(setOrigin(origin)),
  setDestination: (destination: ITripTerminus | null) =>
    dispatch(setDestination(destination)),
  setDepartureDate: (date: Date | null) => dispatch(setDepartureDate(date)),
  setReturnDate: (date: Date | null) => dispatch(setReturnDate(date)),
  resetFilters: () => {
    dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
    dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(
      actions.setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
      })
    );
    dispatch(actions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(
      actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
    );
    dispatch(
      actions.setOutboundArrivalTimeRange(
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
    dispatch(
      actions.setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnDepartureTimeRange(
        initialFilterOptions.returnDepartureTimeRange
      )
    );
    dispatch(
      actions.setReturnArrivalTimeRange(
        initialFilterOptions.returnArrivalTimeRange
      )
    );
    dispatch(actions.setPolicyFilter(initialFilterOptions.policyFilter));
  },
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => dispatch(populateFlightShopQueryParams(args)),
});

const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  return {
    tripCategory: getTripCategory(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    numTravelers: getNumTravelers(state),
    filtersCount: getActiveFiltersCount(state),
    departureDate: departureDate ? new Date(departureDate) : null,
    returnDate: returnDate ? new Date(returnDate) : null,
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    airEntryProperties: getAirEntryProperties(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightSearchControl = connector(
  withRouter(FlightSearchControl)
);
