import { createSelector } from "reselect";
import {
  TripCategory,
  IDateBucket,
  SliceStopCountFilter,
  PassengerTypes,
  Passengers,
} from "redmond";
import { isEqual, max, min } from "lodash-es";

import { IStoreState } from "../../../../reducers/types";
import { initialFilterOptions, noLCCFareclassOptionFilter } from "../index";
import {
  flightsByFlightShopTypeSelector,
  hasSetMaxPriceFilterSelectorV2,
} from "../../../shop/reducer";

export const getNumTravelers = (state: IStoreState) =>
  state.flightSearch.numTravelers;

export const getTripCategory = (state: IStoreState) =>
  state.flightSearch.tripCategory;

export const getOpenDatesModal = (state: IStoreState) =>
  state.flightSearch.openDatesModal;

export const getOrigin = (state: IStoreState) => state.flightSearch.origin;

export const getDestination = (state: IStoreState) =>
  state.flightSearch.destination;

export const getOriginCategories = (state: IStoreState) =>
  state.flightSearch.originCategories;

export const getOriginCategoriesLoading = (state: IStoreState) =>
  state.flightSearch.originCategoriesLoading;

export const getDestinationCategories = (state: IStoreState) =>
  state.flightSearch.destinationCategories;

export const getDestinationCategoriesLoading = (state: IStoreState) =>
  state.flightSearch.destinationCategoriesLoading;

export const getDepartureDateBucketsLoading = (state: IStoreState) =>
  state.flightSearch.departureDateBucketsLoading;

export const getMobileSearchProgress = (state: IStoreState) =>
  state.flightSearch.mobileSearchStep;

export const getCurrency = (state: IStoreState) => state.flightSearch.currency;

export const getCalendarQueryOrigin = (state: IStoreState) =>
  state.flightSearch.calendarQueryOrigin;

export const getCalendarQueryDestination = (state: IStoreState) =>
  state.flightSearch.calendarQueryDestination;

export const getDepartureDate = (state: IStoreState) =>
  state.flightSearch.departureDate;

export const getReturnDate = (state: IStoreState) =>
  state.flightSearch.returnDate;

export const getMulticityRoutes = (state: IStoreState) =>
  state.flightSearch.multiCityRoutes;

export const getDepartureMonths = (state: IStoreState) =>
  state.flightSearch.departureMonths;

export const getDepartureDateBuckets = (state: IStoreState) =>
  state.flightSearch.departureDateBuckets;

export const getAwaitingRefetch = (state: IStoreState) =>
  state.flightSearch.awaitingRefetch;

export const getPriceRangeLegends = createSelector(
  [getDepartureDateBuckets],
  (departureDateBuckets) => {
    const legends = departureDateBuckets
      ? departureDateBuckets.map((dateBucket: IDateBucket) => dateBucket.legend)
      : [];
    return legends;
  }
);

export const getPreferences = (state: IStoreState) =>
  state.flightSearch.preferences;

export const getStopsOption = (state: IStoreState) =>
  state.flightSearch.stopsOption;

export const getMaxPriceFilter = (state: IStoreState) =>
  state.flightSearch.maxPriceFilter;

export const getFareclassOptionFilter = (state: IStoreState) =>
  state.flightSearch.fareclassOptionFilter;

export const getBaggageTransfersFilter = (state: IStoreState) =>
  state.flightSearch.baggageTransfersFilter;

export const getOutboundDepartureTimeRange = (state: IStoreState) =>
  state.flightSearch.outboundDepartureTimeRange;

export const getOutboundArrivalTimeRange = (state: IStoreState) =>
  state.flightSearch.outboundArrivalTimeRange;

export const getReturnDepartureTimeRange = (state: IStoreState) =>
  state.flightSearch.returnDepartureTimeRange;

export const getReturnArrivalTimeRange = (state: IStoreState) =>
  state.flightSearch.returnArrivalTimeRange;

export const getAirlineFilter = (state: IStoreState) =>
  state.flightSearch.airlineFilter;

export const getAirportFilter = (state: IStoreState) =>
  state.flightSearch.airportFilter;

export const getFlightNumberFilter = (state: IStoreState) =>
  state.flightSearch.flightNumberFilter;

// Corporate
export const getPolicyFilter = (state: IStoreState) =>
  state.flightSearch.policyFilter;

export const getDurationFilter = (state: IStoreState) =>
  state.flightSearch.durationFilter;

// Get current filter states
// Note: the max price filter state lives in shop/selectors (it relies on data from the trip summaries page)
export const getHasSetStopsOption = (state: IStoreState) =>
  state.flightSearch.stopsOption !== initialFilterOptions.stopsOption;

export const getStopsOptionFilter = (state: IStoreState) =>
  state.flightSearch.stopsOption;

export const getHasSetAirlineFilter = (state: IStoreState) =>
  state.flightSearch.airlineFilter.length > 0;

export const getHasSetAirportFilter = (state: IStoreState) =>
  state.flightSearch.airportFilter.length > 0;

export const getHasSetFlightNumberFilter = (state: IStoreState) =>
  state.flightSearch.flightNumberFilter.length > 0;

export const getHasSetPolicyFilter = (state: IStoreState) =>
  state.flightSearch.policyFilter;

export const getHasSetFareClassFilter = (state: IStoreState) =>
  Object.values(state.flightSearch.fareclassOptionFilter).includes(true);

export const getHasSetDurationFilter = (state: IStoreState) =>
  state.flightSearch.durationFilter !== initialFilterOptions.durationFilter;

export const getMinMaxFlightDurations = (state: IStoreState) => {
  const flights = flightsByFlightShopTypeSelector(state);
  const durations = Object.values(flights?.slices || {}).map(
    (slice) => slice.totalDurationMinutes
  );
  const minDuration = min(durations);
  const maxDuration = max(durations);

  return { minDuration, maxDuration };
};

export const getHasSetOutboundTimeRange = createSelector(
  [getOutboundDepartureTimeRange, getOutboundArrivalTimeRange],
  (outboundDepartureTimeRange, outboundArrivalTimeRange) => {
    return (
      !isEqual(
        outboundDepartureTimeRange,
        initialFilterOptions.outboundDepartureTimeRange
      ) ||
      !isEqual(
        outboundArrivalTimeRange,
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
  }
);

export const getHasSetReturnTimeRange = createSelector(
  [getReturnDepartureTimeRange, getReturnArrivalTimeRange, getTripCategory],
  (returnDepartureTimeRange, returnArrivalTimeRange, tripCategory) => {
    return (
      tripCategory === TripCategory.ROUND_TRIP &&
      (!isEqual(
        returnDepartureTimeRange,
        initialFilterOptions.returnDepartureTimeRange
      ) ||
        !isEqual(
          returnArrivalTimeRange,
          initialFilterOptions.returnArrivalTimeRange
        ))
    );
  }
);

export const hasSelectedDates = createSelector(
  getDepartureDate,
  getReturnDate,
  getTripCategory,
  (departureDate, returnDate, tripCategory): boolean => {
    return (
      (tripCategory === TripCategory.ROUND_TRIP &&
        !!departureDate &&
        !!returnDate) ||
      (tripCategory === TripCategory.ONE_WAY && !!departureDate)
    );
  }
);

export const hasUnsupportedPredictionFilters = createSelector(
  [
    getHasSetOutboundTimeRange,
    getHasSetReturnTimeRange,
    getHasSetAirlineFilter,
    getHasSetAirportFilter,
    getHasSetFlightNumberFilter,
    getFareclassOptionFilter,
    getStopsOptionFilter,
  ],
  (
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    hasSetAirlineFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter,
    fareclassOptionFilter,
    stopsOption
  ) => {
    const filtersValid =
      isEqual(
        fareclassOptionFilter,
        initialFilterOptions.fareclassOptionFilter
      ) || isEqual(fareclassOptionFilter, noLCCFareclassOptionFilter);
    const stopFilterValid =
      stopsOption === SliceStopCountFilter.NONE ||
      stopsOption === SliceStopCountFilter.ANY_NUMBER ||
      stopsOption === SliceStopCountFilter.DEFAULT;
    return (
      !filtersValid ||
      hasSetOutboundTimeRange ||
      hasSetReturnTimeRange ||
      hasSetAirlineFilter ||
      hasSetFlightNumberFilter ||
      hasSetAirportFilter ||
      !stopFilterValid
    );
  }
);

export const getHasSetMaxPriceFilter = (state: IStoreState) =>
  hasSetMaxPriceFilterSelectorV2(state);

export const getActiveFiltersCount = createSelector(
  [
    getStopsOption,
    getFareclassOptionFilter,
    getHasSetOutboundTimeRange,
    getHasSetReturnTimeRange,
    getBaggageTransfersFilter,
    getDurationFilter,
    getHasSetAirlineFilter,
    getHasSetMaxPriceFilter,
    getHasSetAirportFilter,
    getHasSetFlightNumberFilter,
    getPolicyFilter,
  ],
  (
    stopsOption,
    fareclassOptionFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    hasBaggageTransfersFilter,
    durationFilter,
    hasSetAirlineFilter,
    hasSetMaxPriceFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter,
    hasSetPolicyFilter
  ) => {
    let filtersCount = 0;

    if (stopsOption !== initialFilterOptions.stopsOption) {
      filtersCount += 1;
    }

    if (
      !isEqual(
        fareclassOptionFilter,
        initialFilterOptions.fareclassOptionFilter
      )
    ) {
      filtersCount += 1;
    }

    if (hasSetOutboundTimeRange) {
      filtersCount += 1;
    }

    if (hasSetReturnTimeRange) {
      filtersCount += 1;
    }

    if (!hasBaggageTransfersFilter) {
      filtersCount += 1;
    }

    if (durationFilter !== initialFilterOptions.durationFilter) {
      filtersCount += 1;
    }

    if (hasSetAirlineFilter) {
      filtersCount += 1;
    }

    if (hasSetMaxPriceFilter) {
      filtersCount += 1;
    }

    if (hasSetAirportFilter) {
      filtersCount += 1;
    }

    if (hasSetFlightNumberFilter) {
      filtersCount += 1;
    }
    if (hasSetPolicyFilter) {
      filtersCount += 1;
    }

    return filtersCount;
  }
);

export const getAdultsCount = (state: IStoreState) =>
  state.flightSearch.adultsCount;

export const getChildrenCount = (state: IStoreState) =>
  state.flightSearch.childrenCount;

export const getInfantsInSeatCount = (state: IStoreState) =>
  state.flightSearch.infantsInSeatCount;

export const getInfantsOnLapCount = (state: IStoreState) =>
  state.flightSearch.infantsOnLapCount;

export const passengerCountSelector = createSelector(
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  (
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount
  ): Passengers => {
    const passengerObj = {};
    if (adultsCount > 0) passengerObj[PassengerTypes.Adult] = adultsCount;
    if (infantsInSeatCount > 0)
      passengerObj[PassengerTypes.InfantInSeat] = infantsInSeatCount;
    if (infantsOnLapCount > 0)
      passengerObj[PassengerTypes.InfantInLap] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj[PassengerTypes.Child] = childrenCount;
    return passengerObj;
  }
);

export const getPassengersTotal = createSelector(
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  (adultsCount, childrenCount, infantsInSeatCount, infantsOnLapCount) =>
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount
);

export const getHasOriginAutocompleteError = (state: IStoreState) =>
  state.flightSearch.hasOriginAutocompleteError;

export const getHasDestinationAutocompleteError = (state: IStoreState) =>
  state.flightSearch.hasDestinationAutocompleteError;

export const getUserFlightPreferences = (state: IStoreState) =>
  state.flightSearch.userFlightPreferences;

export const getUserFlightPreferencesCallState = (state: IStoreState) =>
  state.flightSearch.userFlightPreferencesCallState;

export const getUpdateUserFlightPreferencesCallState = (state: IStoreState) =>
  state.flightSearch.updateUserFlightPreferencesCallState;

export const getHasUserSetFlightPreferences = createSelector(
  getUserFlightPreferences,
  (userFlightPreferences): boolean => {
    if (userFlightPreferences) {
      return (
        !!userFlightPreferences.airlines.length ||
        !!userFlightPreferences.homeAirport ||
        userFlightPreferences.nonStopOnly ||
        Object.values(userFlightPreferences.fareClasses).some(
          (val) => val == true
        )
      );
    }
    return false;
  }
);

export const getUserFlightPreferencesNotAvailable = (state: IStoreState) =>
  state.flightSearch.userPreferencesNotAvailable;

export const getApplyUserFlightPreferences = (state: IStoreState) =>
  state.flightSearch.applyUserFlightPreferences;
