export const FLIGHTS_HEADER = "Flights";

export const UPDATE_CALENDAR_TEXT = "Updating your calendar";

export const CHOOSE_DATE = "Choose your dates";

export const INFO_NEW_PACKAGES_CTA_BODY_TEXT =
  "Book a hotel + flight as a package and save more with our select package rates.";

export const INFO_NEW_PACKAGES_CTA_HEADER = "Packages";
