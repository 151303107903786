export const REBOOK_CONFIRMATION_TITLE = "Rebooking successful!";
export const REBOOK_CONFIRMATION_SUBTITLE =
  "You successfully rebooked a new flight. You’ll receive a confirmation email with your new itinerary.";

export const WHAT_IS_NEXT = "What’s next?";

export const IMPORTANT_NEXT_STEP_POINT_ONE_TITLE = "Get your luggage";
export const IMPORTANT_NEXT_STEP_POINT_ONE_SUBTITLE =
  "Collect and re-check any luggage.";
export const IMPORTANT_NEXT_STEP_POINT_ONE_ARIA_LABEL =
  "Learn more about luggage details";

export const IMPORTANT_NEXT_STEP_POINT_TWO_TITLE =
  "Confirm your new flight details";
export const IMPORTANT_NEXT_STEP_POINT_TWO_SUBTITLE =
  "Visit the nearest flight information display system to confirm the departure time and gate of your new flight.";

export const IMPORTANT_NEXT_STEP_POINT_THREE_TITLE =
  "Confirm the status of your return flight";
export const IMPORTANT_NEXT_STEP_POINT_THREE_SUBTITLE =
  "Speak with your original airline to confirm that your return flight won’t be canceled. If you experience any issues with your return flight, please contact us for help.";
export const IMPORTANT_NEXT_STEP_POINT_THREE_ARIA_LABEL =
  "Learn more about the return flight";

export const LEARN_MORE = "Learn more";
export const PERIOD_TEXT = ".";

export const GO_TO_MY_TRIPS_COPY = "Go to my trips";
export const BACK_TO_MY_TRIPS_COPY = "Back to my trips";

export const VIEW_DETAILS = "View details";
