import axios, { AxiosResponse } from "axios";
import { config } from "../../../config";

import { paymentMethodsApiPrefix } from "../../paths";
import {
  GetPaymentMethodRequest,
  GetPaymentMethodResponse,
  Payment,
} from "redmond";

const END_POINT: string = `${paymentMethodsApiPrefix}/get`;

export const fetchPaymentMethod = (
  req: GetPaymentMethodRequest
): Promise<GetPaymentMethodResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<GetPaymentMethodResponse> = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody: GetPaymentMethodResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IListPaymentMethodsResponse extends Array<Payment> {}
