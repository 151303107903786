import React from "react";
import {
  CloseButtonIcon,
  Header,
  ActionLink,
  LoadingPopup,
  B2BSpinner,
  FlightCategoryToggle,
  FlightCategoryToggleTripCategory,
} from "halifax";
import { ITripTerminus, RecentFlightSearch, TripCategory } from "redmond";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";

import { MobileFlightSearchConnectorProps } from "./container";
import "./styles.scss";
import { LocationSearch, MobileCalendarPicker } from "./components";
import * as textConstants from "./textConstants";
import { PATH_HOME } from "../../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../reducer";

export interface IMobileFlightSearchControlProps
  extends MobileFlightSearchConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
}

export const MobileFlightSearchControl = (
  props: IMobileFlightSearchControlProps
) => {
  const {
    origin,
    destination,
    originCode,
    destinationCode,
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    departureDateBucketsLoading,
    currentStep,
    departureDate,
    returnDate,
    setCurrentStep,
    setTripCategory,
    setDepartureDate,
    setReturnDate,
    history,
    hasOriginAutocompleteError,
    hasDestinationAutocompleteError,
    setOriginAutocompleteError,
    setDestinationAutocompleteError,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
  } = props;

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const handleGoBack = () => {
    setCurrentStep(
      currentStep > MobileFlightSearchStep.LocationSearch
        ? currentStep - 1
        : currentStep
    );
  };

  React.useEffect(() => {
    if (originCode && destinationCode) {
      fetchDepartureCalendar(
        origin as ITripTerminus,
        destination as ITripTerminus
      );
    } else {
      setCalendar();
    }
  }, [originCode, destinationCode, tripCategory]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const resetAutocompleteErrors = () => {
    hasOriginAutocompleteError && setOriginAutocompleteError(false);
    hasDestinationAutocompleteError && setDestinationAutocompleteError(false);
  };
  return (
    <Box
      className={clsx({
        "date-range-picker":
          currentStep === MobileFlightSearchStep.CalendarPicker,
      })}
    >
      <Header
        center={
          <HeaderCenterSection
            originCode={originCode}
            destinationCode={destinationCode}
            tripCategory={tripCategory}
          />
        }
        left={
          <ActionLink
            className={clsx("mobile-flight-search-header-go-back", {
              hidden: currentStep === MobileFlightSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-flight-search-header-close"
            onClick={() => {
              setCurrentStep(MobileFlightSearchStep.LocationSearch);
              history.push(PATH_HOME);
              resetAutocompleteErrors();
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="location-search-container">
          <Box className="filters-and-trip-category-container">
            <FlightCategoryToggle
              className={clsx("mobile-flight-search-category-toggle", "b2b")}
              setTripCategory={setTripCategory}
              category={tripCategory as FlightCategoryToggleTripCategory}
            />
          </Box>
          <LocationSearch
            onComplete={() =>
              setCurrentStep(MobileFlightSearchStep.CalendarPicker)
            }
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
            onSelectLocation={onSelectLocation}
          />
        </Box>
      )}
      {currentStep === MobileFlightSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          focusedMonthIndex={focusedMonthIndex}
          returnDate={returnDate}
          departure={departureDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          onSelectDates={onSelectDates}
        />
      )}
      {
        <LoadingPopup
          indicatorSize={"small"}
          indicator={B2BSpinner}
          open={!!departureDateBucketsLoading}
          message={textConstants.UPDATE_CALENDAR_TEXT}
        />
      }
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  originCode?: string;
  destinationCode?: string;
  tripCategory: TripCategory;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { originCode, destinationCode } = props;
  return (
    <Box className="header-center-section">
      {originCode && destinationCode && (
        <Box className="trip-origin-destination-summary">
          <Box className="origin-destination-container">
            <Typography variant="body1">{textConstants.CHOOSE_DATE}</Typography>
          </Box>
        </Box>
      )}
      {(!originCode || !destinationCode) && (
        <Box className="mobile-flight-search-location-label">
          <span>{textConstants.FLIGHTS_HEADER}</span>
        </Box>
      )}
    </Box>
  );
};
