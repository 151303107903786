import React from "react";
import { Box } from "@material-ui/core";
import { getTotalPriceText, getRewardText } from "halifax";
import { FiatPrice, RewardsPrice, ChfarAirOrchestratorEvent } from "redmond";
import { ITrackingPropertiesRequest } from "@capone/checkout";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export const getCoMerchPriceWithRewardsUILabel = ({
  price,
  priceFormatter,
  label,
  rewards,
  mobile,
}: {
  price: FiatPrice;
  priceFormatter: any;
  label: string;
  rewards?: RewardsPrice;
  mobile: boolean;
}) => (
  <Box>
    <Box>
      +
      <strong>
        {getTotalPriceText({
          price,
          priceFormatter,
        })}
      </strong>
      {rewards ? (
        mobile ? (
          <>
            <span> / </span>
            <Box>
              {getRewardText({
                reward: rewards,
              })}
            </Box>
          </>
        ) : (
          ` / ${getRewardText({
            reward: rewards,
          })}`
        )
      ) : (
        ""
      )}
    </Box>
    <Box className="per-traveler-subtitle">{label}</Box>
  </Box>
);

export const onChfarViewedEvent = (
  chfarTrackingProps: ITrackingPropertiesRequest | null
) => {
  const properties = chfarTrackingProps?.properties ?? {};
  trackEvent({
    eventName: ChfarAirOrchestratorEvent.VIEW_CHFAR,
    properties,
    encryptedProperties: chfarTrackingProps?.encryptedProperties,
  });
};
