import React from "react";
import { getTotalPriceText, getRewardText, twoDecimalFormatter } from "halifax";
import {
  Box,
  Typography,
  Popover,
  Link,
  InputAdornment,
} from "@material-ui/core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { FlightFreezePriceBreakdownDropdownConnectorProps } from "./container";
import { TOTAL_TEXT } from "./textConstants";
import "./styles.scss";
import { FlightFreezePriceBreakdown } from "../FlightFreezePriceBreakdown";

export interface IFlightFreezePriceBreakdownDropdownProps
  extends FlightFreezePriceBreakdownDropdownConnectorProps {}

export const FlightFreezePriceBreakdownDropdown = ({
  offerTotal,
  totalPassenger,
}: IFlightFreezePriceBreakdownDropdownProps) => {
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);

  const togglePopover = () => {
    setOpenPopover((open) => !open);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  if (!offerTotal) {
    return null;
  }

  // TODO: refactor GenericDropdown component in Halifax so that it can display ReactNode for label, and then refactor this
  // to use the reworked GenericDropdown component.
  return (
    <>
      <Link
        className="flight-freeze-price-breakdown-dropdown-root"
        onClick={togglePopover}
        component={"button"}
      >
        {/* note: this is dropdownLabel */}
        <Box className="total-price-label-container">
          <Typography className="trip-total" variant="subtitle2">{`${TOTAL_TEXT(
            totalPassenger
          )}:`}</Typography>
          <Typography className={clsx("value", "fiat")} variant="subtitle1">
            {`${offerTotal.fiat.currencyCode} ${getTotalPriceText({
              price: offerTotal.fiat,
              priceFormatter: twoDecimalFormatter,
            })}`}
          </Typography>
          <Typography className="separator" variant="subtitle2">
            /
          </Typography>
          <Typography className={clsx("value", "rewards")} variant="subtitle2">
            {getRewardText({
              reward: offerTotal.rewards,
            })}
          </Typography>
        </Box>
        <InputAdornment
          className="total-price-adornment-icon-container"
          position="end"
        >
          <Box>
            <FontAwesomeIcon
              className={clsx("caret-icon", { open: openPopover })}
              icon={faCaretDown}
            />
          </Box>
        </InputAdornment>
      </Link>
      <Popover
        className="flight-freeze-price-breakdown-popover-root"
        open={openPopover}
        PaperProps={{
          className: "flight-freeze-price-breakdown-popover-paper",
        }}
        BackdropProps={{
          className: "flight-freeze-price-breakdown-popover-backdrop",
          invisible: false,
        }}
        hideBackdrop={false}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableEnforceFocus
      >
        <FlightFreezePriceBreakdown />
      </Popover>
    </>
  );
};
