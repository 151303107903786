import React, { useEffect, useMemo, useState } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  useDeviceTypes,
  formatInterval,
  AirlineIcon,
  removeTimezone,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import { uniqBy } from "lodash-es";
import { RouteComponentProps } from "react-router";
import {
  TripDetails,
  FareDetails,
  Airport,
  AirportMap,
  TripCategory,
  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
  VirtualInterlineEntryPoint,
  VirtualInterlineModalProperties,
  VIEWED_SELF_TRANSFER_MODAL,
} from "redmond";

import * as constants from "./constants";
import { FlightShopSummaryPanelConnectorProps } from "./container";
import { getSliceIndex } from "../../../../utils/flights";
import {
  getMulticityReviewHeader,
  getReviewCardHeaderWithType,
  getStopsString,
} from "../FlightShopReviewItinerary/constants";
import { FlightShopStep, MulticityFlightShopStep } from "../../reducer";
import { DesktopFlightReviewDetailsPopover } from "./components";
import { MobileItineraryDetailsModal } from "../FlightShopReviewItinerary/components/MobileItineraryDetailsModal";
import { getIsMixedClass } from "../../constants";

import "./styles.scss";
import { TripSlice, Airport as b2bAirport } from "@b2bportal/air-shopping-api";
import { IVirtualInterliningVariant } from "../VirtualIinterliningModal";
import { airlinesCountTripSegment } from "../../v2/components/FlightList/components/FlightDetails/component";
import { FlightVICombinationBanner } from "../FlightVICombinationBanner";
import {
  FlightMissedConnectionGuarantee,
  SelfTransferBanner,
} from "../FlightMissedConnectionGuarantee";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IFlightShopSummaryPanelProps
  extends FlightShopSummaryPanelConnectorProps,
    RouteComponentProps {
  // note: when both are set to true, nothing would be shown
  showDepartureReviewOnly?: boolean;
  showReturnReviewOnly?: boolean;
  showViewDetailsSection?: boolean;
  prevPath?: string;
  disableChangeFlight?: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  isVITripSelected: boolean;
  setOpenVIVariantModal: (variant: IVirtualInterliningVariant) => void;
  setOpenMultipleAirlinesFares: (value: boolean) => void;
}

const defaultProps: Partial<IFlightShopSummaryPanelProps> = {
  disableChangeFlight: false,
  prevPath: "",
  showDepartureReviewOnly: false,
  showReturnReviewOnly: false,
  showViewDetailsSection: false,
  isSeatsUXOptimizationExperiment: false,
};

export const FlightShopSummaryPanel = (props: IFlightShopSummaryPanelProps) => {
  const {
    tripCategory,
    tripDetails,
    fareDetails,
    showDepartureReviewOnly,
    showReturnReviewOnly,
    showViewDetailsSection,
    airports,
    history,
    prevPath,
    populateFlightShopQueryParams,
    isOutgoingMultiTicket,
    isReturnMultiTicket,
    disableChangeFlight,
    multicityProgress,
    isSeatsUXOptimizationExperiment,
    isVITripSelected,
    setOpenVIVariantModal,
    setOpenMultipleAirlinesFares,
    hasTravelFusionFareBrand,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const [isMulticityPanelOpen, setIsMulticityPanelOpen] = useState(false);

  const isMulticity = useMemo(() => {
    return tripCategory === TripCategory.MULTI_CITY;
  }, [tripCategory]);

  const handleChangeFlight = (
    newQueryParams: Partial<{
      flightShopProgress: FlightShopStep;
      multicityFlightShopProgress: MulticityFlightShopStep;
    }>
  ) => {
    if (!disableChangeFlight)
      populateFlightShopQueryParams({
        history,
        prevPath,
        useHistoryPush: true,
        forceQueryUpdate: true,
        newQueryParams,
      });
  };

  const hasDepartureFlight = getSliceIndex(true, tripDetails) !== -1;
  const hasReturnFlight = getSliceIndex(false, tripDetails) !== -1;

  const showDepartureFlight = hasDepartureFlight && !showReturnReviewOnly;
  const showReturnFlight = hasReturnFlight && !showDepartureReviewOnly;

  const isMulticityBookStep =
    isMulticity &&
    [
      MulticityFlightShopStep.BookTrip,
      MulticityFlightShopStep.FareDetails,
    ].includes(multicityProgress);

  const isInChooseMulticityDeparturesSteps =
    isMulticity &&
    [
      MulticityFlightShopStep.ChooseDeparture0,
      MulticityFlightShopStep.ChooseDeparture1,
      MulticityFlightShopStep.ChooseDeparture2,
      MulticityFlightShopStep.ChooseDeparture3,
      MulticityFlightShopStep.ChooseDeparture4,
    ].includes(multicityProgress);

  const renderOWRTPanels = () => {
    return (
      <Box className="flight-shop-summary-panel-container">
        <Box
          className={clsx("itinerary-cards-section", {
            "only-display-one":
              (showDepartureFlight && !showReturnFlight) ||
              (!showDepartureFlight && showReturnFlight),
          })}
        >
          {showDepartureFlight && (
            <Box
              className={clsx("flight-shop-summary-panel-card", "departure", {
                mobile: matchesMobile,
              })}
            >
              {renderFlightSummaryPanel(true, tripDetails, airports)}
              {showViewDetailsSection && fareDetails && (
                <FlightReviewDetailsPopover
                  departure
                  tripDetails={tripDetails}
                  fareDetails={fareDetails}
                  isMobile={matchesMobile}
                  isMultiTicket={isOutgoingMultiTicket}
                  onClickEdit={() =>
                    handleChangeFlight({
                      flightShopProgress: FlightShopStep.ChooseDeparture,
                    })
                  }
                  airports={airports}
                  isSeatsUXOptimizationExperiment={
                    isSeatsUXOptimizationExperiment
                  }
                  isVITripSelected={isVITripSelected}
                  setOpenVIVariantModal={setOpenVIVariantModal}
                  setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
                  disableChangeFlight={disableChangeFlight}
                />
              )}
            </Box>
          )}
          {showReturnFlight && (
            <Box
              className={clsx("flight-shop-summary-panel-card", "return", {
                mobile: matchesMobile,
              })}
            >
              {renderFlightSummaryPanel(false, tripDetails, airports)}
              {showViewDetailsSection && fareDetails && (
                <FlightReviewDetailsPopover
                  departure={false}
                  tripDetails={tripDetails}
                  fareDetails={fareDetails}
                  isMobile={matchesMobile}
                  isMultiTicket={isReturnMultiTicket}
                  onClickEdit={() =>
                    handleChangeFlight({
                      flightShopProgress: FlightShopStep.ChooseReturn,
                    })
                  }
                  airports={airports}
                  isSeatsUXOptimizationExperiment={
                    isSeatsUXOptimizationExperiment
                  }
                  disableChangeFlight={disableChangeFlight}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderMobileMulticityDetailsModal = () => {
    if (!matchesMobile) return;

    const headerElement = (
      <Box className="mobile-multicity-summary-modal-title">
        {constants.MULTICITY_SUMMARY_MODAL_TITLE}
      </Box>
    );

    const topRightButton = (
      <Box onClick={() => setIsMulticityPanelOpen(false)}>
        <Icon name={IconName.BlueX} />
      </Box>
    );

    return (
      <MobilePopoverCard
        open={isMulticityPanelOpen}
        onClose={() => setIsMulticityPanelOpen(false)}
        headerElement={headerElement}
        topRightButton={topRightButton}
      >
        {renderMulticityCards()}
      </MobilePopoverCard>
    );
  };

  const renderMulticityCards = () => {
    return (
      <Box
        className={clsx("multicity-summary-panel-cards-container", {
          mobile: matchesMobile,
          "horizontal-layout":
            !matchesMobile && isInChooseMulticityDeparturesSteps,
        })}
      >
        {tripDetails.slices.map((slice, index) => {
          const tripSlice = slice as TripSlice;
          const isSelectedSlice = multicityProgress > index;
          if (!isSelectedSlice) return;
          return (
            <Box
              key={`multicity-flight-shop-summary-panel-card-${index}`}
              className={clsx("flight-shop-summary-panel-card", "multicity", {
                mobile: matchesMobile,
              })}
            >
              {renderMulticityFlightSummaryPanel(tripSlice, index, airports)}
              {showViewDetailsSection && fareDetails && (
                <FlightReviewDetailsPopover
                  isMulticity
                  multicitySliceIndex={index}
                  departure
                  tripDetails={tripDetails}
                  fareDetails={fareDetails}
                  isMobile={matchesMobile}
                  onClickEdit={() =>
                    handleChangeFlight({
                      multicityFlightShopProgress:
                        MulticityFlightShopStep[`ChooseDeparture${index}`],
                    })
                  }
                  airports={airports}
                  isSeatsUXOptimizationExperiment={
                    isSeatsUXOptimizationExperiment
                  }
                  hasTravelFusionFareBrand={hasTravelFusionFareBrand}
                />
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderMulticityCollapsiblePanel = () => {
    if (matchesMobile) {
      return (
        <Box
          className={clsx("multicity-view-summary-button", {
            "with-border": isMulticityBookStep,
          })}
          onClick={() => setIsMulticityPanelOpen(!isMulticityPanelOpen)}
        >
          <Icon name={IconName.DiagonalGreyAirplane} />
          <Typography className="multicity-view-summary-button-text">
            {constants.VIEW_MULTICITY_SUMMARY}
          </Typography>
          <Icon name={IconName.RightChevronIcon} />
        </Box>
      );
    }
    return (
      <Box className="flight-shop-summary-panel-container">
        <Box
          className={clsx("multicity-shop-summary-panel-header", {
            isOpen: isMulticityPanelOpen,
          })}
          onClick={() => setIsMulticityPanelOpen(!isMulticityPanelOpen)}
        >
          <Icon
            name={IconName.DiagonalGreyAirplane}
            className="multicity-flight-icon"
          />
          <Typography className="multicity-shop-summary-panel-header-title">
            {constants.VIEW_MULTICITY_DETAILS_TEXT}
          </Typography>
          <Icon
            name={
              isMulticityPanelOpen
                ? IconName.BlueMinusCircle
                : IconName.BluePlusCircle
            }
            className="multicity-flight-icon"
          />
        </Box>
        {isMulticityPanelOpen && renderMulticityCards()}
      </Box>
    );
  };

  return (
    <Box
      className={clsx("flight-shop-summary-panel-root", {
        mobile: matchesMobile,
      })}
    >
      {!isMulticity && renderOWRTPanels()}
      {!matchesMobile &&
        isInChooseMulticityDeparturesSteps &&
        renderMulticityCards()}
      {((matchesMobile && isInChooseMulticityDeparturesSteps) ||
        isMulticityBookStep) &&
        renderMulticityCollapsiblePanel()}
      {isMulticity && renderMobileMulticityDetailsModal()}
    </Box>
  );
};

FlightShopSummaryPanel.defaultProps = defaultProps;

const renderFlightSummaryPanel = (
  departure: boolean,
  tripDetails: TripDetails,
  airports: { [key: string]: Airport }
) => {
  const sliceIndex = getSliceIndex(departure, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  // get all operating airlines & add tooltips because only logos will be shown
  const airlines: any[] = tripSlice.segmentDetails.map((sd) => ({
    ...sd.operatingAirline,
    tooltip: `1 or more segments operated by ${sd.operatingAirline.name}`,
  }));

  // put marketing airline as first without tooltip
  airlines.unshift(tripSlice.segmentDetails[0].marketingAirline);

  // remove duplicates
  const uniqSegmentAirlines = uniqBy(airlines, "code");

  const { type, description } = getReviewCardHeaderWithType(
    departure,
    airports[tripSlice.destinationCode]
      ? airports[tripSlice.destinationCode].cityName
      : tripSlice.destinationName,
    removeTimezone(tripSlice.departureTime)
  );

  return (
    <Box className="airline-details-with-title">
      <Typography className="card-header">
        <span className="bold">{type}</span>
        <span
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></span>
      </Typography>
      <Box className="airline-details">
        <Box className="airline-details-left-container">
          <Typography variant="body1" className="flight-timespan">
            {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
              "h:mm A"
            )} - ${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
              "h:mm A"
            )}`}
          </Typography>
          <Box className="card-airline-container">
            {uniqSegmentAirlines.map((airline, index) => (
              <>
                {/* Only operating airlines should have a tooltip */}
                <Tooltip
                  arrow
                  title={airline.tooltip}
                  key={`airline-tooltip-${index}-${airline.name}`}
                >
                  <span>
                    <AirlineIcon airlineCode={airline.code} />
                  </span>
                </Tooltip>
                {/* Only first airline (marketing) should show name */}
                {index === 0 && (
                  <Typography
                    variant="body2"
                    key={`airline-name-${index}-${airline.name}`}
                  >
                    {airline.name}
                    <span className="airline-divider">/</span>
                  </Typography>
                )}
              </>
            ))}
          </Box>
        </Box>
        <Box className="airline-details-right-container">
          <Typography variant="body1" className="flight-timespan">
            {formatInterval(
              dayjs(tripSlice.arrivalTime).diff(
                dayjs(tripSlice.departureTime),
                "minute",
                true
              )
            )}
          </Typography>
          <Typography variant="body2">
            {getStopsString(tripSlice.stops)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const renderMulticityFlightSummaryPanel = (
  tripSlice: TripSlice,
  flightIndex: number,
  airports: AirportMap
) => {
  // get all operating airlines & add tooltips because only logos will be shown
  const airlines: any[] = tripSlice.segmentDetails.map((sd) => ({
    ...sd.operatingAirline,
    tooltip: `1 or more segments operated by ${sd.operatingAirline.name}`,
  }));

  // put marketing airline as first without tooltip
  airlines.unshift(tripSlice.segmentDetails[0].marketingAirline);

  // remove duplicates
  const uniqSegmentAirlines = uniqBy(airlines, "code");

  const originAirport = airports[tripSlice.originCode] as b2bAirport;
  const destinationAirport = airports[tripSlice.destinationCode] as b2bAirport;

  const [flightDescription, dateString] = getMulticityReviewHeader(
    flightIndex,
    originAirport,
    destinationAirport,
    dayjs(removeTimezone(tripSlice.departureTime)).toDate(),
    true
  ).split("-");

  return (
    <Box className="airline-details-with-title">
      <Typography className="card-header">
        <span className="bold">{flightDescription}</span>
        <span
          dangerouslySetInnerHTML={{
            __html: dateString,
          }}
        ></span>
      </Typography>
      <Box className="airline-details">
        <Box className="airline-details-left-container">
          <Typography variant="body1" className="flight-timespan">
            {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
              "h:mm A"
            )} - ${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
              "h:mm A"
            )}`}
          </Typography>
          <Box className="card-airline-container">
            {uniqSegmentAirlines.map((airline, index) => (
              <>
                {/* Only operating airlines should have a tooltip */}
                <Tooltip
                  arrow
                  title={airline.tooltip}
                  key={`airline-tooltip-${index}-${airline.name}`}
                >
                  <span>
                    <AirlineIcon airlineCode={airline.code} />
                  </span>
                </Tooltip>
                {/* Only first airline (marketing) should show name */}
                {index === 0 && (
                  <Typography
                    variant="body2"
                    key={`airline-name-${index}-${airline.name}`}
                  >
                    {airline.name}
                    <span className="airline-divider">/</span>
                  </Typography>
                )}
              </>
            ))}
          </Box>
        </Box>
        <Box className="airline-details-right-container">
          <Typography variant="body1" className="flight-timespan">
            {formatInterval(
              dayjs(tripSlice.arrivalTime).diff(
                dayjs(tripSlice.departureTime),
                "minute",
                true
              )
            )}
          </Typography>
          <Typography variant="body2">
            {getStopsString(tripSlice.stops)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface IFlightReviewDetailsPopoverProps {
  isMulticity?: boolean;
  multicitySliceIndex?: number;
  departure: boolean;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMobile: boolean;
  isMultiTicket: boolean;
  onClickEdit?: () => void;
  airports: { [key: string]: Airport };
  hasActiveRefundableFare: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  isVITripSelected: boolean;
  setOpenVIVariantModal: (variant: IVirtualInterliningVariant) => void;
  setOpenMultipleAirlinesFares: (value: boolean) => void;
  disableChangeFlight: boolean;
  hasTravelFusionFareBrand: boolean;
}

const popoverDefaultProps: Partial<IFlightReviewDetailsPopoverProps> = {
  onClickEdit: undefined,
};

const FlightReviewDetailsPopover = (
  props: IFlightReviewDetailsPopoverProps
) => {
  const {
    isMulticity,
    multicitySliceIndex,
    departure,
    tripDetails,
    fareDetails,
    isMobile,
    onClickEdit,
    isMultiTicket,
    airports,
    hasActiveRefundableFare,
    isSeatsUXOptimizationExperiment,
    isVITripSelected,
    setOpenVIVariantModal,
    setOpenMultipleAirlinesFares,
    disableChangeFlight,
    hasTravelFusionFareBrand,
  } = props;

  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);

  useEffect(() => {
    if (fareDetails) {
      departure
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [departure, fareDetails]);

  const tripDetailsSlice = tripDetails.slices[departure ? 0 : 1];

  return (
    <Box className="flight-summary-panel-flight-review-details-root">
      <Box className="flight-summary-panel-flight-review-details-container">
        {isMobile && (
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={() => setOpenPopover(true)}
            icon={faChevronRight}
          />
        )}
        {!isMobile && (
          <>
            <ActionLink
              className="open-popover-button"
              onClick={() => setOpenPopover(true)}
              content={constants.VIEW_DETAILS_TEXT}
            />
            {!disableChangeFlight && onClickEdit && (
              <>
                <Typography className="button-separator">|</Typography>
                <ActionLink
                  className="edit-flights-button"
                  onClick={onClickEdit}
                  content={constants.CHANGE_TEXT}
                />
              </>
            )}
          </>
        )}
        {isMobile && (
          <MobileItineraryDetailsModal
            isMulticity={isMulticity}
            multicityTripSliceIndex={
              isMulticity ? multicitySliceIndex : undefined
            }
            openModal={openPopover}
            isDeparture={departure}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
            onClose={() => setOpenPopover(false)}
            isMixedCabinClass={isMixedCabinClass}
            isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
            flightCombinationBanner={
              airlinesCountTripSegment(tripDetailsSlice.segmentDetails) > 0 && (
                <FlightVICombinationBanner
                  isMobile={isMobile}
                  onClick={() => setOpenMultipleAirlinesFares(true)}
                  ctaText={constants.AIRLINE_SPECIFIC_RESTRICTIONS}
                />
              )
            }
            missedConnectionGuarantee={
              isVITripSelected && (
                <>
                  <FlightMissedConnectionGuarantee
                    isVITripSelected={isVITripSelected}
                    isMobile={isMobile}
                    onClick={() => {
                      trackEvent({
                        eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                        properties: {
                          entry_point: VirtualInterlineEntryPoint.Details,
                        } as VirtualInterlineModalProperties,
                      });
                      setOpenVIVariantModal &&
                        setOpenVIVariantModal("missedConnectionGuarantee");
                    }}
                  />
                  <SelfTransferBanner
                    iconName={IconName.BookTravel}
                    onClick={() => {
                      trackEvent({
                        eventName: VIEWED_SELF_TRANSFER_MODAL,
                        properties: {
                          entry_point: VirtualInterlineEntryPoint.Details,
                        } as VirtualInterlineModalProperties,
                      });
                      setOpenVIVariantModal &&
                        setOpenVIVariantModal("selfCheck");
                    }}
                  />
                </>
              )
            }
          />
        )}
        {!isMobile && (
          <DesktopFlightReviewDetailsPopover
            multicitySliceIndex={multicitySliceIndex}
            isMulticity={isMulticity}
            departure={departure}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
            isMultiTicket={isMultiTicket}
            openPopover={openPopover}
            onClose={() => setOpenPopover(false)}
            onClickEdit={onClickEdit}
            airports={airports}
            isMixedCabinClass={isMixedCabinClass}
            hasActiveRefundableFare={hasActiveRefundableFare}
            isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
            isVITripSelected={isVITripSelected}
            setOpenVIVariantModal={setOpenVIVariantModal}
            setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
            disableChangeFlight={disableChangeFlight}
            hasTravelFusionFareBrand={hasTravelFusionFareBrand}
          />
        )}
      </Box>
    </Box>
  );
};

FlightReviewDetailsPopover.defaultProps = popoverDefaultProps;
