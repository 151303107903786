export const WHERE_TO = "Where to?";
export const WHERE_FROM = "Where from?";
export const RETURN = "Return";
export const DEPARTURE = "Departure";
export const SEARCH_AGAIN = "Search Again";
export const SELECT_DATES = "Select";
export const EDIT_TRAVELERS_TITLE = "Travelers";
export const EDIT_MULTICITY_TITLE = "Edit multi-city search";
export const EDIT_MULTICITY_SUBTITLE =
  "Editing your multi-city search will restart your flight selection process.";
export const EDIT_SEARCH = "Edit search";
export const INCOMPLETE_SEARCH_VALUES_WARNING =
  "Please complete all required fields.";
export const FILTERS_MODAL_CTA_TEXT = (appliedFiltersCount: number) =>
  `All filters${appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ""}`;
