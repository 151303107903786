import React from "react";
import { ButtonWrap, Icon, IconName, MultiSelectDropdown } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { IAirlineOptions } from "../../../../../../../shop/reducer";
import { initialFilterOptions } from "../../../../../../reducer";
import { Box } from "@material-ui/core";
import { AirlineCode } from "redmond";

interface IAirlineFilterProps {
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  allAirlines: IAirlineOptions[];
  hasSetAirlineFilter?: boolean;
  showDropdownContentOnly?: boolean;
  appliedLabel?: string;
  isFlightListOptimizationExperiment?: boolean;
  icon?: IconName;
  title?: string;
  optionLabelPlacement?: "start" | "end";
  isAirCXV3Experiment?: boolean;
}

export const AirlineFilterSelection = (props: IAirlineFilterProps) => {
  const {
    airlineFilter,
    setAirlineFilter,
    allAirlines,
    showDropdownContentOnly,
    hasSetAirlineFilter,
    appliedLabel,
    icon,
    title,
    optionLabelPlacement,
    isFlightListOptimizationExperiment,
    isAirCXV3Experiment,
  } = props;

  return (
    <Box className="flight-shop-airline-filter-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />} {title}
        </Box>
      )}
      <MultiSelectDropdown
        currentValue={airlineFilter}
        dropdownLabel={textConstants.AIRLINE(appliedLabel)}
        options={allAirlines}
        setValue={setAirlineFilter}
        showDropdownContentOnly={showDropdownContentOnly}
        className={clsx("b2b-shop-filter", {
          "has-value": hasSetAirlineFilter,
        })}
        popoverClassName={clsx("b2b", "flight-availability-airlines-popover", {
          "filter-experiment": isFlightListOptimizationExperiment,
        })}
        dropdownIcon={
          hasSetAirlineFilter && appliedLabel ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                setAirlineFilter(initialFilterOptions.airlineFilter);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        optionLabelPlacement={optionLabelPlacement}
        anchorOrigin={(() => {
          switch (true) {
            case isAirCXV3Experiment:
              return {
                vertical: "bottom",
                horizontal: "left",
              };
            case isFlightListOptimizationExperiment:
              return {
                vertical: "bottom",
                horizontal: "right",
              };
            default:
              return undefined;
          }
        })()}
        transformOrigin={(() => {
          switch (true) {
            case isAirCXV3Experiment:
              return {
                vertical: "top",
                horizontal: "left",
              };
            case isFlightListOptimizationExperiment:
              return {
                vertical: "top",
                horizontal: "right",
              };
            default:
              return undefined;
          }
        })()}
      />
    </Box>
  );
};
