import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { schedulePayment } from "../../../book/actions/actions";
import {
  getBookingProgressList,
  getConfirmationContactInfo,
  getFinalizedPriceFreeze,
  getIsBookingValid,
  isTravelerStepCompleteSelector,
  priceFreezeOfferDurationSelector,
} from "../../../book/reducer/selectors";
import {
  isPriceFreezeDurationActiveSelector,
  isPriceFreezeDurationPopupEnabledSelector,
  priceFreezeVoidWindowEndSelector,
  priceFreezeVoidWindowSelector,
  isPriceFreezeShowDurationsVariant1Selector,
  getPriceFreezeEntryPointStepSelector,
} from "../../reducer/selectors";
import { DesktopFlightFreezeWorkflow } from "./component";
import { fetchApplicableTravelWalletItems } from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    freezeProgress: getBookingProgressList(state),
    contactInfo: getConfirmationContactInfo(state),

    isBookingValid: getIsBookingValid(state),
    finalizedPriceFreeze: getFinalizedPriceFreeze(state),
    isTravelerStepComplete: isTravelerStepCompleteSelector(state),
    isPriceFreezeDurationActive: isPriceFreezeDurationActiveSelector(state),
    isPriceFreezeDurationPopupEnabled:
      isPriceFreezeDurationPopupEnabledSelector(state),
    priceFreezeVoidWindow: priceFreezeVoidWindowSelector(state),
    priceFreezeVoidWindowEnd: priceFreezeVoidWindowEndSelector(state),
    priceFreezeDuration: priceFreezeOfferDurationSelector(state),
    isPriceFreezeShowDurationsVariant1Active:
      isPriceFreezeShowDurationsVariant1Selector(state),
    priceFreezeEntryPoint: getPriceFreezeEntryPointStepSelector(state),
  };
};

const mapDispatchToProps = {
  schedulePayment,
  fetchApplicableTravelWalletItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopFlightFreezeWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopFlightFreezeWorkflow = withRouter(
  connector(DesktopFlightFreezeWorkflow)
);
