import { put } from "redux-saga/effects";
import { actions } from "../../actions";
import { verifyPaymentMethod } from "../../../../api/v0/book/payment-methods/verifyPaymentMethod";
import {
  PaymentVerifyResultEnum,
  PaymentVerifyResult,
  Success,
  AddPaymentProperties,
  ADD_PAYMENT,
  NEW_PAYMENT_METHOD_ADDED,
  NewPaymentMethodAddedProperties,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { addTrackingProperties } from "../../../../context/experiments";

export function* verifyPaymentMethodSaga({
  payload,
  accountId,
  isVCN,
}: actions.IVerifyPaymentMethod) {
  try {
    const result: PaymentVerifyResult = yield verifyPaymentMethod(payload);

    switch (result.PaymentVerifyResult) {
      case PaymentVerifyResultEnum.CardDeclined:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.ExcessiveUserActivity:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.GenericError:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.Success:
        yield put(actions.verifyPaymentMethodCallStateSuccess());
        // Immediately set the selected payment method on addition.
        yield put(
          actions.setSelectedPaymentMethodId({
            paymentMethodId: (result as Success).payment.id,
            accountId,
          })
        );
        const properties: AddPaymentProperties = {
          success: true,
          is_vcn: isVCN,
        };
        trackEvent({
          eventName: ADD_PAYMENT,
          properties,
        });
        trackEvent({
          eventName: NEW_PAYMENT_METHOD_ADDED,
          properties: addTrackingProperties(undefined, {
            entry_type: "checkout",
          } as NewPaymentMethodAddedProperties),
        });
        yield put(actions.listPaymentMethods());
        return;
      default:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
    }
    const properties: AddPaymentProperties = {
      success: false,
      is_vcn: isVCN,
    };
    trackEvent({
      eventName: ADD_PAYMENT,
      properties,
    });
  } catch (e) {
    yield put(
      actions.verifyPaymentMethodCallStateFailed(
        PaymentVerifyResultEnum.GenericError
      )
    );
    const properties: AddPaymentProperties = {
      success: false,
      is_vcn: isVCN,
    };
    trackEvent({
      eventName: ADD_PAYMENT,
      properties,
    });
  }
}
