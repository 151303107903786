import { connect, ConnectedProps } from "react-redux";
import { TripCategory } from "redmond";
import * as H from "history";
import { IStoreState } from "../../../../../../reducers/types";
import {
  IFlightShopOverwriteQueryParams,
  IPopulateFlightShopQueryParams,
  populateFlightShopQueryParams,
} from "../../../../../shop/actions/actions";
import {
  setDepartureDate,
  setReturnDate,
  setMulticityDepartureDate,
  ISetReturnDate,
  ISetDepartureDate,
  ISetMulticityDepartureDate,
} from "../../../../actions/actions";
import {
  getDepartureDate,
  getReturnDate,
  getTripCategory,
} from "../../../../reducer";
import { MobileCalendarPicker } from "./component";
interface IDispatchProps {
  setDepartureDate: (date: Date | null) => ISetDepartureDate;
  setReturnDate: (date: Date | null) => ISetReturnDate;
  setMulticityDepartureDate: (
    departureDate: Date | null,
    index: number
  ) => ISetMulticityDepartureDate;
  populateFlightShopQueryParams?: (args: {
    history: H.History;
    prevPath?: string;
    useHistoryPush?: boolean;
    forceQueryUpdate?: boolean;
    newQueryParams?: IFlightShopOverwriteQueryParams;
  }) => IPopulateFlightShopQueryParams;
}

const mapDispatchToProps = {
  setDepartureDate,
  setReturnDate,
  setMulticityDepartureDate,
  populateFlightShopQueryParams,
};

interface IStateProps {
  readyToSearch: false | Date | null;
  tripCategory: TripCategory;
}

const mapStateToProps = (state: IStoreState) => {
  const tripCategory = getTripCategory(state);
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  const readyToSearch =
    (tripCategory === TripCategory.ONE_WAY && departureDate) ||
    (tripCategory === TripCategory.ROUND_TRIP && departureDate && returnDate);

  return {
    readyToSearch,
    tripCategory,
  };
};

interface IOwnProps {
  isMultiCityEnabled?: boolean;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type MobileCalendarPickerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPicker = connector(MobileCalendarPicker);
