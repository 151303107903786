import { put, select } from "redux-saga/effects";
import {
  FlightDetail,
  PotentialCrossSellOffersResponse,
  TripCategory,
  TripCategoryEnum,
} from "redmond";
import { actions } from "../actions";
import { fetchPotentialCrossSellOffers } from "../../../api/v0/cross-sell/fetchPotentialCrossSellOffers";
import {
  MulticityRoute,
  getDepartureDate,
  getMulticityRoutes,
  getReturnDate,
  getTripCategory,
} from "../../search/reducer";
import dayjs from "dayjs";

export function* fetchPotentialCrossSellOffersSaga(
  _: actions.IFetchPotentialCrossSellOffers
) {
  try {
    const tripCategory: TripCategory = yield select(getTripCategory);
    // TripCategory uses snake case while TripCategoryEnum (which is what crosssell endpoint uses) uses PascalCase so this mapping helps determine the correct one for the request
    const tripCategoryPascalCaseMapping = {
      one_way: TripCategoryEnum.OneWay,
      round_trip: TripCategoryEnum.RoundTrip,
      multi_city: TripCategoryEnum.MultiCity,
    };

    const request: FlightDetail = {
      firstSliceDepartureDate: "",
      tripCategory: tripCategoryPascalCaseMapping[tripCategory],
    };

    if (tripCategory == TripCategory.MULTI_CITY) {
      const multiCityRoutes: MulticityRoute[] = yield select(
        getMulticityRoutes
      );
      const firstSlice = multiCityRoutes[0];
      const secondSlice = multiCityRoutes[1];
      if (
        !!firstSlice.destination &&
        !!secondSlice.origin &&
        firstSlice.destination.id.code.code === secondSlice.origin.id.code.code
      ) {
        request.firstSliceDepartureDate =
          firstSlice.departureDate?.toISOString() ?? "";
        const los = dayjs(secondSlice.departureDate).diff(
          dayjs(firstSlice.departureDate),
          "day"
        );
        request.lengthOfStay = los;
        request.tripCategory = tripCategoryPascalCaseMapping.round_trip;
      }
    } else {
      const departureDate: Date | null = yield select(getDepartureDate);
      const returnDate: Date | null = yield select(getReturnDate);
      const los = returnDate
        ? dayjs(returnDate).diff(dayjs(departureDate), "day")
        : undefined;

      request.firstSliceDepartureDate = departureDate?.toISOString() ?? "";
      request.lengthOfStay = los;
    }

    if (request.firstSliceDepartureDate) {
      const potentialXSellOffers: PotentialCrossSellOffersResponse =
        yield fetchPotentialCrossSellOffers({ flightDetail: request });
      yield put(
        actions.setPotentialCrossSellOffers(
          potentialXSellOffers.potentialOffers
        )
      );
    }
  } catch (e) {
    yield put(actions.fetchPotentialCrossSellOffersFailed());
  }
}
