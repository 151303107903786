import { put } from "redux-saga/effects";
import { BatchFetchCfarOfferResponseV2 } from "redmond";
import { IBatchFetchCfarOffers } from "../../actions/actions";
import { actions } from "../../actions";
import { getBatchCfarOffers } from "../../../../api/v2/ancillary/getBatchCfarOffers";
import Logger from "../../../../helpers/Logger";

export function* batchFetchCfarOffersSaga(action: IBatchFetchCfarOffers) {
  try {
    const { requests, variant } = action;
    const response: BatchFetchCfarOfferResponseV2 = yield getBatchCfarOffers({
      requests,
    });

    yield put(actions.setCfarOffers(response.responseByTripId, variant));
  } catch (e) {
    yield put(actions.setBatchCfarOffersCallStateFailed());
    Logger.debug(e);
  }
}
