import axios, { AxiosResponse } from "axios";
import { config } from "../../../config";

import { paymentMethodsApiPrefix } from "../../paths";
import {
  DeletePaymentMethodRequest,
  DeletePaymentMethodSuccess,
  Payment,
} from "redmond";

const END_POINT: string = `${paymentMethodsApiPrefix}/delete`;

export const deletePaymentMethod = (
  req: DeletePaymentMethodRequest
): Promise<DeletePaymentMethodSuccess> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<DeletePaymentMethodSuccess> = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody: DeletePaymentMethodSuccess = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IListPaymentMethodsResponse extends Array<Payment> {}
