export const CHECKOUT_BREAKDOWN_HEADER = "Checkout Breakdown";
export const BASE_FARE_COPY = "Base fare";
export const TAXES_FEES_COPY = "Taxes & fees";
export const CFAR_COPY = "Cancel for any reason";
export const CHFAR_COPY = "Change your flight for any reason";
export const DISRUPTION_PROTECTION_COPY = "Flight disruption assistance";

export const getPerTravelerCopy = (type: string) => `${type}, per traveler`;
export const ROUND_TRIP = `Round-trip`;
export const ONE_WAY = "One-way";
export const OR_SEPARATOR = "or";
export const VALIDATION_TEXT =
  "Please accept or decline the options in order to proceed.";
