import React from "react";
import { MultiSelectDropdown } from "halifax";

import * as textConstants from "./textConstants";
import { AirlineFilterProps } from "./container";

interface IAirlineFilterProps extends AirlineFilterProps {
  showDropdownContentOnly?: boolean;
}

export const AirlineFilterSelection = (props: IAirlineFilterProps) => {
  const {
    airlineFilter,
    setAirlineFilter,
    allAirlines,
    showDropdownContentOnly,
  } = props;

  return (
    <MultiSelectDropdown
      currentValue={airlineFilter}
      dropdownLabel={textConstants.AIRLINE}
      options={allAirlines}
      setValue={setAirlineFilter}
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
