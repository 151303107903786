import { connect } from "react-redux";
import { FlightNumberFilterSelection } from "./component";
import { IStoreState } from "../../../../../../../../reducers/types";
import { actions } from "../../../../../../actions/index";
import {
  getFlightNumberFilter,
  getDepartureDate,
} from "../../../../../../reducer/selectors";
import { IFlightNumberFilter } from "../../../../../../reducer";
import {
  allAirlinesSelector,
  flightNumbersByAirlineSelector,
  IFlightNumbersByAirlineCode,
  IAirlineOptions,
} from "../../../../../../../shop/reducer/selectors";
import dayjs from "dayjs";

export interface IFlightNumberFilterSelectionBase {
  allAirlines: IAirlineOptions[];
  flightNumberFilter: IFlightNumberFilter[];
  flightNumbersByAirline: IFlightNumbersByAirlineCode;
  departureDateString: string;
  showDropdownContentOnly?: boolean;
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
}

const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const dayjsDate = departureDate ? dayjs(departureDate) : null;
  const departureDateString = dayjsDate ? dayjsDate.format("ddd, MMM D") : "";

  return {
    allAirlines: allAirlinesSelector(state),
    flightNumberFilter: getFlightNumberFilter(state),
    flightNumbersByAirline: flightNumbersByAirlineSelector(state),
    departureDateString,
  };
};

const mapDispatchToProps = {
  setFlightNumberFilter: actions.setFlightNumberFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const ConnectedFlightNumberFilterSelection = connector(
  FlightNumberFilterSelection as (
    props: IFlightNumberFilterSelectionBase
  ) => JSX.Element
);
