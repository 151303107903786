import dayjs from "dayjs";
import { ITripTerminus } from "redmond";
import { MAX_NUM_MULTICITY_ROUTES } from "../../search/components/FlightSearchControlV2/constants";
import { MulticityRoute } from "../../search/reducer";

export const getMulticityRoutesWithNewOrigin = (
  previousRoutes: MulticityRoute[],
  origin: ITripTerminus | null,
  index: number
) => {
  const updatedRoute = { ...previousRoutes[index], origin };
  let newMcRoutes = [...previousRoutes];
  newMcRoutes[index] = updatedRoute;
  return newMcRoutes;
};

export const getMulticityRoutesWithNewDestination = (
  previousRoutes: MulticityRoute[],
  destination: ITripTerminus | null,
  index: number
) => {
  const updatedRoute = { ...previousRoutes[index], destination };
  let newMcRoutes = [...previousRoutes];
  newMcRoutes[index] = updatedRoute;
  return newMcRoutes;
};

export const getMulticityRoutesWithNewDepartureDate = (
  previousRoutes: MulticityRoute[],
  departureDate: Date | null,
  index: number
) => {
  const updatedRoute = { ...previousRoutes[index], departureDate };
  let newMcRoutes = [...previousRoutes];
  newMcRoutes[index] = updatedRoute;
  return newMcRoutes;
};

export const getMulticityRoutesWithRemovedIndex = (
  previousRoutes: MulticityRoute[],
  index: number
) => {
  let newMcRoutes = [...previousRoutes];
  newMcRoutes.splice(index, 1);
  return newMcRoutes;
};

export const getMinAllowedDate = (
  multicityRoutes: MulticityRoute[],
  index: number
) => {
  const getPrevAllowedDate = (currentIndex: number): Date => {
    const prevRouteIndex = currentIndex - 1;
    const prevRoute = multicityRoutes[prevRouteIndex];

    if (currentIndex <= 0)
      return dayjs()
        .add(index + 1, "day")
        .toDate();
    if (!!prevRoute && prevRoute.departureDate)
      return dayjs(prevRoute?.departureDate)
        .add(index - prevRouteIndex, "day")
        .toDate();
    return getPrevAllowedDate(currentIndex - 1);
  };

  return getPrevAllowedDate(index);
};

export const getMaxAllowedDate = (
  multicityRoutes: MulticityRoute[],
  index: number
) => {
  const getNextAllowedDate = (currentIndex: number): Date => {
    const nextRouteIndex = currentIndex + 1;
    const nextRoute = multicityRoutes[nextRouteIndex];

    if (currentIndex >= MAX_NUM_MULTICITY_ROUTES)
      return dayjs().add(1, "year").toDate();
    if (!!nextRoute && nextRoute.departureDate)
      return dayjs(nextRoute?.departureDate)
        .subtract(nextRouteIndex - index, "day")
        .toDate();
    return getNextAllowedDate(currentIndex + 1);
  };

  return getNextAllowedDate(index);
};
