import React from "react";
import { Box } from "@material-ui/core";

import { MonthAndDatePicker, MonthAndDatePickerType } from "../../../index";
import { FlightSearchButton } from "../../../SearchButton";
import { MobileCalendarPickerConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

import clsx from "clsx";
import { TripCategory } from "redmond";
import { PickerType } from "halifax";
import { ISetDepartureDate, ISetReturnDate } from "../../../../actions/actions";

interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  departure: Date | null;
  returnDate: Date | null;
  setDepartureDate: (date: Date | null) => ISetDepartureDate;
  setReturnDate: (date: Date | null) => ISetReturnDate;
  focusedMonthIndex: number;
  onComplete?: () => void;
  isHistoryPushDisabled?: boolean;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    readyToSearch,
    focusedMonthIndex,
    onComplete,
    setDepartureDate,
    setReturnDate,
    departure,
    returnDate,
    tripCategory,
    isHistoryPushDisabled,
    populateFlightShopQueryParams,
    onSelectDates,
  } = props;

  return (
    <Box className="mobile-calendar-picker-root">
      <MonthAndDatePicker
        className="b2b-flights"
        pickerType={
          tripCategory === TripCategory.ROUND_TRIP
            ? PickerType.RANGE
            : PickerType.DAY
        }
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setDepartureDate}
        setEndDate={setReturnDate}
        startDate={departure}
        endDate={returnDate}
      />
      {readyToSearch && (
        <FlightSearchButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={textConstants.SEARCH_TEXT}
          onClick={(history) => {
            !isHistoryPushDisabled &&
              populateFlightShopQueryParams({
                history,
                useHistoryPush: true,
                forceQueryUpdate: false,
              });
            onComplete && onComplete();
            onSelectDates?.(departure, returnDate);
          }}
        />
      )}
    </Box>
  );
};
