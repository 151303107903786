import { getCurrencySymbol } from "halifax";
import React from "react";
import { SliceStopCountFilter } from "redmond";
import { FARE_CLASSES } from "../../../shop/components/FlightShopHeader/components/FareClassOptionSelection/textConstants";
import { IAirlineOptions } from "../../../shop/reducer";
import { FareclassOptionFilter } from "../../reducer";

export interface FilterLabelValues {
  stopsLabel: string;
  airlineLabel: string;
  timeLabel: string;
  priceLabel: string;
  fareclassLabel: string;
  durationLabel: string;
  policyLabel?: string;
}

export const useFilterLabelValues = ({
  stopsOption,
  airlineFilter,
  hasSetOutboundTimeRange,
  hasSetReturnTimeRange,
  maxPriceFilter,
  airlines,
  fareclassFilter,
  durationFilter,
  policyFilter,
}: {
  stopsOption: SliceStopCountFilter;
  airlineFilter: string[];
  hasSetOutboundTimeRange: boolean;
  hasSetReturnTimeRange: boolean;
  maxPriceFilter: number;
  airlines: IAirlineOptions[];
  fareclassFilter: FareclassOptionFilter;
  durationFilter: number;
  policyFilter: boolean;
}): FilterLabelValues => {
  const [orderedFareclassFilters, setOrderedFareclassFilters] = React.useState<
    string[]
  >([]);

  const stopsLabel = React.useMemo(() => {
    switch (stopsOption) {
      case SliceStopCountFilter.NONE:
        return "Nonstop";
      case SliceStopCountFilter.ONE_OR_LESS:
        return "One stop or fewer";
      case SliceStopCountFilter.TWO_OR_LESS:
        return "Two stops or fewer";
      default:
        return "";
    }
  }, [stopsOption]);

  const airlineLabel = React.useMemo(() => {
    if (airlineFilter.length > 0) {
      const mainLabel = airlines.find(
        (airline) => airline.value === airlineFilter[0]
      )?.label;
      const additionalCount =
        airlineFilter.length > 1 ? ` +${airlineFilter.length - 1}` : "";

      return `${mainLabel}${additionalCount}`;
    }
    return "";
  }, [airlineFilter, airlines]);

  const timeLabel = React.useMemo(() => {
    const mainLabel = (() => {
      if (hasSetOutboundTimeRange) return "Outbound";
      if (hasSetReturnTimeRange) return "Return";
      return "";
    })();
    const additionalCount =
      hasSetOutboundTimeRange && hasSetReturnTimeRange ? ` +1` : "";

    return `${mainLabel}${additionalCount}`;
  }, [hasSetOutboundTimeRange, hasSetReturnTimeRange]);

  const priceLabel = React.useMemo(() => {
    return `${getCurrencySymbol("USD")}${maxPriceFilter}`;
  }, [maxPriceFilter]);

  const fareclassLabel = React.useMemo(() => {
    if (fareclassFilter) {
      const selectedFareclasses = Object.keys(fareclassFilter).filter(
        (fareclass) => fareclassFilter[fareclass]
      );

      /* 
        append newly applied fareclass filter(s) to end of array + remove those that aren't selected.
        the order that fare classes are selected is kept intact so we can show the first applied fareclass in the label.
      */
      const newOrderedFareclassFilters = [
        ...orderedFareclassFilters.filter((fareclass) =>
          selectedFareclasses.includes(fareclass)
        ),
        ...selectedFareclasses.filter(
          (fareclass) => !orderedFareclassFilters.includes(fareclass)
        ),
      ];
      setOrderedFareclassFilters(newOrderedFareclassFilters);

      const mainLabel = FARE_CLASSES[newOrderedFareclassFilters[0]];
      const additionalCount =
        newOrderedFareclassFilters.length > 1
          ? ` +${newOrderedFareclassFilters.length - 1}`
          : "";

      return `${mainLabel}${additionalCount}`;
    }
    return "";
  }, [fareclassFilter]);

  const durationLabel = React.useMemo(
    () => (durationFilter ? `${durationFilter / 60}h` : ""),
    [durationFilter]
  );

  const policyLabel = policyFilter ? "In policy only" : "";

  return {
    stopsLabel,
    airlineLabel,
    timeLabel,
    priceLabel,
    fareclassLabel,
    durationLabel,
    policyLabel,
  };
};
