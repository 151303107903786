import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AncillaryPostBookingViewOffer } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { postBookingOfferStateSelector } from "../../reducer/selectors/postBooking";
import {
  fetchPostBookingOffer,
  purchasePostBookingOffer,
  postBookingOfferComplete,
  resetFetchAncillaryPostBookingOfferState,
} from "../../actions/actions";
import { isPostBookingOffersEnabledSelector } from "../../reducer/selectors";
import { getListPaymentMethodsCallState } from "../../../book/reducer";
import { listPaymentMethods } from "../../../book/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  postBookingOfferState: postBookingOfferStateSelector(state),
  isPostBookingOffersAvailable: isPostBookingOffersEnabledSelector(state),
  listPaymentMethodCallState: getListPaymentMethodsCallState(state),
});

const mapDispatchToProps = {
  fetchPostBookingOffer,
  purchasePostBookingOffer,
  postBookingOfferComplete,
  resetFetchAncillaryPostBookingOfferState,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PostBookingViewOfferConnectorProps = ConnectedProps<
  typeof connector
>;

export const PostBookingViewOffer = withRouter(
  connector(AncillaryPostBookingViewOffer)
);
