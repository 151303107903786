import axios from "axios";
import { WatchAlertView } from "redmond";
import { airPriceWatchApi } from "@b2bportal/air-price-watch-api";

export const listWatches = (): Promise<WatchAlertView[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const res: any = await airPriceWatchApi(
        axios as any
      ).apiV0AlertWatchListPut();
      resolve(res.data.value.watches);
    } catch (e) {
      reject(e);
    }
  });
};
