import { DP_SUPPORT_PHONE_NUMBER } from "redmond";

export const NO_FLIGHTS_FOUND_TITLE = "No flights found";
export const NO_FLIGHTS_FOUND_SUBTITLE = `We can't find any eligible flights for rebooking. Please try changing the date or adjusting your filters. You can also contact support at ${DP_SUPPORT_PHONE_NUMBER.boldLink} for assistance.<br/><br/> If you’re not satisfied with your rebooking options, get a full refund of your base fare and taxes.`;
export const NO_FLIGHTS_FOUND_SUBTITLE_VI = `We can't find any eligible flights for rebooking. Please try changing the date or adjusting your filters. You can also contact support at ${DP_SUPPORT_PHONE_NUMBER.boldLink} for assistance.`;

export const SOMETHING_WENT_WRONG_TITLE = "Something went wrong!";
export const SOMETHING_WENT_WRONG_SUBTITLE =
  "Check to see if you are connected to a network right now or try reloading.";

export const GET_A_REFUND = "Get a Refund";
export const RELOAD = "Reload";
