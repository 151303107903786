import React from "react";
import { useDeviceTypes } from "halifax";

import {
  DesktopFlightShopSearchFilter,
  MobileFlightShopSearchFilter,
} from "./components";

export interface IFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
}

export const FlightShopSearchFilter = (props: IFlightShopSearchFilterProps) => {
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const { hideAirportFilter } = props;

  return (
    <>
      {matchesDesktop && (
        <DesktopFlightShopSearchFilter hideAirportFilter={hideAirportFilter} />
      )}
      {matchesMobile && (
        <MobileFlightShopSearchFilter hideAirportFilter={hideAirportFilter} />
      )}
    </>
  );
};
