import React, { useContext } from "react";

import "./styles.scss";
import { FlightBookMobileButtonConnectorProps } from "./container";
import {
  ADD_TRAVELERS,
  ADD_CONTACT_INFO,
  APPLY_REWARDS,
  CONFIRM_AND_BOOK,
} from "./textConstants";
import { MobileFloatingButton } from "halifax";
import { Box } from "@material-ui/core";
import { ClientContext } from "../../../../App";
import { SUBMIT_FOR_APPROVAL_TEXT } from "../capone-corporate/DesktopFlightBookWorkflow/textConstants";
import { config } from "../../../../api/config";
import { isCorpTenant } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";

interface IFlightBookMobileButtonProps
  extends FlightBookMobileButtonConnectorProps {
  showPaymentStep: boolean;
  hasContactInfo?: boolean;
  isBookingValid: boolean;
  openContactInfo: () => void;
  onApplyRewards: () => void;
  onConfirmAndBook: () => void;
  isInPolicy?: boolean;
  paymentCardOpen?: boolean;
}

export const FlightBookMobileButton = (props: IFlightBookMobileButtonProps) => {
  const {
    hasContactInfo,
    selectedTravelersId,
    openContactInfo,
    showPaymentStep,
    onApplyRewards,
    onConfirmAndBook,
    isBookingValid,
    isInPolicy,
    paymentCardOpen,
  } = props;

  const { isAutoApprovalEnabled } = useContext(ClientContext);

  const isApprovalsV2Enabled =
    useExperimentsById("corp-approvals-v2")?.variant === "m2";

  const [timeoutPassed, setTimeoutPassed] = React.useState<boolean>(false);

  const getButtonText = () => {
    if (selectedTravelersId.length < 1) {
      return ADD_TRAVELERS;
    } else if (!hasContactInfo) {
      return ADD_CONTACT_INFO;
    } else if (!showPaymentStep) {
      return APPLY_REWARDS;
    }
    if (
      isCorpTenant(config.TENANT) &&
      !isInPolicy &&
      !isAutoApprovalEnabled &&
      !isApprovalsV2Enabled
    ) {
      return SUBMIT_FOR_APPROVAL_TEXT;
    } else {
      return CONFIRM_AND_BOOK;
    }
  };
  const isDisabled = showPaymentStep && !isBookingValid;

  React.useEffect(() => {
    // enable button after delay to prevent initial focus state in mobile safari
    if (!paymentCardOpen) {
      setTimeout(() => {
        setTimeoutPassed(true);
      }, 300);
    } else {
      setTimeoutPassed(false);
    }
  }, [paymentCardOpen]);

  if (isDisabled) return null;

  const onClick = () => {
    if (!hasContactInfo) {
      openContactInfo();
    } else if (!showPaymentStep) {
      onApplyRewards();
    } else {
      onConfirmAndBook();
    }
  };

  return (
    <Box className={"flight-book-mobile-button-container"}>
      <MobileFloatingButton
        className="flight-book-mobile-button"
        onClick={onClick}
        wrapperClassName="b2b"
        disabled={!timeoutPassed}
      >
        {getButtonText()}
      </MobileFloatingButton>
    </Box>
  );
};
