import React from "react";
import { GenericShopListFooter, useDeviceTypes } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import { PriceFreezeRefundModal } from "../../../../../book/components/PriceFreezeRefundModal";
import { PriceFreezeRefundConnectorProps } from "./container";
import * as constants from "./textConstants";

export interface IPriceFreezeRefundProps
  extends PriceFreezeRefundConnectorProps {}

export const PriceFreezeRefund = (props: IPriceFreezeRefundProps) => {
  const { setDisplayPriceFreezeRefundModal } = props;
  const { matchesMobile } = useDeviceTypes();
  return (
    <>
      <GenericShopListFooter
        className={clsx("flight-shop-price-freeze-refund-footer", "b2b")}
        title={constants.CANNOT_FIND_SIMILAR_FLIGHTS_TITLE}
        subtitle={constants.CAN_STILL_REFUND_SUBTITLE}
        buttons={[
          {
            title: constants.GET_A_REFUND,
            isPrimary: false,
            onClick: () => {
              setDisplayPriceFreezeRefundModal(true);
            },
            className: "b2b",
          },
        ]}
        isMobile={matchesMobile}
      />
      <PriceFreezeRefundModal />
    </>
  );
};
