import {
  setApplyUserFlightPreferences,
  setBaggageTransfersFilter,
  setDurationFilter,
  setPolicyFilter,
} from "./../../../search/actions/actions";
import dayjs from "dayjs";
import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  setStopsOption,
  setAirlineFilter,
  setAirportFilter,
  setMaxPriceFilter,
  setFlightNumberFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setFareclassOptionFilter,
} from "../../../search/actions/actions";
import {
  getDepartureDate,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getTripCategory,
  getStopsOption,
  getAirlineFilter,
  getHasSetAirlineFilter,
  getAirportFilter,
  getMaxPriceFilter,
  getFlightNumberFilter,
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnArrivalTimeRange,
  getDestination,
  getOrigin,
  getFareclassOptionFilter,
  getBaggageTransfersFilter,
  getDurationFilter,
  getMinMaxFlightDurations,
  getHasUserSetFlightPreferences,
  getUserFlightPreferencesCallState,
  getApplyUserFlightPreferences,
  getUserFlightPreferences,
  getPolicyFilter,
  getUserFlightPreferencesNotAvailable,
} from "../../../search/reducer";
import { setRerunPrediction, setSortOption } from "../../actions/actions";
import {
  allFlightShopFilterSelector,
  filteredFlightsSelectorV2,
  flightsByFlightShopTypeSelector,
  flightShopTypeSelector,
  sortOptionSelector,
} from "../../reducer";
import { FlightShopFilter } from "./component";
import { isThebesVirtualInterliningInCap1ExperimentAvailable } from "../../reducer/selectors/experiments";

const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const dayjsDate = departureDate ? dayjs(departureDate) : null;
  const departureDateString = dayjsDate ? dayjsDate.format("ddd, MMM D") : "";
  const airportMap = flightsByFlightShopTypeSelector(state)?.airports;
  const origin = getOrigin(state);
  const destination = getDestination(state);

  const hasSetTimeRange =
    getHasSetOutboundTimeRange(state) || getHasSetReturnTimeRange(state);

  const originAirport =
    airportMap && origin ? airportMap[origin?.id.code.code] : undefined;
  const destinationAirport =
    airportMap && destination
      ? airportMap[destination?.id.code.code]
      : undefined;

  const { minDuration, maxDuration } = getMinMaxFlightDurations(state);

  return {
    sortOption: sortOptionSelector(state),
    stopsOption: getStopsOption(state),
    airlineFilter: getAirlineFilter(state),
    hasSetAirlineFilter: getHasSetAirlineFilter(state),
    airportFilter: getAirportFilter(state),
    maxPriceFilter: getMaxPriceFilter(state),
    flightShopFilters: allFlightShopFilterSelector(state),
    tripCategory: getTripCategory(state),
    flightShopType: flightShopTypeSelector(state),
    flightNumberFilter: getFlightNumberFilter(state),
    departureDateString,
    outboundDepartureTimeRange: getOutboundDepartureTimeRange(state),
    outboundArrivalTimeRange: getOutboundArrivalTimeRange(state),
    returnDepartureTimeRange: getReturnDepartureTimeRange(state),
    returnArrivalTimeRange: getReturnArrivalTimeRange(state),
    fareclassOptionFilter: getFareclassOptionFilter(state),
    baggageTransfersFilter: getBaggageTransfersFilter(state),
    isThebesVirtualInterliningInCap1:
      isThebesVirtualInterliningInCap1ExperimentAvailable(state),
    hasSetTimeRange,
    origin,
    originAirport,
    destination,
    destinationAirport,
    airportMap,
    durationFilter: getDurationFilter(state),
    minDuration,
    maxDuration,
    filteredFlightCount: filteredFlightsSelectorV2(state).length,
    userHasSetFlightPreferences: getHasUserSetFlightPreferences(state),
    userFlightPreferencesNotAvailable:
      getUserFlightPreferencesNotAvailable(state),
    userFlightPreferences: getUserFlightPreferences(state),
    userFlightPreferencesCallState: getUserFlightPreferencesCallState(state),
    shouldApplyUserFlightPreferences: getApplyUserFlightPreferences(state),
    policyFilter: getPolicyFilter(state),
  };
};

const mapDispatchToProps = {
  setSortOption,
  setStopsOption,
  setRerunPrediction,
  setAirlineFilter,
  setAirportFilter,
  setMaxPriceFilter,
  setFlightNumberFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setFareclassOptionFilter,
  setBaggageTransfersFilter,
  setDurationFilter,
  setApplyUserFlightPreferences,
  setPolicyFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopFilterConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShopFilter = connector(FlightShopFilter);
