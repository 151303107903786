export type RewardDisplayType = "flight" | "disruption";

export const rewardDisplayTypeName = (type: RewardDisplayType) => {
  switch (type) {
    case "flight":
      return "booking";
    case "disruption":
      return "purchase";
  }
};
