import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getDestination,
  getOrigin,
  getNumTravelers,
  getDestinationCategoriesLoading,
  getOriginCategoriesLoading,
  getOriginCategories,
  getDestinationCategories,
  getHasOriginAutocompleteError,
  getHasDestinationAutocompleteError,
} from "../../../../reducer";
import { LocationSearch } from "./component";

const mapDispatchToProps = {
  setOrigin: actions.setOrigin,
  setDestination: actions.setDestination,
  fetchOriginCategories: actions.fetchOriginCategories,
  fetchDestinationCategories: actions.fetchDestinationCategories,
  setOriginAutocompleteError: actions.setOriginAutocompleteError,
  setDestinationAutocompleteError: actions.setDestinationAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => {
  const origin = getOrigin(state);
  const destination = getDestination(state);
  const readyToContinue = origin && destination;
  return {
    origin,
    destination,
    numTravelers: getNumTravelers(state),
    originCategories: getOriginCategories(state),
    destinationCategories: getDestinationCategories(state),
    readyToContinue,
    loading:
      !!getOriginCategoriesLoading(state) ||
      !!getDestinationCategoriesLoading(state),
    hasOriginAutocompleteError: getHasOriginAutocompleteError(state),
    hasDestinationAutocompleteError: getHasDestinationAutocompleteError(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedLocationSearch = connector(LocationSearch);
