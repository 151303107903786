import React, { useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";
import { AddOnCustomize } from "../../../components/addOnComponents";
import { FlightShopStep } from "../../../reducer";
import { updateFlightShopStep } from "../../../utils/parseQueryString";
import { FlightCustomizeConnectorProps } from "./container";
import "./styles.scss";

export interface IFlightCustomizeProps
  extends FlightCustomizeConnectorProps,
    RouteComponentProps {
  isDisruptionProtectionEnabled: boolean;
}

export const FlightCustomize = (props: IFlightCustomizeProps) => {
  const {
    isAddOnOptionAvailable,
    tripCategory,
    setFlightShopProgress,
    isDisruptionProtectionEnabled,
    isMultiPageMarketplaceEnabled,
    history,
  } = props;

  const goToReviewItinerary = useCallback(() => {
    setFlightShopProgress(FlightShopStep.ReviewItinerary);
    updateFlightShopStep(FlightShopStep.ReviewItinerary, true, history);
  }, [history]);

  useEffect(() => {
    if (!isAddOnOptionAvailable) {
      goToReviewItinerary();
    }
  }, [isAddOnOptionAvailable, goToReviewItinerary]);

  return (
    <Box className="flight-shop-flight-customize-root">
      <Box className="flight-shop-flight-customize-container">
        <AddOnCustomize
          tripCategory={tripCategory}
          isDisruptionProtectionEnabled={isDisruptionProtectionEnabled}
          hideGoToCheckoutButton={isMultiPageMarketplaceEnabled}
          useMultiPageVariant={isMultiPageMarketplaceEnabled}
          goBackToPreviousPage={goToReviewItinerary}
        />
      </Box>
    </Box>
  );
};
