import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../reducers/types";
import { tripCategoryFromSelectedTripDetailsSelector } from "../../../../shop/reducer";
import {
  generateCustomPriceFreezeOffer,
  setSelectedPriceFreezeOfferDataIndex,
} from "../../../actions/actions";
import {
  selectedPriceFreezeOfferCapSelector,
  selectedAndValidPriceFreezeOfferDataSelector,
  priceFreezeMaxDurationSelector,
  priceFreezeMinOfferSelector,
  priceFreezeUserSelectedDurationSelectionMethodSelector,
  selectedAndValidPriceFreezeOfferDataIndexSelector,
  priceFreezeVoidWindowSelector,
  priceFreezeVoidWindowEndSelector,
  selectHasOfferDurationSelectionChanged,
  selectCustomPriceFreezeOfferIndex,
} from "../../../reducer";
import { MobilePriceFreezeDurationModal } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    tripCategory: tripCategoryFromSelectedTripDetailsSelector(state),
    selectedPriceFreezeOfferCap: selectedPriceFreezeOfferCapSelector(state),
    selectedPriceFreezeOfferData:
      selectedAndValidPriceFreezeOfferDataSelector(state),
    priceFreezeMaxDuration: priceFreezeMaxDurationSelector(state),
    priceFreezeUserSelectedDurationSelectionMethod:
      priceFreezeUserSelectedDurationSelectionMethodSelector(state),
    selectedPriceFreezeOfferDataIndex:
      selectedAndValidPriceFreezeOfferDataIndexSelector(state),
    priceFreezeVoidWindow: priceFreezeVoidWindowSelector(state),
    priceFreezeVoidWindowEnd: priceFreezeVoidWindowEndSelector(state),
    priceFreezeMinOffer: priceFreezeMinOfferSelector(state),
    /**
     * It will be `true` if the `customPriceFreezeOffer` duration is different from the `selectedAndValidPriceFreezeOffer` duration.
     */
    hasOfferDurationSelectionChanged:
      selectHasOfferDurationSelectionChanged(state),

    /**
     * This is the index of the `customPriceFreezeOffer` in the `priceFreezeOfferData` array based on the duration of the offer.
     */
    customPriceFreezeOfferIndex: selectCustomPriceFreezeOfferIndex(state),
  };
};

const mapDispatchToProps = {
  generateCustomPriceFreezeOffer,
  setSelectedPriceFreezeOfferDataIndex,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePriceFreezeDurationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePriceFreezeDurationModal = withRouter(
  connector(MobilePriceFreezeDurationModal)
);
