import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getChargeAgentBookingFeeCallState,
  getFinalizedItinerary,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { AgentBookingFeeModal } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  chargeAgentBookingFeeCallState: getChargeAgentBookingFeeCallState(state),
  itinerary: getFinalizedItinerary(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AgentBookingFeeModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAgentBookingFeeModal = withRouter(
  connector(AgentBookingFeeModal)
);
