import axios from "axios";
import { config } from "../../config";
import { scheduleFareQuoteApiPrefix } from "../paths";
import { ScheduleFareQuoteRequest } from "redmond";

export const scheduleFareQuote = (
  body: ScheduleFareQuoteRequest
): Promise<void> =>
  new Promise(async (resolve, reject) => {
    try {
      await axios.post(scheduleFareQuoteApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve();
    } catch (e) {
      reject(e);
    }
  });

export default scheduleFareQuote;
