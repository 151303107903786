import React from "react";
import {
  ButtonWrap,
  CloseButtonIcon,
  DesktopPopupModal,
} from "halifax";

import { Box } from "@material-ui/core";
import { IDesktop } from "../types";
import { BaggageSection } from "../components/BaggageSection";
import { RemindersSection } from "../components/RemindersSection";
import './styles.scss'

export const DesktopSelfTransferReminder = ({
  isOpen,
  onClose,
  slices,
  airports,
  setVariant,
  buttons,
  selectedTripLeg,
  setSelectedTripLeg,
  fareDetails,
}: IDesktop) => {
  return (
    <DesktopPopupModal
      className="self-transfer-reminder-modal-root"
      onClose={onClose}
      invisibleBackdrop={false}
      hideXButton
      open={isOpen}
    >
      <Box className={"close-button"}>
        <ButtonWrap aria-label="Close" onClick={onClose}>
          <CloseButtonIcon />
        </ButtonWrap>
      </Box>
      <Box className="popup-grid-container">
        <Box className="left-panel">
          <RemindersSection
            setVariant={setVariant}
            slices={slices}
            buttons={buttons}
          />
        </Box>
        <Box className="right-panel">
          <BaggageSection
            selectedTripLeg={selectedTripLeg}
            setSelectedTripLeg={setSelectedTripLeg}
            slices={slices}
            fareDetails={fareDetails}
            airports={airports}
          />
        </Box>
      </Box>
    </DesktopPopupModal>
  )
}