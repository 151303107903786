import * as React from "react";
import { useDeviceTypes } from "halifax";
import { PriceFreezeSelectedDurationModal } from "../../../../shop/components/priceFreezeComponents/PriceFreezeSelectedDurationModal";
import { MobileFlightFreezeWorkflowStep } from "../../MobileFlightFreezeWorkflow/component";
import { MobilePriceFreezeDurationModal } from "../MobilePriceFreezeDurationModal";

import {
  READ_TERMS_CONDITION_TEXT,
  PRICE_FREEZE_DETAILS_READ_TERMS_LABEL,
} from "../../../../shop/constants";

import { PATH_PRICE_FREEZE } from "../../../../../utils/urlPaths";
import { PriceFreezeDurationModalConnectorProps } from "./container";

export interface IPriceFreezeDurationModalProps
  extends PriceFreezeDurationModalConnectorProps {
  priceFreezeDurationModalOpen: boolean;
  setPriceFreezeDurationModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  /**
   * This is the price freeze mobile `purchaseStep` the customer is on.
   * On mobile, the checkout is not all on one page.
   */
  purchaseStep?: MobileFlightFreezeWorkflowStep;
  /**
   * Sets the price freeze mobile `purchaseStep`.
   * On mobile, the checkout is not all on one page.
   */
  setPurchaseStep?: React.Dispatch<
    React.SetStateAction<MobileFlightFreezeWorkflowStep>
  >;
}

export const PriceFreezeDurationModal = ({
  priceFreezeDurationModalOpen,
  setPriceFreezeDurationModalOpen,
  purchaseStep,
  setPurchaseStep,
  hasOfferDurationSelectionChanged,
  hasCustomerAppliedCreditToPurchase,
  setCreditToApply,
}: IPriceFreezeDurationModalProps) => {
  const { matchesMobile } = useDeviceTypes();

  const handleOnContinue = () => {
    /**
     * If a customer has applied credits and then goes back a step,
     * we would also want to remove the credits to allow the customer to update the credit amount they'd like to apply to the purchase
     */
    const shouldResetCredits =
      hasOfferDurationSelectionChanged && hasCustomerAppliedCreditToPurchase;
    /**
     * If a customer has applied a credit toward the purchase and updated the duration,
     * we will send them back to the `RewardsAndPayment` step to update the credit amount they'd like to apply to the purchase.
     */
    const shouldSetStepToRewardsAndPayment =
      purchaseStep &&
      setPurchaseStep &&
      purchaseStep === MobileFlightFreezeWorkflowStep.Review &&
      shouldResetCredits;

    if (shouldResetCredits) {
      setCreditToApply(undefined);
    }

    if (shouldSetStepToRewardsAndPayment) {
      setPurchaseStep(MobileFlightFreezeWorkflowStep.RewardsAndPayment);
    }
  };

  if (matchesMobile) {
    return (
      <MobilePriceFreezeDurationModal
        priceFreezeDurationModalOpen={priceFreezeDurationModalOpen}
        setPriceFreezeDurationModalOpen={setPriceFreezeDurationModalOpen}
        onContinue={handleOnContinue}
      />
    );
  }
  return (
    <PriceFreezeSelectedDurationModal
      priceFreezeSelectedDurationModalOpen={priceFreezeDurationModalOpen}
      setPriceFreezeSelectedDurationModalOpen={setPriceFreezeDurationModalOpen}
      termsProps={{
        content: READ_TERMS_CONDITION_TEXT,
        ariaLabelText: PRICE_FREEZE_DETAILS_READ_TERMS_LABEL,
        onClick: () => window.open(`${PATH_PRICE_FREEZE}`, "_blank"),
      }}
      disableGenerateOfferOnChange
    />
  );
};
