import React, { useEffect } from "react";
import { MultiSelectDropdown } from "halifax";

import * as textConstants from "./textConstants";
import { AirportFilterProps } from "./container";
import { generateFilterTrackingEvent } from "../../../../../../actions/actions";
import { trackEvent } from "../../../../../../../../api/v0/analytics/trackEvent";
interface IAirportFilterProps extends AirportFilterProps {
  showDropdownContentOnly?: boolean;
}

export const AirportFilterSelection = (props: IAirportFilterProps) => {
  const {
    airportFilter = [],
    setAirportFilter,
    allAirports,
    showDropdownContentOnly,
  } = props;

  useEffect(() => {
    if (airportFilter.length > 0)
      trackEvent(generateFilterTrackingEvent("airport", "list"));
  }, [airportFilter]);

  return (
    <MultiSelectDropdown
      currentValue={airportFilter}
      dropdownLabel={textConstants.AIRPORTS}
      options={allAirports}
      setValue={setAirportFilter}
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
