import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { fetchTripPricing } from "../../../../api/v0/book/book-flow/tripPricing";
import {
  TripPricingSummaryResponse,
  TripPricingSummarySuccess,
  Failure,
} from "@b2bportal/air-booking-api";
import { selectedTripSelector } from "../../../shop/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { pricingParamsSelector } from "../../reducer";

export function* tripPricingSaga(_: actions.IValidatePassengers) {
  try {
    const state: IStoreState = yield select();
    const selectedTrip = selectedTripSelector(state);
    const params = pricingParamsSelector(state);

    if (!selectedTrip || !params) {
      yield put(actions.setFetchTripPricingCallStateFailure([]));
    }
    const response: TripPricingSummaryResponse = yield fetchTripPricing(params);

    const pricingSummary = (response as TripPricingSummarySuccess)
      .value;
    if (!!pricingSummary) {
      yield put(actions.setTripPricing(pricingSummary));
      yield put(actions.setFetchTripPricingCallStateSuccess());
    } else {
      yield put(
        actions.setFetchTripPricingCallStateFailure(
          (response as Failure).errors
        )
      );
    }
  } catch (e) {
    yield put(actions.setFetchTripPricingCallStateFailure([]));
  }
}
