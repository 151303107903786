import { ITripTerminus } from "redmond";
import { getCityNameAndAirportCodeFromTerminus } from "../../../../utils/terminus";
import { MulticityRoute } from "../../../search/reducer";

export const CHOOSE_DEPARTURE_TEXT = (searchedRoute: MulticityRoute) => {
  const { origin, destination } = searchedRoute;
  const [originCityName] = getCityNameAndAirportCodeFromTerminus(
    origin as ITripTerminus
  );
  const [destinationCityName] = getCityNameAndAirportCodeFromTerminus(
    destination as ITripTerminus
  );
  return `${originCityName} to ${destinationCityName}`;
};
export const REVIEW_ITINERARY_TEXT = "Review itinerary";
export const BOOK_TEXT = "Book";
export const CUSTOMIZE = "Customize";
