import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import {
  OffersForProductsRequest,
  WalletOffersResponse,
  AvailableProductEnum,
  TripType,
  RegionType,
  WalletSummaryResponse,
} from "redmond";
import {
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { fetchTravelWalletOffers } from "../../../../api/v0/travel-wallet/fetchTravelWalletOffers";
import { getSliceIndex } from "../../../../utils/flights";
import dayjs from "dayjs";
import { fetchTravelWalletSummary } from "../../../../api/v0/travel-wallet/fetchTravelWalletSummary";

export function* fetchApplicableTravelWalletItemsSaga() {
  try {
    const state: IStoreState = yield select();
    const selectedTrip = selectedTripSelector(state);
    const tripId = selectedTrip.tripId || "";
    const tripDetails = tripDetailsSelector(state, tripId);

    const departureSlice = tripDetails.slices[0];
    const returnSlice = tripDetails.slices[tripDetails.slices.length - 1];

    const hasReturnFlight = getSliceIndex(false, tripDetails) !== -1;

    const departureSegments = departureSlice.segmentDetails;

    const departureTime = departureSlice.departureTime;
    const returnTime = returnSlice.arrivalTime;

    const marketingCarriers = tripDetails.slices
      .flatMap((slice) => slice.segmentDetails)
      .map((segment) => segment.marketingAirline.code);
    const operatingCarriers = tripDetails.slices
      .flatMap((slice) => slice.segmentDetails)
      .map((segment) => segment.operatingAirline.code);

    const products: OffersForProductsRequest = {
      products: [
        {
          AvailableProduct: AvailableProductEnum.Air,
          advance: dayjs(departureTime).diff(dayjs(), "days"),
          canBeDiscounted: true,
          currency: "USD",
          departureDate: dayjs(departureTime).format("YYYY-MM-DD"),
          includesSaturdayNightStay: false,
          lengthOfStay: dayjs(returnTime).diff(departureTime, "days"),
          marketingCarriers,
          operatingCarriers,
          returnDate: hasReturnFlight
            ? dayjs(returnTime).format("YYYY-MM-DD")
            : undefined,
          route: {
            destination: {
              code: departureSegments[departureSegments.length - 1]
                .destinationCode,
              regionType: RegionType.Airport,
            },
            origin: {
              code: departureSegments[0].originCode,
              regionType: RegionType.Airport,
            },
          },
          shelfRatings: tripDetails.fareDetails
            .flatMap((fare) => fare.slices)
            .map((slice) => slice.fareShelf?.rating ?? 0),
          totalStops: tripDetails.slices.reduce(
            (total, current) => total + current.stops,
            0
          ),
          tripId,
          tripType: hasReturnFlight ? TripType.RoundTrip : TripType.OneWay,
          validatingCarriers: tripDetails.validatingCarrierCode
            ? [tripDetails.validatingCarrierCode]
            : [],
        },
      ],
    };

    const response: WalletOffersResponse = yield fetchTravelWalletOffers(
      products
    );

    const summaryResponse: WalletSummaryResponse =
      yield fetchTravelWalletSummary();

    yield put(actions.setTravelWalletOffers(response.rankedApplicableOffers));
    yield put(actions.setBestOfferOverall(response.rankedApplicableOffers[0]));
    yield put(actions.setTravelWalletCredit(summaryResponse.credit));
    yield put(actions.setFetchApplicableTravelWalletItemsCallStateSuccess());
  } catch (e) {
    yield put(actions.setFetchApplicableTravelWalletItemsCallStateFailure());
  }
}
