import axios from "axios";
import {
  IDepartureCalendarReport,
  IDepartureCalendarResponseBody,
  TripFilter,
} from "redmond";
import { airShoppingApi, CalendarBuckets } from "@b2bportal/air-shopping-api";

export const fetchCalendar = (
  body: CalendarBuckets
): Promise<IDepartureCalendarReport> =>
  new Promise(async (resolve, reject) => {
    try {
      // TODO set up with BE endpoints
      const res = await airShoppingApi(axios as any).apiV0CalendarPost(body);

      const response: IDepartureCalendarResponseBody = res.data as any;
      const report: IDepartureCalendarReport = response.departureDateReport;
      resolve(report);
    } catch (e) {
      const response: IDepartureCalendarResponseBody = {
        departureDateReport: {
          currency: "$(USD)",
          isReliable: false,
          filtersSummary: {
            summaries: [
              {
                filter: {
                  TripFilter: TripFilter.NON_STOP_FILTER,
                },
                summaryCopy: "We haven't seen any flights with these filters",
                summaryState: "Warning",
              },
              {
                filter: {
                  TripFilter: TripFilter.NO_LOW_COST_CARRIER_FILTER,
                },
                summaryCopy: "Flights are about the same price without filters",
                summaryState: "Normal",
              },
              {
                filter: {
                  TripFilter: TripFilter.SHORT_LAYOVER_FILTER,
                },
                summaryCopy: "Flights are about the same price without filters",
                summaryState: "Normal",
              },
              {
                filter: {
                  filters: [
                    {
                      TripFilter: TripFilter.NON_STOP_FILTER,
                    },
                    {
                      TripFilter: TripFilter.NO_LOW_COST_CARRIER_FILTER,
                    },
                  ],
                  TripFilter: TripFilter.AND_FILTER,
                },
                summaryCopy: "We haven't seen any flights with these filters",
                summaryState: "Warning",
              },
              {
                filter: {
                  filters: [
                    {
                      TripFilter: TripFilter.SHORT_LAYOVER_FILTER,
                    },
                    {
                      TripFilter: TripFilter.NO_LOW_COST_CARRIER_FILTER,
                    },
                  ],
                  TripFilter: TripFilter.AND_FILTER,
                },
                summaryCopy: "Flights are about the same price without filters",
                summaryState: "Normal",
              },
            ],
          },
          departureDateBuckets: [
            {
              legend: "$625+",
              dates: [
                "2021-01-09",
                "2021-01-11",
                "2021-01-12",
                "2021-01-15",
                "2021-01-17",
                "2021-01-18",
                "2021-01-19",
                "2021-01-22",
                "2021-01-23",
                "2021-01-24",
                "2021-01-25",
                "2021-01-26",
                "2021-01-29",
                "2021-02-02",
                "2021-02-03",
                "2021-02-07",
                "2021-02-08",
                "2021-02-09",
                "2021-02-10",
                "2021-02-11",
                "2021-02-12",
                "2021-02-22",
                "2021-02-28",
                "2021-03-01",
                "2021-03-04",
                "2021-03-05",
                "2021-03-06",
                "2021-03-07",
                "2021-03-08",
                "2021-03-09",
                "2021-03-17",
                "2021-03-18",
                "2021-03-20",
                "2021-03-21",
                "2021-03-22",
                "2021-04-06",
                "2021-04-15",
                "2021-04-20",
                "2021-04-23",
                "2021-04-24",
                "2021-04-27",
                "2021-05-04",
                "2021-05-06",
                "2021-05-10",
                "2021-05-18",
                "2021-07-31",
                "2021-08-01",
                "2021-08-02",
                "2021-08-11",
                "2021-08-14",
                "2021-08-25",
                "2021-09-01",
              ],
            },
            {
              legend: "$675+",
              dates: [
                "2021-01-04",
                "2021-01-05",
                "2021-01-06",
                "2021-01-07",
                "2021-01-08",
                "2021-01-10",
                "2021-01-13",
                "2021-01-14",
                "2021-01-16",
                "2021-01-20",
                "2021-01-21",
                "2021-01-27",
                "2021-01-28",
                "2021-01-30",
                "2021-01-31",
                "2021-02-01",
                "2021-02-13",
                "2021-02-14",
                "2021-02-15",
                "2021-02-16",
                "2021-02-24",
                "2021-02-26",
                "2021-03-13",
                "2021-03-14",
                "2021-03-19",
                "2021-03-30",
                "2021-04-01",
                "2021-04-02",
                "2021-04-05",
                "2021-04-07",
                "2021-04-12",
                "2021-04-13",
                "2021-04-14",
                "2021-04-19",
                "2021-04-21",
                "2021-04-26",
                "2021-04-28",
                "2021-05-03",
                "2021-05-05",
                "2021-05-09",
                "2021-05-11",
                "2021-05-17",
                "2021-06-02",
                "2021-06-27",
                "2021-07-04",
                "2021-07-18",
                "2021-09-08",
              ],
            },
            {
              legend: "$725+",
              dates: [
                "2021-02-17",
                "2021-02-18",
                "2021-02-23",
                "2021-03-02",
                "2021-03-11",
                "2021-03-15",
                "2021-03-16",
                "2021-03-23",
                "2021-03-29",
                "2021-03-31",
                "2021-04-17",
                "2021-05-08",
                "2021-05-16",
                "2021-05-19",
                "2021-06-26",
                "2021-07-03",
                "2021-07-08",
                "2021-07-23",
                "2021-07-24",
                "2021-08-05",
                "2021-08-06",
                "2021-08-07",
              ],
            },
            {
              legend: "$775+",
              dates: [
                "2021-02-04",
                "2021-02-05",
                "2021-02-06",
                "2021-02-19",
                "2021-02-20",
                "2021-02-21",
                "2021-02-25",
                "2021-02-27",
                "2021-03-03",
                "2021-03-10",
                "2021-03-12",
                "2021-03-24",
                "2021-03-25",
                "2021-03-26",
                "2021-03-27",
                "2021-03-28",
                "2021-04-03",
                "2021-04-04",
                "2021-04-08",
                "2021-04-09",
                "2021-04-10",
                "2021-04-11",
                "2021-04-16",
                "2021-04-18",
                "2021-04-22",
                "2021-04-25",
                "2021-04-29",
                "2021-04-30",
                "2021-05-01",
                "2021-05-02",
                "2021-05-07",
                "2021-05-12",
                "2021-05-13",
                "2021-05-14",
                "2021-05-15",
                "2021-05-20",
                "2021-05-21",
                "2021-05-22",
                "2021-05-23",
                "2021-05-24",
                "2021-05-25",
                "2021-05-26",
                "2021-05-27",
                "2021-05-28",
                "2021-05-29",
                "2021-05-30",
                "2021-05-31",
                "2021-06-01",
                "2021-06-03",
                "2021-06-04",
                "2021-06-05",
                "2021-06-06",
                "2021-06-07",
                "2021-06-08",
                "2021-06-09",
                "2021-06-10",
                "2021-06-11",
                "2021-06-12",
                "2021-06-13",
                "2021-06-14",
                "2021-06-15",
                "2021-06-16",
                "2021-06-17",
                "2021-06-18",
                "2021-06-19",
                "2021-06-20",
                "2021-06-21",
                "2021-06-22",
                "2021-06-23",
                "2021-06-24",
                "2021-06-25",
                "2021-06-28",
                "2021-06-29",
                "2021-06-30",
                "2021-07-01",
                "2021-07-02",
                "2021-07-05",
                "2021-07-06",
                "2021-07-07",
                "2021-07-09",
                "2021-07-10",
                "2021-07-11",
                "2021-07-12",
                "2021-07-13",
                "2021-07-14",
                "2021-07-15",
                "2021-07-16",
                "2021-07-17",
                "2021-07-19",
                "2021-07-20",
                "2021-07-21",
                "2021-07-22",
                "2021-07-25",
                "2021-07-26",
                "2021-07-27",
                "2021-07-28",
                "2021-07-29",
                "2021-07-30",
                "2021-08-03",
                "2021-08-04",
                "2021-08-08",
                "2021-08-09",
                "2021-08-10",
                "2021-08-12",
                "2021-08-13",
                "2021-08-15",
                "2021-08-16",
                "2021-08-17",
                "2021-08-18",
                "2021-08-19",
                "2021-08-20",
                "2021-08-21",
                "2021-08-22",
                "2021-08-23",
                "2021-08-24",
                "2021-08-26",
                "2021-08-27",
                "2021-08-28",
                "2021-08-29",
                "2021-08-30",
                "2021-08-31",
                "2021-09-02",
                "2021-09-03",
                "2021-09-04",
                "2021-09-05",
                "2021-09-06",
                "2021-09-07",
                "2021-09-09",
                "2021-09-10",
                "2021-09-11",
                "2021-09-12",
                "2021-09-13",
                "2021-09-14",
                "2021-09-15",
                "2021-09-16",
                "2021-09-17",
                "2021-09-18",
                "2021-09-22",
                "2021-09-23",
                "2021-09-25",
                "2021-09-28",
                "2021-09-29",
                "2021-10-01",
                "2021-10-02",
                "2021-10-03",
                "2021-10-04",
                "2021-10-06",
                "2021-10-07",
                "2021-10-11",
                "2021-10-12",
                "2021-10-16",
                "2021-10-19",
                "2021-10-28",
                "2021-10-29",
                "2021-10-30",
                "2021-10-31",
                "2021-11-01",
                "2021-11-02",
                "2021-11-03",
                "2021-11-04",
                "2021-11-18",
              ],
            },
          ],
        },
      };
      const report: IDepartureCalendarReport = response.departureDateReport;
      resolve(report);
      reject(e);
    }
  });
