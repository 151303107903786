import axios from "axios";
import { config } from "../../config";
import { showFintechEligibilityApiPrefix } from "../paths";

export interface FetchCorpFintechEligibilityResponse {
  isEligibleToOptin: boolean;
}

export const fetchCorpFintechEligibility = (body: {
  businessId: string;
}): Promise<FetchCorpFintechEligibilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(showFintechEligibilityApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchCorpFintechEligibility;
