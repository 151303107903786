import {
  TripCategory,
  LocationQueryEnum,
  ITripTerminus,
  ILocationQueryLabel,
  IResponse,
  Platform,
  RebookSummaryRequest,
  RebookSummaryResponse,
  RebookSummaryResponseEnum,
  RebookSummarySuccess,
  FlightShopStep,
} from "redmond";
import { putResolve, call, select } from "redux-saga/effects";
import { fetchLocationAutocomplete } from "../../../../api/v0/search/fetchLocationAutocomplete";
import { fetchRebookSummary } from "../../../../api/v1/disruptions/fetchRebookSummary";
import { IStoreState } from "../../../../reducers/types";
import { populateFlightShopQueryParametersFromState } from "../../../shop/sagas/populateShopQueryParamsSaga";
import { ITripTerminusCategory } from "../../../search/types";
import { actions as searchActions } from "../../../search/actions";
import { DateToggleFilter } from "../../reducer";
import {
  IFetchRebookSummary,
  setRebookSummaryCallStateFailed,
  setRebookSummaryResponse,
  setTodayTomorrowDateFilter,
} from "../../actions/actions";
import Logger from "../../../../helpers/Logger";

export function* fetchRebookSummarySaga(action: IFetchRebookSummary) {
  try {
    const state: IStoreState = yield select();
    const { origin, destination, itineraryId, history, isMobile } = action;

    yield populateFlightShopQueryParametersFromState({
      state,
      history,
      newQueryParams: {
        flightShopProgress: FlightShopStep.ChooseDeparture,
      },
    });

    const { originTripTerminus, destinationTripTerminus } = yield call(
      setUpFlightShopParams,
      { origin, destination }
    );

    if (!originTripTerminus || !destinationTripTerminus) {
      yield putResolve(setRebookSummaryCallStateFailed());
      return;
    }

    const requestBody: RebookSummaryRequest = {
      route: {
        origin: { ...originTripTerminus.id.code },
        destination: { ...destinationTripTerminus.id.code },
      },
      itineraryId,
      platform: isMobile ? Platform.Mobile : Platform.Desktop,
    };
    let response: RebookSummaryResponse = yield fetchRebookSummary(requestBody);

    if (
      (response as RebookSummarySuccess)?.RebookSummaryResponse !==
      RebookSummaryResponseEnum.RebookSummarySuccess
    ) {
      yield putResolve(setRebookSummaryCallStateFailed());
      return;
    }

    response = response as RebookSummarySuccess;

    yield putResolve(
      setRebookSummaryResponse({
        rebookSummary: response,
      })
    );

    // note: when no flights departing from the same day are available, it falls back to flights from tomorrow
    if (response.sameDayFlights.outbound.length === 0) {
      yield putResolve(
        setTodayTomorrowDateFilter({ dateFilter: DateToggleFilter.TOMORROW })
      );
    }
  } catch (e) {
    yield putResolve(setRebookSummaryCallStateFailed());
    Logger.debug(e);
  }
}

function* setUpFlightShopParams({
  origin,
  destination,
}: {
  origin: string;
  destination: string;
}) {
  const originTripTerminus: ITripTerminus = yield fetchLocation(origin);
  const destinationTripTerminus: ITripTerminus = yield fetchLocation(
    destination
  );

  yield putResolve(searchActions.setOrigin(originTripTerminus));
  yield putResolve(searchActions.setDestination(destinationTripTerminus));
  yield putResolve(searchActions.setTripCategory(TripCategory.ONE_WAY));

  return {
    originTripTerminus,
    destinationTripTerminus,
  };
}

function* fetchLocation(location: string) {
  const locationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: location,
  };

  const { categories: locationCategories }: IResponse =
    yield fetchLocationAutocomplete(locationRequestBody);
  const correspondingLocation = locationCategories
    .flatMap((category) => (category as ITripTerminusCategory).results)
    .find((result) => result.id.code.code === location) as ITripTerminus;

  return correspondingLocation;
}
