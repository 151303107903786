import axios, { AxiosResponse } from "axios";
import { EarnRequest, EarnResponse } from "redmond";

import { config } from "../../config";

import { rewardsApiPrefix } from "../paths";

const END_POINT: string = `${rewardsApiPrefix}/convert/productToEarn`;

export const productToEarn = (request: EarnRequest): Promise<EarnResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<EarnResponse> = await axios.put(END_POINT, request, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
