import React from "react";
import { RouteComponentProps } from "react-router";
import { PricingBreakdown } from "halifax";

import { FlightFreezePriceBreakdownConnectorProps } from "./container";

export interface IFlightFreezePriceBreakdownProps
  extends FlightFreezePriceBreakdownConnectorProps,
    RouteComponentProps {}

export const FlightFreezePriceBreakdown = (
  props: IFlightFreezePriceBreakdownProps
) => {
  const { currency, summaryLines, isVITripSelected } = props;

  return (
    <PricingBreakdown
      className="flight-freeze-price-breakdown-root"
      summaryItems={summaryLines}
      currencySymbol={currency.currencySymbol}
      currencyCode={currency.currencyCode}
      isVITripSelected={isVITripSelected}
    />
  );
};
