import axios, { AxiosResponse } from "axios";
import { ActiveExperimentsResponse } from "redmond";

import { experimentsApiPrefix } from "../paths";

import { config } from "../../config";

const END_POINT: string = `${experimentsApiPrefix}/active`;

export const fetchActiveExperiments = (): Promise<ActiveExperimentsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<ActiveExperimentsResponse> = await axios.put(END_POINT, {}, { baseURL: config.baseURL });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
