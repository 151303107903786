import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getTripCategory,
  getOrigin,
  getDestination,
  getDepartureDate,
  getReturnDate,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  getDepartureDateBucketsLoading,
  getMulticityRoutes,
} from "../../../search/reducer/selectors";
import {
  setDestination,
  setOrigin,
  setPassengerCounts,
  setTripCategory,
  fetchDepartureCalendar,
  setStopsOption,
  setAirlineFilter,
  setFareclassOptionFilter,
  setMaxPriceFilter,
  setAirportFilter,
  setFlightNumberFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setCalendar,
  setMulticityOrigin,
  setMulticityDestination,
  setAllMulticityRoutes,
  setMulticityDepartureDate,
  setDurationFilter,
} from "../../../search/actions/actions";
import { MobileEditLocationModal } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  IFlightShopOverwriteQueryParams,
  populateFlightShopQueryParams,
  resetSelectedTrip,
  setChosenOutgoingSlice,
  setFlightShopProgress,
  setMulticityFlightShopProgress,
} from "../../actions/actions";
import { PassengerCountPickerType } from "halifax";
import { initialFilterOptions, MulticityRoute } from "../../../search/reducer";
import { Dispatch } from "@reduxjs/toolkit";
import { FlightShopStep, ITripTerminus, TripCategory } from "redmond";
import { MulticityFlightShopStep } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const passengersCount: PassengerCountPickerType = {
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
  };
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
    tripCategory: getTripCategory(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    multicityRoutes: getMulticityRoutes(state),
    passengersCount,
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    infantsInSeatCount: getInfantsInSeatCount(state),
    infantsOnLapCount: getInfantsOnLapCount(state),
    departureDateBucketsLoading: getDepartureDateBucketsLoading(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchDepartureCalendar: (origin: ITripTerminus, destination: ITripTerminus) =>
    dispatch(fetchDepartureCalendar(origin, destination)),
  setCalendar: (report?: any) => dispatch(setCalendar(report)),
  setDestination: (destination: ITripTerminus | null) =>
    dispatch(setDestination(destination)),
  setOrigin: (origin: ITripTerminus | null) => dispatch(setOrigin(origin)),
  setTripCategory: (tripCategory: TripCategory) =>
    dispatch(setTripCategory(tripCategory)),
  resetAll: (resetFilters: boolean) => {
    if (resetFilters) {
      dispatch(setStopsOption(initialFilterOptions.stopsOption));
      dispatch(setAirlineFilter(initialFilterOptions.airlineFilter));
      dispatch(
        setFareclassOptionFilter({
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        })
      );
      dispatch(setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
      dispatch(setAirportFilter(initialFilterOptions.airportFilter));
      dispatch(setFlightNumberFilter(initialFilterOptions.flightNumberFilter));
      dispatch(
        setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        setReturnDepartureTimeRange(
          initialFilterOptions.returnDepartureTimeRange
        )
      );
      dispatch(
        setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange)
      );
      dispatch(setDurationFilter(initialFilterOptions.durationFilter));
    }
    dispatch(
      setChosenOutgoingSlice({
        tripId: "",
        outgoingSliceId: "",
        outgoingFareId: "",
        outgoingFareRating: undefined,
        resetReturnIds: true,
      })
    );
    dispatch(resetSelectedTrip());
  },
  resetFlightShopProgress: () =>
    setFlightShopProgress(FlightShopStep.ChooseDeparture),
  resetMulticityFlightShopProgress: () =>
    setMulticityFlightShopProgress(MulticityFlightShopStep.ChooseDeparture0),
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => dispatch(populateFlightShopQueryParams(args)),
  setPassengerCounts: (counts: PassengerCountPickerType) =>
    dispatch(setPassengerCounts(counts)),
  setMulticityOrigin: (origin: ITripTerminus | null, routeIndex: number) =>
    dispatch(setMulticityOrigin(origin, routeIndex)),
  setMulticityDestination: (
    destination: ITripTerminus | null,
    routeIndex: number
  ) => dispatch(setMulticityDestination(destination, routeIndex)),
  setMulticityDepartureDate: (departureDate: Date | null, routeIndex: number) =>
    dispatch(setMulticityDepartureDate(departureDate, routeIndex)),
  setAllMulticityRoutes: (multicityRoutes: MulticityRoute[]) =>
    dispatch(setAllMulticityRoutes(multicityRoutes)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileEditLocationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileEditLocationModal = connector(
  withRouter(MobileEditLocationModal)
);
