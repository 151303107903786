import {
  AirlineOpenEnum,
  FlightFareSlice,
  FlightRatingsEnum,
  UserFlightPreferencesPayload,
} from "redmond";

export const isFlightRecommendedBasedOnPreferences = (
  fareSlice: FlightFareSlice,
  flightPreferences: UserFlightPreferencesPayload | undefined,
  airlineCode: AirlineOpenEnum
) => {
  if (!flightPreferences) return false;

  const hasFareClassPreference = Object.values(
    flightPreferences.fareClasses
  ).some((applied) => applied);

  const hasAirlinePreference = flightPreferences.airlines.length > 0;

  const hasStopsPreference = flightPreferences.nonStopOnly;

  if (!hasFareClassPreference && !hasAirlinePreference && !hasStopsPreference) {
    return false;
  }

  const fareClassMatches = hasFareClassPreference
    ? flightPreferences.fareClasses[
        FlightRatingsEnum[fareSlice.fareShelf.value]
      ]
    : true;

  const airlineMatches = hasAirlinePreference
    ? flightPreferences.airlines.includes(airlineCode)
    : true;

  const stopsMatch = hasStopsPreference ? fareSlice.segments.length <= 1 : true;

  return fareClassMatches && airlineMatches && stopsMatch;
};
