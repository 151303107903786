import { connect, ConnectedProps } from "react-redux";

import { FlightBookMobileButton } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getUserSelectedPassengerIds } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedTravelersId: getUserSelectedPassengerIds(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightBookMobileButtonConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightBookMobileButton = connector(
  FlightBookMobileButton
);
