import { CallState, WatchAlertView } from "redmond";
import { put } from "redux-saga/effects";

import { listWatches } from "../../../api/v0/price-watch/listWatches";
import Logger from "../../../helpers/Logger";
import { actions } from "../actions";

export function* fetchWatchesSaga() {
  try {
    const watches: WatchAlertView[] = yield listWatches();
    yield put(actions.setListWatchesCallState(CallState.Success));
    yield put(actions.setWatches(watches));
  } catch (e) {
    yield put(actions.setListWatchesCallState(CallState.Failed));
    Logger.debug(e);
  }
}
