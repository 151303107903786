import {
  ADDED_WATCH,
  CallState,
  Dealness,
  Prediction,
  FlightKey,
  ShopFilter,
  SliceStopCountFilter,
  AddedWatchProperties,
  PushNotificationFilter,
  getWatchFilterTypeFromShopFilter,
  getPriceAlertPreferenceProperties,
  CreateWatchAlertResponse,
  CreateWatchAlertResponseEnum,
} from "redmond";
import { call, put, select } from "redux-saga/effects";
import {
  CreateWatchAlertRequest,
  MessageMethodEnum,
} from "@b2bportal/air-price-watch-api";

import { createWatch } from "../../../api/v0/price-watch/createWatch";
import { fetchTripSummaries as fetchFlights } from "../../../api/v3/shop/fetchTripSummaries";
import Logger from "../../../helpers/Logger";
import { passengerCountSelector } from "../../search/reducer";
import { alertKeySelector, predictionSelector } from "../reducer/selectors";
import { getExistingStateVariables } from "./populateShopQueryParamsSaga";
import { IShopParams } from "../actions/actions";
import { actions } from "../actions";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";

export function* createWatchSaga({
  email,
  shopFilter,
  pushNotificationFilter,
  isMobile,
}: actions.ICreateWatch) {
  var createWatchResponse: CreateWatchAlertResponse | undefined = undefined;

  try {
    const alertKey: FlightKey | null = yield select(alertKeySelector);

    if (!alertKey) {
      yield put(actions.setCreateWatchCallState(CallState.Failed));
      return;
    }

    if (shopFilter) {
      alertKey.value.filter = shopFilter;
    }

    const request: CreateWatchAlertRequest = {
      key: alertKey,
      method: (() => {
        switch (pushNotificationFilter) {
          case PushNotificationFilter.AppNotifications:
            return {
              push: {
                MessageMethod: MessageMethodEnum.Push,
              },
              email: {
                email,
                MessageMethod: MessageMethodEnum.Email,
              },
              MessageMethod: MessageMethodEnum.MultiMethod,
            };
          case PushNotificationFilter.EmailOnly:
          default:
            return {
              email,
              MessageMethod: MessageMethodEnum.Email,
            };
        }
      })(),
      passengers: yield select(passengerCountSelector),
    };

    /*
      note: the `/api/v0/alert/watch/create` endpoint isn't stateless, its response is dependent on whether the
      `/api/v3/shopSummary` endpoint has been called (with the same filter option) or not. Calling `/shopSummary` here
      to ensure that `/watch/create` doesn't return the NoRecentPrice error.
    */
    const {
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    } = yield select(getExistingStateVariables);
    const requestBody: IShopParams = {
      origin: alertKey.value.origin,
      destination: alertKey.value.destination,
      ...(alertKey.value.returnDate
        ? {
            returnDate: alertKey.value.returnDate,
          }
        : undefined),
      departureDate: alertKey.value.departureDate,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption:
        shopFilter !== ShopFilter.NonStop &&
        shopFilter !== ShopFilter.NonStopNoLCC
          ? SliceStopCountFilter.DEFAULT
          : SliceStopCountFilter.NONE,
      fareclassOptionFilter: {
        basic:
          shopFilter !== ShopFilter.NoLCC &&
          shopFilter !== ShopFilter.NonStopNoLCC,
        standard: true,
        enhanced: true,
        premium: true,
        luxury: true,
      },
    };
    yield fetchFlights(requestBody, isMobile);

    // `createWatch` can return a 500 response with a response body.
    // We make `createWatch` resolve the promise successfully (even in the case of 500), so that we can save the response body
    // in `createWatchResponse`. After that, we throw an error to ensure the case is treated as a failure as expected.
    createWatchResponse = yield call(createWatch, request);
    if (
      createWatchResponse?.Response === CreateWatchAlertResponseEnum.Failure
    ) {
      throw new Error();
    }

    yield put(actions.listWatches());
    yield put(actions.setCreateWatchCallState(CallState.Success));
    const prediction: Prediction | null = yield select(predictionSelector);
    trackEvent({
      eventName: ADDED_WATCH,
      properties: {
        recommendation: prediction?.dealness
          ? prediction.dealness === Dealness.Wait
            ? "wait"
            : "buy"
          : null,
        filter_type: getWatchFilterTypeFromShopFilter(alertKey.value.filter),
        ...getPriceAlertPreferenceProperties(pushNotificationFilter),
      } as AddedWatchProperties,
    });
  } catch (e) {
    if (
      createWatchResponse &&
      createWatchResponse.Response === CreateWatchAlertResponseEnum.Failure
    ) {
      yield put(
        actions.setCreateWatchCallState(
          CallState.Failed,
          createWatchResponse.errors
        )
      );
    } else {
      yield put(actions.setCreateWatchCallState(CallState.Failed));
    }
    Logger.debug(e);
  }
}
