import { Box, Typography } from "@material-ui/core";
import {
  AccordionCollection,
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
} from "halifax";
import * as React from "react";

import clsx from "clsx";
import {
  AirlineCode,
  CallState,
  IFlightNumberFilter,
  ITimeRange,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import { IShopFilterSelector } from "../../../../../../../shop/reducer";
import { APPLY_FILTERS_TEXT } from "../../../../../FlightSearchFilter/textConstants";
import {
  AirlineFilterSelection,
  AirportFilterSelection,
  DepartureArrivalSelectionDropdown,
  FlightNumberFilterSelection,
  MaxPriceFilterSelection,
  StopsOptionSelection,
} from "../index";
import * as constants from "./constants";
import "./styles.scss";

export interface IMobileFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
  hideMaxPriceFilter?: boolean;
  hideArrivalFilter?: boolean;
  flightShopFilters: IShopFilterSelector;
  predictionLoading?: boolean | null;
  stopsOption: SliceStopCountFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => void;
  setRerunPrediction: () => void;
  hasSetStopsOption: boolean;
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  hasSetAirlineFilter: boolean;
  airportFilter: string[];
  setAirportFilter: (airportFilter: string[]) => void;
  maxPriceFilter: number;
  hasSetMaxPrice: boolean;
  setMaxPriceFilter: (maxPriceFilter: number) => void;
  flightNumberFilter: IFlightNumberFilter[];
  departureDateString: string;
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
  tripCategory: TripCategory;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  hasSetTimeRange: boolean;
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ) => void;
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) => void;
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) => void;
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) => void;
  flightNumberAirlineFilter: string;
  setFlightNumberAirlineFilter: (airline: string) => void;
  isCustomerProfileExperiment?: boolean;
  userHasSetFlightPreferences?: boolean;
  userFlightPreferencesCallState?: CallState;
  shouldApplyUserFlightPreferences: boolean;
  setApplyUserFlightPreferences: (applyPreferences: boolean) => void;
}
export const MobileFlightShopSearchFilter = (
  props: IMobileFlightShopSearchFilterProps
) => {
  const [openSearchFilter, setOpenSearchFilter] =
    React.useState<boolean>(false);

  const handleCloseFilter = () => {
    setOpenSearchFilter(false);
  };

  const {
    hideAirportFilter,
    hideMaxPriceFilter,
    hideArrivalFilter,
    flightShopFilters,
    predictionLoading,
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    hasSetStopsOption,
    airlineFilter,
    setAirlineFilter,
    hasSetAirlineFilter,
    airportFilter,
    setAirportFilter,
    maxPriceFilter,
    setMaxPriceFilter,
    hasSetMaxPrice,
    flightNumberFilter,
    departureDateString,
    setFlightNumberFilter,
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    hasSetTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    flightNumberAirlineFilter,
    setFlightNumberAirlineFilter,
  } = props;
  const {
    priceMax,
    priceMin,
    airlineOptions,
    airportOptions,
    flightNumbersByAirline,
  } = flightShopFilters;
  React.useEffect(() => {
    if (predictionLoading && openSearchFilter) {
      setOpenSearchFilter(false);
    }
  }, [predictionLoading]);

  return (
    <Box className="mobile-flight-shop-search-filter-root">
      <Box className="mobile-flight-shop-search-filter-container">
        <ActionLink
          className="filter-open-button"
          content={
            <Typography variant="body1">
              {constants.FILTERS_BUTTON_TEXT}
            </Typography>
          }
          onClick={() => setOpenSearchFilter(true)}
        />
        <MobilePopoverCard
          open={openSearchFilter}
          onClose={handleCloseFilter}
          fullScreen={true}
          className={"mobile-flight-shop-search-filter"}
          contentClassName={"mobile-flight-shop-search-filter-wrapper"}
          bottomButton={
            <ActionButton
              className={clsx("mobile-flight-shop-filter-button")}
              onClick={() => {
                handleCloseFilter();
              }}
              message={APPLY_FILTERS_TEXT}
            />
          }
          topRightButton={
            <ActionLink
              className="filter-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={handleCloseFilter}
            />
          }
        >
          <Box className="mobile-flight-shop-search-filter-content-container">
            <AccordionCollection
              className="flight-shop-search-filter-accordions-container"
              accordionContents={[
                // StopsOption
                {
                  title: (
                    <Box className="header-container">
                      {constants.STOPS_HEADER_TEXT}
                    </Box>
                  ),
                  body: (
                    <Box className="mobile-filter-option-selection">
                      <StopsOptionSelection
                        stopsOption={stopsOption}
                        setStopsOption={setStopsOption}
                        setRerunPrediction={setRerunPrediction}
                        hasSetStopsOption={hasSetStopsOption}
                        showDropdownContentOnly
                      />
                    </Box>
                  ),
                },
                // MaxPrice
                ...(!hideMaxPriceFilter
                  ? [
                      {
                        title: (
                          <Box className="header-container">
                            {constants.PRICE_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <MaxPriceFilterSelection
                              showDropdownContentOnly={true}
                              maximumPrice={priceMax.value}
                              minimumPrice={priceMin.value}
                              maxPriceFilter={maxPriceFilter}
                              setMaxPriceFilter={setMaxPriceFilter}
                              hasSetMaxPrice={hasSetMaxPrice}
                            />
                          </Box>
                        ),
                      },
                    ]
                  : []),
                // Airline
                {
                  title: (
                    <Box className="header-container">
                      {constants.AIRLINE_HEADER_TEXT}
                    </Box>
                  ),
                  body: (
                    <Box className="mobile-filter-option-selection">
                      <AirlineFilterSelection
                        showDropdownContentOnly={true}
                        allAirlines={airlineOptions}
                        airlineFilter={airlineFilter}
                        setAirlineFilter={setAirlineFilter}
                        hasSetAirlineFilter={hasSetAirlineFilter}
                      />
                    </Box>
                  ),
                },
                // Departure
                {
                  title: (
                    <Box className="header-container">
                      {constants.DEPARTURE_HEADER_TEXT}
                    </Box>
                  ),
                  body: (
                    <Box className="mobile-filter-option-selection">
                      <DepartureArrivalSelectionDropdown
                        showDropdownContentOnly
                        showDepartureSelectionOnly
                        tripCategory={tripCategory}
                        outboundDepartureTimeRange={outboundDepartureTimeRange}
                        outboundArrivalTimeRange={outboundArrivalTimeRange}
                        returnDepartureTimeRange={returnDepartureTimeRange}
                        returnArrivalTimeRange={returnArrivalTimeRange}
                        hasSetTimeRange={hasSetTimeRange}
                        setOutboundDepartureTimeRange={
                          setOutboundDepartureTimeRange
                        }
                        setOutboundArrivalTimeRange={
                          setOutboundArrivalTimeRange
                        }
                        setReturnDepartureTimeRange={
                          setReturnDepartureTimeRange
                        }
                        setReturnArrivalTimeRange={setReturnArrivalTimeRange}
                      />
                    </Box>
                  ),
                },
                // Arrival
                ...(!hideArrivalFilter
                  ? [
                      {
                        title: (
                          <Box className="header-container">
                            {constants.ARRIVAL_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <DepartureArrivalSelectionDropdown
                              showDropdownContentOnly
                              showArrivalSelectionOnly
                              tripCategory={tripCategory}
                              outboundDepartureTimeRange={
                                outboundDepartureTimeRange
                              }
                              outboundArrivalTimeRange={
                                outboundArrivalTimeRange
                              }
                              returnDepartureTimeRange={
                                returnDepartureTimeRange
                              }
                              returnArrivalTimeRange={returnArrivalTimeRange}
                              hasSetTimeRange={hasSetTimeRange}
                              setOutboundDepartureTimeRange={
                                setOutboundDepartureTimeRange
                              }
                              setOutboundArrivalTimeRange={
                                setOutboundArrivalTimeRange
                              }
                              setReturnDepartureTimeRange={
                                setReturnDepartureTimeRange
                              }
                              setReturnArrivalTimeRange={
                                setReturnArrivalTimeRange
                              }
                            />
                          </Box>
                        ),
                      },
                    ]
                  : []),
                // Airport
                ...(!hideAirportFilter
                  ? [
                      {
                        title: (
                          <Box className="header-container">
                            {constants.AIRPORTS_HEADER_TEXT}
                          </Box>
                        ),
                        body: (
                          <Box className="mobile-filter-option-selection">
                            <AirportFilterSelection
                              showDropdownContentOnly={true}
                              allAirports={airportOptions}
                              airportFilter={airportFilter}
                              setAirportFilter={setAirportFilter}
                            />
                          </Box>
                        ),
                      },
                    ]
                  : []),
                // Flight Number
                {
                  title: (
                    <Box className="header-container">
                      {constants.FLIGHT_NUMBER_HEADER_TEXT}
                    </Box>
                  ),
                  body: (
                    <Box className="mobile-filter-option-selection">
                      <FlightNumberFilterSelection
                        showDropdownContentOnly={true}
                        allAirlines={airlineOptions}
                        flightNumbersByAirline={flightNumbersByAirline}
                        flightNumberFilter={flightNumberFilter}
                        departureDateString={departureDateString}
                        setFlightNumberFilter={setFlightNumberFilter}
                        flightNumberAirlineFilter={flightNumberAirlineFilter}
                        setFlightNumberAirlineFilter={
                          setFlightNumberAirlineFilter
                        }
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
        </MobilePopoverCard>
      </Box>
    </Box>
  );
};
