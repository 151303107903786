import React, { useContext } from "react";
import { ButtonWrap, TripsHeader } from "halifax";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { History } from "history";
import { FAQ_URL, BASE_PATH_HOME } from "../../../utils/urlPaths";

import { ClientContext } from "../../../App";
import * as constants from "./constants";
import "./styles.scss";

export interface IContactSupportHeaderProps {
  history: History;
  title: string;
  subtitle: string;
  className?: string;
  isMobile?: boolean;
  isPositionFixed?: boolean;
}

export const ContactSupportHeader = ({
  history,
  title,
  subtitle,
  className,
  isMobile,
  isPositionFixed,
}: IContactSupportHeaderProps) => {
  const clientContext = useContext(ClientContext);

  const ClientLogo = () => (
    <Box className="client-icon-wrapper">
      <ButtonWrap onClick={() => history.push(BASE_PATH_HOME)}>
        {clientContext.logo}
      </ButtonWrap>
    </Box>
  );

  return (
    <TripsHeader
      icon={<ClientLogo />}
      tripHeaderCopy={{
        title,
        subtitle,
        faqTitle: constants.FAQ_TITLE,
        faqCTA: constants.FAQ_CTA,
      }}
      className={clsx("contact-support-header-root", className, {
        "position-fixed": isPositionFixed,
      })}
      isMobile={isMobile}
      onFAQClick={() => {
        window.open(FAQ_URL, "_blank")?.focus();
      }}
    />
  );
};
