// Getters
export const FETCH_TRIP_SUMMARIES = "flightShop/FETCH_TRIP_SUMMARIES";
export type FETCH_TRIP_SUMMARIES = typeof FETCH_TRIP_SUMMARIES;

export const FETCH_MULTICITY_TRIP_SUMMARIES =
  "flightShop/FETCH_MULTICITY_TRIP_SUMMARIES";
export type FETCH_MULTICITY_TRIP_SUMMARIES =
  typeof FETCH_MULTICITY_TRIP_SUMMARIES;

export const STOP_FETCH_MULTICITY_TRIP_SUMMARIES =
  "flightShop/STOP_FETCH_MULTICITY_TRIP_SUMMARIES";
export type STOP_FETCH_MULTICITY_TRIP_SUMMARIES =
  typeof STOP_FETCH_MULTICITY_TRIP_SUMMARIES;

export const FETCH_TRIP_SUMMARIES_FOR_PREDICTION =
  "flightShop/FETCH_TRIP_SUMMARIES_FOR_PREDICTION";
export type FETCH_TRIP_SUMMARIES_FOR_PREDICTION =
  typeof FETCH_TRIP_SUMMARIES_FOR_PREDICTION;

export const FETCH_TRIP_DETAILS = "flightShop/FETCH_TRIP_DETAILS";
export type FETCH_TRIP_DETAILS = typeof FETCH_TRIP_DETAILS;

export const FETCH_TRIP_SUMMARIES_V2 = "flightShop/FETCH_TRIP_SUMMARIES_V2";
export type FETCH_TRIP_SUMMARIES_V2 = typeof FETCH_TRIP_SUMMARIES_V2;

export const FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2 =
  "flightShop/FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2";
export type FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2 =
  typeof FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2;

export const FETCH_TRIP_SUMMARIES_V3 = "flightShop/FETCH_TRIP_SUMMARIES_V3";
export type FETCH_TRIP_SUMMARIES_V3 = typeof FETCH_TRIP_SUMMARIES_V3;

export const STOP_FETCH_TRIP_SUMMARIES_V3 =
  "flightShop/STOP_FETCH_TRIP_SUMMARIES_V3";
export type STOP_FETCH_TRIP_SUMMARIES_V3 = typeof STOP_FETCH_TRIP_SUMMARIES_V3;

export const FETCH_SIMILAR_FLIGHTS = "flightShop/FETCH_SIMILAR_FLIGHTS";
export type FETCH_SIMILAR_FLIGHTS = typeof FETCH_SIMILAR_FLIGHTS;

export const FETCH_TRANSFER_TO_SIMILAR_FLIGHTS =
  "flightShop/FETCH_TRANSFER_TO_SIMILAR_FLIGHTS";
export type FETCH_TRANSFER_TO_SIMILAR_FLIGHTS =
  typeof FETCH_TRANSFER_TO_SIMILAR_FLIGHTS;

export const FETCH_ANCILLARY_OFFER = "flightShop/FETCH_ANCILLARY_OFFER";
export type FETCH_ANCILLARY_OFFER = typeof FETCH_ANCILLARY_OFFER;

export const FETCH_PRICE_FREEZE_OFFER_FOR_FARE_DETAILS =
  "flightShop/FETCH_PRICE_FREEZE_OFFER_FOR_FARE_DETAILS";
export type FETCH_PRICE_FREEZE_OFFER_FOR_FARE_DETAILS =
  typeof FETCH_PRICE_FREEZE_OFFER_FOR_FARE_DETAILS;

// Setters
export const SET_TRIP_SUMMARIES = "flightShop/SET_TRIP_SUMMARIES";
export type SET_TRIP_SUMMARIES = typeof SET_TRIP_SUMMARIES;

export const SET_MULTICITY_TRIP_SUMMARIES =
  "flightShop/SET_MULTICITY_TRIP_SUMMARIES";
export type SET_MULTICITY_TRIP_SUMMARIES = typeof SET_MULTICITY_TRIP_SUMMARIES;

export const SET_FLIGHTS = "flightShop/SET_FLIGHTS";
export type SET_FLIGHTS = typeof SET_FLIGHTS;

export const SET_MULTICITY_FLIGHTS = "flightShop/SET_MULTICITY_FLIGHTS";
export type SET_MULTICITY_FLIGHTS = typeof SET_MULTICITY_FLIGHTS;

export const SET_TRIP_DETAILS = "flightShop/SET_TRIP_DETAILS";
export type SET_TRIP_DETAILS = typeof SET_TRIP_DETAILS;

export const SET_PRICE_FREEZE_OFFER_ON_FARE_DETAILS =
  "flightShop/SET_PRICE_FREEZE_OFFER_ON_FARE_DETAILS";
export type SET_PRICE_FREEZE_OFFER_ON_FARE_DETAILS =
  typeof SET_PRICE_FREEZE_OFFER_ON_FARE_DETAILS;

export const SET_TRIP_SUMMARIES_ERROR = "flightShop/SET_TRIP_SUMMARIES_ERROR";
export type SET_TRIP_SUMMARIES_ERROR = typeof SET_TRIP_SUMMARIES_ERROR;

export const RERUN_PREDICTION = "flightShop/RERUN_PREDICTION";
export type RERUN_PREDICTION = typeof RERUN_PREDICTION;

export const SET_PREDICTION_ERROR = "flightShop/SET_PREDICTION_ERROR";
export type SET_PREDICTION_ERROR = typeof SET_PREDICTION_ERROR;

export const SET_PREDICTION = "flightShop/SET_PREDICTION";
export type SET_PREDICTION = typeof SET_PREDICTION;

export const SET_BEST_OFFER_OVERALL = "flightShop/SET_BEST_OFFER_OVERALL";
export type SET_BEST_OFFER_OVERALL = typeof SET_BEST_OFFER_OVERALL;

export const SET_OFFERS_BY_TRIP_ID = "flightShop/SET_OFFERS_BY_TRIP_ID";
export type SET_OFFERS_BY_TRIP_ID = typeof SET_OFFERS_BY_TRIP_ID;

export const SET_PRICE_PRICE_FRREEZE_OFFER =
  "flightShop/SET_PRICE_PRICE_FRREEZE_OFFER";
export type SET_PRICE_PRICE_FRREEZE_OFFER =
  typeof SET_PRICE_PRICE_FRREEZE_OFFER;

export const SET_CHOSEN_OUTGOING_SLICE = "flightShop/SET_CHOSEN_OUTGOING_SLICE";
export type SET_CHOSEN_OUTGOING_SLICE = typeof SET_CHOSEN_OUTGOING_SLICE;

export const SET_CHOSEN_RETURN_SLICE = "flightShop/SET_CHOSEN_RETURN_SLICE";
export type SET_CHOSEN_RETURN_SLICE = typeof SET_CHOSEN_RETURN_SLICE;

export const SET_CHOSEN_DEPARTURE_SLICE =
  "flightShop/SET_CHOSEN_DEPARTURE_SLICE";
export type SET_CHOSEN_DEPARTURE_SLICE = typeof SET_CHOSEN_DEPARTURE_SLICE;

export const SET_FLIGHT_SHOP_PROGRESS = "flightShop/SET_FLIGHT_SHOP_PROGRESS";
export type SET_FLIGHT_SHOP_PROGRESS = typeof SET_FLIGHT_SHOP_PROGRESS;

export const SET_PRODUCT_REDEEM_CHOICE = "flightShop/SET_PRODUCT_REDEEM_CHOICE";
export type SET_PRODUCT_REDEEM_CHOICE = typeof SET_PRODUCT_REDEEM_CHOICE;

export const SET_MULTICITY_FLIGHT_SHOP_PROGRESS =
  "flightShop/SET_MULTICITY_FLIGHT_SHOP_PROGRESS";
export type SET_MULTICITY_FLIGHT_SHOP_PROGRESS =
  typeof SET_MULTICITY_FLIGHT_SHOP_PROGRESS;

export const SET_SORT_OPTION = "flightShop/SET_SORT_OPTION";
export type SET_SORT_OPTION = typeof SET_SORT_OPTION;

export const POPULATE_FLIGHT_SHOP_QUERY_PARAMS =
  "flightShop/POPULATE_FLIGHT_SHOP_QUERY_PARAMS";
export type POPULATE_FLIGHT_SHOP_QUERY_PARAMS =
  typeof POPULATE_FLIGHT_SHOP_QUERY_PARAMS;

export const CREATE_WATCH = "flightShop/CREATE_WATCH";
export type CREATE_WATCH = typeof CREATE_WATCH;

export const SET_CREATE_WATCH_CALL_STATE =
  "flightShop/SET_CREATE_WATCH_CALL_STATE";
export type SET_CREATE_WATCH_CALL_STATE = typeof SET_CREATE_WATCH_CALL_STATE;

export const UPDATE_WATCH = "flightShop/UPDATE_WATCH";
export type UPDATE_WATCH = typeof UPDATE_WATCH;

export const SET_UPDATE_WATCH_CALL_STATE =
  "flightShop/SET_UPDATE_WATCH_CALL_STATE";
export type SET_UPDATE_WATCH_CALL_STATE = typeof SET_UPDATE_WATCH_CALL_STATE;

export const DELETE_WATCH = "flightShop/DELETE_WATCH";
export type DELETE_WATCH = typeof DELETE_WATCH;

export const SET_DELETE_WATCH_CALL_STATE =
  "flightShop/SET_DELETE_WATCH_CALL_STATE";
export type SET_DELETE_WATCH_CALL_STATE = typeof SET_DELETE_WATCH_CALL_STATE;

export const LIST_WATCHES = "flightShop/LIST_WATCHES";
export type LIST_WATCHES = typeof LIST_WATCHES;

export const SET_LIST_WATCHES_CALL_STATE =
  "flightShop/SET_LIST_WATCHES_CALL_STATE";
export type SET_LIST_WATCHES_CALL_STATE = typeof SET_LIST_WATCHES_CALL_STATE;

export const SET_WATCHES = "flightShop/SET_WATCHES";
export type SET_WATCHES = typeof SET_WATCHES;

export const SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP =
  "flightShop/SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP";
export type SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP =
  typeof SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP;

export const SET_OPEN_FLIGHT_SHOP_CALENDAR_MOBILE =
  "flightShop/SET_OPEN_FLIGHT_SHOP_CALENDAR_MOBILE";
export type SET_OPEN_FLIGHT_SHOP_CALENDAR_MOBILE =
  typeof SET_OPEN_FLIGHT_SHOP_CALENDAR_MOBILE;

export const SET_FLIGHT_SHOP_TYPE = "flightShop/SET_FLIGHT_SHOP_TYPE";
export type SET_FLIGHT_SHOP_TYPE = typeof SET_FLIGHT_SHOP_TYPE;

export const RESET_SELECTED_TRIP = "flightShop/RESET_SELECTED_TRIP";
export type RESET_SELECTED_TRIP = typeof RESET_SELECTED_TRIP;

export const SET_SIMILAR_FLIGHTS_RESPONSE =
  "flightShop/SET_SIMILAR_FLIGHTS_RESPONSE";
export type SET_SIMILAR_FLIGHTS_RESPONSE = typeof SET_SIMILAR_FLIGHTS_RESPONSE;

export const SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  "flightShop/SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED";
export type SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  typeof SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED;

export const SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE =
  "flightShop/SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE";
export type SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE =
  typeof SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE;

export const SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  "flightShop/SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED";
export type SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED =
  typeof SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED;

export const SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED =
  "flightShop/SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED";
export type SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED =
  typeof SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED;

export const SET_FETCH_ANCILLARY_OFFER_RESPONSE =
  "flightShop/SET_FETCH_ANCILLARY_OFFER_RESPONSE";
export type SET_FETCH_ANCILLARY_OFFER_RESPONSE =
  typeof SET_FETCH_ANCILLARY_OFFER_RESPONSE;

export const SET_FETCH_ANCILLARY_OFFER_CALL_STATE_FAILED =
  "flightShop/SET_FETCH_ANCILLARY_OFFER_CALL_STATE_FAILED";
export type SET_FETCH_ANCILLARY_OFFER_CALL_STATE_FAILED =
  typeof SET_FETCH_ANCILLARY_OFFER_CALL_STATE_FAILED;

export const RESET_FETCH_ANCILLARY_OFFER_STATE =
  "flightShop/RESET_FETCH_ANCILLARY_OFFER_STATE";
export type RESET_FETCH_ANCILLARY_OFFER_STATE =
  typeof RESET_FETCH_ANCILLARY_OFFER_STATE;

export const SET_SELECTED_FLIGHT_INDEX = "flightShop/SET_SELECTED_FLIGHT_INDEX";
export type SET_SELECTED_FLIGHT_INDEX = typeof SET_SELECTED_FLIGHT_INDEX;

export const SET_SELECTED_CFAR_ID = "flightShop/SET_SELECTED_CFAR_ID";
export type SET_SELECTED_CFAR_ID = typeof SET_SELECTED_CFAR_ID;

export const SET_SELECTED_CHFAR_ID = "flightShop/SET_SELECTED_CHFAR_ID";
export type SET_SELECTED_CHFAR_ID = typeof SET_SELECTED_CHFAR_ID;

export const SET_SKIP_CHFAR_TRACKING_PROPERTIES =
  "flightShop/SET_SKIP_CHFAR_TRACKING_PROPERTIES";
export type SET_SKIP_CHFAR_TRACKING_PROPERTIES =
  typeof SET_SKIP_CHFAR_TRACKING_PROPERTIES;

export const SET_HAS_SELECTED_REFUNDABLE_FARE =
  "flightShop/SET_HAS_SELECTED_REFUNDABLE_FARE";
export type SET_HAS_SELECTED_REFUNDABLE_FARE =
  typeof SET_HAS_SELECTED_REFUNDABLE_FARE;

export const UPDATE_REFUNDABLE_FARES_PROPERTIES =
  "flightShop/UPDATE_REFUNDABLE_FARES_PROPERTIES";
export type UPDATE_REFUNDABLE_FARES_PROPERTIES =
  typeof UPDATE_REFUNDABLE_FARES_PROPERTIES;

export const RESET_REFUNDABLE_FARES_PROPERTIES =
  "flightShop/RESET_REFUNDABLE_FARES_PROPERTIES";
export type RESET_REFUNDABLE_FARES_PROPERTIES =
  typeof RESET_REFUNDABLE_FARES_PROPERTIES;

export const SET_SELECTED_DISRUPTION_PROTECTION_ID =
  "flightShop/SET_SELECTED_DISRUPTION_PROTECTION_ID";
export type SET_SELECTED_DISRUPTION_PROTECTION_ID =
  typeof SET_SELECTED_DISRUPTION_PROTECTION_ID;

export const SET_HAS_PRICE_FREEZE_ON_OUTBOUND =
  "flightShop/SET_HAS_PRICE_FREEZE_ON_OUTBOUND";
export type SET_HAS_PRICE_FREEZE_ON_OUTBOUND =
  typeof SET_HAS_PRICE_FREEZE_ON_OUTBOUND;

export const SET_FLIGHT_SHOP_EXPERIMENTS =
  "flightShop/SET_FLIGHT_SHOP_EXPERIMENTS";
export type SET_FLIGHT_SHOP_EXPERIMENTS = typeof SET_FLIGHT_SHOP_EXPERIMENTS;

export const SET_FLIGHT_SHOP_ENTRY_POINT =
  "flightShop/SET_FLIGHT_SHOP_ENTRY_POINT";
export type SET_FLIGHT_SHOP_ENTRY_POINT = typeof SET_FLIGHT_SHOP_ENTRY_POINT;

export const SET_FLIGHT_SHOP_RECOMMENDED_BASED_ON_PREFERENCES =
  "flightShop/SET_FLIGHT_SHOP_RECOMMENDED_BASED_ON_PREFERENCES";
export type SET_FLIGHT_SHOP_RECOMMENDED_BASED_ON_PREFERENCES =
  typeof SET_FLIGHT_SHOP_RECOMMENDED_BASED_ON_PREFERENCES;

export const SET_SELECTED_MARKETING_AIRLINE_CODES =
  "flightShop/SET_SELECTED_MARKETING_AIRLINE_CODES";
export type SET_SELECTED_MARKETING_AIRLINE_CODES =
  typeof SET_SELECTED_MARKETING_AIRLINE_CODES;

export const SET_SELECTED_OPERATING_AIRLINE_CODES =
  "flightShop/SET_SELECTED_OPERATING_AIRLINE_CODES";
export type SET_SELECTED_OPERATING_AIRLINE_CODES =
  typeof SET_SELECTED_OPERATING_AIRLINE_CODES;

export const LIST_PAYMENT_METHODS = "flightShop/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS = "flightShop/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "flightShop/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;

// Corporate Travel
export const SET_POLICY_LIMIT = "flightShop/SET_POLICY_LIMIT";
export type SET_POLICY_LIMIT = typeof SET_POLICY_LIMIT;

export const SET_SHOP_REQUEST = "flightShop/SET_SHOP_REQUEST";
export type SET_SHOP_REQUEST = typeof SET_SHOP_REQUEST;

export const FETCH_CORP_FINTECH_ELIGIBILITY =
  "flightShop/FETCH_CORP_FINTECH_ELIGIBILITY";
export type FETCH_CORP_FINTECH_ELIGIBILITY =
  typeof FETCH_CORP_FINTECH_ELIGIBILITY;

export const SET_CORP_FINTECH_ELIGIBILITY =
  "flightShop/SET_CORP_FINTECH_ELIGIBILITY";
export type SET_CORP_FINTECH_ELIGIBILITY = typeof SET_CORP_FINTECH_ELIGIBILITY;
