import { put, putResolve, take, select } from "redux-saga/effects";
import {
  FlightBookType,
  SingleFlightItineraryResponse,
  CallState,
} from "redmond";
import { BookedFlightItinerary } from "@b2bportal/air-booking-api";
import { PaymentOpaqueValue, CipherText } from "@b2bportal/purchase-api";
import {
  IExerciseDisruptionProtectionRebook,
  setExerciseDisruptionProtectionRebookCallStateSuccess,
  setExerciseDisruptionProtectionRebookCallStateFailed,
  fetchSingleFlightItinerary,
} from "../../actions/actions";
import {
  SET_SINGLE_FLIGHT_ITINERARY,
  SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED,
} from "../../actions/constants";
import {
  originalItineraryIdSelector,
  singleFlightItinerarySelector,
  fetchSingleFlightItineraryCallStateSelector,
} from "../../reducer/selectors";
import {
  setFlightBookType,
  scheduleQuote,
  schedulePayment,
} from "../../../book/actions/actions";
import {
  SCHEDULE_QUOTE_CALL_STATE_FAILED,
  SET_POLL_QUOTE_CALL_STATE_FAILED,
  SET_POLL_QUOTE_CALL_STATE_SUCCESS,
  SET_SCHEDULE_PAYMENT_FAILED,
  POLL_FINALIZED_CALL_STATE_FAILED,
  SET_FINALIZED,
} from "../../../book/actions/constants";
import {
  getSession,
  getPollPriceQuoteCallState,
  getDisruptionProtectionRebookPayments,
  getFinalizedItinerary,
} from "../../../book/reducer/selectors";
import Logger from "../../../../helpers/Logger";

export function* exerciseDisruptionProtectionRebookSaga(
  action: IExerciseDisruptionProtectionRebook
) {
  try {
    yield putResolve(
      setFlightBookType(FlightBookType.DISRUPTION_PROTECTION_EXERCISE)
    );

    const originalItineraryId: string | null = yield select(
      originalItineraryIdSelector
    );
    const singleFlightItinerary: SingleFlightItineraryResponse | null =
      yield select(singleFlightItinerarySelector);
    if (!singleFlightItinerary) {
      yield put(fetchSingleFlightItinerary(originalItineraryId ?? ""));
      // note: wait for fetchSingleFlightItinerary to finish
      yield take([
        SET_FETCH_SINGLE_FLIGHT_ITINERARY_CALL_STATE_FAILED,
        SET_SINGLE_FLIGHT_ITINERARY,
      ]);

      const fetchSingleFlightItineraryCallState: CallState = yield select(
        fetchSingleFlightItineraryCallStateSelector
      );
      if (fetchSingleFlightItineraryCallState !== CallState.Success) {
        yield put(setExerciseDisruptionProtectionRebookCallStateFailed(`Unsuccessful fetchSingleFlightItineraryCall, CallState=${fetchSingleFlightItineraryCallState}`));
        return;
      }
    }

    yield put(
      scheduleQuote({
        agentFee: action.agentFee,
      })
    );
    // note: wait for priceQuote to finish
    yield take([
      SCHEDULE_QUOTE_CALL_STATE_FAILED,
      SET_POLL_QUOTE_CALL_STATE_FAILED,
      SET_POLL_QUOTE_CALL_STATE_SUCCESS,
    ]);

    const pollPriceQuoteCallState: CallState = yield select(
      getPollPriceQuoteCallState
    );
    const payments: PaymentOpaqueValue[] | null = yield select(
      getDisruptionProtectionRebookPayments
    );
    const session: CipherText | undefined = yield select(getSession);

    if (pollPriceQuoteCallState !== CallState.Success) {
      yield put(setExerciseDisruptionProtectionRebookCallStateFailed(`Unsuccessful pollPriceQuoteCallState, CallState=${pollPriceQuoteCallState}`));
      return;
    } else if (!payments) {
      yield put(setExerciseDisruptionProtectionRebookCallStateFailed("Undefined or empty rebookPayments"));
      return;
    } else if (!session) {
      yield put(setExerciseDisruptionProtectionRebookCallStateFailed("Undefined session"));
      return;
    }

    yield put(
      schedulePayment({
        session,
        payments,
        ancillaries: [],
        agentFee: action.agentFee,
      })
    );
    // note: wait for payment to finish
    yield take([
      SET_SCHEDULE_PAYMENT_FAILED,
      POLL_FINALIZED_CALL_STATE_FAILED,
      SET_FINALIZED,
    ]);

    const finalizedItinerary: BookedFlightItinerary | null = yield select(
      getFinalizedItinerary
    );
    if (!finalizedItinerary) {
      yield put(setExerciseDisruptionProtectionRebookCallStateFailed("Unfinalized itinerary"));
      return;
    }

    yield put(setExerciseDisruptionProtectionRebookCallStateSuccess());
  } catch (e) {
    yield put(setExerciseDisruptionProtectionRebookCallStateFailed((e as Error).message));
    Logger.debug(e);
  }
}
