export const FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY =
  "flightToHotelCrossSell/FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY";
export type FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY =
  typeof FETCH_INITIAL_CROSS_SELL_HOTEL_AVAILABILITY;

export const FETCH_MORE_CROSS_SELL_HOTEL_AVAILABILITY =
  "flightToHotelCrossSell/FETCH_MORE_CROSS_SELL_HOTEL_AVAILABILITY";
export type FETCH_MORE_CROSS_SELL_HOTEL_AVAILABILITY =
  typeof FETCH_MORE_CROSS_SELL_HOTEL_AVAILABILITY;

export const SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS =
  "flightToHotelCrossSell/SET_CROSS_SELL_OTEL_AVAILABILITY_RESULTS";
export type SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS =
  typeof SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS;

export const SET_CROSS_SELL_HOTEL_AVAILABILITY_CALL_STATE_FAILED =
  "flightToHotelCrossSell/SET_CROSS_SELL_HOTEL_AVAILABILITY_CALL_STATE_FAILED";
export type SET_CROSS_SELL_HOTEL_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_CROSS_SELL_HOTEL_AVAILABILITY_CALL_STATE_FAILED;

export const SET_SEARCHED_DATES = "flightToHotelCrossSell/SET_SEARCHED_DATES";
export type SET_SEARCHED_DATES = typeof SET_SEARCHED_DATES;

export const SET_SEARCHED_LOCATION_RESULT =
  "flightToHotelCrossSell/SET_SEARCHED_LOCATION_RESULT";
export type SET_SEARCHED_LOCATION_RESULT = typeof SET_SEARCHED_LOCATION_RESULT;

export const SET_SEARCHED_OCCUPANCY_COUNTS =
  "flightToHotelCrossSell/SET_SEARCHED_OCCUPANCY_COUNTS";
export type SET_SEARCHED_OCCUPANCY_COUNTS =
  typeof SET_SEARCHED_OCCUPANCY_COUNTS;

export const SET_SELECTED_LODGING_INDEX =
  "flightToHotelCrossSell/SET_SELECTED_LODGING_INDEX";
export type SET_SELECTED_LODGING_INDEX = typeof SET_SELECTED_LODGING_INDEX;

export const FETCH_UPCOMING_FLIGHT_CROSS_SELL =
  "flightToHotelCrossSell/FETCH_UPCOMING_FLIGHT_CROSS_SELL";
export type FETCH_UPCOMING_FLIGHT_CROSS_SELL =
  typeof FETCH_UPCOMING_FLIGHT_CROSS_SELL;

export const SET_UPCOMING_FLIGHTS =
  "flightToHotelCrossSell/SET_UPCOMING_FLIGHTS";
export type SET_UPCOMING_FLIGHTS = typeof SET_UPCOMING_FLIGHTS;

export const FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED =
  "flightToHotelCrossSell/FETCH_UPCOMING_FFLIGHT_CROSS_SELL_FAILED";
export type FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED =
  typeof FETCH_UPCOMING_FLIGHT_CROSS_SELL_FAILED;

export const SET_MOST_UPCOMING_FLIGHT =
  "flightToHotelCrossSell/SET_MOST_UPCOMING_FLIGHT";
export type SET_MOST_UPCOMING_FLIGHT = typeof SET_MOST_UPCOMING_FLIGHT;

export const FETCH_POTENTIAL_CROSS_SELL_OFFERS =
  "flightToHotelCrossSell/FETCH_POTENTIAL_CROSS_SELL_OFFERS";
export type FETCH_POTENTIAL_CROSS_SELL_OFFERS =
  typeof FETCH_POTENTIAL_CROSS_SELL_OFFERS;

export const SET_POTENTIAL_CROSS_SELL_OFFERS =
  "flightToHotelCrossSell/SET_POTENTIAL_CROSS_SELL_OFFERS";
export type SET_POTENTIAL_CROSS_SELL_OFFERS =
  typeof SET_POTENTIAL_CROSS_SELL_OFFERS;

export const FETCH_POTENTIAL_CROSS_SELL_OFFERS_FAILED =
  "flightToHotelCrossSell/FETCH_POTENTIAL_CROSS_SELL_OFFERS_FAILED";
export type FETCH_POTENTIAL_CROSS_SELL_OFFERS_FAILED =
  typeof FETCH_POTENTIAL_CROSS_SELL_OFFERS_FAILED;
