import { ITripTerminus } from "redmond";

export const getCityNameAndAirportCodeFromTerminus = (
  location: ITripTerminus
) => {
  const locationLabel = location?.label || "";
  // location label is usually in the following format:
  // New York City, New York (LGA)
  // we need to account for when there is no comma in the returned string
  // example: Washington D.C. (3 Airports)
  const cityName = locationLabel.substring(
    0,
    locationLabel.indexOf(",") > 0
      ? locationLabel.indexOf(",")
      : locationLabel.indexOf(" ")
  );
  const airportCode = locationLabel.slice(locationLabel.indexOf("("));
  return [cityName, airportCode];
};
