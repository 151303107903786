import axios from "axios";

import { pendingRequestApiPrefix } from "../../paths";
import { FulfillResponse } from "@b2bportal/purchase-api";
import { config } from "../../../config";
import { FlightsSubmitForApprovalPayload } from "redmond";

const submitForApproval = async (
  body: FlightsSubmitForApprovalPayload
): Promise<FulfillResponse> => {
  try {
    const END_POINT = `${pendingRequestApiPrefix}/create`;
    const res = await axios.post(END_POINT, body, {
      baseURL: config.baseURL,
    });

    return res.data;
  } catch (e) {
    throw e;
  }
};

export default submitForApproval;
