import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";

import { TravelOfferSelection } from "./component";
import { withRouter } from "react-router";

import {
  getBestOfferOverall,
  getCredit,
  getCreditToApply,
  getFetchApplicableTravelWalletItemsCallState,
  getIsTravelWalletPaymentOnly,
  getOffers,
  getOfferToApply,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getMaxApplicableTravelWalletCreditOnlyAmount,
} from "../../reducer";
import {
  resetPaymentCardSelectedAccounts,
  setCreditToApply,
  setOfferToApply,
  setSelectedRewardsPaymentTotal,
} from "../../actions/actions";
import { getCreditBreakdown, getTravelCreditsHistoryTransaction } from "../../../travel-wallet/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    bestOverallOffer: getBestOfferOverall(state),
    offers: getOffers(state),
    offerToApply: getOfferToApply(state),
    fetchApplicableTravelWalletItemsCallState:
      getFetchApplicableTravelWalletItemsCallState(state),
    selectedPaymentMethodId: getSelectedPaymentMethodId(state) ?? "",
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    credit: getCredit(state),
    creditBreakdown: getCreditBreakdown(state),
    creditToApply: getCreditToApply(state),
    maxApplicableTravelWalletCreditOnly:
      getMaxApplicableTravelWalletCreditOnlyAmount(state),
    isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
    creditsTransactionHistory: getTravelCreditsHistoryTransaction(state),
  };
};

export const mapDispatchToProps = {
  setOfferToApply,
  resetPaymentCardSelectedAccounts,
  setSelectedRewardsPaymentTotal,
  setCreditToApply,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelOfferSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedTravelOfferSelection = withRouter(
  connector(TravelOfferSelection)
);
