import {
  TripCategory,
  ITimeRange,
  ITripTerminus,
  IDepartureCalendarReport,
  IMonthBucket,
  AirlineCode,
  SliceStopCountFilter,
  APPLIED_FILTER,
  AppliedFilterProperties,
  AppliedFilterPropertyType,
  UserFlightPreferencesPayload,
} from "redmond";
import { PassengerCountPickerType } from "halifax";

import * as actionTypes from "./constants";
import {
  FareclassOptionFilter,
  IFlightNumberFilter,
  initialFilterOptions,
  MobileFlightSearchStep,
  MulticityRoute,
} from "../reducer";
import { ITripTerminusCategory } from "../types";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { isEqual } from "lodash-es";

export interface ISetTripCategory {
  type: actionTypes.SET_TRIP_CATEGORY;
  tripCategory: TripCategory;
  updateTripCategoryOnly?: boolean;
}

export const setTripCategory = (
  tripCategory: TripCategory,
  updateTripCategoryOnly?: boolean
): ISetTripCategory => ({
  type: actionTypes.SET_TRIP_CATEGORY,
  tripCategory,
  updateTripCategoryOnly,
});

export interface ISetOrigin {
  type: actionTypes.SET_ORIGIN;
  origin: ITripTerminus | null;
}

export const setOrigin = (origin: ITripTerminus | null): ISetOrigin => ({
  type: actionTypes.SET_ORIGIN,
  origin,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetOriginCategories {
  type: actionTypes.SET_ORIGIN_CATEGORIES;
  originCategories: ITripTerminusCategory[];
}

export const setOriginCategories = (
  originCategories: ITripTerminusCategory[]
): ISetOriginCategories => ({
  type: actionTypes.SET_ORIGIN_CATEGORIES,
  originCategories,
});

export interface IFetchOriginCategories {
  type: actionTypes.FETCH_ORIGIN_CATEGORIES;
  queryString: string;
}

export const fetchOriginCategories = (
  queryString: string
): IFetchOriginCategories => ({
  type: actionTypes.FETCH_ORIGIN_CATEGORIES,
  queryString,
});

export interface ISetDestination {
  type: actionTypes.SET_DESTINATION;
  destination: ITripTerminus | null;
}

export const setDestination = (
  destination: ITripTerminus | null
): ISetDestination => ({
  type: actionTypes.SET_DESTINATION,
  destination,
});

export interface ISetDestinationCategories {
  type: actionTypes.SET_DESTINATION_CATEGORIES;
  destinationCategories: ITripTerminusCategory[];
}

export const setDestinationCategories = (
  destinationCategories: ITripTerminusCategory[]
): ISetDestinationCategories => ({
  type: actionTypes.SET_DESTINATION_CATEGORIES,
  destinationCategories,
});

export interface IFetchDestinationCategories {
  type: actionTypes.FETCH_DESTINATION_CATEGORIES;
  queryString: string;
}

export const fetchDestinationCategories = (
  queryString: string
): IFetchDestinationCategories => ({
  type: actionTypes.FETCH_DESTINATION_CATEGORIES,
  queryString,
});

export interface ISetDepartureDate {
  type: actionTypes.SET_DEPARTURE_DATE;
  date: Date | null;
}

export const setDepartureDate = (date: Date | null): ISetDepartureDate => ({
  type: actionTypes.SET_DEPARTURE_DATE,
  date,
});

export interface ISetReturnDate {
  type: actionTypes.SET_RETURN_DATE;
  date: Date | null;
}

export const setReturnDate = (date: Date | null): ISetReturnDate => ({
  type: actionTypes.SET_RETURN_DATE,
  date,
});

export interface ISetMulticityOrigin {
  type: actionTypes.SET_MULTICITY_ORIGIN;
  origin: ITripTerminus | null;
  index: number;
}

export const setMulticityOrigin = (
  origin: ITripTerminus | null,
  index: number
): ISetMulticityOrigin => ({
  type: actionTypes.SET_MULTICITY_ORIGIN,
  origin,
  index,
});

export interface ISetMulticityDestination {
  type: actionTypes.SET_MULTICITY_DESTINATION;
  destination: ITripTerminus | null;
  index: number;
}

export const setMulticityDestination = (
  destination: ITripTerminus | null,
  index: number
): ISetMulticityDestination => ({
  type: actionTypes.SET_MULTICITY_DESTINATION,
  destination,
  index,
});

export interface ISetMulticityDepartureDate {
  type: actionTypes.SET_MULTICITY_DEPARTURE_DATE;
  departureDate: Date | null;
  index: number;
}

export const setMulticityDepartureDate = (
  departureDate: Date | null,
  index: number
): ISetMulticityDepartureDate => ({
  type: actionTypes.SET_MULTICITY_DEPARTURE_DATE,
  departureDate,
  index,
});

export interface ISetAllMulticityRoutes {
  type: actionTypes.SET_ALL_MULTICITY_ROUTES;
  multicityRoutes: MulticityRoute[];
}

export const setAllMulticityRoutes = (
  multicityRoutes: MulticityRoute[]
): ISetAllMulticityRoutes => ({
  type: actionTypes.SET_ALL_MULTICITY_ROUTES,
  multicityRoutes,
});

export interface IAddMulticityRoute {
  type: actionTypes.ADD_MULTICITY_ROUTE;
}

export const addMulticityRoute = (): IAddMulticityRoute => ({
  type: actionTypes.ADD_MULTICITY_ROUTE,
});

export interface IRemoveMulticityRoute {
  type: actionTypes.REMOVE_MULTICITY_ROUTE;
  index: number;
}

export const removeMulticityRoute = (index: number): IRemoveMulticityRoute => ({
  type: actionTypes.REMOVE_MULTICITY_ROUTE,
  index,
});

export interface IFetchDepartureCalendar {
  type: actionTypes.FETCH_DEPARTURE_CALENDAR;
  origin: ITripTerminus;
  destination: ITripTerminus;
}

export const fetchDepartureCalendar = (
  origin: ITripTerminus,
  destination: ITripTerminus
): IFetchDepartureCalendar => ({
  type: actionTypes.FETCH_DEPARTURE_CALENDAR,
  origin,
  destination,
});

export interface ISetDepartureCalendar extends ISetDepartureCalendarArgs {
  type: actionTypes.SET_DEPARTURE_CALENDAR;
}

export interface ISetDepartureCalendarArgs
  extends Partial<IDepartureCalendarReport> {
  departureMonths?: IMonthBucket[];
}

export const setCalendar = (
  report: ISetDepartureCalendarArgs = {}
): ISetDepartureCalendar => {
  return { type: actionTypes.SET_DEPARTURE_CALENDAR, ...report };
};

export interface ISetPassengerCounts {
  type: actionTypes.SET_PASSENGER_COUNTS;
  counts: PassengerCountPickerType;
}

export const setPassengerCounts = (
  counts: PassengerCountPickerType
): ISetPassengerCounts => ({
  type: actionTypes.SET_PASSENGER_COUNTS,
  counts,
});

// Filter option actions
export interface ISetStopsOption {
  type: actionTypes.SET_STOPS_OPTION;
  stopsOption: SliceStopCountFilter;
}

export const setStopsOption = (
  stopsOption: SliceStopCountFilter,
  location?: "search" | "list"
): ISetStopsOption => {
  if (location && stopsOption !== initialFilterOptions.stopsOption) {
    trackEvent(generateFilterTrackingEvent("stops", location));
  }
  return {
    type: actionTypes.SET_STOPS_OPTION,
    stopsOption,
  };
};

export interface ISetMaxPriceFilter {
  type: actionTypes.SET_MAX_PRICE_FILTER;
  maxPriceFilter: number;
}

export const setMaxPriceFilter = (
  maxPriceFilter: number
): ISetMaxPriceFilter => {
  return {
    type: actionTypes.SET_MAX_PRICE_FILTER,
    maxPriceFilter,
  };
};

export interface ISetFareclassOptionFilter {
  type: actionTypes.SET_FARECLASS_OPTION_FILTER;
  fareclassOptionFilter: FareclassOptionFilter;
}

export const setFareclassOptionFilter = (
  fareclassOptionFilter: FareclassOptionFilter,
  location?: "search" | "list"
): ISetFareclassOptionFilter => {
  if (
    location &&
    !isEqual(fareclassOptionFilter, initialFilterOptions.fareclassOptionFilter)
  ) {
    trackEvent(generateFilterTrackingEvent("fareclass", location));
  }
  return {
    type: actionTypes.SET_FARECLASS_OPTION_FILTER,
    fareclassOptionFilter,
  };
};

export interface ISetBaggageTransfersFilter {
  type: actionTypes.SET_BAGGAGE_TRANSFERS_FILTER;
  value: boolean;
}

export const setBaggageTransfersFilter = (
  value: boolean
): ISetBaggageTransfersFilter => {
  return {
    type: actionTypes.SET_BAGGAGE_TRANSFERS_FILTER,
    value,
  };
};

export interface ISetOutboundDepartureTimeRange {
  type: actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER;
  outboundDepartureTimeRange: ITimeRange;
}

export const setOutboundDepartureTimeRange = (
  outboundDepartureTimeRange: ITimeRange
): ISetOutboundDepartureTimeRange => {
  if (
    outboundDepartureTimeRange !==
    initialFilterOptions.outboundDepartureTimeRange
  ) {
    trackEvent(generateFilterTrackingEvent("time", "list"));
  }

  return {
    type: actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER,
    outboundDepartureTimeRange,
  };
};

export interface ISetOutboundArrivalTimeRange {
  type: actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER;
  outboundArrivalTimeRange: ITimeRange;
}

export const setOutboundArrivalTimeRange = (
  outboundArrivalTimeRange: ITimeRange
): ISetOutboundArrivalTimeRange => {
  if (
    outboundArrivalTimeRange !== initialFilterOptions.outboundArrivalTimeRange
  ) {
    trackEvent(generateFilterTrackingEvent("time", "list"));
  }

  return {
    type: actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER,
    outboundArrivalTimeRange,
  };
};

export interface ISetReturnDepartureTimeRange {
  type: actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER;
  returnDepartureTimeRange: ITimeRange;
}

export const setReturnDepartureTimeRange = (
  returnDepartureTimeRange: ITimeRange
): ISetReturnDepartureTimeRange => {
  if (
    returnDepartureTimeRange !== initialFilterOptions.returnDepartureTimeRange
  ) {
    trackEvent(generateFilterTrackingEvent("time", "list"));
  }

  return {
    type: actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER,
    returnDepartureTimeRange,
  };
};

export interface ISetReturnArrivalTimeRange {
  type: actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER;
  returnArrivalTimeRange: ITimeRange;
}

export const setReturnArrivalTimeRange = (
  returnArrivalTimeRange: ITimeRange
): ISetReturnArrivalTimeRange => {
  if (returnArrivalTimeRange !== initialFilterOptions.returnArrivalTimeRange) {
    trackEvent(generateFilterTrackingEvent("time", "list"));
  }

  return {
    type: actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER,
    returnArrivalTimeRange,
  };
};

export interface ISetAirlineFilter {
  type: actionTypes.SET_AIRLINE_FILTER;
  airlineFilter: AirlineCode[];
}

export const setAirlineFilter = (
  airlineFilter: AirlineCode[]
): ISetAirlineFilter => {
  if (airlineFilter !== initialFilterOptions.airlineFilter) {
    trackEvent(generateFilterTrackingEvent("airline", "list"));
  }
  return {
    type: actionTypes.SET_AIRLINE_FILTER,
    airlineFilter,
  };
};

export interface ISetAirportFilter {
  type: actionTypes.SET_AIRPORT_FILTER;
  airportFilter: string[];
}

export const setAirportFilter = (
  airportFilter: string[]
): ISetAirportFilter => {
  return {
    type: actionTypes.SET_AIRPORT_FILTER,
    airportFilter,
  };
};

export interface ISetFlightNumberFilter {
  type: actionTypes.SET_FLIGHT_NUMBER_FILTER;
  flightNumberFilter: IFlightNumberFilter[];
}

export const setFlightNumberFilter = (
  flightNumberFilter: IFlightNumberFilter[]
): ISetFlightNumberFilter => {
  return {
    type: actionTypes.SET_FLIGHT_NUMBER_FILTER,
    flightNumberFilter,
  };
};

export const fetchUserFlightPreferences = (): IFetchUserFlightPreferences => ({
  type: actionTypes.FETCH_USER_FLIGHT_PREFERENCES,
});

export interface IFetchUserFlightPreferences {
  type: actionTypes.FETCH_USER_FLIGHT_PREFERENCES;
}

export const setUserFlightPreferences = (args: {
  flightPreferences: UserFlightPreferencesPayload;
}): ISetUserFlightPreferences => ({
  type: actionTypes.SET_USER_FLIGHT_PREFERENCES,
  flightPreferences: args.flightPreferences,
});

export interface ISetUserFlightPreferences {
  type: actionTypes.SET_USER_FLIGHT_PREFERENCES;
  flightPreferences: UserFlightPreferencesPayload;
}

export const setUserFlightPreferencesCallStateFailed =
  (): ISetUserFlightPreferencesCallStateFailed => ({
    type: actionTypes.SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED,
  });

export interface ISetUserFlightPreferencesCallStateFailed {
  type: actionTypes.SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED;
}

export interface ISetApplyUserFlightPreferences {
  type: actionTypes.SET_APPLY_USER_FLIGHT_PREFERENCES;
  applyPreferences: boolean;
}

export const setApplyUserFlightPreferences = (
  applyPreferences: boolean
): ISetApplyUserFlightPreferences => ({
  type: actionTypes.SET_APPLY_USER_FLIGHT_PREFERENCES,
  applyPreferences,
});

export interface ISetUserPreferencesNotAvailable {
  type: actionTypes.SET_USER_PREFERENCES_NOT_AVAILABLE;
  userPreferencesNotAvailable: boolean;
}

export const setUserPreferencesNotAvailable = (
  userPreferencesNotAvailable: boolean
): ISetUserPreferencesNotAvailable => ({
  type: actionTypes.SET_USER_PREFERENCES_NOT_AVAILABLE,
  userPreferencesNotAvailable: userPreferencesNotAvailable,
});

// Corporate
export interface ISetPolicyFilter {
  type: actionTypes.SET_POLICY_FILTER;
  policyFilter: boolean;
}

export const setPolicyFilter = (policyFilter: boolean): ISetPolicyFilter => {
  trackEvent(generateFilterTrackingEvent("policy_status", "list"));
  return {
    type: actionTypes.SET_POLICY_FILTER,
    policyFilter,
  };
};

export interface ISetDurationFilter {
  type: actionTypes.SET_DURATION_FILTER;
  duration: number;
}

export const setDurationFilter = (duration: number): ISetDurationFilter => {
  return {
    type: actionTypes.SET_DURATION_FILTER,
    duration,
  };
};

export interface ISetAwaitingRefetch {
  type: actionTypes.SET_AWAITING_REFETCH;
  awaitingRefetch: boolean;
}

export const setAwaitingRefetch = (
  awaitingRefetch: boolean
): ISetAwaitingRefetch => ({
  type: actionTypes.SET_AWAITING_REFETCH,
  awaitingRefetch,
});

export interface ISetMobileSearchProgress {
  type: actionTypes.SET_MOBILE_SEARCH_PROGRESS;
  step: MobileFlightSearchStep;
}

export const setMobileSearchProgress = (
  step: MobileFlightSearchStep
): ISetMobileSearchProgress => ({
  type: actionTypes.SET_MOBILE_SEARCH_PROGRESS,
  step,
});

export interface ISetOriginAutocompleteError {
  type: actionTypes.SET_ORIGIN_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setOriginAutocompleteError = (
  error: boolean
): ISetOriginAutocompleteError => ({
  type: actionTypes.SET_ORIGIN_AUTOCOMPLETE_ERROR,
  error,
});

export interface ISetDestinationAutocompleteError {
  type: actionTypes.SET_DESTINATION_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setDestinationAutocompleteError = (
  error: boolean
): ISetDestinationAutocompleteError => ({
  type: actionTypes.SET_DESTINATION_AUTOCOMPLETE_ERROR,
  error,
});

export type FlightSearchActions =
  | ISetTripCategory
  | ISetOrigin
  | ISetOriginCategories
  | IFetchOriginCategories
  | ISetDestination
  | ISetDestinationCategories
  | IFetchDestinationCategories
  | ISetDepartureDate
  | ISetReturnDate
  | ISetMulticityOrigin
  | ISetMulticityDestination
  | ISetMulticityDepartureDate
  | ISetAllMulticityRoutes
  | IAddMulticityRoute
  | IRemoveMulticityRoute
  | IFetchDepartureCalendar
  | ISetDepartureCalendar
  | ISetStopsOption
  | ISetMaxPriceFilter
  | ISetFareclassOptionFilter
  | ISetBaggageTransfersFilter
  | ISetOutboundDepartureTimeRange
  | ISetOutboundArrivalTimeRange
  | ISetReturnDepartureTimeRange
  | ISetReturnArrivalTimeRange
  | ISetAirlineFilter
  | ISetAirportFilter
  | ISetFlightNumberFilter
  | ISetPolicyFilter
  | ISetDurationFilter
  | ISetPassengerCounts
  | ISetAwaitingRefetch
  | ISetMobileSearchProgress
  | ISetOpenDatesModal
  | ISetOpenDatesModal
  | ISetOriginAutocompleteError
  | ISetDestinationAutocompleteError
  | IFetchUserFlightPreferences
  | ISetUserFlightPreferences
  | ISetUserFlightPreferencesCallStateFailed
  | ISetApplyUserFlightPreferences
  | ISetUserPreferencesNotAvailable;

export const generateFilterTrackingEvent = (
  type: AppliedFilterPropertyType,
  location: "search" | "list"
): { eventName: string; properties: AppliedFilterProperties } => {
  return {
    eventName: APPLIED_FILTER,
    properties: { filter_type: type, location },
  };
};
