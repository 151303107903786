import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import * as constants from "./constants";
import "./styles.scss";
import { config } from "../../../../../api/config";

export interface IAddOnHeaderProps {}

export const AddOnHeader = (_: IAddOnHeaderProps) => {
  return (
    <Box className="add-on-customize-header-root">
      <Box className="add-on-customize-header-details">
        <Box className="add-on-customize-header-title">
          <Typography className="title-text">
            {constants.ADD_ON_HEADER_TITLE}
          </Typography>
        </Box>
        <Box className="add-on-customize-header-subtitle">
          <Typography className="subtitle-text">
            {constants.ADD_ON_HEADER_SUBTITLE}
          </Typography>
        </Box>
      </Box>
      <Box
        className={clsx("add-on-customize-header-image", config.TENANT)}
      ></Box>
    </Box>
  );
};
