import React from "react";
import { debounce } from "lodash-es";
import { SliderDropdown } from "halifax";

import * as textConstants from "./textConstants";
import { MaxPriceFilterSelectionProps } from "./container";
import { generateFilterTrackingEvent } from "../../../../../../actions/actions";
import { trackEvent } from "../../../../../../../../api/v0/analytics/trackEvent";

interface IMaxPriceFilterSelectionProps extends MaxPriceFilterSelectionProps {
  showDropdownContentOnly?: boolean;
}

export const MaxPriceFilterSelection = (
  props: IMaxPriceFilterSelectionProps
) => {
  const {
    maxPriceFilter,
    setMaxPriceFilter,
    maximumPrice,
    minimumPrice,
    showDropdownContentOnly,
  } = props;
  const debouncedAction = debounce(setMaxPriceFilter, 300);
  const [stateDebounceDispatchAction] = React.useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (newValue: number) => {
    trackEvent(generateFilterTrackingEvent("price", "list"));

    stateDebounceDispatchAction(newValue);
  };

  return (
    <SliderDropdown
      dropdownLabel={textConstants.PRICE_DROPDOWN_LABEL_TEXT}
      sliderHeader={textConstants.PRICE_SLIDER_HEADER_TEXT}
      maxValue={maximumPrice}
      minValue={maximumPrice === minimumPrice ? 0 : minimumPrice}
      value={maxPriceFilter}
      setValue={handleChange}
      sliderLabelFormat={(value) => `$${value.toFixed(2)}`}
      showDropdownContentOnly={showDropdownContentOnly}
      className="b2b-shop-filter"
      popoverClassName="b2b"
    />
  );
};
