import { connect, ConnectedProps } from "react-redux";
import { PriceFreezeRefund } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import { setDisplayPriceFreezeRefundModal } from "../../../../../freeze/actions/actions";

const mapStateToProps = (_state: IStoreState) => {
  return {};
};

const mapDispatchToProps = {
  setDisplayPriceFreezeRefundModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeRefundConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceFreezeRefund = connector(PriceFreezeRefund);
