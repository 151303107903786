import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
} from "react";
import { RouteComponentProps } from "react-router";
import {
  CloseButtonIcon,
  ButtonWrap,
  ActionLink,
  ActionButton,
  DefaultStyle,
} from "halifax";
import { TripCategory, VIEWED_DURATION_SLIDER } from "redmond";
import { Box, Dialog, Typography } from "@material-ui/core";
import { PriceFreezeSelectedDurationModalConnectorProps } from "./container";
import * as constants from "../textConstants";
import "./styles.scss";
import {
  PriceFreezeDurationSelection,
  getPriceFreezeDurationSelectionPageProperty,
} from "../../../../freeze/components/priceFreezeDurationComponents";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { PriceFreezeDurationSelectionMethod } from "../../../../freeze/utils";

interface PriceFreezeSelectedDurationButtonProps {
  content: string;
  ariaLabelText?: string;
  buttonStyle?: DefaultStyle;
  onClick: () => void;
}

export interface IPriceFreezeSelectedDurationModalProps
  extends PriceFreezeSelectedDurationModalConnectorProps,
    IPriceFreezeSelectedDurationModalOwnProps,
    RouteComponentProps {}

export interface IPriceFreezeSelectedDurationModalOwnProps {
  // note: this prop is used to overwrite the Continue cta logic that does goToPriceFreezeCheckout
  handleContinue?: () => void;
  priceFreezeSelectedDurationModalOpen: boolean;
  setPriceFreezeSelectedDurationModalOpen: Dispatch<SetStateAction<boolean>>;
  termsProps: PriceFreezeSelectedDurationButtonProps;
  useLockPriceLanguage?: boolean;
  tripCategory?: TripCategory;
  disableGenerateOfferOnChange?: boolean;
}

export const PriceFreezeSelectedDurationModal = (
  props: IPriceFreezeSelectedDurationModalProps
) => {
  const {
    history,
    handleContinue,
    priceFreezeSelectedDurationModalOpen,
    setPriceFreezeSelectedDurationModalOpen,
    termsProps,
    useLockPriceLanguage,
    selectedPriceFreezeOfferCap,
    generateCustomPriceFreezeOffer,
    selectedPriceFreezeOfferData,
    priceFreezeMaxOffer,
    priceFreezeMinOffer,
    tripCategory,
    disableGenerateOfferOnChange,
    priceFreezeUserSelectedDurationSelectionMethod: selectionMethod,
  } = props;

  useEffect(() => {
    if (priceFreezeSelectedDurationModalOpen) {
      trackEvent({
        eventName: VIEWED_DURATION_SLIDER,
        properties: {
          ...getPriceFreezeDurationSelectionPageProperty(history),
        },
      });
    }
  }, [priceFreezeSelectedDurationModalOpen]);

  const handleCloseModal = () => {
    if (selectedPriceFreezeOfferData) {
      // note: it generates a new offer when the user exits this modal
      generateCustomPriceFreezeOffer(selectedPriceFreezeOfferData, history, false);
    }
    setPriceFreezeSelectedDurationModalOpen(false);
  };

  const handleOnContinue = () => {
    handleCloseModal();
    handleContinue && handleContinue();
  };

  const hideDetailsSectionTopCriteria = {
    [PriceFreezeDurationSelectionMethod.Buttons]: true,
    [PriceFreezeDurationSelectionMethod.Slider]: false,
    [PriceFreezeDurationSelectionMethod.Toggle]: false,
  };

  const hideDetailsSectionTop = hideDetailsSectionTopCriteria[selectionMethod];

  const hideDetailsSectionBottomCriteria = {
    [PriceFreezeDurationSelectionMethod.Buttons]: true,
    [PriceFreezeDurationSelectionMethod.Slider]: true,
    [PriceFreezeDurationSelectionMethod.Toggle]: true,
  };

  const hideDetailsSectionBottom =
    hideDetailsSectionBottomCriteria[selectionMethod];

  const renderPriceFreezeSelectedDurationSlider = () => {
    const buttons: PriceFreezeSelectedDurationButtonProps[] = [
      {
        content: constants.PRICE_FREEZE_SELCTED_DURATION_BUTTON_TEXT,
        ariaLabelText:
          constants.PRICE_FREEZE_SELCTED_DURATION_BUTTON_LABEL(
            useLockPriceLanguage
          ),
        buttonStyle: "h4r-primary",
        onClick: () => {
          handleOnContinue();
        },
      },
    ];

    return (
      <Box className="desktop-price-freeze-selected-duration-container">
        <Box className="desktop-price-freeze-duration-details-root">
          <Box className="desktop-price-freeze-duration-details-container">
            <Box className="header-section">
              <Typography className="title" variant="h2">
                {constants.PRICE_FREEZE_CHOOSE_DURATION_TITLE}
              </Typography>
              <Typography className="subtitle" variant="body2">
                {constants.PRICE_FREEZE_CHOOSE_DURATION_SUBTITLE(
                  priceFreezeMinOffer?.secondsTtl,
                  priceFreezeMaxOffer?.secondsTtl,
                )}
              </Typography>
            </Box>
            <Box className="duration-selection-section">
              <PriceFreezeDurationSelection
                textSize="large"
                hideDetailsSectionTop={hideDetailsSectionTop}
                hideDetailsSectionBottom={hideDetailsSectionBottom}
                disableGenerateOfferOnChange={disableGenerateOfferOnChange}
                selectionMethod={selectionMethod}
              />
            </Box>
            <Box className="footer-section">
              <Typography className="footer-copy" variant="body2">
                {constants.CHOOSE_DURATION_FOOTER(
                  tripCategory === TripCategory.ROUND_TRIP
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="coverage-section">
          <Typography
            className="coverage-copy"
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: constants.CHOOSE_DURATION_COVERAGE(
                `${selectedPriceFreezeOfferCap?.fiat.currencySymbol}${selectedPriceFreezeOfferCap?.fiat.value}`
              ),
            }}
          />
        </Box>
        <Box className="bottom-section">
          <ActionLink
            className="price-freeze-terms-link"
            onClick={termsProps.onClick}
            content={
              <Typography variant="inherit" className="terms">
                {termsProps.content}
              </Typography>
            }
            label={termsProps.ariaLabelText}
          />
          <Box className="buttons-section">
            {buttons.map((buttonProps, index) => (
              <ActionButton
                key={index}
                className="price-freeze-selected-duration-button"
                onClick={buttonProps.onClick}
                disabled={false}
                message={buttonProps.content}
                defaultStyle={buttonProps.buttonStyle ?? "h4r-primary"}
                ariaLabelText={buttonProps.ariaLabelText}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const PriceFreezeSelectedDurationPopup = (
    <Dialog
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      className="desktop-price-freeze-selected-duration-popup"
      open={priceFreezeSelectedDurationModalOpen}
      role="dialog"
      onClose={handleCloseModal}
    >
      <Box className="top-button-right">
        <ButtonWrap
          aria-label="Close"
          onClick={handleCloseModal as MouseEventHandler<HTMLButtonElement>}
        >
          <CloseButtonIcon />
        </ButtonWrap>
      </Box>
      {renderPriceFreezeSelectedDurationSlider()}
    </Dialog>
  );

  return PriceFreezeSelectedDurationPopup;
};
