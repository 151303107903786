import React from "react";

export const POLICY_FILTER_LABEL = (appliedLabel?: string) =>
  !!appliedLabel ? (
    <>
      <span className="filter-name">Policy status:</span> {appliedLabel}
    </>
  ) : (
    <span className="filter-name">Policy status</span>
  );
export const POLICY_FILTER_OPTION = "In policy only";
