import { Box, Switch, Typography, withStyles } from "@material-ui/core";
import { ButtonWrap, Icon, IconName } from "halifax";
import React from "react";
import { colors } from "../../../../../../../../utils/colors";
import "./styles.scss";
import { BAGGAGE_TRANSFERS, EXCLUDE } from "./textConstants";
import { trackEvent } from "../../../../../../../../api/v0/analytics/trackEvent";
import { generateFilterTrackingEvent } from "../../../../../../actions/actions";

interface BaggageTransfersFilterProps {
  icon?: IconName;
  title?: string;
  label?: string;
  baggageTransfersFilter: boolean;
  setBaggageTransfersFilter: (value: boolean) => void;
  showDropdownContentOnly?: boolean;
  showButtonWrapOnly?: boolean;
}

const StyledSwitch = withStyles({
  switchBase: {
    // PrivateSwitchBase - default padding size
    padding: "9px",
    "&$checked + $track": {
      backgroundColor: colors["blue-9"],
    },
  },
  track: {
    borderRadius: "16px",
    opacity: 1,
    backgroundColor: colors["grey-8"],
  },
  thumb: {},
  checked: {},
})(Switch);

export const BaggageTransfersFilter: React.FC<BaggageTransfersFilterProps> = ({
  title,
  icon,
  label,
  baggageTransfersFilter,
  setBaggageTransfersFilter,
  showDropdownContentOnly,
  showButtonWrapOnly,
}) => {
  const showFilter = showDropdownContentOnly && title && icon && label;
  return (
    <Box className="baggage-transfers-filter-container" tabIndex={0}>
      {showFilter && (
        <>
          <Box className="header-container">
            <Icon name={icon} />
            <Box className="titles-wrapper">{title}</Box>
          </Box>
          <Box className="baggage-transfers-filter-switch-container">
            <StyledSwitch
              checked={baggageTransfersFilter}
              onChange={(e) => {
                trackEvent(
                  generateFilterTrackingEvent("baggage_transfer", "list")
                );
                setBaggageTransfersFilter(e.target.checked);
              }}
              focusVisibleClassName={
                "baggage-transfers-filter-switch-focus-visible"
              }
              disableRipple
              className={"baggage-transfers-filter-switch"}
              classes={{
                thumb: "baggage-transfers-filter-switch-thumb",
                track: baggageTransfersFilter
                  ? "baggage-transfers-filter-switch-track-checked"
                  : "baggage-transfers-filter-switch-track",
                switchBase: "baggage-transfers-filter-switch-base",
              }}
            />
            <Typography
              variant="body1"
              className="baggage-transfers-filter-switch-container-label"
            >
              {label}
            </Typography>
          </Box>
        </>
      )}
      {showButtonWrapOnly && (
        <ButtonWrap
          className="button-wrap slider-dropdown-button b2b-shop-filter b2b-shop-filter has-value filter-experiment"
          onClick={() => setBaggageTransfersFilter(true)}
        >
          <span>
            <b>{BAGGAGE_TRANSFERS}</b> {EXCLUDE}
            <Icon className="icon-end" name={IconName.XCircle} />
          </span>
        </ButtonWrap>
      )}
    </Box>
  );
};
