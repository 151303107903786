import * as React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
import { CalendarPickerButton } from "../FlightSearchControl/components";
import {
  OriginAutocomplete,
  DestinationAutocomplete,
} from "../FlightSearchControl/components/TerminusAutocomplete";
import { FlightShopSearchFilter } from "./components/FlightShopSearchFilter";
import { AppliedFilterTags } from "./components/AppliedFilterTags";
import { FlightSearchButton } from "../SearchButton";
import * as textConstants from "./textConstants";
import { RouteComponentProps } from "react-router";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../../../shop/utils/parseQueryString";
import { FlightShopSearchControlConnectorProps } from "./container";
import { Icon, IconName } from "halifax";
import { isEqual } from "lodash-es";
import { ITripTerminus, RegionType } from "redmond";
import dayjs from "dayjs";
import { FlightShopStep } from "../../../shop/reducer";
export interface IFlightShopSearchControlProps
  extends RouteComponentProps,
    FlightShopSearchControlConnectorProps {}

export const FlightShopSearchControl = (
  props: IFlightShopSearchControlProps
) => {
  const {
    departureDate,
    destination,
    destinationCode,
    fetchDepartureCalendar,
    fetchTripSummaries,
    history,
    origin,
    originCode,
    returnDate,
    setCalendar,
    setDepartureDate,
    setDestination,
    setOrigin,
    setReturnDate,
    tripCategory,
    resetAll,
    disabled,
    setFlightShopProgress,
  } = props;
  const extractCode = (terminus: ITripTerminus | null) =>
    terminus && terminus.id && terminus.id.code ? terminus.id.code.code : "";
  const [hasChangedSearchParams, setHasChangedSearchParams] =
    React.useState(false);
  const [org, setOrg] = React.useState(origin);
  const [dest, setDest] = React.useState(destination);
  const parsedSearchParams = parseQueryString(
    history
  ) as IFlightShopParsedQuery;
  const {
    destination: searchDest,
    origin: searchOrigin,
    returnDate: searchReturn,
    departureDate: searchDeparture,
  } = parsedSearchParams;

  React.useEffect(() => {
    !isEqual(org, origin) && setOrg(origin);
  }, [origin]);
  React.useEffect(() => {
    !isEqual(dest, destination) && setDest(destination);
  }, [destination]);
  React.useEffect(() => {
    const originCode = extractCode(org);
    const destinationCode = extractCode(dest);
    const hasState = destinationCode && originCode && departureDate?.toString();

    if (
      hasState &&
      ((searchDest && searchDest !== destinationCode) ||
        (searchOrigin && searchOrigin !== originCode) ||
        (searchDeparture &&
          !dayjs(departureDate).isSame(dayjs(searchDeparture), "day")) ||
        (searchReturn && !dayjs(returnDate).isSame(dayjs(searchReturn), "day")))
    ) {
      setHasChangedSearchParams(true);
    } else {
      setHasChangedSearchParams(false);
    }
  }, [
    searchDest,
    searchOrigin,
    searchReturn,
    searchDeparture,
    org,
    dest,
    returnDate,
    departureDate,
  ]);

  React.useEffect(() => {
    if (originCode && destinationCode) {
      fetchDepartureCalendar(org as ITripTerminus, dest as ITripTerminus);
    } else {
      setCalendar();
    }
  }, [originCode, destinationCode, tripCategory]);

  return (
    <>
      <Box className="flight-shop-search-root">
        <Box className="flight-shop-search-container">
          <Box className="flight-shop-search">
            <Box className="location-and-date-pickers">
              <OriginAutocomplete
                className="origin-auto-complete b2b"
                customIcon={
                  <Icon
                    name={IconName.B2BMapPin}
                    ariaLabel=""
                    aria-hidden={true}
                  />
                }
                label={textConstants.WHERE_FROM}
                value={org}
                setValue={(value) => {
                  setOrg(value);
                  setOrigin(value);
                }}
                getOptionSelected={(
                  option: ITripTerminus | null,
                  value: ITripTerminus | null
                ) => {
                  return (
                    !!value &&
                    !!option &&
                    value.id.code.code === option.id.code.code
                  );
                }}
                disabled={disabled}
              />
              <DestinationAutocomplete
                className="destination-auto-complete b2b"
                customIcon={
                  <Icon
                    name={IconName.B2BMapPin}
                    ariaLabel=""
                    aria-hidden={true}
                  />
                }
                label={textConstants.WHERE_TO}
                value={dest}
                setValue={(value) => {
                  setDest(value);
                  setDestination(value);
                }}
                getOptionSelected={(
                  option: ITripTerminus | null,
                  value: ITripTerminus | null
                ) => {
                  return (
                    !!value &&
                    !!option &&
                    value.id.code.code === option.id.code.code
                  );
                }}
                disabled={disabled}
              />
              <CalendarPickerButton
                departureDate={departureDate}
                returnDate={returnDate}
                classes={["departure-date-input"]}
                tripCategory={tripCategory}
                setDepartureDate={setDepartureDate}
                setReturnDate={setReturnDate}
              />
            </Box>
            <Box className="filter-pickers">
              <FlightShopSearchFilter
                hideAirportFilter={
                  origin?.id.code.regionType === RegionType.Airport
                }
              />
            </Box>
            <Box className="filter-reset-chips">
              <AppliedFilterTags />
            </Box>
          </Box>
        </Box>
        <Box className={"search-button"}>
          {hasChangedSearchParams && !disabled && (
            <FlightSearchButton
              className={clsx("flight-search-button", "b2b")}
              message={textConstants.SEARCH_AGAIN}
              onClick={(history) => {
                setDestination(dest);
                setOrigin(org);
                setDepartureDate(departureDate);
                setReturnDate(returnDate);
                fetchTripSummaries(history, false);
                setHasChangedSearchParams(false);
                setFlightShopProgress(FlightShopStep.PricePrediction);
                resetAll();
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
