import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import {
  tripDetailsLoadingSelector,
  tripSummariesLoadingSelector,
  tripSummariesErrorSelector,
  orderedAndFilteredFlightIdsSelector,
  flightShopProgressSelector,
  tripIdsByReturnSliceSelector,
  hasSetNonFareclassFiltersSelector,
  selectedTripSelector,
  getSelectedOutgoingSliceProperties,
  getSelectedReturnSliceProperties,
  getTravelOfferProperties,
  flightFareDetailsSelector,
} from "../../reducer/selectors";
import {
  setChosenOutgoingSlice,
  setChosenReturnSlice,
  setFlightShopProgress,
  setOpenFlightShopCalendarDesktop,
  fetchTripDetails,
  ISetChosenOutgoingSliceParams,
  ISetChosenReturnSliceParams,
} from "../../actions/actions";
import { FlightList } from "./component";
import { FlightShopStep } from "../../reducer";
import { TripCategory, FareDetails } from "redmond";
import {
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getTripCategory,
  IFlightNumberFilter,
  initialFilterOptions,
} from "../../../search/reducer";
import { actions as shopActions } from "../../../search/actions";
import { withRouter } from "react-router";
import {
  flightInfoSelector,
  getViewedFlightListProperties,
  cfarDiscountPropertiesSelector,
} from "../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  const flightInfoById = (tripId: string) => flightInfoSelector(state, tripId);

  return {
    flightShopProgress: flightShopProgressSelector(state),
    selectedTrip: selectedTripSelector(state),
    tripSummariesLoading: tripSummariesLoadingSelector(state),
    tripDetailsLoading: tripDetailsLoadingSelector(state),
    hasTripSummariesError: tripSummariesErrorSelector(state),
    sortedFlightIds: orderedAndFilteredFlightIdsSelector(state),
    tripIdsByReturnSlice: tripIdsByReturnSliceSelector(state),
    isReturn: flightShopProgressSelector(state) === FlightShopStep.ChooseReturn,
    isOneWay: getTripCategory(state) === TripCategory.ONE_WAY,
    hasAppliedFareClassFilter: getHasSetFareClassFilter(state),
    hasAppliedNonFareclassFilter: hasSetNonFareclassFiltersSelector(state),
    flightInfoById,
    viewedForecastProperties: getViewedFlightListProperties(state),
    selectedOutgoingSliceProperties: getSelectedOutgoingSliceProperties(state),
    selectedReturnSliceProperties: getSelectedReturnSliceProperties(state),
    fareClassOptionFilter: getFareclassOptionFilter(state),
    travelOfferProperties: getTravelOfferProperties(state),
    getFlightFareDetailsByTripId: (tripId: string) =>
      flightFareDetailsSelector(state, tripId),
    cfarDiscountProperties: cfarDiscountPropertiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setChosenOutgoingSlice: (
      chosenOutGoingSliceParams: ISetChosenOutgoingSliceParams
    ) => dispatch(setChosenOutgoingSlice(chosenOutGoingSliceParams)),
    setChosenReturnSlice: (
      chosenReturnSliceParams: ISetChosenReturnSliceParams
    ) => dispatch(setChosenReturnSlice(chosenReturnSliceParams)),
    setFlightShopProgress: (progress: FlightShopStep) =>
      dispatch(setFlightShopProgress(progress)),
    fetchTripDetails: (args: {
      tripId: string;
      filterFareDetail?: (fare: FareDetails) => boolean;
    }) => dispatch(fetchTripDetails(args)),
    setFlightNumberFilter: (flightNumberFilter: IFlightNumberFilter[]) =>
      dispatch(shopActions.setFlightNumberFilter(flightNumberFilter)),
    setAirportFilter: (airportFilter: string[]) =>
      dispatch(shopActions.setAirportFilter(airportFilter)),
    setPolicyFilter: (policyFilter: boolean) =>
      dispatch(shopActions.setPolicyFilter(policyFilter)),
    setOpenFlightShopCalendarDesktop: (open: boolean) =>
      dispatch(setOpenFlightShopCalendarDesktop(open)),
    resetAll: () => {
      dispatch(
        shopActions.setFareclassOptionFilter({
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        })
      );
      dispatch(shopActions.setStopsOption(initialFilterOptions.stopsOption));
      dispatch(
        shopActions.setAirlineFilter(initialFilterOptions.airlineFilter)
      );
      dispatch(
        shopActions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter)
      );
      dispatch(
        shopActions.setAirportFilter(initialFilterOptions.airportFilter)
      );
      dispatch(shopActions.setPolicyFilter(initialFilterOptions.policyFilter));
      dispatch(
        shopActions.setFlightNumberFilter(
          initialFilterOptions.flightNumberFilter
        )
      );
      dispatch(
        shopActions.setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        shopActions.setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        shopActions.setReturnDepartureTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        shopActions.setReturnArrivalTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FlightList));
