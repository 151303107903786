import * as React from "react";
import {
  AirlineCode,
  Airport,
  IFlightNumberFilter,
  ITimeRange,
  ITripTerminus,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import {
  IAirlineOptions,
  IShopFilterSelector,
} from "../../../../../../../shop/reducer";
import { FareclassOptionFilter } from "../../../../../../reducer";
import { FilterLabelValues } from "../../../../../FlightShopSearchControl/useFilterLabelValues";
import {
  StopsOptionSelection,
  MaxPriceFilterSelection,
  DepartureArrivalSelectionDropdown,
  AirlineFilterSelection,
  AirportFilterSelection,
  FlightNumberFilterSelection,
  FareclassOptionSelection,
  BaggageTransfersFilter,
  DurationSelection,
} from "../index";
import {
  AirCXV3VariantType,
  CONTROL,
} from "../../../../../../../../context/experiments";
import { ButtonWrap } from "halifax";
import { RESET_FILTERS_BUTTON_LABEL } from "./textConstants";
import { config } from "../../../../../../../../api/config";
import { isCorpTenant } from "@capone/common";
import { PolicyFilterSelection } from "../capone-corporate/PolicyFilterSelection";

export interface IDesktopFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
  hideMaxPriceFilter?: boolean;
  flightShopFilters: IShopFilterSelector;
  isFlightListOptimizationExperiment?: boolean;
  filterLabelValues?: FilterLabelValues;
  airlinesToDisplay?: IAirlineOptions[];
  stopsOption: SliceStopCountFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => void;
  setRerunPrediction: () => void;
  hasSetStopsOption: boolean;
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  hasSetAirlineFilter: boolean;
  airportFilter: string[];
  setAirportFilter: (airportFilter: string[]) => void;
  maxPriceFilter: number;
  hasSetMaxPrice: boolean;
  setMaxPriceFilter: (maxPriceFilter: number) => void;
  flightNumberFilter: IFlightNumberFilter[];
  departureDateString: string;
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
  tripCategory: TripCategory;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  hasSetTimeRange: boolean;
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ) => void;
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) => void;
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) => void;
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) => void;
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
  hasSetFareclassFilter: boolean;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  originAirport?: Airport;
  destinationAirport?: Airport;
  flightNumberAirlineFilter: string;
  setFlightNumberAirlineFilter: (airline: string) => void;
  hasBaggageTransfersFilter: boolean;
  setBaggageTransfersFilter: (value: boolean) => void;
  airCXV3Variant?: AirCXV3VariantType;
  durationFilter: number;
  setDurationFilter: (duration: number) => void;
  minDuration: number;
  maxDuration: number;
  policyFilter: boolean;
  setPolicyFilter: (policyFilter: boolean) => void;
  filteredFlightCount: number;
  resetAllFilters: () => void;
  hasActiveFilters: boolean;
  showClearFiltersButton?: boolean;
}

export const DesktopFlightShopSearchFilter = (
  props: IDesktopFlightShopSearchFilterProps
) => {
  const {
    hideAirportFilter,
    hideMaxPriceFilter,
    flightShopFilters,
    isFlightListOptimizationExperiment,
    filterLabelValues,
    airlinesToDisplay,
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    hasSetStopsOption,
    airlineFilter,
    setAirlineFilter,
    hasSetAirlineFilter,
    airportFilter,
    setAirportFilter,
    maxPriceFilter,
    setMaxPriceFilter,
    hasSetMaxPrice,
    flightNumberFilter,
    departureDateString,
    setFlightNumberFilter,
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    hasSetTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    fareclassOptionFilter,
    setFareclassOptionFilter,
    hasSetFareclassFilter,
    origin,
    destination,
    originAirport,
    destinationAirport,
    flightNumberAirlineFilter,
    setFlightNumberAirlineFilter,
    hasBaggageTransfersFilter,
    setBaggageTransfersFilter,
    airCXV3Variant,
    durationFilter,
    setDurationFilter,
    minDuration,
    maxDuration,
    setPolicyFilter,
    policyFilter,
    filteredFlightCount,
    resetAllFilters,
    hasActiveFilters,
    showClearFiltersButton = true,
  } = props;

  const {
    priceMax,
    priceMin,
    airlineOptions,
    airportOptions,
    flightNumbersByAirline,
  } = flightShopFilters;

  const isAirCXV3Experiment = airCXV3Variant
    ? airCXV3Variant !== CONTROL
    : false;

  const isCorporate = isCorpTenant(config.TENANT);

  return isFlightListOptimizationExperiment || isAirCXV3Experiment ? (
    <>
      <FareclassOptionSelection
        fareclassOptionFilter={fareclassOptionFilter}
        setFareclassOptionFilter={setFareclassOptionFilter}
        hasSetFareclassFilter={hasSetFareclassFilter}
        appliedLabel={filterLabelValues?.fareclassLabel}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        isAirCXV3Experiment={isAirCXV3Experiment}
      />
      <StopsOptionSelection
        stopsOption={stopsOption}
        setStopsOption={setStopsOption}
        setRerunPrediction={setRerunPrediction}
        hasSetStopsOption={hasSetStopsOption}
        appliedLabel={filterLabelValues?.stopsLabel}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        airCXV3Variant={airCXV3Variant}
      />
      <AirlineFilterSelection
        airlineFilter={airlineFilter}
        setAirlineFilter={setAirlineFilter}
        hasSetAirlineFilter={hasSetAirlineFilter}
        allAirlines={airlinesToDisplay || airlineOptions}
        appliedLabel={filterLabelValues?.airlineLabel}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        isAirCXV3Experiment={isAirCXV3Experiment}
      />
      <DepartureArrivalSelectionDropdown
        appliedLabel={filterLabelValues?.timeLabel}
        tripCategory={tripCategory}
        outboundDepartureTimeRange={outboundDepartureTimeRange}
        outboundArrivalTimeRange={outboundArrivalTimeRange}
        returnDepartureTimeRange={returnDepartureTimeRange}
        returnArrivalTimeRange={returnArrivalTimeRange}
        hasSetTimeRange={hasSetTimeRange}
        setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
        setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
        setReturnDepartureTimeRange={setReturnDepartureTimeRange}
        setReturnArrivalTimeRange={setReturnArrivalTimeRange}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        origin={origin}
        destination={destination}
        originAirport={originAirport}
        destinationAirport={destinationAirport}
        isAirCXV3Experiment={isAirCXV3Experiment}
      />
      {!hideMaxPriceFilter && (
        <MaxPriceFilterSelection
          maximumPrice={priceMax.value}
          minimumPrice={priceMin.value}
          appliedLabel={filterLabelValues?.priceLabel}
          maxPriceFilter={maxPriceFilter}
          setMaxPriceFilter={setMaxPriceFilter}
          hasSetMaxPrice={hasSetMaxPrice}
          isFlightListOptimizationExperiment={
            isFlightListOptimizationExperiment
          }
          isAirCXV3Experiment={isAirCXV3Experiment}
        />
      )}
      {!hasBaggageTransfersFilter && (
        <BaggageTransfersFilter
          baggageTransfersFilter={hasBaggageTransfersFilter}
          showButtonWrapOnly
          setBaggageTransfersFilter={setBaggageTransfersFilter}
        />
      )}
      {isAirCXV3Experiment && (
        <>
          <DurationSelection
            value={durationFilter}
            setValue={setDurationFilter}
            minValue={minDuration}
            maxValue={maxDuration}
            flightCount={filteredFlightCount}
            appliedLabel={filterLabelValues?.durationLabel}
          />
          {isCorporate && (
            <PolicyFilterSelection
              appliedLabel={filterLabelValues?.policyLabel}
              policyFilter={policyFilter}
              setPolicyFilter={setPolicyFilter}
            />
          )}
          {hasActiveFilters && showClearFiltersButton && (
            <ButtonWrap
              onClick={() => resetAllFilters()}
              className="clear-filters-button"
            >
              {RESET_FILTERS_BUTTON_LABEL}
            </ButtonWrap>
          )}
        </>
      )}
    </>
  ) : (
    <>
      <StopsOptionSelection
        stopsOption={stopsOption}
        setStopsOption={setStopsOption}
        setRerunPrediction={setRerunPrediction}
        hasSetStopsOption={hasSetStopsOption}
      />
      {!hideMaxPriceFilter && (
        <MaxPriceFilterSelection
          maximumPrice={priceMax.value}
          minimumPrice={priceMin.value}
          maxPriceFilter={maxPriceFilter}
          setMaxPriceFilter={setMaxPriceFilter}
          hasSetMaxPrice={hasSetMaxPrice}
        />
      )}
      <AirlineFilterSelection
        allAirlines={airlineOptions}
        airlineFilter={airlineFilter}
        setAirlineFilter={setAirlineFilter}
        hasSetAirlineFilter={hasSetAirlineFilter}
      />
      <DepartureArrivalSelectionDropdown
        tripCategory={tripCategory}
        outboundDepartureTimeRange={outboundDepartureTimeRange}
        outboundArrivalTimeRange={outboundArrivalTimeRange}
        returnDepartureTimeRange={returnDepartureTimeRange}
        returnArrivalTimeRange={returnArrivalTimeRange}
        hasSetTimeRange={hasSetTimeRange}
        setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
        setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
        setReturnDepartureTimeRange={setReturnDepartureTimeRange}
        setReturnArrivalTimeRange={setReturnArrivalTimeRange}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
      />
      {!hideAirportFilter && (
        <AirportFilterSelection
          allAirports={airportOptions}
          airportFilter={airportFilter}
          setAirportFilter={setAirportFilter}
        />
      )}
      <FlightNumberFilterSelection
        allAirlines={airlineOptions}
        flightNumbersByAirline={flightNumbersByAirline}
        flightNumberFilter={flightNumberFilter}
        departureDateString={departureDateString}
        setFlightNumberFilter={setFlightNumberFilter}
        flightNumberAirlineFilter={flightNumberAirlineFilter}
        setFlightNumberAirlineFilter={setFlightNumberAirlineFilter}
      />
      {isCorporate && (
        <PolicyFilterSelection
          policyFilter={policyFilter}
          setPolicyFilter={setPolicyFilter}
        />
      )}
    </>
  );
};
