import {
  GetExerciseEligibilitiesResponseV1,
  GetExerciseEligibilitiesRequestV1Enum,
} from "redmond";
import { put } from "redux-saga/effects";
import { getFlightDisruptions } from "../../../../api/v1/disruptions/getFlightDisruptions";

import { actions } from "../../actions";

export function* fetchFlightDisruptionsSaga(
  action: actions.IFetchFlightDisruptionsByItineraryId
) {
  try {
    const response: GetExerciseEligibilitiesResponseV1 =
      yield getFlightDisruptions({
        itineraryIds: [action.itineraryId],
        GetExerciseEligibilitiesRequestV1:
          GetExerciseEligibilitiesRequestV1Enum.ByItineraryIds,
      });

    yield put(actions.setFlightDisruptions(response));
  } catch (e) {
    yield put(actions.setFetchFlightDisruptionsCallStateFailed());
  }
}
