import React, { useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  Header,
  BackButton,
  MobileFloatingButton,
  MobilePopoverCard,
  emailRegex,
} from "halifax";
import clsx from "clsx";

import { MobileFlightFreezeWorkflowConnectorProps } from "./container";
import { FlightFreezeReviewItinerary } from "../FlightFreezeReviewItinerary";
import { FlightFreezeSummary } from "../FlightFreezeSummary";
import { FlightFreezeTravelers } from "../FlightFreezeTravelers";
import { FlightFreezeContactInfoForm } from "../FlightFreezeContactInfoForm";
import { FlightFreezePriceBreakdownDropdown } from "../FlightFreezePriceBreakdownDropdown";
import { PaymentCard } from "../../../book/components";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { PATH_FREEZE_CONFIRMATION } from "../../../../utils/urlPaths";
import { FlightFreezePriceBreakdown } from "../FlightFreezePriceBreakdown";
import {
  AVAILABLE,
  PRICE_FREEZE_TRAVEL_CREDITS,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export enum MobileFlightFreezeWorkflowStep {
  Start,
  RewardsAndPayment,
  Review,
}

export interface IMobileFlightFreezeWorkflowProps
  extends RouteComponentProps,
    MobileFlightFreezeWorkflowConnectorProps {
  useLockPriceLanguage?: boolean;
  disableChangeFlight: boolean;
}

export const MobileFlightFreezeWorkflow = ({
  isBookingValid,
  history,
  contactInfo,
  isConfirmationEmailValid,
  rewardsPaymentAccountReferenceId,
  schedulePayment,
  finalizedPriceFreeze,
  useLockPriceLanguage,
  disableChangeFlight,
  fetchApplicableTravelWalletItems,
  isPriceFreezeTravelCreditPaymentOnly,
}: IMobileFlightFreezeWorkflowProps) => {
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(
    !emailRegex.test(contactInfo.email)
  );
  const [purchaseStep, setPurchaseStep] =
    useState<MobileFlightFreezeWorkflowStep>(
      MobileFlightFreezeWorkflowStep.Start
    );

  const expState = useExperiments();

  const isPriceFreezePurchase = true;

  const openPaymentCardSection = () => setOpenPaymentCard(true);

  const handleCloseModal = () => setOpenPaymentCard(false);

  const handlePurchase = () =>
    schedulePayment({
      ancillaries: [],
      agentFee: undefined,
      isPriceFreezePurchase,
    });

  const incrementPurchaseStep = () => {
    setPurchaseStep((step) => {
      switch (step) {
        case MobileFlightFreezeWorkflowStep.Review:
          handlePurchase();
          return step;
        default:
          return step + 1;
      }
    });
  };

  const handleGoBack = () => {
    switch (purchaseStep) {
      case MobileFlightFreezeWorkflowStep.Start:
        history.goBack();
        break;
      default:
        setPurchaseStep((step) => step - 1);
        break;
    }
  };

  const getFloatingButtonText = () => {
    switch (purchaseStep) {
      case MobileFlightFreezeWorkflowStep.Review:
        return textConstants.FLIGHT_FREEZE_COMPLETE_BUTTON_TEXT(
          useLockPriceLanguage
        );
      default:
        return textConstants.FLIGHT_FREEZE_CONTINUE_BUTTON_TEXT;
    }
  };

  const isFloatingButtonDisabled = () => {
    switch (purchaseStep) {
      case MobileFlightFreezeWorkflowStep.Review:
        return !isConfirmationEmailValid;
      default:
        return false;
    }
  };

  const closeAllPopovers = () => {
    handleCloseModal();
  };

  React.useEffect(() => {
    switch (purchaseStep) {
      case MobileFlightFreezeWorkflowStep.RewardsAndPayment:
        openPaymentCardSection();
        break;
      default:
        closeAllPopovers();
        break;
    }
  }, [purchaseStep]);

  // note: when the user is at Review step, but rewardsPaymentAccountReferenceId is null / undefined,
  // let the user go to the previous step
  React.useEffect(() => {
    if (
      purchaseStep === MobileFlightFreezeWorkflowStep.Review &&
      !rewardsPaymentAccountReferenceId &&
      !isPriceFreezeTravelCreditPaymentOnly
    ) {
      handleGoBack();
    }
  }, [purchaseStep, rewardsPaymentAccountReferenceId]);

  React.useEffect(() => {
    if (finalizedPriceFreeze) {
      history.push(PATH_FREEZE_CONFIRMATION);
    }
  }, [finalizedPriceFreeze]);

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );
  const priceFreezeTravelCredits = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE_TRAVEL_CREDITS
  );
  const isPriceFreezeTravelCreditsAvailable = useMemo(
    () => priceFreezeTravelCredits === AVAILABLE,
    [priceFreezeTravelCredits]
  );

  React.useEffect(() => {
    isTravelWalletOfferExperiment &&
      isPriceFreezeTravelCreditsAvailable &&
      fetchApplicableTravelWalletItems();
  }, []);

  return (
    <>
      <Box className="mobile-flight-freeze-workflow-root">
        <Header
          className="mobile-flight-freeze-workflow-header static-header"
          left={
            <BackButton
              className="mobile-flight-freeze-header-go-back"
              onClick={handleGoBack}
            />
          }
          center={
            purchaseStep === MobileFlightFreezeWorkflowStep.Start ? (
              <Typography variant="body1" className="freeze-price-header">
                {textConstants.FLIGHT_FREEZE_HEADER}
              </Typography>
            ) : undefined
          }
          right={
            purchaseStep === MobileFlightFreezeWorkflowStep.Review ? (
              <FlightFreezePriceBreakdownDropdown />
            ) : undefined
          }
          isMobile={true}
          fullWidth={true}
        />
        <FlightFreezeSummary
          isMobile
          displayPriceFreezeChangesContent
          useLockPriceLanguage={useLockPriceLanguage}
          isFromFlightShopReviewItinerary={true}
          setPurchaseStep={setPurchaseStep}
          purchaseStep={purchaseStep}
        >
          <Box className="mobile-travelers-container">
            <FlightFreezeTravelers
              isMobile
              useLockPriceLanguage={useLockPriceLanguage}
            />
          </Box>
          <Box className="mobile-contact-info-form-container">
            <FlightFreezeContactInfoForm
              title={textConstants.CONTACT_INFORMATION}
              isDisableSave={isDisableSave}
              setIsDisableSave={setIsDisableSave}
            />
          </Box>
        </FlightFreezeSummary>
        <Box
          className={clsx(
            "mobile-flight-freeze-content-section",
            "review-itinerary"
          )}
        >
          <FlightFreezeReviewItinerary
            title={textConstants.REVIEW_ITINERARY_TITLE}
            isMobile={true}
            disableChangeFlight={disableChangeFlight}
          />
        </Box>
        {purchaseStep === MobileFlightFreezeWorkflowStep.Review && (
          <Box
            className={clsx(
              "mobile-flight-freeze-content-section",
              "price-breakdown"
            )}
          >
            <FlightFreezePriceBreakdown />
          </Box>
        )}
        {!isDisableSave && (
          <Box className="flight-freeze-button-section">
            <MobileFloatingButton
              className="flight-freeze-button"
              wrapperClassName="b2b"
              disabled={isFloatingButtonDisabled()}
              onClick={incrementPurchaseStep}
            >
              {getFloatingButtonText()}
            </MobileFloatingButton>
          </Box>
        )}
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={handleCloseModal}
        onConfirm={incrementPurchaseStep}
        onGoBack={handleGoBack}
        mobileHeaderElement={<FlightFreezePriceBreakdownDropdown />}
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("price-freeze-flight-payment-card-popup", "b2b")}
      fullScreen={true}
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="price-freeze-flight-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
      fullScreenWithBanner={true}
    >
      <PaymentCard isPriceFreezePurchase={true} />
      <MobileFloatingButton
        className="price-freeze-flight-book-review-trip-button"
        onClick={onConfirm}
        wrapperClassName="b2b"
        disabled={disabled}
      >
        {textConstants.REVIEW_MY_FROZEN_PRICE_TEXT}
      </MobileFloatingButton>
    </MobilePopoverCard>
  );
};
