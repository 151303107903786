import React, { useContext, useEffect, useRef } from "react";
import { Header } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { ClientContext } from "../../../../App";
import { RewardsAccountSelection } from "../RewardsAccountSelection";
import { DesktopRewardsHeaderConnectorProps } from "./container";
import { PATH_HOME } from "../../../../utils/urlPaths";
import "./styles.scss";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import clsx from "clsx";

export interface IDesktopRewardsHeaderProps
  extends DesktopRewardsHeaderConnectorProps,
    RouteComponentProps {
  title: string;
  subtitle: string;
}

export const DesktopRewardsHeader = (props: IDesktopRewardsHeaderProps) => {
  const { history, title, subtitle, step } = props;
  const clientContext = useContext(ClientContext);
  const expState = useExperiments();
  const titleRef = useRef<HTMLSpanElement>(null);

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  useEffect(() => {
    if (null !== titleRef.current) {
      titleRef.current.focus();
    }
  }, [step]);

  return (
    <Header
      className="desktop-flight-shop-rewards-header-container"
      left={
        <Box className={"rewards-account-section-left-content"}>
          <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
            {clientContext.logo}
          </Box>
          <Box className={"rewards-account-section-travel-details"}>
            <Typography ref={titleRef} variant={"body1"} tabIndex={0}>{title}</Typography>
            <Typography variant={"body2"}>{subtitle}</Typography>
          </Box>
        </Box>
      }
      right={
        <Box className="desktop-flight-shop-rewards-account-contents">
          <RewardsAccountSelection
            className={clsx("b2b", {
              "hide-balance-border": isTravelWalletCreditsExperiment,
            })}
            popoverClassName="b2b"
          />
          {isTravelWalletCreditsExperiment && <TravelWalletDrawer />}
        </Box>
      }
    />
  );
};
