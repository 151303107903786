import {
  CAP1_DISRUPTION_POLICY_EXERCISED,
  CAP1_REBOOKING_COMPLETE_BUY,
  Cap1DpExerciseFactsProperties,
  Cap1DpExerciseItineraryFactsProperties,
} from "redmond";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";

export const handleDisruptionPolicyExercisedEvent = (
  args: Cap1DpExerciseFactsProperties & Cap1DpExerciseItineraryFactsProperties
) => {
  trackEvent({
    eventName: CAP1_DISRUPTION_POLICY_EXERCISED,
    properties: args,
  });
};

export const handleRebookingCompleteBuyEvent = (
  args: Cap1DpExerciseFactsProperties & Cap1DpExerciseItineraryFactsProperties,
  failure_reason: string | null,
) => {
  trackEvent({
    eventName: CAP1_REBOOKING_COMPLETE_BUY,
    properties: {...args, failure_reason },
  });
};
