import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PriceFreezeDurationRadioGroup } from "./component";
import {
  generateCustomPriceFreezeOffer,
  setSelectedPriceFreezeRadioDataIndex,
} from "../../../../freeze/actions/actions";
import { IStoreState } from "../../../../../reducers/types";
import {
  isPriceFreezeShowDurationsVariant1Selector,
  priceFreezeOfferRadioDurationsSelector,
  selectedPriceFreezeRadioDataIndexSelector,
} from "../../../../freeze/reducer";
import { getSelectedAccountReferenceId } from "../../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceFreezeOfferDurations: priceFreezeOfferRadioDurationsSelector(state),
    selectedPriceFreezeRadioDataIndex:
     selectedPriceFreezeRadioDataIndexSelector(state),
    accountReferenceId: getSelectedAccountReferenceId(state),
    isPriceFreezeShowDurationsVariant1Active:
      isPriceFreezeShowDurationsVariant1Selector(state),
  };
};

const mapDispatchToProps = {
  generateCustomPriceFreezeOffer,
  setSelectedPriceFreezeRadioDataIndex,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezeDurationRadioGroupConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezeDurationRadioGroup = withRouter(
  connector(PriceFreezeDurationRadioGroup)
);
