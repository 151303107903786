import { takeLatest, takeEvery, race, take, call } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchTripDetailsSaga } from "./fetchTripDetailsSaga";
import { fetchTripSummaries } from "./fetchTripSummariesSaga";
import { fetchTripSummariesV2 } from "./fetchTripSummariesV2Saga";
import { fetchTripSummariesV3 } from "./fetchTripSummariesV3Saga";
import { populateFlightShopQueryParamsSaga } from "./populateShopQueryParamsSaga";
import { createWatchSaga } from "./createWatchSaga";
import { fetchWatchesSaga } from "./fetchWatchesSaga";
import { updateWatchSaga } from "./updateWatchSaga";
import { deleteWatchSaga } from "./deleteWatchSaga";
import { fetchSimilarFlightsSaga } from "./fetchSimilarFlightsSaga";
import { fetchAncillaryOfferSaga } from "./fetchAncillaryOfferSaga";
import { fetchTransferSimilarFlightsSaga } from "./fetchTransferToSimilarFlightsSaga";
import { actions } from "../actions";
import { fetchMulticityTripSummaries } from "./fetchMulticityTripSummariesSaga";
import { listPaymentMethodsSaga } from "./listPaymentMethodsSaga";
import { fetchCorpFintechEligibilitySaga } from "./fetchCorpFintechEligibilitySaga";

export function* watchSetUpQueryParams() {
  yield takeLatest(
    actionTypes.POPULATE_FLIGHT_SHOP_QUERY_PARAMS,
    populateFlightShopQueryParamsSaga
  );
}

export function* watchFetchTripSummariesSaga() {
  yield takeLatest(actionTypes.FETCH_TRIP_SUMMARIES, fetchTripSummaries);
}

export function* watchFetchTripSummariesForPredictionSaga() {
  yield takeLatest(
    actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION,
    fetchTripSummaries
  );
}

export function* watchFetchSimilarFlightsSaga() {
  yield takeLatest(actionTypes.FETCH_SIMILAR_FLIGHTS, fetchSimilarFlightsSaga);
}

export function* watchFetchAncillaryOfferSaga() {
  yield takeLatest(actionTypes.FETCH_ANCILLARY_OFFER, fetchAncillaryOfferSaga);
}

export function* watchFetchCorpFintechEligibilitySaga() {
  yield takeLatest(
    actionTypes.FETCH_CORP_FINTECH_ELIGIBILITY,
    fetchCorpFintechEligibilitySaga
  );
}

export function* watchFetchTransferSimilarFlightsSaga() {
  yield takeLatest(
    actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS,
    fetchTransferSimilarFlightsSaga
  );
}

export function* watchFetchTripSummariesV2Saga() {
  yield takeLatest(actionTypes.FETCH_TRIP_SUMMARIES_V2, fetchTripSummariesV2);
}

export function* watchFetchTripSummariesForPredictionV2Saga() {
  yield takeLatest(
    actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2,
    fetchTripSummariesV3
  );
}

export function* watchFetchTripSummariesV3Saga() {
  yield takeLatest(
    actionTypes.FETCH_TRIP_SUMMARIES_V3,
    function* (action: actions.IFetchTripSummariesV3) {
      yield race({
        task: call(fetchTripSummariesV3, action),
        cancel: take(actionTypes.STOP_FETCH_TRIP_SUMMARIES_V3),
      });
    }
  );
}

export function* watchFetchMulticityTripSummariesSaga() {
  yield takeLatest(
    actionTypes.FETCH_MULTICITY_TRIP_SUMMARIES,
    function* (action: actions.IFetchMulticityTripSummaries) {
      yield race({
        task: call(fetchMulticityTripSummaries, action),
        cancel: take(actionTypes.STOP_FETCH_MULTICITY_TRIP_SUMMARIES),
      });
    }
  );
}

export function* watchFetchTripDetailsSaga() {
  yield takeEvery(actionTypes.FETCH_TRIP_DETAILS, fetchTripDetailsSaga);
}

export function* watchCreateWatch() {
  yield takeLatest(actionTypes.CREATE_WATCH, createWatchSaga);
}

export function* watchListWatches() {
  yield takeLatest(actionTypes.LIST_WATCHES, fetchWatchesSaga);
}

export function* watchUpdateWatch() {
  yield takeLatest(actionTypes.UPDATE_WATCH, updateWatchSaga);
}

export function* watchDeleteWatch() {
  yield takeLatest(actionTypes.DELETE_WATCH, deleteWatchSaga);
}

export function* watchListFlightShopPaymentMethods() {
  yield takeLatest(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}
