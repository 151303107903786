import { FlightError } from "@b2bportal/air-booking-api";
import {
  NO_AVAILABILITY_AIR,
  FlightBookType,
  ErrorTitles,
  FiatPrice,
  CallState,
  ErrorModalType,
  PriceFreezeView,
  GeneralPurchaseErrorEnum,
  PaymentPurchaseErrorEnum,
  PurchaseError,
  PaymentErrorEnum,
  UncategorizedPurchaseError,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  getAgentErrorTitleFromPurchaseError,
  getAgentErrorSubtitleFromPurchaseError,
} from "../selectors";
import * as textConstants from "../selectors/textConstants";

export const getErrorTitlesFromPaymentError = ({
  errors,
  numTravelers,
  flightBookType,
  priceFreezePaid,
  priceFreezeTravelers,
  fetchSimilarFlightsCallState,
  isSimilarFlightsEnabled,
  hasSelectedSeats,
  priceFreeze,
}: {
  errors: (PurchaseError | FlightError)[];
  numTravelers: number;
  flightBookType: FlightBookType;
  priceFreezePaid?: FiatPrice;
  priceFreezeTravelers?: number;
  fetchSimilarFlightsCallState: CallState;
  isSimilarFlightsEnabled: boolean;
  hasSelectedSeats: boolean;
  priceFreeze: PriceFreezeView | undefined;
}): ErrorTitles => {
  let agentSubtitle = getAgentErrorSubtitleFromPurchaseError(errors[0]);
  let agentTitle = getAgentErrorTitleFromPurchaseError(errors[0]);
  const errorTitles: ErrorTitles[] = errors.map((error) => {
    let titles: ErrorTitles = {
      title: textConstants.GENERIC_ERROR_TITLE,
      subtitle: textConstants.GENERIC_ERROR_SUBTITLE,
      icon: textConstants.ERROR_ICON,
    };

    const errorCode = (error as UncategorizedPurchaseError).code
      ? (error as UncategorizedPurchaseError).code
      : error;

    switch (errorCode) {
      case FlightError.InvalidCustomer:
      case FlightError.NoAdultPassenger:
      case FlightError.IllegalLapInfantRedress:
      case FlightError.IllegalLapInfantKTN:
      case FlightError.LapInfantTooOld:
      case FlightError.TooManyLapInfants:
      case FlightError.NoContactInformation:
      case FlightError.TooManyPassengers:
      case FlightError.MalformedRedressNumber:
      case FlightError.MalformedKnownTravelerNumber:
      case FlightError.LapInfantsUnsupported:
      case FlightError.SeatedInfantsUnsupported:
      case FlightError.ExpiredPassport:
      case FlightError.MissingPassport:
      case FlightError.InvalidPassengers:
        if (error === FlightError.NoAdultPassenger) {
          titles = {
            type: ErrorModalType.NO_ADULT_PASSENGER,
            title: textConstants.NO_ADULT_PAX_TITLE,
            subtitle: textConstants.NO_ADULT_PAX_SUBTITLE,
            primaryButtonText: textConstants.MODIFY_PAX,
          };
        } else {
          titles = {
            title: textConstants.INVALID_PASSENGERS,
            subtitle: textConstants.GENERIC_ERROR_SUBTITLE,
            primaryButtonText: textConstants.UPDATE_TRAVELERS,
          };
        }
        break;
      case FlightError.InvalidPassportNumber: {
        titles = {
          title: textConstants.INVALID_PASSPORT_NUMBER,
          subtitle: textConstants.UPDATE_PASSPORT_INFO,
          primaryButtonText: textConstants.UPDATE_TRAVELERS,
        };
        break;
      }
      case PaymentPurchaseErrorEnum.RedemptionFailure:
        titles = {
          title: textConstants.REDEMPTION_ISSUE,
          subtitle: textConstants.REDEMPTION_ISSUE_SUTITLE,
          primaryButtonText: textConstants.TRY_AGAIN,
          icon: textConstants.UNABLED_ICON,
        };
        break;
      case FlightError.InvalidPassengers:
        titles = {
          title: textConstants.INVALID_PASSENGERS,
          subtitle: "",
          primaryButtonText: textConstants.UPDATE_TRAVELERS,
        };
        break;
      case GeneralPurchaseErrorEnum.FraudAutoReject:
      case GeneralPurchaseErrorEnum.LikelyFraud:
        titles = {
          title: textConstants.GENERIC_ERROR_TITLE,
          subtitle: textConstants.FRAUD_SUBTITLE,
          icon: textConstants.UNABLED_ICON,
          primaryButtonText: textConstants.EDIT_PAYMENT_METHOD,
          secondaryButtonText: textConstants.CONTACT_SUPPORT,
        };
        break;
      case GeneralPurchaseErrorEnum.NoAvailability:
        let noAvailabilityAirProperties = {};
        if (flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE) {
          noAvailabilityAirProperties = {
            price_freeze_transferred:
              priceFreeze?.priceFreeze.actions.transferTime !== undefined,
          };
          const isSimilarFlightsAvailable =
            fetchSimilarFlightsCallState === CallState.Success &&
            isSimilarFlightsEnabled;
          const getContent = () => {
            if (isSimilarFlightsAvailable) {
              return {
                type: ErrorModalType.PRICE_FREEZE_EXERCISE_SIMILAR_FLIGHTS_AND_REFUND,
                subtitle: textConstants.PRICE_FREEZE_SIMILAR_FLIGHTS_SUBTITLE,
                mobileSubtitle:
                  priceFreezePaid && priceFreezeTravelers
                    ? textConstants.PRICE_FREEZE_SIMILAR_FLIGHTS_WITH_REFUND_AMOUNT_SUBTITLE(
                        priceFreezePaid,
                        priceFreezeTravelers
                      )
                    : undefined,
                primaryButtonText: textConstants.CHOOSE_A_SIMILAR_FLIGHT,
                secondaryButtonText: textConstants.GET_A_REFUND,
              };
            } else {
              return {
                type: ErrorModalType.PRICE_FREEZE_EXERCISE_REFUND,
                subtitle:
                  priceFreezePaid && priceFreezeTravelers
                    ? textConstants.PRICE_FREEZE_REFUND_SUBTITLE(
                        priceFreezePaid,
                        priceFreezeTravelers
                      )
                    : textConstants.PRICE_FREEZE_DONT_WORRY_SUBTITLE,
                primaryButtonText: textConstants.GET_A_REFUND,
              };
            }
          };

          titles = {
            title:
              textConstants.getPriceFreezeNoAvailabilityTitle(numTravelers),
            useHtml: true,
            icon: textConstants.UNABLED_ICON,
            ...getContent(),
          };
        } else {
          titles = {
            type: ErrorModalType.FLIGHTS_PRICE_QUOTE_HAS_NO_AVAILABILITY,
            title: textConstants.getNoAvailabilityTitle(numTravelers),
            subtitle: textConstants.getNoAvailabilitySubtitle(numTravelers),
            icon: textConstants.UNABLED_ICON,
            // note: ideally, if the user only selected 1 PAX and still received NoAvailability, the secondary button shouldn't be displayed
            primaryButtonText: textConstants.SEARCH_AGAIN,
            secondaryButtonText:
              numTravelers > 1
                ? textConstants.NO_AVAILABILITY_EDIT_TRAVELER_TEXT
                : "",
          };
        }
        trackEvent({
          eventName: NO_AVAILABILITY_AIR,
          properties: noAvailabilityAirProperties,
        });
        break;
      case PaymentErrorEnum.MissingNationality:
        titles = {
          title: (error as UncategorizedPurchaseError).title,
          subtitle: (error as UncategorizedPurchaseError).subtitle,
          primaryButtonText: textConstants.UPDATE_TRAVELERS,
        };
        break;
      case PaymentErrorEnum.UserCardNotFound:
        titles = textConstants.USER_CARD_ERROR_TITLES;
        break;
      case PaymentPurchaseErrorEnum.CardDeclined:
        titles = {
          title: textConstants.CARD_DECLINED,
          subtitle: textConstants.CARD_DECLINED_SUBTITLE,
          icon: textConstants.UNABLED_ICON,
          primaryButtonText: textConstants.EDIT_PAYMENT_METHOD,
        };
        break;
      case GeneralPurchaseErrorEnum.InActivity:
        hasSelectedSeats
          ? (titles = {
              type: ErrorModalType.FLIGHT_INACTIVITY,
              title: textConstants.INACTIVITY_TITLE,
              subtitle: textConstants.ERROR_WITH_SELECTED_SEATS_SUBTITLE,
              primaryButtonText: textConstants.CONTINUE,
            })
          : (titles = {
              type: ErrorModalType.FLIGHT_INACTIVITY,
              title: textConstants.INACTIVITY_TITLE,
              subtitle: textConstants.INACTIVITY_SUBTITLE,
              primaryButtonText: textConstants.TRY_AGAIN,
            });
        break;
      case FlightError.UnknownSabreAppError:
      case FlightError.MissingAirlineLocator:
      case FlightError.DuplicateBooking:
        if (hasSelectedSeats) {
          titles = {
            type: ErrorModalType.DUPLICATE_BOOKING,
            title: textConstants.DUPLICATE_BOOKING,
            subtitle: textConstants.DUPLICATE_BOOKING_SUBTITLE,
            primaryButtonText: textConstants.GO_TO_MY_TRIPS,
            secondaryButtonText: textConstants.SEARCH_AGAIN,
          };
        } else {
          if (flightBookType === FlightBookType.PRICE_FREEZE_EXERCISE) {
            titles = {
              type: ErrorModalType.PRICE_FREEZE_EXERCISE_PROVIDER_ERROR,
              title: textConstants.PROVIDER_ERROR_TITLE,
              subtitle:
                textConstants.PRICE_FREEZE_EXERCISE_PROVIDER_ERROR_SUBTITLE,
              primaryButtonText: textConstants.CONTACT_SUPPORT,
            };
          } else {
            titles = {
              type: ErrorModalType.PROVIDER_ERROR,
              title: textConstants.PROVIDER_ERROR_TITLE,
              subtitle: textConstants.PROVIDER_ERROR_SUBTITLE,
              primaryButtonText: textConstants.CHOOSE_ANOTHER_FLIGHT,
              secondaryButtonText: textConstants.TRY_AGAIN,
            };
          }
        }
        break;
      case PaymentPurchaseErrorEnum.RedemptionFailure:
        titles = {
          title: textConstants.REDEMPTION_ISSUE,
          subtitle: textConstants.REDEMPTION_ISSUE_SUTITLE,
          primaryButtonText: textConstants.TRY_AGAIN,
          icon: textConstants.UNABLED_ICON,
        };
        break;
      case FlightError.LapInfantsUnsupported:
        titles = {
          title: textConstants.INVALID_PASSENGERS,
          subtitle: textConstants.LAP_INFANT_UNSUPPORTED,
          primaryButtonText: textConstants.CONTINUE_WITH_OWN_SEAT,
          secondaryButtonText: textConstants.CHOOSE_ANOTHER_FLIGHT,
          icon: textConstants.UNABLED_ICON,
        };
        break;
      case FlightError.ExpiringPassport:
        titles = {
          title: textConstants.PASSENGER_ERROR,
          subtitle: (error as UncategorizedPurchaseError).subtitle,
          primaryButtonText: textConstants.UPDATE_TRAVELERS,
        };
        break;
      default:
        switch (flightBookType) {
          case FlightBookType.PRICE_FREEZE_PURCHASE:
            titles.type = ErrorModalType.PRICE_FREEZE_PURCHASE_GENERIC_FAILED;
            titles.primaryButtonText = textConstants.SEARCH_AGAIN;
            break;
          case FlightBookType.PRICE_FREEZE_EXERCISE:
            titles.type = ErrorModalType.PRICE_FREEZE_EXERCISE_GENERIC_FAILED;
            titles.subtitle = textConstants.TRY_AGAIN_OR_CONTACT_US;
            titles.primaryButtonText = textConstants.TRY_AGAIN;
            titles.secondaryButtonText = textConstants.CONTACT_US;
            break;
          // note: by default, the returned ErrorTitles doesn't include a particular `type` for generic failures
          default:
            titles.subtitle = titles.subtitle;
        }
    }
    return titles;
  });

  const realError = errorTitles.find(
    (e) => e.title !== textConstants.GENERIC_ERROR_TITLE
  );

  return {
    ...(realError ? realError : errorTitles[0]),
    agentSubtitle,
    agentTitle,
  };
};
