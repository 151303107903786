import { ITripLeg } from "./types";

export const PRIMARY_BTN = "Continue to checkout";
export const CONTINUE = "Continue";
export const SECONDARY_BTN = "Select a different flight";
export const SELF_TRANSFER_MULTIPLE_AIRLINES =
  "Reminder! This flight operates through <b>multiple airlines</b> and requires you to <b>claim</b> and <b>re-check</b> baggage during layovers.";

export const SELF_TRANSFER_ONE_AIRLINE =
  "Reminder! This flight requires you to <b>claim</b> and <b>re-check</b> baggage during layovers.";
export const SELF_TRANSFER_MODAL_SUBTITLE =
  "All layovers on this flight are protected by";
export const SELF_TRANSFER_MODAL_MCG = "missed connection guarantee";
/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const SELF_TRANSFER_MODAL_MCR = "missed connection rebooking";
export const BEFORE_CONTINUE =
  "<b>Before you continue</b>, review baggage fees:";

const OUTBOUND_DISPLAY_TEXT = "Outbound";
const RETURN__DISPLAY_TEXT = "Return";

export const REMINDER = "Reminder!";
export const RESTRICTIONS = "Fare restrictions";

export const getValueText = (tripLeg: ITripLeg) =>
  tripLeg === ITripLeg.OUTBOUND ? OUTBOUND_DISPLAY_TEXT : RETURN__DISPLAY_TEXT;

export const getAriaValueText = (tripLeg: ITripLeg) =>
  `Display flights that depart ${
    tripLeg === ITripLeg.OUTBOUND ? OUTBOUND_DISPLAY_TEXT : RETURN__DISPLAY_TEXT
  }`;

export const OUTBOUND_RETURN__TOGGLE_ARIA_LABEL = `${OUTBOUND_DISPLAY_TEXT} or ${RETURN__DISPLAY_TEXT}`;
