import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { PriceBreakdownDropdown } from "./component";
import {
  getFlightTotalInPrices,
  getIsPriceFreezeExerciseEnabled,
  currentPriceDropProtectionSelector,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    flightTotal: getFlightTotalInPrices(state),
    isPriceFreezeExerciseEnabled: getIsPriceFreezeExerciseEnabled(state),
    priceDropProtection: currentPriceDropProtectionSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownDropdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceBreakdownDropdown = connector(
  PriceBreakdownDropdown
);
