import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../../../reducers/types";
import { setStopsOption } from "../../../../../../actions/actions";
import { getStopsOption } from "../../../../../../reducer/index";
import { setRerunPrediction } from "../../../../../../../shop/actions/actions";
import { StopsOptionSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const stopsOption = getStopsOption(state);

  return {
    stopsOption,
  };
};

const mapDispatchToProps = {
  setStopsOption,
  setRerunPrediction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type StopsOptionSelectionProps = ConnectedProps<typeof connector>;

export const ConnectedStopsOptionSelection = connector(StopsOptionSelection);
