import dayjs from "dayjs";

export const BANNER_COPY = `Confirmed, your price is frozen.`;
export const FEE_WAIVED_SUCCESS_TITLE = "Booking Fee Waived Successfully";
// TODO: once the backend is ready, add in the email variable
export const TITLE = (email: string) =>
  `An email confirmation will be sent to ${email}`;
export const SUBTITLE = (id: string) => `Your booking confirmation is #${id}`;
export const WHATS_NEXT_HEADER = "What's next?";
export const WHATS_NEXT_CONTENT = (date: string) => {
  // e.g.: Thursday, November 11 at 09:35 AM
  const dateToDisplay = dayjs(date).format("dddd, MMMM DD");
  const timeToDisplay = dayjs(date).format("hh:mm A");
  return `You have until <strong>${dateToDisplay} at ${timeToDisplay}</strong> to purchase your flight at the frozen price.`;
};

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string,
  date: string
) => ({
  type: isDeparture ? "Outbound" : "Return",
  description: `to ${location} on ${dayjs(date).format("ddd, MMM D")}`,
});

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const FROZEN_PRICES_LIST_LINK = "/trips/?tripsFilter=Frozen%20Prices";
