import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";

import { MonthAndDatePicker, MonthAndDatePickerType } from "../../../index";
import { FlightSearchButton } from "../../../SearchButton";
import { MobileCalendarPickerModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

import clsx from "clsx";
import {
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
  Icon,
  IconName,
  PickerType,
  LoadingPopup,
  B2BSpinner,
} from "halifax";
import dayjs from "dayjs";
import { FlightShopStep, TripCategory } from "redmond";
import { RouteComponentProps } from "react-router";

interface IMobileCalendarPickerModalProps
  extends MobileCalendarPickerModalConnectorProps,
    RouteComponentProps {
  isHistoryPushDisabled?: boolean;
  openCalendarModal: boolean;
  setOpenCalendarModal: (val: boolean) => void;
  handleIncompleteSearch?: (val: boolean) => void;
  searchOnContinue?: boolean;
  headerText?: string;
  fullScreen?: boolean;
  buttonText?: string;
  // overrides the tripCategory check for reshop from MC > OW/RT
  // temporary workaround- setting the tripCategory state from the MobileEditLocationModal will dismount the multicity funnel
  calendarTripType: TripCategory;
  setCalendarTripType?: (val: TripCategory) => void;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
}

export const MobileCalendarPickerModal = (
  props: IMobileCalendarPickerModalProps
) => {
  const {
    setDepartureDate,
    setReturnDate,
    departureDate,
    returnDate,
    tripCategory,
    setTripCategory,
    isHistoryPushDisabled,
    populateFlightShopQueryParams,
    openCalendarModal,
    setOpenCalendarModal,
    origin,
    destination,
    handleIncompleteSearch,
    searchOnContinue = false,
    history,
    headerText,
    fullScreen = false,
    buttonText,
    calendarTripType,
    setCalendarTripType,
    resetSelectedTrip,
    departureDateBucketsLoading,
    onSelectDates,
  } = props;

  const [localDepartureDate, setLocalDepartureDate] = useState<Date | null>(
    null
  );
  const [localReturnDate, setLocalReturnDate] = useState<Date | null>(null);

  const isRoundTrip = calendarTripType === TripCategory.ROUND_TRIP;

  const isReadyToSearch =
    !!destination &&
    !!origin &&
    !!localDepartureDate &&
    (isRoundTrip ? !!localReturnDate : true);

  useEffect(() => {
    setLocalDepartureDate(departureDate);
  }, [departureDate]);

  useEffect(() => {
    setLocalReturnDate(returnDate);
  }, [returnDate]);

  const handleContinue = () => {
    // Order here matters because of the reducer structure.
    // Set trip category resets the returnDate field.
    tripCategory !== calendarTripType && setTripCategory(calendarTripType); // should only set tripCategory if it's not the same because changing trip category will make returndate to be null (and then causes it to use the prev return date from params)
    setReturnDate(localReturnDate);
    setDepartureDate(localDepartureDate);
    setOpenCalendarModal(false);
    onSelectDates?.(localDepartureDate, localReturnDate);
    if (!isReadyToSearch && handleIncompleteSearch) {
      handleIncompleteSearch(true);
    } else if (isReadyToSearch && !isHistoryPushDisabled) {
      resetSelectedTrip();
      populateFlightShopQueryParams({
        history,
        useHistoryPush: false,
        forceQueryUpdate: true,
        newQueryParams: {
          flightShopProgress: FlightShopStep.ChooseDeparture,
          isFromFlightWatch: undefined,
          tripCategory: calendarTripType,
        },
      });
    }
  };
  const handleClose = () => {
    !searchOnContinue && setReturnDate(localReturnDate);
    !searchOnContinue && setDepartureDate(localDepartureDate);
    searchOnContinue && setLocalDepartureDate(departureDate);
    searchOnContinue && setLocalReturnDate(returnDate);
    // reset to current tripCategory
    setCalendarTripType && setCalendarTripType(tripCategory);
    setOpenCalendarModal(false);
  };
  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {headerText || textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <MobilePopoverCard
      open={openCalendarModal}
      className={"mobile-flights-search-calendar-popup"}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
      fullScreen={!!fullScreen}
    >
      <Box className="mobile-calendar-picker-root redesign-v2">
        {departureDateBucketsLoading ? (
          <LoadingPopup
            indicatorSize={"small"}
            indicator={B2BSpinner}
            open={!!departureDateBucketsLoading}
            message={textConstants.UPDATE_CALENDAR_TEXT}
          />
        ) : (
          <MonthAndDatePicker
            className="b2b-flights"
            pickerType={isRoundTrip ? PickerType.RANGE : PickerType.DAY}
            viewType={MonthAndDatePickerType.Column}
            setStartDate={setLocalDepartureDate}
            setEndDate={setLocalReturnDate}
            startDate={localDepartureDate}
            endDate={localReturnDate}
            header={
              <Box className="flights-mobile-calendar-header">
                <DatePickerHeader
                  label={textConstants.OUTBOUND}
                  value={
                    localDepartureDate
                      ? dayjs(localDepartureDate).format("ddd D, MMM")
                      : undefined
                  }
                />
                {isRoundTrip ? (
                  <DatePickerHeader
                    label={textConstants.RETURN}
                    value={
                      localReturnDate
                        ? dayjs(localReturnDate).format("ddd D, MMM")
                        : undefined
                    }
                  />
                ) : null}
              </Box>
            }
          />
        )}
        {localDepartureDate && (isRoundTrip ? localReturnDate : true) && (
          <FlightSearchButton
            className={clsx("mobile-calendar-picker-search-button", "b2b")}
            message={buttonText ?? textConstants.SEARCH_FLIGHTS_TEXT}
            onClick={handleContinue}
            return={isRoundTrip ? localReturnDate : null}
            departure={localDepartureDate}
            enabled={
              localDepartureDate
                ? isRoundTrip
                  ? !!localReturnDate
                  : true
                : false
            }
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
