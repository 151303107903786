import React from "react";
import dayjs from "dayjs";
import {
  PriceDropProtectionEnum,
  Prediction,
  IsEligible,
  PRICE_DROP_PROTECTION_MODAL_SUBTITLE,
  TripCategory,
} from "redmond";
import { Airport } from "@b2bportal/air-shopping-api";
import { tripTypeText } from "halifax";

const OUTBOUND = "Outbound";
const RETURN = "Return";
const FLIGHT = "Flight";

export const getReviewCardHeader = (
  isDeparture: boolean,
  location: string,
  date: Date,
  parsable = false
) =>
  //Added semicolon to parse into two phrases
  `${isDeparture ? OUTBOUND : RETURN} to ${location} ${
    parsable ? ":" : ""
  } on ${dayjs(date).format("ddd, MMM D")}`;

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string,
  date: Date | string
) => ({
  type: isDeparture ? OUTBOUND : RETURN,
  description: `<strong> to ${location}
  </strong> on ${dayjs(date).format("ddd, MMM D")}`,
});

export const getMulticityReviewHeader = (
  flightIndex: number,
  originAirport: Airport,
  destinationAirport: Airport,
  date: Date | string,
  hasFlightSegmentNumber?: boolean
) => {
  const {
    cityName: originCityName,
    servedCityName: originServedCityName,
    code: originCode,
  } = originAirport;
  const {
    cityName: destinationCityName,
    servedCityName: destinationServedCityName,
    code: destinationCode,
  } = destinationAirport;
  return `${hasFlightSegmentNumber ? `${FLIGHT} ${flightIndex + 1}: ` : ""}${
    originCityName || originServedCityName
  } (${originCode}) to ${
    destinationCityName || destinationServedCityName
  } (${destinationCode}) - on ${dayjs(date).format("ddd, MMM D")}`;
};

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const bestFlightText = "Best Overall";
export const cheapestFlightText = "Best Price";
export const bestQualityText = "Best Quality";
export const fastestText = "Fastest";

export const getExpandedTitleText = (departure: boolean) =>
  `${departure ? "Departure" : "Return"} details`;

export const getTotalPriceDescription = (tripType: TripCategory) =>
  `${tripTypeText[tripType]}, per traveler`;

export const getContinueText = (price: string) => (
  <>
    Continue | <strong>{price} per traveler</strong>
  </>
);
export const CONTINUE_TEXT = "Continue";
export const CONTINUE_TO_CHECKOUT_TEXT = "Continue to Checkout";
export const COMBINATION_FLIGHT_WARNING =
  "This fare is part of a flight combination";
export const COMBINATION_FLIGHT_TOOLTIP =
  "Restrictions may differ between outbound and return flights. Cancellations or changes to one of your flights will not alter the other automatically.";
export const DEPARTURE_KEY = "departure";
export const RETURN_KEY = "return";
export const EXPANDED_MC_DEPARTURE_KEY = (flightNumber: number) =>
  `flight${flightNumber}`;
export const CHANGE = "Change Flight";
export const FARE_DETAILS_TITLE = "Ready to book?";
export const FARE_DETAILS_VARIANT_TITLE = "Fare Details";
export const FARE_DETAILS_SUBTITLE =
  "Here are some details you should know about.";
export const FARE_DETAILS_COMBINATION_FLIGHT_TITLE =
  "Your trip is a combination of 2 one-way fares.";
export const FARE_DETAILS_COMBINATION_FLIGHT_SUBTITLE =
  "Restrictions may differ between airlines.";
export const VIRTUAL_INTERLINE_FLIGHT_TITLE =
  "You’re flying on separate airlines.";
export const VIRTUAL_INTERLINE_FLIGHT_SUBTITLE =
  FARE_DETAILS_COMBINATION_FLIGHT_SUBTITLE;
export const PDP_TITLE = "How it works";
export const PDP_CTA_TEXT = "Read terms and conditions";
export const PDP_HEADER =
  "Book now with <span class='font-regular'>free price drop protection</span>";

const getMonitoringDaysCount = (prediction: Prediction) => {
  if (prediction) {
    const days =
      prediction &&
      prediction.priceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible
        ? (prediction.priceDropProtection as IsEligible).monitoringDuration
            .inSeconds / 86400
        : 0;
    return days;
  } else {
    return "";
  }
};

export const getMaxRefund = (prediction: Prediction) => {
  if (prediction) {
    const maxRefund =
      prediction.priceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible &&
      prediction.priceDropProtection.maximumRefund.display;
    return maxRefund;
  } else {
    return "";
  }
};

export const getSubtitle = (
  prediction: Prediction,
  isPriceDropCreditEnabled: boolean
) => {
  const monitoringDays = getMonitoringDaysCount(prediction);
  const maxRefund = getMaxRefund(prediction);
  return PRICE_DROP_PROTECTION_MODAL_SUBTITLE(
    monitoringDays,
    maxRefund,
    isPriceDropCreditEnabled
  );
};

export const VIEW_PRICE_DROP_ARIA_LABEL = "View price drop details";

// Marketing airlines that do NOT allow Price Freeze on the Review Itinerary page
export const NO_PRICE_FREEZE_AIRLINE_CODES = [
  "B6", // JetBlue
  "WN", // Southwest
  "UA", // United Airlines
];

export const MISSED_CONNECTION_GUARANTEE_INCLUDED =
  "Missed connection guarantee included";
/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const MISSED_CONNECTION_REBOOKING_INCLUDED =
  "Missed connection rebooking included";
export const NO_REFUNDABLE = "Non-refundable";
export const NO_REFUNDABLE_DESCRIPTION =
  "This itinerary is not refundable through Capital One Travel.";
