import axios from "axios";
import { putResolve, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { IStoreState } from "../../../../reducers/types";
import { getSession } from "../../reducer";
import { purchaseApi } from "@b2bportal/purchase-api";

export function* closeSessionSaga(action: actions.ICloseSession) {
  try {
    const { resetSessionOnly } = action;
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (!session) {
      throw new Error("Session token is not present.");
    }
    yield purchaseApi(axios as any).apiV0PurchaseQuoteDiscardPost(session);
    if (!resetSessionOnly) {
      // note: this will reset everything in /book/reducer
      yield putResolve(actions.closeSessionCallStateSuccess());
    }
  } catch (e) {
    yield putResolve(actions.closeSessionCallStateFailed());
  }
}
