import axios, { AxiosResponse } from "axios";
import {
  GetExerciseEligibilitiesRequestV1,
  GetExerciseEligibilitiesResponseV1,
} from "redmond";
import { config } from "../../config";
import { getFlightDisruptionExerciseEligibilityPath } from "../paths";

/**
 * @description Get flight disruption exercise eligibility information based on the itinerary IDs
 * @param {GetExerciseEligibilitiesRequestV1} req Contains the itinerary IDs
 * @return {GetExerciseEligibilitiesResponseV1} The eligibility info for exercising DP for each itinerary
 */
export const getFlightDisruptions = (
  req: GetExerciseEligibilitiesRequestV1
): Promise<GetExerciseEligibilitiesResponseV1> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<GetExerciseEligibilitiesResponseV1> = await axios.post(
        getFlightDisruptionExerciseEligibilityPath,
        req,
        {
          baseURL: config.baseURL,
        }
      );

      resolve((res.data as any).value);
    } catch (e) {
      reject(e);
    }
  });
