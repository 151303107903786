import {
  TripFilter,
  IMonthBucket,
  IDateBucket,
  IBucketedDate,
  IDepartureCalendarReport,
  IDepartureCalendarRequestBody,
  IUserPreferences,
  TripCategory,
} from "redmond";
import { select, put } from "redux-saga/effects";
import { fetchCalendar } from "../../../api/v0/search/fetchCalendar";
import Logger from "../../../helpers/Logger";
import { IStoreState } from "../../../reducers/types";
import { actions } from "../actions";
import {
  getTripCategory,
  getPreferences,
  getCalendarQueryOrigin,
  getCalendarQueryDestination,
} from "../reducer";
import dayjs from "dayjs";
import { CalendarBuckets } from "@b2bportal/air-shopping-api";

export function* fetchDepartureCalendarSaga() {
  try {
    const state: IStoreState = yield select();
    const origin = getCalendarQueryOrigin(state);
    const destination = getCalendarQueryDestination(state);
    const tripType = getTripCategory(state);
    const preferences: IUserPreferences = yield getPreferences(state);

    if (origin && destination) {
      const {
        id: { code: destinationCode },
      } = destination;
      const {
        id: { code: originCode },
      } = origin;

      const requestBody: IDepartureCalendarRequestBody = {
        route: { origin: originCode, destination: destinationCode },
        // if multicity, we will be doing a series of one-way fetches
        tripType:
          tripType === TripCategory.MULTI_CITY
            ? TripCategory.ONE_WAY
            : tripType || TripCategory.ONE_WAY,
        filter: { TripFilter: TripFilter.NO_FILTER },
        preferences,
      };

      const calendarReport: IDepartureCalendarReport = yield fetchCalendar(
        requestBody as CalendarBuckets
      );

      const departureMonths: IMonthBucket[] = transformDateBuckets(
        calendarReport.departureDateBuckets
      );

      yield put(actions.setCalendar({ ...calendarReport, departureMonths }));
    }
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setCalendar());
  }
}

export const transformDateBuckets = (
  dateBuckets: IDateBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(({ monthIndex }) => {
            return monthIndex === currentMonthIndex;
          });

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};
