import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import {
  resetSelectedTrip,
  populateFlightShopQueryParams,
} from "../../../../../shop/actions/actions";
import {
  setDepartureDate,
  setReturnDate,
  setTripCategory,
} from "../../../../actions/actions";
import {
  getDepartureDate,
  getDepartureDateBucketsLoading,
  getDestination,
  getOrigin,
  getReturnDate,
  getTripCategory,
} from "../../../../reducer";
import { MobileCalendarPickerModal } from "./component";
import { withRouter } from "react-router";

const mapDispatchToProps = {
  setDepartureDate,
  setReturnDate,
  setTripCategory,
  resetSelectedTrip,
  populateFlightShopQueryParams,
};
const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);
  const origin = getOrigin(state);
  const destination = getDestination(state);
  const tripCategory = getTripCategory(state);
  const departureDateBucketsLoading = getDepartureDateBucketsLoading(state);

  return {
    returnDate,
    departureDate,
    origin,
    destination,
    tripCategory,
    departureDateBucketsLoading,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCalendarPickerModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPickerModal = withRouter(
  connector(MobileCalendarPickerModal)
);
