import axios, { AxiosResponse } from "axios";
import { config } from "../../../config";

import { agentPortalChargeBookingFeeApi } from "../../paths";
import { TransactionStoreId } from "redmond";

// TODO: add to redmond when types can be generated
export interface ChargeAgentBookingFeeRequest {
  transactionStoreId: TransactionStoreId;
  initiator: {
    id: string;
    Initiator: string;
  };
}

export type ChargeAgentBookingFeeResponse = { ChargeBookingFeeResponse: string };

export const chargeAgentBookingFee = (
  req: ChargeAgentBookingFeeRequest
): Promise<ChargeAgentBookingFeeResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<ChargeAgentBookingFeeResponse> = await axios.post(agentPortalChargeBookingFeeApi, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
