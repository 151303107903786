import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RebookConfirmation } from "./component";
import {
  getFinalizedItinerary,
  finalizedItineraryIdSelector,
  finalizedRebookItineraryFlightSummaryDataSelector,
  rebookedItineraryDpExerciseItineraryFactsPropertiesSelector,
  rebookedItineraryDpRebookingCompleteBuyFactsPropertiesSelector,
} from "../../../book/reducer/selectors";
import {
  hasUpcomingReturnFlightSelector,
  reviewItineraryEligibilityDpExerciseFactsPropertiesSelector,
  isSelectingReturnFlightSelector,
  disruptedFlightDpExerciseFactsPropertiesSelector,
  rebookSummarySelector,
  isFintechCsatEnabledSelector,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    rebookSummary: rebookSummarySelector(state),
    finalizedItinerary: getFinalizedItinerary(state),
    finalizedItineraryId: finalizedItineraryIdSelector(state),
    hasUpcomingReturnFlight: hasUpcomingReturnFlightSelector(state),
    finalizedRebookItineraryFlightSummaryData:
      finalizedRebookItineraryFlightSummaryDataSelector(state),
    reviewItineraryEligibilityDpExerciseFactsProperties:
      reviewItineraryEligibilityDpExerciseFactsPropertiesSelector(state),
    rebookedItineraryDpExerciseItineraryFactsProperties:
      rebookedItineraryDpExerciseItineraryFactsPropertiesSelector(state),
    rebookedItineraryDpRebookingCompleteBuyFactsProperties:
      rebookedItineraryDpRebookingCompleteBuyFactsPropertiesSelector(state),
    isSelectingReturnFlight: isSelectingReturnFlightSelector(state),
    disruptedFlightDpExerciseFactsProperties:
      disruptedFlightDpExerciseFactsPropertiesSelector(state),
    isFintechCsatEnabled: isFintechCsatEnabledSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RebookConfirmationConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRebookConfirmation = connector(
  withRouter(RebookConfirmation)
);
