import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getDestination,
  getOrigin,
  getFareclassOptionFilter,
  getTripCategory,
  getDepartureDateBucketsLoading,
  getMobileSearchProgress,
  getDepartureDate,
  getReturnDate,
  getHasOriginAutocompleteError,
  getHasDestinationAutocompleteError,
  MobileFlightSearchStep,
  getApplyUserFlightPreferences,
  getHasUserSetFlightPreferences,
  getUserFlightPreferences,
  getUserFlightPreferencesCallState,
  getStopsOption,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
} from "../../reducer";
import {
  fetchDepartureCalendar,
  IFetchDepartureCalendar,
  ISetDepartureCalendar,
  ISetDepartureCalendarArgs,
  ISetDestinationAutocompleteError,
  ISetMobileSearchProgress,
  ISetOriginAutocompleteError,
  ISetTripCategory,
  setApplyUserFlightPreferences,
  setCalendar,
  setDestinationAutocompleteError,
  setMobileSearchProgress,
  setOriginAutocompleteError,
  setTripCategory,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileFlightSearchControlV3 } from "./component";
import {
  CallState,
  FareclassOptionFilter,
  ITripTerminus,
  TripCategory,
  UserFlightPreferencesPayload,
} from "redmond";
import {
  IFlightShopOverwriteQueryParams,
  IPopulateFlightShopQueryParams,
  populateFlightShopQueryParams,
} from "../../../shop/actions/actions";
import { SliceStopCountFilter } from "redmond/build";

interface IDispatchProps {
  fetchDepartureCalendar: (
    origin: ITripTerminus,
    destination: ITripTerminus
  ) => IFetchDepartureCalendar;
  setCalendar: (report?: ISetDepartureCalendarArgs) => ISetDepartureCalendar;
  setCurrentStep: (step: MobileFlightSearchStep) => ISetMobileSearchProgress;
  setTripCategory: (tripCategory: TripCategory) => ISetTripCategory;
  setOriginAutocompleteError: (error: boolean) => ISetOriginAutocompleteError;
  setDestinationAutocompleteError: (
    error: boolean
  ) => ISetDestinationAutocompleteError;
  populateFlightShopQueryParams: (args: {
    history: any;
    prevPath?: string | undefined;
    useHistoryPush?: boolean | undefined;
    forceQueryUpdate?: boolean | undefined;
    newQueryParams?: IFlightShopOverwriteQueryParams | undefined;
  }) => IPopulateFlightShopQueryParams;
  setApplyUserFlightPreferences: (applyPreferences: boolean) => void;
}

const mapDispatchToProps = {
  fetchDepartureCalendar,
  setCalendar,
  setCurrentStep: setMobileSearchProgress,
  setTripCategory,
  setOriginAutocompleteError,
  setDestinationAutocompleteError,
  populateFlightShopQueryParams,
  setApplyUserFlightPreferences,
};

interface IStateProps {
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  originCode?: string;
  destinationCode?: string;
  tripCategory: TripCategory;
  departureDateBucketsLoading: boolean | null;
  currentStep: MobileFlightSearchStep;
  departureDate: Date | null;
  returnDate: Date | null;
  hasOriginAutocompleteError: boolean;
  hasDestinationAutocompleteError: boolean;
  userHasSetFlightPreferences?: boolean;
  userFlightPreferences?: UserFlightPreferencesPayload;
  userFlightPreferencesCallState?: CallState;
  shouldApplyUserFlightPreferences: boolean;
  fareclassOptionFilter: FareclassOptionFilter;
  stopsOption: SliceStopCountFilter;
  adultsCount: number;
  childrenCount: number;
  infantsInSeatCount: number;
  infantsOnLapCount: number;
}

const mapStateToProps = (state: IStoreState) => ({
  origin: getOrigin(state),
  destination: getDestination(state),
  originCode: getOrigin(state)?.id.code.code,
  destinationCode: getDestination(state)?.id.code.code,
  tripCategory: getTripCategory(state),
  departureDateBucketsLoading: getDepartureDateBucketsLoading(state),
  currentStep: getMobileSearchProgress(state),
  departureDate: getDepartureDate(state),
  returnDate: getReturnDate(state),
  hasOriginAutocompleteError: getHasOriginAutocompleteError(state),
  hasDestinationAutocompleteError: getHasDestinationAutocompleteError(state),
  userHasSetFlightPreferences: getHasUserSetFlightPreferences(state),
  userFlightPreferences: getUserFlightPreferences(state),
  userFlightPreferencesCallState: getUserFlightPreferencesCallState(state),
  shouldApplyUserFlightPreferences: getApplyUserFlightPreferences(state),
  fareclassOptionFilter: getFareclassOptionFilter(state),
  stopsOption: getStopsOption(state),
  adultsCount: getAdultsCount(state),
  childrenCount: getChildrenCount(state),
  infantsOnLapCount: getInfantsOnLapCount(state),
  infantsInSeatCount: getInfantsInSeatCount(state),
});

interface IOwnProps {
  isMulticityEnabled?: boolean;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type MobileFlightSearchControlV3ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileFlightSearchControlV3 = withRouter(
  connector(MobileFlightSearchControlV3)
);
