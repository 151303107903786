import { connect, ConnectedProps } from "react-redux";
import { PolicyFilterSelection } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import { getPolicyFilter } from "../../../reducer";
import { setPolicyFilter } from "../../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const policyFilter = getPolicyFilter(state);

  return {
    policyFilter,
  };
};

const mapDispatchToProps = {
  setPolicyFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PolicyFilterProps = ConnectedProps<typeof connector>;

export const ConnectedPolicyFilterSelection = connector(PolicyFilterSelection);
