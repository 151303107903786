import React from "react";
import { debounce } from "lodash-es";
import { ButtonWrap, Icon, IconName, SliderDropdown } from "halifax";
import clsx from "clsx";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { generateFilterTrackingEvent } from "../../../../../../actions/actions";
import { trackEvent } from "../../../../../../../../api/v0/analytics/trackEvent";
import { initialFilterOptions } from "../../../../../../reducer";
import { Box, Typography } from "@material-ui/core";

interface IMaxPriceFilterSelectionProps {
  maxPriceFilter: number;
  setMaxPriceFilter: (maxPriceFilter: number) => void;
  showDropdownContentOnly?: boolean;
  maximumPrice: number;
  minimumPrice: number;
  hasSetMaxPrice?: boolean;
  appliedLabel?: string;
  isFlightListOptimizationExperiment?: boolean;
  icon?: IconName;
  title?: string;
  subtitle?: string;
  isAirCXV3Experiment?: boolean;
}

export const MaxPriceFilterSelection = (
  props: IMaxPriceFilterSelectionProps
) => {
  const {
    maxPriceFilter,
    setMaxPriceFilter,
    maximumPrice,
    minimumPrice,
    showDropdownContentOnly,
    hasSetMaxPrice,
    appliedLabel,
    isFlightListOptimizationExperiment,
    icon,
    title,
    subtitle,
    isAirCXV3Experiment,
  } = props;

  const debouncedAction = debounce(setMaxPriceFilter, 300);
  const [stateDebounceDispatchAction] = React.useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const handleChange = (newValue: number) => {
    trackEvent(generateFilterTrackingEvent("price", "list"));
    stateDebounceDispatchAction(
      newValue === maximumPrice ? initialFilterOptions.maxPriceFilter : newValue
    );
  };

  const sliderValue =
    maxPriceFilter === initialFilterOptions.maxPriceFilter
      ? maximumPrice
      : maxPriceFilter;

  return (
    <Box className="flight-shop-max-price-filter-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />}
          <Box className="titles-wrapper">
            {title}
            {subtitle && (
              <Typography variant="body2" className="subtitle">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <SliderDropdown
        dropdownLabel={textConstants.PRICE_DROPDOWN_LABEL_TEXT(
          hasSetMaxPrice ? appliedLabel : undefined
        )}
        sliderHeader={
          !title
            ? isFlightListOptimizationExperiment
              ? textConstants.PRICE_SLIDER_HEADER_TEXT_EXP
              : textConstants.PRICE_SLIDER_HEADER_TEXT
            : undefined
        }
        sliderSubtitle={
          !subtitle && isFlightListOptimizationExperiment
            ? textConstants.PRICE_SLIDER_SUBTITLE_TEXT
            : undefined
        }
        maxValue={maximumPrice}
        minValue={maximumPrice === minimumPrice ? 0 : minimumPrice}
        value={sliderValue}
        setValue={handleChange}
        sliderLabelFormat={(value) =>
          isFlightListOptimizationExperiment
            ? `${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
              }).format(value)}`
            : `$${value.toFixed(2)}`
        }
        showDropdownContentOnly={showDropdownContentOnly}
        className={clsx("b2b-shop-filter", {
          "has-value": hasSetMaxPrice,
          "filter-experiment": isFlightListOptimizationExperiment,
        })}
        popoverClassName={clsx("b2b", "flight-availability-price-popover", {
          "filter-experiment": isFlightListOptimizationExperiment,
        })}
        dropdownIcon={
          hasSetMaxPrice && appliedLabel ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                handleChange(initialFilterOptions.maxPriceFilter);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        anchorOrigin={(() => {
          switch (true) {
            case isAirCXV3Experiment:
              return {
                vertical: "bottom",
                horizontal: "left",
              };
            case isFlightListOptimizationExperiment:
              return {
                vertical: "bottom",
                horizontal: "right",
              };
            default:
              return undefined;
          }
        })()}
        transformOrigin={(() => {
          switch (true) {
            case isAirCXV3Experiment:
              return {
                vertical: "top",
                horizontal: "left",
              };
            case isFlightListOptimizationExperiment:
              return {
                vertical: "top",
                horizontal: "right",
              };
            default:
              return undefined;
          }
        })()}
        alwaysShowTooltip={isFlightListOptimizationExperiment}
        showResetButton={
          isFlightListOptimizationExperiment &&
          maxPriceFilter !== initialFilterOptions.maxPriceFilter
        }
        reset={() => handleChange(initialFilterOptions.maxPriceFilter)}
        showMinLabel={isFlightListOptimizationExperiment}
      />
    </Box>
  );
};
