import { connect } from "react-redux";
import { ITripTerminus } from "redmond";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getOriginCategories,
  getOriginCategoriesLoading,
  getDestinationCategories,
  getDestinationCategoriesLoading,
} from "../../../../reducer";
import { actions } from "../../../../actions";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";

export const mapOriginDispatchToProps = {
  fetchValueCategories: actions.fetchOriginCategories,
};

export const mapOriginStateToProps = (state: IStoreState) => {
  let loading = !!getOriginCategoriesLoading(state);

  return {
    valueCategories: getOriginCategories(state),
    loading,
  };
};

export const ConnectedOriginAutocomplete = connect(
  mapOriginStateToProps,
  mapOriginDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<ITripTerminus | null>
  ) => JSX.Element
);

export const mapDestinationDispatchToProps = {
  fetchValueCategories: actions.fetchDestinationCategories,
};

export const mapDestinationStateToProps = (state: IStoreState) => {
  let loading = !!getDestinationCategoriesLoading(state);

  return {
    valueCategories: getDestinationCategories(state),
    loading,
  };
};

export const ConnectedDestinationAutocomplete = connect(
  mapDestinationStateToProps,
  mapDestinationDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<ITripTerminus | null>
  ) => JSX.Element
);
