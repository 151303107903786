import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";

export const getTravelWalletOffers = (state: IStoreState) =>
  state.flightTravelWallet.offers;

export const getActiveTravelWalletOffers = createSelector(
  getTravelWalletOffers,
  (offers) => {
    return offers.filter((offer) => {
      return dayjs().isAfter(dayjs(offer?.saleEventStartOn));
    });
  }
);
export const getTravelWalletCredit = (state: IStoreState) =>
  state.flightTravelWallet.credit;

export const getCreditBreakdown = (state: IStoreState) =>
  state.flightTravelWallet.creditBreakdown;

export const getTravelCreditsHistoryTransaction = (state: IStoreState) =>
  state.flightTravelWallet.creditsHistoryTransactions;
