import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  GenericVirtualInterliningModal,
  PriceDropProtectionImage,
  MobilePriceDropProtectionImage,
  Icon,
  IconName,
  MobilePopoverCard,
  DesktopPopupModal,
} from "halifax";

import "./styles.scss";
import { getPropsForVariant } from "./helpers";
import { SelfTransferBanner } from "../FlightMissedConnectionGuarantee";
import { formatLayoverBannerContent, FLIGHT_INCLUDES } from "./textConstants";
import {
  Airport,
  FareDetails,
  MISSED_CONNECTION_GUARANTEE_BODY,
  TripSegment,
} from "redmond";
import { PATH_MISSED_CONNECTION_GUARANTEE } from "../../../../utils/urlPaths";

export type IVirtualInterliningVariant =
  | "selfCheck"
  | "missedConnectionProtection"
  | "missedConnectionGuarantee";

export interface IVirtualInterliningModalProps {
  className?: string;
  onClose: () => void;
  onClick?: () => void;
  variant: IVirtualInterliningVariant;
  isOpen: boolean;
  isMobile?: boolean;
  segmentDetails?: TripSegment[];
  fareDetails?: FareDetails[];
  isOutgoing?: boolean;
  airports?: {
    [key: string]: Airport;
  };
}

export const VirtualInterliningModal = ({
  variant,
  className,
  onClose,
  isOpen,
  isMobile = false,
  segmentDetails,
  fareDetails,
  isOutgoing,
  airports = {},
}: IVirtualInterliningModalProps) => {
  const renderContent = () => {
    const content = segmentDetails
      ?.filter(
        (_, index) =>
          fareDetails?.[0].slices[isOutgoing ? 0 : 1].fareDetails.segments[
            index
          ].isSelfTransferLayover
      )
      .map(({ stopoverDurationMinutes, destinationCode }) =>
        formatLayoverBannerContent({
          stopoverDurationMinutes,
          destinationCode,
          airports,
        })
      )
      .filter(Boolean);
    return (
      <GenericVirtualInterliningModal
        {...getPropsForVariant(variant)}
        extraContent={
          variant === "selfCheck" &&
          content && (
            <SelfTransferBanner
              isMobile={isMobile}
              content={`${FLIGHT_INCLUDES} ${content.join(", ")}`}
            />
          )
        }
        image={
          isMobile ? MobilePriceDropProtectionImage : PriceDropProtectionImage
        }
        onClose={onClose}
        isMobile={isMobile}
        desktopTermsConditionURL={PATH_MISSED_CONNECTION_GUARANTEE}
        mobileTermsConditionCopy={MISSED_CONNECTION_GUARANTEE_BODY}
        icon={
          <Icon
            name={
              variant === "selfCheck"
                ? IconName.BookTravel
                : IconName.FlightSearch
            }
          />
        }
      />
    );
  };

  if (isMobile) {
    return (
      <MobilePopoverCard
        role="tooltip"
        id="virtual-interlining-modal-modal"
        open={isOpen}
        className="mobile-virtual-interlining-modal-popup"
        contentClassName="mobile-flight-virtual-interlining-modal-content-wrapper"
        onClose={onClose}
      >
        {renderContent()}
      </MobilePopoverCard>
    );
  }
  return (
    <Box
      className={clsx("virtual-interlining-root", className, {
        mobile: isMobile,
      })}
    >
      <DesktopPopupModal
        open={isOpen}
        className="virtual-interlining-popup"
        onClose={onClose}
        invisibleBackdrop={false}
        hideXButton
      >
        <Box className="virtual-interlining-wrapper">{renderContent()}</Box>
      </DesktopPopupModal>
    </Box>
  );
};
