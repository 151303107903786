import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { selectedTripSelector } from "../../../shop/reducer";
import { passengerCountSelector } from "../../../search/reducer";
import {
  priceFreezePassengerCountsSelector,
  setUpFlightFreezeParamsCallStateSelector,
  priceFreezeReviewDetailsPropertiesSelector,
  priceFreezeOfferCallStateSelector,
  generateCustomPriceFreezeOfferCallStateSelector,
  currentPriceFreezeOfferSelector,
} from "../../reducer/selectors";
import {
  isTravelerStepCompleteSelector,
  getConfirmationEmail,
} from "../../../book/reducer/selectors";
import {
  setUpFlightFreezeParams,
  setPassengerCounts,
  fetchPriceFreezeFareQuote,
  cancelFetchPriceFreezeFareQuote,
  setSelectedPriceFreezeOfferDataIndex,
} from "../../actions/actions";
import {
  setContactInfo,
  scheduleQuote,
  resetPaymentCardSelectedAccounts,
} from "../../../book/actions/actions";
import { PriceFreezePurchase } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedTrip: selectedTripSelector(state),
    offer: currentPriceFreezeOfferSelector(state),
    searchCounts: passengerCountSelector(state),
    isContactStepComplete: isTravelerStepCompleteSelector(state),
    confirmationEmail: getConfirmationEmail(state),
    passengerCounts: priceFreezePassengerCountsSelector(state),
    setUpFlightFreezeParamsCallState:
      setUpFlightFreezeParamsCallStateSelector(state),
    priceFreezeReviewDetailsProperties:
      priceFreezeReviewDetailsPropertiesSelector(state),
    priceFreezeOfferCallState: priceFreezeOfferCallStateSelector(state),
    generateCustomPriceFreezeOfferCallState:
      generateCustomPriceFreezeOfferCallStateSelector(state),
  };
};

const mapDispatchToProps = {
  setUpFlightFreezeParams,
  setPassengerCounts,
  setContactInfo,
  scheduleQuote,
  resetPaymentCardSelectedAccounts,
  fetchPriceFreezeFareQuote,
  cancelFetchPriceFreezeFareQuote,
  setSelectedPriceFreezeOfferDataIndex,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceFreezePurchaseConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceFreezePurchase = withRouter(
  connector(PriceFreezePurchase)
);
