import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingConfirmationPage } from "./component";
import { getEarnedString, getFinalizedItinerary } from "../../../book/reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  redoSearch,
  chargeAgentBookingFee,
} from "../../../book/actions/actions";
import {
  airportsSelector,
  selectedTripSelector,
  tripDetailsSelector,
  predictionSelector,
} from "../../../shop/reducer/selectors";
import {
  getConfirmationEmail,
  getPriceDropProperties,
  priceFreezeOfferTotalInRewards,
  getPriceFreezeConfirmationProps,
  priceFreezeOfferTotalFiatSelector,
  selectedPriceFreezePurchasePropertiesSelector,
} from "../../../book/reducer/selectors";
import { isPriceFreezeDurationEnabledSelector } from "../../reducer/selectors";
import { getTripCategory } from "../../../search/reducer";
import { isFintechCsatEnabledSelector } from "../../../ancillary/reducer/selectors";
import { TripCategory } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  itinerary: getFinalizedItinerary(state),
  airports: airportsSelector(state, selectedTripSelector(state)?.tripId || ""),
  tripDetails: tripDetailsSelector(
    state,
    selectedTripSelector(state)?.tripId || ""
  ),
  isRoundTrip: getTripCategory(state) === TripCategory.ROUND_TRIP,
  earnString: getEarnedString(state),
  confirmationEmail: getConfirmationEmail(state),
  prediction: predictionSelector(state),
  priceDropViewedProperties: getPriceDropProperties(state),
  priceFreezeOfferTotalAmount: priceFreezeOfferTotalFiatSelector(state),
  priceFreezeOfferTotalInRewards: priceFreezeOfferTotalInRewards(state),
  priceFreezeConfirmationProps: getPriceFreezeConfirmationProps(state),
  selectedPriceFreezePurchaseProperties:
    selectedPriceFreezePurchasePropertiesSelector(state),
  isPriceFreezeDurationEnabled: isPriceFreezeDurationEnabledSelector(state),
  isFintechCsatEnabled: isFintechCsatEnabledSelector(state),
});

const mapDispatchToProps = {
  chargeAgentBookingFee,
  redoSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingConfirmationPageConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingConfirmationPage = withRouter(
  connector(BookingConfirmationPage)
);
