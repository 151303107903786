import { Prices } from "@b2bportal/air-booking-api";
import { HOPPER_SEAT_MAP_STYLE } from "../SeatSelection/textConstants";

export const SEAT_SELECTION_TITLE = "Choose seats";
export const SEAT_SELECTION_SUBTITLE = "Book seats ahead for your journey.";
export const SELECT_SEATS_MOBILE_CTA_TEXT = "Select Your Seats";
export const SKIP_SEAT_SELECTION_SUBTITLE =
  "Skip seats. Seats can be added on the airline website after booking, or will be assigned at check-in.";
export const SKIP_SEAT_SELECTION_CTA = "Continue without seats";
export const MOBILE_SEAT_SELECTION_TITLE = "Seat Selection";
export const MOBILE_SKIP_SEAT_SELECTION_SUBTITLE =
  "If you decide to skip this step, seats will be assigned at check-in";

export const SEAT_MAP_MODAL_TITLE = "Choose Seats";

export const getSeatSelectionCTAText = (cheapestSeat: Prices | null) => {
  if (cheapestSeat?.fiat.value === 0) {
    return `Select seats for free`;
  } else {
    return `Select your seats`;
  }
};

export const MOBILE_HOPPER_SEAT_MAP_STYLE = `
  ${HOPPER_SEAT_MAP_STYLE}
  .tenant-styles {
    .header {
      .select {
        .text-primary {
          font-size: 14px;
        }
      }
    }

    .seat-map {
      .legend-button {
        border-radius: 8px;
        background: white;
        border: 1.5px solid rgba(0, 0, 0, 0.08);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px 18px;
        width: 138px;
        height: 44px;
        font-size: 14px;
        font-weight: 600;
      }

      .legend {
        padding: 0px;
        width: 100%;
        height: 99%;
        top: 1%;
        left: 0px;
        h2 {
          text-align: center;
          color: #141414;
          font-size: 14px;
          font-weight: 400;
          border-bottom: 0.5px solid #CECFCD;
          padding: 20px 0px;
          margin: 0px;
          width: 100%;
        }
        .close {
          svg {
            height: 24px;
            width: 24px;
            path {
              fill: #013D58;
            }
          }
        }
        .categories {
          grid-template-columns: 5em 1fr;
          .icon:not(.jets-unavailable) {
            margin-bottom: 20px;
          }
          .category-price {
            margin-bottom: 20px;
          }
          .category-description {
            &.unavailable {
              .text-primary {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }

    .footer {
      .text-primary {
        font-size: 16px;
      }
      .text-secondary {
        font-size: 14px;
      }
      .button {
        max-width: 120px;
        height: 34px;
        margin-right: 22px;
      }
    }

    .seat-details {
      width: 100%;
      height: 99%;
      top: 1%;
      left: 0px;
      .seat-details-titlebar {
        border-bottom: 0.5px solid #CECFCD;
        padding-right: 1em;
        .close {
          width: 24px;
          height: 24px;
          svg {
            path {
              fill: #013D58;
            }
          }
        }
        h2 {
          text-align: center;
          color: #141414;
          font-size: 14px;
          font-weight: 400;
          padding: 20px 0px;
          margin: 0px;
          width: 100%;
        }
      }
    }

    .exit-row-disclaimer {
      top: 1%;
      left: 0px;
      width: 100%;
      height: 99%;
      border-radius: 8px 8px 0px 0px;
      border-top: none;
      .exit-row-disclaimer-actions {
        .button {
          &:not(:last-child) {
            margin-right: 0px;
          }
          &:last-child {
            margin-left: 0px;
          }
        }
      }
    }

    .selected-popout {
      padding: 16px 20px;
      width: calc(100% - 36px);
      box-sizing: border-box;
      left: 18px;
      .unselect {
        margin-right: -1em;
      }
    }

    .dropdown {
      &+.dropdown {
        border-top: none;
      }
    }
  }
`;
